import { ISupplyItem, ISupplyReceipt, ISupplyReceiptItem, TareStatusEnum, TrustBox, TrustBoxObj } from '@/interfaces'
import { cloneDeep } from '@shared/helpers'

export const getOpenTare = (tares: ISupplyReceipt[]) => {
  return tares.find((tares) => tares.status === TareStatusEnum.open)
}
export const getTareById = (tareId: string, tares: ISupplyReceipt[]) => {
  return tares.find((tare) => tare.id === tareId)
}

export const getTrustBoxesObj = (trustBoxes: TrustBox[] = []): TrustBoxObj => trustBoxes.reduce((acc, item) => {
  return {...acc, [item.barcode]: item.items}
}, {} as TrustBoxObj)

const getSupplyReceiptItemKey = (
  item: { sku_id?: string, sku_batch_id?: string, trust_acceptance_box_barcode?: string},
) =>
  `${item.sku_id||''}${item.sku_batch_id || ''}${item.trust_acceptance_box_barcode||''}`

/**
 * planItems - Текущие товары К ОБРАБОТКЕ
 * tares - Тары из которых вычитают добавленные товары
 */
export const getSupplyItemsWithoutTareItems = (
  planItems: ISupplyItem[],
  tares: ISupplyReceipt[],
  trustBoxes: TrustBoxObj,
  debug?: boolean
) => {
  /**
   * Перерасчитывает товар К ОБРАБОТКЕ отностильно ПРИНЯТЫХ товаров
   */

  /** Получаем плоский список товаров из тар */
  const allTaresItemsObj: Record<string,ISupplyReceiptItem> = tares
    .map((tare) => tare.items)
    .flat(2)
    .reduce((accum, item) => {
      const itemKey = getSupplyReceiptItemKey(item)
      /** Складываем все товары из разных партий */
      if (item.trust_acceptance_box_barcode) {
        trustBoxes[item.trust_acceptance_box_barcode].forEach(trustItem => {
          const trustItemKey = getSupplyReceiptItemKey(trustItem)
          if (accum[trustItemKey]) {
            accum[trustItemKey].quantity += trustItem.quantity
            return
          }
          accum[trustItemKey] = { ...trustItem, multiplicity: 1 }
        })
        return accum
      }
      if (accum[itemKey]) {
        accum[itemKey].quantity += (item.quantity * item.multiplicity)
        return accum
      }
      const newItem: ISupplyReceiptItem = { ...item, quantity: item.quantity * item.multiplicity }
      return {
        ...accum,
        [itemKey]: newItem
      }
    }, {})

  /** Вычитаем товары добавленные в тары из планируемых (К ОБРАБОТКЕ) */
  return cloneDeep(planItems)
    .reduce((acc, item) => {
      const alreadyAddedItemPlane = acc.find(planItem => getSupplyReceiptItemKey(planItem) === getSupplyReceiptItemKey(item))
      if (alreadyAddedItemPlane) {
        alreadyAddedItemPlane.quantity += item.quantity
        return acc
      }
      return [...acc, item]
    }, [])
    .reduce((acc: ISupplyItem[], planItem) => {
    const addedTareItems = Object.values(allTaresItemsObj).filter((tareItem) => {
      const tareItemKey = getSupplyReceiptItemKey({
        ...tareItem,
        /* только если есть план по партиям, иначе партия принятого товара не учитывается */
        sku_batch_id: planItem.sku_batch_id ? tareItem.sku_batch_id : '',
      })
      const planItemKey = getSupplyReceiptItemKey(planItem)
      return tareItemKey === planItemKey
    })
    if (addedTareItems.length) {
      addedTareItems.forEach((tareItem) => {
        planItem.quantity -= tareItem.quantity
      })
      if (planItem.quantity <= 0) {
        return acc
      }
    }
    return [...acc, planItem]
  }, [])
}

/**
 * originalUserPlanItems -  Изначальные товары К ОБРАБОТКЕ (для пользователя)
 * currentPlanItems - Текущие товары К ОБРАБОТКЕ (локально изменяемые)
 * deletedItems - Удаляемые товары из тары
 * */
export const getUpdatedSupplyItemsFromDeletedTareItems = (
  originalUserPlanItems: ISupplyItem[],
  currentPlanItems: ISupplyItem[],
  deletedItems: ISupplyReceiptItem[],
): ISupplyItem[] => {
  /**
   * Перерасчитывает товар К ОБРАБОТКЕ при удалении элементов (тары и товара)
   */
  let newPlanItems = cloneDeep(currentPlanItems)
  deletedItems.forEach((deletedItem) => {
    const currentItem = newPlanItems.find(
      (item) => item.sku_id === deletedItem.sku_id,
    )
    if (currentItem) {
      currentItem.quantity += (deletedItem.quantity * deletedItem.multiplicity)
      return
    }

    const originalPlanItem = originalUserPlanItems.find(
      (item) => item.sku_id === deletedItem.sku_id,
    )
    // if (!originalPlanItem || deletedItem.isSurplus) {
    if (!originalPlanItem) {
      // /** Это излишек, его не надо добавлять в план при удалении */
      // console.log('Нет оригинального товара или это товар и излишком')
      return
    }
    newPlanItems.push({
      ...cloneDeep(originalPlanItem),
      quantity: deletedItem.quantity,
    })
  })
  return newPlanItems
}

export const checkTareItemBySerial = (
  tareItem: ISupplyReceiptItem,
  serialNumberValue: string,
) => {
  return tareItem.serial_numbers.find(
    (addedSerialNumber) => addedSerialNumber.value === serialNumberValue,
  )
}

export const getAlreadyUsedSerialNumbers = (receipts: ISupplyReceipt[]) => {
  return receipts
    .map((receipt) =>
      receipt.items.map((receiptItem) =>
        receiptItem.serial_numbers.map((serial) => serial.value),
      ),
    )
    .flat(3)
}

export const getTaresForCurrentUser = (
  allTares: ISupplyReceipt[],
  userBarcode: string,
) => {
  return allTares.filter((tare) => tare.executor?.login === userBarcode)
}
export const getAllBlockedTaresForCurrentUser = (
  allTares: ISupplyReceipt[],
  userBarcode: string,
) => {
  return allTares.filter((tare) => {
    const tareIsDone = tare.status === TareStatusEnum.done
    const isOtherUser = tare.executor?.login !== userBarcode
    return tareIsDone || isOtherUser
  })
}
export const generateStressTesting = (count: number) => {
  // Array(count).fill(() => {
  //
  // })
  // return allSupplyItems.filter((supplyItem) => {
  //   return otherTares.filter(tare => (
  //     tare.items.find(item => item.sku_id !== supplyItem.id)
  //   )).length
  // })
}
