import { ISku } from 'src/interfaces'


export const generateSkuMapByBarcode = (skus: ISku[]): Map<string, ISku[]> => {
  const skuMap = new Map<string, ISku[]>()
  skus.forEach(sku => {
    sku.barcodes.forEach(({ barcode }) => {
      const mapVal = skuMap.get(barcode)
      if (mapVal) {
        mapVal.push(sku)
      } else {
        skuMap.set(barcode, [sku])
      }
    })
  })
  return skuMap
}

export const generateSkusMapById = (skus: ISku[]): Map<string, ISku> => {
  const skuMap = new Map<string, ISku>()
  skus.forEach(sku => {
    skuMap.set(sku.id, sku)
  })
  return skuMap
}

export const setSkusToMapByBarcodes = (sku: ISku, map: Map<string, ISku[]>) => {
  sku.barcodes.forEach(({ barcode }) => {
    const mapVal = map.get(barcode)
    if (mapVal) {
      // если этот товар добавили, то его добавлять больше нет смысла
      const alreadyAdded = mapVal.find((val) => val.id === sku.id)
      if (!alreadyAdded) {
        mapVal.push(sku)
      }
    } else {
      map.set(barcode, [sku])
    }
  })
  return map
}

export const generateSkusMaps = (skus: ISku[]): { barcodesMap: Map<string, ISku[]>, idMap: Map<string, ISku> } => {
  const skuBarcodesMap = new Map<string, ISku[]>()
  const skuIdMap = new Map<string, ISku>()
  skus.forEach(sku => {
    setSkusToMapByBarcodes(sku, skuBarcodesMap)
    skuIdMap.set(sku.id, sku)
  })
  return { barcodesMap: skuBarcodesMap, idMap: skuIdMap }
}