import React, { CSSProperties, ElementType, useRef, useState } from 'react'
import styles from './LinkContextMenu.module.scss'
import { ContextMenu } from '@consta/uikit/ContextMenu'
import { Direction } from '@consta/uikit/__internal__/src/components/Popover/Popover'
import {
  ContextMenuItemDefault,
  ContextMenuPropGetItemLabel,
} from '@consta/uikit/__internal__/src/components/ContextMenu/types'
import { useToggle } from '@shared/hooks'
import { useHistory } from 'react-router-dom'
import { useForkRef } from '@consta/uikit/useForkRef'

enum OpenTypeEnum {
  none = 'none',
  open = 'open',
  newTabOpen = 'newTabOpen',
}

interface IProps {
  defaultOpenType?: 'none' | 'open' | 'newTabOpen'
  href: string
  getLabel?: ContextMenuPropGetItemLabel<any>
  direction?: Direction
  children: React.ReactNode
  className?: string
  style?: CSSProperties
  itemRef?: React.RefObject<any>
  as?: ElementType
  onOpen?: () => void
}


const LinkContextMenu = (props: IProps) => {
  const {
    as: Tag = 'div',
    defaultOpenType = OpenTypeEnum.none,
    getLabel = (item) => item.label,
    direction = 'downRight',
    children,
    href,
    className,
    style,
    itemRef,
    onOpen,
  } = props

  const history = useHistory()

  const {
    value: visible,
    setValue,
  } = useToggle()

  const [position, setPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 })

  const menuRef = useRef(null)

  const handleOpen = (openType: OpenTypeEnum) => {
    if (openType === OpenTypeEnum.newTabOpen) {
      window.open(href, '_blank')?.focus()
      setValue(false)
    }
    if (openType === OpenTypeEnum.open) {
      onOpen?.()
      history.push(href)
      setValue(false)
    }
  }

  const handleClick = () => {
    // todo проверить, что не повлияет на поведение кнопок
    handleOpen(defaultOpenType as OpenTypeEnum)
  }
  const handleClickOutside = () => {
    setValue(false)
  }
  const handleContextMenu = (e) => {
    e.preventDefault()
    setPosition({ x: e.clientX, y: e.clientY })
    setValue(true)
  }

  const handleItemClick = (item: ContextMenuItemDefault) => {
    handleOpen(item.key as OpenTypeEnum)
  }

  const items: ContextMenuItemDefault[] = [
    { key: OpenTypeEnum.open, label: 'Открыть' },
    { key: OpenTypeEnum.newTabOpen, label: 'Открыть в новом окне' },
  ]

  return (
    <>
      <Tag
        id={href}
        ref={useForkRef([menuRef, itemRef])}
        onClick={handleClick}
        onContextMenu={handleContextMenu}
        className={className}
        style={style}
      >
        {children}
      </Tag>
      <ContextMenu
        isOpen={visible}
        offset={10}
        direction={direction}
        onClickOutside={handleClickOutside}
        items={items}
        getItemLabel={getLabel}
        onItemClick={({ item }) => {
          handleItemClick(item)
        }}
        position={position}
        className={styles.contextMenu}
      />
    </>
  )
}
export default LinkContextMenu
