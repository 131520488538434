import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { useReturnContext } from '../../../../../context/ReturnContext'
import { DocumentsParams, DocumentStatusField } from '../../../../../components'
import { InputWrapper } from 'src/components'
import { DocumentTypeEnum } from '@/interfaces'
import days from 'dayjs'

import { generateReturnColumns, generateReturnRows } from './utils'

import styles from './documents-params.module.scss'
import { updateFormStateDraftAction } from '../../../../../store/actions'
import { DATE_TIME_VIEW } from '@shared/const/date'

interface SupplyFormContentProps {}

const DocumentsParamsFields = (props: SupplyFormContentProps) => {
  const { page, id: documentId } = useParams<{ page: OperAndDocumPagesType, id: string, tabId: string }>()
  const { state, dispatch } = useReturnContext()

  const rows = useMemo(() =>
    generateReturnRows(state.documentRaw?.return?.places || [], state.documentRaw?.skus || []),
    [state.documentRaw])

  const cols = useMemo(() =>
    generateReturnColumns(),
    [])

  const responsible = state.documentRaw?.return?.executor
  console.log(state.formStateDraft?.status)
  return (
    <DocumentsParams
      rows={rows}
      columns={cols}
      tableTitle={'Места'}
    >
      <div className={styles.fieldsWrapper}>
         <DocumentStatusField
          className={styles.fieldType25}
          value={state.formStateDraft?.status}
          onChange={(optionValue) =>
            dispatch(updateFormStateDraftAction('status', optionValue?.value))
          }
          documentType={page as DocumentTypeEnum}
        />

        <InputWrapper
          className={styles.fieldType25}
          value={`${responsible?.surname || ''} ${responsible?.name || ''} ${responsible?.surname}`.replace(' ', '')}
          id={'responsible'}
          label={'Ответственный'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.return?.external_id}
          id={'docNumber'}
          label={'Номер накладной'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={days(state.documentRaw?.return?.date).format(DATE_TIME_VIEW)}
          id={'date'}
          label={'Дата накладной'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.return?.delivery_company}
          id={'deliveryService'}
          label={'Способ доставки'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={'-'}
          id={'video'}
          label={'Видео возврата'}
          disabled
        />
      </div>
    </DocumentsParams>
  )
}

export default DocumentsParamsFields
