import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'

import { returnInitialState, returnReducer } from '../store/reducer/returnReducer'

import { IReturnFormState } from '../store/interfaces'

export interface IUseReducer {
  state: IReturnFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {
}

export const ReturnContext = createContext<IContext>({
  state: returnInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const ReturnContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof returnInitialState, IReducerAction>>(returnReducer, returnInitialState)

  useEffect(() => {
    console.log('--- Return State ---', state)
  }, [state])

  return (
    <ReturnContext.Provider value={{ state, dispatch }}>
      {children}
    </ReturnContext.Provider>
  )
}

export const useReturnContext = () => useContext(ReturnContext)
