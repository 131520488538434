import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'

import { replaceInitialState, replaceReducer } from '../store/reducer/replaceReducer'

import { IReplaceFormState } from '../store/interfaces'

export interface IUseReducer {
  state: IReplaceFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {
}

export const ReplaceContext = createContext<IContext>({
  state: replaceInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const ReplaceContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof replaceInitialState, IReducerAction>>(replaceReducer, replaceInitialState)

  useEffect(() => {
    console.log('--- Replace State ---', state)
  }, [state])

  return (
    <ReplaceContext.Provider value={{ state, dispatch }}>
      {children}
    </ReplaceContext.Provider>
  )
}

export const useReplaceContext = () => useContext(ReplaceContext)
