import React, { FC } from 'react'
import styles from './form-wrapper.module.scss'
import { Button } from '@/components'
import { Loader } from '@consta/uikit/__internal__/src/components/Loader/Loader'


interface FormWrapperProps {
  title?: string,
  subtitle?: string | React.ReactNode,
  loading?: boolean,
  extraHead?: React.ReactNode,

  handleClickCancel: () => void
  cancelBtnDisabled?: boolean

  handleClickSave?: () => void
  saveBtnDisabled?: boolean
  saveBtnLoading?: boolean
  children?: React.ReactNode
}

const FormWrapper: FC<FormWrapperProps> = (props) => {
  const {
    title,
    subtitle,
    extraHead,
    loading,
    children,
    handleClickCancel,
    handleClickSave,
    cancelBtnDisabled,
    saveBtnDisabled,
    saveBtnLoading
  } = props

  return (
    <div className={styles.main}>
      <div className={styles.cardWrapper}>
        <div className={styles.head}>
          <div>
            <div className={styles.titleWrapper}>
              <h2 className={styles.title}>
                {title}
              </h2>
              {loading ? (
                <Loader size="s" className={styles.loader}/>
              ) : null}
            </div>
            <h3 className={styles.subtitle}>
              {subtitle}
            </h3>
          </div>
          {extraHead}
        </div>

        {children}
      </div>

      <div className={styles.footer}>
        <Button
          label={'Отменить и закрыть'}
          view={'ghost'}
          onClick={handleClickCancel}
          className={styles.orderCancelBtn}
          // disabled={cancelBtnDisabled}
        />
        {
          handleClickSave ? (
            <Button
              label={'Сохранить'}
              view={'primary'}
              onClick={handleClickSave}
              className={styles.orderCancelBtn}
              disabled={saveBtnDisabled}
              loading={saveBtnLoading}
            />
          ) : null
        }
      </div>
    </div>
  )
}

export default FormWrapper
