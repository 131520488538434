import { INotification } from "./schema"


export const notificationReducer = (state: { [key: string]: INotification }, action: any) => {
  switch (action.type) {
    case 'addNotification':
      return { [action.newNotification.id]: action.newNotification, ...state }
    case 'removeNotification':
      return (() => {
        const newNotifications = { ...state }
        delete newNotifications[action.id]
        return newNotifications
      })()
    case 'clearAll':
      return {}
    default:
      return state
  }
}