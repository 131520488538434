import React from 'react'
import { ComboboxWrapperWithApi } from '@/components'
import { useGetItems } from '@/hooks'
import { ISku } from '@/interfaces'
import { CommonFieldProps } from '@shared/types/fields'

interface IComboboxSkus extends CommonFieldProps {
  convertHookData?: (data: { skus: ISku[] }) => any
}

const ComboboxSkus = (props: IComboboxSkus) => {
  const {
    label = 'Номенклатура',
    placeholder = 'Выберите номенклатуру',
    convertHookData = (data) => data.skus.map((sku) => ({ id: sku.id, label: sku.title, dto: sku })),
    convertHookParams = (params) => ({
      search: params.search
    }),
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      label={label}
      withApiSearch={!Boolean(restProps.items?.length)}
      placeholder={placeholder}
      useGetHookSearch={useGetItems}
      convertHookData={convertHookData}
      convertHookParams={convertHookParams}
      {...restProps}
    />
  )
}
export default ComboboxSkus
