import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { ICombobox } from '../acceptance'
import { generateStatusesOptions } from '@shared/helpers'


export enum ShipmentPlanStatusesEnum {
  require_check = "REQUIRE_CHECK",
  ready = "READY"
}

export const SHIPMENT_PLAN_STATUSES_DESCRIPTION: Record<ShipmentPlanStatusesEnum | string, string> = {
  [ShipmentPlanStatusesEnum.require_check]: 'Требует проверки',
  [ShipmentPlanStatusesEnum.ready]: 'Готов',
}

export const SHIPMENT_PLAN_STATUS_BADGE_TYPE: Record<ShipmentPlanStatusesEnum | string, BadgePropStatus> = {
  [ShipmentPlanStatusesEnum.require_check]: 'warning',
  [ShipmentPlanStatusesEnum.ready]: 'normal',
}

export const shipmentPlanSelectOptions: ICombobox[] = generateStatusesOptions(SHIPMENT_PLAN_STATUSES_DESCRIPTION)