import { useMutation } from 'react-query'
import api from '@shared/api'

export const useDeleteCells = () => {
  const deleteCells = async (params: string[]) => {
    const result = await api.delete('/warehouse/cell', { data: params })

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(deleteCells)
}
