import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { formStatesAreEqual } from '../../../store/utils'
import { revertDraftStateToRawAction } from '../../../store/actions'
import { IOrderFormDataState } from '../../../store/interfaces/order'

import { OrderPlaceFormContextProvider, useOrderPlaceFormContext } from '../../../context/OrderPlaceFormContext'

import { setOrderPlaceRawAction } from '@pages/OperationsAndDocumentsPage/store/actions/orderPlace'

import { OperAndDocumPagesType } from '../../../pagesType'
import { FormWrapper } from '../../../components'
import { OrderPlaceFormContent } from './components'

import { useGetOrderPlace } from 'src/hooks'


const OrderPlaceForm = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const { state, dispatch } = useOrderPlaceFormContext()

  const { id: documentId } = useParams<{ page: OperAndDocumPagesType, id: string }>()

  const { fetch: getOrderPlace } = useGetOrderPlace()

  useEffect(() => {
    setIsLoading(true)

    getOrderPlace(documentId)
      .then((data) => {
        dispatch(setOrderPlaceRawAction(data))
      })
      .finally(() => setIsLoading(false))
  }, [])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.goBack()
  }


  const statesAreEqual = formStatesAreEqual<IOrderFormDataState | null>(state.formStateDraft, state.formStateRaw)
  return (
    <FormWrapper
      loading={isLoading}
      title={state.documentRaw?.id || documentId}
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <OrderPlaceFormContent />
    </FormWrapper>
  )
}

const CargoSpaceFormWithContext = () => (
  <OrderPlaceFormContextProvider>
    <OrderPlaceForm />
  </OrderPlaceFormContextProvider>
)

export default CargoSpaceFormWithContext
