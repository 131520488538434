import { ICarryerQuby } from 'src/interfaces/transportationTemplates'
import { IQubyFormDataState } from '../interfaces/quby'

export const SET_QUBY_RAW = 'SET_QUBY_RAW'
export const setQubyRawAction = (documentRaw: ICarryerQuby | null) => {
  return {
    type: SET_QUBY_RAW,
    payload: documentRaw,
  }
}

export const SET_QUBY_FORM_STATE_DRAFT = 'SET_QUBY_FORM_STATE_DRAFT'
export const setQubyFormDraftAction = (formState: IQubyFormDataState) => {
  return {
    type: SET_QUBY_FORM_STATE_DRAFT,
    payload: formState,
  }
}

export const SET_QUBY_FORM_STATE_RAW = 'SET_QUBY_FORM_STATE_RAW'
export const setQubyFormRawAction = (formState: IQubyFormDataState) => {
  return {
    type: SET_QUBY_FORM_STATE_RAW,
    payload: formState,
  }
}
export const UPDATE_QUBY_FORM_STATE_RAW = 'UPDATE_QUBY_FORM_STATE_RAW'
export const updateQubyFormStateRawAction = (
  key: keyof IQubyFormDataState,
  value: any,
) => {
  return {
    type: UPDATE_QUBY_FORM_STATE_RAW,
    payload: { key, value },
  }
}
export const UPDATE_QUBY_FORM_STATE_DRAFT = 'UPDATE_QUBY_FORM_STATE_DRAFT'
export const updateQubyFormStateDraftAction = (
  key: keyof IQubyFormDataState,
  value: any,
) => {
  return {
    type: UPDATE_QUBY_FORM_STATE_DRAFT,
    payload: { key, value },
  }
}
