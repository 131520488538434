import React, { useEffect, useMemo } from 'react'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { DocumentsListWrapper } from '../../../components'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'

import { useGetShipmentPlans } from 'src/hooks'
import { useFilter } from '../../../hooks/useFilter'


const ShipmentPlanList = () => {
  const { filterState, setFilterState } = useFilter()

  const {
    isFetching: shipmentPlansLoading,
    data: shipmentPlansData,
    refetch: refetchShipmentPlans,
  } = useGetShipmentPlans(generateFilterParams(filterState))

  useEffect(() => {
    refetchShipmentPlans()
  }, [filterState])

  const rows = useMemo(() =>
      generateRows(shipmentPlansData?.shipment_plans || []),
    [shipmentPlansData?.shipment_plans]
  )
  const columns = useMemo(() => generateColumns(), [])
  const filtersConfig = generateFilters()

  return (
    <DocumentsListWrapper
      title={'План на отгрузку'}
      rows={rows}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      filtersConfig={filtersConfig}
      loading={shipmentPlansLoading}
      pagination={shipmentPlansData?.pagination}
    />
  )
}

export default ShipmentPlanList
