import { ITransportaion } from 'src/interfaces/transportationTemplates'
import { ITemplatesFormDataState } from '../interfaces'

export const SET_TEMPLATE_DOCUMENT_RAW = 'SET_TEMPLATE_DOCUMENT_RAW'
export const setTemplateDocumentRawAction = (documentRaw: ITransportaion) => {
  return {
    type: SET_TEMPLATE_DOCUMENT_RAW,
    payload: documentRaw,
  }
}

export const SET_TEMPLATE_FORM_STATE_DRAFT = 'SET_TEMPLATE_FORM_STATE_DRAFT'
export const setTemplateFormDraftAction = (
  formState: ITemplatesFormDataState,
) => {
  return {
    type: SET_TEMPLATE_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_TEMPLATE_FORM_STATE_RAW = 'SET_TEMPLATE_FORM_STATE_RAW'
export const setTemplateFormRawAction = (
  formState: ITemplatesFormDataState,
) => {
  return {
    type: SET_TEMPLATE_FORM_STATE_RAW,
    payload: formState,
  }
}

export const UPDATE_FORM_TEMPLATE_STATE_DRAFT =
  'UPDATE_FORM_TEMPLATE_STATE_DRAFT'
export const updateFormTemplateStateDraftAction = (
  key: keyof ITemplatesFormDataState,
  value: any,
) => {
  return {
    type: UPDATE_FORM_TEMPLATE_STATE_DRAFT,
    payload: { key, value },
  }
}

export const UPDATE_FORM_TEMPLATE_STATE_RAW = 'UPDATE_FORM_TEMPLATE_STATE_RAW'
export const updateFormTemplateStateRawAction = (
  key: keyof ITemplatesFormDataState,
  value: any,
) => {
  return {
    type: UPDATE_FORM_TEMPLATE_STATE_RAW,
    payload: { key, value },
  }
}
