import api from '@shared/api'
import { IOrderBatch } from '../../interfaces'

const getOrderBatch = async (id: string) => {
  const result = await api.get(`/order-batch/${id}`)

  if (!result.data?.success) {
    throw result.data
  }
  return result.data.batch
}

const useGetOrderBatch = () => ({
  fetch: async (id: string): Promise<IOrderBatch> => await getOrderBatch(id),
})

export default useGetOrderBatch
