import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'

interface IParams {
  orderId: string
}

const useResetPackingPhysicalSet = (
  options?: UseMutationOptions<any,ApiReqResult,any>
) => {
  return useMutation(
    async (params: IParams,) => {
      const result = await api.post(`/physical-set-order/${params.orderId}/reset`)
      if (!result?.data?.success) {
        throw new Error('not success')
      }
      return result.data
    },
    options
  )
}

export default useResetPackingPhysicalSet
