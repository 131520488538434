const MOCK_COUNT = 100
export const MOCK_BATCH_SORTING: any = {
  'errors': [],
  'skus': [
    ...Array(MOCK_COUNT).fill(1).map((el, i) => {
      return ({
        'id': 'TM-1041'+i,
        'article': 'pen',
        'barcodes': [
          {
            'active': true,
            'barcode': '978-5-00202-393-6'+i,
            'type': 'COMMON',
            'used_in_last_supply': false,
          },
          {
            'active': true,
            'barcode': '746806457'+i,
            'type': 'OZON',
            'used_in_last_supply': false,
          },
          {
            'active': true,
            'barcode': '100020003001'+i,
            'type': 'COMMON',
            'used_in_last_supply': false,
          },
          {
            'active': true,
            'barcode': '9785002023936'+i,
            'type': 'COMMON',
            'used_in_last_supply': false,
          },
        ],
        'batch_accounting': false,
        'batch_expdate_mandatory': false,
        'batches': [],
        'country_of_origin': 'RU',
        'dimensions_fact': {
          'depth': 10,
          'height': 10,
          'width': 10,
        },
        'dimensions_plan': {
          'depth': 0,
          'height': 0,
          'width': 0,
        },
        'external_id': '1041',
        'fragile': false,
        'image': 'https://storage.yandexcloud.net/images-k2c/f5bdca2e-3aff-46e2-afda-8de561d1732a.png',
        'image_preview': 'https://storage.yandexcloud.net/images-k2c/f5bdca2e-3aff-46e2-afda-8de561d1732a-100.png',
        'images': [
          {
            'position': 0,
            'preview_url': 'https://storage.yandexcloud.net/images-k2c/f5bdca2e-3aff-46e2-afda-8de561d1732a-100.png',
            'url': 'https://storage.yandexcloud.net/images-k2c/f5bdca2e-3aff-46e2-afda-8de561d1732a.png',
          },
        ],
        'merchant': {
          'cam_id': '101',
          'contract_date': '2022-09-25',
          'contract_num': '1001',
          'enabled': true,
          'id': '2b78266b-0405-48f6-a263-3dba1869209f',
          'login': 'test@new-wms.ru',
          'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
          'title': 'Test new WMS merchant',
          'trade_title': 'Test new WMS merchant',
        },
        'parts': [],
        'require_dimensions': false,
        'require_weight': false,
        'scan_serial_numbers': [],
        'title': 'Ручка',
        'type': 'SKU',
        'weight_fact': 1000,
        'weight_plan': 0,
      })
    }),
  ],
  'sorting': {
    'activated': true,
    'create_ts': '2024-08-30T09:43:36.911298',
    'date': '2024-08-30T09:43:36.888957',
    'executor': {
      'enabled': true,
      'id': '69e2c608-88c9-4a32-87a9-1401736228b0',
      'login': '123',
      'name': '123',
      'patronymic': '123',
      'position': 'GENERAL_DIRECTOR',
      'roles': [
        'e545d9ad-c079-4c11-9005-a775438549a2',
      ],
      'surname': '123',
    },
    'id': 'ST-0000-0009',
    'items': [
      ...Array(MOCK_COUNT).fill(1).map((el, i) => {
        return ({
          'quantity': 1,
          'quantity_plan': 1,
          'sku_id': 'TM-1041'+i,
          'sorting_number': 2,
        })
      })
      // {
      //   'quantity': 1,
      //   'quantity_plan': 1,
      //   'sku_id': 'TM-1041',
      //   'sorting_number': 1,
      // },
    ],
    'sorted_items': [
      // {
      //     "quantity": 1,
      //     "sku_id": "TM-1041",
      //     "sorting_number": 2
      // },
      // {
      //     "quantity": 1,
      //     "sku_id": "TM-1041",
      //     "sorting_number": 1
      // }
    ],
    'status': 'DONE',
    'update_ts': '2024-09-01T16:16:54.592615',
    'uuid': '9e8be387-e27d-4ea9-96b9-e848e8cd8cc9',
    'workspace': {
      'barcode': 'XX-1',
      'condition': 'GOOD',
      'id': 'a94203b3-67d7-42b7-9642-ef510c3e9323',
      'printer_settings': [
        {
          'name': 'PRINTER_A4',
          'type': 'A4',
        },
        {
          'name': 'STICKER_6x6',
          'type': 'STICKER_6x6',
        },
        {
          'name': 'STICKER_10X15',
          'type': 'STICKER_10x15',
        },
      ],
      'title': 'XX-1',
      'zone_id': '26826c40-ac61-4e9f-a5ea-1101ef5521f2',
    },
  },
  'success': true,
}