import api from '@shared/api'
import { ApiReqResult, IGate } from 'src/interfaces'
import { useQuery } from 'react-query'

interface IParams {}
const getGatesList = async (params?: IParams) => {
  const result = await api.get(`/warehouse/gate`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

interface IGetOrdersListResult extends ApiReqResult {
  gates: IGate[]
}

const useGetWarehouseGatesList = (
  params?: IParams,
  callback?: (gates: any[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IGetOrdersListResult>(
    [`getGatesList`, params],
    () => getGatesList(params),
    {
      onSuccess: ({ success, gates }) => {
        if (success) {
          callback?.(gates)
        } else {
          error?.()
        }
      },
      onError: error,
      onSettled: settled,
    },
  )
}
export default useGetWarehouseGatesList
