import { IOrderPlaceFormDataState } from '../interfaces/orderPlace'
import { IOrderPlace } from 'src/interfaces'

export const SET_ORDER_PLACE_RAW = 'SET_ORDER_PLACE_RAW'
export const setOrderPlaceRawAction = (documentRaw: IOrderPlace | null) => {
  return {
    type: SET_ORDER_PLACE_RAW,
    payload: documentRaw,
  }
}

export const SET_ORDER_PLACE_FORM_STATE_DRAFT = 'SET_ORDER_PLACE_FORM_STATE_DRAFT'
export const setOrderPlaceFormDraftAction = (formState: IOrderPlaceFormDataState) => {
  return {
    type: SET_ORDER_PLACE_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_ORDER_PLACE_FORM_STATE_RAW = 'SET_ORDER_PLACE_FORM_STATE_RAW'
export const setOrderPlaceFormRawAction = (formState: IOrderPlaceFormDataState) => {
  return {
    type: SET_ORDER_PLACE_FORM_STATE_RAW,
    payload: formState,
  }
}

