import React, { FC, useState } from 'react'
import styles from './download-docs-table.module.scss'
import { TableV2 } from 'src/components'
import { Button } from '@consta/uikit/Button'
import { generateColumns } from './utils'
import { ITableRow } from 'src/interfaces'

interface DownloadTableProps {
  title?: string
  tableRows: ITableRow[]
  handleClickDownload: (filesKeys: string[]) => void
  maxHeightBody?: number
  loading?: boolean
}
/**
 * todo изменить скачивание документов (добавить в пропсы документы, которые надо скачать)
 * */
const DownloadDocsTable: FC<DownloadTableProps> = (props) => {
  const {
    title,
    handleClickDownload,
    maxHeightBody = 550,
    tableRows,
    loading,
  } = props

  const [checkedFiles, setCheckedFiles] = useState<string[]>([])

  const onClickDownload = () => {
    handleClickDownload(checkedFiles)
  }

  const columns = generateColumns(checkedFiles, setCheckedFiles, tableRows)
  return (
    <div className={styles.main}>
      <TableV2
        fullHeight
        theme={'gray'}
        extraHeader={
          <div className={styles.tableHeader}>
            <Button
              view={'secondary'}
              label="Скачать"
              onClick={onClickDownload}
              loading={loading}
            />
          </div>
        }
        rows={tableRows}
        columns={columns}
        title={title}
        titleClassName={styles.tableTitle}
        className={styles.tableWrap}
        maxHeightBody={maxHeightBody}
      />
    </div>
  )
}

export default DownloadDocsTable
