import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { MOCK_GET_CROSSDOCKING_SUPPLY, provideMock } from '../../mocks'
import { RequestData } from '../../interfaces/supplySortingDto'


interface Params {
  supplyId: string,
  joinCode?: string
  zoneId?: string
}

interface Result extends RequestData, ApiReqResult {
}

const joinToCrossdockingSorting = async (params: Params) => {
  const result = await provideMock<Params, { data: Result }>(
    async () =>
      await api.post(`/crossdocking-sorting/supply/${params.supplyId}/join`, { join_code: params.joinCode, zone_id: params.zoneId }),
    params,
    [
      {
        key: 'supplyId',
        value: 'mock',
        result: { data: MOCK_GET_CROSSDOCKING_SUPPLY},
        logData: 'Присоединение к сортировке'
      }
    ],
  )()

  if (!result.data?.success) {
    throw result
  }
  return result.data
}


const useJoinToCrossdockingSorting = (
  options?: UseMutationOptions<Result, any, Params>,
) => {
  return useMutation<Result, any, Params>(
    joinToCrossdockingSorting,
    options
  )
}

export default useJoinToCrossdockingSorting
