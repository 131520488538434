import React from 'react'
import { InputWrapper, Modal } from '@/components'
import { ModalProps } from '@/interfaces'
import { Button } from '@consta/uikit/Button'
import styles from './select-multiplicity-module.module.scss'

interface IProps extends ModalProps {
  onClose: (isFinal?: boolean) => void
  multiplicity: string | null
  setMultiplicity: (value: string | null) => void
  onSubmit: (multiplicity: string | null) => void
}

const SelectMultiplicityModal = (props: IProps) => {
  const { isOpen, onClose, multiplicity, setMultiplicity, onSubmit } = props;

  const handleSubmit = () => {
    onSubmit(multiplicity)
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      withClose
      size={'s'}
      onClose={onClose}
    >
      <InputWrapper
        autoFocus
        isInteger
        label="Укажите кратность"
        id="multiplicity"
        value={multiplicity}
        handleChange={setMultiplicity}
      />
      <Button
        className={styles.btn}
        label="Выбрать"
        disabled={!multiplicity || !Number(multiplicity)}
        onClick={handleSubmit}
      />
    </Modal>
  )
}

export default SelectMultiplicityModal
