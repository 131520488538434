import { defaultSettingsLS } from '@shared/lib/storage'


export const useDefaultSettings = () => {
  /*
    Arm (рабочая стр.)
    Стр. по умолчанию после авторизации (например арм упаквоки)
  */
  const getDefaultArm = () => defaultSettingsLS.defaultArm
  const setDefaultArm = (value: string) => {
    defaultSettingsLS.defaultArm = value
  }
  const clearDefaultArm = () => {
    defaultSettingsLS.defaultArm = ''
  }

  /*
   Workplace
   ШК рабочего места по умолчанию
  */
  const getDefaultWorkplace = () => defaultSettingsLS.defaultWorkplace
  const setDefaultWorkplace = (value: string) => {
    defaultSettingsLS.defaultWorkplace = value
  }
  const clearDefaultWorkplace = () => {
    defaultSettingsLS.defaultWorkplace = ''
  }

  /*
   DB
   База по умолчанию
  */
  const getDefaultBase = () => defaultSettingsLS.defaultBase
  const setDefaultBase = (defaultBaseValue: string) => {
    defaultSettingsLS.defaultBase = defaultBaseValue
  }
  const clearDefaultBase = () => {
    defaultSettingsLS.defaultBase = ''
  }

  /*
  * Сохранение/управление localStorage для нового дизайна
  */

  const getNewDesign = () => !defaultSettingsLS.newDesign
  const setNewDesign = (value: boolean) => {
    defaultSettingsLS.newDesign = value
  }
  const clearNewDesign = () => setNewDesign(false)


  return {
    defaultArm: getDefaultArm(),
    getDefaultArm,
    setDefaultArm,
    clearDefaultArm,

    defaultWorkplace: getDefaultWorkplace(),
    getDefaultWorkplace,
    setDefaultWorkplace,
    clearDefaultWorkplace,

    defaultBase: getDefaultBase(),
    getDefaultBase,
    setDefaultBase,
    clearDefaultBase,

    defaultNewDesign: getNewDesign(),
    getNewDesign,
    setNewDesign,
    clearNewDesign

  }
}
