import { useMutation } from 'react-query'
import api from '@shared/api'

// request types
export interface IRequestPlaceProduct {
  cell_barcode: string
  quantity: number
  sku_batch_id?: string
  sku_id: string
}

export interface PlaceProductsProps {
  docId: string
  products: IRequestPlaceProduct[]
}

// response types
export interface Error {
  code: string
  message: string
}

export interface Error2 {
  code: string
  message: string
}

export interface Result {
  errors: Error2[]
  id: string
  n: number
  success: boolean
}

export interface PlaceProductsResponseType {
  errors: Error[]
  results: Result[]
  success: boolean
}

export const usePlaceProducts = () => {
  const sendProducts = async (props: PlaceProductsProps) => {
    const result = await api.post(
      `/placing/${props.docId}/item`,
      props.products,
    )

    if (result.status === 200) {
      return result.data as PlaceProductsResponseType
    }
  }

  return useMutation(sendProducts)
}

export default usePlaceProducts
