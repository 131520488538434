import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IDimensions, IDimensionsObj } from '../../../interfaces'


export type weightMeasure = 'g' | 'kg'
export const convertWeight = (form: weightMeasure, to: weightMeasure, value: number): number => {
  if (form === 'g' && to === 'kg') {
    return +(value / 1000).toFixed(3)
  }
  if (form === 'kg' && to === 'g') {
    return Math.round(+value.toFixed(3) * 1000)
  }
  return value
}
export const convertWeightText = (from:weightMeasure, to:weightMeasure, value: TextFieldPropValue): TextFieldPropValue => (
  value ? `${convertWeight(from,to, Number(value))}` : value
)

export type lengthMeasure = 'sm' | 'mm'
export const convertLength = (form: lengthMeasure, to: lengthMeasure, value: number): number => {
  if (form === 'mm' && to === 'sm') {
    return +(value / 10).toFixed(1)
  }
  if (form === 'sm' && to === 'mm') {
    return Math.round(+value.toFixed(1) * 10)
  }
  return value
}
export const convertLengthText = (from:lengthMeasure, to:lengthMeasure, value: TextFieldPropValue): TextFieldPropValue => (
  value ? `${convertLength(from,to, Number(value))}` : value
)
export const convertDimensionsStr = (from:lengthMeasure, to:lengthMeasure, dimensions: IDimensions): IDimensions => ({
  width: convertLengthText(from, to, dimensions.width),
  height: convertLengthText(from, to, dimensions.height),
  depth: convertLengthText(from, to, dimensions.depth),
})
export const convertDimensionsNum = (from:lengthMeasure, to:lengthMeasure, dimensions: IDimensionsObj): IDimensionsObj => ({
  width: convertLength(from, to, Number(dimensions.width)),
  height: convertLength(from, to, Number(dimensions.height)),
  depth: convertLength(from, to, Number(dimensions.depth)),
})

export const convertDimensionsToStrView = (dimensions?: IDimensionsObj | IDimensions): string => {
  if (!dimensions) return ''
  return `${ dimensions.depth } х ${ dimensions.width } х ${ dimensions.height }`
}