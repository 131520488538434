import React, { useEffect } from 'react'
import { DocumentsListWrapper } from '../../../components'
import { generateColumns, generateFilters, generateRows } from './helpers'
import useGetPlacings from '@/hooks/placing/useGetPlacings'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { useGetMerchantList } from 'src/hooks'
import { useFilter } from '../../../hooks/useFilter'

const PlacingList = () => {
  /** Стейт для поиска и отображения данных фильтров */
  const { filterState, setFilterState } = useFilter()

  const { data: merchantData } = useGetMerchantList({})

  const {
    isFetching: placingLoading,
    data: placingData,
    refetch: refetchPlacings,
  } = useGetPlacings(generateFilterParams(filterState))

  useEffect(() => {
    refetchPlacings()
  }, [filterState])

  const rows = generateRows(placingData?.placings || [])
  const columns = generateColumns()

  const inputSearchFilterKey = 'search'
  const filtersConfig = generateFilters(
    inputSearchFilterKey,
    merchantData?.merchants || [],
  )
  return (
    <DocumentsListWrapper
      title={'Документы размещения'}
      rows={rows}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      filtersConfig={filtersConfig}
      inputSearchFilterKey={inputSearchFilterKey}
      loading={placingLoading}
      pagination={placingData?.pagination}
    />
  )
}

export default PlacingList
