import { useMutation } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IVehicleCreateData } from 'src/interfaces'

interface Result extends ApiReqResult {
  id: string
}

interface Params {
  vehicleId: string
}

const useCreateUpdateVehicle = (params: Params) => {
  return useMutation(async (data: IVehicleCreateData) => {
    const vehicleId = params.vehicleId || data.id || ''
    const path = `/vehicle${vehicleId ? `/${vehicleId}` : ''}`
    const result = await api.post(path, data)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}

export default useCreateUpdateVehicle
