import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { RelabelingStatusEnum } from '../relabeling'


export const RELABELING_STATUSES_DESCRIPTION: Record<RelabelingStatusEnum | string, string> = {
  [RelabelingStatusEnum.readyForProcessing]: 'Новый',
  [RelabelingStatusEnum.processing]: 'В процессе',
  [RelabelingStatusEnum.done]: 'Готово',
}

export const RELABELING_STATUS_BADGE_TYPE: Record<RelabelingStatusEnum | string, BadgePropStatus> = {
  [RelabelingStatusEnum.readyForProcessing]: 'system',
  [RelabelingStatusEnum.processing]: 'normal',
  [RelabelingStatusEnum.done]: 'success',
}
