import { IOrder, ORDER_STATUSES_DESCRIPTION, OrderStatusEnum } from 'src/interfaces'
import { TableColumn } from '@consta/uikit/Table'
import React from 'react'
import { OperAndDocumPages } from '../../../../pagesType'
import { DateCell, DeliveryServiceCell } from 'src/components'
import { DocumentNumCell, OrderStatusBadge } from '../../../../components'
import { getFullPersonName } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import days from 'dayjs'

export const generateRows = (orders: IOrder[]) => {
  return orders.map((order) => ({
    id: order.id,
    date: order.create_ts,
    date_value: days(order.create_ts).format(DATE_TIME_VIEW),
    documentNum: order.id,
    cancelItemsConfirmed: order.cancel_items_confirmed,
    shippingDatePlan: order.delivery.shipping_date_plan
      ? days(order.delivery.shipping_date_plan).format(DATE_TIME_VIEW)
      : '',
    deliveryService: order.delivery.title,
    merchant: order.merchant?.title,
    executor: getFullPersonName({
      name: order.executor?.name,
      surname: order.executor?.surname,
      patronymic: order.executor?.patronymic,
    }),
    status: order.status,
    paused_at: order.paused_at,
    status_value: ORDER_STATUSES_DESCRIPTION[order.status as OrderStatusEnum],
    cancel_requested: order.cancel_requested,
    delivery_contract_title: order.delivery.delivery_contract_title,
  }))
}
export const generateColumns = (): TableColumn<any>[] => {
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{ minWidth: '120px' }} />
      },
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        return (
          <DocumentNumCell
            documentNum={row.documentNum}
            page={OperAndDocumPages.ordersShippedForm}
          />
        )
      },
    },

    {
      accessor: 'shippingDatePlan',
      title: 'Дата отгрузки',
    },

    {
      accessor: 'merchant',
      title: 'Организация',
    },
    {
      accessor: 'deliveryService',
      title: 'Способ доставки',
      sortable: true,
      renderCell: ({row}) => {
        return (
          <DeliveryServiceCell
            deliveryServiceTitle={row.deliveryService}
            deliveryContractTitle={row.delivery_contract_title}
          />
        )
      },
    },
    {
      title: 'Статус',
      sortable: true,
      accessor: 'status_value',
      renderCell: ({row}) => {
        return (
          <>
            <OrderStatusBadge
              cancelRequested={Boolean(row.cancel_requested)}
              status={row.status as OrderStatusEnum}
            />
            {
              row?.cancelItemsConfirmed ? (
                <span style={{color: 'red', display: 'block'}}>Отмена позиций подтверждена</span>
              ) : null
            }
            {
              row?.paused_at ? (
                <span style={{color: 'red', display: 'block'}}>{
                  `На паузе с ${days(row?.paused_at).format(DATE_TIME_VIEW)}`
                }</span>
              ) : null
            }
          </>
        )
      },
    },
  ]
}
