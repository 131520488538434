import { useMutation } from 'react-query'
import api from '@shared/api'

const useCompletePlacement = () => {
  const sendCompletePlacement = async (docId: string) => {
    const result = await api.post(`/placing/${docId}/complete`)

    if (result?.status === 200) {
      return result.data
    }
  }

  return useMutation('getPlacementList', sendCompletePlacement)
}

export default useCompletePlacement
