import { generateStatusesOptions } from '@shared/helpers'
import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { ICombobox, PlacingStatusEnum } from '../../interfaces'

export const PLACING_STATUSES: Record<PlacingStatusEnum | string, string> = {
  [PlacingStatusEnum.new]: 'Новый',
  [PlacingStatusEnum.processing]: 'В работе',
  [PlacingStatusEnum.done]: 'Выполнен',
}

export const PLACING_STATUS_BADGE_TYPE: Record<PlacingStatusEnum | string, BadgePropStatus> = {
  [PlacingStatusEnum.new]: 'system',
  [PlacingStatusEnum.processing]: 'success',
  [PlacingStatusEnum.done]: 'error',
}

export const placingSelectOptions: ICombobox[] = generateStatusesOptions(PLACING_STATUSES)