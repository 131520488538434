import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { ICompletingFormState } from '../store/interfaces'
import { completingInitialState, completingReducer } from '../store/reducer'

export interface IUseReducer {
  state: ICompletingFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {}

export const CompletingFormContext = createContext<IContext>({
  state: completingInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const CompletingFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof completingInitialState, IReducerAction>
  >(completingReducer, completingInitialState)

  useEffect(() => {
    console.log('--- State ---', state)
  }, [state])

  return (
    <CompletingFormContext.Provider value={{ state, dispatch }}>
      {children}
    </CompletingFormContext.Provider>
  )
}
export const useCompletingFormContext = () => useContext(CompletingFormContext)
