import { useMutation } from 'react-query'

import api from '@shared/api'

/*
 * Удаление документа
 * http://wms-test.kak2c.ru/warehouse-api/transportation/{doc_id}/item/{item_id}
 */

interface DeleteQubyParams {
  documentId: string
  itemId: string
}

const useDeleteQuby = () => {
  return useMutation(async ({ documentId, itemId }: DeleteQubyParams) => {
    const result = await api.delete(
      `/transportation/${documentId}/item/${itemId}`,
    )

    if (!result?.data?.success) {
      throw result
    }
    return result.data
  })
}

export default useDeleteQuby
