import React from 'react'

import { IReplaceDoc } from 'src/interfaces/replace'

import { REPLACE_STATUS_BADGE_TYPE, REPLACE_STATUSES } from '@/interfaces/statuses'

import { OperAndDocumPages } from '../../../../pagesType'

import { TableColumn } from '@consta/uikit/Table'
import { Badge } from '@consta/uikit/Badge'
import { DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'


export const generateRows = (replaces: IReplaceDoc[]) => {
  return replaces.map(replace => ({
    id: replace.id || '',
    date: replace.create_ts,
    date_value: days(replace.create_ts).format(DATE_TIME_VIEW),
    documentNum: replace.id,
    status: replace.status,
    status_value: REPLACE_STATUSES[replace.status],
    comment: replace.comment || '',
  }))
}

export const generateColumns = (): TableColumn<any>[] => {

  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{minWidth: '120px'}}/>
      }
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        return <DocumentNumCell page={OperAndDocumPages.replaceForm} documentNum={row.documentNum} />
      }
    },
    {
      title: 'Комментарий',
      accessor: 'comment',
    },
    {
      title: 'Статус',
      accessor: 'status_value',
      sortable: true,
      renderCell: ({row}) => (
        <Badge
          status={REPLACE_STATUS_BADGE_TYPE[row.status]}
          label={REPLACE_STATUSES[row.status]}
        />
      )
    },
  ]
}


