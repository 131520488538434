import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { IBatchFormState } from '../store/interfaces'
import { batchInitialState, batchReducer } from '../store/reducer'

export interface IUseReducer {
  state: IBatchFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {
}

export const BatchFormContext = createContext<IContext>({
  state: batchInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const BatchFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof batchInitialState, IReducerAction>>(batchReducer, batchInitialState)

  useEffect(() => {
    console.log('--- State ---', state)
  }, [state])

  return (
    <BatchFormContext.Provider value={{ state, dispatch }}>
      {children}
    </BatchFormContext.Provider>
  )
}

export const useBatchFormContext = () => useContext(BatchFormContext)
