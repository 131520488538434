import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, ISku, OrderPackingV2 } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


interface Params {
  orderId: string
}
const relabelingOrderReset = async (params: Params) => {
  const result = await api.post(`/packing-v2/order/${params.orderId}/relabel/reset`)
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

interface Result extends ApiReqResult {
  order: OrderPackingV2
  skus: ISku[]
}

const useRelabelingOrderReset = (
  options?: UseMutationOptions<Result, any, any>,
) => {
  return useMutation((params: Params) => relabelingOrderReset(params), options)
}

export default useRelabelingOrderReset
