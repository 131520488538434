import { useMutation } from 'react-query'
import api from '@shared/api'
import { IDeliveryFromTo, IPlaceTransportation, IWarehouseAdress } from 'src/interfaces/transportationTemplates'
import { ApiReqResult } from 'src/interfaces'

interface Result extends ApiReqResult {
  id: string
}
interface ITransportaionAdd {
  quantity: number | string
  contractor: { phone: string; title: string }
  delivery_from: IDeliveryFromTo | string
  delivery_to: IDeliveryFromTo | string
  external_warehouse_address: IWarehouseAdress | null

  order_prefix: string
  pickup_from: IDeliveryFromTo | string
  pickup_to: IDeliveryFromTo | string
  place: IPlaceTransportation
  title?: string
  transportation_type: string
}

interface useUpdateTransportationPositionsParams {
  docId?: string | null
  itemId?: string | null
  data: ITransportaionAdd
}

//http://wms-test.kak2c.ru/warehouse-api/transportation/{doc_id}/item/{item_id}
const useUpdateTransportationPositions = () => {
  return useMutation(async (params: useUpdateTransportationPositionsParams) => {
    const path = `/transportation/${params.docId}/item${
      params.itemId ? `/${params.itemId}` : ''
    }`

    const result = await api.post(path, params.data)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}
export default useUpdateTransportationPositions
