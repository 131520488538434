import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { IPlacingFormState } from '../store/interfaces'
import { placingInitialState, placingReducer } from '../store/reducer'

export interface IUseReducer {
  state: IPlacingFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {}

export const PlacingFormContext = createContext<IContext>({
  state: placingInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const PlacingFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof placingInitialState, IReducerAction>>(placingReducer, placingInitialState)

  useEffect(() => {
    console.log('--- State ---', state)
  }, [state])

  return (
    <PlacingFormContext.Provider value={{ state, dispatch }}>
      {children}
    </PlacingFormContext.Provider>
  )
}

export const usePlacingFormContext = () => useContext(PlacingFormContext)
