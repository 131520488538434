import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { formStatesAreEqual } from '../../../store/utils'
import { revertDraftStateToRawAction, setOriginalSkusAction } from '../../../store/actions'
import { IOrderFormDataState } from '../../../store/interfaces/order'

import { ReturnPlaceFormContextProvider, useReturnPlaceFormContext } from '../../../context/ReturnPlaceFormContext'

import { setReturnPlaceRawAction } from '@pages/OperationsAndDocumentsPage/store/actions/returnPlace'

import { FormWrapper } from '../../../components'
import { ReturnPlaceFormContent } from './components'
import useGetReturnById from 'src/hooks/refundAcceptance/useGetReturnById'
import { ISku } from 'src/interfaces'


const ReturnPlaceForm = () => {
  const history = useHistory()

  const { state, dispatch } = useReturnPlaceFormContext()

  const { id: documentId } = useParams<{ id: string }>()

  const [returnPlaceId, returnId] = documentId.split('*')

  const { data, isLoading } = useGetReturnById(returnId)

  useEffect(() => {
    if (!data) {
      return
    }

    dispatch(setOriginalSkusAction(data?.skus as ISku[] || []))
    dispatch(setReturnPlaceRawAction(data?.return?.places?.find((place) => place.id === returnPlaceId) || null))
  }, [data])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.goBack()
  }


  const statesAreEqual = formStatesAreEqual<IOrderFormDataState | null>(state.formStateDraft, state.formStateRaw)
  return (
    <FormWrapper
      loading={isLoading}
      title={state.documentRaw?.id || returnPlaceId}
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <ReturnPlaceFormContent />
    </FormWrapper>
  )
}

const CargoSpaceFormWithContext = () => (
  <ReturnPlaceFormContextProvider>
    <ReturnPlaceForm />
  </ReturnPlaceFormContextProvider>
)

export default CargoSpaceFormWithContext
