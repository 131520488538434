import React from 'react'
import { generatePath } from 'react-router-dom'

import { IShipmentPlan, SHIPMENT_PLAN_STATUS_BADGE_TYPE, SHIPMENT_PLAN_STATUSES_DESCRIPTION } from 'src/interfaces'

import { PATHS } from '@shared/routing'

import { OperAndDocumPages } from '../../../../pagesType'
import { FormTabs } from '../../../../helpers/documentTabs'

import { TableColumn } from '@consta/uikit/Table'
import { Badge } from '@consta/uikit/Badge'
import { DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'


export const generateRows = (plans: IShipmentPlan[]) => {
  return plans.map(plan => ({
    id: plan.id,
    date: plan.create_ts,
    date_value: days(plan.create_ts).format(DATE_TIME_VIEW),
    documentNum: plan.id,
    deliveryService: plan.delivery_service,
    status: plan.status,
    status_value: SHIPMENT_PLAN_STATUSES_DESCRIPTION[plan.status],
  }))
}
export const generateColumns = (): TableColumn<any>[] => {

  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{minWidth: '120px'}}/>
      }
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        const to = generatePath(
              PATHS.OPERATIONS_AND_DOCUMENTS,
              {page: OperAndDocumPages.shipmentPlanForm, id: row.documentNum, tabId: FormTabs.documentsParams}
            )
        return <DocumentNumCell page={OperAndDocumPages.shipmentPlanForm} documentNum={row.documentNum} />
      }
    },
    {
      title: 'Курьерская служба',
      accessor: 'deliveryService',
    },
    {
      title: 'Статус',
      accessor: 'status_value',
      sortable: true,
      renderCell: ({row}) => (
        <Badge
          status={SHIPMENT_PLAN_STATUS_BADGE_TYPE[row.status]}
          label={SHIPMENT_PLAN_STATUSES_DESCRIPTION[row.status]}
        />
      )
    },
  ]
}


