import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import api from '@shared/api'
import { convertObjectToURLParams } from '@shared/helpers'
import { ApiReqResult, ICellPlace, IPaginationFilter, PaginationResult } from 'src/interfaces'

export interface IGetCellsListParams extends IPaginationFilter {
  search?: string
}
export interface GetCellsResult extends ApiReqResult, PaginationResult {
  cells: ICellPlace[]
}

export const useGetCellsList = (
  params: IGetCellsListParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<GetCellsResult>(
    ['getSellsList', params],
    async () => {
      const searchParams = convertObjectToURLParams(params)
      const result = await api.get(`/warehouse/cell${searchParams}`)

      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}
