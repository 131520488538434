import React from 'react'
import { IServiceDocumentsItem, IServices, ITableColumn, ITableRow } from 'src/interfaces'

export const generateServicesColumns = (): ITableColumn[] => {
  return [
    {
      key: 'code',
      title: 'ID PAS',
      title_txt: 'ID PAS',
    },
    {
      key: 'name',
      title: 'Наименование',
      title_txt: 'Наименование',
      gridCellWidth: '2fr',
    },
    {
      key: 'measure',
      title: 'Ед. измерения',
      title_txt: 'Ед. измерения',
    },
    {
      key: 'quantity',
      title: 'Количество',
      title_txt: 'Количество',
    },
  ]
}
export const generateServicesRows = (
  docs: IServiceDocumentsItem[],
  services: IServices[],
): ITableRow[] => {
  return docs.map((doc) => {
    const service = services.find((serv) => serv.id === doc.service_id)

    return {
      id: doc.id,
      measure: service?.measure,
      code: `${service?.code || ''}`,
      name: service?.title || '',
      quantity: doc.quantity,
    }
  })
}
