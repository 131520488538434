import { useMutation } from 'react-query'
import api from '@shared/api'

export interface TareGenerateProps {
  // Кол-во тар
  count: number
}

// response types
export interface GetFBSTareStickersResponseTypes {
  content: string
  printer: string
  errors: Error[]
  success: boolean
}

export interface Error {
  code: string
  message: string
}

const useGetFBSTareStickers = () => {
  const getTareStickers = async (props: TareGenerateProps) => {
    const result = await api.post(`/packing/pallet/barcode-generate`, {
      count: props.count,
    })

    if (result?.status === 200) {
      return result.data as GetFBSTareStickersResponseTypes
    }
  }

  return useMutation('getTareStickers', getTareStickers)
}

export default useGetFBSTareStickers
