import { IMoving } from 'src/interfaces'
import { IMovingFormDataState } from '../interfaces'

export const SET_MOVING_RAW = 'SET_MOVING_RAW'
export const setMovingRawAction = (movingRaw: IMoving | null) => {
  return {
    type: SET_MOVING_RAW,
    payload: movingRaw,
  }
}
export const SET_MOVING_FORM_STATE_DRAFT = 'SET_MOVING_FORM_STATE_DRAFT'
export const setMovingFormDraftAction = (formState: IMovingFormDataState) => {
  return {
    type: SET_MOVING_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_MOVING_FORM_STATE_RAW = 'SET_MOVING_FORM_STATE_RAW'
export const setMovingFormRawAction = (formState: IMovingFormDataState) => {
  return {
    type: SET_MOVING_FORM_STATE_RAW,
    payload: formState,
  }
}
export const UPDATE_MOVING_FORM_STATE_DRAFT = 'UPDATE_MOVING_FORM_STATE_DRAFT'
export const updateMovingFormStateDraftAction = (
  key: keyof IMovingFormDataState,
  value: any,
) => {
  return {
    type: UPDATE_MOVING_FORM_STATE_DRAFT,
    payload: { key, value },
  }
}
export const UPDATE_MOVING_FORM_STATE_RAW = 'UPDATE_MOVING_FORM_STATE_RAW'
export const updateMovingFormStateRawAction = (
  key: keyof IMovingFormDataState,
  value: any,
) => {
  return {
    type: UPDATE_MOVING_FORM_STATE_RAW,
    payload: { key, value },
  }
}
