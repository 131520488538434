import { CommonSerialNumberFilled, IOrderPlaceItem, IPackageDto, ISku, ITableColumn, ITableRow } from 'src/interfaces'
import { NomenclatureCell, TitleCell } from '../../../../../components'
import React from 'react'
import styles from './documents-params.module.scss'
import { BarcodesCell } from 'src/components'

export const generateOrderPlaceColumns = (
  handleOpenModal: (sn: CommonSerialNumberFilled[]) => void,
): ITableColumn[] => {
  return [
    {
      key: 'name',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: '2fr',
      renderCell: ({ row }) => (
        <NomenclatureCell title={row.name} itemId={`${row.id}` || '-'} />
      ),
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
    },
    {
      key: 'barcodes_value',
      title: 'все ШК',
      title_txt: 'все ШК',
      gridCellWidth: '300px',
      renderCell: ({row}) => (
        <BarcodesCell barcodes={row.barcodes}/>
      )
    },
    {
      key: 'barcodeUsed',
      title: 'используемый ШК',
      title_txt: 'используемый ШК',
      gridCellWidth: '300px',
    },
    {
      key: 'un',
      title: 'Уникальные номера',
      title_txt: 'Уникальные номера',
      renderCell: ({ row }) => {
        return (
          <div
            className={styles.unicWrapper}
            onClick={(e) => {
              e.stopPropagation();
              const sn = row.serial_numbers as CommonSerialNumberFilled[]
              handleOpenModal(sn)
            }}
          >
            {
              row.serial_numbers.map(serial => (
                <div className={styles.serialItem}>
                  <b>{serial.title}</b>
                  <span>{serial.value}</span>
                </div>
              ))
            }
          </div>
        )
      },
    },
    {
      key: 'packaging',
      title: 'Упаковочный материал',
      title_txt: 'Упаковочный материал',
    },
  ]
}

export const generateOrderPlaceRows = (
  orderItems: IOrderPlaceItem[],
  originalItems: ISku[],
  packagings: IPackageDto[],
): ITableRow[] => {
  return orderItems.map((item) => {
    const originalItem = originalItems.find((oItem) => oItem.id === item.id)
    const un = new Set()

    item.serial_numbers?.forEach((sn) => {
      un.add(sn.title ? `${sn.title}:\n${sn.value}` : sn.code)
    })
    const packaging = item?.packagings
      ?.map((el) => {
        const element = packagings.find((pack) => pack.id === el.packaging_id)
        return ` ${element?.title}`
      })
      .join(';')

    return {
      id: item.id,
      name: (
        <TitleCell
          title={originalItem?.title || ''}
          article={originalItem?.article || ''}
        />
      ),
      barcodeUsed: item?.barcode_used,
      barcodes: originalItem?.barcodes,
      barcodes_value: originalItem?.barcodes?.map(el => `  ${el.barcode}`),
      quantity: item.quantity,
      un: Array.from(un).join(', ') || '-',
      packaging: packaging || '-',
      serial_numbers: item.serial_numbers,
    }
  })
}
