import React, { useMemo, useState } from 'react'
import styles from './documents-params.module.scss'
import { BatchDocsPrint, Button, ConfirmActionModal, InputWrapper } from 'src/components'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { getListPageByFormPage, OperAndDocumPagesType } from '../../../../../pagesType'
import { generateOrdersColumns, generateOrdersRows } from './utils'
import { DocumentTypeEnum } from 'src/interfaces'
import { DocumentsParams, DocumentStatusField } from '../../../../../components'
import { setBatchRawAction, updateFormStateDraftAction } from '../../../../../store/actions'
import { useBatchFormContext } from '@pages/OperationsAndDocumentsPage/context/BatchFormContext'
import { useDeleteOrderBatch, useQueryGetOrderBatch } from 'src/hooks'
import { usePrintBatchDocs } from '@shared/hooks'
import { useAppContext } from '@shared/providers/AppContextProvider'
import { IconTrash } from '@consta/icons/IconTrash'
import { PRIVATE_PATHS } from '@shared/routing'
import useEditOrderBatch from '@/hooks/packing/useEditOrderBatch'

interface SupplyFormContentProps {

}

const DocumentsParamsFields = (props: SupplyFormContentProps) => {
  const { page, id: documentId } = useParams<{ page: OperAndDocumPagesType, id: string, tabId: string }>()
  const { state, dispatch } = useBatchFormContext()
  const { setGlobalLoading } = useAppContext()
  const history = useHistory()

  const { printBatchDocs } = usePrintBatchDocs()

  // включает/выключает запрос с подбором польностью
  const [fullCompleteActivation, setFullCompleteActivation] = useState<boolean>(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false)
  const [editOrdersModalVisible, setEditOrdersModalVisible] = useState<boolean>(false)

  const [checkedOrders, setCheckedOrders] = useState<string[]>([])

  const { isLoading: deleteOrderBatchLoading, mutateAsync: deleteOrderBatch } = useDeleteOrderBatch();
  const { fetch: editOrderBatch} = useEditOrderBatch();
  const { refetch: batchRefetch } = useQueryGetOrderBatch(
    { batchId: documentId },
    {
      onSuccess: (data) => dispatch(setBatchRawAction(data?.batch || null)),
      enabled: false
    },
  )

  const handlePrint = async (isPreview: boolean, formsSelections: string[]) => {
    await printBatchDocs(
      [state.documentRaw.id],
      formsSelections.includes('pickingList'),
      fullCompleteActivation,
      formsSelections.includes('invoice'),
      isPreview,
      checkedOrders
    )
    await batchRefetch()
  }

  const ordersRows = useMemo(() => generateOrdersRows(state.documentRaw?.orders || []), [state.documentRaw?.orders])
  const ordersColumns = useMemo(() => generateOrdersColumns(checkedOrders, setCheckedOrders, ordersRows), [ordersRows, checkedOrders])

  const deleteOrderBatchHandler = (orderNumbers: string[]) => {
    for (let i = 0; i < orderNumbers.length; i++) {
      editOrderBatch({ id: orderNumbers[i]})
        .then(() => {
        })
        .catch(() => {})
    }


    setEditOrdersModalVisible(false)
    const listPage = getListPageByFormPage(page)
    history?.push(
      generatePath(PRIVATE_PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: listPage,
      }),
    )
  }

  const disbandBatchHandler = async () => {
    await deleteOrderBatch(documentId)
      .then(() => {
        const listPage = getListPageByFormPage(page)
        history?.push(generatePath(PRIVATE_PATHS.OPERATIONS_AND_DOCUMENTS, {page: listPage}))
      })
      .catch(() => {})
    setConfirmModalVisible(false)
  }

  return (
    <DocumentsParams
      withNumbering={false}
      rows={ordersRows}
      columns={ordersColumns}
      tableTitle={'Заказы'}
      tableExtraHeader={<>
        <div></div>
        <div className={styles.printButton}>
          <BatchDocsPrint
            handlePrint={handlePrint}
            fullCompleteActivation={fullCompleteActivation}
            setFullCompleteActivation={setFullCompleteActivation}
            size={'s'}
          />
          <Button
            iconLeft={IconTrash}
            label={'Расформировать батч'}
            size={'s'}
            view={'ghost'}
            onClick={() => {
              setConfirmModalVisible(true)
            }}
          />
          <Button
            iconLeft={IconTrash}
            label={'Удалить выбранные заказы из батча'}
            size={'s'}
            view={'ghost'}
            onClick={() => {
              setEditOrdersModalVisible(true)
            }}
            disabled={checkedOrders.length === 0}
          />
        </div>
      </>}
    >
      <DocumentStatusField
        value={state.formStateDraft?.status}
        onChange={(optionValue) =>
          dispatch(updateFormStateDraftAction('status', optionValue?.value))
        }
        documentType={page as DocumentTypeEnum}
      />
      <InputWrapper
        id={'merchant'}
        label={'Организация'}
        value={state.documentRaw?.merchant?.title || null}
        className={styles.itemField}
        disabled={true}
      />
      <InputWrapper
        value={state.documentRaw?.id || documentId}
        id={'supplyId'}
        label={'Номер документа'}
        className={styles.itemField}
        disabled={true}
      />
      <InputWrapper
        value={
          `${state.documentRaw?.orders?.reduce((acc, order) => acc+order.files?.length, 0)}`
        }
        id={'files'}
        label={'Общее кол-во файлов'}
        className={styles.itemField}
        disabled={true}
      />
      {confirmModalVisible ? (
        <ConfirmActionModal
          isOpen={true}
          type="warning"
          withPreview={false}
          title={'Вы уверены, что хотите расформировать батч?'}
          size={'min'}
          confirmLoading={deleteOrderBatchLoading}
          confirmWithScan={false}
          onClose={() => setConfirmModalVisible(false)}
          onSubmit={disbandBatchHandler}
        />
      ) : null}
      {editOrdersModalVisible && (
        <ConfirmActionModal
          isOpen={true}
          type="warning"
          withPreview={false}
          title={'Вы уверены, что хотите удалить выбранные заказы?'}
          size={'min'}
          confirmWithScan={false}
          onClose={() => setEditOrdersModalVisible(false)}
          onSubmit={() => {
            deleteOrderBatchHandler(checkedOrders)
          }}
        />
      )}
    </DocumentsParams>
  )
}
export default DocumentsParamsFields
