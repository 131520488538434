import React, { useEffect } from 'react'
import { useGetInventoryDocument } from 'src/hooks/warehouse/inventory'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { FormWrapper } from '../../../components'
import { formStatesAreEqual } from '../../../store/utils'
import {
  revertDraftStateToRawAction,
  setCellsRawAction,
  setDocumentRawAction,
  setInventoryFormDraftAction,
  setInventoryFormRawAction,
  setSkusRawAction,
} from '../../../store/actions'
import {
  InventoryFormContextProvider,
  useInventoryFormContext,
} from '@pages/OperationsAndDocumentsPage/context/InventoryContext'
import { InventoryFormContent } from './components'
import { IInventoryFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces'
import { PATHS } from '@shared/routing'

const InventoryForm = () => {
  const history = useHistory()
  const { state, dispatch } = useInventoryFormContext()
  const { id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()

  const {
    data: inventoryData,
    isFetching: isInventoryLoading,
    refetch: refetchInventory,
  } = useGetInventoryDocument(
    { invenoryId: documentId },
    {
      onSuccess: ({ document, skus, cells }) => {
        dispatch(setDocumentRawAction(document))

        dispatch(setSkusRawAction(skus))

        dispatch(setCellsRawAction(cells))
      },
    },
  )

  useEffect(() => {
    const formData: IInventoryFormDataState = {
      status: inventoryData?.document.status || '',
      comment: inventoryData?.document?.warehouse_comment || '',
    }

    dispatch(setInventoryFormDraftAction(cloneDeep(formData)))
    dispatch(setInventoryFormRawAction(cloneDeep(formData)))
  }, [inventoryData])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.inventoryList,
      }),
    )
  }


  const statesAreEqual = formStatesAreEqual<IInventoryFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )

  return (
    <FormWrapper
      loading={isInventoryLoading}
      title={state.documentRaw?.id || documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <InventoryFormContent />
    </FormWrapper>
  )
}

const InventoryFormWithContext = () => (
  <InventoryFormContextProvider>
    <InventoryForm />
  </InventoryFormContextProvider>
)
export default InventoryFormWithContext
