export const VALUE_SEPARATOR = '|'

export const COPY_VALUE_POSTFIX = '_copy_value'
export const COMPONENT_POSTFIX = '_component'
export const SELECT_PREFIX = 'select__action'

export const IMAGE_POSTFIX = '_image'
export const ARTICLE_POSTFIX = '_article'
export const SKU_BARCODE_POSTFIX = '_barcode'

export const CREATE_PAGE_ID = 'create'

export const HEADER_ID = 'header'