import React, { useMemo } from 'react'

import { Modal, TableWithCopy } from 'src/components'
import { CommonSerialNumberFilled, ModalProps } from 'src/interfaces'

interface IProps extends ModalProps {
  serialNumbers: CommonSerialNumberFilled[]
}

const SerialNumbersModal = ({ isOpen, onClose, serialNumbers }: IProps) => {

  const { rows, columns } = useMemo(() => {
    return {
      rows: serialNumbers.map(serial => ({
        id: serial.value,
        title: serial.title,
        value: serial.value,
      })),
      columns: [
        {
          key: 'title',
          title: 'Название',
          title_txt: 'Название',
        },
        {
          key: 'value',
          title: 'Значение',
          title_txt: 'Значение',
        },
      ],
    }
  }, [serialNumbers, isOpen])

  return (
    <Modal
      withClose
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={onClose}
      title={'Серийные номера'}
      subtitle={'Выделите нужное значение и нажмите ctrl + c, чтобы скопировать'}
      size={'xl'}
    >
      <TableWithCopy
        rows={rows}
        columns={columns}
      />
    </Modal>
  )
}

export default SerialNumbersModal
