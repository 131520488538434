import React, { FC } from 'react'

import { ITableRow } from 'src/interfaces'
import { DownloadDocsTable } from '../../../../../components'
import { useShipmentContext } from '../../../../../context/ShipmentContext'

interface AdditionalTabProps {
  documentUUID?: string
}

const AdditionalTab: FC<AdditionalTabProps> = () => {
  const { state } = useShipmentContext()

  let rows: ITableRow[] = []

  if (state.documentRaw?.files) {
    rows = state.documentRaw?.files.map((file) => ({
      id: file.id,
      name: file.title,
    }))
  }

  const handleDownload = (filesKeys: string[]) => {
    if (!filesKeys.length) {
      return
    }

    const selectedDocsUrls = state.documentRaw?.files.map((file) => file.url) || []

    selectedDocsUrls.forEach((url) => window.open(url, '_blank'))

  }

  return (
    <div>
      <DownloadDocsTable
        title={'Документы: сканы прикрепленные'}
        tableRows={rows}
        handleClickDownload={handleDownload}
      />
    </div>
  )
}

AdditionalTab.displayName = 'AdditionalTab'

export default AdditionalTab
