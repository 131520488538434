import { getFieldsGeneratorByFieldType } from './getFieldsGeneratorByFieldType'
import { IField, IFieldValue } from '@shared/types/fields'
import { FieldRow, GenerateFieldType } from '@widgets/GeneratorFields'


const generateFieldsRow = (arr: GenerateFieldType[]): FieldRow[] => {

  let result = []
  let i = 0
  while (i < arr.length) {
    result.push({ fields: arr.slice(i, i + 4) })
    i += 4
  }
  return result
}


export const generateFields = (fields: IField[], fieldValues: IFieldValue[], editState: boolean): FieldRow[] => {
  const generatedFields: GenerateFieldType[] = (
    getFieldsGeneratorByFieldType(fields, fieldValues, editState)
    .filter((item) => item !== null)
  )

  return generateFieldsRow(generatedFields)
}