import { useMutation } from 'react-query'

import api from '@shared/api'


const useReturnPlaceComplete = () => {
  return useMutation(
    async (data: {returnId: string;  placeId: string}) => {
      const result = await api.post(`/return/${data.returnId}/place/${data.placeId}/complete`)
      /*if (!result?.data?.success) {
        throw new Error(result?.data?.message)
      }*/
      return result.data
    },
  )
}

export default useReturnPlaceComplete
