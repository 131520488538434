import React from 'react'

import { ServiceDocument } from 'src/interfaces'

import { BILLING_STATUS_BADGE_TYPE, BILLING_STATUSES } from '@/interfaces/statuses'

import { OperAndDocumPages } from '../../../../pagesType'

import { TableColumn } from '@consta/uikit/Table'
import { Badge } from '@consta/uikit/Badge'
import { DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'

export const generateRows = (docs: ServiceDocument[]) => {
  return docs.map((doc) => ({
    id: doc.id || '',
    date: doc.create_ts,
    date_value: days(doc.create_ts).format(DATE_TIME_VIEW),
    documentNum: doc.id,
    org: doc.merchant?.title || '-',
    status: doc.status || '-',
    status_value: BILLING_STATUSES[doc.status] || doc.status || '-',
    comment: doc.comment || '-',
  }))
}

export const generateColumns = (): TableColumn<any>[] => {
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{ minWidth: '120px' }} />
      },
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        return (
          <DocumentNumCell
            documentNum={row.documentNum}
            page={OperAndDocumPages.billingForm}
          />
        )
      },
    },
    {
      title: 'Организация',
      accessor: 'org',
    },
    {
      title: 'Статус',
      accessor: 'status_value',
      sortable: true,
      renderCell: ({row}) => {
        return (
          <Badge
            status={BILLING_STATUS_BADGE_TYPE[row.status]}
            label={BILLING_STATUSES[row.status]}
          />
        )
      },
    },
    {
      title: 'Комментарий',
      accessor: 'comment',
      renderCell: ({row}) => {
        return (
          <div style={{maxWidth: "300px"}}>
            {row.comment}
          </div>
        )
      }
    },
  ]
}
