import React from 'react'
import cx from 'classnames'
import styles from './informer.module.scss'

import { Timer } from '@shared/ui/Timer'
import { Button } from '@consta/uikit/Button'
import { IInformer } from 'src/interfaces'

interface IInformerProps extends IInformer {

}

const Informer = ({ status, onClose, text, className }: IInformerProps) => {
  const isError = status === 'error'
  const isSuccess = status === 'success'
  const isInfo = status === 'info'
  return (
    <div
      className={`
    ${cx(styles.informer, className)} 
    ${isError ? styles.informerError : ''}
    ${isSuccess ? styles.informerSuccess : ''}
    ${isInfo ? styles.informerInfo : ''}
    `}
    >
      <div className={styles.left}>
        <Timer size="m" handleComplete={onClose} />
        <div className={styles.text}>{text}</div>
      </div>
      <Button
        className={styles.btn}
        size="s"
        onClick={onClose}
        label="Закрыть"
        view="ghost"
      />
    </div>
  )
}

export default Informer
