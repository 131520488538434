import { useParams } from 'react-router-dom'
import { useHistoryPush } from './useHistoryPush'
import { OperAndDocumPagesType } from '../pagesType'
import { useFilterState } from '@shared/hooks'


export const useFilter = () => {
  const { page } = useParams<{ page: OperAndDocumPagesType }>()

  const { handleUpdateBreadcrumb } = useHistoryPush()

  return useFilterState({onChangeFilterState: () => handleUpdateBreadcrumb(page)})
}