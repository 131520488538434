import React, { useEffect, useMemo, useState } from 'react'
import { useGetDeliveriesList, useGetMerchantList } from 'src/hooks'
import { usePrintBatchDocs } from '@shared/hooks'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { DocumentsListWrapper } from '../../../components'
import useGetBatchList from '@/hooks/packing/useGetBatchList'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { BatchDocsPrint } from '@/components'
import { useFilter } from '@pages/OperationsAndDocumentsPage/hooks/useFilter'


const BatchesList = () => {
  const { printBatchDocs } = usePrintBatchDocs()

  const { filterState, setFilterState } = useFilter()

  const [batchSelectionsIds, setBatchSelectionsIds] = useState<string[]>([])
  const [fullCompleteActivation, setFullCompleteActivation] = useState<boolean>(false)

  const { data: merchantData } = useGetMerchantList({})
  const { data: deliveriesData } = useGetDeliveriesList()
  const {
    isFetching: batchesLoading,
    data: batchesData,
    refetch: refetchBatches,
  } = useGetBatchList({...generateFilterParams(filterState), __front__light: true  })

  useEffect(() => {
    refetchBatches()
  }, [filterState])

  const rows = useMemo(() =>
      generateRows(batchesData?.batches || [], deliveriesData?.values || [])
    , [batchesData?.batches, deliveriesData?.values])

  const allItemsIds = batchesData?.batches.map(batch => batch.id)
  const columns = useMemo(() => generateColumns(
    allItemsIds,
    batchSelectionsIds,
    setBatchSelectionsIds,
  ), [allItemsIds, batchSelectionsIds, setBatchSelectionsIds])
  const filtersConfig = generateFilters(deliveriesData?.values || [])

  const handlePrint = async (isPreview: boolean, formsSelections: string[]) => {
    await printBatchDocs(
      batchSelectionsIds,
      formsSelections.includes('pickingList'),
      fullCompleteActivation,
      formsSelections.includes('invoice'),
      isPreview,
    )
    refetchBatches()
  }

  return (
    <DocumentsListWrapper
      title={'Документы батч отгрузки'}
      rows={rows}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      filtersConfig={filtersConfig}
      loading={batchesLoading}
      pagination={batchesData?.pagination}
      tableExtraHeaderRight={
        <BatchDocsPrint
          handlePrint={handlePrint}
          fullCompleteActivation={fullCompleteActivation}
          setFullCompleteActivation={setFullCompleteActivation}
        />
      }
    />
  )
}

export default BatchesList
