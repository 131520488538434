import { IPrintOptions, usePrint } from './print'
import { usePreviewBase64Pdf } from '../usePreviewBase64Pdf'
import { useGetFragileSticker } from '@/hooks'


/** Печать стикера товара */
interface printResult {
  printFragileSticker: (orderId?: string, type?: 'sku' | 'place', isPreview?: boolean, options?: IPrintOptions) => Promise<void>
}

export const usePrintFragileSticker = (): printResult => {
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const { printSticker } = usePrint()

  const getFragileSticker = useGetFragileSticker()

  const printFragileSticker = async (orderId?: string, type?: 'sku' | 'place', isPreview?: boolean, options: IPrintOptions = {}) => {
     await getFragileSticker
       .fetch({ orderId, type })
       .then((result) => {
         if (!result.success) {
          return
        }
        const { sticker, stickerExists } = result
        if (!sticker) {
          console.error('Ошибка получения стикера')
          return
        }

        if (isPreview) {
          previewBase64Pdf(sticker.content, 'Стикер хрупкое')
          return
        }
        printSticker(sticker.printer, sticker.content, {
          copies: 1,
          stickerExists,
          ...options
        })
       })
       .catch(() => {

       });
  }

  return {
    printFragileSticker,
  }
}