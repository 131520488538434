import React from 'react'
import { ComboboxWrapperWithApi } from '@/components'
import { useGetMerchantList } from '@/hooks'
import { IMerchant } from '@/interfaces'
import { CommonFieldProps } from '@shared/types/fields'

interface IProps extends CommonFieldProps  {
  convertHookData?: (data: { merchants: IMerchant[] }) => any
}

const ComboboxMerchants = (props: IProps) => {
  const {
    label = 'Организация',
    placeholder = 'Выберите организацию/продавца',
    convertHookData = (data) => {
      return data.merchants.map((el) => ({
        label: el.title,
        id: el.id,
        value: el.id,
        dto: el,
      }))
    },
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      label={label}
      placeholder={placeholder}
      useGetHookSearch={useGetMerchantList}
      convertHookData={convertHookData}
      {...restProps}
    />
  )
}
export default ComboboxMerchants
