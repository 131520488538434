import api from '@shared/api'
import { useMutation } from 'react-query'
import { provideMock } from 'src/mocks'
import { ApiReqResult } from 'src/interfaces'

/**
 * Завершить приёмку тар и отправить их на размещение
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Postavki/operation/completeReceipt
 */

interface useCompleteSupplyPartsParamsType {
  supplyId: string
}

const useCompleteSupplyParts = (params: useCompleteSupplyPartsParamsType) => {
  return useMutation(async (receipts: string[]) => {
    try {
      const result = await provideMock<
        useCompleteSupplyPartsParamsType,
        { data: ApiReqResult }
      >(
        async () =>
          await api.post(`/receiving/supply/${params.supplyId}/part/complete`, {
            receipts,
          }),
        params,
        {
          key: 'supplyId',
          value: 'mock',
          result: {
            data: {
              success: true,
            },
          },
        },
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result.data
    } catch (e) {
      throw e
    }
  })
}

export default useCompleteSupplyParts
