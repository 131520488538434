import { useMutation } from 'react-query'
import api from '@shared/api'

import { ICellPlace } from 'src/interfaces'

export const useUpdateCells = () => {
  const updateCells = async (params: ICellPlace[]) => {
    const result = await api.post('/warehouse/cell', params)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(updateCells)
}
