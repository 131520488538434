import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { orderPlaceInitialState, orderPlaceReducer } from '../store/reducer'
import { IOrderPlaceFormState } from '../store/interfaces'

export interface IUseReducer {
  state: IOrderPlaceFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {
}

export const OrderPlaceFormContext = createContext<IContext>({
  state: orderPlaceInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const OrderPlaceFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof orderPlaceInitialState, IReducerAction>>(orderPlaceReducer, orderPlaceInitialState)

  useEffect(() => {
    console.log('--- OrderPlace State ---', state)
  }, [state])

  return (
    <OrderPlaceFormContext.Provider value={{ state, dispatch }}>
      {children}
    </OrderPlaceFormContext.Provider>
  )
}

export const useOrderPlaceFormContext = () => useContext(OrderPlaceFormContext)
