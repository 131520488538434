import { TemplateFieldType } from '../types/tableGenerator/generatorTypes'
import { getCalendarValueFormatByFieldType } from './calendar'

export const generateFilterParamsModified = (
  valuesState: Record<string, any>,
  fields: any[],
) => {
  let result = []
  fields.forEach((field) => {
    const searchId = 'search'
    if (field.type === TemplateFieldType.SEARCH && valuesState[searchId]) {
      result.push({
        id: field.id,
        value: valuesState[searchId]?.trim(),
      })
      return
    }

    if (!valuesState[field.id]) return

    const multiSelectsType = [
      TemplateFieldType.SKUS,
      TemplateFieldType.CELLS,
      TemplateFieldType.MERCHANTS,
      TemplateFieldType.CONTRACTORS,
      TemplateFieldType.MULTISELECT,
    ].includes(field.type)

    const singleSelectsType = [
      TemplateFieldType.SKU,
      TemplateFieldType.CELL,
      TemplateFieldType.MERCHANT,
      TemplateFieldType.CONTRACTOR,
      TemplateFieldType.SELECT,
    ].includes(field.type)

    const dateType = [
      TemplateFieldType.TIME,
      TemplateFieldType.DATE,
      TemplateFieldType.DATETIME,
      TemplateFieldType.YEARMONTH
    ].includes(field.type)

    switch(true) {
      case multiSelectsType:
        result.push({
          id: field.id,
          values: valuesState[field.id].map((item) => item.id),
        })
        break
      case singleSelectsType:
        result.push({
          id: field.id,
          value: valuesState[field.id].id,
        })
        break
      case [TemplateFieldType.TEXT, TemplateFieldType.NUMBER].includes(field.type):
        result.push({
          id: field.id,
          value: field.type === TemplateFieldType.NUMBER
            ? Number(valuesState[field.id])
            : valuesState[field.id],
        })
        break
      case [TemplateFieldType.BOOLEAN].includes(field.type):
        result.push({
          id: field.id,
          value: valuesState[field.id]?.id || valuesState[field.id],
        })
        break
      case dateType:
        result.push({
          id: field.id,
          value: getCalendarValueFormatByFieldType(field.type, valuesState[field.id]),
        })
        break
    }
  })

  return result
}