import { useQuery } from 'react-query'
import api from '@shared/api'
import { ApiReqResult, PaginationResult } from 'src/interfaces'
import { ITransportaion } from 'src/interfaces/transportationTemplates'
import { UseQueryOptions } from 'react-query/types/react/types'

// http://wms-test.kak2c.ru/warehouse-api/transportation-item-template/{template_id}

export interface IGetTransportationParams {
  template_id: string
}

export interface IGetTransportationResult
  extends ApiReqResult,
    PaginationResult {
  template: ITransportaion
}

const useGetTransportationTemplate = (
  params: IGetTransportationParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IGetTransportationResult>(
    [`getTransportationTemplate`, params],
    async () => {
      const result = await api.get(
        `/transportation-item-template/${params.template_id}`,
      )

      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}
export default useGetTransportationTemplate
