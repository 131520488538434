import { useMutation } from 'react-query'
import api from '@shared/api'

interface IParams {
  id: string
  file: File
}

const useSendUserSignature = () => {
  const sendSignature = async (params: IParams) => {
    const result = await api.post(
      `/warehouse/user/${params.id}/signature`,
      params.file,
    )

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(sendSignature)
}

export default useSendUserSignature
