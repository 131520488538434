import React, { useEffect, useMemo, useState } from 'react'
import { InputWrapper } from 'src/components'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { useTemplateFormContext } from '@pages/OperationsAndDocumentsPage/context/TemplateContext'
import { generateColumns, generateRows } from './utils'
import { IWarehouseAdress } from 'src/interfaces/transportationTemplates'
import { TEMPLATES_PLACE_STATUS, TEMPLATES_STATUS } from '@/interfaces/statuses'
import styles from './DocumentsParamsFields.module.scss'
import { getDimensionStr } from '@shared/helpers'
import dayjs from 'dayjs'

interface TemplateFormContentProps {}

const DocumentsParamsFields = (props: TemplateFormContentProps) => {
  const { page, id: templateId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()
  const { state } = useTemplateFormContext()
  const [adress, setAdress] = useState<IWarehouseAdress | null>(null)
  const [measure, setMeasure] = useState<string[]>([])

  useEffect(() => {
    if (!state.documentRaw?.place.dimensions) {
      return
    }
    const measure = {
      width: state.documentRaw?.place.dimensions?.width || 0,
      height: state.documentRaw?.place.dimensions?.height || 0,
      depth: state.documentRaw?.place.dimensions?.depth || 0,
    }
    const convert = getDimensionStr(measure, { from: 'mm', to: 'sm' }).split(
      'x',
    )
    setMeasure(convert)

    console.log(convert)
  }, [state.documentRaw?.place.dimensions])

  const rows = useMemo(
    () => generateRows(state.documentRaw),
    [state.documentRaw],
  )

  const columns = useMemo(() => generateColumns(), [])
  useEffect(() => {
    if (!state.documentRaw?.external_warehouse_address) {
      return
    }
    setAdress(state.documentRaw?.external_warehouse_address)
  }, [state.documentRaw?.external_warehouse_address])

  return (
    <div className={styles.inputWrapper}>
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.contractor?.title || null}
        id={'contractor'}
        label={'Контрагент'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.contractor?.phone || null}
        id={'phone'}
        label={'Телефон'}
        disabled
      />

      <InputWrapper
        className={styles.inputCurrent}
        value={
          TEMPLATES_STATUS[state.documentRaw?.transportation_type || ''] || null
        }
        id={'transportation_type'}
        label={'Тип перевозки'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.external_warehouse_address?.zip || null}
        id={'zip'}
        label={'Индекс'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.external_warehouse_address?.city || null}
        id={'city'}
        label={'Город'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.external_warehouse_address?.region || null}
        id={'region'}
        label={'Область'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.external_warehouse_address?.area || null}
        id={'area'}
        label={'Район в области'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={
          state.documentRaw?.external_warehouse_address?.settlement || null
        }
        id={'settlement'}
        label={'Населенный пункт'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.external_warehouse_address?.street || null}
        id={'street'}
        label={'Улица'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.external_warehouse_address?.house || null}
        id={'house'}
        label={'Дом'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.external_warehouse_address?.block || null}
        id={'block'}
        label={'Корпус'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.external_warehouse_address?.building || null}
        id={'building'}
        label={'Строение'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.external_warehouse_address?.flat || null}
        id={'flat'}
        label={'Квартира'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={
          `${state.documentRaw?.external_warehouse_address?.floor}` || null
        }
        id={'floor'}
        label={'Этаж'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={state.documentRaw?.order_prefix || null}
        id={'order_prefix'}
        label={'Наименование для выгрузки'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={
          TEMPLATES_PLACE_STATUS[state.documentRaw?.place.type || ''] || null
        }
        id={'type'}
        label={'Тип'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={`${measure[2]}` || null}
        id={'depth'}
        label={'Длина см.'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={`${measure[0]}` || null}
        id={'width'}
        label={'Ширина см.'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={`${measure[1]}` || null}
        id={'height'}
        label={'Высота см.'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={
          dayjs(
            `${state?.documentRaw?.delivery_from}`,
            'HH:mm:ss.SSSSSS',
          ).format('HH:mm:ss') || null
        }
        id={'delivery_from'}
        label={'Время доставки с'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={
          dayjs(`${state?.documentRaw?.delivery_to}`, 'HH:mm:ss.SSSSSS').format(
            'HH:mm:ss',
          ) || null
        }
        id={'delivery_to'}
        label={'Время доставки по'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={
          dayjs(`${state?.documentRaw?.pickup_from}`, 'HH:mm:ss.SSSSSS').format(
            'HH:mm:ss',
          ) || null
        }
        id={'pickup_from'}
        label={'Время самовывоз с'}
        disabled
      />
      <InputWrapper
        className={styles.inputCurrent}
        value={
          dayjs(`${state?.documentRaw?.pickup_to}`, 'HH:mm:ss.SSSSSS').format(
            'HH:mm:ss',
          ) || null
        }
        id={'pickup_to'}
        label={'Время самовывоз по'}
        disabled
      />
    </div>
  )
}
export default DocumentsParamsFields
