import api from '@shared/api'
import { convertObjectToURLParams } from '@shared/helpers'
import { StickerResult } from '../../interfaces'

interface IParams {
  sku_id: string, /* идентификатор товара */
  defect_id: string, /* идентификатор причины брака */
  doc_id: string, /* номер поставки */
  disable_billing?: boolean /* Не учитывать в билинге */
}

interface IResult extends StickerResult {

}

const useGetDefectSticker = () => ({
  fetch: async (params: IParams) => {
    const paramsStr = convertObjectToURLParams(params)
    const result = await api.get(`/items/defect-sticker${paramsStr}`)
    if (!result.data.success) {
      throw new Error(result.data.message)
    }
    return result.data as IResult
  }
})

export default useGetDefectSticker
