import React, { FC } from 'react'

import ReturnList from './ReturnList/ReturnList'
import ReturnForm from './ReturnForm/ReturnForm'

import { ReturnContextProvider } from '../../context/ReturnContext'

interface ReturnProps {
  type?: 'list' | 'form'
}

const Return: FC<ReturnProps> = ({
  type = 'list'
}) => {
  return (
    <ReturnContextProvider>
      {
        type === 'list'
          ? (<ReturnList />)
          : (<ReturnForm />)
      }
    </ReturnContextProvider>
  )
}

Return.displayName = 'Return'

export default Return
