import api from '@shared/api'
import { ApiReqResult, IPicking, ISku, IWarehouseCell } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { useQuery } from 'react-query'
import { GET_PICKING_BY_ID, provideMock } from 'src/mocks'

interface IParams {
  docId: string
}
interface IResult extends ApiReqResult {
  picking: IPicking
  cells: IWarehouseCell[]
  skus: ISku[]
}

const getPickingById = async (docId: string) => {
  const result = await api.get(`/picking/${docId}`)
  if (!result.data?.success) {
    throw result.data
  }
  return result.data as IResult
}

export const useGetPickingById = () => ({
  fetch: (docId: string) => getPickingById(docId),
})


export const useGetQueryPickingById = (
  params: IParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IResult>(
    [`getPickingById`],
    provideMock<IParams, IResult>(
    async () => {
      const result = await api.get(`/picking/${params.docId}`)
      return result.data
    },
      params,
      [
        {
        key: 'docId',
        value: 'mock',
        result: GET_PICKING_BY_ID,
      },
    ]),{
      keepPreviousData: true,
      ...options
    },
  )
}
