import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'

import { billingInitialState, billingReducer } from '../store/reducer/billingReducer'

import { IBillingFormState } from '../store/interfaces'

export interface IUseReducer {
  state: IBillingFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {
}

export const BillingContext = createContext<IContext>({
  state: billingInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const BillingContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof billingInitialState, IReducerAction>>(billingReducer, billingInitialState)

  useEffect(() => {
    console.log('--- Billing State ---', state)
  }, [state])

  return (
    <BillingContext.Provider value={{ state, dispatch }}>
      {children}
    </BillingContext.Provider>
  )
}

export const useBillingContext = () => useContext(BillingContext)
