import React, { useEffect, useMemo, useState } from 'react'
import styles from './UploadDocuments.module.scss'
import { useGetSupplyById } from 'src/hooks'
import { OperAndDocumPagesType } from '@pages/OperationsAndDocumentsPage'
import { useParams } from 'react-router-dom'
import { IFile, IUploadFile } from 'src/interfaces'
import { useAddFilesToSupplyPlan, useDeleteSupplyFile } from 'src/hooks/supplyPlan'
import { convertFileToBase64 } from '@shared/helpers'
import { FileType } from 'src/hooks/supplyPlan/useAddFilesToSupplyPlan'
import { Button, ConfirmActionModal, TableV2 } from 'src/components'
import { FileField } from '@consta/uikit/FileField'
import { IconAttach } from '@consta/icons/IconAttach'
import { generateColumns, generateRows } from './utils'

const UploadDocuments = () => {
  const [files, setFiles] = useState<IFile[]>([])
  const [fileId, setFileId] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const { id: supplyId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()

  const {
    data: SupplyData,
    isFetching: isSupplyLoading,
    refetch: refetchSupply,
  } = useGetSupplyById({
    supplyId,
  })

  const { mutateAsync: deleteSupplyFile } = useDeleteSupplyFile()

  const supply = SupplyData?.supply
  const filesRaw = supply?.files || []

  const addFilesToSupply = useAddFilesToSupplyPlan(
    { supplyId: supplyId },
    (results) => refetchSupply(),
  )

  useEffect(() => {
    setFiles(filesRaw)
  }, [filesRaw])

  const onDeleteFile = (id: string) => {
    setFileId(id)
    setOpenModal(true)
  }

  const columns = useMemo(() => generateColumns(onDeleteFile), [])
  const rows = useMemo(() => generateRows(files), [files])

  const handleClickUploadFiles = async (files: File[]) => {
    const filesServerFormat = files.map(async (file): Promise<IUploadFile> => {
      const base64File = await convertFileToBase64(file)
      return {
        data: base64File,
        title: file.name,
        type: file.type.includes('image/') ? FileType.photo : FileType.document,
      }
    })
    Promise.all(filesServerFormat)
      .then((res) => {
        if (res) {
          addFilesToSupply.mutateAsync(res).then(() => {
            refetchSupply()
          })
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <>
      <div className={styles.main}>
        <TableV2
          fullHeight
          theme={'gray'}
          extraHeader={
            <div className={styles.tableHeader}>
              <FileField
                multiple
                id={'files'}
                accept="image/*,.pdf"
                onChange={(e) => {
                  //@ts-ignore
                  const filesList = e.target?.files
                  if (!filesList) {
                    console.error('Файл отсутствует')
                    return
                  }
                  const files = Array.from(filesList) as File[]
                  handleClickUploadFiles(files)
                }}
              >
                {(props) => (
                  <Button
                    {...props}
                    label="Загрузить сканы"
                    width={'full'}
                    view="primary"
                    loading={addFilesToSupply.isLoading}
                    iconLeft={IconAttach}
                  />
                )}
              </FileField>
            </div>
          }
          rows={rows}
          columns={columns}
          title={'Документы'}
          titleClassName={styles.tableTitle}
          className={styles.tableWrap}
          maxHeightBody={315}
        />
      </div>
      {openModal ? (
        <ConfirmActionModal
          isOpen={true}
          type="warning"
          withPreview={false}
          title={'Вы уверены, что хотите удалить файл?'}
          size={'min'}
          confirmWithScan={false}
          onClose={() => {
            setFileId('')
            setOpenModal(false)
          }}
          onSubmit={() => {
            const data = {
              supplyId: supplyId,
              fileId: fileId,
            }
            deleteSupplyFile(data)
              .then(async () => {
                setFileId('')
                setOpenModal(false)
                refetchSupply()
              })
              .catch(() => {})
          }}
        />
      ) : null}
    </>
  )
}
export default UploadDocuments
