import React, { useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { PATHS } from '@shared/routing'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'

import { formStatesAreEqual } from '../../../store/utils'
import { revertDraftStateToRawAction } from '../../../store/actions'

import { FormWrapper } from '../../../components'
import { ReplaceFormContent } from './components'

import { setReplaceRawAction } from '@pages/OperationsAndDocumentsPage/store/actions/replace'
import { useReplaceContext } from '../../../context/ReplaceContext'

import { IOrderFormDataState } from '../../../store/interfaces/order'
import { useGetReplaceById } from 'src/hooks'
import { DATE_TIME_VIEW } from '@shared/const/date'


const ReplaceForm = () => {
  const history = useHistory()
  const { state, dispatch } = useReplaceContext()

  const { id: documentId } = useParams<{ page: OperAndDocumPagesType, id: string }>()
  const { isLoading, data: replaceData } = useGetReplaceById(documentId)

  useEffect(() => {
    if (!replaceData) {return}
    dispatch(setReplaceRawAction(replaceData))
  }, [replaceData])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
      page: OperAndDocumPages.replaceList,
    }))
  }



  const statesAreEqual = formStatesAreEqual<IOrderFormDataState | null>(state.formStateDraft, state.formStateRaw)

  return (
    <FormWrapper
      loading={isLoading}
      title={state.documentRaw?.document.id || documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw?.document.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <ReplaceFormContent />
    </FormWrapper>
  )
}

export default ReplaceForm
