import { IBreadcrumbItem } from '../interfaces'


export const PUSH_MAIN_BREADCRUMB_ITEM = 'PUSH_MAIN_BREADCRUMB_ITEM'
export const pushBreadcrumb = (label: string, path: string) => {
  return {
    type: PUSH_MAIN_BREADCRUMB_ITEM,
    payload: { label, path } as IBreadcrumbItem,
  }
}

export const UPDATE_MAIN_BREADCRUMB_ITEM = 'UPDATE_MAIN_BREADCRUMB_ITEM'
export const updateBreadcrumb = (label: string, path: string) => {
  return {
    type: UPDATE_MAIN_BREADCRUMB_ITEM,
    payload: { label, path } as IBreadcrumbItem,
  }
}
