import React from 'react'

import { IOrder } from 'src/interfaces'
import { TableColumn } from '@consta/uikit/Table'
import { OperAndDocumPages } from '../../../../pagesType'
import { DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'

export const generateRows = (orders: IOrder[]) => {
  return orders.map(order => ({
    id: order.id,
    date: order.create_ts,
    date_value: days(order.create_ts).format(DATE_TIME_VIEW),
    documentNum: order.id,
  }))
}

export const generateColumns = (): TableColumn<any>[] => {

  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{minWidth: '120px'}}/>
      }
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        return <DocumentNumCell documentNum={row.documentNum} page={OperAndDocumPages.orderPlaceForm} />
      }
    },
    {
      title: 'ID места',
      sortable: true,
      accessor: 'spaceId',
    },
  ]
}


