import { CHZ_CODE } from '@shared/helpers/chz'

export const MOCK_GET_SUPPLY_BY_ID: any = {
  'errors': [],
  'skus': [
    ...[
    {
        "article": "DRForte",
        "barcodes": [
            {
                "active": true,
                "barcode": "4627102710543",
                "type": "COMMON",
                "used_in_last_supply": true
            }
        ],
        "batch_accounting": true,
        "batch_expdate_mandatory": true,
        "batches": [
            {
                "exp_date": "2027-10-27",
                "id": "75137bd1-ff0e-48be-8715-1ffd10a1fbe3",
                "num": "1056 27.10.2027"
            },
            {
                "exp_date": "2026-12-01",
                "id": "2f64426e-48bc-44df-a47d-063fb9404aa7",
                "num": "1056 01.12.2026"
            },
            {
                "exp_date": "2026-06-01",
                "id": "a9c2ba89-0e39-4167-ae06-0f0fff606981",
                "num": "DRForte 01.06.2026 0:00:00 до 01.06.26 | Dry RU Forte / Драй РУ Форте, 50 мл. – дезодорант-антиперспирант для чувствительной кожи"
            }
        ],
        "country_of_origin": "RU",
        "dimensions_fact": {
            "depth": 130,
            "height": 130,
            "width": 50
        },
        "dimensions_plan": {
            "depth": 50,
            "height": 130,
            "width": 50
        },
        "external_id": "1056",
        "fragile": false,
        "id": "1U-1056",
        "image": "https://storage.yandexcloud.net/images-k2c/dd5d9004-153c-4485-91c2-85f4a5c2604a.png",
        "image_preview": "https://storage.yandexcloud.net/images-k2c/dd5d9004-153c-4485-91c2-85f4a5c2604a-100.png",
        "images": [
            {
                "position": 0,
                "preview_url": "https://storage.yandexcloud.net/images-k2c/dd5d9004-153c-4485-91c2-85f4a5c2604a-100.png",
                "url": "https://storage.yandexcloud.net/images-k2c/dd5d9004-153c-4485-91c2-85f4a5c2604a.png"
            }
        ],
        "merchant": {
            "cam_id": "33846",
            "contract_date": "2023-01-30",
            "contract_num": "КО-ОП- 30/01/2023-245 ",
            "enabled": true,
            "id": "85060c40-419a-46f3-adb0-d6ea91799f41",
            "inn": "5018112138",
            "internal_title": "Сканди Лайн",
            "kpp": "501811213",
            "login": "galinal1207@yandex.ru",
            "ogrn": "5018112138",
            "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
            "title": "Сканди Лайн",
            "trade_title": "Сканди Лайн"
        },
        "parts": [],
        "require_dimensions": false,
        "require_weight": false,
        "scan_serial_numbers": [],
        "title": "Dry RU Forte / Драй РУ Форте, 50 мл. – дезодорант-антиперспирант для чувствительной кожи",
        "type": "SKU",
        "weight_fact": 85,
        "weight_plan": 1000
    },
    {
        "article": "DRSolidSLCA",
        "barcodes": [
            {
                "active": true,
                "barcode": "2038074150954",
                "type": "COMMON",
                "used_in_last_supply": true
            }
        ],
        "batch_accounting": true,
        "batch_expdate_mandatory": false,
        "batches": [
            {
                "id": "545254ca-b492-4b59-9514-ff03c9c58da2",
                "num": "27.04.2023"
            },
            {
                "exp_date": "2026-06-01",
                "id": "cf731e5c-e205-40d9-b562-0f914ed8957d",
                "num": "DRSolidSLCA 01.06.2026 0:00:00 до 01.06.26 | твердый шампунь и кондиционер для сухих волос"
            },
            {
                "exp_date": "2028-10-01",
                "id": "630b094f-3656-4d22-b83e-bd940cf46f6b",
                "num": "DRSolidSLCA 01.10.2028 0:00:00 до 01.10.28 | твердый шампунь и кондиционер для сухих волос"
            },
            {
                "id": "d4a50856-76a6-4455-845a-ff92fb6d34b8",
                "num": "27.04.2026"
            },
            {
                "exp_date": "2026-08-01",
                "id": "e44c53be-0384-4e2d-970a-9eb2d6b50798",
                "num": "DRSolidSLCA 01.08.2026 0:00:00 до 01.08.26 | твердый шампунь и кондиционер для сухих волос"
            }
        ],
        "country_of_origin": "RU",
        "dimensions_fact": {
            "depth": 200,
            "height": 100,
            "width": 110
        },
        "dimensions_plan": {
            "depth": 100,
            "height": 100,
            "width": 100
        },
        "external_id": "1074",
        "fragile": false,
        "id": "1U-1074",
        "image": "https://storage.yandexcloud.net/images-k2c/6660ce48-e109-48a0-9f44-3675a7b7c858.png",
        "image_preview": "https://storage.yandexcloud.net/images-k2c/6660ce48-e109-48a0-9f44-3675a7b7c858-100.png",
        "images": [
            {
                "position": 0,
                "preview_url": "https://storage.yandexcloud.net/images-k2c/6660ce48-e109-48a0-9f44-3675a7b7c858-100.png",
                "url": "https://storage.yandexcloud.net/images-k2c/6660ce48-e109-48a0-9f44-3675a7b7c858.png"
            }
        ],
        "merchant": {
            "cam_id": "33846",
            "contract_date": "2023-01-30",
            "contract_num": "КО-ОП- 30/01/2023-245 ",
            "enabled": true,
            "id": "85060c40-419a-46f3-adb0-d6ea91799f41",
            "inn": "5018112138",
            "internal_title": "Сканди Лайн",
            "kpp": "501811213",
            "login": "galinal1207@yandex.ru",
            "ogrn": "5018112138",
            "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
            "title": "Сканди Лайн",
            "trade_title": "Сканди Лайн"
        },
        "parts": [
            {
                "optional": false,
                "quantity": 1,
                "sku_id": "1U-1073"
            },
            {
                "optional": false,
                "quantity": 1,
                "sku_id": "1U-1071"
            }
        ],
        "require_dimensions": false,
        "require_weight": false,
        "scan_serial_numbers": [],
        "title": "твердый шампунь и кондиционер для сухих волос",
        "type": "PHYSICAL_SET",
        "weight_fact": 175,
        "weight_plan": 150
    },
    {
        "article": "DRNoWaterShamp",
        "barcodes": [
            {
                "active": true,
                "barcode": "4627102710901",
                "type": "COMMON",
                "used_in_last_supply": true
            }
        ],
        "batch_accounting": true,
        "batch_expdate_mandatory": true,
        "batches": [
            {
                "exp_date": "2026-09-19",
                "id": "fe624aeb-5173-4f5d-9791-5b17d441bd40",
                "num": "DRNoWaterShamp 19.09.2026 0:00:00 до 19.09.26 | DRY RU No Water Shampoo сухой шампунь для всех типов волос"
            },
            {
                "exp_date": "2025-09-19",
                "id": "3a57af1b-a338-4822-b503-06e642919901",
                "num": "DRNoWaterShamp 19.09.2025 0:00:00 до 19.09.25 | DRY RU No Water Shampoo сухой шампунь для всех типов волос"
            }
        ],
        "dimensions_fact": {
            "depth": 50,
            "height": 235,
            "width": 50
        },
        "dimensions_plan": {
            "depth": 50,
            "height": 230,
            "width": 50
        },
        "external_id": "1085",
        "fragile": false,
        "id": "1U-1085",
        "image": "https://storage.yandexcloud.net/images-k2c/66d6db5f-7130-4549-b7ab-e455b8483063.jpg",
        "image_preview": "https://storage.yandexcloud.net/images-k2c/66d6db5f-7130-4549-b7ab-e455b8483063-100.jpg",
        "images": [
            {
                "position": 0,
                "preview_url": "https://storage.yandexcloud.net/images-k2c/66d6db5f-7130-4549-b7ab-e455b8483063-100.jpg",
                "url": "https://storage.yandexcloud.net/images-k2c/66d6db5f-7130-4549-b7ab-e455b8483063.jpg"
            }
        ],
        "merchant": {
            "cam_id": "33846",
            "contract_date": "2023-01-30",
            "contract_num": "КО-ОП- 30/01/2023-245 ",
            "enabled": true,
            "id": "85060c40-419a-46f3-adb0-d6ea91799f41",
            "inn": "5018112138",
            "internal_title": "Сканди Лайн",
            "kpp": "501811213",
            "login": "galinal1207@yandex.ru",
            "ogrn": "5018112138",
            "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
            "title": "Сканди Лайн",
            "trade_title": "Сканди Лайн"
        },
        "parts": [],
        "require_dimensions": false,
        "require_weight": false,
        "scan_serial_numbers": [],
        "title": "DRY RU No Water Shampoo сухой шампунь для всех типов волос",
        "type": "SKU",
        "weight_fact": 240,
        "weight_plan": 240
    },
    {
        "article": "DRActiveMan",
        "barcodes": [
            {
                "active": true,
                "barcode": "4627102710802",
                "type": "COMMON",
                "used_in_last_supply": true
            }
        ],
        "batch_accounting": true,
        "batch_expdate_mandatory": true,
        "batches": [
            {
                "id": "e0eee63f-e4eb-4ba0-a539-05dd48af7f19",
                "num": "31.08.2026"
            },
            {
                "exp_date": "2026-05-01",
                "id": "7f1149f0-e41f-4c00-8d94-56a4f52a6908",
                "num": "DRActiveMan 01.05.2026 0:00:00 до 01.05.26 | DRYRU Active Man (ДРАЙРУ Актив Мен) Антиперспирант с парфюмом для активных мужчин, аэрозоль 150 мл"
            },
            {
                "id": "f0c6b0b8-c28b-4a80-9e93-5ba69bed0183",
                "num": "31.08.26"
            },
            {
                "exp_date": "2026-02-27",
                "id": "ad3113a6-c963-4786-bb7e-c6deb32ab117",
                "num": "DRActiveMan 27.02.2026 0:00:00 до 27.02.26 | DRYRU Active Man (ДРАЙРУ Актив Мен) Антиперспирант с парфюмом для активных мужчин, аэрозоль 150 мл"
            },
            {
                "exp_date": "2026-01-27",
                "id": "0b8fe1fe-6917-4d96-be2c-53ea750ed3a7",
                "num": "DRActiveMan 27.01.2026 0:00:00 до 27.01.26 | DRYRU Active Man (ДРАЙРУ Актив Мен) Антиперспирант с парфюмом для активных мужчин, аэрозоль 150 мл"
            },
            {
                "id": "dfb5db30-5fd8-414b-a5b7-1ffc8bc992fb",
                "num": "27.01.2026"
            }
        ],
        "country_of_origin": "RU",
        "dimensions_fact": {
            "depth": 175,
            "height": 45,
            "width": 45
        },
        "dimensions_plan": {
            "depth": 50,
            "height": 180,
            "width": 50
        },
        "external_id": "1052",
        "fragile": false,
        "id": "1U-1052",
        "image": "https://storage.yandexcloud.net/images-k2c/a01da183-8f67-425f-a441-cd57301add95.JPG",
        "image_preview": "https://storage.yandexcloud.net/images-k2c/a01da183-8f67-425f-a441-cd57301add95-100.JPG",
        "images": [
            {
                "position": 0,
                "preview_url": "https://storage.yandexcloud.net/images-k2c/a01da183-8f67-425f-a441-cd57301add95-100.JPG",
                "url": "https://storage.yandexcloud.net/images-k2c/a01da183-8f67-425f-a441-cd57301add95.JPG"
            }
        ],
        "merchant": {
            "cam_id": "33846",
            "contract_date": "2023-01-30",
            "contract_num": "КО-ОП- 30/01/2023-245 ",
            "enabled": true,
            "id": "85060c40-419a-46f3-adb0-d6ea91799f41",
            "inn": "5018112138",
            "internal_title": "Сканди Лайн",
            "kpp": "501811213",
            "login": "galinal1207@yandex.ru",
            "ogrn": "5018112138",
            "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
            "title": "Сканди Лайн",
            "trade_title": "Сканди Лайн"
        },
        "parts": [],
        "require_dimensions": false,
        "require_weight": false,
        "scan_serial_numbers": [],
        "title": "DRYRU Active Man (ДРАЙРУ Актив Мен) Антиперспирант с парфюмом для активных мужчин, аэрозоль 150 мл",
        "type": "SKU",
        "weight_fact": 120,
        "weight_plan": 125
    },
    {
        "article": "DRSolidSXCK",
        "barcodes": [
            {
                "active": true,
                "barcode": "2038092161789",
                "type": "COMMON",
                "used_in_last_supply": false
            }
        ],
        "batch_accounting": true,
        "batch_expdate_mandatory": true,
        "batches": [
            {
                "exp_date": "2026-04-27",
                "id": "908301d0-efe4-4c4e-b309-ed53442ad67b",
                "num": "DRSolidSXCK|Годен до:27.04.2026 0:00:00 до 27.04.26 | твердый шампунь и кондиционер для жирных волос DRY RU Solid"
            }
        ],
        "dimensions_fact": {
            "depth": 200,
            "height": 100,
            "width": 100
        },
        "dimensions_plan": {
            "depth": 100,
            "height": 100,
            "width": 100
        },
        "external_id": "1076",
        "fragile": false,
        "id": "1U-1076",
        "image": "https://storage.yandexcloud.net/images-k2c/6c99ab0e-267e-46ba-917a-35e9cf7a83c7.png",
        "image_preview": "https://storage.yandexcloud.net/images-k2c/6c99ab0e-267e-46ba-917a-35e9cf7a83c7-100.png",
        "images": [
            {
                "position": 0,
                "preview_url": "https://storage.yandexcloud.net/images-k2c/6c99ab0e-267e-46ba-917a-35e9cf7a83c7-100.png",
                "url": "https://storage.yandexcloud.net/images-k2c/6c99ab0e-267e-46ba-917a-35e9cf7a83c7.png"
            }
        ],
        "merchant": {
            "cam_id": "33846",
            "contract_date": "2023-01-30",
            "contract_num": "КО-ОП- 30/01/2023-245 ",
            "enabled": true,
            "id": "85060c40-419a-46f3-adb0-d6ea91799f41",
            "inn": "5018112138",
            "internal_title": "Сканди Лайн",
            "kpp": "501811213",
            "login": "galinal1207@yandex.ru",
            "ogrn": "5018112138",
            "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
            "title": "Сканди Лайн",
            "trade_title": "Сканди Лайн"
        },
        "parts": [
            {
                "optional": false,
                "quantity": 1,
                "sku_id": "1U-1072"
            },
            {
                "optional": false,
                "quantity": 1,
                "sku_id": "1U-1070"
            }
        ],
        "require_dimensions": false,
        "require_weight": false,
        "scan_serial_numbers": [],
        "title": "твердый шампунь и кондиционер для жирных волос DRY RU Solid",
        "type": "PHYSICAL_SET",
        "weight_fact": 181,
        "weight_plan": 1000
    },
    {
        "article": "DRMineral",
        "barcodes": [
            {
                "active": true,
                "barcode": "4627102710512",
                "type": "COMMON",
                "used_in_last_supply": true
            }
        ],
        "batch_accounting": true,
        "batch_expdate_mandatory": true,
        "batches": [
            {
                "exp_date": "2024-03-01",
                "id": "d8fc9d50-745e-4713-a659-621a5b30d6c2",
                "num": "30324 01.03.2024"
            },
            {
                "exp_date": "2027-05-01",
                "id": "0fc8268d-845e-4bfc-b1e2-584a37c7afef",
                "num": "DRMineral 01.05.2027 0:00:00 до 01.05.27 | Dry RU Deo Mineral / Драй РУ Део Минерал, 60 гр. – минеральный дезодорант для всех типов кожи"
            },
            {
                "exp_date": "2027-05-01",
                "id": "5e9c7ba9-9d29-42f9-801b-e28cae16fa2d",
                "num": "1062 01.05.2027"
            },
            {
                "exp_date": "2029-03-01",
                "id": "28ff9b74-71ae-4398-a14f-c18f4b23b3b2",
                "num": "1062 01.03.2029"
            }
        ],
        "dimensions_fact": {
            "depth": 150,
            "height": 40,
            "width": 40
        },
        "dimensions_plan": {
            "depth": 40,
            "height": 150,
            "width": 40
        },
        "external_id": "1062",
        "fragile": false,
        "id": "1U-1062",
        "image": "https://storage.yandexcloud.net/images-k2c/45a8c8ce-0039-4621-9c6e-c0e669ad0a5f.JPG",
        "image_preview": "https://storage.yandexcloud.net/images-k2c/45a8c8ce-0039-4621-9c6e-c0e669ad0a5f-100.JPG",
        "images": [
            {
                "position": 0,
                "preview_url": "https://storage.yandexcloud.net/images-k2c/45a8c8ce-0039-4621-9c6e-c0e669ad0a5f-100.JPG",
                "url": "https://storage.yandexcloud.net/images-k2c/45a8c8ce-0039-4621-9c6e-c0e669ad0a5f.JPG"
            }
        ],
        "merchant": {
            "cam_id": "33846",
            "contract_date": "2023-01-30",
            "contract_num": "КО-ОП- 30/01/2023-245 ",
            "enabled": true,
            "id": "85060c40-419a-46f3-adb0-d6ea91799f41",
            "inn": "5018112138",
            "internal_title": "Сканди Лайн",
            "kpp": "501811213",
            "login": "galinal1207@yandex.ru",
            "ogrn": "5018112138",
            "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
            "title": "Сканди Лайн",
            "trade_title": "Сканди Лайн"
        },
        "parts": [],
        "require_dimensions": false,
        "require_weight": false,
        "scan_serial_numbers": [],
        "title": "Dry RU Deo Mineral / Драй РУ Део Минерал, 60 гр. – минеральный дезодорант для всех типов кожи",
        "type": "SKU",
        "weight_fact": 85,
        "weight_plan": 100
    },
    {
        "article": "DRCrystal",
        "barcodes": [
            {
                "active": true,
                "barcode": "4627102710529",
                "type": "COMMON",
                "used_in_last_supply": true
            }
        ],
        "batch_accounting": true,
        "batch_expdate_mandatory": true,
        "batches": [
            {
                "exp_date": "2027-04-01",
                "id": "064fe51f-ed99-4a3c-bba7-16aa1131fe45",
                "num": "DRCrystal 01.04.2027 0:00:00 до 01.04.27 | Dry RU Deo Crystal / Драй РУ Део Кристал, 40 гр. – дезодорант-спрей с минеральными кристаллами для в"
            },
            {
                "exp_date": "2027-04-01",
                "id": "b1535536-9190-4a82-a262-deef3664ce00",
                "num": "1063 01.04.2027"
            }
        ],
        "country_of_origin": "RU",
        "dimensions_fact": {
            "depth": 150,
            "height": 40,
            "width": 40
        },
        "dimensions_plan": {
            "depth": 40,
            "height": 150,
            "width": 40
        },
        "external_id": "1063",
        "fragile": false,
        "id": "1U-1063",
        "image": "https://storage.yandexcloud.net/images-k2c/be7bcfd5-ed39-43bc-b284-750f9059afa0.JPG",
        "image_preview": "https://storage.yandexcloud.net/images-k2c/be7bcfd5-ed39-43bc-b284-750f9059afa0-100.JPG",
        "images": [
            {
                "position": 0,
                "preview_url": "https://storage.yandexcloud.net/images-k2c/be7bcfd5-ed39-43bc-b284-750f9059afa0-100.JPG",
                "url": "https://storage.yandexcloud.net/images-k2c/be7bcfd5-ed39-43bc-b284-750f9059afa0.JPG"
            }
        ],
        "merchant": {
            "cam_id": "33846",
            "contract_date": "2023-01-30",
            "contract_num": "КО-ОП- 30/01/2023-245 ",
            "enabled": true,
            "id": "85060c40-419a-46f3-adb0-d6ea91799f41",
            "inn": "5018112138",
            "internal_title": "Сканди Лайн",
            "kpp": "501811213",
            "login": "galinal1207@yandex.ru",
            "ogrn": "5018112138",
            "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
            "title": "Сканди Лайн",
            "trade_title": "Сканди Лайн"
        },
        "parts": [],
        "require_dimensions": false,
        "require_weight": false,
        "scan_serial_numbers": [],
        "title": "Dry RU Deo Crystal / Драй РУ Део Кристал, 40 гр. – дезодорант-спрей с минеральными кристаллами для всех типов кожи",
        "type": "SKU",
        "weight_fact": 78,
        "weight_plan": 100
    },
    {
        "article": "DRActiveWoman",
        "barcodes": [
            {
                "active": true,
                "barcode": "4627102710925",
                "type": "COMMON",
                "used_in_last_supply": true
            }
        ],
        "batch_accounting": true,
        "batch_expdate_mandatory": true,
        "batches": [
            {
                "exp_date": "2027-01-23",
                "id": "04828c81-33c2-4a85-8617-66525daabaa9",
                "num": "DRActiveWoman 23.01.2027 0:00:00 до 23.01.27 | DRYRU Active Woman (ДРАЙРУ Актив Вуман) Антиперспирант с парфюмом для активных женщин, аэрозоль 150"
            },
            {
                "id": "5b895f80-fe06-4ef5-8348-05fd4277b89b",
                "num": "13.03.2027"
            },
            {
                "exp_date": "2026-08-02",
                "id": "e103c229-f8fd-453e-8ee5-49d99952c372",
                "num": "1053 02.08.2026"
            },
            {
                "id": "d1ebf614-4f84-4900-b3d5-0ca1376106bb",
                "num": "001"
            },
            {
                "id": "9269d62c-367e-4be8-b750-d9a32a680ca2",
                "num": "020826"
            },
            {
                "exp_date": "2026-05-01",
                "id": "dcaa677a-ea36-4071-8f47-f048f8132b50",
                "num": "DRActiveWoman 01.05.2026 0:00:00 до 01.05.26 | DRYRU Active Woman (ДРАЙРУ Актив Вуман) Антиперспирант с парфюмом для активных женщин, аэрозоль 150"
            },
            {
                "id": "be744397-6a70-4913-a968-fce8f09bd4f6",
                "num": "02.08.2026"
            },
            {
                "id": "524a3956-607c-44ff-8368-26ee1eb17df5",
                "num": "13.03.2024"
            }
        ],
        "country_of_origin": "RU",
        "dimensions_fact": {
            "depth": 75,
            "height": 45,
            "width": 45
        },
        "dimensions_plan": {
            "depth": 180,
            "height": 50,
            "width": 50
        },
        "external_id": "1053",
        "fragile": false,
        "id": "1U-1053",
        "image": "https://storage.yandexcloud.net/images-k2c/f1cd914a-7a76-4317-b6fb-d4b1356a42f1.JPG",
        "image_preview": "https://storage.yandexcloud.net/images-k2c/f1cd914a-7a76-4317-b6fb-d4b1356a42f1-100.JPG",
        "images": [
            {
                "position": 0,
                "preview_url": "https://storage.yandexcloud.net/images-k2c/f1cd914a-7a76-4317-b6fb-d4b1356a42f1-100.JPG",
                "url": "https://storage.yandexcloud.net/images-k2c/f1cd914a-7a76-4317-b6fb-d4b1356a42f1.JPG"
            }
        ],
        "merchant": {
            "cam_id": "33846",
            "contract_date": "2023-01-30",
            "contract_num": "КО-ОП- 30/01/2023-245 ",
            "enabled": true,
            "id": "85060c40-419a-46f3-adb0-d6ea91799f41",
            "inn": "5018112138",
            "internal_title": "Сканди Лайн",
            "kpp": "501811213",
            "login": "galinal1207@yandex.ru",
            "ogrn": "5018112138",
            "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
            "title": "Сканди Лайн",
            "trade_title": "Сканди Лайн"
        },
        "parts": [],
        "require_dimensions": false,
        "require_weight": false,
        "scan_serial_numbers": [],
        "title": "DRYRU Active Woman (ДРАЙРУ Актив Вуман) Антиперспирант с парфюмом для активных женщин, аэрозоль 150 мловар",
        "type": "SKU",
        "weight_fact": 125,
        "weight_plan": 125
    },
    {
        "article": "DRActive",
        "barcodes": [
            {
                "active": true,
                "barcode": "2038133867090",
                "type": "COMMON",
                "used_in_last_supply": true
            }
        ],
        "batch_accounting": true,
        "batch_expdate_mandatory": true,
        "batches": [
            {
                "id": "816d6a0c-dfb8-4278-80a9-5904025b5696",
                "num": "020826"
            },
            {
                "id": "483cab3b-b30c-4623-8100-6546ead32278",
                "num": "02.08.2026"
            },
            {
                "exp_date": "2106-11-02",
                "id": "5800e5c6-78bb-4a14-8110-56b0e119ccc6",
                "num": "1U-0000-0004"
            },
            {
                "id": "5c8f1633-071c-4202-a868-fdab14849fe2",
                "num": "27.01.2026"
            },
            {
                "id": "c491d804-9a85-40d9-b312-9a47788ca628",
                "num": "13.03.2027"
            },
            {
                "id": "ffff9570-1547-4995-964a-f3e368632f2d",
                "num": "1071"
            },
            {
                "id": "5405e572-8981-4f4d-9e56-e7850695bae7",
                "num": "31.08.2026"
            },
            {
                "id": "da94ae9e-63df-4394-8f32-d8947a63c94f",
                "num": "31.08.2023"
            },
            {
                "id": "ca80a159-c52d-4af6-90d6-da70c03d7865",
                "num": "31.08.26"
            },
            {
                "exp_date": "2106-04-27",
                "id": "60b7d77d-4938-4e6b-8524-672ff69a6f37",
                "num": "1U-0000-0005"
            }
        ],
        "dimensions_fact": {
            "depth": 200,
            "height": 110,
            "width": 100
        },
        "dimensions_plan": {
            "depth": 100,
            "height": 150,
            "width": 100
        },
        "external_id": "1077",
        "fragile": false,
        "id": "1U-1077",
        "image": "https://storage.yandexcloud.net/images-k2c/f6624d60-775c-4b6a-9a49-468945ff5d95.png",
        "image_preview": "https://storage.yandexcloud.net/images-k2c/f6624d60-775c-4b6a-9a49-468945ff5d95-100.png",
        "images": [
            {
                "position": 0,
                "preview_url": "https://storage.yandexcloud.net/images-k2c/f6624d60-775c-4b6a-9a49-468945ff5d95-100.png",
                "url": "https://storage.yandexcloud.net/images-k2c/f6624d60-775c-4b6a-9a49-468945ff5d95.png"
            }
        ],
        "merchant": {
            "cam_id": "33846",
            "contract_date": "2023-01-30",
            "contract_num": "КО-ОП- 30/01/2023-245 ",
            "enabled": true,
            "id": "85060c40-419a-46f3-adb0-d6ea91799f41",
            "inn": "5018112138",
            "internal_title": "Сканди Лайн",
            "kpp": "501811213",
            "login": "galinal1207@yandex.ru",
            "ogrn": "5018112138",
            "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
            "title": "Сканди Лайн",
            "trade_title": "Сканди Лайн"
        },
        "parts": [
            {
                "optional": false,
                "quantity": 1,
                "sku_id": "1U-1052"
            },
            {
                "optional": false,
                "quantity": 1,
                "sku_id": "1U-1053"
            }
        ],
        "require_dimensions": false,
        "require_weight": false,
        "scan_serial_numbers": [],
        "title": "Набор антиперспирантов DRY RU Active",
        "type": "PHYSICAL_SET",
        "weight_fact": 296,
        "weight_plan": 300
    }
],
    {
      'article': 'demo1024',
      'barcodes': [
        {
          'active': true,
          'barcode': '1',
          'type': 'COMMON',
          'used_in_last_supply': true,
        },
      ],
      'batch_accounting': true,
      'batch_expdate_mandatory': true,
      'batches': Array(5).fill(1).map((el, i) => (  {
          id: `some-batch-id${i+10}`,
          num: `some-batch-id${i+10}`,
          exp_date: `2022-10-0${i+10}`,
        })),
      'dimensions_fact': {
        'depth': 10,
        'height': 10,
        'width': 10,
      },
      'dimensions_plan': {
        'depth': 300,
        'height': 600,
        'width': 1000,
      },
      'external_id': '1024',
      'fragile': false,
      'id': 'TM-1024',
      'image': 'https://storage.yandexcloud.net/images-k2c/57bf535f-89da-4806-90c4-43158fa44bea.jpg',
      'image_preview': 'https://storage.yandexcloud.net/images-k2c/57bf535f-89da-4806-90c4-43158fa44bea-100.jpg',
      'images': [
        {
          'position': 0,
          'preview_url': 'https://storage.yandexcloud.net/images-k2c/57bf535f-89da-4806-90c4-43158fa44bea-100.jpg',
          'url': 'https://storage.yandexcloud.net/images-k2c/57bf535f-89da-4806-90c4-43158fa44bea.jpg',
        },
      ],
      'merchant': {
        'cam_id': '101',
        'contract_date': '2022-09-25',
        'contract_num': '1001',
        'enabled': true,
        'id': '2b78266b-0405-48f6-a263-3dba1869209f',
        'login': 'test@new-wms.ru',
        'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
        'title': 'Test new WMS merchant',
        'trade_title': 'Test new WMS merchant',
      },
      'parts': [],
      'require_dimensions': false,
      'require_weight': false,
      'scan_serial_numbers': [
        {
          required: true,
          code: CHZ_CODE,
          title: 'Честный знак',
          template: '*',
        },
        {
          code: 'imei2',
          title: 'IMEI код2',
          template: '*',
        },

      ],
      'title': 'Компьютер (демо товар)',
      'type': 'SKU',
      'weight_fact': 1000,
      'weight_plan': 10000,
    },
    {
      'article': 'demo1025',
      'barcodes': [
        {
          'active': true,
          'barcode': '2',
          'type': 'COMMON',
          'used_in_last_supply': true,
        },
      ],
      'batch_accounting': true,
      'batch_expdate_mandatory': true,
      "expiration_months": 24,
      'batches': Array(5).fill(1).map((el, i) => (  {
          id: `some-batch-id${i}`,
          num: `some-batch-id${i}`,
          exp_date: `2022-10-0${i+1}`,
        })),
      'dimensions_fact': {
        'depth': 123,
        'height': 210,
        'width': 120,
      },
      'dimensions_plan': {
        'depth': 300,
        'height': 200,
        'width': 200,
      },
      'external_id': '1025',
      'fragile': false,
      'id': 'TM-1025',
      'image': 'https://storage.yandexcloud.net/images-k2c/9a4b3378-0cc7-4880-aea5-4714bc25cfcc.jpg',
      'image_preview': 'https://storage.yandexcloud.net/images-k2c/9a4b3378-0cc7-4880-aea5-4714bc25cfcc-100.jpg',
      'images': [
        {
          'position': 0,
          'preview_url': 'https://storage.yandexcloud.net/images-k2c/9a4b3378-0cc7-4880-aea5-4714bc25cfcc-100.jpg',
          'url': 'https://storage.yandexcloud.net/images-k2c/9a4b3378-0cc7-4880-aea5-4714bc25cfcc.jpg',
        },
      ],
      'merchant': {
        'cam_id': '101',
        'contract_date': '2022-09-25',
        'contract_num': '1001',
        'enabled': true,
        'id': '2b78266b-0405-48f6-a263-3dba1869209f',
        'login': 'test@new-wms.ru',
        'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
        'title': 'Test new WMS merchant',
        'trade_title': 'Test new WMS merchant',
      },
      'parts': [],
      'require_dimensions': false,
      'require_weight': false,
      'scan_serial_numbers': [
        {
          required: true,
          code: CHZ_CODE,
          title: 'Честный знак',
          template: '*',
        },
      ],
      'title': 'Фотоаппарат (демо товар)',
      'type': 'SKU',
      'weight_fact': 12000,
      'weight_plan': 1000,
    },
    {
      'article': 'demo1026',
      'barcodes': [
        {
          'active': true,
          'barcode': '63547534',
          'type': 'OZON',
          'used_in_last_supply': false,
        },
        {
          'active': true,
          'barcode': '1234563524124253',
          'type': 'COMMON',
          'used_in_last_supply': false,
        },
      ],
      'batch_accounting': true,
      'batch_expdate_mandatory': true,
      "expiration_months": 12,
      'batches': Array(5).fill(1).map((el, i) => (  {
          id: `some-batch-id${i}`,
          num: `some-batch-id${i}`,
          exp_date: `2022-10-0${i+1}`,
        })),
      'dimensions_fact': {
        'depth': 210,
        'height': 120,
        'width': 210,
      },
      'dimensions_plan': {
        'depth': 100,
        'height': 100,
        'width': 100,
      },
      'external_id': '1026',
      'fragile': false,
      'id': 'TM-1026',
      'image': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d.jpg',
      'image_preview': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d-100.jpg',
      'images': [
        {
          'position': 0,
          'preview_url': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d-100.jpg',
          'url': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d.jpg',
        },
      ],
      'merchant': {
        'cam_id': '101',
        'contract_date': '2022-09-25',
        'contract_num': '1001',
        'enabled': true,
        'id': '2b78266b-0405-48f6-a263-3dba1869209f',
        'login': 'test@new-wms.ru',
        'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
        'title': 'Test new WMS merchant',
        'trade_title': 'Test new WMS merchant',
      },
      'parts': [],
      'require_dimensions': false,
      'require_weight': false,
      'scan_serial_numbers': [],
      'title': 'Наушники (демо товар)',
      'type': 'SKU',
      'weight_fact': 2000,
      'weight_plan': 1000,
    },
  ],
  'success': true,
  'supply': {
    'activated': true,
    'create_ts': '2023-12-14T11:02:28.130722',
    'date': '2023-12-14T11:02:27.883000',
    'supply_form': [
      // {
      //   'title': 'test title',
      //   'value': 'test value',
      //   'comment': 'test comment formItem.comment formItem.comment formItem.commentformItem.comment formItem.comment formItem.commenttest comment',
      // },
      // {
      //   'title': 'test title 2',
      //   'value': 'test value 2',
      //   'comment': 'test comment 2 formItem.comment formItem.comment formItem.commentformItem.comment formItem.comment formItem.commenttest comment 2',
      // }
    ],
    'executor': {
      'enabled': true,
      'id': 'd5e1534b-6af3-44bb-82a6-b71ac5d02f90',
      'login': '12354321',
      'name': 'Юлия',
      'patronymic': '',
      'position': 'DEVELOPER',
      'roles': [
        'e8191d72-0574-470e-a5bf-43fe5818ac19',
        '6d2726ed-914d-413c-8224-cbca5ce244b5',
        'e0e29932-c19d-431e-92ac-702f939e0cda',
        '2494d4ff-f0f8-4b1e-b2ec-2b330a449fae',
        'edfa715d-f607-47cb-9ad4-ca4c93409dd9',
        '1a30e375-dbf6-4de2-8976-a07a5a5847ef',
        '15da10de-5e40-4c46-9373-8cfe35aa7659',
        'ac018110-47bd-4d3d-b070-0aa4b0915a09',
        '70b16569-38eb-4014-a1fe-6ac0054c431a',
      ],
      'surname': 'П',
    },
    'external_id': '1037',
    'files': [],
    'has_diffs': false,
    'id': 'S-TM-1037',
    'items': [
      // {
      //   'id': 'dc560023-6059-478b-bb3e-1491ed8f80fa',
      //   'price_data': {
      //     'currency': 'RUB',
      //     'price': 2,
      //     'vat': 'NO_VAT',
      //   },
      //   'quantity': 6,
      //   'scan_serial_numbers': [CHZ_CODE],
      //   'sku_id': 'TM-1025',
      // },
      {
        'id': '40b710b4-c4e4-42d2-996f-09cd7b66420a',
        'price_data': {
          'currency': 'RUB',
          'price': 3,
          'vat': 'NO_VAT',
        },
        'quantity': 2,
        'scan_serial_numbers': [],
        'sku_id': 'TM-1026',
      },
      ...["1U-1056",
        "1U-1074",
        "1U-1085",
        "1U-1052",
        "1U-1076",
        "1U-1062",
        "1U-1063",
        "1U-1053",
        "1U-1077"].map((el) => ({
          'id': '40b710b4-c4e4-42d2-996f-09c12512566420a'+el,
          'price_data': {
            'currency': 'RUB',
            'price': 3,
            'vat': 'NO_VAT',
          },
          'quantity': 2,
          'scan_serial_numbers': [],
          'sku_id': el,
        }))
      // {
      //   'id': '40b710b4-c4e4-42d2-996f-09cd7b66122',
      //   'price_data': {
      //     'currency': 'RUB',
      //     'price': 3,
      //     'vat': 'NO_VAT',
      //   },
      //   'quantity': 7,
      //   'scan_serial_numbers': ['imei','imei2'],
      //   'sku_id': 'TM-1026',
      //   'sku_batch_id': 'some-batch-id3',
      // },
      //
      // {
      //   'id': v4(),
      //   'price_data': {
      //     'currency': 'RUB',
      //     'price': 1,
      //     'vat': 'NO_VAT',
      //   },
      //   'quantity': 14,
      //   'sku_id': 'TM-1024',
      //   // 'sku_batch_id': 'some-batch-id12',
      // },
    ],
    'merchant': {
      'cam_id': '101',
      'contract_date': '2022-09-25',
      'contract_num': '1001',
      'enabled': true,
      'id': '2b78266b-0405-48f6-a263-3dba1869209f',
      'login': 'test@new-wms.ru',
      'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
      'title': 'Test new WMS merchant',
      'trade_title': 'Test new WMS merchant',
    },
    'receipt_started_at': '2023-12-14T12:16:53.000000',
    // "supply_type": "FROM_SUPPLIER",
    "supply_type": "DEFAULT",
    'receipts': [
      {
        'activated': true,
        // 'condition': 'DEFECT',
        'condition': 'GOOD',
        'create_ts': '2023-12-15T11:36:16.891960',
        'date': '2023-12-15T11:36:16.881045',
        'executor': {
          'enabled': true,
          'id': '69e2c608-88c9-4a32-87a9-1401736228b0',
          'login': '123',
          'name': '123',
          'patronymic': '123',
          'position': 'GENERAL_DIRECTOR',
          'roles': [
            'e545d9ad-c079-4c11-9005-a775438549a2',
          ],
          'surname': '123',
        },
        'id': 'RT-TM-0000-0142',
        'items': [
          {
            'acceptance_type': 'PIECE',
            'barcode_used': '63547534',
            'id': 'de280bd7-3690-4d29-bbbb-a0fe5e4056e1',
            'multiplicity': 1,
            'quantity': 2,
            'serial_numbers': [],
            'sku_id': 'TM-1026',
          }
        ],
        'merchant': {
          'cam_id': '101',
          'contract_date': '2022-09-25',
          'contract_num': '1001',
          'enabled': true,
          'id': '2b78266b-0405-48f6-a263-3dba1869209f',
          'login': 'test@new-wms.ru',
          'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
          'title': 'Test new WMS merchant',
          'trade_title': 'Test new WMS merchant',
        },
        // 'status': 'CLOSED',
        'status': 'OPEN',
        'tare_barcode': 'TARA0000393',
        'update_ts': '2023-12-22T22:47:18.048818',
        'uuid': '596ffadd-3cc4-4dbb-8358-332c4d96e934',
        'workspace': {
          'barcode': 'XX-1',
          'condition': 'GOOD',
          'id': 'a94203b3-67d7-42b7-9642-ef510c3e9323',
          'title': 'XX-1',
          'zone_id': '26826c40-ac61-4e9f-a5ea-1101ef5521f2',
        },
      },
    ],
    'status': 'PROCESSING',
    'supplier': {
      'id': 'e8771d15-0809-478c-8c31-99a687c5ebd7',
      'inn': '5404084407',
      'kpp': '540401001',
      'ogrn': '1195476004789',
      'title': 'ООО "ПОСТАВЩИК 1"',
    },
    'update_ts': '2023-12-14T12:16:53.989066',
    'uuid': '6e51c3ae-4492-4c15-bfa0-3f3424518a6f',
    'workspace': {
      'barcode': 'DM-22',
      'condition': 'GOOD',
      'id': '7a9cb4f2-19fc-4c55-a757-d216f0b8a861',
      'title': 'DM-22',
      'zone_id': '26826c40-ac61-4e9f-a5ea-1101ef5521f2',
    },
  },
}
