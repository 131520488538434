import React, { useMemo } from 'react'
import { ResponseError } from '@shared/api/types'
import { Modal, TableWithCopy } from '@/components'
import { ITableColumn, ITableRow } from '@/interfaces'

interface DetailsModalProps {
  onClose?: () => void
  allErrors?: ResponseError[]
}

const MessageDescr: Record<keyof ResponseError, string> = {
  "code": 'Код',
  "message": 'Текст',
} as const

export const DetailsModal = (props: DetailsModalProps) => {
  const {
    onClose,
    allErrors,
  } = props

  const { rows, columns } = useMemo(() => {
    const columns: ITableColumn[] = Object.keys(allErrors[0]).map((key) => {
      const columnName = MessageDescr[key] || key
      return {
        key: key,
        title: columnName,
        title_txt: columnName
      }
    })

    const rows: ITableRow[] = allErrors.map((error, i) => {
      return ({
        id: `${i}`,
        ...error,
      })
    })
    return { rows, columns }
  }, [allErrors])


  return (
    <Modal
      isOpen={true}
      withClose
      onClose={onClose}
      onOverlayClick={onClose}
      title={'Детали ошибок'}
      subtitle={' '}
      size='m'
    >
      <TableWithCopy
        columns={columns}
        rows={rows}
      />
    </Modal>
  )
}
