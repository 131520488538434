import { FieldRow, FieldType } from '@widgets/GeneratorFields'
import { orderStatusSelectOptions, taskSelectOptions } from 'src/interfaces'

export const generateFilters = (): FieldRow[] => [
  {
    hidden: true,
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: 'search',
          viewDescription: 'Поиск',
        },
      },
    ],
  },
  {
    fields: [
      {
        type: FieldType.MERCHANTS,
        fieldProps: {
          id: 'merchant',
          isMultiple: true,
          label: 'Организация',
          viewDescription: 'Организация',
        },
      },
      {
        type: 'combobox',
        fieldProps: {
          id: 'statuses',
          label: 'Статус',
          isMultiple: true,
          items: orderStatusSelectOptions,
          viewDescription: 'Статус',
        },
      },
      {
        type: 'combobox',
        fieldProps: {
          id: 'set',
          label: 'Тип',
          isMultiple: true,
          items: taskSelectOptions,
          viewDescription: 'Тип',
        },
      },
    ],
  },
]
