import React, { useEffect, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { IDocumentsFields } from '@pages/OperationsAndDocumentsPage/store/interfaces'
import { Button } from 'src/components'
import { PATHS } from '@shared/routing'
import { OperAndDocumPages } from '@pages/OperationsAndDocumentsPage'
import classes from './ServicesRenderedAddDocument.module.scss'
import { generatePath, useHistory } from 'react-router-dom'
import useCreateServicesRendered from 'src/hooks/servicesRendered/useCreateServiceRendered'
import { useGetMerchantList, useGetServicesList } from 'src/hooks'
import { DefaultItem } from '@consta/uikit/Combobox'

const ServicesRenderedAddDocument = () => {
  const history = useHistory()
  const { data: merchantData } = useGetMerchantList({})
  const { data: servicesData } = useGetServicesList({})
  const [merchantList, setMerchantList] = useState<DefaultItem[]>([])
  const [merchant, setMerchant] = useState<DefaultItem | null>()

  useEffect(() => {
    if (!merchantData) {
      return
    }

    setMerchantList(
      merchantData.merchants.map((merchantItem) => ({
        label: merchantItem.title,
        id: merchantItem.id,
      })),
    )
  }, [merchantData])
  interface IFormField {
    merchant: string
    items: {
      services: string
      count: number
    }[]
    comment: string
  }
  const { mutateAsync: createUpdateServices } = useCreateServicesRendered()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<IFormField>({
    defaultValues: {
      merchant: '',
      items: [{ services: ' ', count: 1 }],
    },
  })
  const onSubmit: SubmitHandler<IFormField> = (data) => {
    const merchant = merchantData?.merchants.find(
      (el) => el.title === data.merchant,
    )
    const itemsResult = data.items.map((item) => {
      const service = servicesData?.services.find(
        (serv) => serv.title === item.services,
      )
      return {
        service_id: service?.id,
        quantity: item.count < 1 ? 1 : item.count,
      }
    })
    const document: IDocumentsFields = {
      comment: data.comment || '',
      merchant_id: merchant?.id,
      items: itemsResult,
    }
    // createUpdateServices({
    //   data: document,
    // })
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.servicesRenderedList,
      }),
    )
    reset()
  }
  const { fields, append, remove } = useFieldArray({
    name: 'items',
    control,
  })
  const handleClick = () => {
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.servicesRenderedList,
      }),
    )
  }
  return (
    <div className={classes['form-wrapper']}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <div className={classes.merchant}>
          <label htmlFor="merchant" className={classes['merchant-label']}>
            <span>Организация</span>
            <select
              id="merchant"
              {...register('merchant', { required: 'Обязательное поле!' })}
            >
              {merchantData?.merchants.map((merchant) => {
                return <option key={merchant.id}>{merchant.title}</option>
              })}
            </select>
          </label>
          {errors?.merchant && (
            <div style={{ color: 'red' }}>{errors.merchant?.message}</div>
          )}
        </div>
        <div className={classes.fields}>
          {fields.map(({ id }, index) => {
            return (
              <div className={classes['fields-content']} key={id}>
                <div className={classes['fields-content__row']}>
                  <div className={classes['row-label']}>
                    <label
                      className={classes['services-label']}
                      htmlFor="services"
                    >
                      <span>Наименование</span>
                      <select
                        id="services"
                        {...register(`items.${index}.services`, {
                          required: 'Обязательное поле!',
                        })}
                      >
                        {servicesData?.services.map((service) => {
                          return (
                            <option key={service.id}>{service.title}</option>
                          )
                        })}
                      </select>
                      {errors.items?.[index]?.services && (
                        <div style={{ color: 'red' }}>
                          {errors.items?.[index]?.services?.message}
                        </div>
                      )}
                    </label>
                    <label
                      htmlFor="count"
                      className={classes['services-label_count']}
                    >
                      <span>Количество</span>
                      <input
                        type="number"
                        id="count"
                        {...register(`items.${index}.count`, {
                          valueAsNumber: true,
                        })}
                      />
                    </label>
                  </div>

                  <Button
                    label="Удалить услугу"
                    size={'s'}
                    view={'ghost'}
                    onClick={() => {
                      remove(index)
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className={classes['services-del']}>
          <Button
            label="Добавить Услугу"
            size={'s'}
            onClick={() => {
              append({
                services: '',
                count: 1,
              })
            }}
          />
        </div>
        <label htmlFor="" className={classes['form-comment']}>
          <span>Коментарий</span>
          <textarea
            {...register('comment')}
            className={classes['comment']}
          ></textarea>
        </label>

        <div className={classes['button-container']}>
          <Button label={'Добавить'} />
          <Button label="Отмена" view={'ghost'} onClick={handleClick} />
        </div>
      </form>
    </div>
  )
}
export default ServicesRenderedAddDocument
