import { useQuery } from 'react-query'
import api from '@shared/api'
import { ApiReqResult, IServices, IServicesDocuments } from 'src/interfaces'

import { UseQueryOptions } from 'react-query/types/react/types'

export interface IGetServiceParams {
  serviceId: string
}
export interface IGetServiceResult extends ApiReqResult {
  document: IServicesDocuments
  services: IServices
}
const useGetService = (
  params: IGetServiceParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IGetServiceResult>(
    [`getService`, params],
    async () => {
      const result = await api.get(`/billing/service-doc/${params.serviceId}`)
      return result.data
    },
    options,
  )
}
export default useGetService
