import { IContainer, IDeliveryItem, IOrderPackagingOption } from '@/interfaces'
import {
  IDefectType,
  IRefund,
  IRefundPlace,
  IRefundPlaceItem,
  IRefundPlanItem,
  IRefundSku,
} from '@/interfaces/refund/refund'
import { IUseReducer } from '../context/RefundAcceptanceContext'
import { ProblemContainersType } from '../types'

/** КОМБИНИРОВАННЫЕ ЭКШНЫ */
export const addSkus = (skus: IRefundSku[], { state, dispatch }: IUseReducer) => {
  if (!skus.length) {
    return
  }
  dispatch(setSkus([
    ...state.skus,
    ...skus.filter(ns => !state.skus.find(s => s.id === ns.id))
  ]))
}


export const updateSku = (sku: IRefundSku, { state, dispatch }: IUseReducer) => {
  const idx = state.skus.findIndex(s => s.id === sku.id);
  if (idx >= 0) {
    dispatch(setSkus([...state.skus.slice(0, idx), sku, ...state.skus.slice(idx + 1)]))
  }
}


export const addRefundPlace = (place: IRefundPlace, { state, dispatch }: IUseReducer) => {
  if (state.refund) {
    dispatch(setRefund({
      ...state.refund,
      places: [
        ...(state.refund.places || []),
        place
      ]
    }))
  }
  dispatch(setCurrentRefundPlace(place))
}


export const updateRefundPlace = (place: IRefundPlace, { state, dispatch }: IUseReducer) => {
  if (!state.refund?.places) {
    return
  }
  const idx = state.refund.places.findIndex(p => p.id === place.id);
  if (idx >= 0) {
    dispatch(setRefund({
      ...state.refund,
      places: [...state.refund.places.slice(0, idx), place, ...state.refund.places.slice(idx + 1)]
    }))
  }
  if (state.currentRefundPlace?.id === place.id) {
    dispatch(setCurrentRefundPlace(place))
  }
}

export const deleteRefundPlace = (place: IRefundPlace, { state, dispatch }: IUseReducer) => {
  if (!state.refund?.places) {
    return
  }
  const idx = state.refund.places.findIndex(p => p.id === place.id);
  if (idx >= 0) {
    dispatch(setRefund({
      ...state.refund,
      places: [...state.refund.places.slice(0, idx), ...state.refund.places.slice(idx + 1)]
    }))
  }
  if (state.currentRefundPlace?.id === place.id) {
    dispatch(setCurrentRefundPlace(null))
  }
}


export const deleteItemFromPlan = (sku: IRefundSku | null, item:IRefundPlaceItem, { state, dispatch }: IUseReducer) => {
  dispatch(setCurrentPlanItems(
    state.currentPlanItems.map(pi =>
      pi.sku_id === item.sku_id && (!sku?.batch_accounting || pi.sku_batch_id === item.sku_batch_id) ?
        {...pi, quantity: pi.quantity - item.quantity} : pi
    )
      .filter(pi => pi.quantity > 0)
  ))
}


export const updateTareAction = (tare: IContainer, { state, dispatch }: IUseReducer) => {
  const idx = state.tares.findIndex(t => t.barcode === tare.barcode);
  if (idx >= 0) {
    dispatch(setTares([...state.tares.slice(0, idx), tare, ...state.tares.slice(idx + 1)]))
  }
}

/** ОДИНОЧНЫЕ ЭКШЕНЫ */

export const SET_REFUND = 'SET_REFUND'
export const setRefund = (refund: IRefund | null) => ({
  type: SET_REFUND,
  payload: refund,
})

export const SET_SKUS = 'SET_SKUS'
export const setSkus = (refund: IRefundSku[]) => ({
  type: SET_SKUS,
  payload: refund,
})

export const SET_DELIVERY_LIST = 'SET_DELIVERY_LIST'
export const setDeliveryList = (deliveries: IDeliveryItem[]) => ({
  type: SET_DELIVERY_LIST,
  payload: deliveries,
})

export const SET_CURRENT_REFUND_PLACE = 'SET_CURRENT_REFUND_PLACE'
export const setCurrentRefundPlace = (refundPlace: IRefundPlace | null) => ({
  type: SET_CURRENT_REFUND_PLACE,
  payload: refundPlace,
})

export const SET_CURRENT_PLAN_ITEMS = 'SET_CURRENT_PLAN_ITEMS'
export const setCurrentPlanItems = (planItems: IRefundPlanItem[]) => ({
  type: SET_CURRENT_PLAN_ITEMS,
  //API может возвращать нулевые или отрицательные плановые количества например в случае подмен или в случае если что-то уже вернули по данному заказу
  payload: planItems.filter(i => i.quantity > 0),
})

export const SET_PACKAGINGS = 'SET_PACKAGINGS'
export const setPackagings = (packagings: IOrderPackagingOption[]) => ({
  type: SET_PACKAGINGS,
  payload: packagings,
})

export const SET_USED_SERIAL_NUMBERS = 'SET_USED_SERIAL_NUMBERS'
export const setUsedSerialNumbers = (serials: string[]) => ({
  type: SET_USED_SERIAL_NUMBERS,
  payload: serials,
})

export const SET_TARES = 'SET_TARES'
export const setTares = (tares: IContainer[]) => ({
  type: SET_TARES,
  payload: tares,
})

export const SET_GOOD_TARA = 'SET_GOOD_TARA'
export const setGoodTara = (tara: IContainer | null) => ({
  type: SET_GOOD_TARA,
  payload: tara,
})

export const SET_DEFECT_TARA = 'SET_DEFECT_TARA'
export const setDefectTara = (tara: IContainer | null) => ({
  type: SET_DEFECT_TARA,
  payload: tara,
})

export const SET_SUBSTITUTION_TARA = 'SET_SUBSTITUTION_TARA'
export const setSubstitutionTara = (tara: IContainer | null) => ({
  type: SET_SUBSTITUTION_TARA,
  payload: tara,
})

export const SET_ERROR_OPEN_TARES = 'SET_ERROR_OPEN_TARES'
export const setErrorOpenTares = (data: ProblemContainersType | null) => ({
  type: SET_ERROR_OPEN_TARES,
  payload: data,
})

export const SET_DEFECT_TYPES = 'SET_DEFECT_TYPES'
export const setDefectTypes = (defectTypes: IDefectType[]) => ({
  type: SET_DEFECT_TYPES,
  payload: defectTypes,
})

export const SET_GOOD_TARA_MERCHANT_ID = 'SET_GOOD_TARA_MERCHANT_ID'
export const setGoodTaraMerchantId = (merchantId: string | null) => ({
  type: SET_GOOD_TARA_MERCHANT_ID,
  payload: merchantId,
})

export const SET_DEFECT_TARA_MERCHANT_ID = 'SET_DEFECT_TARA_MERCHANT_ID'
export const setDefectTaraMerchantId = (merchantId: string | null) => ({
  type: SET_DEFECT_TARA_MERCHANT_ID,
  payload: merchantId,
})

export const SET_SUBSTITUTION_TARA_MERCHANT_ID = 'SET_SUBSTITUTION_TARA_MERCHANT_ID'
export const setSubstitutionTaraMerchantId = (merchantId: string | null) => ({
  type: SET_SUBSTITUTION_TARA_MERCHANT_ID,
  payload: merchantId,
})


export const SET_MASS_ACCEPTANCE = 'SET_MASS_ACCEPTANCE'
export const setMassAcceptance = (status: boolean) => ({
  type: SET_MASS_ACCEPTANCE,
  payload: status,
})



export const SET_ADD_MASS_ACCEPTANCE = 'SET_ADD_MASS_ACCEPTANCE'
export const setAddMassAcceptance = (data: { orderId: string, placeBarcode: string }) => ({
  type: SET_ADD_MASS_ACCEPTANCE,
  payload: data,
})

