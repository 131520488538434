import { ICommonDocument, ISku, IWarehouseCell } from '@/interfaces'
import { IInventoryFormDataState } from '../interfaces'

export const SET_SKUS_RAW = 'SET_SKUS_RAW'
export const setSkusRawAction = (skus: ISku[]) => {
  return {
    type: SET_SKUS_RAW,
    payload: skus,
  }
}

export const SET_DOCUMENT_RAW = 'SET_DOCUMENT_RAW'
export const setDocumentRawAction = (documentRaw: ICommonDocument | null) => {
  return {
    type: SET_DOCUMENT_RAW,
    payload: documentRaw,
  }
}
export const SET_CELLS_RAW = 'SET_CELLS_RAW'
export const setCellsRawAction = (cellsRaw: IWarehouseCell[] | null) => {
  return {
    type: SET_CELLS_RAW,
    payload: cellsRaw,
  }
}
export const SET_INVENTORY_FORM_STATE_DRAFT = 'SET_INVENTORY_FORM_STATE_DRAFT'
export const setInventoryFormDraftAction = (
  formState: IInventoryFormDataState,
) => {
  return {
    type: SET_INVENTORY_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_INVENTORY_FORM_STATE_RAW = 'SET_INVENTORY_FORM_STATE_RAW'
export const setInventoryFormRawAction = (
  formState: IInventoryFormDataState,
) => {
  return {
    type: SET_INVENTORY_FORM_STATE_RAW,
    payload: formState,
  }
}
export const UPDATE_FORM_INVENTORY_STATE_DRAFT =
  'UPDATE_FORM_INVENTORY_STATE_DRAFT'
export const updateFormInventoryStateDraftAction = (
  key: keyof IInventoryFormDataState,
  value: any,
) => {
  return {
    type: UPDATE_FORM_INVENTORY_STATE_DRAFT,
    payload: { key, value },
  }
}
export const UPDATE_FORM_INVENTORY_STATE_RAW = 'UPDATE_FORM_INVENTORY_STATE_RAW'
export const updateFormInventoryStateRawAction = (
  key: keyof IInventoryFormDataState,
  value: any,
) => {
  return {
    type: UPDATE_FORM_INVENTORY_STATE_RAW,
    payload: { key, value },
  }
}
