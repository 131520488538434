import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  IGetOrderListFilter,
  useGetDeliveriesList,
  useGetQueryOrderList,
  useGetUsers,
  useGetWarehouseZones,
} from 'src/hooks'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { DocumentsListWrapper } from '../../../components'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { IDeliveryItem, IUserData, IZone } from 'src/interfaces'
import { useFilter } from '../../../hooks/useFilter'

const OrdersShippedList = () => {
  const { page } = useParams<any>()

  /** Стейт для поиска и отображения данных фильтров */
  const { filterState, setFilterState } = useFilter()

  const [warehouseUsers, setWarehouseUsers] = useState<IUserData[]>([])
  const [deliveriesList, setDeliveriesList] = useState<IDeliveryItem[]>([])
  const [zonesList, setZonesList] = useState<IZone[]>([])

  const { fetch: getUsersRequest } = useGetUsers()
  const { data: zonesListData } = useGetWarehouseZones(setZonesList)
  const { data: deliveriesListData } = useGetDeliveriesList(setDeliveriesList)

  const {
    page: filterPage,
    ...restFilterParams
  } = generateFilterParams(filterState)

  const {
    isFetching: listLoading,
    data: listData,
    refetch: refetchList,
  } = useGetQueryOrderList({
    filter: restFilterParams as IGetOrderListFilter,
    page: filterPage,
  })

  useEffect(() => {
    refetchList()
  }, [filterState])

  useEffect(() => {
    getUsersRequest()
      .then((data) => setWarehouseUsers(data.users || []))
      .catch(() => {})
  }, [])

  const rows = useMemo(
    () => generateRows(listData?.orders || []),
    [listData?.orders],
  )
  const columns = useMemo(() => generateColumns(), [])
  const filtersConfig = generateFilters(
    warehouseUsers,
    deliveriesList,
    zonesList,
  )
  return (
    <DocumentsListWrapper
      title={'Заказы на отгрузку'}
      rows={rows}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      filtersConfig={filtersConfig}
      loading={listLoading}
      pagination={listData?.pagination}
    />
  )
}

export default OrdersShippedList
