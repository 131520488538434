import React, { useMemo } from 'react'
import cx from 'classnames'
import styles from './DownloadDoc.module.scss'

import { DownloadDocsTable } from '../../../../../components'
import { useBillingContext } from '@pages/OperationsAndDocumentsPage/context/BillingContext'

interface DownloadDocProps {}

const DownloadDoc = (props: DownloadDocProps) => {
  const { state } = useBillingContext()

  const rows = useMemo(() => {
    if (!state?.documentRaw?.files) {
      return []
    }

    return state.documentRaw.files.map((file) => {
      const { id, title, url } = file
      const fileName = title

      return {
        id,
        name: fileName,
        action: () => {
          const link = document.createElement('a')
          link.href = url
          link.download = fileName
          link.style.display = 'none'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        },
      }
    })
  }, [state?.documentRaw?.files])

  const downloadFiles = (filesKeys: string[]) => {
    filesKeys.map((key: any) => {
      const currFileRow = rows.find((row: any) => row.id === key)
      currFileRow?.action()
    })
  }

  return (
    <div className={cx(styles.main)}>
      <DownloadDocsTable
        title={'Акты'}
        handleClickDownload={downloadFiles}
        tableRows={rows}
      />
    </div>
  )
}

export default DownloadDoc
