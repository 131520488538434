import React from 'react'
import cx from 'classnames'
import styles from './additional-params.module.scss'
import ServicesForm from '../../ServicesForm/ServicesForm'
import DownloadActs from '../../DownloadActs/DownloadActs'
import UploadDocuments from '../../UploadDocuments/UploadDocuments'

interface AdditionalParamsProps {}

const AdditionalParams = (props: AdditionalParamsProps) => {
  return (
    <div className={cx(styles.main)}>
      {/* Операции и Материалы */}
      <ServicesForm />

      {/* Акты  и Сканы*/}
      <div className={styles.documents}>
        <DownloadActs />
        <UploadDocuments />
      </div>
    </div>
  )
}
export default React.memo(AdditionalParams)
