import { IPicking, ISku } from 'src/interfaces'
import { IPickingFormDataState } from '../interfaces'

export const SET_PICKING_RAW = 'SET_PICKING_RAW'
export const setPickingRawAction = (documentRaw: { picking: IPicking, skus: ISku[] } | null) => {
  return {
    type: SET_PICKING_RAW,
    payload: documentRaw,
  }
}

export const SET_PICKING_FORM_STATE_DRAFT = 'SET_PICKING_FORM_STATE_DRAFT'
export const setPickingFormDraftAction = (formState: IPickingFormDataState) => {
  return {
    type: SET_PICKING_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_PICKING_FORM_STATE_RAW = 'SET_PICKING_FORM_STATE_RAW'
export const setPickingFormRawAction = (formState: IPickingFormDataState) => {
  return {
    type: SET_PICKING_FORM_STATE_RAW,
    payload: formState,
  }
}

