import React from 'react'
import { WarningModal } from '@/components'
import styles from './SelectConditionTypeModal.module.scss'

import { ConditionEnum, ModalProps } from '@/interfaces'
import { Button } from '@consta/uikit/Button'

interface ItemRemovingProps extends ModalProps {
  allSupplyItemsComplete?: boolean
  advanceMode: boolean
  currentCondition: ConditionEnum
  onSelectCondition: (condition: ConditionEnum) => void
}

type ButtonItemType = {
  title: string,
  condition: ConditionEnum | null,
  visible: boolean
}

const SelectConditionTypeModal = (props: ItemRemovingProps) => {
  const {
    allSupplyItemsComplete = false,
    advanceMode,
    currentCondition,
    onSelectCondition,
    onClose
  } = props

  const buttons: ButtonItemType[] = [
    {title: 'Приемка годного товара', condition: ConditionEnum.GOOD, visible: advanceMode},
    {title: 'Приемка брака', condition: ConditionEnum.HIDDEN_DEFECT, visible: true},
    {title: 'Приемка излишков', condition: ConditionEnum.HIDDEN_GOOD, visible: true},
    {title: 'Приемка излишков ЧЗ', condition: ConditionEnum.HIDDEN_WRONG_SERIAL_NUMBER, visible: true},
    {title: 'Завершить приемку', condition: null, visible: allSupplyItemsComplete},
  ]

  return (
    <WarningModal
      title='Какие товары будут приниматься дальше?'
      subtitle=' '
      isOpen={true}
      withClose
      onClose={onClose}
      onOverlayClick={onClose}
      customFooter={
        <div className={styles.btns}>
          {
            buttons.map(btn => {
              const btnWithCurrentCondition = currentCondition === btn.condition
              if (!btn.visible) return
              return (
                <Button
                  key={btn.condition}
                  label={`${btn.title} ${btnWithCurrentCondition ? '(текущий)' : ''}`}
                  disabled={btnWithCurrentCondition}
                  onClick={() => onSelectCondition(btn.condition)}
                />
              )
            })
          }
        </div>
      }
    />
  )
}

export default SelectConditionTypeModal
