import React from 'react'
import { IFile, ITableColumn, ITableRow } from 'src/interfaces'
import { Button, IconPDF } from 'src/components'
import styles from './UploadDocuments.module.scss'
import { IconRemove } from '@consta/icons/IconRemove'

export const generateColumns = (
  onDeleteFile: (id: string) => void,
): ITableColumn[] => [
  {
    key: 'name',
    title: 'Имя документа',
    title_txt: 'Имя документа',
    gridCellWidth: '200px',
    renderCell: ({ row }: any) => {
      return (
        <div className={styles.fileRow}>
          <div
            className={styles.fileContent}
            onClick={() => {
              if (!row.url) {
                console.error('Нет url файла')
                return
              }
              if (window) {
                window?.open(row.url, '_blank')?.focus()
              }
            }}
          >
            <IconPDF />
            <div className={styles.fileBody}>
              <p>{row.name || '-'}</p>
            </div>
          </div>
          <Button
            onlyIcon
            iconLeft={IconRemove}
            size="s"
            view="ghost"
            onClick={() => onDeleteFile(row.id)}
          />
        </div>
      )
    },
  },
]

export const generateRows = (files: IFile[]): ITableRow[] => {
  if (!files) {
    return []
  }
  return files.map((file) => {
    return {
      id: file.id,
      name: file.title,
      url: file.url,
    }
  })
}
