import React, { useMemo } from 'react'
import { FormTabs, generateTabs } from '../../../../../helpers/documentTabs'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { Comment, HistoryChanges, RelativeDocs } from '../../../../../components/CommonTabsPages'
import { AdditionalParams, DocumentsParamsWrapper } from '../SupplyTabsyPages'
import { updateFormStateDraftAction } from '../../../../../store/actions'
import { useMainFormContext } from '@pages/OperationsAndDocumentsPage/context/SupplyFormContext'
import { TabsContentWrapper } from '../../../../../components'
import { Loader } from '@consta/uikit/Loader'
import { DocumentTypeEnum } from '@/interfaces'

interface SupplyFormContentProps {
  loading?: boolean
}

const SupplyFormContent = (props: SupplyFormContentProps) => {
  const { loading } = props
  const {
    page,
    id: supplyId,
    tabId,
  } = useParams<{ page: OperAndDocumPagesType; id: string; tabId: string }>()

  const supplyTabsWithDescription = useMemo(
    () =>
      generateTabs([
        FormTabs.documentsParams,
        FormTabs.additionalParams,
        FormTabs.relativeDocs,
        FormTabs.comments,
        FormTabs.historyChanges,
      ]),
    [page],
  )

  return (
    <TabsContentWrapper tabs={supplyTabsWithDescription}>
      {loading ? <Loader /> : null}
      <TabPage tabId={tabId} documentId={supplyId} />
    </TabsContentWrapper>
  )
}

interface ITabContent {
  tabId: string
  documentId: string
}

const TabPage = React.memo(({ documentId, tabId }: ITabContent) => {
  const {
    state: { supplyRaw, formStateDraft },
    dispatch,
  } = useMainFormContext()

  switch (tabId) {
    case FormTabs.documentsParams:
      return <DocumentsParamsWrapper />
    case FormTabs.additionalParams:
      return <AdditionalParams />
    case FormTabs.relativeDocs:
      return <RelativeDocs documentId={documentId} documentType={DocumentTypeEnum.SUPPLY_PLAN}/>
    case FormTabs.comments:
      return (
        <Comment
          stateCommentValue={formStateDraft?.comment || ''}
          handleChangeComment={(value) =>
            dispatch(updateFormStateDraftAction('comment', value))
          }
        />
      )
    case FormTabs.historyChanges:
      return <HistoryChanges documentUUID={supplyRaw?.uuid} />
  }
  return null
})

export default SupplyFormContent
