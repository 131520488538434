import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { generateOrderColumns, generateOrderRows } from './utils'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { useShipmentPlanFormContext } from '../../../../../context/ShipmentPlanFormContext'
import { InputWrapper } from 'src/components'
import { DocumentsParams } from '../../../../../components'
import styles from './documents-params.module.scss'

interface SupplyFormContentProps {}


const DocumentsParamsFields = (props: SupplyFormContentProps) => {
  const { page, id: documentId } = useParams<{ page: OperAndDocumPagesType, id: string, tabId: string }>()
  const { state, dispatch } = useShipmentPlanFormContext()

  const orderRows = useMemo(() => generateOrderRows(state.documentRaw?.items || []), [state.documentRaw?.items])
  const orderColumns = useMemo(() => generateOrderColumns(), [])

  return (
    <DocumentsParams
      rows={orderRows}
      columns={orderColumns}
      tableTitle={'Места'}
    >
      <div className={styles.fieldsWrapper}>
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.delivery_service || ''}
          id={'deliveryService'}
          label={'Курьерская служба'}
          disabled
        />
      </div>
    </DocumentsParams>
  )
}
export default DocumentsParamsFields
