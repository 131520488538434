import { useQuery } from 'react-query'
import api from '@shared/api'
import { ApiReqResult, PaginationResult } from 'src/interfaces'
import { ITransportaion } from 'src/interfaces/transportationTemplates'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'

// http://wms-test.kak2c.ru/warehouse-api/transportation-item-template

export interface IGetTransportationParams {}

export interface IGetTransportationResult
  extends ApiReqResult,
    PaginationResult {
  templates: ITransportaion[]
}

const useGetTransportationTemplatesList = (
  paramsObj: IGetTransportationParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IGetTransportationResult>(
    [`getTransportationTemplatesList`, paramsObj],
    async () => {
      const searchParams = convertObjectToURLParams(paramsObj)
      const result = await api.get(
        `/transportation-item-template${searchParams}`,
      )

      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}
export default useGetTransportationTemplatesList
