import styles from './acceptence-sidebar.module.scss'
import {
  CONDITION_BADGE_TYPE,
  CONDITION_DESCRIPTION,
  ConditionEnum,
  IReducerAction,
  ISelectOption,
  ISupply,
  ISupplyReceipt,
} from '@/interfaces'
import { Badge } from '@consta/uikit/Badge'
import { Button, ItemScanner, Popover, Select, SideBar } from '@/components'
import React from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { SUPPLY_STATUSES, SUPPLY_STATUSES_STYLES } from '@/interfaces/statuses'
import { PATHS } from '@shared/routing'
import { Flex } from '@shared/ui/Flex'
import { useNotifications } from '@shared/providers/NotificationProvider'
import {
  setAdditionalStickerCountAction,
  setIndicateItemCount,
  setPreviewPrintAction,
  setShowAllTaresAction,
  showDiscrepanciesModalAction,
  showSupplyFormModalAction,
} from '../../../store/actions'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IconRestart } from '@consta/icons/IconRestart'
import { Checkbox } from '@consta/uikit/Checkbox'
import { IconBackward } from '@consta/icons/IconBackward'
import { Text } from '@shared/ui/Text'
import { ACCEPTANCE_TYPES } from '@pages/Acceptance/AcceptanceFormPage/utils/const'

interface IProps {
  currentCondition?: ConditionEnum
  isNonSellerAcceptance?: boolean
  dispatch: React.Dispatch<IReducerAction>
  tares: ISupplyReceipt[]
  allTares: ISupplyReceipt[]
  supply: ISupply | null
  openTareId?: string
  allWorkTablesPlacesVisible?: boolean
  handleCompleteParts: (forceComplete?: boolean) => void
  indicateItemCount: any
  finalItemsCount: number
  completeItemsCount: number
  isDone: boolean
  advanceMode: boolean
  showAllTares: boolean
  additionalStickerCount: number | null
  handleStartItem: (value: TextFieldPropValue, type: 'barcode' | 'id') => void
  reloadStateData: () => void
  barcodeInputRef: React.RefObject<
    HTMLTextAreaElement | HTMLInputElement | null
  >

  /* Тип пиремки - поштучно / коробами / паллетами */
  acceptanceOption?: ISelectOption | null
  setAcceptanceOption?: (value: ISelectOption | null) => void

  /* Завершаем текущее качество товара и выбираем следующее */
  handleCompleteCurrentCondition?: () => void
  taresIncludesHiddenConditions?: boolean
}

const AcceptanceSidebar = (props: IProps) => {
  const {
    currentCondition,
    isNonSellerAcceptance,
    advanceMode,
    tares,
    supply,
    dispatch,
    indicateItemCount,
    handleStartItem,
    barcodeInputRef,
    handleCompleteParts,
    reloadStateData,
    showAllTares,
    additionalStickerCount,
    finalItemsCount,
    completeItemsCount,
    isDone,
    openTareId,
    acceptanceOption,
    setAcceptanceOption,
    handleCompleteCurrentCondition,
    taresIncludesHiddenConditions,
  } = props

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const prevDocumentPagePath = params.get('prev')

  const history = useHistory()
  const notification = useNotifications()


  const handlePauseSupply = () => {
    history.push(generatePath(PATHS.ACCEPTANCE_SCAN))
  }

  const LabelValue = ({label, value}:{label: string, value?: string}) => (
    <Flex
      direction={'column'}
      gap={'2xs'}
      className={styles.labelValueWrapper}
    >
      <Text
        view={'secondary'}
        children={label}
      />
      <Text
        view={'secondary'}
        weight={'bold'}
        children={value}
      />
    </Flex>
  )

  const status = supply?.status
  const comment = supply?.comment
  return (
    <SideBar
      btnsClass={styles.leftPanelBtn}
      bottomBtns={
        <>
          <div>
            <Popover
              tooltipText={comment}
              direction={'rightCenter'}
              btnProps={{
                theme: !comment ? 'default' : 'danger',
                view: 'secondary',
                label: 'Комментарий',
                disabled: !comment,
              }}
            />
            <Button
              label='Отчет о расхождении'
              view='secondary'
              onClick={() => {
                dispatch(showDiscrepanciesModalAction(true))
              }}
            />
          </div>
          <div>
            {
              isNonSellerAcceptance && (taresIncludesHiddenConditions || advanceMode) ? (
                <Button
                  label='Выбрать качество товара'
                  onClick={() => {
                    if (openTareId) {
                      notification?.show('alert', 'Нельзя менять при открытой таре, закройте открытую тару!')
                      return
                    }
                    handleCompleteCurrentCondition()
                  }}
                />
              ) : null
            }

            {
              prevDocumentPagePath ? (
                <Button
                  label='Вернуться назад'
                  view='primary'
                  iconLeft={IconBackward}
                  onClick={() => {
                    history?.push(prevDocumentPagePath)
                  }}
                />
              ) : null
            }

            <Button
              view={'ghost'}
              label='Приостановить'
              onClick={handlePauseSupply}
            />
            {!isDone ? (
              <Button
                label='Завершить приемку'
                disabled={!tares.length}
                onClick={() => handleCompleteParts()}
              />
            ) : null}
          </div>
        </>
      }
    >
      <div className={styles.packName}>
        <Flex
          justifyContent={'between'}
        >
          {status ? (
            <Badge
              status={SUPPLY_STATUSES_STYLES[status]}
              label={SUPPLY_STATUSES[status]}
              className={styles.badge}
            />
          ) : null}
          <Flex
            direction={'column'}
            gap={'xs'}
          >
            <Badge
              status={supply?.supply_form?.length ? 'warning' : 'normal'}
              label={supply?.supply_form?.length ? 'Нестандартная приемка' : 'Стандартная приемка'}
              className={styles.badge}
            />
            {
              supply?.supply_form?.length ? (
                <Button
                  label='Анкета поставки'
                  view='secondary'
                  size={'xs'}
                  onClick={() => {
                    dispatch(showSupplyFormModalAction(true))
                  }}
                />
              ) : null
            }
          </Flex>
        </Flex>
        <Flex
          direction={'column'}
          gap={'2xs'}
        >
          <Text
            size={'l'}
            view={'secondary'}
            children={'Приемка товара'}
          />
          <Text
            as={'h2'}
            size={'2xl'}
            children={supply?.id}
          />
          <Text
            size={'l'}
            view={'secondary'}
            as={'span'}
          >
            {supply?.merchant?.title}{' '}
            {supply?.merchant?.trade_title !== supply?.merchant?.title ? (
              <>
                {
                  !supply?.merchant?.trade_title
                  || !supply?.merchant?.title
                  || ' | '
                }
                {' '}
                {supply?.merchant?.trade_title}
              </>
            ) : null}
          </Text>
        </Flex>

        {supply?.supplier ? (
          <LabelValue
            label={'Поставщик'}
            value={supply?.supplier?.title}
          />
        ) : null}
        {supply?.accompanying_doc_num ? (
          <LabelValue
            label={'Номер сопровод-го документа'}
            value={supply?.accompanying_doc_num}
          />
        ) : null}
      </div>
      {
        currentCondition ?
          <Flex
            gap={'xs'}
            align="center"
            className={styles.condition}
          >
            <Text
              size={'l'}
              view={'secondary'}
              children={`Принимаемый товар `}
            />
            <Badge
              status={CONDITION_BADGE_TYPE[currentCondition]}
              label={CONDITION_DESCRIPTION[currentCondition]}
              className={styles.badge}
            />
          </Flex>
          : null
      }
      <div className={styles.packCount}>
        Принято штук:
        <div>
          <h2 className={styles.accepted}>{completeItemsCount}</h2>
          <p>из</p>
          <h2>{finalItemsCount}</h2>
        </div>
      </div>

      <ItemScanner
        showPreviewPrintCheckbox
        label='Отсканируйте штрихкод'
        itemCountCheckboxValue={indicateItemCount}
        onItemCountCheckboxChange={(checked) => {
          dispatch(setIndicateItemCount(checked))
        }}
        onPreviewPrintCheckboxChange={(printType) => {
          dispatch(setPreviewPrintAction(printType))
        }}
        onChange={(val) => handleStartItem(val, 'barcode')}
        inputRef={barcodeInputRef}
        extraSlot={
          <>
            <Checkbox
              label='Доп. стикер'
              checked={Boolean(additionalStickerCount)}
              onChange={(e) => {
                dispatch(setAdditionalStickerCountAction(e.checked ? 1 : null))
              }}
            />
            <Checkbox
              label='Показать все тары в любом статусе'
              checked={showAllTares}
              disabled={!advanceMode}
              onChange={(e) => {
                dispatch(setShowAllTaresAction(e.checked))
              }}
            />
          </>
        }
      />

      {
        setAcceptanceOption &&
        <Select
          label='Указать тип приемки'
          options={ACCEPTANCE_TYPES}
          value={acceptanceOption}
          onChange={({ value }) => setAcceptanceOption(value)}
          className={styles.select}
        />
      }
      <Button
        label='Обновить'
        view='ghost'
        iconLeft={IconRestart}
        onClick={reloadStateData}
        width={'full'}
      />
    </SideBar>
  )
}

export default AcceptanceSidebar
