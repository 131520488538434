import {
  ConditionEnum,
  IAcceptanceSku,
  IAcceptanceSkuObj,
  IBatch,
  ISupplyItem,
  ISupplyReceipt,
  ISupplyReceiptItem,
  ISupplyReceiptItemRequest,
  ReceiptStatusEnum,
  SupplyTypEnum,
  TareStatusEnum,
  TrustBox,
} from '@/interfaces'
import { DATE_FORMAT } from '@shared/const/date'
import { CHZ_CODE, isChz } from '@shared/helpers/chz'
import { IAcceptanceFormState } from '../store/interfaces'
import dayjs from 'dayjs'

export const validateCreationTare = (
  openTareId: string | null,
  currentTare?: ISupplyReceipt,
) => {
  if (openTareId) {
    const text = 'Необходимо закрыть текущую тару, перед началом приемки новой'
    window.showNotification('alert', text)
    return text
  }
  if (currentTare) {
    const text = 'Тара с таким ШК уже существует'
    window.showNotification('alert', text)
    return text
  }
}

export const validateAddingItem = (
  openTareId: string | null,
  barcode: string,
  currentItem?: IAcceptanceSku,
) => {
  /* Если ШК не тары, проверяем есть ли открытая тара */
  if (!openTareId) {
    const textErr = 'Перед началом приемки необходимо открыть тару'
    window.showNotification('alert', textErr)
    return textErr
  }

  /* Товар с ШК не найден */
  if (!currentItem) {
    const textErr = `Товар со штрихкодом ${barcode} не найден`
    window.showNotification('alert', textErr, {
      soundName: 'PACKING_WRONG_ITEM',
    })
    return textErr
  }
}
/** Получение товара из поставки по ID товара */
export const getCurrentSupplyItem = (
  skuId: string,
  supplyItems: ISupplyItem[],
): ISupplyItem | undefined => {
  return supplyItems?.find((supplyItem) => supplyItem.sku_id === skuId)
}
/** Получение оригинального товара по ID или ШК (barcode) */
export const getCurrentSkuItem = (
  originalSkus: IAcceptanceSku[],
  originalSkusObj: IAcceptanceSkuObj,
  id: string,
  idType: 'barcode' | 'id',
): IAcceptanceSku | undefined => {
  let item = null

  if (idType === 'barcode') {
    const correctBarcode = isChz(id) ? id.slice(2, 16) : id
    item = originalSkus?.find((item) =>
      item?.barcodes?.find(
        (itemBarcode) => itemBarcode?.barcode === correctBarcode,
      ),
    )
  } else {
    item = originalSkusObj[id]
  }

  if (!item) return
  return item
}
/** Получение тары по ШК */
export const getTareByBarcode = (
  barcode: string,
  tares: ISupplyReceipt[],
): ISupplyReceipt | undefined => {
  return tares.find((tare) => tare.tare_barcode === barcode)
}
/** Проверка на закрытие тары при сканировании ШК */
export const findTareByBarcode = (
  tareBarcode: string,
  tares: ISupplyReceipt[],
) => {
  return tares.find((tare) => tare.tare_barcode === tareBarcode)
}

/** Проверка на закрытость тары для переоткрытия */
export const tareClosedCheck = (tare: ISupplyReceipt) => tare?.status === ReceiptStatusEnum.closed

/**
 * Получиние и формирование данных товара в нужный формат для
 * формы удаления товара
 */
type RemovingItemDataResult = {
  isTrustBox?: boolean
  currentOriginalSku: IAcceptanceSku
  currentTare: ISupplyReceipt
  currentTareItems: ISupplyReceiptItem[]
}
type RemovingItemDataArgs = {
  openTareId: string | null
  removingItemBarcode: string | null
  originalSkus: IAcceptanceSku[]
  originalSkusObj: IAcceptanceSkuObj
  supplyItems: ISupplyItem[]
  tares: ISupplyReceipt[]
  resetRemovingBarcode: () => void
  showAlertNotification: (text: string) => void
  trustBoxes?: TrustBox[]
}
export const makeRemovingItemData = (
  args: RemovingItemDataArgs,
): RemovingItemDataResult | null => {
  const {
    openTareId,
    removingItemBarcode,
    originalSkus,
    originalSkusObj,
    supplyItems,
    tares,
    resetRemovingBarcode,
    showAlertNotification,
    trustBoxes
  } = args

  if (!removingItemBarcode) {
    return null
  }

  const isTrustBox = trustBoxes.find(box => box.barcode === removingItemBarcode)

  const currentOriginalSku = getCurrentSkuItem(
    originalSkus,
    originalSkusObj,
    removingItemBarcode,
    'barcode',
  )

  if (!currentOriginalSku && !isTrustBox) {
    window.showNotification(
      'alert',
      'Не найден оригинальный товар при удалении',
    )
    return null
  }
  const supplyItem = getCurrentSupplyItem(
    currentOriginalSku?.id,
    supplyItems || [],
  )

  const { tare: currentTare, tareItems: currentTareItems } = tares.reduce((
      accum: { tare: ISupplyReceipt | null, tareItems: ISupplyReceiptItem[] | null },
      tare,
    ) => {
      if (tare.status !== TareStatusEnum.open) {
        return accum
      }
      const tareItems = tare.items.filter(
        (item) =>
          (currentOriginalSku?.id === item.sku_id)
          || item.trust_acceptance_box_barcode === removingItemBarcode
        ,
      )
      if (tareItems?.length) {
        return { tare, tareItems }
      }
      return accum
    },
    { tare: null, tareItems: null },
  )

  if (!currentTareItems) {
    showAlertNotification('Товар с таким ШК в таре не найден')
    resetRemovingBarcode()
    return null
  }
  if (currentTare?.id !== openTareId) {
    showAlertNotification('Товар с таким ШК находится в закрытой таре')
    resetRemovingBarcode()
    return null
  }

  return {
    isTrustBox: Boolean(isTrustBox),
    currentOriginalSku: !isTrustBox ? {
      ...currentOriginalSku,
      needScanSerialCodes: supplyItem?.scan_serial_numbers || [],
    } : null,
    currentTare,
    currentTareItems,
  }
}

export const checkItemIsSurplus = (
  addingItem: ISupplyReceiptItemRequest,
  state: IAcceptanceFormState,
): boolean => {
  /**
   * Проверяем, являетсял и товар излишком или нет
   */

  /** Текущее состояние плана приемки товара (не первоначальное, а изменяющееся) */
  const supplyItem = state.supplyItems.find((suppItem) =>
      suppItem.sku_id === addingItem.sku_id
      && (suppItem.sku_batch_id ? suppItem.sku_batch_id === addingItem.sku_batch_id : true)
  )
  if (!supplyItem) {
    /**
     * Если товара в плане нет, значит это излишек (2 случая):
     *  - Уже все добавили и новый товар идет поверх
     *  - В планах его и не было
     */
    return true
  }
  if (addingItem.quantity > supplyItem.quantity) {
    /** Если добавляемого товара больше планируемого */
    return true
  }

  return false
}

export const getAddingItemWithoutId = ({ id, ...restAddingItem }: ISupplyReceiptItem): ISupplyReceiptItemRequest => {
  return restAddingItem
}


export const getFormattedTareItems = (
  tare: ISupplyReceipt,
  tares: ISupplyReceipt[],
  originalSkusObj: IAcceptanceSkuObj
) => {
  return tare.items.reduce(
    (
      accum: ISupplyReceiptItem[],
      item: ISupplyReceiptItem,
      i: number,
      arr: ISupplyReceiptItem[],
    ) => {
      const findCurrentTareItem = accum.find(
        (ci: ISupplyReceiptItem) => ci.id === item.id,
      )

      const findCurrentItem = originalSkusObj[item?.id]

      if (findCurrentTareItem && findCurrentItem) {
        findCurrentTareItem.quantity =
          findCurrentTareItem.quantity + item.quantity
      } else {
        accum.push({ ...item })
      }
      return accum
    },
    [] as ISupplyReceiptItem[],
  )
}

// генерация тары с товарами в удобном фомрате
export const getFormattedTares = (
  tares: ISupplyReceipt[],
  originalSkusObj: IAcceptanceSkuObj,
  searchBarcode?: string
) =>
  tares
    .slice()
    .map((tare: ISupplyReceipt) => ({
      ...tare,
      items: getFormattedTareItems(tare, tares, originalSkusObj),
    }))
    .filter(tare => searchBarcode ? tare.tare_barcode.includes(searchBarcode) : true)
    .sort((a, b) => (a.status === TareStatusEnum.open ? -1 : 1))


/* Получение create_date по партии и срока годности */
export const getCreateDateByBatch = (batch?: IBatch, expiration_months?: number) => {
  const expDate = dayjs(batch?.exp_date, DATE_FORMAT)
  return (
    expDate.isValid() && batch
      ? expDate.subtract(expiration_months || 999, 'month').format(DATE_FORMAT)
      : undefined
  )
}


/* Проверяем по типу приемки для разного функционала */
export const checkIsNonSellerAcceptance = (supplyType: SupplyTypEnum) => {
  // Андрей С попросил сделать эту приемку отдельно
  // return [SupplyTypEnum.FROM_DISTRIBUTION_CENTER, SupplyTypEnum.FROM_SUPPLIER].includes(supplyType)
  return [SupplyTypEnum.FROM_SUPPLIER].includes(supplyType)
}
/* Проверяем есть ли в тарах скрытые годности */
export const checkTaresIncludesHiddenConditions = (receipts: ISupplyReceipt[] = []) => {
  return receipts.some(tare =>
    [ConditionEnum.HIDDEN_DEFECT, ConditionEnum.HIDDEN_GOOD, ConditionEnum.HIDDEN_WRONG_SERIAL_NUMBER].includes(tare.condition),
  )
}

/* Получаем корректный тип тары, по излишку товара */
export const getCorrectTareConditionBySurplusSku = (scanSerialNumbers: string[]): ConditionEnum => {
  const itemHasChz = scanSerialNumbers?.includes(CHZ_CODE)
  return itemHasChz
    ? ConditionEnum.HIDDEN_WRONG_SERIAL_NUMBER
    : ConditionEnum.HIDDEN_GOOD
}
