import React, { useState } from 'react'
import { Modal } from '@/components'
import { Button } from '@consta/uikit/Button'
import styles from './TableModal.module.scss'
import { TemplateTable, TemplateTableData, TemplateTableRow } from '@shared/types/tableGenerator/generatorTypes'
import { ModalProps } from '@/interfaces'
import { v4 } from 'uuid'
import { cloneDeep } from '@shared/helpers'
import TableGenerator from '@widgets/TableGenerator/TableGenerator/TableGenerator'
import { NEW_ROW_ID_PREFIX } from '@pages/Documents/DocumentForm/helpers/const'

interface Props extends ModalProps {
  editMode: boolean
  tableData: TemplateTableData,
  table: TemplateTable
  handleSubmit: (rows: TemplateTableRow[]) => void
}

const TableModal = (props: Props) => {
  const {
    isOpen,
    editMode,
    tableData,
    onClose,
    table,
    handleSubmit,
  } = props

  const [rows, setRows] = useState<TemplateTableRow[]>(cloneDeep(tableData.rows))

  const handleCancelChanges = () => {
    onClose()
  }

  const handleCreateItem = () => {
    const newRow = {
      id: `${NEW_ROW_ID_PREFIX}${v4()}`,
      cells: table.columns.map(col => ({
        value: null,
        id: col.id,
        read_only: col.read_only,
        related_column_id: col.related_column_id,
        type: col.type,
        required: col.required,
      })),
    }
    setRows(prev => [...prev, newRow])
  }
  const handleDeleteItem = (rowId: string) => {
    setRows(prev => {
      return prev.filter((row) => rowId !== row.id)
    })
  }

  const handleChange = (tableData: TemplateTableData) => {
    setRows(tableData.rows)
  }
  const handleClickSubmit = () => {
    handleSubmit(rows)
  }

  return (
    <Modal
      isOpen={true}
      hasOverlay={true}
      onOverlayClick={handleCancelChanges}
      title='Изменение данных в таблице'
      size='xl'
    >
      <TableGenerator
        maxHeightBody={600}
        editMode={editMode && isOpen}
        tableData={{ ...tableData, rows }}
        table={table}
        handleChangeTableField={handleChange}
        handleCreateItem={handleCreateItem}
        handleDeleteItem={handleDeleteItem}
      />
      <div className={styles.footer}>
        <Button label='Отмена' view={'ghost'} onClick={handleCancelChanges} />

        {
          editMode ? (
            <Button label='Принять изменения' view={'primary'} onClick={handleClickSubmit} />
          ) : null
        }
      </div>
    </Modal>
  )
}

export default TableModal
