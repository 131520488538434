import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { privateRoutes, publicRoutes, Routes } from '../../index'

import { default as TemplateWrapper } from '../TemplateWrapper/TemplateWrapper'
import { useDefaultSettings } from '@shared/hooks/settings'
import { Loader } from '@consta/uikit/Loader'


const RenderRoutes = (routes: Routes, isPrivate: boolean) => {
  const {
    defaultArm
  } = useDefaultSettings()

  const baseRoutePath = routes.find((route) => route.default)?.path
  const defaultPath = isPrivate
    ? defaultArm || baseRoutePath
    : baseRoutePath

  return (
    <Switch>
      <Redirect from='/:url*(/+)' to={window.location.pathname.slice(0, -1)} />
      {routes.map((route) => (
        <Route exact key={route.path} path={route.path}>
          <TemplateWrapper template={route.template}>
            <Suspense
              fallback={<Loader style={{top: '50%', left: '50%', position: 'absolute'}}/>}
            >
              <route.component {...route.props} />
            </Suspense>
          </TemplateWrapper>
        </Route>
      ))}
      {defaultPath && <Redirect to={defaultPath} />}
    </Switch>
  )
}

export const renderPrivateRoutes = () => RenderRoutes(privateRoutes, true)
export const renderPublicRoutes = () => RenderRoutes(publicRoutes, false)
