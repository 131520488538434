import { useQuery } from 'react-query'
import api from '@shared/api'
import { ApiReqResult, IOrderTask } from 'src/interfaces'

import { UseQueryOptions } from 'react-query/types/react/types'

export interface IGetTaskParams {
  completingId: string
}
export interface IGetTaskResult extends ApiReqResult {
  order: IOrderTask
}
// http://wms-test.kak2c.ru/warehouse-api/physical-set-order/{order_id}
const useGetTask = (params: IGetTaskParams, options?: UseQueryOptions<any>) => {
  return useQuery<IGetTaskResult>(
    [`getTask`, params],
    async () => {
      const result = await api.get(`/physical-set-order/${params.completingId}`)
      console.log(result.data)

      return result.data
    },
    options,
  )
}
export default useGetTask
