import React, { useEffect, useMemo, useState } from 'react'
import { DocumentsListWrapper } from '@pages/OperationsAndDocumentsPage/components'
import { useDeleteBilling, useGetServices, useGetServicesList } from 'src/hooks'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { Button } from 'src/components'
import ServicesRenderedModal from '../ServicesRenderedModal/ServicesRenderedModal'
import { IServicesDocuments } from 'src/interfaces'
import { useHistory } from 'react-router-dom'
import ConfirmModal from '../ConfirmModal/ConfirmModal'
import { useFilter } from '../../../hooks/useFilter'

const ServicesRenderedList = () => {
  const { filterState, setFilterState } = useFilter()
  const [servicesSearch, setServicesSearch] = useState<string>('')
  const [openModal, setOpenModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [deleteDocId, setDeleteDocId] = useState<string | null>(null)
  const history = useHistory()
  const [document, setDocument] = useState<IServicesDocuments | null>(null)
  const { data: services } = useGetServicesList({params: {search: servicesSearch}})
  const { mutateAsync: deleteDoc } = useDeleteBilling()
  const {
    isFetching: servicesLoading,
    data: servicesData,
    refetch: refetchServices,
  } = useGetServices(generateFilterParams(filterState))

  useEffect(() => {
    refetchServices()
  }, [filterState])

  const handleEdit = (doc: IServicesDocuments) => {
    setDocument(doc)
    setOpenModal(true)
  }
  const handleConfirmModal = (docId: string) => {
    setOpenConfirmModal(true)
    setDeleteDocId(docId)
  }
  const handleDelete = (docId: string | null) => {
    if (!docId) {
      return
    }
    deleteDoc({ docId })
      .then((data) => {
        refetchServices()
      })
      .catch(() => {})
  }

  const rows = useMemo(
    () => generateRows(servicesData?.documents || []),
    [servicesData?.documents],
  )

  const columns = useMemo(
    () => generateColumns({ handleEdit, handleConfirmModal }),
    [],
  )
  const filtersConfig = generateFilters()

  const handleClick = () => {
    // history.push(
    //   generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
    //     page: OperAndDocumPages.servicesRenderedAddDocument,
    //   }),
    // )
    setDocument(null)
    setOpenModal(true)
  }

  return (
    <>
      <DocumentsListWrapper
        title={'Оказанные услуги'}
        rows={rows}
        columns={columns}
        filterState={filterState}
        setFilterState={setFilterState}
        filtersConfig={filtersConfig}
        loading={servicesLoading}
        pagination={servicesData?.pagination}
        tableExtraHeaderRight={
          <Button label={'Добавить'} onClick={handleClick} />
        }
      />
      {openModal ? (
        <ServicesRenderedModal
          services={services?.services}
          servicesTotalCount={services?.pagination?.total_elements}
          isOpen
          document={document}
          onClose={() => setOpenModal(false)}
          refetch={refetchServices}
          setServicesSearch={setServicesSearch}
        />
      ) : null}
      {openConfirmModal ? (
        <ConfirmModal
          isOpen={true}
          onClose={() => {
            setDeleteDocId(null)
            setOpenConfirmModal(false)
          }}
          onSubmit={() => {
            handleDelete(deleteDocId)
            setOpenConfirmModal(false)
            setDeleteDocId(null)
          }}
        />
      ) : null}
    </>
  )
}
export default ServicesRenderedList
