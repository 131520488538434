import { FormInputCalcItem, PreCalcItem, Service, ServiceDocument } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

export type OperationsAndMaterialsResult = {
  operations: Service[],
  materials: Service[],
  /** Значения для формы */
  operationsInputService: FormInputCalcItem[],
  materialsInputService: FormInputCalcItem[],
  /** Начальные значения */
  operationsPreCalcItems: PreCalcItem[],
  materialsPreCalcItems: PreCalcItem[],
}
export const getOperationsAndMaterials = (
  allServices: Service[],
  preCalcItems: PreCalcItem[],
  documents: ServiceDocument[],
) => {
  /** Сортируем данные с сервера по Опреациям и Материалам */
  const result: OperationsAndMaterialsResult = {
    operations: [],
    operationsInputService: [],
    operationsPreCalcItems: [],

    materials: [],
    materialsInputService: [],
    materialsPreCalcItems: [],
  }
  const documentItems = documents[0]?.items || []
  return allServices.reduce((accum, service) => {
    const documentItem = documentItems.find(docItem => docItem.service_id === service.id)
    const preCalcItem = preCalcItems.find(calcItem => calcItem.service_id === service.id)
    const documentItemServiceValue = documentItem
      ? { ...documentItem, quantity: documentItem?.quantity || null }
      : null

    if (service.is_material) {
      accum.materials.push(service)
      if (documentItemServiceValue) {
        accum.materialsInputService.push(documentItemServiceValue)
      }
      if (preCalcItem) {
        accum.materialsPreCalcItems.push(preCalcItem)
      }
    } else {
      accum.operations.push(service)
      if (documentItemServiceValue) {
        accum.operationsInputService.push(documentItemServiceValue)
      }
      if (preCalcItem) {
        accum.operationsPreCalcItems.push(preCalcItem)
      }
    }
    return accum
  }, result)
}

export const updateCalcItems = (calcItemsList: FormInputCalcItem[], calcItemId: string, newValueCalcItem: TextFieldPropValue) => {
  /** Важно сохранить тот же объект, чтобы избавиться от перерендера */
  const currentCalcItem = calcItemsList.find(calcItem => calcItem.service_id === calcItemId)
  const value = Number(newValueCalcItem) || 0
  if (currentCalcItem) {
    currentCalcItem.quantity = value
    return calcItemsList
  }

  calcItemsList.push({
    service_id: calcItemId,
    quantity: value,
  })
  return calcItemsList
}

export const getNewMaterialServices = (services: Service[], materialsInputService: FormInputCalcItem[]) => {
  return services.map(service => {
    const materialInputService = materialsInputService.find(inputService => inputService.service_id === service.id)
    return ({ quantity: materialInputService?.quantity || null, service_id: service.id })
  })
}

export const removeServices = (materialsServices: Service[], materialsServicesIds: string[]): Service[] => {
  /** Удаляем выбранные услуги */
  return materialsServices.filter(materialService => !materialsServicesIds.includes(materialService.id))
}
export const removeCalcItems = (materialsInputService: FormInputCalcItem[], materialsServicesIds: string[]): FormInputCalcItem[] => {
  /** Удаляем выбранные данные */
  return materialsInputService.filter(calcItem => !materialsServicesIds.includes(calcItem.service_id))
}