import { useCallback, useState } from 'react'

type Props = {
  defaultState?: boolean
}
export const useToggle = (props?: Props) => {

  const [value, setValue] = useState<boolean>(props?.defaultState || false)

  const toggle = useCallback(() => {
    setValue(prev => !prev)
  }, [])

  const turnOff = useCallback(() => {
    setValue(false)
  }, [])

  const turnOn = useCallback(() => {
    setValue(true)
  }, [])

  return {
    value,
    toggle,
    setValue,
    turnOff,
    turnOn
  }
}
