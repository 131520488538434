import React from 'react'
import cx from 'classnames'

import styles from './item-scanner.module.scss'

import { Checkbox } from '@consta/uikit/Checkbox'

import { useAppContext } from '@shared/providers/AppContextProvider'
import { rusToLatin } from '@shared/helpers'
import { InputWrapper, PrintPreviewCheckbox } from 'src/components'
import { PrintTypeEnum } from '../../interfaces'


interface ItemScannerProps {
  onChange: (value: string | null) => void
  itemCountCheckboxValue?: boolean
  showItemCountCheckbox?: boolean
  showPreviewPrintCheckbox?: boolean
  onPreviewPrintCheckboxChange?: (printType: PrintTypeEnum) => void
  onItemCountCheckboxChange?: (value: boolean) => void
  className?: string
  label?: string
  advanceModeForCount?: boolean
  inputRef?: React.MutableRefObject<
    HTMLTextAreaElement | HTMLInputElement | null
  >
  additional?: React.ReactNode
  extraSettingsSlot?: React.ReactNode
  disabled?: boolean
  extraSlot?: React.ReactNode
}

const ItemScanner = (props: ItemScannerProps) => {
  const {
    onChange,
    itemCountCheckboxValue = false,
    onItemCountCheckboxChange = () => false,
    className,
    label,
    advanceModeForCount = false,
    inputRef,
    additional,
    showItemCountCheckbox = true,
    showPreviewPrintCheckbox,
    onPreviewPrintCheckboxChange,
    disabled = false,
    extraSlot
  } = props

  const { advanceMode } = useAppContext()

  const handleChange = (value: string | null) => {
    onChange(value ? rusToLatin(value) : null)
  }

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.group}>
        <InputWrapper
          label={label}
          className={styles.itemInput}
          autoFocus
          withDelay
          size="l"
          handleChange={handleChange}
          inputRef={inputRef}
          disabled={disabled}
        />
        {additional ? <>{additional}</> : null}
      </div>
      <div className={styles.underInput}>
        {showItemCountCheckbox ? (
          <Checkbox
            label="Указывать количество товара"
            checked={itemCountCheckboxValue}
            disabled={advanceModeForCount ? !advanceMode : false}
            onChange={(e) => {
              onItemCountCheckboxChange(e.checked)
              if (inputRef?.current) {
                inputRef?.current?.focus()
              }
            }}
          />
        ) : null}
        {showPreviewPrintCheckbox && onPreviewPrintCheckboxChange ? (
          <PrintPreviewCheckbox
            onChange={onPreviewPrintCheckboxChange}
          />
        ):null}
        {extraSlot}
      </div>
    </div>
  )
}

export default ItemScanner
