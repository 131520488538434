import { RefObject, useCallback, useEffect, useState } from 'react'
import { ITableColumn, ITableRow } from '@/interfaces'
import useTableSelectHelper from './useTableSelectHelper'
import { useHotkeyCopy, useKeyDownEscape } from '@shared/hooks'
import { useNotifications } from '@shared/providers/NotificationProvider'


export type CopyTupleType = [string, string]

type PropsType = {
  tableRef: Readonly<RefObject<HTMLElement>>,
  withCopy: boolean
  columns: ITableColumn[]
  rows: ITableRow[]
  generateColumnsCopyValue: (columns: ITableColumn[]) => string[]
  generateRowCopyValue: (columns: ITableColumn[], row: ITableRow) => string[]
}

// Расшифровка кортежа [{columnIndex}, {rowIndex.subrowIndex...}]
const initialState:CopyTupleType = ['', '']

/* Хук для помощи копирования таблиц */
export const useTableCopy = (props: PropsType) => {
  const {
    tableRef,
    withCopy,
    columns,
    rows,
    generateColumnsCopyValue,
    generateRowCopyValue
  } = props

  const notification = useNotifications()

  const [startCopyRegime, setStartCopyRegime] = useState<boolean>(false)

  // Для копирования только строк, без хедера столбцов
  const [startForCopy, setStartForCopy] = useState<CopyTupleType>(initialState)
  const [endForCopy, setEndForCopy] = useState<CopyTupleType>(initialState)

  const resetCopyState = useCallback(() => {
    const hasStartForCopy = startForCopy.filter(Boolean)?.length
    const hasEndForCopy =  endForCopy.filter(Boolean)?.length
    if (hasStartForCopy) {
      setStartForCopy(initialState)
    }
    if (hasEndForCopy) {
      setEndForCopy(initialState)
    }
  }, [endForCopy, startForCopy])

  useHotkeyCopy({
    isActive: withCopy,
    copyActive: Boolean(startForCopy[0]),
    selectAllActive: Boolean(startForCopy[0]),
    copyHandler: clickCopy,
    selectAllHandler: selectAll,
  }, [startForCopy, endForCopy, rows, columns])

  useKeyDownEscape({
    isActive: withCopy,
    handler: resetCopyState,
  }, [startForCopy, endForCopy])

  useEffect(() => {
    if (!withCopy) {
      resetCopyState()
    }
  }, [resetCopyState, withCopy])

  const isLeftBtn = (e) => e.button === 0

  // Включаем / выключаем режим выделения
  useEffect(() => {
    if (!withCopy) return

    // Вычисляем клик внутри таблицы, чтобы избавиться от лишнего перерендера
    const isMouseInBound = (e) => {
        const eleBounds = tableRef.current.getBoundingClientRect();
        return (
          (e.clientX >= eleBounds.left && e.clientX <= eleBounds.right) // inside X
          && (e.clientY >= eleBounds.top && e.clientY <= eleBounds.bottom) // inside Y
        )
    };
    const onMouseDown = (e) => {
      if (isMouseInBound(e) && isLeftBtn(e)) {
        setStartCopyRegime(true)
      }
    }
    const onMouseUp = (e) => {
      if (isMouseInBound(e) && isLeftBtn(e)) {
        setStartCopyRegime(false)
      }
    }

    document.addEventListener('mousedown',onMouseDown)
    document.addEventListener('mouseup',onMouseUp)

    return () => {
      document.removeEventListener('mousedown',onMouseDown)
      document.removeEventListener('mouseup',onMouseUp)
    }
  }, [tableRef, withCopy])


  const {
    getRowIndex,
    getCorrectIndexes
  } = useTableSelectHelper({startForCopy, endForCopy})

  function selectAll() {
    setStartForCopy(['0','0'])
    setEndForCopy([`${columns.length+1}`,`${rows.length+1}`])
  }
  function clickCopy() {
    const {
      columnStartIndex,
      columnEndIndex,
      rowStartIndex,
      rowEndIndex,
    } = getCorrectIndexes(startForCopy, endForCopy)

    if (!columnStartIndex && !columnEndIndex && !rowStartIndex && !rowEndIndex) {
      return
    }

    const columnIsSelected = rowStartIndex[0] === '0' || rowEndIndex[0] === '0'

    const selectedColumns = columns.slice(
      Number(columnStartIndex),
      Number(columnEndIndex)+1
    )

    const columnsKeys = selectedColumns.map(column => column.copy_key || column.key)
    const generateRowDataForCopy = (row: ITableRow) => {
      const rowValue = Object.keys(row).reduce((acc, rowKey) => {
        if (columnsKeys.includes(rowKey)) {
          acc[rowKey] = row[rowKey]
        }
        return acc
      }, {} as ITableRow)

      const rowDataByColumns = generateRowCopyValue(selectedColumns, rowValue)
      return rowDataByColumns.map(item => item ? item : '').join(' \t ')
    }

    const getRowsForCopy = (rows: ITableRow[], prevIndex: string): string[] => {
      return rows.reduce((acc, row, index) => {
        const rowIndex = getRowIndex(prevIndex, index)
        if ((+rowIndex >= +rowStartIndex && +rowIndex <= +rowEndIndex) || (rowEndIndex === '0' && rowStartIndex === '0')) {
          const rowValue = generateRowDataForCopy(row)
          acc.push(rowValue)
        }
        if (row.subItems) {
          return [...acc, ...getRowsForCopy(row.subItems, rowIndex)]
        }
        return acc
      }, [] as string[])
    }

    let resultRows = getRowsForCopy(rows, '')
    if (columnIsSelected) {
      const columnKeysStr = generateColumnsCopyValue(selectedColumns).join('\t')
      resultRows = [columnKeysStr, ...resultRows]
    }
    navigator.clipboard.writeText(resultRows.join('\n'))
    notification?.show('success', 'Скопировано')
  }


  /* Методы для работы с ячейками */
  const handleMouseOverRow = (columnIndex: string, rowIndex: string, e) => {
    if (!withCopy || !isLeftBtn(e)) return
    setEndForCopy([columnIndex, rowIndex])
  }
  const handleMouseDownRow = (columnIndex: string, rowIndex: string, e) => {
    if (!withCopy || !isLeftBtn(e)) return
    if (startForCopy) {
      setEndForCopy([columnIndex, rowIndex])
    }
    setStartForCopy([columnIndex, rowIndex])
  }



  return {
    clickCopy,
    resetCopyState,

    startCopyRegime,
    setStartCopyRegime,
    startForCopy,
    setStartForCopy,
    endForCopy,
    setEndForCopy,

    handleMouseOverRow,
    handleMouseDownRow,
  }
}