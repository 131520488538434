import React, { FC } from 'react'
import { LinkCell } from 'src/components'
import { useHistoryPush } from '../../hooks/useHistoryPush'
import { OperAndDocumPagesType } from '../../pagesType'
import { FormTabs } from '../../helpers/documentTabs'

interface DocumentNumCellProps {
  title?: string
  documentNum: string
  page: OperAndDocumPagesType
  tabId?: FormTabs
  withNewTab?: boolean
}
const DocumentNumCell: FC<DocumentNumCellProps> = (props) => {
  const { historyPush, getLinkPath } = useHistoryPush()
  const {
    title,
    page,
    tabId,
    documentNum,
    withNewTab
  } = props

  return (
    <LinkCell
      to={''}
      title={title || documentNum}
      onClick={(e) => {
        e.preventDefault();
        if (withNewTab) {
          window.open(getLinkPath(page, documentNum, tabId), "_blank")?.focus();
          return
        }
        historyPush(page, documentNum, tabId)
      }}
    />
  )
}

export default DocumentNumCell
