import { useQuery } from 'react-query'
import api from '@shared/api'
import { ApiReqResult, IError } from '../../interfaces'
import { IZone } from '../../interfaces/warehouse/zone'

interface IGetZonesResult extends ApiReqResult {
  errors?: IError[]
  zones: IZone[]
}
const useGetWarehouseZones = (
  callback?: (data: IZone[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery(
    [`getWarehouseZones`],
    async () => {
      const result = await api.get(`/warehouse/zone`)
      if (result?.status === 200) {
        return result.data
      }
      if (!result.data?.success) {
        throw new Error(result.data.message)
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,

      onSuccess: ({ success, zones }) => {
        if (success) {
          callback?.(zones)
        } else {
          error?.()
        }
      },
      onError: () => error?.(),
      onSettled: () => settled?.(),
    },
  ) as {
    refetch: any
    isLoading: boolean
    isError: boolean
    data: IGetZonesResult
  }
}

export default useGetWarehouseZones
