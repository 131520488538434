import React, { FC } from 'react'

import BillingList from './BillingList/BillingList'
import BillingForm from './BillingForm/BillingForm'

import { BillingContextProvider, useBillingContext } from '../../context/BillingContext'

interface BillingProps {
  type?: 'list' | 'form'
}

const Billing: FC<BillingProps> = ({
  type = 'list'
}) => {
  const { state, dispatch } = useBillingContext()

  return (
    <BillingContextProvider>
      {
        type === 'list'
          ? (<BillingList />)
          : (<BillingForm />)
      }
    </BillingContextProvider>
  )
}

Billing.displayName = 'Billing'

export default Billing
