import React, { useEffect, useMemo, useState } from 'react'
import styles from './DocumentsParamsFields.module.scss'
import { Button, InputWrapper } from 'src/components'
import { generateMovingColumns, generateMovingRows } from './utils'
import { DocumentsParams } from '../../../../../components'
import { useMovingFormContext } from '@pages/OperationsAndDocumentsPage/context/MovingFormContext'
import { fetchItemsStock, useFetchItemsByFilters, useGetItemsNew } from 'src/hooks'
import days from 'dayjs'
import { ICellPlace } from 'src/interfaces'
import MovingModalNew from '../../../MovingModalNew/MovingModalNew'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { StockType } from '../../../MovingModalNew/types'
import { DATE_TIME_VIEW } from '@shared/const/date'

interface MovingFormContentProps {
  refetch?: () => void
}

const DocumentsParamsFields = (props: MovingFormContentProps) => {
  const {
    refetch
  } = props
  const { id: docId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()
  const { state } = useMovingFormContext()
  const [cells, setCells] = useState<ICellPlace[]>([])
  const [stocks, setStocks] = useState<StockType | null>(null)

  const [openModal, setOpenModal] = useState(false)

  const skusIds = useMemo(() => {
    return state.documentRaw?.items.map((el) => el.sku_id)
  }, [state.documentRaw?.items])

  const cellsBarcodes = useMemo(() => {
    return state.documentRaw?.items.reduce((acc: string[], item) => {
      return Array.from(new Set([...acc, item.from_cell_barcode, item.to_cell_barcode]))
    }, [] as string[]) || []
  }, [state.documentRaw?.items])

  useEffect(() => {
    if (stocks || !cellsBarcodes?.length) return
    (async function() {
      const stocksData = await fetchItemsStock({place_ids:cellsBarcodes})
      setStocks(stocksData)
    })()
  }, [cellsBarcodes])

  const { fetch: getItemsByFilter } = useFetchItemsByFilters()
  const { data: skusData, refetch: refetchItems } = useGetItemsNew(
    { filter: { ids: skusIds } },
  )
  const skus = skusData?.skus

  const refetchCells = (cellsBarcodes: string[]) => {
    getItemsByFilter({
      type: 'cell',
      subtype: 'barcodes',
      data: cellsBarcodes,
    }).then((data) => setCells(data.cells))
  }

  useEffect(() => {
    if (!state.documentRaw?.items) return

    if (cellsBarcodes?.length) {
      refetchCells(cellsBarcodes)
    }
    refetchItems()
  }, [state.documentRaw?.items, cellsBarcodes])

  const rows = useMemo(
    () =>
      generateMovingRows(
        state.documentRaw?.items || [],
        skus || [],
        cells || [],
      ),
    [cells, state.documentRaw?.items, cellsBarcodes, skus],
  )

  const hasDefect = useMemo(() => state.documentRaw?.items.some(item => Boolean(item.defect_type)), [state.documentRaw?.items])
  const columns = useMemo(() => generateMovingColumns(hasDefect), [hasDefect])

  return (
    <>
      <DocumentsParams
        rows={rows}
        columns={columns}
        tableTitle={'Перемещение'}
        tableExtraHeader={
          <Button
            size={'s'}
            label={`Редактировать`}
            onClick={() => setOpenModal(true)}
          />
        }
      >
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.id || null}
          id={'id'}
          label={'Номер'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={days(state.documentRaw?.create_ts || null).format(DATE_TIME_VIEW)}
          id={'create_ts'}
          label={'Дата'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.executor?.name || null}
          id={'executor'}
          label={'Ответственный'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.warehouse_comment || null}
          id={'comment'}
          label={'Комментарий'}
          disabled
        />
      </DocumentsParams>

      {openModal ? (
        <MovingModalNew
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          docId={docId}
          refetch={() => refetch?.()}
          movingDocument={state.documentRaw}
          skus={skus}
          stocksData={stocks}
        />
      ) : null}
    </>
  )
}
export default DocumentsParamsFields
