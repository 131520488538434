import React, { FC } from 'react'
import ServicesForm from '../ServicesForm/ServicesForm'
import styles from './services-modal.module.scss'
import { Button, Modal } from 'src/components'
import { ModalProps } from 'src/interfaces'


interface ServicesFormProps extends ModalProps {
  handleClickSave: () => void
  saveBtnLoading?: boolean
}

const ServicesModal: FC<ServicesFormProps> = (props) => {
  const {handleClickSave, onClose, saveBtnLoading} = props

  const handleClickCancel = () => {
    onClose()
  }

  return (
    <Modal
      isOpen={true}
      hasOverlay={true}
      titleAlign={'left'}
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      title={'Дополнительные параметры'}
      subtitle={'Введите кол-во операций и выберите доп. материал'}
      width={'auto'}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.body}>
          {/* Операции и Материалы */}
          <ServicesForm />
        </div>
        <div className={styles.footer}>
          <Button
            label={'Отмена'}
            view={'ghost'}
            onClick={handleClickCancel}
            className={styles.btn}
          />
          <Button
            label={'Сохранить'}
            view={'primary'}
            onClick={handleClickSave}
            className={styles.btn}
            loading={saveBtnLoading}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ServicesModal
