import styles from './table-batch-cell.module.scss'

const TableBatchCell = ({ num, date }: { num: string; date?: string }) => {
  return (
    <div className={styles.batch}>
      {date ? <p className={styles.date}>{date}</p> : null}
      <span className={styles.num}>{num}</span>
    </div>
  )
}

export default TableBatchCell
