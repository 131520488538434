import React, { useState } from 'react'
import { Button, Modal, Select } from '@/components'
import styles from './styles.module.scss'
import { ISelectOrderOption } from '@/interfaces'
import { TextField } from '@consta/uikit/TextField'
import { useFetchItemsByFilters } from '@/hooks'
import { setModalCells, setModalSkus } from '../../store/actions'
import { useReportsContext } from '../../context/ReportsContext'
import { IReportParam, ITemplateParam } from '@/interfaces/report'

export enum orderItemsEnum {
  nomenclature = 'nomenclature',
  cell = 'cell',
  order = 'order'
}

export enum orderSubtypeEnum {
  barcodes = 'barcodes',
  ids = 'ids',
  titles = 'titles',
  orders = 'orders'
}

export const SELECT_ITEMS: ISelectOrderOption[] = [
  {
    label: 'Номенклатура - ШК',
    value: 'name',
    type: orderItemsEnum.nomenclature,
    subtype: orderSubtypeEnum.barcodes
  },
  {
    label: 'Номенклатура - ID',
    value: 'name',
    type: orderItemsEnum.nomenclature,
    subtype: orderSubtypeEnum.ids
  },
  {
    label: 'Номенклатура - наименование',
    value: 'name',
    type: orderItemsEnum.nomenclature,
    subtype: orderSubtypeEnum.titles
  },
  {
    label: 'Ячейка - наименование',
    value: 'cell',
    type: orderItemsEnum.cell,
    subtype: orderSubtypeEnum.titles
  },
  {
    label: 'Ячейка - ШК',
    value: 'cell',
    type: orderItemsEnum.cell,
    subtype: orderSubtypeEnum.barcodes
  },
  {
    label: 'Заказ клиента - номер заказа',
    value: 'order',
    type: orderItemsEnum.order,
    subtype: orderSubtypeEnum.orders
  },
]

interface IDefineParamsModal {
  isOpen: boolean;
  onClose: () => void;
  setParamValue: (value: IReportParam) => void
  type: orderItemsEnum
  templateParam?: ITemplateParam
}

const DefineParamsModal: React.FC<IDefineParamsModal> = (props) => {
  const { isOpen, onClose, type, setParamValue, templateParam }  = props;
  const [selectState, setSelectState] = useState<ISelectOrderOption | null>(null);
  const [inputState, setInputState] = useState<string | null>("");
  const { dispatch } = useReportsContext()

  const submitData = () => {
    if (inputState && selectState) {
      getItemsByFilter(
        {type: selectState.type, subtype: selectState.subtype, data: inputState?.split(/\r?\n/)}
      ).then((data) => {
        if (data.skus) {
          dispatch(setModalSkus(data.skus));
          setSelectState(null)
          setInputState('')
          onClose()
        }
        if (data.cells) {
          dispatch(setModalCells(data.cells));
          const nodeTopology = data.cells.map(cell => `${cell.zone_id}|${cell.topology.row}|${cell.topology.bay}|${cell.topology.tier}`)
          setParamValue({id: templateParam?.id, values: nodeTopology})
          setSelectState(null)
          setInputState('')
          onClose()
        }
      })
    }
  }

  const { fetch: getItemsByFilter } = useFetchItemsByFilters()

  const handleClose = () => {
    setSelectState(null)
    setInputState('')
    onClose()
  }


  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size="min"
    >
      <Button
        label={'Закрыть'}
        view={'ghost'}
        onClick={handleClose}
        size={'m'}
        className={styles.closeButton}
      />
      <div className={styles.contentWrapper}>
        <div className={styles.titleWrapper}>
          <h3 className={styles.title}>Добавление данных</h3>
        </div>
        <div className={styles.selectWrapper}>
          <Select
            className={styles.typeOptions}
            label={'Выберите тип параметра'}
            options={SELECT_ITEMS.filter((item) => item.type === type)}
            value={selectState}
            onChange={({ value }) => setSelectState(value as ISelectOrderOption)}
          />
          <TextField
            className={styles.textarea}
            width="full"
            value={inputState}
            type="textarea"
            rows={20}
            placeholder="Каждое наименование должно начинаться с новой строки"
            onChange={({ value }) => {
              setInputState(value || '')
            }}
          />
        </div>
        <Button label="Сохранить данные" onClick={submitData} />
      </div>
    </Modal>
  )
}

export default DefineParamsModal
