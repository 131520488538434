import React, { useEffect, useMemo, useState } from 'react'
import { DocumentsListWrapper } from '@pages/OperationsAndDocumentsPage/components'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { useDeleteInventoryDoc, useGetInventoryList } from 'src/hooks/warehouse/inventory'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { Button, ConfirmActionModal } from 'src/components'

import InventoryModal from '../InventoryModal/InventoryModal'
import { useFilter } from '../../../hooks/useFilter'

const InventoryList = () => {
  const { filterState, setFilterState } = useFilter()
  const [modalOpen, setModalOpen] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  const {
    isFetching: inventoryLoading,
    data: inventoryData,
    refetch: refetchInventory,
  } = useGetInventoryList(generateFilterParams(filterState))

  const { mutateAsync: deleteInventory } = useDeleteInventoryDoc()

  useEffect(() => {
    refetchInventory()
  }, [filterState])

  const handleConfirmModal = (id: string) => {
    setDeleteId(id)
    setOpenConfirmModal(true)
  }
  const handleDeleteInventory = () => {
    deleteInventory({
      id: deleteId,
    }).then((res) => {
      refetchInventory()
      setDeleteId('')
    })
  }

  const rows = useMemo(
    () => generateRows(inventoryData?.documents || []),
    [inventoryData?.documents],
  )

  const columns = useMemo(() => generateColumns(handleConfirmModal), [])
  const filtersConfig = generateFilters()
  return (
    <>
      <DocumentsListWrapper
        title={'Инвентаризация'}
        rows={rows}
        columns={columns}
        filterState={filterState}
        setFilterState={setFilterState}
        filtersConfig={filtersConfig}
        loading={inventoryLoading}
        pagination={inventoryData?.pagination}
        tableExtraHeaderRight={
          <Button label={'Добавить'} onClick={() => setModalOpen(true)} />
        }
      />

      {modalOpen ? (
        <InventoryModal
          isOpen={true}
          onClose={() => setModalOpen(false)}
          refetch={refetchInventory}
        />
      ) : null}
      {openConfirmModal ? (
        <ConfirmActionModal
          isOpen={true}
          type="warning"
          withPreview={false}
          title={'Вы уверены, что хотите удалить инвентаризацию?'}
          size={'min'}
          confirmWithScan={false}
          onClose={() => {
            setDeleteId('')
            setOpenConfirmModal(false)
          }}
          onSubmit={() => {
            if (!deleteId) return
            setOpenConfirmModal(false)
            handleDeleteInventory()
          }}
        />
      ) : null}
    </>
  )
}
export default InventoryList
