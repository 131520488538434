import { generateStatusesOptions } from '@shared/helpers'
import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { ICombobox } from '../../interfaces'
import { PhysicalSetOrderBatchStatusEnum } from '../physicalSetOrderBatch/physicalSetOrderBatch'


export const PHYSICAL_SET_BATCH_STATUSES_DESCRIPTION: Record<PhysicalSetOrderBatchStatusEnum | string, string> = {
  [PhysicalSetOrderBatchStatusEnum.NEW]: "Новый",
  [PhysicalSetOrderBatchStatusEnum.PICKING]: "Подбор",
  [PhysicalSetOrderBatchStatusEnum.PICKED]: "Подобран",
  [PhysicalSetOrderBatchStatusEnum.PACKING]: "На упаковке",
  [PhysicalSetOrderBatchStatusEnum.PACKED]: "Упакован",
  [PhysicalSetOrderBatchStatusEnum.PLACING]: "Размещается",
  [PhysicalSetOrderBatchStatusEnum.PLACED]: "Размещен",
}

export const PHYSICAL_SET_BATCH_STATUS_BADGE_TYPE: Record<PhysicalSetOrderBatchStatusEnum | string, BadgePropStatus> = {
  [PhysicalSetOrderBatchStatusEnum.NEW]: 'system',
  [PhysicalSetOrderBatchStatusEnum.PICKING]: 'normal',
  [PhysicalSetOrderBatchStatusEnum.PICKED]: 'normal',
  [PhysicalSetOrderBatchStatusEnum.PACKING]: 'normal',
  [PhysicalSetOrderBatchStatusEnum.PACKED]: 'normal',
  [PhysicalSetOrderBatchStatusEnum.PLACING]: 'success',
  [PhysicalSetOrderBatchStatusEnum.PLACED]: 'warning',
}

export const PHYSICAL_SET_BATCH_STATUSES: {
  value: string
  label: string
  style: BadgePropStatus
}[] = Object.keys(PHYSICAL_SET_BATCH_STATUSES_DESCRIPTION).map(key => ({
  value: key as string,
  label: PHYSICAL_SET_BATCH_STATUSES_DESCRIPTION[key as keyof object],
  style: PHYSICAL_SET_BATCH_STATUS_BADGE_TYPE[key as keyof object]
}))


export const physicalSetBatchSelectOptions: ICombobox[] = generateStatusesOptions( PHYSICAL_SET_BATCH_STATUSES_DESCRIPTION)
