import React, { useEffect, useMemo, useState } from 'react'
import { DocumentsListWrapper } from '@pages/OperationsAndDocumentsPage/components'
import { useGetCarryerQubyList } from 'src/hooks/carryerQuby'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { Button } from 'src/components'
import { generateColumns, generateFilters, generateRows } from './helpers'
import CarryerQubyModal from '../CarryerQubyModal/CarryerQubyModal'
import { useFilter } from '../../../hooks/useFilter'

const CarrierQubyList = () => {
  const { filterState, setFilterState } = useFilter()
  const [id, setId] = useState<string | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const {
    isFetching: qubyLoading,
    data: qubyData,
    refetch: refetchQuby,
  } = useGetCarryerQubyList(generateFilterParams(filterState))

  useEffect(() => {
    refetchQuby()
  }, [filterState])

  const handleEdit = (id: string) => {
    setId(id)
    setOpenModal(true)
  }

  const rows = useMemo(
    () => generateRows(qubyData?.transportations || []),
    [qubyData?.transportations],
  )

  const columns = useMemo(() => generateColumns({ handleEdit }), [])
  const filtersConfig = generateFilters()

  return (
    <>
      <DocumentsListWrapper
        title={'Перевозки'}
        rows={rows}
        columns={columns}
        filterState={filterState}
        setFilterState={setFilterState}
        filtersConfig={filtersConfig}
        loading={qubyLoading}
        pagination={qubyData?.pagination}
        tableExtraHeaderRight={
          <Button
            label={'Добавить'}
            onClick={() => {
              setId(null)
              setOpenModal(true)
            }}
          />
        }
      />
      {openModal ? (
        <CarryerQubyModal
          docId={id}
          docList={qubyData?.transportations || []}
          onClose={() => setOpenModal(false)}
          isOpen={openModal}
          refetch={refetchQuby}
        />
      ) : null}
    </>
  )
}
export default CarrierQubyList
