import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, ICellPlace, IPlacing, ISupplyReceipt } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'


export interface IGetReceiptParams {
  placingId: string
}

export interface IGetReceiptResult extends ApiReqResult {
  cells: ICellPlace[]
  placing: IPlacing
  receipt: ISupplyReceipt
}

const useGetPlacing = (
  params: IGetReceiptParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IGetReceiptResult>(
    [`getPlacing`, params],
    async () => {
      const result = await api.get(`/placing/${params.placingId}`)
      return result.data
    },
    options
  )
}

export default useGetPlacing
