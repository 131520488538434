import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { orderInitialState, orderReducer } from '../store/reducer/orderReducer'
import { IOrderFormState } from '../store/interfaces/order'

export interface IUseReducer {
  state: IOrderFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {
}

export const OrderFormContext = createContext<IContext>({
  state: orderInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const OrderFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof orderInitialState, IReducerAction>>(orderReducer, orderInitialState)

  useEffect(() => {
    console.log('--- State ---', state)
  }, [state])

  return (
    <OrderFormContext.Provider value={{ state, dispatch }}>
      {children}
    </OrderFormContext.Provider>
  )
}

export const useOrderFormContext = () => useContext(OrderFormContext)
