import React from 'react'
import { presetGpnDefault, presetGpnDark, Theme } from '@consta/uikit/Theme'

export const ThemeProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props
  const preset = presetGpnDefault
  // const preset = presetGpnDark
  return (
    <Theme preset={preset}>{children}</Theme>
  )
}
