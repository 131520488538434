import React from 'react'
import { useHistory } from 'react-router-dom'

import cx from 'classnames'
import styles from './scan-header.module.scss'

import { IconArrowDown } from '@consta/icons/IconArrowDown'
import { IconSettings } from '@consta/icons/IconSettings'
import { Badge } from '@consta/uikit/Badge'

import { AdvanceModeLock, UserBlock } from 'src/components'

import { useAppContext } from '@shared/providers/AppContextProvider'

import { PATHS } from '@shared/routing'


interface ScanFormHeaderProps {
  className?: string
  lightStyle?: boolean
  title?: string
  subtitle?: string | React.ReactNode
  onExit?: () => void
  withoutGoToMainMenu?: boolean
}

const ScanFormHeader = ({
  className = '',
  title,
  subtitle,
  lightStyle,
  onExit = () => {},
  withoutGoToMainMenu
  }: ScanFormHeaderProps) => {
  const { advanceMode } = useAppContext()

  const history = useHistory()

  const openSettings = () => {
    history.push(PATHS.SETTINGS)
  }

  return (
    <>
      <div className={cx(styles.wrapper, className)}>
        <div className={cx({ [styles.titleLightCard]: lightStyle })}>
          <h3 className={cx(styles.title, { [styles.light]: lightStyle })}>{title}</h3>
          <h5 className={cx(styles.subtitle, { [styles.light]: lightStyle })}>{subtitle}</h5>
        </div>

        <div className={cx(styles.right)}>
          <div className={cx(styles.infoWrapper, { [styles.lightCard]: lightStyle && advanceMode })}>
            {advanceMode ? (
              <>
                <Badge
                  form="round"
                  status="normal"
                  label="Расширенный"
                  className={styles.badge}
                />
                {/*Button*/}
                <IconSettings
                  className={styles.settings}
                  view="secondary"
                  size="m"
                  onClick={openSettings}
                />
              </>
            ) : null}

            <div className={cx(styles.userCard,{ [styles.lightCard]: lightStyle })}>
              <AdvanceModeLock className={styles.lock} />
              <UserBlock
                withMenu
                iconRight={IconArrowDown}
                size="l"
                defaultMenuSettings={{
                  withoutGoToMainMenu: withoutGoToMainMenu
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ScanFormHeader
