import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

/*
  Добавление транспортные места в отгрузку
  wiki:
  https://wiki.kak2c.ru/link/785#bkmrk-%2Fshipment%2F%7Bid%7D%2Fitem
*/

interface IParams {
  shipmentId: string
  transportPlacesId: string | null
}

const useDeleteTransportPlaceFromShipment = (
  params: IParams,
  callback?: () => void,
  error?: () => void,
  settled?: () => void,
) => {
  const { shipmentId, transportPlacesId } = params
  return useMutation(
    async () =>
      api.delete(`/shipment/${shipmentId}/item/${transportPlacesId || ''}`),
    {
      onSuccess: ({ data: { success } }: { data: ApiReqResult }) => {
        if (success) {
          callback?.()
        } else {
          error?.()
        }
      },
      onError: error,
      onSettled: settled,
    },
  )
}

export default useDeleteTransportPlaceFromShipment
