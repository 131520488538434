import { useState } from 'react'
import { AudioRecordType } from '../config/schema'

export const useDynamicSoundsHook = () => {
  const [soundsObj, setSoundsObj] = useState<AudioRecordType>({})

  const setMultipleAudios = (soundsUrls: string[]) => {
    setSoundsObj(soundsUrls.reduce((acc, url) => (
      { ...acc, [url]: { audio: new Audio(url) } }
    ), {}))
  }

  const playAudio = (url: string) => {
    try {
      const audio = new Audio(url)
      audio.addEventListener('canplaythrough', audio.play)
    } catch (e) {
      console.error(e)
    }
  }

  const clearSounds = () => setSoundsObj({})

  return {
    sounds: soundsObj,
    clearSounds,
    setMultipleAudios,
    playAudio
  }
}