import React from 'react'
import styles from './TSDTableNomenclatureCell.module.scss'


import cx from 'classnames'
import { IBarcode } from 'src/interfaces'
import days from 'dayjs'
import { useToggle } from '@shared/hooks'
import { DATE_FORMAT_VIEW } from '@shared/const/date'

export interface TSDTableNomenclatureCellProps {
  merchantName?: string
  name: string
  article?: string
  barcodes?: IBarcode[]
  withoutCollapseName?: boolean
  batchExpDate?: string
  className?: string
}

export const TSDTableNomenclatureCell = (props: TSDTableNomenclatureCellProps) => {
  const {
    withoutCollapseName = false,
    name,
    merchantName,
    article,
    className,
    barcodes = [],
    batchExpDate
  } = props

  const {
    value: fullNameVisible,
    toggle: toggleName
  } = useToggle()

  const batchExpDateStr = batchExpDate ? days(batchExpDate).format(DATE_FORMAT_VIEW) : null
  const MAX_SYMBOLS = 30
  const shortName = name.slice(0, MAX_SYMBOLS)
  return (
    <div
      className={cx(styles.name, className)}
      onClick={toggleName}
    >
      {merchantName ? <p>{merchantName}</p> : null}
      <p>
        {
          fullNameVisible || withoutCollapseName
            ? name
            : shortName + (name.length > MAX_SYMBOLS && !fullNameVisible ? '...' : '')
        }
      </p>
      {article ? <p>{article}</p> : null}
      {
        batchExpDateStr ? (
          <span className={styles.batch}>Партия: {batchExpDateStr}</span>
        ) : null
      }
      {
        barcodes.length ? (
          <div className={styles.barcodes}>
            {barcodes.map((barcode, i) => (
              <b key={barcode.barcode}>
                {barcode.barcode}
                {barcodes.length !== 1 || barcodes.length-1 < i ? ',' : ''}
              </b>
            ))}
          </div>
        ) : null
      }
    </div>
  )
}
