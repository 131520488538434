import api from '@shared/api'

import { MOCK_STICKER_6x6, provideMock } from 'src/mocks'

import { PrintResult } from 'src/interfaces'

/*
* Получение инвойсов и доп. документов заказов
* https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Zakazy-na-otgruzku/operation/getDocsForPacking
*/

interface IParams {
  ordersIds: string[]
}
const getDocsForPacking = async (params: IParams) => {
  const result = await api.post(`/order/docs-for-packing`, params.ordersIds)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

const useGetDocsForPacking = () => ({
  fetch: (params: IParams) =>
    provideMock<IParams, PrintResult>(getDocsForPacking, params, [
      {
        key: 'ordersIds',
        value: 'mock',
        result: {
          success: true,
          content: MOCK_STICKER_6x6,
          printer: 'PRINTER_A4',
        },
      },
    ])(),
})

export default useGetDocsForPacking
