import { useMutation } from 'react-query'
import api from '@shared/api'

const useStartPlacement = () => {
  const sendStartPlacement = async (docId: string) => {
    const result = await api.post(`/placing/${docId}/start`)

    if (result?.status === 200) {
      return result.data
    }
  }

  return useMutation('getPlacementList', sendStartPlacement)
}

export default useStartPlacement
