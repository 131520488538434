import React, { FC, useEffect, useState } from 'react'
import styles from './filters.module.scss'
import { InputWrapper } from 'src/components'
import { GeneratorFields } from '@widgets/GeneratorFields'
import { IconSearchStroked } from '@consta/icons/IconSearchStroked'
import { IconFilter } from '@consta/icons/IconFilter'
import { Button } from '@consta/uikit/Button'
import { useDebounce } from '@shared/hooks'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { FiltersProps } from '..'


const Filters: FC<FiltersProps> = (props) => {
  const {
    headExtraSlot,
    inputSearchFilterKey: searchKey = 'search',
    searchPlaceholder,
    filterState,
    filterStateDraft,
    handleChangeFilter,
    handleChangeFilterDraft,
    handleSubmit,
    handleClear,
    filtersConfig,
  } = props

  const [filterVisible, setFilterVisible] = useState<boolean>(false)
  const handleToggleShowFilter = () => setFilterVisible((prev) => !prev)

  const [searchTxtDebounced, searchTxt, setSearchTxt] = useDebounce<TextFieldPropValue>(null)

  useEffect(() => {
    if (searchTxt === null || searchTxt === undefined) return
    handleChangeFilter('page', 0)
    handleChangeFilter(searchKey, searchTxtDebounced)
  }, [searchTxtDebounced])

  const filterStateSearch = filterState[searchKey]

  useEffect(() => {
    /** Обнуляем данные если удалили тег поиска */
    if (filterStateSearch !== searchTxtDebounced) {
      setSearchTxt(filterStateSearch)
    }
  }, [filterStateSearch])

  const handleClickClearAll = () => {
    setSearchTxt(null)
    handleClear()
  }
  const withFiltersBlock: boolean = Boolean(
    filtersConfig.filter((config) => !config.hidden).length,
  )

  return (
    <div className={styles.filters}>
      <div className={styles.searchContainer}>
        <div className={styles.search}>
          <div className={styles.searchInput}>
            <InputWrapper
              id={searchKey}
              autoFocus
              placeholder={searchPlaceholder}
              handleChange={(value: TextFieldPropValue) => {
                if (value === null) return
                setSearchTxt(value)
              }}
              value={searchTxt}
              className={styles.applicationBarcodeInput}
              leftSide={IconSearchStroked}
              rightSide={() => {
                if (!withFiltersBlock) {
                  return null
                }
                return (
                  <IconFilter
                    onClick={handleToggleShowFilter}
                    view="brand"
                    className={styles.iconBtn}
                  />
                )
              }}
            />
          </div>
          <div className={styles.searchBtns}>
            <Button
              iconLeft={IconSearchStroked}
              label="Искать"
              onClick={handleSubmit}
              view="secondary"
            />
            <Button
              label="Сбросить все"
              view="clear"
              onClick={handleClickClearAll}
            />
          </div>
        </div>
        {headExtraSlot}
      </div>

      <GeneratorFields
        visible={filterVisible}
        fieldsConfig={filtersConfig}
        valuesState={filterState}
        valuesStateDraft={filterStateDraft}
        onChangeField={handleChangeFilter}
        onChangeFieldDraft={handleChangeFilterDraft}
        excludeFields={['page', 'size']} // исключаем для пагинации
      />
    </div>
  )
}

export default Filters
