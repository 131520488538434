import React from 'react'
import { ITableColumn, ITableRow, TEMPLATES_PLACE_STATUS } from 'src/interfaces'
import { Button } from 'src/components'
import styles from './DocumentsParamsField.module.scss'
import { ICarryerQubyItem, ITransportaion } from 'src/interfaces/transportationTemplates'
import { IconEdit } from '@consta/icons/IconEdit'
import { IconRemove } from '@consta/icons/IconRemove'

export const generateColumns = ({
  handleEdit,
  handleConfirmModal,
}: {
  handleEdit: (itemId: string) => void
  handleConfirmModal: (itemId: string) => void
}): ITableColumn[] => {
  return [
    {
      key: 'contractorTitle',
      title: 'Контрагент',
      title_txt: 'Контрагент',
      gridCellWidth: '2fr',
    },
    {
      key: 'type',
      title: 'Тип',
      title_txt: 'Тип',
    },
    {
      key: 'quantity',
      title: 'Количество',
      title_txt: 'Количество',
    },
    {
      key: 'actions_btns',
      title: 'Удаление/Редактирование',
      title_txt: 'Удаление/Редактирование',
      renderCell: ({row}) => {
        return (
          <div className={styles.actionsBtns}>
            <Button
              onlyIcon
              view={'ghost'}
              size={'s'}
              iconLeft={IconEdit}
              onClick={() => handleEdit(row.id)}
            />
            <Button
              onlyIcon
              view={'ghost'}
              size={'s'}
              iconLeft={IconRemove}
              onClick={() => handleConfirmModal(row.id)}
            />
          </div>
        )
      },
    },
  ]
}
export const generateRows = (
  qubyItems: ICarryerQubyItem[],
  transportations: ITransportaion[],
): ITableRow[] => {
  return qubyItems.map((item) => {
    const transportation = transportations.find((el) => el.id === item.id)

    return {
      id: item.id,
      contractorTitle: item.contractor?.title,
      type: TEMPLATES_PLACE_STATUS[item.place?.type || ''] || '',
      quantity: item.quantity,
      actions: qubyItems,
    }
  })
}
