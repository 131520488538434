import React, { createContext, useContext, useEffect, useReducer } from 'react'

import { IReducerAction } from '@/interfaces'
import { returnPlaceInitialState, returnPlaceReducer } from '../store/reducer'
import { IReturnPlaceFormState } from '../store/interfaces'

export interface IUseReducer {
  state: IReturnPlaceFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {
}

export const ReturnPlaceFormContext = createContext<IContext>({
  state: returnPlaceInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const ReturnPlaceFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof returnPlaceInitialState, IReducerAction>>(returnPlaceReducer, returnPlaceInitialState)

  useEffect(() => {
    console.log('--- ReturnPlace State ---', state)
  }, [state])

  return (
    <ReturnPlaceFormContext.Provider value={{ state, dispatch }}>
      {children}
    </ReturnPlaceFormContext.Provider>
  )
}

export const useReturnPlaceFormContext = () => useContext(ReturnPlaceFormContext)
