import { useQuery } from 'react-query'
import api from '@shared/api'

import { MOCK_GET_INSTRUCTION, provideMock } from 'src/mocks'

import { ApiReqResult, IBaseInstruction } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'

interface IParams {
  instructionId: string
}

const getPackingInstruction = async (params: IParams) => {
  const result = await api.get(`/instructions/${params.instructionId}`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

interface IResult extends ApiReqResult {
  instruction: IBaseInstruction
}

const useGetPackingInstruction = (
  params: IParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IResult>(
    [`getPackingInstruction`, params],
    provideMock<IParams, IResult>(getPackingInstruction, params, [
      {
        key: 'instructionId',
        value: 'mock',
        result: MOCK_GET_INSTRUCTION,
      },
    ]),
    options,
  )
}

export default useGetPackingInstruction
