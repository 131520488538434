import {
  REVERT_FORM_STATE_TO_RAW,
  SET_ORIGINAL_SKUS,
  SET_RECEIPT_FORM_STATE_DRAFT,
  SET_RECEIPT_FORM_STATE_RAW,
  SET_RECEIPT_RAW,
  SET_TRUST_ACCEPTANCE_BOXES,
  UPDATE_FORM_STATE_DRAFT,
  UPDATE_FORM_STATE_RAW,
} from '../actions'
import { IReducerAction } from 'src/interfaces'
import { cloneDeep } from '@shared/helpers'
import { IReceiptFormState } from '../interfaces/receipt'

export const receiptInitialState: IReceiptFormState = {
  documentRaw: null,
  formStateRaw: null,
  formStateDraft: null,
  originalSkus: [],
  trustBoxes: [],
}

export const receiptReducer = (
  state: IReceiptFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    /** Общие кейсы */
    case SET_RECEIPT_FORM_STATE_DRAFT:
      return { ...state, formStateDraft: payload }

    case UPDATE_FORM_STATE_DRAFT:
      return {
        ...state,
        formStateDraft: {
          ...(state.formStateDraft || {}),
          [payload.key]: payload.value,
        },
      }

    case SET_RECEIPT_FORM_STATE_RAW:
      return { ...state, formStateRaw: payload }

    case SET_TRUST_ACCEPTANCE_BOXES:
      return { ...state, trustBoxes: payload }

    case UPDATE_FORM_STATE_RAW:
      return {
        ...state,
        formStateRaw: {
          ...(state.formStateRaw || {}),
          [payload.key]: payload.value,
        },
      }

    case REVERT_FORM_STATE_TO_RAW:
      return {
        ...state,
        formStateDraft: cloneDeep(state.formStateRaw),
      }

    case SET_RECEIPT_RAW:
      return { ...state, documentRaw: payload }

    case SET_ORIGINAL_SKUS:
      return { ...state, originalSkus: payload }

    default:
      return state
  }
}
