import React, { useEffect } from 'react'
import { useSound } from 'use-sound'
import { SoundContext } from '../config/context'
import { soundNameEnum } from '../config/enums'

const SOUND_NAMES = Object.values(soundNameEnum)

export const SoundProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  useEffect(() => {
    window.soundPlay = play //TODO better
  }, [])

  const soundObj: { [key: string]: { play: () => void } } = {}

  for (let i = 0; i < SOUND_NAMES.length; i++) {
    const name = SOUND_NAMES[i]
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [play] = useSound(`/sounds/${name}.mp3`)
    soundObj[name] = { play }
  }

  const play = (name: string) => soundObj[name].play()

  return (
    <SoundContext.Provider value={{ play }}>{children}</SoundContext.Provider>
  )
}
