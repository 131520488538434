import {
  REVERT_FORM_STATE_TO_RAW,
  SET_CELLS_RAW,
  SET_DOCUMENT_RAW,
  SET_INVENTORY_FORM_STATE_DRAFT,
  SET_INVENTORY_FORM_STATE_RAW,
  SET_SKUS_RAW,
  UPDATE_FORM_INVENTORY_STATE_DRAFT,
  UPDATE_FORM_INVENTORY_STATE_RAW,
} from '../actions'
import { IReducerAction } from 'src/interfaces'
import { cloneDeep } from '@shared/helpers'
import { IInventoryFormState } from '../interfaces'

export const inventoryInitialState: IInventoryFormState = {
  documentRaw: null,
  formStateRaw: null,
  formStateDraft: null,
  originalSkus: [],
  cellRaw: [],
}

export const inventoryReducer = (
  state: IInventoryFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    /** Общие кейсы */
    case SET_INVENTORY_FORM_STATE_DRAFT:
      return { ...state, formStateDraft: payload }

    case UPDATE_FORM_INVENTORY_STATE_DRAFT:
      return {
        ...state,
        formStateDraft: {
          ...(state.formStateDraft || {}),
          [payload.key]: payload.value,
        },
      }

    case SET_INVENTORY_FORM_STATE_RAW:
      return { ...state, formStateRaw: payload }

    case UPDATE_FORM_INVENTORY_STATE_RAW:
      return {
        ...state,
        formStateRaw: {
          ...(state.formStateRaw || {}),
          [payload.key]: payload.value,
        },
      }

    case REVERT_FORM_STATE_TO_RAW:
      return {
        ...state,
        formStateDraft: cloneDeep(state.formStateRaw),
      }

    case SET_DOCUMENT_RAW:
      return { ...state, documentRaw: payload }

    case SET_SKUS_RAW:
      return { ...state, originalSkus: payload }

    case SET_CELLS_RAW:
      return { ...state, cellRaw: payload }

    default:
      return state
  }
}
