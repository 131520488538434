import React from 'react'

import styles from './table-pagination.module.scss'
import { Pagination } from '@consta/uikit/Pagination'
import {
  HotKeys,
  PaginationPropForm,
  PaginationPropSize,
  PaginationPropType,
} from '@consta/uikit/__internal__/src/components/Pagination/Pagination'
import cx from 'classnames'

interface IProps {
  minified?: boolean,
  withoutHotkey?: boolean,
  pageSize?: number
  currentPage: number
  totalPages: number
  onChangePage: (page: number) => void
  getLabel?: (totalPages: number) => string
  form?: PaginationPropForm
  size?: PaginationPropSize
  type?: PaginationPropType
  hotkeys?: HotKeys
  containerEventListener?: HTMLElement | Window;
  className?: string;
  classNameWrapper?: string;
}

const TablePagination = React.memo((props: IProps) => {
  const {
    withoutHotkey,
    pageSize,
    onChangePage,
    classNameWrapper,
    className,
    ...restProps
  } = props

  const handleChange = (pageNumber: number): void => {
    onChangePage(pageNumber)
  };
  return (
    <div className={cx(styles.paginationWrapper, classNameWrapper)}>
      <Pagination
        onChange={handleChange}
        {...restProps}
        className={cx(styles.pagination, className, {[styles.withoutHotkey]: withoutHotkey})}
      />
    </div>
  )
})
export default TablePagination
