import { IconCalendar } from '@consta/icons/IconCalendar'
import { FieldRow } from '@widgets/GeneratorFields'
import { IMerchant } from 'src/interfaces'

export const generateFilters = (
  merchants: IMerchant[],
): FieldRow[] => [
  {
    fields: [
      {
        jointFields: [
          {
            type: 'calendar',
            fieldProps: {
              id: 'from',
              label: 'Дата',
              placeholder: 'C',
              form: 'defaultBrick',
              rightSide: IconCalendar,
              viewDescription: 'С',
            },
          },
          {
            type: 'calendar',
            fieldProps: {
              id: 'to',
              placeholder: 'По',
              form: 'clearDefault',
              rightSide: IconCalendar,
              viewDescription: 'По',
            },
          },
        ],
      },
    ],
  },
]