import React, { useEffect, useState } from 'react'
import styles from './TemplatesModal.module.scss'
import { CalendarInput, ComboboxWrapper, InputWrapper, Modal } from 'src/components'
import { ModalProps } from 'src/interfaces'
import { Button } from '@consta/uikit/Button'
import days from 'dayjs'
import { getDimensionStr } from '@shared/helpers'
import { TIME_FORMAT } from '@shared/const/date'
import { useUpdateTransportation } from 'src/hooks/trasportationTemplates'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { ValidateErrors } from '@pages/DirectoriesPage/types'
import { IFields, IFieldsRequired } from './types'
import { ITransportaion } from 'src/interfaces/transportationTemplates'
import { convertTimeTofullDate } from './utils'

interface IProps extends ModalProps {
  refetch: () => void
  docId: string | null
  documents: ITransportaion[]
}
const TemplatesModal = (props: IProps) => {
  const { isOpen, onClose, refetch = () => {}, docId, documents } = props
  const [fieldValues, setFieldValues] = useState<IFields>({
    deliveryFrom: null,
    deliveryTo: null,
    adress: null,
    depth: "",
    height: "",
    width: "",
    pickupFrom: null,
    pickupTo: null,
    phone: '',
  })
  const [fieldValuesRequired, setFieldValuesRequired] =
    useState<IFieldsRequired>({
      title: '',
      order_prefix: '',
      transportation_type: null,
    })

  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({
    title: '',
    transportation_type: '',
    order_prefix: '',
  })
  const typeList = [
    { label: 'Короб', id: 'BOX' },
    { label: 'Палета', id: 'PALLET' },
  ]
  const typeTransitionList = [
    { label: 'Отгрузка', id: 'SHIPMENT' },
    { label: 'Перемещение', id: 'MOVING_TO' },
    { label: 'Возврат', id: 'RETURN' },
  ]
  const { mutateAsync: createTransportation } = useUpdateTransportation()

  useEffect(() => {
    if (!docId) {
      return
    }
    const document = documents.find((el) => el.id === docId)
    const transportation_type = typeTransitionList.find(
      (el) => el.id === document?.transportation_type,
    )
    const measure = {
      width: document?.place?.dimensions?.width || 0,
      height: document?.place?.dimensions?.height || 0,
      depth: document?.place?.dimensions?.depth || 0,
    }
    const convert = getDimensionStr(measure, { from: 'mm', to: 'sm' }).split(
      'x',
    )

    const type = typeList.find((el) => el.id === document?.place?.type)
    setFieldValuesRequired({
      title: document?.title || '',
      order_prefix: document?.order_prefix || '',
      transportation_type: transportation_type || null,
    })

    const adress = {
      area: document?.external_warehouse_address?.area || '',
      block: document?.external_warehouse_address?.block || '',
      building: document?.external_warehouse_address?.building || '',
      city: document?.external_warehouse_address?.city || '',
      flat: document?.external_warehouse_address?.flat || '',
      floor: document?.external_warehouse_address?.floor || '',
      house: document?.external_warehouse_address?.house || '',
      region: document?.external_warehouse_address?.region || '',
      settlement: document?.external_warehouse_address?.settlement || '',
      street: document?.external_warehouse_address?.street || '',
      zip: document?.external_warehouse_address?.zip || '',
    }
    setFieldValues({
      adress: adress,
      contractorTitle: document?.contractor?.title || '',
      phone: document?.contractor?.phone || '',
      deliveryFrom: document?.delivery_from
        ? convertTimeTofullDate(document?.delivery_from)
        : null,
      deliveryTo: document?.delivery_to
        ? convertTimeTofullDate(document?.delivery_to)
        : null,
      pickupFrom: document?.pickup_from
        ? convertTimeTofullDate(document?.pickup_from)
        : null,
      pickupTo: document?.pickup_to
        ? convertTimeTofullDate(document?.pickup_to)
        : null,
      type: type || null,
      width: Number(convert[0]) || '',
      height: Number(convert[1]) || '',
      depth: Number(convert[2]) || '',
    })
  }, [documents, docId])

  useEffect(() => {
    canSubmit()
  }, [fieldValuesRequired])

  const canSubmit = (): boolean => {
    const fieldErrorsObj = Object.keys(fieldErrors).reduce((acc, requiredKey) => {
      const value = fieldValuesRequired[requiredKey as keyof typeof fieldValuesRequired]
      return {
        ...acc,
        [requiredKey]: value ? '' : ValidateErrors.isEmpty
      }
    }, {})
    setFieldErrors(fieldErrorsObj)

    return Object.values(fieldErrorsObj).every(val => !val)
  }

  const handleSubmit = () => {
    const isValid = canSubmit()

    if (!isValid) {
      return
    }

    const dimensions = {
      width: Number(fieldValues.width),
      height: Number(fieldValues.height),
      depth: Number(fieldValues.depth),
    }
    const convert = getDimensionStr(dimensions, { from: 'sm', to: 'mm' }).split(
      'x',
    )
     const measure = {}
    if (+convert[0]) measure["width"] = +convert[0]
    if (+convert[1]) measure["height"] = +convert[1]
    if (+convert[2]) measure["depth"] = +convert[2]
    const dimensionsData = Object.keys(measure).length ? measure : undefined

    const data = {
      contractor: fieldValues.phone || fieldValues.contractorTitle
        ? { phone: fieldValues.phone || '', title: fieldValues.contractorTitle }
        : undefined,
      delivery_from: fieldValues.deliveryFrom
        ? days(fieldValues.deliveryFrom).format(TIME_FORMAT)
        : undefined,
      delivery_to: fieldValues.deliveryTo
        ? days(fieldValues.deliveryTo).format(TIME_FORMAT)
        : undefined,
      pickup_from: fieldValues.pickupFrom
        ? days(fieldValues.pickupFrom).format(TIME_FORMAT)
        : undefined,
      pickup_to: fieldValues.pickupTo
        ? days(fieldValues.pickupTo).format(TIME_FORMAT)
        : undefined,
      external_warehouse_address: fieldValues.adress,
      place: dimensionsData || fieldValues?.type?.id ? {
        dimensions: dimensionsData,
        type: fieldValues.type?.id,
      } : undefined,
      title: fieldValuesRequired.title
        ? String(fieldValuesRequired.title)
        : undefined,
      transportation_type: fieldValuesRequired?.transportation_type?.id
        ? String(fieldValuesRequired.transportation_type.id)
        : undefined,
      order_prefix: fieldValuesRequired.order_prefix,
    }

    createTransportation({ docId, data })
      .then(async () => {
        refetch()
        onClose()
      })
      .catch(() => {})
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.modal}
      size="m"
      headerTitle={'Создание документа'}
    >
      <div className={styles.formWrapper}>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Наименование'}
              value={fieldValuesRequired.title}
              size={'s'}
              handleChange={(value) => {
                setFieldValuesRequired((prev) => ({
                  ...prev,
                  title: value || '',
                }))
              }}
            />
            <div className={styles.errorMessage}>{fieldErrors.title}</div>
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Контрагент'}
              value={fieldValues.contractorTitle}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  contractorTitle: value || '',
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Телефон'}
              value={fieldValues.phone}
              size={'s'}
              isNumber
              handleChange={(value) => {
                if (value && value.length > 15) {
                  return
                }
                setFieldValues((prev) => ({
                  ...prev,
                  phone: value || '',
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <ComboboxWrapper
              className={styles.typeRow}
              items={typeTransitionList}
              value={fieldValuesRequired.transportation_type}
              label={'Тип перевозки'}
              placeholder="Выберите тип перевозки"
              size={'s'}
              onChange={({ value }) => {
                setFieldValuesRequired({
                  ...fieldValuesRequired,
                  transportation_type: value,
                })
              }}
            />
            {!fieldValuesRequired.transportation_type ? (
              <div className={styles.errorMessage}>
                Поле не может быть пустым
              </div>
            ) : null}
          </div>
        </div>
        <span>
          Адрес{' '}
          {fieldValuesRequired.transportation_type?.id === 'RETURN'
            ? 'отправителя:'
            : 'получателя:'}
        </span>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Индекс'}
              value={fieldValues.adress?.zip}
              size={'s'}
              isNumber
              handleChange={(value) => {
                if (value && value?.length > 10) {
                  return
                }
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, zip: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Город'}
              value={fieldValues.adress?.city}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, city: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Область'}
              value={fieldValues.adress?.region}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, region: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Район в области'}
              value={fieldValues.adress?.area}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, area: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Населенный пункт'}
              value={fieldValues.adress?.settlement}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, settlement: value || '' },
                }))
              }}
            />
          </div>
        </div>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Улица'}
              value={fieldValues.adress?.street}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, street: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Дом'}
              value={fieldValues.adress?.house}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, house: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Корпус'}
              value={fieldValues.adress?.block}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, block: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Строение'}
              value={fieldValues.adress?.building}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, building: value || '' },
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Квартира'}
              value={fieldValues.adress?.flat}
              size={'s'}
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, flat: value || '' },
                }))
              }}
            />
          </div>
        </div>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Этаж'}
              value={
                fieldValues.adress && fieldValues.adress.floor
                  ? String(fieldValues?.adress?.floor)
                  : ''
              }
              size={'s'}
              isNumber
              handleChange={(value) => {
                if (value && value?.length > 6) {
                  return
                }
                setFieldValues((prev) => ({
                  ...prev,
                  adress: { ...fieldValues.adress, floor: Number(value) || '' },
                }))
              }}
            />
          </div>
        </div>

        <span>Значения по умолчанию:</span>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Наименование для выгрузки'}
              value={fieldValuesRequired.order_prefix}
              size={'s'}
              handleChange={(value) => {
                setFieldValuesRequired((prev) => ({
                  ...prev,
                  order_prefix: value || '',
                }))
              }}
            />
            <div className={styles.errorMessage}>
              {fieldErrors.order_prefix}
            </div>
          </div>
          <div className={styles.inputCell}>
            <ComboboxWrapper
              className={styles.typeRow}
              items={typeList}
              value={fieldValues.type}
              label={'Тип'}
              placeholder="Выберите тип"
              size={'s'}
              onChange={({ value }) => {
                setFieldValues({
                  ...fieldValues,
                  type: value ? { label: String(value?.label), id: String(value?.id) } : null,
                })
              }}
            />
          </div>
        </div>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Длина см.'}
              value={`${fieldValues.depth}`}
              size={'s'}
              isNumber
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  depth: Number(value) || '',
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Ширина см.'}
              value={`${fieldValues.width}`}
              size={'s'}
              isNumber
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  width: Number(value) || '',
                }))
              }}
            />
          </div>
          <div className={styles.inputCell}>
            <InputWrapper
              label={'Высота см.'}
              value={`${fieldValues.height}`}
              size={'s'}
              isNumber
              handleChange={(value) => {
                setFieldValues((prev) => ({
                  ...prev,
                  height: Number(value) || '',
                }))
              }}
            />
          </div>
        </div>
        <span>Время:</span>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <CalendarInput
              label="Слот доставки с"
              id={'deliveryFrom'}
              placeholder={'C'}
              handleChange={(value) =>
                setFieldValues({ ...fieldValues, deliveryFrom: value })
              }
              type={'time'}
              value={fieldValues.deliveryFrom}
              size="m"
              className={styles.dateInput}
              rightSide={IconCalendar}
              backspaceReset
            />
          </div>
          <div className={styles.inputCell}>
            <CalendarInput
              label="Слот доставки по"
              id={'deliveryTo'}
              placeholder={'По'}
              handleChange={(value) =>
                setFieldValues({ ...fieldValues, deliveryTo: value })
              }
              type={'time'}
              value={fieldValues.deliveryTo}
              size="m"
              className={styles.dateInput}
              rightSide={IconCalendar}
              backspaceReset
            />
          </div>
          <div className={styles.inputCell}>
            <CalendarInput
              label="Слот сбора с"
              id={'pickupFrom'}
              placeholder={'C'}
              handleChange={(value) =>
                setFieldValues({ ...fieldValues, pickupFrom: value })
              }
              type={'time'}
              value={fieldValues.pickupFrom}
              size="m"
              className={styles.dateInput}
              rightSide={IconCalendar}
              backspaceReset
            />
          </div>
          <div className={styles.inputCell}>
            <CalendarInput
              label="Слот сбора по"
              id={'pickupTo'}
              placeholder={'По'}
              handleChange={(value) =>
                setFieldValues({ ...fieldValues, pickupTo: value })
              }
              type={'time'}
              value={fieldValues.pickupTo}
              size="m"
              className={styles.dateInput}
              rightSide={IconCalendar}
              backspaceReset
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button label="Отмена" view={'ghost'} onClick={onClose} />
        <Button label="Сохранить" view={'primary'} onClick={handleSubmit} />
      </div>
    </Modal>
  )
}
export default TemplatesModal
