export interface ITemplateColumn {
  grouping_available?: boolean
  id: number
  sorting_available?: boolean
  hidden_by_default: boolean
  title: string
  type?: string
}

export enum TemplateParamType {
  text = 'TEXT',
  number = 'NUMBER',
  boolean = 'BOOLEAN',
  yearmonth = "YEARMONTH",
  date = 'DATE',
  datetime = 'DATETIME',
  select = 'SELECT',
  multiselect = 'MULTISELECT',
  skus = 'SKUS',
  merchants = 'MERCHANTS',
  cells = 'CELLS',
  workspace = 'WORKSPACE',
  workspaces = 'WORKSPACES',
}

export interface ITemplateParam {
  id: number
  important?: boolean
  required?: boolean
  title: string
  type: TemplateParamType
  values?: {
    title: string
    value: string
  }[]
}

export interface IReportTemplate {
  columns: ITemplateColumn[]
  id: string
  params?: ITemplateParam[]
  title: string
  collapse_depth?: number
}

export interface IReportParam {
  id: number
  value?: string
  values?: string[]
}

export interface IReportCell {
  id: number
  value?: string | null
  additional_data?: IAdditionalData
}
export interface IAdditionalData {
  id: number
  value: string
  columns: IAdditionalDataColumns[]
  rows: { cells: IReportCell[] }
}

export interface IAdditionalDataColumns {
  grouping_available: boolean
  hidden_by_default: boolean
  id: number
  sorting_available: boolean
  title: string
  type: string
}
export interface IReportRow {
  cells: IReportCell[]
  children?: IReportRow[]
}
