import { useMutation } from 'react-query'

import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

/*
 http://wms-test.kak2c.ru/warehouse-api/order/{order_id}
 */

interface Result extends ApiReqResult {
  id: string
}

interface UpdateOrderParams {
  orderId?: string
  data: {
    disable_billing: boolean | undefined
  }
}

const useEditOrder = () => {
  return useMutation(async ({ orderId, data }: UpdateOrderParams) => {
    const path = `/order/${orderId}`
    const result = await api.post(path, data)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}

export default useEditOrder
