import React, { useCallback, useMemo, useState } from 'react'

import styles from './mass-printing-modal.module.scss'
import { Modal } from 'src/components'

import { IAcceptanceSku, ModalProps } from 'src/interfaces'
import { IReplaceItem } from 'src/interfaces/replace'

import { Button } from '@consta/uikit/Button'
import UnProcess from '../UnProcessed/UnProcess'

interface ItemRemovingProps extends ModalProps {
  originalSkus: IAcceptanceSku[]
  documentReplace: IReplaceItem[]
  onSubmit: (tableData: Record<string, number | undefined>) => void
}
const MassPrintModal = (props: ItemRemovingProps) => {
  const { isOpen, onClose, onSubmit, originalSkus, documentReplace } = props

  const [printItemsValues, setPrintItemsValues] = useState<
    Record<string, number | undefined>
  >({})

  const resultPrintItems = useMemo(() => {
    return Object.keys(printItemsValues).reduce((acc, printItemKey) => {
      const value = printItemsValues[printItemKey as keyof object]
      if (value) {
        acc[printItemKey as keyof object] = value
      }
      return acc
    }, {} as Record<string, number | undefined>)
  }, [printItemsValues])

  const handleSetPrintQuantity = useCallback(
    (itemId: string, value?: number) =>
      setPrintItemsValues((prev) => ({ ...prev, [itemId]: value })),
    [],
  )

  const handleClickApplyAll = () => {
    setPrintItemsValues(
      documentReplace.reduce(
        (acc, sup) => ({ ...acc, [sup.sku_id]: sup.quantity }),
        {},
      ),
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      title={'Печать стикеров товара'}
      titleAlign={'left'}
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size="l"
    >
      <div className={styles.body}>
        <UnProcess
          tableExtraHeader={
            <Button
              label={'Вставить все значения'}
              view={'primary'}
              onClick={handleClickApplyAll}
              size={'m'}
            />
          }
          documentReplace={documentReplace}
          openTareId={null}
          originalSkus={originalSkus}
          maxHeightBody={500}
          printItemsValues={printItemsValues}
          handleSetPrintQuantity={handleSetPrintQuantity}
          handleChooseItem={() => {}}
        />
        <div className={styles.btns}>
          <Button
            label={'Отменить'}
            view={'ghost'}
            onClick={onClose}
            size={'m'}
          />
          <Button
            label={'Напечатать'}
            view={'primary'}
            disabled={!Object.keys(resultPrintItems).length}
            onClick={() => onSubmit(resultPrintItems)}
            size={'m'}
          />
        </div>
      </div>
    </Modal>
  )
}

export default MassPrintModal
