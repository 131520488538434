import { ITransportaion } from 'src/interfaces/transportationTemplates'
import { ITableColumn, ITableRow, TEMPLATES_PLACE_STATUS } from 'src/interfaces'

export const generateColumns = (): ITableColumn[] => {
  return [
    {
      key: 'barcode',
      title: 'ШК',
      title_txt: 'ШК',
    },
    {
      key: 'depth',
      title: 'Длина',
      title_txt: 'Длина',
    },
    {
      key: 'width',
      title: 'Ширина',
      title_txt: 'Ширина',
    },
    {
      key: 'height',
      title: 'Высота',
      title_txt: 'Высота',
    },
    {
      key: 'type',
      title: 'Тип перевозки',
      title_txt: 'Тип перевозки',
    },
  ]
}
export const generateRows = (document: ITransportaion | null): ITableRow[] => {
  if (!document) {
    return []
  }
  const { depth, width, height } = document.place.dimensions
  return [
    {
      id: document.id,
      barcode: document.place.barcode,
      type: TEMPLATES_PLACE_STATUS[String(document.place.type)],
      depth: depth,
      width: width,
      height: height,
    },
  ]
}
