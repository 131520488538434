import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { InputWrapper } from 'src/components'
import { DocumentsParams, SerialNumbersModal } from '../../../../../components'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { generateOrderPlaceColumns, generateOrderPlaceRows } from './utils'
import { useOrderPlaceFormContext } from '../../../../../context/OrderPlaceFormContext'

import styles from './documents-params.module.scss'
import { convertWeight, getDimensionStr } from '@shared/helpers'
import { useFetchItemsNew, useGetPackagings } from 'src/hooks'
import { CommonSerialNumberFilled, IPackageDto, ISku } from 'src/interfaces'

interface SupplyFormContentProps {
}

const DocumentsParamsFields = (props: SupplyFormContentProps) => {
  const { page, id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()
  const [packagings, setPackagings] = useState<IPackageDto[]>([])
  const { state, dispatch } = useOrderPlaceFormContext()
  const { fetch: fetchItems } = useFetchItemsNew()
  const { fetch: getPackagings } = useGetPackagings()
  const [originalSkus, setOriginalSkus] = useState<ISku[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [serialNumbers, setSerialNumbers] = useState<CommonSerialNumberFilled[]>([])

  const placeItems = state.documentRaw?.items

  const handleOpenModal = (sn: CommonSerialNumberFilled[]) => {
    setSerialNumbers(sn)
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
    setSerialNumbers([])
  }

  const orderRows = useMemo(
    () =>
      generateOrderPlaceRows(placeItems || [], originalSkus, packagings || []),
    [placeItems, originalSkus, packagings],
  )
  const orderColumns = useMemo(
    () => generateOrderPlaceColumns(handleOpenModal),
    [],
  )
  const itemsQuantity = useMemo(
    () => orderRows.reduce((acc, item) => acc + Number(item.quantity), 0),
    [orderRows],
  )

  useEffect(() => {
    if (!placeItems) {
      return
    }
    ;(async function() {
      try {
        const itemsIds = Array.from(
          new Set((placeItems || []).map((item) => item.id)),
        )
        const result = await fetchItems({ filter: { ids: itemsIds } })
        setOriginalSkus(result.skus)
      } catch (e) {
        console.error('Оишбка получения номенклатуры')
      }
    })()
  }, [placeItems])

  useEffect(() => {
    getPackagings().then((data) => {
      setPackagings(data)
    })
  }, [])

  const dimensions = state.documentRaw?.dimensions
  const dimsStr = getDimensionStr(dimensions, { from: 'mm', to: 'sm' }, [
    'depth',
    'width',
    'height',
  ])
  const packaging_id = packagings.find(
    (el) => el.id === state?.documentRaw?.packaging_id,
  )

  return (
    <>
      <DocumentsParams
        withCopy={!openModal}
        rows={orderRows}
        columns={orderColumns}
        tableTitle={'Товары'}
        tableExtraHeader={
          <span className={styles.allCount}>Общее кол-во: {itemsQuantity}</span>
        }
      >
        <div className={styles.fieldsWrapper}>
          <InputWrapper
            className={styles.fieldType25}
            value={`${state.documentRaw?.sequence_num}`}
            id={'spaceNum'}
            label={'Номер места'}
            disabled
          />

          <InputWrapper
            className={styles.fieldType25}
            id={'spaceId'}
            label={'ID места'}
            value={state.documentRaw?.id}
            disabled
          />

          <InputWrapper
            className={styles.fieldType25}
            id={'weight'}
            label={'Вес (КГ)'}
            value={`${convertWeight('g', 'kg', state.documentRaw?.weight || 0)}`}
            disabled
          />

          <InputWrapper
            className={styles.fieldType25}
            id={'dimensions'}
            label={'Габариты ГхШхВ (СМ)'}
            value={dimsStr}
            disabled
          />
          <InputWrapper
            className={styles.fieldType25}
            id={'packaging_id'}
            label={'Упаковачный материал'}
            value={packaging_id?.title || '-'}
            disabled
          />
        </div>
        {
          openModal ? (
            <SerialNumbersModal
              isOpen={openModal}
              serialNumbers={serialNumbers}
              onClose={handleCloseModal}
            />
          ) : null
        }
      </DocumentsParams>

    </>
  )
}

export default DocumentsParamsFields
