
/** Регулярное выражение для честного знака */
export const DefaultChzRegExp: RegExp =
  /^01(\d{14})21(.{6}|.{13}|.{20})(\x1d|)(91|93)(.{4})(\x1d|)((92(.{88}|.{44}))|(\s*))(\x1d|)$/g

/** ЧЗ - заглушки, которые не проверяются на уникальность */
export const ChzExcludeCodes = [
  "01000000000000002100000000000009100009200000000000000000000000000000000000000000000"
]


// код серийного номера Честный знак
export const CHZ_CODE = 'chestniy_znak'

export const isChz = (chzValue: string) => {
  return new RegExp(DefaultChzRegExp).test(chzValue)
}
