import { ISelectOption } from '@/interfaces'


/* ID значения анкеты поставки предпочтительного типа приемки */
export const ACCEPTANCE_PREFERRED_TYPE = 'preferredType';

export enum ACCEPTANCE_TYPES_ENUM {
  PIECE = "PIECE",
  BOX = "BOX",
  PALLET = "PALLET",
}
export const PREFERRED_TYPES = {
  "ШТУЧНО": ACCEPTANCE_TYPES_ENUM.PIECE,
  "МОНО_КОРОБА": ACCEPTANCE_TYPES_ENUM.BOX,
  "МОНО_ПАЛЛЕТЫ": ACCEPTANCE_TYPES_ENUM.PALLET,
}

export const ACCEPTANCE_TYPES: ISelectOption[] = [
  {
    value: ACCEPTANCE_TYPES_ENUM.PIECE,
    label: 'Поштучно',
  },
  {
    value: ACCEPTANCE_TYPES_ENUM.BOX,
    label: 'По коробам',
  },
  {
    value: ACCEPTANCE_TYPES_ENUM.PALLET,
    label: 'По паллетам',
  },
]