import React from 'react'

import { IOrderItem, ITableColumn, ITableRow } from 'src/interfaces'
import { BarcodesCell, ImageCell } from 'src/components'
import { NomenclatureCell } from '../../../../../components'

export const generateOrderColumns = (): ITableColumn[] => {
  return [
    {
      key: 'image',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '150px',
      renderCell: ({ row }: any) => {
        return <ImageCell image={row.image} />
      },
    },
    {
      key: 'name',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: '2fr',
      renderCell: ({row}) => (
        <NomenclatureCell
          target={'_blank'}
          title={row.name}
          itemId={row.id || '-'}
        />
      )
    },
    {
      key: 'barcodes_value',
      title: 'ШК',
      title_txt: 'ШК',
      gridCellWidth: '300px',
      renderCell: ({row}) => (
        <BarcodesCell barcodes={row.barcodes}/>
      )
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
    },
    {
      key: 'price',
      title: 'Цена',
      title_txt: 'Цена',
    },
  ]
}

export const generateOrderRows = (orderItems: IOrderItem[]): ITableRow[] => {
  return orderItems.map((item) => {
    return {
      id: item.id,
      image: item.image,
      quantity: item.quantity,
      barcodes: item?.barcodes,
      barcodes_value: item?.barcodes.map(el => `  ${el.barcode}`),
      name: item.title,
      price: item.price_data?.price,
    }
  })
}
