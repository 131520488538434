import React, { FC } from 'react'
import styles from './OrderStatusBadge.module.scss'
import { Badge } from '@consta/uikit/Badge'
import { ORDER_STATUS_BADGE_TYPE, ORDER_STATUSES_DESCRIPTION } from 'src/interfaces'


interface Props {
  status: string,
  cancelRequested?: boolean,
}

const OrderStatusBadge: FC<Props> = (props) => {
  const { status, cancelRequested } = props
  return (
    <div className={styles.wrapper}>
      <Badge
        status={ORDER_STATUS_BADGE_TYPE[status]}
        label={ORDER_STATUSES_DESCRIPTION[status]}
        size={'s'}
      />
      {
        cancelRequested ? (
          <Badge
            status={'error'}
            label={'Отменен'}
            view={'stroked'}
            size={'s'}
          />
        ) : null
      }
    </div>
  )
}

export default OrderStatusBadge
