import React, { useCallback, useMemo, useState } from 'react'
import styles from './documents-params.module.scss'
import { Button, InputWrapper, ItemPreview, Modal } from 'src/components'
import { DocumentsParams } from '../../../../../components'
import { useCompletingFormContext } from '@pages/OperationsAndDocumentsPage/context/TaskOfCompletingFormContext'
import { generateCompletingColumns, generateCompletingRows } from './utils'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { PATHS } from '@shared/routing'
import { SECTIONS, tabs } from '@pages/DirectoriesPage/constants'
import { PhysicalSetOrderOperationEnum } from 'src/interfaces'
import { MassPhysicalSetForm } from '../MassPhysicalSetForm/MassPhysicalSetForm'
import { StateDataType } from '../MassPhysicalSetForm/types'
import useMassProcessPhysicalSet, { IMassProcessPhysicalSetData } from 'src/hooks/physicalSet/useMassProcessPhysicalSet'
import { convertDimensionsNum, isDimensionsCorrect } from '@shared/helpers'
import { useNotifications } from '@shared/providers/NotificationProvider'

interface IProps {
  refetch: () => void
}

const DocumentsParamsFields = (props: IProps) => {
  const { id } = useParams<{ id: string, }>()
  const { state } = useCompletingFormContext()
  const history = useHistory()
  const notifications = useNotifications()
  const {
    refetch,
  } = props

  const [massModalForm, setMassModalForm] = useState(false)

  const document = state.documentRaw
  const physicalSetOrderSku = document?.sku
  const merchantId = physicalSetOrderSku?.merchant?.id

  const massProcessPhysicalSet = useMassProcessPhysicalSet()


  const isDisassembling = document?.operation === PhysicalSetOrderOperationEnum.DISASSEMBLING

  const rows = useMemo(
    () => generateCompletingRows(document?.items || []),
    [document?.items],
  )
  const onClickPhysicalSet = useCallback((item: any) => {
    window.open(generatePath(PATHS.DIRECTORIES, {
      page: tabs.guides.value,
      section: SECTIONS.nomenclature.value,
      type: 'item',
      id: physicalSetOrderSku.id || '-',
    }))
  }, [physicalSetOrderSku])

  const handleCloseModal = () => {
    setMassModalForm(false)
  }
  const handleSubmitModal = async (data: StateDataType) => {
    const {
      tare_barcode,
      quantity,
      weight,
      packaging,
      dimensions,
    } = data
    const requestData: IMassProcessPhysicalSetData = {
      tare_barcode: tare_barcode,
      quantity: quantity ? Number(quantity) : undefined,
      weight: weight ? Number(weight) : undefined,
      packaging_id: packaging ? String(packaging.id) : undefined,
      dimensions: isDimensionsCorrect(dimensions)
        ? convertDimensionsNum('sm', 'mm', {
          width: Number(dimensions.width),
          height: Number(dimensions.height),
          depth: Number(dimensions.depth),
        })
        : undefined,
    }

    massProcessPhysicalSet
      .mutateAsync({ orderId: id, data: requestData })
      .then(() => {
        refetch()
        notifications.show('success','Комплекты успешно собраны')
        setMassModalForm(false)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const columns = useMemo(() => generateCompletingColumns(), [])

  const title = `Задание на ${isDisassembling ? 'разукомплектование' : 'комплектацию'}`
  const operationTitle = `${isDisassembling ? 'разборка' : 'сборка'}`
  return (
    <>
      <DocumentsParams
        rows={rows}
        columns={columns}
        tableTitle={title}
        tableExtraHeader={
          <div className={styles.physicalSetsInfo}>
            <p>
              К {isDisassembling ? 'разборке' : 'сборке'}: {document?.quantity}
            </p>
            <p>
              {isDisassembling ? 'Разборано' : 'Собрано'}: {document?.places?.length}
            </p>
          </div>
        }
        extraContent={
          <div className={styles.extraHeader}>
            {physicalSetOrderSku ? (
              <div
                className={styles.previewItem}
                onClick={onClickPhysicalSet}
              >
                <ItemPreview
                  image={physicalSetOrderSku.image}
                  title={physicalSetOrderSku.title}
                  article={physicalSetOrderSku.article}
                  imgClassName={styles.previewImg}
                />
              </div>
            ) : null}
            <Button
              label={`Массовая ${operationTitle}`}
              view='secondary'
              onClick={() => setMassModalForm(true)}
            />
          </div>
        }
      >
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.merchant?.title || null}
          id={'merchant'}
          label={'Организация'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.status || null}
          id={'status'}
          label={'Статус'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.operation || null}
          id={'operation'}
          label={'Тип'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.comment || null}
          id={'comment'}
          label={'Комментарий'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.sku.id || null}
          id={'sku'}
          label={'Партия'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={`${state.documentRaw?.quantity}` || null}
          id={'quantity'}
          label={'Количество'}
          disabled
        />
      </DocumentsParams>
      {
        massModalForm ? (
          <Modal
            withClose
            isOpen
            hasOverlay
            onOverlayClick={handleCloseModal}
            onClose={handleCloseModal}
            title={`Массовая ${operationTitle}`}
            subtitle={' '}
            size='m'
          >
            <MassPhysicalSetForm
              isDisassembling={isDisassembling}
              loading={massProcessPhysicalSet.isLoading}
              merchantId={merchantId}
              onClose={handleCloseModal}
              onSubmit={handleSubmitModal}
            />
          </Modal>
        ) : null
      }
    </>
  )
}
export default DocumentsParamsFields
