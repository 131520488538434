import React, { useState } from 'react'
import styles from './photos-modal.module.scss'
import { Button } from '@consta/uikit/Button'
import { Modal, WebcamModal } from '@/components'
import { ModalProps } from '@/interfaces'

interface IPhotosModal extends ModalProps {
  children?: React.ReactNode
  onSubmit: (photos: string[]) => void
  title?: string
  header?: string
  submitDisabled?: boolean
}



const PhotosModal = (props: IPhotosModal) => {
  const { isOpen, onSubmit, onClose, children, title, submitDisabled, header} = props

  const [webcamModalVisible, setWebcamModalVisible] = useState<boolean>(false)
  const [srcImgs, setSrcImgs] = useState<string[]>([])

  const handleSubmit = () => {
    onSubmit(srcImgs)
  }

  const handleNewScreenshot = (imgBase64Src: string) => {
    console.log(srcImgs.slice(-2))
    setSrcImgs([
      ...srcImgs.slice(-2),
      imgBase64Src
    ])
    setWebcamModalVisible(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      title={title}
    >
      {header ? (
        <h3>{header}</h3>
      ) : null}

      {children}

      <div className={styles.preview}>
        {srcImgs.map((src, idx) => (
          <img src={src} key={idx} />
        ))}
      </div>
      <WebcamModal
        isOpen={webcamModalVisible}
        onClose={() => setWebcamModalVisible(false)}
        handleNewScreenshot={handleNewScreenshot}
      />

      <div className={styles.btns}>
        <Button
          label="Отмена"
          view="ghost"
          onClick={onClose}
        />

        <Button
          label="Сделать фото"
          onClick={() => setWebcamModalVisible(true)}
        />

        <Button
          label="Сохранить"
          view="primary"
          className={styles.btn}
          disabled={submitDisabled}
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  )
}

export default PhotosModal
