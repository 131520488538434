import { useQuery } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, ISticker } from 'src/interfaces'

/*
 * Получение транспортной накладной
 */

interface IParams {
  shipmentId: string
}

const getWaybillForPrint = async ({ shipmentId }: IParams) => {
  const result = await api.get(`/shipment/${shipmentId}/waybill`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

interface IResult extends ApiReqResult, ISticker {}

export const useFetchWaybillForPrint = () => ({
  fetch: async (params: IParams): Promise<IResult> => await getWaybillForPrint(params)
})

export const useGetWaybillForPrint = (
  params: IParams,
  callback?: (data: ISticker) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IResult>(
    [`getWaybillForPrint`, params],
    () => getWaybillForPrint(params),
    {
      enabled: false,
      keepPreviousData: false,
      onSuccess: ({ success, ...data }) => {
        if (success) {
          callback?.(data)
        } else {
          error?.()
        }
      },
      onError: () => error?.(),
      onSettled: () => settled?.(),
    },
  )
}

