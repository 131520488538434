import api from '@shared/api'

import { MOCK_STICKER_6x6, provideMock } from 'src/mocks'

import { PrintResult } from 'src/interfaces'

interface useGetPickingListParams {
  doc_id: string
}

const getPickingList = async (params: useGetPickingListParams) => {
  const result = await api.get(`/picking/${params.doc_id}/picking-list`)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

const useGetPickingList = () => ({
  fetch: (params: useGetPickingListParams) =>
    provideMock<useGetPickingListParams, PrintResult>(getPickingList, params, [
      {
        key: 'doc_id',
        value: 'mock',
        result: {
          success: true,
          content: MOCK_STICKER_6x6,
          printer: 'sticker_6x6',
        },
      },
    ])(),
})

export default useGetPickingList
