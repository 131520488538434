import { useQuery, UseQueryOptions } from 'react-query'

import api from '@shared/api'
import { ApiReqResult, IMoving, ISku, PaginationResult } from 'src/interfaces'

export interface IParams {
  doc_id: string
}

interface GetMovingResult extends ApiReqResult, PaginationResult {
  document: IMoving
  skus: ISku[]
}
// http://wms-test.kak2c.ru/warehouse-api/moving/{doc_id}
const useGetMovingDoc = (params: IParams, options?: UseQueryOptions<any>) => {
  return useQuery<GetMovingResult>(
    [`getMovingDoc`, params],
    async () => {
      const result = await api.get(`/moving/${params.doc_id}`)
      return result.data as GetMovingResult
    },
    options,
  )
}
export default useGetMovingDoc
