import { DocumentTypeEnum, ITab } from '@/interfaces'
import { ValueOf } from '@shared/helpers'

export const listPostfix = '__LIST'
export const formPostfix = '__FORM'
export const OperAndDocumPages = {
  // carrierCompany: `carrierCompany${listPostfix}`,

  servicesRenderedList: `${DocumentTypeEnum.SERVICE}${listPostfix}`,
  servicesRenderedForm: DocumentTypeEnum.SERVICE,
  servicesRenderedAddDocument: `${DocumentTypeEnum.SERVICE}${formPostfix}`,

  suppliesList: `${DocumentTypeEnum.SUPPLY_PLAN}${listPostfix}`,
  supplyForm: DocumentTypeEnum.SUPPLY_PLAN,

  receiptsList: `${DocumentTypeEnum.RECEIPT}${listPostfix}`,
  receiptForm: DocumentTypeEnum.RECEIPT,

  placingList: `${DocumentTypeEnum.PLACING}${listPostfix}`,
  placingForm: DocumentTypeEnum.PLACING,

  batchesList: `${DocumentTypeEnum.ORDER_BATCH}${listPostfix}`,
  batchForm: DocumentTypeEnum.ORDER_BATCH,

  ordersShippedList: `${DocumentTypeEnum.ORDER}${listPostfix}`,
  ordersShippedForm: DocumentTypeEnum.ORDER,

  orderPlaceList: `${DocumentTypeEnum.ORDER_PLACE}${listPostfix}`,
  orderPlaceForm: DocumentTypeEnum.ORDER_PLACE,

  pickingList: `${DocumentTypeEnum.PICKING}${listPostfix}`,
  pickingForm: DocumentTypeEnum.PICKING,

  shipmentPlanList: `${DocumentTypeEnum.SHIPMENT_PLAN}${listPostfix}`,
  shipmentPlanForm: DocumentTypeEnum.SHIPMENT_PLAN,

  shipmentList: `${DocumentTypeEnum.SHIPMENT}${listPostfix}`,
  shipmentForm: DocumentTypeEnum.SHIPMENT,

  returnList: `${DocumentTypeEnum.RETURN}${listPostfix}`,
  returnForm: DocumentTypeEnum.RETURN,

  transportPlaceList: `${DocumentTypeEnum.TRANSPORT_PLACE}${listPostfix}`,
  transportPlaceForm: DocumentTypeEnum.TRANSPORT_PLACE,

  billingList: `${DocumentTypeEnum.BILLING}${listPostfix}`,
  billingForm: DocumentTypeEnum.BILLING,

  returnPlaceList: `${DocumentTypeEnum.RETURN_PLACE}${listPostfix}`,
  returnPlaceForm: DocumentTypeEnum.RETURN_PLACE,

  replaceList: `${DocumentTypeEnum.REPLACE}${listPostfix}`,
  replaceForm: DocumentTypeEnum.REPLACE,

  taskOfCompletingList: `${DocumentTypeEnum.TASK_OF_COMPLETING}${listPostfix}`,
  taskOfCompletingForm: DocumentTypeEnum.TASK_OF_COMPLETING,

  inventoryList: `${DocumentTypeEnum.INVENTORY}${listPostfix}`,
  inventoryForm: `${DocumentTypeEnum.INVENTORY}`,

  workspacesList: `${DocumentTypeEnum.WORKSPACES}${listPostfix}`,

  movingList: `${DocumentTypeEnum.MOVING}${listPostfix}`,
  movingForm: `${DocumentTypeEnum.MOVING}`,

  billingServicesList: `${DocumentTypeEnum.BILLING_SERVICES}${listPostfix}`,

  transportationTemplatesList: `${DocumentTypeEnum.TRANSPORTATION_TEMPLATES}${listPostfix}`,
  transportationTemplatesForm: `${DocumentTypeEnum.TRANSPORTATION_TEMPLATES}`,

  carryerQubyList: `${DocumentTypeEnum.CARRYER_QUBY}${listPostfix}`,
  carryerQubyForm: `${DocumentTypeEnum.CARRYER_QUBY}`,
} as const
export type OperAndDocumPagesType = ValueOf<typeof OperAndDocumPages>

export const sidebarPagesTabs: ITab[] = [
  // { value: OperAndDocumPages.carrierCompany, label: 'Компании перевозчиков' },
  { value: OperAndDocumPages.suppliesList, label: 'Документы приемки' },
  { value: OperAndDocumPages.receiptsList, label: 'Приходные ордера' },
  { value: OperAndDocumPages.placingList, label: 'Размещение' },
  { value: OperAndDocumPages.batchesList, label: 'Батчи' },
  { value: OperAndDocumPages.ordersShippedList, label: 'Заказ на отгрузку' },
  { value: OperAndDocumPages.pickingList, label: 'Отбор' },
  { value: OperAndDocumPages.shipmentPlanList, label: 'План на отгрузку' },
  { value: OperAndDocumPages.shipmentList, label: 'Отгрузка со склада' },
  { value: OperAndDocumPages.returnList, label: 'Возврат заказов' },
  { value: OperAndDocumPages.transportPlaceList, label: 'Транспортные места' },
  { value: OperAndDocumPages.billingList, label: 'Биллинг' },
  { value: OperAndDocumPages.billingServicesList, label: 'Биллинг услуги' },
  { value: OperAndDocumPages.servicesRenderedList, label: 'Оказанные услуги' },
  { value: OperAndDocumPages.replaceList, label: 'Коррекция' },
  {
    value: OperAndDocumPages.taskOfCompletingList,
    label: 'Задание на комплектацию',
  },
  {
    value: OperAndDocumPages.inventoryList,
    label: 'Инвентаризация',
  },

  {
    value: OperAndDocumPages.movingList,
    label: 'Перемещение',
  },
  {
    value: OperAndDocumPages.carryerQubyList,
    label: 'Перевозки Quby',
  },
  {
    value: OperAndDocumPages.transportationTemplatesList,
    label: 'Перевозки шаблонов Quby',
  },
]

export const getListPageByFormPage = (page: string) => {
  /** Проверяем к какой стр списков относится форма */
  return Object.values(OperAndDocumPages).find(
    (pageValue) => pageValue === `${page}${listPostfix}`,
  )
}
