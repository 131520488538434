import React, { useMemo } from 'react'

import { InputWrapper } from 'src/components'
import { DocumentsParams } from '../../../../../components'
import { generateReturnPlaceColumns, generateReturnPlaceRows } from './utils'
import { useReturnPlaceFormContext } from '../../../../../context/ReturnPlaceFormContext'
import days from 'dayjs'

import styles from './documents-params.module.scss'
import { DATE_TIME_VIEW } from '@shared/const/date'

interface ReturnPlaceFormContentProps {

}


const DocumentsParamsFields = (props: ReturnPlaceFormContentProps) => {
  const { state } = useReturnPlaceFormContext()

  const orderRows = useMemo(() => generateReturnPlaceRows(
    state.documentRaw?.items || [],
    state.originalSkus || []
  ), [state.documentRaw?.items, state.originalSkus])
  const orderColumns = useMemo(() => generateReturnPlaceColumns(), [])


  return (
    <DocumentsParams
      rows={orderRows}
      columns={orderColumns}
      tableTitle={'Товары'}
    >
      <div className={styles.fieldsWrapper}>
        <InputWrapper
          className={styles.fieldType25}
          value={days(state.documentRaw?.date).format(DATE_TIME_VIEW)}
          id={'orderDate'}
          label={'Дата заказа'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={`${state.documentRaw?.order_id}`}
          id={'orderId'}
          label={'Номер заказа'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={`${state.documentRaw?.id}`}
          id={'spaceNum'}
          label={'Штрихкод места'}
          disabled
        />
      </div>
    </DocumentsParams>
  )
}

export default DocumentsParamsFields
