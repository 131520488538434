import React, { useMemo, useState } from 'react'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { DocumentsListWrapper } from '../../../components'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import styles from './BillingList.module.scss'
import { useCreateBilling, useGetMerchantList, useGetQueryBillingList } from 'src/hooks'
import { Button } from 'src/components'
import BillingModal, { ISubmitBillingModal } from '../BillingModal/BillingModal'
import { DATE_MONTH_FORMAT } from '@shared/const/date'
import days from 'dayjs'
import useBillingPas from '@/hooks/billing/useBillingPas'
import { useFilter } from '../../../hooks/useFilter'

const BillingList = () => {
  const { filterState, setFilterState } = useFilter()
  const [openModal, setOpenModal] = useState(false)
  const [recreate, setRecreate] = useState(false)
  const [uploadPass, setUploadPass] = useState(false)

  const { isLoading: uploadToPassLoading, mutateAsync: uploadToPass } = useBillingPas()
  const { data: merchantData } = useGetMerchantList({})
  const {
    isFetching: billingListLoading,
    data: billingListData,
    refetch: refetchBillingList,
  } = useGetQueryBillingList(generateFilterParams(filterState))

  const { isLoading: createBillingLoading, mutateAsync: createBilling } = useCreateBilling()

  const rows = useMemo(
    () => generateRows(billingListData?.documents || []),
    [billingListData?.documents],
  )
  const columns = useMemo(() => generateColumns(), [])
  const filtersConfig = generateFilters(merchantData?.merchants || [])

  const handleSubmit = (obj: ISubmitBillingModal) => {
    const data = {
      merchant_ids: obj.merchant.map((el) => String(el.id)),
      recreate: recreate,
      year_month: days(obj.month).format(DATE_MONTH_FORMAT) || '',
    }

    if (uploadPass) {
      uploadToPass(data)
        .then(async () => {
          setOpenModal(false)
          setUploadPass(false)
          await refetchBillingList()
        })
        .catch(() => {
        })
      return
    }

    createBilling(data)
      .then(async () => {
        setOpenModal(false)
        setRecreate(false)
        await refetchBillingList()
      })
      .catch(() => {
      })
  }
  return (
    <>
      <DocumentsListWrapper
        title={'Биллинг'}
        rows={rows}
        columns={columns}
        filterState={filterState}
        setFilterState={setFilterState}
        filtersConfig={filtersConfig}
        loading={billingListLoading}
        pagination={billingListData?.pagination}
        tableExtraHeaderRight={
          <div className={styles.tableExtraHeader}>
            <Button
              size={'s'}
              label={'Выгрузить в PASS'}
              view='secondary'
              loading={uploadToPassLoading}
              onClick={() => {
                setUploadPass(true)
                setOpenModal(true)
              }}
            />
            <Button
              size={'s'}
              label={'Переформировать биллинг'}
              view='secondary'
              loading={createBillingLoading}
              onClick={() => {
                setRecreate(true)
                setOpenModal(true)
              }}
            />
            <Button
              size={'s'}
              label={'Добавить'}
              loading={createBillingLoading}
              onClick={() => setOpenModal(true)}
            />
          </div>
        }
      />
      {openModal ? (
        <BillingModal
          isOpen={openModal}
          isLoading={uploadToPassLoading || createBillingLoading}
          onClose={() => {
            setUploadPass(false)
            setRecreate(false)
            setOpenModal(false)
          }}
          refetch={refetchBillingList}
          onSubmit={handleSubmit}
        />
      ) : null}
    </>
  )
}

export default BillingList
