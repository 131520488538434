import React, { useState } from 'react'
import styles from './AddModalZeroCells.module.scss'
import { Modal } from 'src/components'
import { IDocumentsFacts, IWarehouseCell, ModalProps } from 'src/interfaces'
import { Button } from '@consta/uikit/Button'
import { TextField } from '@consta/uikit/TextField'
import { useAddResultInventory } from 'src/hooks/warehouse/inventory'
import { useNotifications } from '@shared/providers/NotificationProvider'

interface IProps extends ModalProps {
  cells: IWarehouseCell[]
  plans: IDocumentsFacts[] | undefined
  docId: string | undefined
  refetch: () => void
}

const AddModalZeroCells = (props: IProps) => {
  const {
    isOpen,
    onClose,
    cells = [],
    plans = [],
    docId = '',
    refetch = () => {},
  } = props
  const [textInput, setTextInput] = useState('')

  const notification = useNotifications()
  const { mutateAsync: addResultInventory } =
    useAddResultInventory({})

  const handleSubmit = () => {
    const cellsInput = textInput
      .toUpperCase()
      .split('\n')
      .filter((el) => el.trim())
      .map((el) => el.trim())
    cellsInput.forEach((el) => {
      const barcodeCell = cells.find((cell) => cell.title === el)
      if (!barcodeCell) {
        notification?.show('alert', `Данный адрес ячейки ${el} не найден`)
        return
      }
      const planItems = plans.find(
        (barcode) => barcode.cell_barcode === barcodeCell?.barcode,
      )
      if (!planItems) {
        return
      }

      const data = {
        cell_barcode: barcodeCell?.barcode || '',
        items: [...planItems?.items.map((el) => ({ ...el, quantity: 0 }))],
      }

      addResultInventory({ docId, data }).then(() => refetch())
    })

    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.modal}
      size="m"
      titleAlign={'left'}
      title={'Вставка нулевых ячеек'}
    >
      <h5>Вставте адреса нулевых ячеек в текстовом поле</h5>
      <div className={styles.formWrapper}>
        <div className={styles.inputCell}>
          <TextField
            className={styles.textarea}
            width="full"
            value={textInput}
            type="textarea"
            rows={20}
            placeholder="Каждая ячейка должна начинаться с новой строки"
            onChange={({ value }) => {
              setTextInput(value || '')
            }}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button label="Отмена" view={'ghost'} onClick={onClose} />
        <Button
          label="Загрузить"
          view={'primary'}
          disabled={!textInput}
          onClick={() => handleSubmit()}
        />
      </div>
    </Modal>
  )
}
export default AddModalZeroCells
