import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IPaginationBase, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'
import { PhysicalSetOrderBatchDto, PhysicalSetOrderBatchStatusEnum } from '../../interfaces/physicalSetOrderBatch'


interface IParams extends Partial<IPaginationBase> {
  __front__light?: boolean
  search?: string
  from?: string
  to?: string
  statuses?: PhysicalSetOrderBatchStatusEnum[]
  executor_ids?: string[]
}

interface IResult extends ApiReqResult, PaginationResult {
  batches: PhysicalSetOrderBatchDto[]
}

const getPhysicalSetOrderBatchList = async (params: IParams = {}): Promise<IResult> => {
  const searchParams = convertObjectToURLParams(params)
  const result = await api.get(`/physical-set-order-batch${searchParams}`)
  if (!result?.data?.success) {
    throw new Error('not success')
  }
  return result.data
}

export const useFetchPhysicalSetOrderBatchList = () => ({
  fetch: (params?: IParams) => getPhysicalSetOrderBatchList(params),
})


export const useGetPhysicalSetOrderBatchList = (
  params?: IParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IResult>(
    [`getPhysicalSetOrderBatchList`, params],
    async () => getPhysicalSetOrderBatchList(params),
    {
      keepPreviousData: true,
      ...options,
    },
  )
}

