import { useMutation } from 'react-query'

import api from '@shared/api'

import { MOCK_DELETE_SUPPLY_PART, provideMock } from 'src/mocks'

import { ApiReqResult } from 'src/interfaces'

interface useDeleteSupplyPartParamsType {
  supplyId: string
}

const useDeleteSupplyPart = (params: useDeleteSupplyPartParamsType) => {
  return useMutation(async (data: { receiptId: string }) => {
    try {
      const result = await provideMock<
        useDeleteSupplyPartParamsType,
        { data: ApiReqResult }
      >(
        async () =>
          await api.delete(
            `/receiving/supply/${params.supplyId}/part/${data.receiptId}`,
          ),
        params,
        {
          key: 'supplyId',
          value: 'mock',
          result: MOCK_DELETE_SUPPLY_PART,
        },
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result.data
    } catch (e) {
      throw e
    }
  })
}

export default useDeleteSupplyPart
