import { PATHS } from '@shared/routing'
import { generatePath } from 'react-router-dom'
import { DocumentTypeEnum } from 'src/interfaces'

export enum HeaderTypeEnum {
  operations = 'Операции',
  documents = 'Документы',
  reports = 'Отчеты',
  noname = '',
}

export enum FrontMenuCategoryTypeEnum {
  acceptance = 'Приемка',
  warehouse = 'Склад',
  unload = 'Отгрузка',
  billing = 'Биллинг',
  legend = 'Справочники',
  settings = 'Настройки',
  other = 'Прочее',
}

export enum DocumentCategoryEnum {
  ACCEPTANCE = 'ACCEPTANCE',
  BILLING = 'BILLING',
  OTHER = 'OTHER',
  SHIPMENT = 'SHIPMENT',
  WAREHOUSE = 'WAREHOUSE',
}

export const CategoryTypesMatcher = {
  [DocumentCategoryEnum.ACCEPTANCE]: FrontMenuCategoryTypeEnum.acceptance,
  [DocumentCategoryEnum.BILLING]: FrontMenuCategoryTypeEnum.billing,
  [DocumentCategoryEnum.OTHER]: FrontMenuCategoryTypeEnum.other,
  [DocumentCategoryEnum.SHIPMENT]: FrontMenuCategoryTypeEnum.unload,
  [DocumentCategoryEnum.WAREHOUSE]: FrontMenuCategoryTypeEnum.warehouse,
}

export interface DocumentsType {
  category: DocumentCategoryEnum,
  title: string,
  value: string, // reports enum
}

export interface IRoute {
  name: string
  mainType: FrontMenuCategoryTypeEnum
  documentType?: string
  headerType: HeaderTypeEnum
  route: string
}

export interface IMenuList {
  label: string
  type: FrontMenuCategoryTypeEnum
  items?: IRoute[]
  pages?: string[]
}

const getNewOperDocsPath = (docType: DocumentTypeEnum) => generatePath(PATHS.DOCUMENTS_LIST, { docType })
const getReportsPath = (page?: string) => generatePath(PATHS.REPORTS, { page })
const getDirectoriesPath = ({ page, section }: { page: string, section: string }) =>
  generatePath(PATHS.DIRECTORIES, { page, section })

export const MENU_LIST: IMenuList[] = [
  {
    label: 'Приемка',
    type: FrontMenuCategoryTypeEnum.acceptance,
    pages: [],
    items: [
      {
        name: 'Приемка товара',
        mainType: FrontMenuCategoryTypeEnum.acceptance,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.ACCEPTANCE_SCAN),
      },
      {
        name: 'Размещение товара',
        mainType: FrontMenuCategoryTypeEnum.acceptance,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.PRODUCT_PLACEMENT, { page: 'docList' }),
      },
      {
        name: 'Приемка возвратов',
        mainType: FrontMenuCategoryTypeEnum.acceptance,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.REFUND_ACCEPTANCE, { page: 'invoiceScanPage' }),
      },
      {
        name: 'Сортировка кроссдокингом',
        mainType: FrontMenuCategoryTypeEnum.acceptance,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.CROSSDOCKING_SORTING, { page: 'scan' }),
      },
    ],
  },
  {
    label: 'Склад',
    type: FrontMenuCategoryTypeEnum.warehouse,
    pages: [],
    items: [
      {
        name: 'Перемещение между складами/адресами',
        mainType: FrontMenuCategoryTypeEnum.warehouse,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.GOODS_RELOCATION),
      },
      {
        name: 'Сборка комплектов',
        mainType: FrontMenuCategoryTypeEnum.warehouse,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.ASSEMBLING_PHYSICAL_SET),
      },
      {
        name: 'Пересчет товара (инвентаризация)',
        mainType: FrontMenuCategoryTypeEnum.warehouse,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.INVENTORY_TSD),
      },
      {
        name: 'Пересчет товара ячейка (частичная инвентаризация)',
        mainType: FrontMenuCategoryTypeEnum.warehouse,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.PARTIAL_INVENTORY_TSD),
      },
      {
        name: 'Проверка товара/адреса (ТСД)',
        mainType: FrontMenuCategoryTypeEnum.warehouse,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.WAREHOUSE_CONTROL_SCAN),
      },

      {
        name: 'Сортировка батча заказов',
        mainType: FrontMenuCategoryTypeEnum.warehouse,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.BATCH_SORTING),
      },
    ],
  },
  {
    label: 'Отгрузка',
    type: FrontMenuCategoryTypeEnum.unload,
    pages: [],
    items: [
      {
        name: 'Генерация батчей заказов',
        mainType: FrontMenuCategoryTypeEnum.warehouse,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.BATCH_GENERATION, { page: 'batchList' }),
      },
      {
        name: 'Генерация батчей комплектации',
        mainType: FrontMenuCategoryTypeEnum.warehouse,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.BATCH_GENERATION_PHYSICAL_SET, { page: 'batchList' }),
      },
      {
        name: 'Упаковка заказов',
        mainType: FrontMenuCategoryTypeEnum.warehouse,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.ORDER_SCAN),
      },
      {
        name: 'Сверка реестра',
        mainType: FrontMenuCategoryTypeEnum.unload,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.REGISTRY_SCAN),
      },
      {
        name: 'Формирование ТМ',
        mainType: FrontMenuCategoryTypeEnum.unload,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.F_OF_TRANSPORT_PLACES_LIST, { page: 'uncompletedPlaces' }),
      },
      {
        name: 'Формирование ТМ ВБ',
        mainType: FrontMenuCategoryTypeEnum.unload,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.F_OF_PLACES_LIST, { page: 'placesList' }),
      },
      {
        name: 'Маркировка ТМ',
        mainType: FrontMenuCategoryTypeEnum.unload,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.MARKING_TRANSPORT_PLACE),
      },
      {
        name: 'Отгрузка со склада',
        mainType: FrontMenuCategoryTypeEnum.unload,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.SHIPMENT_FROM_WAREHOUSE_SCAN),
      },
    ],
  },
  {
    label: 'Биллинг',
    type: FrontMenuCategoryTypeEnum.billing,
    pages: [],
    items: [],
  },
  {
    label: 'Справочники',
    type: FrontMenuCategoryTypeEnum.legend,
    pages: [
      'guides',
    ],
    items: [
      {
        name: 'Номенклатура',
        mainType: FrontMenuCategoryTypeEnum.legend,
        headerType: HeaderTypeEnum.noname,
        route: getDirectoriesPath({ page: 'guides', section: 'nomenclature' }),
      },
      {
        name: 'Роли',
        mainType: FrontMenuCategoryTypeEnum.legend,
        headerType: HeaderTypeEnum.noname,
        route: getDirectoriesPath({ page: 'guides', section: 'roles' }),
      },
      {
        name: 'Компании перевозчиков',
        mainType: FrontMenuCategoryTypeEnum.other,
        headerType: HeaderTypeEnum.noname,
        route: getDirectoriesPath({ page: 'guides', section: 'carriersCompany' }),
      },
      {
        name: 'Перевозчики (ИП, ТК)',
        mainType: FrontMenuCategoryTypeEnum.unload,
        headerType: HeaderTypeEnum.noname,
        route: getDirectoriesPath({ page: 'guides', section: 'carriers' }),
      },
      {
        name: 'Договоры доставки',
        mainType: FrontMenuCategoryTypeEnum.legend,
        headerType: HeaderTypeEnum.noname,
        route: getDirectoriesPath({ page: 'guides', section: 'delivery' }),
      },
      {
        name: 'Перевозки шаблонов QUBY',
        mainType: FrontMenuCategoryTypeEnum.legend,
        headerType: HeaderTypeEnum.noname,
        route: '/operations-and-documents/TRANSPORTATION_TEMPLATES__LIST', // todo это надо прееместить в натсройки
      },
    ],
  },
  {
    label: 'Настройки',
    type: FrontMenuCategoryTypeEnum.settings,
    pages: [
      'settings',
    ],
    items: [
      {
        name: 'Склад',
        mainType: FrontMenuCategoryTypeEnum.settings,
        headerType: HeaderTypeEnum.noname,
        route: getDirectoriesPath({ page: 'settings', section: 'warehouse' }),
      },
      {
        name: 'Зоны',
        mainType: FrontMenuCategoryTypeEnum.settings,
        headerType: HeaderTypeEnum.noname,
        route: getDirectoriesPath({ page: 'settings', section: 'zones' }),
      },
      {
        name: 'Ворота',
        mainType: FrontMenuCategoryTypeEnum.settings,
        headerType: HeaderTypeEnum.noname,
        route: getDirectoriesPath({ page: 'settings', section: 'gates' }),
      },
      {
        name: 'Пользователи',
        mainType: FrontMenuCategoryTypeEnum.settings,
        headerType: HeaderTypeEnum.noname,
        route: getDirectoriesPath({ page: 'settings', section: 'users' }),
      },
      {
        name: 'Продавцы',
        mainType: FrontMenuCategoryTypeEnum.legend,
        headerType: HeaderTypeEnum.noname,
        route: getDirectoriesPath({ page: 'settings', section: 'users' }),
      },
      {
        name: 'Рабочие места',
        mainType: FrontMenuCategoryTypeEnum.settings,
        headerType: HeaderTypeEnum.noname,
        route: getDirectoriesPath({ page: 'settings', section: 'workspaces' }),
      },
    ],
  },
  {
    label: 'Прочее',
    type: FrontMenuCategoryTypeEnum.other,
    pages: [],
    items: [
      {
        name: 'Приемка товара (ТСД)',
        mainType: FrontMenuCategoryTypeEnum.other,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.ACCEPTANCE_FORM_TSD),
      },
      {
        name: 'Печать стикеров',
        mainType: FrontMenuCategoryTypeEnum.other,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.ARM_PRINTING),
      },
      {
        name: 'Сборка товаров ТСД',
        mainType: FrontMenuCategoryTypeEnum.other,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.GOODS_PICKING_TSD),
      },
      {
        name: 'Новая версия сборки товаров ТСД (V2)',
        mainType: FrontMenuCategoryTypeEnum.other,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.NEW_ASSEMBLY_TSD),
      },
      {
        name: 'Сборка товаров на РМ',
        mainType: FrontMenuCategoryTypeEnum.other,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.GOODS_PICKING),
      },
      {
        name: 'Перестикеровка товаров',
        mainType: FrontMenuCategoryTypeEnum.other,
        headerType: HeaderTypeEnum.operations,
        route: generatePath(PATHS.GOODS_RELABEL_SCAN),
      },
    ],
  },
]

const getHeaderType = (
  currentType: DocumentsType,
  reportsTypeList: DocumentsType[],
  documentsTypeList: DocumentsType[],
  addedTypes: IRoute[],
): HeaderTypeEnum => {
  const isDocument = Boolean(documentsTypeList.find(document => document.title === currentType.title))
  const isReport = Boolean(reportsTypeList.find(report => report.title === currentType.title))
  if (isDocument && isReport) {
    const addedType = addedTypes.find(type => type.name === currentType.title)
    if (addedType?.headerType === HeaderTypeEnum.reports) {
      return HeaderTypeEnum.documents
    } else {
      return HeaderTypeEnum.reports
    }
  }
  if (isReport)
    return HeaderTypeEnum.reports
  if (isDocument)
    return HeaderTypeEnum.documents
  return HeaderTypeEnum.noname
}

export const generateMenuList = (args: { reportsTypeList: DocumentsType[], documentsTypeList: DocumentsType[] }) => {
  const {
    reportsTypeList = [],
    documentsTypeList = [],
  } = args
  const typesList = [...reportsTypeList, ...documentsTypeList]
  return MENU_LIST.map(menuItem => {
    const operationsAndNonameItems = menuItem.items.filter(item =>
      item.headerType === HeaderTypeEnum.operations || item.headerType === HeaderTypeEnum.noname
    )
    const currentTypes = typesList.filter(type => CategoryTypesMatcher[type.category] === menuItem.type)
    menuItem.pages = [...menuItem.pages, ...currentTypes.map(type => type.value)]
    menuItem.items = [
      ...operationsAndNonameItems,
      ...currentTypes.reduce((acc, type) => {
        let headerType = getHeaderType(type, reportsTypeList, documentsTypeList, acc)
        const foundMenuItem = acc.find(accItem =>
          (accItem.headerType === headerType)
          && (accItem.name === type.title)
        )
        if (foundMenuItem) return acc
        return [
          ...acc,
          {
            name: type.title,
            mainType: menuItem.type,
            documentType: type.value,
            headerType: headerType,
            route: headerType === HeaderTypeEnum.reports
              ? getReportsPath(type.value)
              : getNewOperDocsPath(type.value as DocumentTypeEnum),
          }]
      }, [])]

    return menuItem
  })
}
