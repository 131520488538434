import { ICommonDocument } from 'src/interfaces'
import { Badge } from '@consta/uikit/Badge'
import styles from '../InventoryList.module.scss'
import { OperAndDocumPages } from '../../../../pagesType'
import { Button, DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import { TableColumn } from '@consta/uikit/Table'
import {
  INVENTORY_CONDITION,
  INVENTORY_CONDITION_BADGE_TYPE,
  INVENTORY_STATUS_BADGE_TYPE,
  INVENTORY_STATUSES,
} from '@/interfaces/statuses'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { IconRemove } from '@consta/icons/IconRemove'

export const generateRows = (documents: ICommonDocument[]) => {
  return documents.map((doc) => ({
    id: doc.id,
    date: doc.create_ts,
    date_value: days(doc.create_ts).format(DATE_TIME_VIEW),
    documentNum: doc.id,
    merchant: doc.merchant?.title,
    status: doc.status,
    status_value: INVENTORY_STATUSES[doc.status || ''],
    condition: doc.sku_condition,
    condition_value: INVENTORY_CONDITION[doc.sku_condition || ''],
  }))
}
export const generateColumns = (
  handleConfirmModal: (id: string) => void,
): TableColumn<any>[] => {
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({ row }) => {
        return <DateCell date={row.date} style={{ minWidth: '120px' }} />
      },
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({ row }) => {
        return (
          <DocumentNumCell
            page={OperAndDocumPages.inventoryForm}
            documentNum={row.documentNum}
          />
        )
      },
    },
    {
      title: ' Организация',
      accessor: 'merchant',
    },
    {
      title: 'Статус',
      sortable: true,
      accessor: 'status_value',
      renderCell: ({ row }) => {
        const status = row.status

        return (
          <>
            <Badge
              status={INVENTORY_STATUS_BADGE_TYPE[status]}
              label={INVENTORY_STATUSES[status]}
            />
          </>
        )
      },
    },
    {
      title: 'Качество товара',
      sortable: true,
      accessor: 'condition_value',
      renderCell: ({ row }) => {
        const condition = row.condition

        return (
          <>
            <Badge
              status={INVENTORY_CONDITION_BADGE_TYPE[condition]}
              label={INVENTORY_CONDITION[condition]}
            />
          </>
        )
      },
    },
    {
      title: 'Удаление',
      accessor: 'actions',
      align: 'right',
      renderCell: ({ row }: any) => {
        return (
          <div className={styles.actionsBtns}>
            <Button
              onlyIcon
              view={'ghost'}
              size={'s'}
              iconLeft={IconRemove}
              onClick={() => handleConfirmModal(row.id)}
            />
          </div>
        )
      },
    },
  ]
}
