import { useQuery } from 'react-query'
import api from '@shared/api'
import { ApiReqResult } from 'src/interfaces'
import { ICarryerQuby } from 'src/interfaces/transportationTemplates'

import { UseQueryOptions } from 'react-query/types/react/types'

export interface IGetCarryerQubyParams {
  docId: string
}
export interface IGetCarryerQubyResult extends ApiReqResult {
  transportation: ICarryerQuby
}
const useGetCarryerQuby = (
  params: IGetCarryerQubyParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IGetCarryerQubyResult>(
    [`getCarryerQuby`, params],
    async () => {
      const result = await api.get(`/transportation/${params.docId}`)
      return result.data
    },
    options,
  )
}
export default useGetCarryerQuby
