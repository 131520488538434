import api from '@shared/api'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'
import { PartialInventoryRequestResult } from 'src/interfaces'


const completePartInventory = async (inventoryId: string) => {
  const result = await api.post(`/inventory-cell/${inventoryId}/complete`)
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

const useCompletePartInventory = (
  options?: UseMutationOptions<any, PartialInventoryRequestResult, any>,
) => {
  return useMutation(completePartInventory, options)
}

export default useCompletePartInventory
