import React from 'react'

import { IPicking, PICKING_STATUS_BADGE_TYPE, PICKING_STATUSES_DESCRIPTION } from 'src/interfaces'

import { TableColumn } from '@consta/uikit/Table'
import { Badge } from '@consta/uikit/Badge'
import { OperAndDocumPages } from '../../../../pagesType'
import { DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'

export const generateRows = (pickings: IPicking[]) => {
  return pickings.map(picking => ({
    id: picking.id,
    date: picking.create_ts,
    date_value: days(picking.create_ts).format(DATE_TIME_VIEW),
    documentNum: picking.id,
    status: picking.status,
    status_value: PICKING_STATUSES_DESCRIPTION[picking.status],
  }))
}

export const generateColumns = (): TableColumn<any>[] => {

  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{minWidth: '120px'}}/>
      }
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        return <DocumentNumCell page={OperAndDocumPages.pickingForm} documentNum={row.documentNum} />
      }
    },
    {
      title: 'Статус',
      sortable: true,
      accessor: 'status_value',
      renderCell: ({row}) => (
        <Badge
          status={PICKING_STATUS_BADGE_TYPE[row.status]}
          label={PICKING_STATUSES_DESCRIPTION[row.status]}
        />
      )
    },
  ]
}
