import { useMutation } from 'react-query'

import api from '@shared/api'

import { IOrderReqPallet } from 'src/interfaces'

let temp = 0

type debugPlacesType = { placeId: string; orderId: string }[]
const useUpdatePackingPallet = () => {
  return useMutation(
    async (data: { pallet: IOrderReqPallet; palletId?: string, debugPlaces?: debugPlacesType, debugTempPlaces?: debugPlacesType }) => {
      const reqData = {
        pallet: data.pallet,
        debugPlaces: data.debugPlaces,
        debugTempPlaces: data.debugTempPlaces
      }
      const result = data.palletId
        ? await api.post(`/packing/pallet/${data.palletId}`, reqData)
        : await api.post(`/packing/pallet`, reqData)

      if (!result?.data?.success) {
        throw result
      }
      return result.data

      // return {
      //   success: true,
      //   pallet_id: 'mockPallet2',
      //   pallet_num: 1,
      //   sticker: {
      //     content: MOCK_STICKER_6x6,
      //     printer: 'STICKER_6x6',
      //   },
      // }
      // console.log(data)
      // console.log(temp)
      // if (temp === 1) {
      //   temp = temp + 1
      //   throw new Error('error')
      // } else {
      //   temp = temp + 1
      //   return {
      //     success: true,
      //     pallet_id: 'mock',
      //     pallet_num: 1,
      //     sticker: {
      //       content: MOCK_STICKER_6x6,
      //       printer: 'Microsoft Print to PDF',
      //     },
      //   }
      // }
      // console.log(data.palletId)
      // if (data.palletId === 'mockPallet1') {
      //   return {
      //     success: true,
      //     pallet_id: 'mock',
      //     pallet_num: 1,
      //     sticker: {
      //       content: MOCK_STICKER_6x6,
      //       printer: 'Microsoft Print to PDF',
      //     },
      //   }
      // } else {
      //   return {
      //     success: true,
      //     pallet_id: 'mockPallet2',
      //     pallet_num: 2,
      //     sticker: {
      //       content: MOCK_STICKER_6x6,
      //       printer: 'Microsoft Print to PDF',
      //     },
      //   }
      // }
    },
  )
}

export default useUpdatePackingPallet
