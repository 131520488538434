import React, { FC, useCallback, useMemo, useState } from 'react'
import { ServicesTable } from '../../../../../components'
import {
  setAllMaterCalcItemsFromServerAction,
  setAllOperCalcItemsFromServerAction,
  updateFormStateDraftAction,
} from '../../../../../store/actions'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { useMainFormContext } from '../../../../../context/SupplyFormContext'
import { getNewMaterialServices, removeCalcItems, removeServices, updateCalcItems } from './utils'
import { Button } from 'src/components'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import styles from './services-form.module.scss'
import MaterialServicesModal from '../MaterialServicesModal/MaterialServicesModal'


interface ServicesFormProps {

}

const ServicesForm: FC<ServicesFormProps> = (props) => {
  const { id: supplyId } = useParams<{ page: OperAndDocumPagesType, id: string, tabId: string }>()
  const {state, dispatch} = useMainFormContext()
  const {
    allMaterialsServices,
    formStateDraft,
  } = state

  const operationsServices = formStateDraft?.operationsServices || []
  const materialsServices = formStateDraft?.materialsServices || []
  const initialOperationsPreCalcItems = formStateDraft?.initialOperationsPreCalcItems || []
  const operationsInputService = formStateDraft?.operationsInputService || []
  const initialMaterialsPreCalcItems = formStateDraft?.initialMaterialsPreCalcItems || []
  const materialsInputService = formStateDraft?.materialsInputService || []

  const [checkedMaterials, setCheckedMaterials] = useState<string[]>([])
  const [showAddMaterial, setShowAddMaterial] = useState(false)

  const handleRemoveMaterials = () => {
    const newMaterialsServices = removeServices(materialsServices, checkedMaterials)
    dispatch(updateFormStateDraftAction('materialsServices', newMaterialsServices))
    const newMaterialsCalcItems = removeCalcItems(materialsInputService, checkedMaterials)
    dispatch(updateFormStateDraftAction('materialsInputService', newMaterialsCalcItems))
    setCheckedMaterials([])
  }
  const handleAddMaterials = () => {
    setShowAddMaterial(true)
  }
  const handlePutAllMaterials = () => {
    dispatch(setAllMaterCalcItemsFromServerAction())
  }
  const handlePutAllOperations = () => {
    dispatch(setAllOperCalcItemsFromServerAction())
  }

  const operationsExtraHeader = useMemo(() => {
    return (
      <div className={styles.buttons}>
        <Button
          label={'Вставить все'}
          view={'ghost'}
          onClick={handlePutAllOperations}
        />
      </div>
    )
  }, [formStateDraft])

  const packagingExtraHeader = useMemo(() => {
    return (
      <div className={styles.buttons}>
        <Button
          label={'Удалить'}
          view={'ghost'}
          onClick={handleRemoveMaterials}
        />
        <Button
          label={'Добавить'}
          view={'secondary'}
          onClick={handleAddMaterials}
        />
        <Button
          label={'Вставить все'}
          view={'ghost'}
          onClick={handlePutAllMaterials}
        />
      </div>
    )
  }, [formStateDraft, checkedMaterials])

  const handleChangeOperationsInput = useCallback(
    (id: string, value: TextFieldPropValue) => {
      const calcItems = updateCalcItems(operationsInputService, id, value)
      dispatch(updateFormStateDraftAction('operationsInputService', calcItems))
    },
    [operationsInputService]
  );
  const handleChangeMaterialsInput = useCallback(
    (id: string, value: TextFieldPropValue) => {
      const calcItems = updateCalcItems(materialsInputService, id, value)
      dispatch(updateFormStateDraftAction('materialsInputService', calcItems))
    },
    [materialsInputService]
  );

  return (
    <>
      <ServicesTable
        key={'operations'}
        title={'Операции'}
        services={operationsServices}
        initialPreCalcItems={initialOperationsPreCalcItems}
        preCalcItems={operationsInputService}
        extraHeader={operationsExtraHeader}
        handleChangeInput={handleChangeOperationsInput}
      />
      <ServicesTable
        key={'materials'}
        title={'Материалы'}
        withCheckbox={true}
        services={materialsServices}
        initialPreCalcItems={initialMaterialsPreCalcItems}
        preCalcItems={materialsInputService}
        extraHeader={packagingExtraHeader}
        checkedIds={checkedMaterials}
        setCheckedIds={setCheckedMaterials}
        handleChangeInput={handleChangeMaterialsInput}
      />

      {
        showAddMaterial ? (
          <MaterialServicesModal
            hasOverlay={true}
            services={allMaterialsServices}
            selectedServices={materialsServices}
            isOpen={true}
            onClose={() => {
              setShowAddMaterial(false)
            }}
            handleSubmit={(services) => {
              dispatch(updateFormStateDraftAction('materialsServices', services))
              const newMaterialServices = getNewMaterialServices(services, materialsInputService)
              dispatch(updateFormStateDraftAction('materialsInputService', newMaterialServices))
              setShowAddMaterial(false)
            }}
          />
        ) : null
      }
    </>
  )
}

export default ServicesForm
