import React from 'react'
import { ComboboxWrapperWithApi } from '@/components'
import { ICellPlace } from '@/interfaces'
import { CommonFieldProps } from '@shared/types/fields'
import { useGetPlacesList } from '@/hooks'

interface IProps extends CommonFieldProps<ComboboxCellItem> {
  convertHookData?: (data: { cells: ICellPlace[] }) => any
}
export type ComboboxCellItem = {
  label: string
  id: string
  dto: ICellPlace
}

const ComboboxWarehousePlace = (props: IProps) => {
  const {
    label = 'Ячейка',
    placeholder = 'Выберите ячейку',
    convertHookData = (data) => {
      return data.cells.map((el) => ({ label: el.title, id: el.id, dto: el }))
    },
    convertHookParams = (params) => ({
      search: params.search
    }),
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      label={label}
      placeholder={placeholder}
      useGetHookSearch={useGetPlacesList}
      convertHookParams={convertHookParams}
      convertHookData={convertHookData}
      {...restProps}
    />
  )
}
export default ComboboxWarehousePlace
