import React, { useEffect, useMemo, useState } from 'react'
import { useGetMovingList } from 'src/hooks/warehouse/movingSimple'
import { DocumentsListWrapper } from '@pages/OperationsAndDocumentsPage/components'
import { useGetItemsStock } from 'src/hooks'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { Button } from 'src/components'
import { IMoving } from 'src/interfaces'
import MovingModalNew from '../MovingModalNew/MovingModalNew'
import { useFilter } from '../../../hooks/useFilter'

const MovingList = () => {
  const { filterState, setFilterState } = useFilter()
  const [openModal, setOpenModal] = useState(false)
  const [docId, setDocId] = useState('')

  const {
    isFetching: movingLoading,
    data: movingData,
    refetch: refetchData,
  } = useGetMovingList(generateFilterParams(filterState))
  const { refetch: refetchCellStocks, data: stockList } = useGetItemsStock(
    {
      place_ids: [],
    },
    {
      onSuccess: (data) => {
        if (!data.skus) {
          return
        }
      },
    },
  )

  const handleEdit = (doc: IMoving) => {
    setDocId(doc.id)

    setOpenModal(true)
  }

  useEffect(() => {
    refetchData()
  }, [filterState])

  const rows = useMemo(
    () => generateRows(movingData?.documents || []),
    [movingData?.documents],
  )

  const columns = useMemo(() => generateColumns({ handleEdit }), [])
  const filtersConfig = generateFilters()

  return (
    <>
      <DocumentsListWrapper
        title={'Перемещение'}
        rows={rows}
        columns={columns}
        filterState={filterState}
        setFilterState={setFilterState}
        filtersConfig={filtersConfig}
        loading={movingLoading}
        pagination={movingData?.pagination}
        tableExtraHeaderRight={
          <Button
            label={'Добавить'}
            onClick={() => {
              setDocId('')
              setOpenModal(true)
            }}
          />
        }
      />
      {openModal ? (
        <MovingModalNew
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          docId={docId}
          refetch={refetchData}
          skus={stockList?.skus}
        />
      ) : null}
    </>
  )
}
export default MovingList
