import React, { FC, memo, useState } from 'react'

import type { ICollapse } from './types'

import cx from 'classnames'

import { IconArrowDown } from '@consta/icons/IconArrowDown'

import styles from './Collapse.module.scss'

/**
 * Компонент поля с выпадющим контентом
 */
const Collapse: FC<ICollapse> = memo((props) => {
  const {
    wrapperClassName = '',
    labelClassName = '',
    label = null,
    iconNode = <IconArrowDown />,
    leftSideNode = null,
    lvl = 0,
    lvlStep = 12,
    hasDivider = false,
    children,

    isOpen: externalIsOpen,
    setIsOpen: externalSetIsOpen,
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const correctIsOpen = externalIsOpen !== undefined ? externalIsOpen : isOpen
  const correctSetIsOpen = externalSetIsOpen ? externalSetIsOpen : setIsOpen

  const mainStyles = cx(styles.main, hasDivider ? styles.main_underlined : null)

  const bodyStyles = cx(styles.body, correctIsOpen ? null : styles.body_hidden)

  const iconContainerStyles = cx(
    styles.iconContainer,
    correctIsOpen ? styles.iconContainer_open : null,
  )

  return (
    <div
      className={cx(mainStyles, wrapperClassName)}
      style={{ paddingLeft: `${lvl * lvlStep}px` }}
    >
      <section
        className={cx(styles.label, labelClassName)}
        onClick={() => correctSetIsOpen(!correctIsOpen)}
      >
        {!!leftSideNode && (
          <div className={styles.leftSide}>{leftSideNode}</div>
        )}

        <div className={styles.labelLeft}>
          <div className={iconContainerStyles}>
            {iconNode}
          </div>

          {!!label && label}
        </div>
      </section>

      <section className={bodyStyles}>{correctIsOpen ? children : null}</section>
    </div>
  )
})

Collapse.displayName = 'Collapse'

export default Collapse
