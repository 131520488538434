import React, { useState } from 'react'
import { Collapse } from '@consta/uikit/Collapse'
import { InputWrapper } from '@/components'
import styles
  from '../../pages/SupplyPlan/SupplyForm/components/SupplyTabsyPages/DocumentsParamsWrapper/documents-params.module.scss'


interface CommentCollapseProps {
  comment?: string
}

const CommentCollapse = ({comment}: CommentCollapseProps) => {
  const [isCommentOpen, setCommentOpen] = useState<boolean>(false)

  if (!comment) return null
  return (
    <Collapse
      label={'Комментарий от клиента'}
      isOpen={isCommentOpen}
      onClick={() => setCommentOpen(prev => !prev)}
      hoverEffect
      horizontalSpace='s'
    >
      <InputWrapper
        type={'textarea'}
        value={comment}
        id={'comment'}
        label={''}
        className={styles.itemField}
        disabled={true}
      />
    </Collapse>
  )
}
export default CommentCollapse