import { useMutation } from 'react-query'

import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

/*
 http://wms-test.kak2c.ru/warehouse-api/billing/result/generate
 */

interface Result extends ApiReqResult {
  id: string
  n: number
}

export interface ICreateBilling {
  merchant_ids?: string[]
  recreate?: boolean
  year_month: string
}

const useCreateBilling = () => {
  return useMutation(async (data: ICreateBilling) => {
    const path = `/billing/result/generate`
    const result = await api.post(path, data)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}

export default useCreateBilling
