import api from '@shared/api'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'
import { ApiReqResult } from '../../interfaces'


interface IData {
  warehouse_place_barcode: string
  with_errors?: boolean
}

interface IParams {
  doc_id: string
  data: IData
}

const pickingComplete = async ({ doc_id, data }: IParams) => {
  const result = await api.post(`/picking/${doc_id}/complete`, data)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

const usePickingComplete = (options?: UseMutationOptions<any,ApiReqResult,any>) => {
  return useMutation(pickingComplete, options)
}

export default usePickingComplete
