import { ISupply, NewServiceDocument, Service, ServiceDocument } from 'src/interfaces'
import { ISupplyFormDataState } from '../interfaces'


export const SET_SUPPLY_RAW = 'SET_SUPPLY_RAW'
export const setSupplyRawAction = (supplyRaw: ISupply | null) => {
  return {
    type: SET_SUPPLY_RAW,
    payload: supplyRaw,
  }
}

export const SET_SERVICES_DOCUMENTS = 'SET_SERVICES_DOCUMENTS'
export const setServicesDocumentsAction = (documents: ServiceDocument[] | NewServiceDocument[]) => {
  return {
    type: SET_SERVICES_DOCUMENTS,
    payload: documents,
  }
}


export const SET_ALL_SERVICES_MATERIAL = 'SET_ALL_SERVICES_MATERIAL'
export const setAllServicesMaterialAction = (services: Service[]) => {
  return {
    type: SET_ALL_SERVICES_MATERIAL,
    payload: services,
  }
}

export const SET_ALL_OPERATIONS_CALC_ITEMS_FROM_SERVER_VALUES = 'SET_ALL_OPERATIONS_CALC_ITEMS_FROM_SERVER_VALUES'
export const setAllOperCalcItemsFromServerAction = () => {
  return {
    type: SET_ALL_OPERATIONS_CALC_ITEMS_FROM_SERVER_VALUES,
    payload: null,
  }
}

export const SET_ALL_MATERIALS_CALC_ITEMS_FROM_SERVER_VALUES = 'SET_ALL_MATERIALS_CALC_ITEMS_FROM_SERVER_VALUES'
export const setAllMaterCalcItemsFromServerAction = () => {
  return {
    type: SET_ALL_MATERIALS_CALC_ITEMS_FROM_SERVER_VALUES,
    payload: null,
  }
}


export const SET_FORM_STATE_DRAFT = 'SET_FORM_STATE_DRAFT'
export const setFormStateDraftAction = (formState: ISupplyFormDataState) => {
  return {
    type: SET_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const UPDATE_FORM_STATE_RAW = 'UPDATE_FORM_STATE_RAW'
export const updateFormStateRawAction = (key: keyof ISupplyFormDataState, value: any) => {
  return {
    type: UPDATE_FORM_STATE_RAW,
    payload: { key, value },
  }
}
export const UPDATE_FORM_STATE_DRAFT = 'UPDATE_FORM_STATE_DRAFT'
export const updateFormStateDraftAction = (key: keyof ISupplyFormDataState, value: any) => {
  return {
    type: UPDATE_FORM_STATE_DRAFT,
    payload: { key, value },
  }
}

export const SET_FORM_STATE_RAW = 'SET_FORM_STATE_RAW'
export const setFormStateRawAction = (formState: ISupplyFormDataState) => {
  return {
    type: SET_FORM_STATE_RAW,
    payload: formState,
  }
}

// todo попытаться вынести общие экшены и редюсеры
// export const updateSupplyFormDraftAction =
//   (key: keyof ISupplyFormDataState, value: any) => updateFormStateDraftAction<ISupplyFormDataState>(key, value)