import { IOrder, ITableColumn, ITableRow, ORDER_STATUSES_DESCRIPTION, OrderStatusEnum } from 'src/interfaces'
import React from 'react'
import { CheckboxCell, DateCell, FileTooltip } from 'src/components'
import days from 'dayjs'
import { getFullAddress } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { DocumentNumCell, OrderStatusBadge } from '@pages/OperationsAndDocumentsPage/components'
import { OperAndDocumPages } from '../../../../../pagesType'

export const generateOrdersColumns = (
  checkedFiles: string[],
  setCheckedFiles: React.Dispatch<React.SetStateAction<string[]>>,
  rows: ITableRow[],
): ITableColumn[] => {
  return [
    {
      key: 'actions',
      title: '',
      title_txt: '',
      isAction: true,
      withHeaderAction: true,
      gridCellWidth: '40px',
      renderCell: ({ row, isHeader, index }: any) => (
        <CheckboxCell
          isHeaderCheckbox={isHeader}
          fieldId={row.id}
          fieldsIds={rows.map(row => row.id)}
          checkedItemsIds={checkedFiles}
          setCheckedItemsIds={setCheckedFiles}
        />
      ),
    },
    {
      key: 'sortingNumber',
      title: '№ корзины',
      title_txt: '№ корзины',
      sortable: true,
      gridCellWidth: '150px',
    },
    {
      key: 'date',
      title: 'Дата',
      title_txt: 'Дата',
      gridCellWidth: '150px',
      renderCell: ({ row }: any) => {
        return <DateCell date={row.date} />
      },
    },
    {
      key: 'id',
      title: 'Номер заказа',
      title_txt: 'Номер заказа',
      // gridCellWidth: '1fr',
      renderCell: ({ row }: any) => {
        return (
          <DocumentNumCell
            documentNum={row.id}
            page={OperAndDocumPages.ordersShippedForm}
          />
        )
      },
    },
    {
      key: 'statusValue',
      title: 'Статус',
      title_txt: 'Статус',
      gridCellWidth: '2fr',
      renderCell: ({ row }) => {
        return (
          <OrderStatusBadge
            cancelRequested={Boolean(row.cancelRequested)}
            status={row.status as OrderStatusEnum}
          />
        )
      },
    },
    {
      key: 'files',
      title: 'Файлы',
      title_txt: 'Файлы',
      // gridCellWidth: '150px',
      renderCell: ({ row }) => {
        return (
          row.files?.length ? <FileTooltip count={row.files?.length} /> : null
        )
      }
    },
    {
      key: 'shippingDatePlan',
      title: 'Дата отгрузки',
      title_txt: 'Дата отгрузки',
      // gridCellWidth: '150px',
    },
    {
      key: 'itemsQuantity',
      title: 'Кол-во товаров',
      title_txt: 'Кол-во товаров',
      gridCellWidth: '80px',
    },
    {
      key: 'deliveryService',
      title: 'Способ доставки',
      title_txt: 'Способ доставки',
      // gridCellWidth: '180px',
    },
    {
      key: 'address',
      title: 'Адрес доставки',
      title_txt: 'Адрес доставки',
      gridCellWidth: '2fr',
    },
    {
      key: 'merchant',
      title: 'Организация',
      title_txt: 'Организация',
      // gridCellWidth: '1fr',
    },
  ]
}

export const generateOrdersRows = (orders: IOrder[]): ITableRow[] => {
  return orders.map((order) => {
    return {
      id: order.id,
      date: order.date,
      status: order.status,
      statusValue: ORDER_STATUSES_DESCRIPTION[order.status || ''],
      sortingNumber: order.sorting_number,
      files: order.files,
      shippingDatePlan:
        order.delivery.shipping_date_plan &&
        days(order.delivery.shipping_date_plan).format(DATE_TIME_VIEW),
      address:
        order.delivery.address ?
          // getFullAddress(order.delivery.address, countryList),
          getFullAddress(order.delivery.address, []) : '',
      deliveryService: order.delivery.title || '',
      itemsQuantity: order.items.reduce((sum, item) => sum + item.quantity, 0),
      merchant: order.merchant?.title || '',
      cancelRequested: order.cancel_requested,
    }
  })
}
