import { useQuery } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

/*
 * Получение файлов для печати
 * wiki:
 * http://wms-test.kak2c.ru/warehouse-api/inventory/{inventory_id}/inf3
 */

interface IParams {
  inventoryId: string
}

interface IResultData {
  content: string
  printer: string // base64
}
interface IResult extends ApiReqResult, IResultData {}

const getDocsForPrint = async ({ inventoryId }: IParams) => {
  const result = await api.get(`/inventory/${inventoryId}/inf3`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

const useFetchInventoryDocsForPrint = () => ({
  fetch: getDocsForPrint,
})

const useGetInventoryDocsForPrint = (
  params: IParams,
  callback?: (data: IResultData) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IResult>(
    [`getDocsForPrint`, params],
    () => getDocsForPrint(params),
    {
      enabled: false,
      keepPreviousData: false,
      onSuccess: ({ success, ...data }) => {
        if (success) {
          callback?.(data)
        } else {
          error?.()
        }
      },
      onError: () => error?.(),
      onSettled: () => settled?.(),
    },
  )
}
export { useFetchInventoryDocsForPrint, useGetInventoryDocsForPrint }
