import { IReturnPlaceFormDataState } from '../interfaces'
import { IRefundPlace } from 'src/interfaces/refund/refund'

export const SET_RETURN_PLACE_RAW = 'SET_RETURN_PLACE_RAW'
export const setReturnPlaceRawAction = (documentRaw: IRefundPlace | null) => {
  return {
    type: SET_RETURN_PLACE_RAW,
    payload: documentRaw,
  }
}

export const SET_RETURN_PLACE_FORM_STATE_DRAFT = 'SET_RETURN_PLACE_FORM_STATE_DRAFT'
export const setReturnPlaceFormDraftAction = (formState: IReturnPlaceFormDataState) => {
  return {
    type: SET_RETURN_PLACE_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_RETURN_PLACE_FORM_STATE_RAW = 'SET_RETURN_PLACE_FORM_STATE_RAW'
export const setReturnPlaceFormRawAction = (formState: IReturnPlaceFormDataState) => {
  return {
    type: SET_RETURN_PLACE_FORM_STATE_RAW,
    payload: formState,
  }
}

