import React, { useMemo } from 'react'
import { FormTabs, generateTabs } from '../../../../../helpers/documentTabs'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { Comment, HistoryChanges, RelativeDocs } from '../../../../../components/CommonTabsPages'
import { updateQubyFormStateDraftAction } from '@pages/OperationsAndDocumentsPage/store/actions'
import { TabsContentWrapper } from '../../../../../components'
import { useQubyFormContext } from '@pages/OperationsAndDocumentsPage/context/CarryerQubyContext'
import { DocumentsParamsFields } from '..'
import { DocumentTypeEnum } from '@/interfaces'

interface CarryerQubyFormContentProps {
  loading?: boolean
}
const CarryerQubyFormContent = () => {
  const {
    page,
    id: docId,
    tabId,
  } = useParams<{ page: OperAndDocumPagesType; id: string; tabId: string }>()

  const qubyTabsWithDescription = useMemo(
    () =>
      generateTabs([
        FormTabs.documentsParams,
        FormTabs.relativeDocs,
        FormTabs.comments,
        FormTabs.historyChanges,
      ]),
    [page],
  )

  return (
    <TabsContentWrapper tabs={qubyTabsWithDescription}>
      <TabPage tabId={tabId} documentId={docId} />
    </TabsContentWrapper>
  )
}
interface ITabContent {
  tabId: string
  documentId: string
}

const TabPage = React.memo(({ documentId, tabId }: ITabContent) => {
  const {
    state: { documentRaw, formStateDraft },
    dispatch,
  } = useQubyFormContext()

  switch (tabId) {
    case FormTabs.documentsParams:
      return <DocumentsParamsFields />
    case FormTabs.relativeDocs:
      return <RelativeDocs documentId={documentId} documentType={DocumentTypeEnum.CARRYER_QUBY}/>
    case FormTabs.comments:
      return (
        <Comment
          stateCommentValue={formStateDraft?.comment || ''}
          handleChangeComment={(value) =>
            dispatch(updateQubyFormStateDraftAction('comment', value))
          }
        />
      )
    case FormTabs.historyChanges:
      return <HistoryChanges documentUUID={documentRaw?.uuid} />
  }
  return null
})
export default CarryerQubyFormContent
