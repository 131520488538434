import { IPrintOptions, usePrint } from './print'
import { useGetPhysicalSetSticker } from '@/hooks'
import { usePreviewBase64Pdf } from '@shared/hooks'


/** Печать стикера комплекта */

type ParamsType = {
  order_id: string,
  place_id: string
}
interface printResult {
  printPhysicalSetSticker: (params: ParamsType, isPreview?: boolean, options?: IPrintOptions) => Promise<void>
}

export const usePhysicalSetSticker = (): printResult => {
  const { printSticker } = usePrint()
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const getPhysicalSetSticker = useGetPhysicalSetSticker()

  const printPhysicalSetSticker = async (params: ParamsType, isPreview?: boolean, options: IPrintOptions = {}) => {
    return await getPhysicalSetSticker
      .fetch(params)
      .then((result) => {
        if (!result) { return }
        const { sticker, stickerExists } = result

        if (isPreview) {
          previewBase64Pdf(sticker.content, 'Стикер комплекта')
          return
        }

        printSticker(sticker.printer, sticker.content, {
          ...options,
          stickerExists,
        })
      })
      .catch(() => {

      })
  }

  return {
    printPhysicalSetSticker,
  }
}