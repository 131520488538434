import { REVERT_FORM_STATE_TO_RAW, UPDATE_FORM_STATE_DRAFT, UPDATE_FORM_STATE_RAW } from '../actions'

import { IReducerAction } from 'src/interfaces'
import { cloneDeep } from '@shared/helpers'
import { IReturnFormState } from '../interfaces'

import {
  SET_RETURN_FORM_STATE_DRAFT,
  SET_RETURN_FORM_STATE_RAW,
  SET_RETURN_LIST,
  SET_RETURN_RAW,
} from '../actions/return'

export const returnInitialState: IReturnFormState = {
  documentRaw: null,
  formStateRaw: null,
  formStateDraft: null,
  list: null,
}

export const returnReducer = (
  state: IReturnFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    case SET_RETURN_RAW:
      return { ...state, documentRaw: payload }

    case SET_RETURN_FORM_STATE_DRAFT:
      return { ...state, formStateDraft: payload }

    case SET_RETURN_FORM_STATE_RAW:
      return { ...state, formStateRaw: payload }

    case SET_RETURN_LIST:
      return { ...state, list: payload }

    case UPDATE_FORM_STATE_DRAFT:
      return {
        ...state,
        formStateDraft: {
          ...(state.formStateDraft || {}),
          [payload.key]: payload.value,
        },
      }

    case UPDATE_FORM_STATE_RAW:
      return {
        ...state,
        formStateRaw: {
          ...(state.formStateRaw || {}),
          [payload.key]: payload.value,
        },
      }

    case REVERT_FORM_STATE_TO_RAW:
      return {
        ...state,
        formStateDraft: cloneDeep(state.formStateRaw),
      }

    default:
      return state
  }
}
