import { useQuery } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, StickerWithCopies } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'

interface IParams {
  transportPlaceId: string
}
const getTransportPlaceStickersV2 = async (params: IParams): Promise<IResult> => {
  const result = await api.get(`/packing-v2/transport-place/${params.transportPlaceId}/sticker`)
  if (!result?.data?.success) {
    throw result
  }
  return result.data
}

interface IResult extends ApiReqResult {
  files_for_print: StickerWithCopies[]
}


const useFetchTransportPlaceStickersV2 = () => ({
  fetch: getTransportPlaceStickersV2
})

const useGetTransportPlaceStickersV2 = (
  params: IParams,
  options?: UseQueryOptions<IResult>,
) => {
  return useQuery<IResult>(
    [`getPackagings`, params],
    () => getTransportPlaceStickersV2(params),
      options
  )
}

export { useGetTransportPlaceStickersV2, useFetchTransportPlaceStickersV2 }
