import { IPrintOptions, usePrint } from './print'
import { useGetStickerByPlace } from '@/hooks'
import { usePreviewBase64Pdf } from '@shared/hooks'

/** Печать стикера места */

interface printResult {
  printPlaceStickerById: (placeId: string, orderId: string, isPreview?: boolean, options?: IPrintOptions) => Promise<void>
}

export const usePrintPlaceSticker = (): printResult => {
  const { printSticker } = usePrint()
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const getPlaceSticker = useGetStickerByPlace()

  const printPlaceStickerById = async (placeId: string, orderId: string, isPreview?: boolean, options?: IPrintOptions) => {
    await getPlaceSticker
      .fetch({ placeId, orderId })
      .then((result) => {
        if (result.success && result.sticker) {
          const { sticker, stickerExists } = result

          if (isPreview) {
            previewBase64Pdf(sticker.content, 'Стикер места')
            return
          }

          printSticker(sticker.printer, sticker.content, { copies: 1, stickerExists, ...(options || {})})
        }
      })
      .catch(() => {

      })
  }

  return {
    printPlaceStickerById,
  }
}