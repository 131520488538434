import React from 'react'
import { ComboboxWrapperWithApi } from '@/components'
import { IDeliveryContract } from '@/interfaces'
import { CommonFieldProps } from '@shared/types/fields'
import useGetDeliveryContractsList from '../../../../../hooks/warehouse/delivery/useGetDeliveryContractsList'

interface IProps extends CommonFieldProps  {
  merchantId?: string
  convertHookData?: (data: { delivery_contracts: IDeliveryContract[] }) => any
  wrapperClassName?: string
}

const ComboboxDeliveryContracts = (props: IProps) => {
  const {
    label = 'Контракт доставки',
    placeholder = 'Выберите контракт',
    merchantId,
    convertHookData = (data) => {
      return data.delivery_contracts.map((el) => ({
        label: el.title,
        id: el.id,
        dto: el,
      }))
    },
    convertHookParams = (params) => {
      return {
        merchant_ids: merchantId ? [merchantId] : undefined,
        search: params.search
      }
    },
    wrapperClassName,
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      label={label}
      placeholder={placeholder}
      useGetHookSearch={useGetDeliveryContractsList}
      convertHookData={convertHookData}
      convertHookParams={convertHookParams}
      wrapperClassName={wrapperClassName}
      {...restProps}
    />
  )
}
export default ComboboxDeliveryContracts
