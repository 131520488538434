import api from '@shared/api'

import { ApiReqResult, IPaginationFilter, PaginationResult, Service, ServiceDocument } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { useQuery } from 'react-query'
import { convertObjectToURLParams } from '@shared/helpers'

export interface IGetBillingListResponse {
  documents: ServiceDocument[]
  services: Service[]
}

export const useGetBillingList = () => ({
  fetch: async () => {
    const result = await api.get('/billing/result')
    if (!result.data?.success) {
      throw result.data
    }
    return {
      documents: result.data?.documents || [],
      services: result.data?.services || [],
    } as IGetBillingListResponse
  }
})


interface IParams extends IPaginationFilter {
  merchant_ids?: string[]
}
interface IResult extends ApiReqResult, PaginationResult, IGetBillingListResponse {

}

export const useGetQueryBillingList = (
  params: IParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IResult>(
    [`getQueryBillingList`, params],
    async () => {
      const searchParams = convertObjectToURLParams(params)
      const result = await api.get(`/billing/result${searchParams}`)
      return result.data
    },
    {
      keepPreviousData: true,
      ...options
    },
  )
}
