import { useQuery } from 'react-query'
import api from '@shared/api'

import { MOCK_STICKER_6x6, provideMock } from 'src/mocks'

import { ApiReqResult } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'

interface IParams {
  supplyId: string
}

const getAcceptanceActMX1 = async (params: IParams) => {
  const result = await api.get(`/receiving/supply/${params.supplyId}/mx1`)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface IActResult extends ApiReqResult {
  content: string[]
  printer: string
}

const useGetActOfAcceptanceMX1 = (
  params: IParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IActResult>(
    [`getAcceptanceActMX1`, params],
    provideMock<IParams, IActResult>(getAcceptanceActMX1, params, {
      key: 'supplyId',
      value: 'mock',
      result: {
        success: true,
        content: [MOCK_STICKER_6x6],
        printer: 'sticker_6x6',
      },
    }),
    options
  )
}

export default useGetActOfAcceptanceMX1
