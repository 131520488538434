import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import { useNotifications } from '../../lib/useNotifications'

import styles from './notifications.module.scss'

import { Informer } from '@consta/uikit/Informer'

import { NOTIFICATIONS_TIMEOUT } from '@shared/const/common'

import { Button } from '@/components'
import { IconClose } from '@consta/icons/IconClose'
import { INotification } from '../../config/schema'
import { IconShape } from '@consta/icons/IconShape'
import { Flex } from '@shared/ui/Flex'
import { DetailsModal } from '@shared/providers/NotificationProvider/ui/DetailsModal/DetailsModal'
import { useToggle } from '@shared/hooks'
import isMobile from 'ismobilejs'

type NotificationProps = INotification & {
  id: string
}

const Notification = (props: NotificationProps) => {
  const { id, type, content, options, allErrors } = props

  const {
    value: detailModalVisible,
    turnOn: setVisibleDetailModal,
    turnOff: closeVisibleDetailModal,
  } = useToggle()

  const context = useNotifications()
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const isMobileAny = isMobile().any;

  const clickableNotif = options?.clickable && !isMobileAny
  const closableNotif = options?.withClose && !isMobileAny

  const closeNotif = () => context?.clear(id)

  useEffect(() => {
    /* Дефолтный таймер закрытия */
    context?.playSound(type || '', options)
    if (!options?.persistent && timeoutRef) {
      timeoutRef.current = setTimeout(() => {
        closeNotif()
      }, options?.timeout ?? NOTIFICATIONS_TIMEOUT)
    }
  }, [timeoutRef, options?.persistent])

  const handleClickOnWrapper = () => {
    /* Сбрасываем таймер, чтобы было время на открытие деталки */
    if (!clickableNotif) return
    clearTimeout(timeoutRef.current)
  }

  const handleClickModalBtn = () => {
    /* Открываем модалку сдетализацией, если есть список ошибок */
    if (!allErrors?.length) {
      alert('Нет данных об ошибке')
      return
    }
    setVisibleDetailModal()
  }
  const handleClickCloseModalBtn = () => {
    /* Закрываем модалку и нотиф */
    closeNotif()
    closeVisibleDetailModal()
  }

  return (
    <>
      <div
        className={cx(
          styles.notifWrapper,
          { [styles.clickable]: clickableNotif },
          { [styles.alert]: type === 'alert' },
        )}
        onClick={clickableNotif ? handleClickOnWrapper : undefined}
      >
        <Informer
          className={cx(styles.wrap, options?.allWidth && styles.allWidth)}
          title={undefined}
          status={type}
          label={content}
          icon={options?.icon}
        />
        <Flex
          gap={'m'}
          className={cx(styles.btnsWrapper)}
        >
          {
            clickableNotif ? (
              <Button
                onlyIcon
                size={'s'}
                view={'secondary'}
                theme={'white'}
                iconLeft={IconShape}
                className={styles.openDetailModalBtn}
                onClick={options?.onClick || handleClickModalBtn}
              />
            ) : null
          }
          {
            closableNotif ? (
              <Button
                onlyIcon
                size={'s'}
                view={'secondary'}
                theme={'white'}
                iconLeft={IconClose}
                className={styles.allWidthNotifCloseBtn}
                onClick={options?.onClose || closeNotif}
              />
            ) : null
          }
        </Flex>
      </div>
      {
        allErrors && detailModalVisible
          ? <DetailsModal allErrors={allErrors} onClose={handleClickCloseModalBtn}/>
          : null
      }
    </>
  )
}

export default Notification
