import { useQuery, UseQueryOptions } from 'react-query'

import api from '@shared/api'
import { ApiReqResult, IMoving, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'

export interface IParams {
  search?: string | null
}

interface GetMovingListResult extends ApiReqResult, PaginationResult {
  documents: IMoving[]
}
//http://wms-test.kak2c.ru/warehouse-api/moving
const useGetMovingList = (params: IParams, options?: UseQueryOptions<any>) => {
  return useQuery<GetMovingListResult>(
    [`getMovingList`, params],
    async () => {
      const paramsStr = convertObjectToURLParams(params)

      const result = await api.get(`/moving${paramsStr}`)
      return result.data as GetMovingListResult
    },
    options,
  )
}
export default useGetMovingList
