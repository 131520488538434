import { ConditionEnum, IAcceptanceSkuObj, IDefectType, ISupplyItem, ITableColumn, ITableRow } from '@/interfaces'
import React from 'react'
import styles from '../MassAcceptTable.module.scss'
import { Button } from '@consta/uikit/Button'
import { ComboboxWrapper, ImageCell, InputWrapper } from '@/components'
import { getBatchesOptions, getDefectOptions } from '../../../../utils'


interface IQuantityCell {
  id: string,
  skuQuantity: number,
  quantityValue?: number,
  handleSetPrintQuantity?: (itemId: string, value?: number) => void
}

const QuantityCell = (props: IQuantityCell) => {
  const { id, skuQuantity, quantityValue, handleSetPrintQuantity } = props

  const updateValue = (newValue: string | null) => {
    handleSetPrintQuantity?.(id, newValue ? Number(newValue) : undefined)
  }
  return (
    <div className={styles.printInputWrapper}>
      <InputWrapper
        isInteger
        id={'quantity'}
        handleChange={updateValue}
        value={quantityValue?.toString()}
        className={styles.countInput}
      />
      <Button
        // onlyIcon
        // iconLeft={IconBackward}
        label={'Вставить'}
        view={'ghost'}
        onClick={() => updateValue(String(skuQuantity || 0))}
        size={'m'}
      />
    </div>
  )
}

export const generateTableColumns = (condition: ConditionEnum): ITableColumn[] => {
  let columns = [
    {
      key: 'img',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '90px',
      renderCell: ({ row }) => {
        return <ImageCell image={row.img as string} />
      },
    },
    {
      key: 'name',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: '2fr',
    },
    {
      key: 'quantity',
      title: <p className={styles.thQuantity}>Кол-во</p>,
      title_txt: 'Кол-во',
      gridCellWidth: '100px',
      align: 'right',
    },
    {
      key: 'batch',
      title: 'Партия',
      title_txt: 'Партия',
      gridCellWidth: '250px',
      align: 'center',
    },
    {
      key: 'defect',
      title: 'Дефект',
      title_txt: 'Дефект',
      gridCellWidth: '250px',
      align: 'center',
      hidden: condition === ConditionEnum.GOOD
    },
    {
      key: 'action',
      title: '',
      title_txt: '',
      gridCellWidth: '250px',
      isAction: true,
    },
  ] as ITableColumn[]

  return columns
}


export const generateTableRows = (args: {
  condition: ConditionEnum
  originalSkusObj: IAcceptanceSkuObj,
  supplyItems: ISupplyItem[],
  handleSetQuantity: (itemId: string, value?: number) => void,
  handleSetItemBatch: (skuId: string, batchId: string | undefined) => void,
  handleCreateItemBatch: (skuId: string) => void,
  handleSetDefect: (skuId: string, defect: string | undefined) => void,
  quantityItemsValues: Record<string, number | undefined>
  batchItemsValues: Record<string, string | undefined>
  defectsItemsValues: Record<string, string | undefined>
  defectTypes: IDefectType[]
}): ITableRow[] => {
  const {
    condition,
    originalSkusObj,
    supplyItems,
    handleSetQuantity,
    handleSetItemBatch,
    handleCreateItemBatch,
    handleSetDefect,
    quantityItemsValues,
    batchItemsValues,
    defectsItemsValues,
    defectTypes
  } = args
  return supplyItems.map((supplyItem) => {
    const originalSku = originalSkusObj[supplyItem.sku_id]
    const defectOptions = getDefectOptions(defectTypes)
    const id = supplyItem.id
    const skuId = supplyItem.sku_id
    const quantity = supplyItem.quantity
    const batchesOptions = getBatchesOptions(originalSku?.batches || [])
    const batchValue = batchesOptions?.find(option => option.id === batchItemsValues[skuId])
    const defectValue = defectOptions.find(option => option.id === defectsItemsValues[skuId])
    return {
      id: id,
      skuId: skuId,
      img: originalSku?.image,
      article: originalSku?.article,
      name: (
        <>
          <p>{originalSku?.title}</p>
          <span className={styles.lightTxt}>{originalSku?.article}</span>
        </>
      ),
      quantity: supplyItem.quantity,
      batch: originalSku?.batch_accounting ? (
        <ComboboxWrapper
          withCreateItem
          id='batch'
          label=''
          /* @ts-ignore */
          items={batchesOptions}
          /* @ts-ignore */
          value={batchValue || null}
          placeholder='Выберите партию'
          searchFunction={(item, searchValue) => {
            const value = searchValue?.toUpperCase()
            const label = item?.labelTxt?.toUpperCase()
            return label?.includes(value)
          }}
          onCreate={() => handleCreateItemBatch(skuId)}
          onChange={({value}) => {
            handleSetItemBatch(skuId, value?.id ? String(value.id) : undefined)
          }}
          className={styles.batchSelect}
        />
      ) : undefined,
      defect: condition === ConditionEnum.DEFECT ? (
        <ComboboxWrapper
          id='defectItem'
          label=''
          items={defectOptions}
          value={defectValue || null}
          placeholder='Выберите дефект'
          onChange={({value}) => {
            handleSetDefect(skuId, value?.id ? String(value.id) : undefined)
          }}
        />
      ) : undefined,
      action: (
        <div className={styles.actionCell}>
          <QuantityCell
            id={skuId}
            skuQuantity={Number(quantity)}
            quantityValue={quantityItemsValues[skuId]}
            handleSetPrintQuantity={handleSetQuantity}
          />
        </div>
      ),
    }
  })
}
