import { ISupplyReceiptItem } from '@/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

export const getTareItemBySerialNumberVal = (
  serialNumber: TextFieldPropValue,
  tareItems: ISupplyReceiptItem[],
  selectedBatchId?: string,
): ISupplyReceiptItem | void => {
  if (!serialNumber) {
    return
  }

  return (
    tareItems
      /* филтьруем по выбранной партии */
      .filter((tareItem) =>
        selectedBatchId ? selectedBatchId === tareItem.sku_batch_id : true,
      )
      /* находим нужный серийник */
      .find((tareItem) =>
        checkTareItemBySerialNumberCorrect(serialNumber, tareItem),
      )
  )
}

export const checkTareItemBySerialNumberCorrect = (
  serialNumber: TextFieldPropValue,
  tareItem: ISupplyReceiptItem,
) => {
  return tareItem.serial_numbers.find((serial) => serial.value === serialNumber)
}
export const getTareItemsBySkuId = (
  tareItems: ISupplyReceiptItem[],
  skuId: string,
) => {
  return tareItems.filter((tareItem) => tareItem.sku_id === skuId)
}
export const getTareItemsByTrustBarcode = (
  tareItems: ISupplyReceiptItem[],
  trustBarcode: string,
) => {
  return tareItems.filter((tareItem) => tareItem.trust_acceptance_box_barcode === trustBarcode)
}

export const getTareItemsByBatch = (
  tareItems: ISupplyReceiptItem[],
  batchId: string,
) => {
  return tareItems.filter((tareItem) => tareItem.sku_batch_id === batchId)
}

export const checkTareItemForMomentalDeleting = (
  tareItem: ISupplyReceiptItem,
) => {
  /** Проверяет добавленный товар в тару на возможность удаления срзау */
  return (
    !tareItem.sku_batch_id &&
    !tareItem.serial_numbers?.length &&
    tareItem.quantity === 1
  )
}
