import React from 'react'
import { CommonFieldProps } from '@shared/types/fields'
import ComboboxDocumentsSearchApi from '../../../Document/ui/ComboboxDocumentsSearchApi/ComboboxDocumentsSearchApi'
import { DocumentTypeEnum } from '@/interfaces'

interface IProps extends CommonFieldProps {
}

const ComboboxTransportPlaces = (props: IProps) => {
  const {
    label = 'Транспортное место',
    placeholder = 'Выберите транспортное место',
    ...restProps
  } = props

  return (
    <ComboboxDocumentsSearchApi
      label={label}
      placeholder={placeholder}
      documentType={DocumentTypeEnum.TRANSPORT_PLACE}
      {...restProps}
    />
  )
}
export default ComboboxTransportPlaces
