import React from 'react'
import { ITransportaions } from 'src/interfaces/transportationTemplates'
import styles from '../CarryerQubyList.module.scss'
import { TableColumn } from '@consta/uikit/Table'
import { Button, DateCell } from 'src/components'
import { DocumentNumCell } from '@pages/OperationsAndDocumentsPage/components'
import { OperAndDocumPages } from '@pages/OperationsAndDocumentsPage'
import { Badge } from '@consta/uikit/Badge'
import { QUBY_STATUS_BADGE_TYPE, QUBY_STATUSES } from '@/interfaces/statuses'
import { IconEdit } from '@consta/icons/IconEdit'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'

export const generateRows = (document: ITransportaions[]) => {
  return document.map((doc) => ({
    id: doc.id,
    date: doc.create_ts,
    date_value: days(doc.create_ts).format(DATE_TIME_VIEW),
    documentNum: doc.id,
    merchant: doc.merchant,
    status: doc.status,
    status_value: QUBY_STATUSES[doc.status || ''],
    comment: doc.comment || '',
    merchant_title: doc.merchant?.title || '-',
  }))
}

export const generateColumns = ({
  handleEdit,
}: {
  handleEdit: (id: string) => void
}): TableColumn<any>[] => {
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{ minWidth: '120px' }} />
      },
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        return (
          <DocumentNumCell
            page={OperAndDocumPages.carryerQubyForm}
            documentNum={row.documentNum}
          />
        )
      },
    },
    {
      title: ' Организация',
      accessor: 'merchant_title',
    },
    {
      title: 'Статус',
      sortable: true,
      accessor: 'status_value',
      renderCell: ({row}) => {
        const status = row.status
        return (
          <>
            <Badge
              status={QUBY_STATUS_BADGE_TYPE[status]}
              label={QUBY_STATUSES[status]}
            />
          </>
        )
      },
    },
    {
      title: ' Комментарий',
      accessor: 'comment',
    },
    {
      title: 'Редактирование',
      accessor: 'actions',
      align: 'right',
      renderCell: ({row}: any) => {
        return (
          <div className={styles.actionsBtns}>
            <Button
              onlyIcon
              view={'ghost'}
              size={'s'}
              iconLeft={IconEdit}
              onClick={() => handleEdit(row.id)}
            />
          </div>
        )
      },
    },
  ]
}
