const logError = (str: string) =>
  console.log('%c%s', 'color: red; font-size: 1rem;', str)

type GetLogErrorTextType = {
  url: string,
  method: string,
  status: string,
  requestBody?: string,
}
const getLogErrorText = (args: GetLogErrorTextType) => {
  const {
    url,
    method,
    status,
    requestBody,
  } = args
  return `
      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      API REQ ERROR LOG:
      Request URL: ${url}
      Request Method: ${method?.toUpperCase()}
      Status Code: ${status}
      ${requestBody ? 'Request body: ' + requestBody : ''}
  `
}

export const apiResErrorLog = (res: any) => {
  const { config = {}, status, statusText, data = {} } = res
  logError(
    getLogErrorText({
      url: `${config?.baseURL}${config?.url}`,
      method: config?.method,
      status: `${status}${statusText ? ' (' + statusText + ')' : ''}`,
      requestBody: `${config.data ? 'Request body: ' + config.data : ''}`,
    })
  )
  if (data?.errors) {
    data?.errors.forEach((error: any, i: number) => {
      logError(
        `${data?.errors.length > 1 ? i + ') ' : ''}${error.code}: ${
          error.message
        }`,
      )
    })
  } else {
    logError(`Undefined error`)
  }
  logError(`~~~~~~~~~~~~~~~~~~~~~~~~~~~~`)
}

export const apiErrorLog = (error: any) => {
  const { config = {}, response = {} } = error
  logError(`
    ${getLogErrorText({
      url: `${config?.baseURL}${config?.url}`,
      method: config?.method,
      status: `${response?.status}${
        response?.statusText ? ' (' + response?.statusText + ')' : ''
      }`,
      requestBody: `${config.data ? 'Request body: ' + config.data : ''}`,
    })}
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  `)
}
