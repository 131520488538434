import React, { useEffect, useReducer } from 'react'
import { useLocation } from 'react-router-dom'
import qz from 'qz-tray'
import qzSign from '../config/sign'

import { useNotifications } from '@shared/providers/NotificationProvider'

import { IconWarning } from '@consta/icons/IconWarning'

import { getPathWithoutLastPart } from '@shared/helpers'

import { QZ_HOST } from '@shared/const/common'
import { ROUTES_WITHOUT_QZ } from '../config/routes'
import { PrinterContext } from '../config/context'
import { printerReducer } from '../config/reducer'
import { IPrintOptions } from '../config/schema'


const qzConfig = qz.configs.create(null)
qzConfig.reconfigure({ copies: 1 })

//Mb move from context to simple hook

export const PrinterProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const { pathname } = useLocation()
  const notification = useNotifications()

  const [state, dispatch] = useReducer(printerReducer, {
    connected: false,
  })

  const displayError = (err: any) => {
    console.error(err)
    notification?.show('alert', err?.message ?? err)
  }

  const findPrinters = () =>
    qz.printers
      .find()
      .then((data: string[]) => {
        console.log('Available printers:')

        for (var i = 0; i < data.length; i++) {
          console.log(data[i])
        }
      })
      .catch(displayError)

  const findVersion = () => qz.api.getVersion().catch(displayError)

  const connect = async () => {
    const config = { host: QZ_HOST /*usingSecure: true*/ }
    if (!qz.websocket.isActive()) {
      return await qz.websocket
        .connect(config)
        .then(async () => {
          await findPrinters()
          await findVersion()
          dispatch({ type: 'connected' })
          notification?.clear('qzStartError')
        })
        .catch(() => {
          /*
          *  Не записываем в локал, чтобы появлялось при каждом запуске
          *  Если записать в локал, то будет проблема с отслеживанием ошибки на складе
          */
          const alreadyShown = sessionStorage.getItem('qzStartError')
          if (alreadyShown) {
            console.error('Ошибка доступа к сервису печати QZ Tray. Обратитесь к администратору.')
            return
          }
          notification?.show(
            'alert',
            'Ошибка доступа к сервису печати QZ Tray. Обратитесь к администратору.',
            {
              id: 'qzStartError',
              persistent: true,
              allWidth: true,
              icon: IconWarning,
              withClose: true,
              onClose: () => {
                sessionStorage.setItem('qzStartError', '1')
                notification?.clear('qzStartError')
              },
            },
          )
        })
    } else {
      return Promise.resolve()
    }
  }

  useEffect(() => {
    ;(async () => {
      const routeNeedQzTray = !ROUTES_WITHOUT_QZ.includes(
        getPathWithoutLastPart(pathname),
      )
      if (routeNeedQzTray && !state.connected) {
        await qzSign()
        await connect()

        window.printSticker = print //TODO better
      }
    })()
  }, [pathname])


  const showError = (err: any, options?: IPrintOptions) => {
    if (options?.onError) {
      options.onError(err)
      return
    }
    displayError(err)
  }

  const print = async (base64Data: string, options?: IPrintOptions) => {
    try {
      if (!qz.websocket.isActive()) {
        await connect()
      }

      const printData = [
        {
          type: 'pixel',
          format: options?.qzOptions?.format || 'pdf',
          flavor: options?.qzOptions?.flavor || 'base64',
          data: base64Data,
        },
      ]

      await findPrinters()

      const currentConfig = options?.qzOptions
        ? qz.configs?.create(options?.qzOptions?.printer, {
          ...options.qzOptions,
          copies: options.qzOptions?.copies ?? 1,
        })
        : qzConfig

      // saveBase64AsPdf({
      //   content: base64Data,
      //   fileName: 'sticker.pdf',
      // })

      return await qz
        .print(currentConfig, printData)
        .then((result: any) => {
          if (options?.onSuccess) {
            options.onSuccess()
          } else {
            notification?.show('success', 'Стикер успешно отправлен на печать')
          }
        })
        .catch((err: any) => {
          console.log('printer error')
          console.dir(err)
          showError(err, options)
        })
        .finally(() => {
          if (options?.onFinally) {
            options.onFinally()
          }
        })
    } catch (err: any) {
      showError(err, options)
    }
  }

  const setPrinter = (printerName: string) => {
    qzConfig.setPrinter(printerName)
  }

  return (
    <PrinterContext.Provider
      value={{
        state,
        connect,
        print,
        setPrinter,
      }}
    >
      {children}
    </PrinterContext.Provider>
  )
}

// function endConnection() {
//     if (qz.websocket.isActive()) {
//         qz.websocket.disconnect().then(function() {
//             updateState('Inactive', 'default');
//         }).catch(handleConnectionError);
//     } else {
//         displayMessage('No active connection with QZ exists.', 'alert-warning');
//     }
// }
