import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, InputWrapper } from 'src/components'
import { DocumentsParams } from '../../../../../components'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { generatePickingColumns, generatePickingRows } from './utils'
import { usePickingFormContext } from '../../../../../context/PickingFormContext'

import { PICKING_STATUSES_DESCRIPTION } from 'src/interfaces/statuses/picking'
import { ICellPlace, ISku, IWarehouseCell } from 'src/interfaces'
import {
  IGetSkuItemsParams,
  IWarehouseCellsParams,
  IWarehouseCellsResult,
  useGetItems,
  useGetWarehouseCells,
} from 'src/hooks'

import { IReportParam, ITemplateParam, TemplateParamType } from 'src/interfaces/report'
import MultiselectParam from '@pages/Reports/components/ReportParams/MultiselectParam/MultiselectParam'
import InputParam from '@pages/Reports/components/ReportParams/InputParam/InputParam'
import styles from './documents-params.module.scss'
import usePickingFullComplete from '@/hooks/picking/usePickingFullComplete'

interface SupplyFormContentProps {
  refetch: () => void
}
type FilterDataFnType = <T>(prevItem: T[], newItems: T[], key: string) => T[]
type SetStateFnType = <T>(
  data: T[],
  setState: Dispatch<SetStateAction<T[]>>,
  key?: string,
) => void
const paramsSelect = [
  { id: 0, title: 'Номенклатура', type: 'SKUS' },
  { id: 1, title: 'Ячейки', type: 'CELLS' },
]

const DocumentsParamsFields = (props: SupplyFormContentProps) => {
  const { refetch } = props

  const { page, id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()
  const [paramValues, setParamValues] = useState<IReportParam[]>([])
  const { state, dispatch } = usePickingFormContext()
  const [skus, setSkus] = useState<ISku[]>([])
  const [cells, setCells] = useState<ICellPlace[]>([])
  const [skuParams, setSkuParams] = useState<IGetSkuItemsParams>({})
  const [cellParams, setCellParams] = useState<IWarehouseCellsParams>({})
  const searchParams = { size: 100 }

  const {
    isLoading: pickingFullCompleteLoading,
    mutateAsync: pickingFullComplete
  } = usePickingFullComplete()

  const filterSkusItems: FilterDataFnType = (prevItems, newItems, field) => {
    return [
      ...prevItems.filter(
        (pItem) =>
          !newItems.find(
            (nItem) =>
              nItem[field as keyof object] === pItem[field as keyof object],
          ),
      ),
      ...newItems,
    ]
  }

  const setNewData: SetStateFnType = (data, setStateFn, key = 'id') => {
    setStateFn((prev) => filterSkusItems(prev, data, key))
  }

  const { refetch: skusRefetch, isFetching: isSkusLoading } = useGetItems(
    { ...skuParams, ...searchParams },
    (result) => {
      setNewData<ISku>(result, setSkus)
    },
  )
  const { refetch: cellsRefetch, isFetching: isCellsLoading } =
    useGetWarehouseCells(
      { ...cellParams, ...searchParams },
      {
        onSuccess: (data: IWarehouseCellsResult) => {
          if (!data.success) {
            return
          }
          setNewData<ICellPlace>(data.cells, setCells)
        },
      },
    )

  useEffect(() => {
    skusRefetch()
  }, [skuParams])
  useEffect(() => {
    cellsRefetch()
  }, [cellParams])

  const setParamValue = (paramValue: IReportParam) => {
    const idx = paramValues.findIndex((v) => v.id === paramValue.id)

    const arr =
      idx < 0
        ? paramValues
        : [...paramValues.slice(0, idx), ...paramValues.slice(idx + 1)]
    setParamValues(
      !paramValue.value && !paramValue.values?.length
        ? arr
        : [...arr, paramValue],
    )
  }

  const orderRows = useMemo(
    () => generatePickingRows(state.documentRaw, paramValues),
    [state.documentRaw, paramValues],
  )
  const orderColumns = useMemo(() => generatePickingColumns(), [])

  const getParamComponent = (type: TemplateParamType) => {
    switch (type) {
      case TemplateParamType.skus:
      case TemplateParamType.cells:
        return MultiselectParam

      default:
        return InputParam
    }
  }

  return (
    <DocumentsParams
      rows={orderRows}
      columns={orderColumns}
      tableTitle={''}
      tableExtraHeader={
        <Button
          label={'Подобрать полностью'}
          onClick={async () => {
            await pickingFullComplete({doc_id: documentId})
            refetch()
          }}
          loading={pickingFullCompleteLoading}
        />
      }
    >
      <div className={styles.fieldsWrapper}>
        <InputWrapper
          className={styles.fieldType25}
          id={'state'}
          label={'Статус'}
          value={
            PICKING_STATUSES_DESCRIPTION[
              state.documentRaw?.picking.status || ''
            ]
          }
          disabled
        />
        <div className={styles.WrapperSelect}>
          {paramsSelect.map((template) => {
            const ParamComponent = getParamComponent(
              template.type as TemplateParamType,
            )
            const val = paramValues.find((v) => v.id === template.id) || {
              id: template.id,
            }
            let specificProps = {}
            if (template.type === TemplateParamType.skus) {
              specificProps = {
                options: state.documentRaw?.skus.map((s) => ({
                  value: s.id,
                  title: s.title,
                  article: s.article,
                  barcodes: s.barcodes.map((b) => b.barcode),
                })),
                searchFunction: (item: any, searchValue: string) => {
                  const value = searchValue.toUpperCase()
                  return (
                    item.title?.toUpperCase()?.includes(value) ||
                    item.barcodes?.includes(searchValue) ||
                    item.value?.toUpperCase()?.includes(searchValue) ||
                    item.article?.toUpperCase()?.includes(searchValue)
                  )
                },
                loading: isSkusLoading,
                withApiSearch: true,
                handleApiSearch: (value: string | null) => {
                  setSkuParams({ search: value || undefined })
                },
              }
            }
            if (template.type === TemplateParamType.cells) {
              specificProps = {
                options:
                  state.documentRaw?.cells.map((c: IWarehouseCell) => ({
                    value: c.barcode,
                    title: c.title,
                  })) || [],
                loading: isCellsLoading,
                searchFunction: (item: any, searchValue: string) => {
                  const value = searchValue.toUpperCase()
                  return (
                    item.title?.toUpperCase()?.includes(value) ||
                    item.value?.toUpperCase()?.includes(value)
                  )
                },
                withApiSearch: true,
                handleApiSearch: (value: string | null) =>
                  setCellParams({ search: value || undefined }),
              }
            }
            return (
              <ParamComponent
                key={template.id}
                template={template as ITemplateParam}
                paramValue={val}
                handleChange={setParamValue}
                {...specificProps}
              />
            )
          })}
        </div>
      </div>
    </DocumentsParams>
  )
}

export default DocumentsParamsFields
