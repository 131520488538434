import api from '@shared/api'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'
import { ApiReqResult, IError } from 'src/interfaces'

interface IData {
  items: IDataItems[]
  warehouse_comment?: string
}
interface IDataItems {
  quantity: number
  sku_batch_id?: string
  sku_id: string
  to_cell_barcode: string
  from_cell_barcode: string
}

interface IResult extends ApiReqResult {
  results: {
    errors?: IError[]
    id: string
    success: boolean
  }[]
}

interface IParams {
  docId: string
  data: IData
}
// http://wms-test.kak2c.ru/warehouse-api/moving/{doc_id}
const addResultMoving = async ({ docId, data }: IParams) => {
  const result = await api.post(`/moving${docId ? `/${docId}` : ''}`, data)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

const useCreateAddMoving = (
  options?: UseMutationOptions<any, IResult, any>,
) => {
  return useMutation(addResultMoving, options)
}

export default useCreateAddMoving
