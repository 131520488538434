import api from '@shared/api'
import { ISticker } from 'src/interfaces'

interface IInventoryExcelParams {
  indentoryId: string
}

// http://wms-test.kak2c.ru/warehouse-api/inventory/{inventory_id}/inf3/xls

const getInventoryExcel = async ({ indentoryId }: IInventoryExcelParams) => {
  const result = await api.get(`/inventory/${indentoryId}/inf3/xls`)

  if (!result.data?.success) {
    throw result.data
  }

  return {
    content: result.data?.content,
    printer: result.data?.printer || '',
  } as ISticker
}

const useGetInventoryExcel = () => ({
  fetch: (params: IInventoryExcelParams) => getInventoryExcel(params),
})

export default useGetInventoryExcel
