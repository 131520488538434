import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import styles from './SelectBatchModal.module.scss'

import { Button } from '@consta/uikit/Button'

import { InputWithDateMask, InputWrapper, ItemPreview, Modal, SelectList } from 'src/components'

import { useCreateBatch } from 'src/hooks'

import { IBatch, ISelectListOption, ModalProps, useCreateBatchResult } from 'src/interfaces'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { DATE_FORMAT, DATE_FORMAT_VIEW } from '@shared/const/date'

interface ISelectBatchModal extends ModalProps {
  onlyCreate?: boolean
  expdate?: boolean
  item: {
    id: string,
    batches: IBatch[],
    external_id?: string
    image?: string
    title?: string
  } | null
  onSubmit: (batch: IBatch, isNewBatch?: boolean) => void
  defaultSelectedBatch?: IBatch
}

const SelectBatchModal = (props: ISelectBatchModal) => {
  const {
    onlyCreate,
    isOpen,
    onSubmit,
    expdate,
    item,
    onClose,
    defaultSelectedBatch
  } = props

  const createBatchMutation = useCreateBatch({ itemId: item?.id ?? '' })

  const [selectedBatch, setSelectedBatch] = useState<ISelectListOption | null>(
    null,
  )
  const [batchNum, setBatchNum] = useState<TextFieldPropValue>(null)
  const [batchDate, setBatchDate] = useState<TextFieldPropValue>(null)

  const [batchesOptions, setBatchesOptions] = useState<ISelectListOption[]>([])

  useEffect(() => {
    if (batchDate) {
      setBatchNum(`${item?.external_id||'-'} ${batchDate}`)
    }
  }, [batchDate])

  useEffect(() => {
    if (item) {
      const options =
        item?.batches?.map((b) => {
          return {
            label: (
              <span className={styles.batchSelect}>
                {dayjs(b.exp_date).format(DATE_FORMAT_VIEW)} <span>{b.num}</span>
              </span>
            ),
            id: b.id,
            value: b.id,
          }
        }) ?? []
      setBatchesOptions(options)
      if (!options.length) {
        return
      }
      /** Дефолтно выбранный батч */
      const defaultBatchOption = options.find(
        (option) => option.id === defaultSelectedBatch?.id,
      )
      if (defaultBatchOption) {
        setSelectedBatch(defaultBatchOption)
        return
      }
      setSelectedBatch(options[0])
    }
  }, [])

  const handleChoiceBatch = () => {
    const findBatch = item?.batches?.find(
      (b: IBatch) => b.id === selectedBatch?.value,
    )

    if (findBatch) {
      onSubmit(findBatch)
    }
  }

  const handleAddNewBatch = async () => {
    const newBatch = {
      num: batchNum ? String(batchNum) : undefined,
      exp_date: batchDate
        ? dayjs(batchDate, DATE_FORMAT_VIEW).format(DATE_FORMAT)
        : undefined,
    }

    createBatchMutation
      .mutateAsync({
        batch: newBatch,
      })
      .then((result: useCreateBatchResult) => {
        onSubmit({ id: result.id, ...newBatch }, true)
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.selectBatch}
      withClose
      onClose={onClose}
    >
      <div className={styles.top}>
        {(item.image || item.title) && (
          <ItemPreview
            image={item.image}
            title={item.title}
          />
        )}
      </div>

      <div className={styles.body}>
        {
          !onlyCreate ? (
            <div className={styles.left}>
              <h3>Выберите партию из списка</h3>
              <SelectList
                options={batchesOptions}
                value={selectedBatch}
                onChange={(value) => setSelectedBatch(value)}
                className={styles.select}
              />
              <Button
                label="Выбрать"
                className={styles.btn}
                disabled={!selectedBatch}
                onClick={handleChoiceBatch}
              />
            </div>
          ) : null
        }
        <div className={styles.right}>
          <h3>Новая партия</h3>
          <div className={styles.newBatch}>
            {expdate ? (
              <InputWithDateMask
                value={batchDate}
                id={'dateTo'}
                handleChange={(value: TextFieldPropValue) =>
                  setBatchDate(value)
                }
                className={styles.itemInput}
                size="l"
                placeholder={DATE_FORMAT_VIEW}
                rightSide={IconCalendar}
              />
            ) : null}

            <InputWrapper
              value={batchNum}
              id={'batchNum'}
              handleChange={(value: TextFieldPropValue) =>
                setBatchNum(String(value))
              }
              className={styles.itemInput}
              placeholder={'№ партии'}
              size="l"
            />
          </div>
          <Button
            label="Добавить партию"
            className={styles.btn}
            disabled={expdate ? !batchNum || !batchDate : !batchNum}
            onClick={handleAddNewBatch}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SelectBatchModal
