export class LocalStorage {
  readonly key: string

  constructor(keyword: string) {
    this.key = keyword
  }

  get state() {
    return localStorage.getItem(this.key) || '{}'
  }

  getData(key: string) {
    return this.state ? JSON.parse(this.state)?.[key] ?? undefined : undefined
  }

  setData(key: string, data: any) {
    localStorage.setItem(
      this.key,
      JSON.stringify({ ...JSON.parse(this.state), [key]: data }),
    )
  }

  clearData(key: string) {
    const state = JSON.parse(this.state)
    if (state.hasOwnProperty(key)) {
      delete state[key]
      localStorage.setItem(this.key, JSON.stringify(state))
    }
  }

  clear = () => localStorage.removeItem(this.key)
}
