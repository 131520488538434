import { IMerchant } from '../acceptance'
import { IOrder } from '../order'
import { IUserData } from '../user'
import { ValueOf } from '@shared/helpers'

export interface IOrderBatch {
  activated?: boolean
  comment?: string
  create_ts?: string
  picking_printed_at?: string
  date: string
  executor?: IUserData
  external_id?: string
  id: string
  merchant?: IMerchant
  orders: IOrder[]
  status: string
  update_ts?: string
  uuid?: string
  warehouse_comment?: string
}

export const BatchStatusesEnum = {
  new: 'NEW',
  picking: 'PICKING',
  picking_error: 'PICKING_ERROR',
  picked: 'PICKED',
  packing: 'PACKING',
  packed: 'PACKED',
  readyToShip: 'READY_TO_SHIP',
  shipped: 'SHIPPED',
  canceled: 'CANCELED',
} as const
export type BatchStatusesEnum = ValueOf<typeof BatchStatusesEnum>
