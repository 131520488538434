import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { FormTabs, generateTabs } from '../../../../../helpers/documentTabs'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { Comment, HistoryChanges, RelativeDocs } from '../../../../../components/CommonTabsPages'
import { updateFormStateDraftAction } from '../../../../../store/actions'
import { TabsContentWrapper } from '../../../../../components'
import { DocumentsParamsFields } from '..'
import { useBillingContext } from '../../../../../context/BillingContext'
import AdditionalParams from '../AdditionalParams/AdditionalParams'
import { DocumentTypeEnum } from '@/interfaces'

interface BillingFormContentProps {
  isLoading?: boolean
  refetch: () => void
}

const BillingFormContent = (props: BillingFormContentProps) => {
  const {
    isLoading,
    refetch
  } = props
  const {
    page,
    id: supplyId,
    tabId,
  } = useParams<{ page: OperAndDocumPagesType; id: string; tabId: string }>()

  const supplyTabsWithDescription = useMemo(
    () =>
      generateTabs([
        FormTabs.documentsParams,
        FormTabs.additionalParams,
        FormTabs.relativeDocs,
        FormTabs.comments,
        FormTabs.historyChanges,
      ]),
    [page],
  )

  return (
    <TabsContentWrapper tabs={supplyTabsWithDescription}>
      <TabPage
        tabId={tabId}
        documentId={supplyId}
        isLoading={isLoading}
        refetch={refetch}
      />
    </TabsContentWrapper>
  )
}

interface ITabContent {
  tabId: string
  documentId: string
  isLoading?: boolean
  refetch: () => void
}

const TabPage = React.memo(({ documentId, tabId, isLoading, refetch }: ITabContent) => {
  const {
    state: { documentRaw, formStateDraft },
    dispatch,
  } = useBillingContext()

  switch (tabId) {
    case FormTabs.documentsParams:
      return <DocumentsParamsFields isLoading={isLoading} refetch={refetch} />
    case FormTabs.additionalParams:
      return <AdditionalParams />
    case FormTabs.relativeDocs:
      return <RelativeDocs documentId={documentId} documentType={DocumentTypeEnum.BILLING}/>
    case FormTabs.comments:
      return (
        <Comment
          stateCommentValue={formStateDraft?.comment || ''}
          handleChangeComment={(value) =>
            dispatch(updateFormStateDraftAction('comment', value))
          }
        />
      )
    case FormTabs.historyChanges:
      return <HistoryChanges documentUUID={documentRaw?.uuid} />
  }
  return null
})

export default BillingFormContent
