import { IReplaceFormDataState } from '../interfaces/replace'
import { GetReplaceByIdResult } from 'src/hooks'

export const SET_REPLACE_RAW = 'SET_REPLACE_RAW'
export const setReplaceRawAction = (documentRaw: GetReplaceByIdResult | null) => {
  return {
    type: SET_REPLACE_RAW,
    payload: documentRaw,
  }
}

export const SET_REPLACE_FORM_STATE_DRAFT = 'SET_REPLACE_FORM_STATE_DRAFT'
export const setReplaceFormDraftAction = (formState: IReplaceFormDataState) => {
  return {
    type: SET_REPLACE_FORM_STATE_DRAFT,
    payload: formState,
  }
}

export const SET_REPLACE_FORM_STATE_RAW = 'SET_REPLACE_FORM_STATE_RAW'
export const setReplaceFormRawAction = (formState: IReplaceFormDataState) => {
  return {
    type: SET_REPLACE_FORM_STATE_RAW,
    payload: formState,
  }
}
