import { IShipment } from 'src/interfaces'
import { IShipmentData, IShipmentFormDataState } from '../interfaces/shipment'

export const SET_SHIPMENT_RAW = 'SET_SHIPMENT_RAW'
export const setShipmentRawAction = (documentRaw: IShipment | null) => {
  return {
    type: SET_SHIPMENT_RAW,
    payload: documentRaw,
  }
}

export const SET_SHIPMENT_FORM_STATE_DRAFT = 'SET_SHIPMENT_FORM_STATE_DRAFT'
export const setShipmentFormDraftAction = (formState: IShipmentFormDataState) => {
  return {
    type: SET_SHIPMENT_FORM_STATE_DRAFT,
    payload: formState,
  }
}

export const SET_SHIPMENT_FORM_STATE_RAW = 'SET_SHIPMENT_FORM_STATE_RAW'
export const setShipmentFormRawAction = (formState: IShipmentFormDataState) => {
  return {
    type: SET_SHIPMENT_FORM_STATE_RAW,
    payload: formState,
  }
}

export const SET_SHIPMENT_LIST = 'SET_SHIPMENT_LIST'
export const setShipmentList = (list: IShipment[]) => {
  return {
    type: SET_SHIPMENT_LIST,
    payload: list,
  }
}

export const SET_SHIPMENT_DATA = 'SET_SHIPMENT_DATA'
export const setShipmentData = (data: IShipmentData) => {
  return {
    type: SET_SHIPMENT_DATA,
    payload: data,
  }
}
