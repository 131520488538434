import { IGetPackingListResponse } from '@/hooks/packing/useGetPackingList'

export const combineDataToFrontFormat = (data: IGetPackingListResponse) => {
    data.pallets.forEach((pallet) => {
      pallet.orders = (
        pallet.places
          .map((placeId) =>
            data.places.find((place) => place.id === placeId)
          )
          .map((place) =>
            data.orders.find((order) => order.id === place?.order_id)
          )
      )
    })
    return data
  }
