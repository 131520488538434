
export enum soundNameEnum {
  PACKING_ITEM_ADDED_TO_PLACE = 'PACKING_ITEM_ADDED_TO_PLACE',
  PACKING_WRONG_ITEM = 'PACKING_WRONG_ITEM',
  PACKING_SURPLUS_ITEM = 'PACKING_SURPLUS_ITEM',
  PACKING_PLACE_POSTED = 'PACKING_PLACE_POSTED',
  PACKING_COMPLETED = 'PACKING_COMPLETED',
  PACKING_KIT_ASSEMBLE = 'PACKING_KIT_ASSEMBLE',
  PACKING_WEIGHT_ITEM = 'PACKING_WEIGHT_ITEM',
  RECEIVING_SURPLUS_ITEM = 'RECEIVING_SURPLUS_ITEM',
  RECEIVING_WEIGHT_ITEM = 'RECEIVING_WEIGHT_ITEM',
  PACKING_PLACE_WEIGHT = 'PACKING_PLACE_WEIGHT',
  OTHER_ERROR = 'OTHER_ERROR',
}