import { FieldRow, FieldType } from '@widgets/GeneratorFields'
import { placingSelectOptions } from '@/interfaces/statuses'
import { IMerchant } from 'src/interfaces'

export const generateFilters = (
  inputSearchFilterKey: string,
  merchants: IMerchant[],
): FieldRow[] => [
  {
    hidden: true,
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: inputSearchFilterKey,
          viewDescription: 'Поиск',
        },
      },
    ],
  },
  {
    fields: [
      {
        type: 'combobox',
        fieldProps: {
          id: 'statuses',
          label: 'Статус',
          isMultiple: true,
          items: placingSelectOptions,
          viewDescription: 'Статус',
        },
      },
      {
        type: FieldType.MERCHANTS,
        fieldProps: {
          id: 'merchant',
          isMultiple: true,
          label: 'Организация',
          viewDescription: 'Организация',
        },
      },
    ],
  },
]
