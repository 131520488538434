import React from 'react'

import { IAcceptanceSku, ICellPlace, ITableColumn, ITableRow } from 'src/interfaces'
import { IReplaceItem } from 'src/interfaces/replace'

import { NomenclatureCell } from '../../../../../components'
import { getBatchNameValue } from '@pages/OperationsAndDocumentsPage/helpers/common'

export const generateReplaceColumns = (): ITableColumn[] => {
  return [
    {
      key: 'id',
      title: 'Ячейка',
      title_txt: 'Ячейка',
    },
    {
      key: 'skuId',
      title: 'Номенклатура старая',
      title_txt: 'Номенклатура старая',
      renderCell: ({ row }) => {
        return (
          <NomenclatureCell title={row.oldSkuTitle} itemId={`${row.skuId}`} />
        )
      },
    },
    {
      key: 'skuBatchId',
      title: 'Партия старая',
      title_txt: 'Партия старая',
    },
    {
      key: 'merchantOld',
      title: 'Организация для старой номенклатуры',
      title_txt: 'Организация для старой номенклатуры',
    },
    {
      key: 'newSkuId',
      title: 'Номенклатура новая',
      title_txt: 'Номенклатура новая',
      renderCell: ({ row }) => {
        return (
          <NomenclatureCell
            title={row.newSkuTitle}
            itemId={`${row.newSkuId}`}
          />
        )
      },
    },
    {
      key: 'newSkuBatchId',
      title: 'Партия новая',
      title_txt: 'Партия новая',
    },
    {
      key: 'merchantNew',
      title: 'Организация для новой номенклатуры',
      title_txt: 'Организация для новой номенклатуры',
    },
    {
      key: 'quantity',
      title: 'Количество',
      title_txt: 'Количество',
    },
  ]
}

export const generateReplaceRows = (
  replaces: IReplaceItem[],
  skus: IAcceptanceSku[],
  cells: ICellPlace[],
): ITableRow[] => {
  return replaces.map((item) => {
    const cell = cells.find((el) => el.barcode === item.cell_barcode)
    const oldSkuName = skus?.find((el) => el.id === item.sku_id)
    const merchantOld = oldSkuName?.merchant?.title
    const newSkuName = skus?.find((el) => el.id === item.new_sku_id)
    const merchantNew = newSkuName?.merchant?.title
    let skuBatch = null
    let newSkuBatch = null
    if (oldSkuName?.batch_accounting) {
      skuBatch = getBatchNameValue(
        oldSkuName.batches.find((el) => el.id === item.sku_batch_id),
      )
    }
    if (newSkuName?.batch_accounting) {
      newSkuBatch = getBatchNameValue(
        newSkuName.batches.find((el) => el.id === item.new_sku_batch_id),
      )
    }

    return {
      id: cell.title || '-',
      newSkuBatchId: item.new_sku_batch_id ? newSkuBatch : '-',
      skuBatchId: item.sku_batch_id ? skuBatch : '-',
      newSkuId: item.new_sku_id || '-',
      skuId: item.sku_id || '-',
      quantity: item.quantity || '-',
      oldSkuTitle: oldSkuName?.title,
      newSkuTitle: newSkuName?.title,
      merchantOld: merchantOld || '-',
      merchantNew: merchantNew || '-',
    }
  })
}
