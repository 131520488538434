import React, { useEffect, useState } from 'react'
import { useCreateUpdateCarrierCompany, useDeleteCarrierCompany, useGetCarriersCompaniesList } from 'src/hooks'
import { DocumentsListWrapper } from '../../../components'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { generateFilterParams } from '../../../helpers/filters'
import { Button } from 'src/components'
import CreateCarrierCompanyModal from '@/components/widgets/CreateCarrierCompanyModal'
import { ICarrierCompany } from '@/interfaces'
import { useFilter } from '../../../hooks/useFilter'


const CompaniesList = () => {

  const { filterState, setFilterState } = useFilter()

  const [modalCompanyFormVisible, setModalCompanyFormVisible] = useState<boolean>(false)
  const [companyForEdit, setCompanyForEdit] = useState<ICarrierCompany | null>(null)

  const { mutateAsync: createUpdateCompany } = useCreateUpdateCarrierCompany()
  const { mutateAsync: deleteCompany } = useDeleteCarrierCompany()

  const {
    isFetching: companiesLoading,
    data: companiesData,
    refetch: refetchCompanies,
  } = useGetCarriersCompaniesList(generateFilterParams(filterState))

  useEffect(() => {
    if (!companiesLoading)
    refetchCompanies()
  }, [filterState])

  const handleEdit = (company: ICarrierCompany) => {
    setCompanyForEdit(company);
    setModalCompanyFormVisible(true)
  }
  const handleDelete = (companyId: string) => {
    deleteCompany({ companyId })
      .then((data) => {
        refetchCompanies()
      })
      .catch(() => {})
  }

  const rows = generateRows(companiesData?.companies || [])
  const columns = generateColumns({handleEdit, handleDelete})
  const filtersConfig = generateFilters()

  return (
    <>
      <DocumentsListWrapper
        title={'Компании перевозчиков'}
        rows={rows}
        columns={columns}
        filterState={filterState}
        setFilterState={setFilterState}
        filtersConfig={filtersConfig}
        loading={companiesLoading}
        tableExtraHeaderRight={
          <Button
            label={'Добавить'}
            onClick={() => {
              setCompanyForEdit(null)
              setModalCompanyFormVisible(true)
            }}
          />
        }
      />

      {
        modalCompanyFormVisible ? (
          <CreateCarrierCompanyModal
            isOpen={true}
            company={companyForEdit}
            onSubmit={({ legal_address_full, ...restData }) => {
              createUpdateCompany({
                companyId: restData.id,
                data: {
                  ...restData,
                  legal_address: {
                    full_address: legal_address_full,
                  }
                }})
                .then(async () => {
                  setModalCompanyFormVisible(false);
                  refetchCompanies();
                })
                .catch(() => {})
            }}
            onClose={() => setModalCompanyFormVisible(false)}
          />
        ) : null
      }
    </>
  )
}

export default CompaniesList
