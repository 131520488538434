import React, { useEffect, useMemo, useState } from 'react'

import styles from './item-removing-modal.module.scss'
import { InputWrapper, ItemPreview, Modal, SelectList } from '@/components'

import { IAcceptanceSku, IBatch, ISelectListOption, ISupplyReceiptItem, ModalProps } from '@/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { Button } from '@consta/uikit/Button'
import days from 'dayjs'
import {
  checkTareItemBySerialNumberCorrect,
  checkTareItemForMomentalDeleting,
  getTareItemBySerialNumberVal,
  getTareItemsByBatch,
  getTareItemsBySkuId,
  getTareItemsByTrustBarcode,
} from './utils'
import { Loader } from '@consta/uikit/Loader'
import { DATE_FORMAT_VIEW } from '@shared/const/date'

interface ItemRemovingProps extends ModalProps {
  itemBarcode?: string

  /** Добавленные товары в тару (не смог проверить как обыгать это без интерфеса) */
  isTrustBox?: boolean
  removingItemOriginal?: IAcceptanceSku
  openTareItems: ISupplyReceiptItem[]

  onSubmit: (data: { removingQuantity: number; tareItemId: string }) => void
}

interface ISelectListOptionWithQuantity extends ISelectListOption {
  quantity?: number
}


const ACCEPTANCE_TYPE: { [key: string] : string | undefined } = {
  PIECE: 'Поштучно',
  BOX: 'По коробам',
  PALLET: 'По паллетам'
}

const ItemRemoving = (props: ItemRemovingProps) => {
  const {
    isTrustBox,
    isOpen,
    onClose,
    onSubmit,
    itemBarcode = '',

    removingItemOriginal,
    openTareItems = [],
  } = props

  const [serial, setSerial] = useState<TextFieldPropValue>('')
  const [quantity, setQuantity] = useState<TextFieldPropValue>('')
  const [countError, setCountError] = useState<string>('')
  const [serialError, setSerialError] = useState<string>('')

  const [selectedItem, setSelectedItem] =
    useState<ISelectListOptionWithQuantity | null>(null)
  const [itemsOptions, setItemsOptions] = useState<
    ISelectListOptionWithQuantity[]
  >([])

  const firstRemovingTareItem = openTareItems[0]

  /**
   *  Для удаления по серийным номерам проверяем
   *  наличие задания на сканирование серийных номеров
   */
  const itemHasSerialNumbers: boolean =
    Boolean(removingItemOriginal?.needScanSerialCodes?.length) &&
    Boolean(removingItemOriginal?.scan_serial_numbers?.length)

  useEffect(() => {
    /** Задаем дефолтно количество при выборе партии */
    setQuantity('1')
  }, [selectedItem])

  useEffect(() => {
    /**
     * Обработывает сценарий, когда у добавленного товара
     * нет партии, серийников и колчество = 1
     * Без выбора требуется сразу удалять товар
     */
    const tareItems = isTrustBox
      ? getTareItemsByTrustBarcode(openTareItems, itemBarcode)
      : getTareItemsBySkuId(openTareItems, removingItemOriginal?.id)

    if (tareItems.length === 1) {
      const tareItem = tareItems[0]
      const canBeDelete = checkTareItemForMomentalDeleting(tareItem)
      if (canBeDelete) {
        onSubmit({
          removingQuantity: tareItem.quantity,
          tareItemId: tareItem.id,
        })
      }
    }
  }, [])

  useEffect(() => {
    /**
     * Формируем и записываем список партий товара,
     * которые присутствуют в открытой таре
     */

    /** Фильтруем данные для поля выбора партий */
    let itemBatches: IBatch[] = removingItemOriginal?.batches.filter(
      (batch) =>
        batch.id && getTareItemsByBatch(openTareItems, batch.id).length,
    )
    setItemsOptions(
      openTareItems.map((item) => {
        /**
         *  Получаем кол-во товара в партии
         *  (нужно для поля запроса кол-ва удаляемого товара и валидации)
         */

        const correctBatch = itemBatches?.find(batch => batch.id === item.sku_batch_id)
        const date = correctBatch?.exp_date
          ? days(correctBatch.exp_date).format(DATE_FORMAT_VIEW)
          : ''

        return {
          id: item.id,
          value: item.id,
          quantity: item.quantity || 0,
          label: (
            <div className={styles.selectItem}>
              <div>
                <p>Тип</p>
                {item.acceptance_type ? ACCEPTANCE_TYPE[item.acceptance_type] : ''}
              </div>
              <div className={styles.quantityInfo}>
                <div>
                  <p>Кол-во</p>
                  {item.quantity}
                </div>
                {item.multiplicity ? (
                  <>
                    <div>
                      <p>Кратность</p>
                      {item.multiplicity}
                    </div>
                    <div>
                      <p>Итого</p>
                      {item.quantity * item.multiplicity}
                    </div>
                  </>
                ) : null}
              </div>
              <div>
                {
                  correctBatch ? (
                    <>
                      <p>Партия</p>
                      <p className={styles.name}>{correctBatch?.num}</p>
                      {date ? <p className={styles.date}>{date}</p> : null}
                    </>
                  ) : null
                }
              </div>
            </div>
          ),
        }
      }),
    )
  }, [openTareItems])

  useEffect(() => {
    /** Валидация по количеству удаляемого товара */
    const numberQuantity = Number(quantity)
    const maxQuantityNum =
      selectedItem?.quantity || firstRemovingTareItem?.quantity

    if (maxQuantityNum < numberQuantity) {
      setCountError(`Максимально можно удалить ${maxQuantityNum}`)
      return
    }
    if (needCount && !quantity) {
      setCountError(`Требуется ввести количество!`)
      return
    }
    setCountError('')
  }, [quantity])

  useEffect(() => {
    /** Валидация серийных номеров */
    const itemWithCurrentSerial = getTareItemBySerialNumberVal(
      serial,
      openTareItems,
      selectedItem?.id,
    )
    if (!itemWithCurrentSerial && serial) {
      setSerialError('Серийный номер не найден в таре или партии')
      return
    }
    setSerialError('')
  }, [selectedItem, serial])

  const needCount = useMemo(() => {
    const batchAccounting = removingItemOriginal?.batch_accounting
    const tareItem = (
      isTrustBox
      ? getTareItemsByTrustBarcode(openTareItems, itemBarcode)
      : getTareItemsBySkuId(openTareItems, removingItemOriginal?.id))[0]
    /**
     * Когда надо вводить количесвто
     * 1) У товара: нет партий & нет серийных номеров & количесвто > 1
     * 2) У товара есть партия & нет серийных номеров & количесвто > 1
     */

    // 1)
    const usualItemWithQuantityMoreOne =
      !batchAccounting && !itemHasSerialNumbers && tareItem.quantity > 1
    // 2)
    const itemWithBatchQuantityMoreOne =
      batchAccounting &&
      !itemHasSerialNumbers &&
      (selectedItem?.quantity || 0) > 1

    return usualItemWithQuantityMoreOne || itemWithBatchQuantityMoreOne
  }, [selectedItem, openTareItems])

  const handleClickSubmit = () => {
    const correctItem = openTareItems.find((item) => {
      /** Находим нужный товар */

      const itemWithSerial = checkTareItemBySerialNumberCorrect(serial, item)
      const isItemIdCorrect = item.sku_id === removingItemOriginal?.id && selectedItem?.id === item.id

      if (itemHasSerialNumbers) {
        return Boolean(isItemIdCorrect && itemWithSerial)
      }

      return isItemIdCorrect && Number(quantity)
    })
    if (!correctItem) {
      return console.error('товар не найден, такого быть не может быть')
    }

    onSubmit({
      removingQuantity: quantity ? Number(quantity) : 1,
      tareItemId: correctItem.id,
    })
  }

  const btnDisabled =
    (itemsOptions?.length ? !Boolean(selectedItem) : false) ||
    (itemHasSerialNumbers ? !serial || Boolean(serialError) : false) ||
    (needCount ? Boolean(countError) : false) ||
    !Number(quantity)

  /** При мгновенном удпении заполнять нечего, по этому показываем лоадер */
  const isMomentalDeleting =
    !itemsOptions?.length && !itemHasSerialNumbers && !needCount
  if (isMomentalDeleting) {
    return <Loader size="m" className={styles.loader} />
  }
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      title={'Удаление товара'}
      titleAlign={'left'}
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size="m"
    >
      <div className={styles.body}>
        {
          removingItemOriginal ? (
            <ItemPreview
              image={removingItemOriginal.image}
              title={removingItemOriginal.title}
              barcode={itemBarcode}
            />
          ) : null
        }
        <div className={styles.fields}>
          {itemsOptions?.length ? (
            <div className={styles.batch}>
              <p className={styles.label}>Выберите партию и кратность</p>
              <SelectList
                height={300}
                options={itemsOptions}
                value={selectedItem}
                onChange={setSelectedItem}
                className={styles.select}
              />
            </div>
          ) : null}

          {itemHasSerialNumbers || needCount ? (
            <div className={styles.inputs}>
              {itemHasSerialNumbers ? (
                <InputWrapper
                  id={'serial'}
                  autoFocus
                  handleChange={setSerial}
                  error={serialError}
                  value={serial}
                  label={'Введите серийный номер'}
                  className={styles.serialInput}
                />
              ) : null}
              {needCount ? (
                <InputWrapper
                  isInteger
                  id={'quantity'}
                  autoFocus
                  error={countError}
                  handleChange={setQuantity}
                  value={quantity}
                  label={'Кол-во товара'}
                  className={styles.countInput}
                />
              ) : null}
            </div>
          ) : null}
        </div>
        <div className={styles.btns}>
          <Button
            label={'Отменить'}
            view={'ghost'}
            onClick={onClose}
            size={'m'}
          />
          <Button
            label={'Удалить'}
            view={'primary'}
            disabled={btnDisabled}
            onClick={handleClickSubmit}
            size={'m'}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ItemRemoving
