import React from 'react'
import styles from './UserCell.module.scss'
import cx from 'classnames'
import { IUserData } from '@/interfaces'
import { getFullPersonName } from '@shared/helpers'

interface DateCellProps {
  user: IUserData
}

export const UserCell = (props: DateCellProps) => {
  const {
    user,
  } = props
  if (!user) return null
  return (
    <div className={cx(styles.user)}>
      <b>{user.login || ''}</b>
      <p>{getFullPersonName(user)}</p>
    </div>
  )
}
