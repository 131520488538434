import React, { useEffect } from 'react'
import cx from 'classnames'
import styles from './combobox-wrapper.module.scss'

import { Combobox, ComboboxProps } from '@consta/uikit/Combobox'
import { useDebounce } from '@shared/hooks'
import { useHistory } from 'react-router-dom'
import { IconOpenInNew } from '@consta/icons/IconOpenInNew'
import { Text } from '@shared/ui/Text'

export type ApiSearchProps = {
  withApiSearch?: boolean
  handleApiSearch?: (value: string | null) => void
}
// Обработка добавления элемента
export type AddItemProps = {
  withCreateItem?: boolean
  createItemLabel?: string
  onCreate?: () => void
}
export type ComboboxWrapperProps = ComboboxProps & {
  id?: string
  link?: string
  label?: string
  isMultiple?: boolean
  searchFunction?: (item: any, searchValue: string) => boolean
  loading?: boolean
  wrapperClassName?: string
  readOnly?: boolean
  isRequired?: boolean
  error?: string
} & ApiSearchProps &
  AddItemProps

const ADD_ITEM_ID = 'addNewItem'

const ComboboxWrapper = (props: ComboboxWrapperProps) => {
  const history = useHistory()
  const {
    link,
    id,
    label,
    items,
    value,
    onChange,
    isMultiple,
    handleApiSearch,
    renderItem,
    size,
    searchFunction,
    loading,
    placeholder,
    className,
    wrapperClassName,
    withApiSearch,
    groups,
    withCreateItem,
    onCreate,
    createItemLabel = '+ Добавить',
    disabled,
    readOnly,
    isRequired,
    error
  } = props

  const [apiSearchDeb, apiSearch, setApiSearch] = useDebounce<string | null>(
    null, 500
  )

  useEffect(() => {
    if (!withApiSearch) {
      return
    }
    handleApiSearch?.(apiSearchDeb)
  }, [apiSearchDeb])

  const handleChangeInput = (data: { value: string | null }) => {
    setApiSearch(data.value)
  }

  const handleChangeItem: typeof onChange = (data) => {
    if (data.value === null) {
      // При сбросе значения, сбрасываем поисковый текст, для обновления списка
      setApiSearch('')
    }
    if (withCreateItem && String(data.value?.id) === ADD_ITEM_ID) {
      onCreate?.()
      return
    }
    onChange(data)
  }

  const correctItems =
    withCreateItem && !isMultiple
      ? [{ label: createItemLabel, id: ADD_ITEM_ID }, ...items]
      : items

  const hasLink = Boolean(value && link && (readOnly || disabled))
  return (
    <div
      className={cx(styles.wrapper, {[styles.cursorPointer]: hasLink}, wrapperClassName)}
      onClick={() => {
        if (hasLink) {
          window.open(link, '_blank')
        }
      }}
    >
      {label
        ?
        <label className={cx({ [styles.requiredLabel]: isRequired })}>
          {label} {hasLink ? <IconOpenInNew size={'s'} view={'link'} /> : null}
        </label>
        : null
      }
      <Combobox
        items={correctItems}
        value={value}
        onChange={handleChangeItem}
        multiple={isMultiple}
        renderItem={renderItem}
        size={size}
        status={error ? 'alert' : undefined}
        className={cx(styles.comboboxClassName, className)}
        searchFunction={searchFunction}
        onInputChange={handleChangeInput}
        dropdownClassName={cx(styles.dropdown)}
        placeholder={placeholder}
        isLoading={loading}
        groups={groups}
        labelForCreate={'Добавить'}
        onCreate={onCreate}
        disabled={disabled || readOnly}
        required={isRequired}
      />
      {error && <Text size={'s'} view={'alert'} children={error}/>}
    </div>
  )
}

export default ComboboxWrapper
