import React, { useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { ReturnStatusEnum } from '@/interfaces/statuses'
import { PATHS } from '@shared/routing'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'

import { formStatesAreEqual } from '../../../store/utils'
import { revertDraftStateToRawAction } from '../../../store/actions'

import { FormWrapper } from '../../../components'
import { ReturnFormContent } from './components'

import { setReturnFormDraftAction, setReturnRawAction } from '@pages/OperationsAndDocumentsPage/store/actions/return'
import { useReturnContext } from '../../../context/ReturnContext'

import { IOrderFormDataState } from '../../../store/interfaces/order'
import { useGetReturnById, useUpdateReturnStatus } from 'src/hooks'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { useNotifications } from '@shared/providers/NotificationProvider'


const ReturnForm = () => {
  const history = useHistory()
  const notification = useNotifications()

  const { state, dispatch } = useReturnContext()

  const { id: documentId } = useParams<{ page: OperAndDocumPagesType, id: string }>()
  const { isLoading, refetch: documentRefetch, data: documentData } = useGetReturnById(documentId)


  useEffect(() => {
    if (!documentData) {
      return
    }
    const formData = {
      status: documentData?.return?.status || '',
      comment: documentData?.return?.warehouse_comment || '',
    }
    dispatch(setReturnFormDraftAction(cloneDeep(formData)))
    dispatch(setReturnRawAction(cloneDeep(documentData)))
  }, [documentData])


  const updateReturnStatus = useUpdateReturnStatus()

  const handleClickSave = () => {
    updateReturnStatus
      .mutateAsync([{
        status: state.formStateDraft.status as ReturnStatusEnum,
        id: documentId,
      }])
      .then(() => {
        notification?.show('success', 'Документ успешно сохранен', {
          soundName: 'PACKING_COMPLETED',
        })
      })
      .finally(documentRefetch)
  }

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
      page: OperAndDocumPages.returnList,
    }))
  }

  const statesAreEqual = formStatesAreEqual<IOrderFormDataState | null>(state.formStateDraft, state.formStateRaw)

  return (
    <FormWrapper
      loading={isLoading || updateReturnStatus.isLoading}
      title={state.documentRaw?.return?.id || documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw?.return?.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={handleClickSave}
    >
      <ReturnFormContent />
    </FormWrapper>
  )
}

export default ReturnForm
