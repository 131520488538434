import { IOrderTask } from 'src/interfaces'
import { ICompletingFormDataState } from '../interfaces'

export const SET_COMPLETING_RAW = 'SET_COMPLETING_RAW'
export const setCompletingRawAction = (documentRaw: IOrderTask | null) => {
  return {
    type: SET_COMPLETING_RAW,
    payload: documentRaw,
  }
}
export const SET_COMPLETING_FORM_STATE_DRAFT = 'SET_COMPLETING_FORM_STATE_DRAFT'
export const setCompletingFormDraftAction = (
  formState: ICompletingFormDataState,
) => {
  return {
    type: SET_COMPLETING_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_COMPLETING_FORM_STATE_RAW = 'SET_COMPLETING_FORM_STATE_RAW'
export const setCompletingFormRawAction = (
  formState: ICompletingFormDataState,
) => {
  return {
    type: SET_COMPLETING_FORM_STATE_RAW,
    payload: formState,
  }
}
export const UPDATE_COMPLETING_STATE_DRAFT = 'UPDATE_FORM_STATE_DRAFT'
export const updateCompletingFormStateDraftAction = (
  key: keyof ICompletingFormDataState,
  value: any,
) => {
  return {
    type: UPDATE_COMPLETING_STATE_DRAFT,
    payload: { key, value },
  }
}
export const UPDATE_COMPLETING_STATE_RAW = 'UPDATE_FORM_STATE_RAW'
export const updateCompletingFormStateRawAction = (
  key: keyof ICompletingFormDataState,
  value: any,
) => {
  return {
    type: UPDATE_COMPLETING_STATE_RAW,
    payload: { key, value },
  }
}
