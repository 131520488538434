import { useMutation } from 'react-query'
import api from '@shared/api'

// response types
export interface GetTareStickersResponseTypes {
  content: string
  printer: string
  stickers: Sticker[]
  errors: Error[]
  success: boolean
}

export interface Error {
  code: string
  message: string
}

export interface Sticker {
  barcode: string
  content: string
  errors: Error2[]
  printer: string
  success: boolean
}

export interface Error2 {
  code: string
  message: string
}

const useGetTareStickers = () => {
  const getTareStickers = async (barcodes: string[]) => {
    const result = await api.post(`/warehouse/container/sticker`, barcodes)

    if (!result?.data?.success) {
      return
    }
    return result.data as GetTareStickersResponseTypes
  }

  return useMutation('getTareStickers', getTareStickers)
}

export default useGetTareStickers
