import { useQuery } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'
import { IShipment, IShipmentOrder, IShipmentOrderPlace } from 'src/interfaces/shipmnet'

/*
 * Получение данных отгрузки
 * wiki:
 * https://wiki.kak2c.ru/link/785#bkmrk-%D0%A1%D0%BE%D0%B7%D0%B4%D0%B0%D0%BD%D0%B8%D0%B5%2F%D1%80%D0%B5%D0%B4%D0%B0%D0%BA%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0
 */

interface IParams {
  shipmentId: string
}

const getShipment = async ({ shipmentId }: IParams) => {
  const result = await api.get(`/shipment/${shipmentId}`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

export interface IShipmentResultData {
  shipment: IShipment
  places: IShipmentOrderPlace[]
  orders: IShipmentOrder[]
}

interface IApiResult extends IShipmentResultData, ApiReqResult {}

const useGetShipment = (
  params: IParams,
  callback?: (object: IShipmentResultData) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IApiResult>(
    [`getShipment`, params],
    () => getShipment(params),
    {
      // keepPreviousData: true,
      onSuccess: ({ success, ...rest }) => {
        if (success) {
          callback?.(rest)
        } else {
          error?.()
        }
      },
      onError: () => error?.(),
      onSettled: () => settled?.(),
    },
  )
}

export default useGetShipment
