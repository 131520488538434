import { ISku, ITableColumn, ITableRow } from 'src/interfaces'
import { IRefundPlaceItem } from 'src/interfaces/refund/refund'
import React from 'react'
import { getBatchNameValue, getNomenclatureNameValue } from '../../../../../helpers/common'
import { NomenclatureCell, TitleCell } from '../../../../../components'
import { BatchCell } from 'src/components'

export const generateReturnPlaceColumns = (): ITableColumn[] => {
  return [
    {
      key: 'name_value',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: '2fr',
      renderCell: ({row}) => {
        return (
          <NomenclatureCell
            title={
              <TitleCell title={`${row.skuTitle}`} article={`${row.skuArticle}`} />
            }
            itemId={`${row.id}`}
          />
        )
      }
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
    },
    {
      key: 'batch_value',
      title: 'Партия',
      title_txt: 'Партия',
      renderCell: ({row}) => (
        <BatchCell
          num={row.batchNum as string || ''}
          date={row.batchDate as string || ''}
        />
      )
    },
    {
      key: 'tare',
      title: 'Тара',
      title_txt: 'Тара',
    },
  ]
}

export const generateReturnPlaceRows = (placeItems: IRefundPlaceItem[], skus: ISku[]): ITableRow[] => {
  return placeItems.map((item) => {
    const originalSku = skus.find(sku => sku.id === item.sku_id)
    const batch = originalSku?.batches?.find(skuBatch => skuBatch.id === item.sku_batch_id)
    return {
      id: item.sku_id || '',
      skuTitle: originalSku?.title,
      skuArticle: originalSku?.article,
      name_value: getNomenclatureNameValue(originalSku),
      quantity: item.quantity,
      batchNum: batch?.num || '',
      batchDate: batch?.exp_date || '',
      batch_value: getBatchNameValue(batch),
      tare: item.tare_barcode,
    }
  })
}
