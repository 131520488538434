import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { ICombobox, ReceiptStatusEnum } from 'src/interfaces'
import { generateStatusesOptions } from '@shared/helpers'


export const RECEIPT_STATUSES: Record<ReceiptStatusEnum | string, string> = {
  [ReceiptStatusEnum.open]: 'Открыт',
  [ReceiptStatusEnum.closed]: 'Закрыт',
  [ReceiptStatusEnum.done]: 'Размещен',
}

export const RECEIPT_STATUS_BADGE_TYPE: Record<ReceiptStatusEnum | string, BadgePropStatus> = {
  [ReceiptStatusEnum.open]: 'system',
  [ReceiptStatusEnum.closed]: 'success',
  [ReceiptStatusEnum.done]: 'error',
}

export const receiptSelectOptions: ICombobox[] = generateStatusesOptions(RECEIPT_STATUSES)