import { IPlacing } from 'src/interfaces'
import { TableColumn } from '@consta/uikit/Table'
import { PLACING_STATUS_BADGE_TYPE, PLACING_STATUSES } from '@/interfaces/statuses'
import { Badge } from '@consta/uikit/Badge'
import React from 'react'
import { OperAndDocumPages } from '../../../../pagesType'
import { DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'


export const generateRows = (placings: IPlacing[]) => {
  return placings.map(placing => ({
    id: placing.id,
    date: placing.create_ts,
    date_value: days(placing.create_ts).format(DATE_TIME_VIEW),
    documentNum: placing.id,
    merchant: placing.merchant?.title,
    status: placing.status,
    tareBarcode: placing.tare_barcode,
    status_value: PLACING_STATUSES[placing.status],
  }))
}
export const generateColumns = (): TableColumn<any>[] => {
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      // width: 130,
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{minWidth: '120px'}}/>
      }
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {

        return <DocumentNumCell page={OperAndDocumPages.placingForm} documentNum={row.documentNum} />
      }
    },
    {
      title: 'Организация',
      accessor: 'merchant',
    },
    {
      title: 'Статус',
      sortable: true,
      accessor: 'status_value',
      renderCell: ({row}) => {
        const status = row.status
        return (
          <Badge
            status={PLACING_STATUS_BADGE_TYPE[status]}
            label={PLACING_STATUSES[status]}
          />
        )
      }
    },
    {
      title: '№ тары',
      accessor: 'tareBarcode',
    },
  ]
}


