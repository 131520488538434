import React, { useEffect, useMemo, useState } from 'react'
import { DocumentsListWrapper } from '@pages/OperationsAndDocumentsPage/components'
import { useGetBillingServices } from 'src/hooks/billingServices.ts'
import { Button } from 'src/components'
import { generateColumns, generateFilters, generateRows } from './helpers'
import BillingServicesModal from '../BillingServicesModal/BillingServicesModal'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { useFilter } from '../../../hooks/useFilter'

const BillingServicesList = () => {
  const { filterState, setFilterState } = useFilter()
  const [openModal, setOpenModal] = useState(false)
  const [idDocument, setIdDocument] = useState('')

  const {
    isFetching: billingLoading,
    data: billingServices,
    refetch: refetchBillingServices,
  } = useGetBillingServices(generateFilterParams(filterState))

  useEffect(() => {
    refetchBillingServices()
  }, [filterState])

  const rows = useMemo(
    () => generateRows(billingServices?.services || []),
    [billingServices?.services],
  )
  const handleEdit = (id: string) => {
    setIdDocument(id)
    setOpenModal(true)
  }

  const columns = useMemo(
    () => generateColumns({ handleEdit }, billingServices?.groups || []),
    [billingServices?.groups],
  )
  const filtersConfig = generateFilters()

  return (
    <>
      <DocumentsListWrapper
        title={'Биллинг услуги'}
        rows={rows}
        columns={columns}
        filterState={filterState}
        setFilterState={setFilterState}
        filtersConfig={filtersConfig}
        loading={billingLoading}
        pagination={billingServices?.pagination}
        tableExtraHeaderRight={
          <Button
            label={'Добавить'}
            onClick={() => {
              setIdDocument('')
              setOpenModal(true)
            }}
          />
        }
      />
      {openModal ? (
        <BillingServicesModal
          isOpen
          docId={idDocument}
          services={billingServices?.services || []}
          groups={billingServices?.groups}
          onClose={() => setOpenModal(false)}
          refetch={refetchBillingServices}
        />
      ) : null}
    </>
  )
}
export default BillingServicesList
