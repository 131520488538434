import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IAcceptanceSku, ISupplyReceipt, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'


export interface IGetReceiptsParams {
  // todo проверить
  // search?: string
  status?: string
  from?: string
  to?: string
  merchant_ids?: string | null
}

export interface IGetReceiptsResult extends ApiReqResult, PaginationResult {
  skus: IAcceptanceSku[]
  receipts: ISupplyReceipt[]
}

const useGetReceipts = (
  paramsObj: IGetReceiptsParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IGetReceiptsResult>(
    [`getReceipts`, paramsObj],
    async () => {
      const searchParams = convertObjectToURLParams(paramsObj)
      const result = await api.get(`/receiving/receipt${searchParams}`)
      return result.data
    },
    {
      keepPreviousData: true,
      ...options
    },
  )
}

export default useGetReceipts
