import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { ISupplyFormState } from '../store/interfaces'
import { reducer, supplyInitialState } from '../store/reducer'

export interface IUseReducer {
  state: ISupplyFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {}

export const SupplyFormContext = createContext<IContext>({
  state: supplyInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const SupplyFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof supplyInitialState, IReducerAction>>(reducer, supplyInitialState)

  useEffect(() => {
    console.log('--- State ---', state)
  }, [state])

  return (
    <SupplyFormContext.Provider value={{ state, dispatch }}>
      {children}
    </SupplyFormContext.Provider>
  )
}

export const useMainFormContext = () => useContext(SupplyFormContext)
