import api from '@shared/api'

export interface IGetBillingResponse {
  content: string
  printer: string[] | []
  title: string
}

export interface IBillingExcelParams {
  docId: string
  itemId: string
}


const getBillingAsExcel = async (params: IBillingExcelParams): Promise<IGetBillingResponse> => {
  const result = await api.get(`/billing/result/${params.docId}/item/${params.itemId}/details/xls`)

  if (!result.data?.success) {
    throw result.data
  }

  return result.data
}

const useGetBillingAsExcel = () => ({
  fetch: (params: IBillingExcelParams) => getBillingAsExcel(params)
})

export default useGetBillingAsExcel
