import { ISupplyFormState } from '../interfaces'
import {
  REVERT_FORM_STATE_TO_RAW,
  SET_ALL_MATERIALS_CALC_ITEMS_FROM_SERVER_VALUES,
  SET_ALL_OPERATIONS_CALC_ITEMS_FROM_SERVER_VALUES,
  SET_ALL_SERVICES_MATERIAL,
  SET_FORM_STATE_DRAFT,
  SET_FORM_STATE_RAW,
  SET_ORIGINAL_SKUS,
  SET_SERVICES_DOCUMENTS,
  SET_SUPPLY_RAW,
  UPDATE_FORM_STATE_DRAFT,
  UPDATE_FORM_STATE_RAW,
} from '../actions'
import { IReducerAction } from 'src/interfaces'
import { cloneDeep } from '@shared/helpers'

export const supplyInitialState: ISupplyFormState = {
  supplyRaw: null,
  formStateRaw: null,
  formStateDraft: null,
  servicesDocuments: [],
  originalSkus: [],
  allMaterialsServices: [],
}

export const reducer = (
  state: ISupplyFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    /** Общие кейсы */
    case SET_FORM_STATE_DRAFT:
      return { ...state, formStateDraft: payload }

    case UPDATE_FORM_STATE_DRAFT:
      return {
        ...state,
        formStateDraft: {
          ...(state.formStateDraft || {}),
          [payload.key]: payload.value,
        }
      }

    case SET_FORM_STATE_RAW:
      return { ...state, formStateRaw: payload }

    case UPDATE_FORM_STATE_RAW:
      return {
        ...state,
        formStateRaw: {
          ...(state.formStateRaw || {}),
          [payload.key]: payload.value,
        },
      }

    case SET_ALL_OPERATIONS_CALC_ITEMS_FROM_SERVER_VALUES:
      console.log(state.formStateDraft?.initialOperationsPreCalcItems)
      return {
        ...state,
        formStateDraft: {
          ...state.formStateDraft,
          operationsInputService: cloneDeep(state.formStateDraft?.initialOperationsPreCalcItems),
        },
      }
    case SET_ALL_MATERIALS_CALC_ITEMS_FROM_SERVER_VALUES:
      return {
        ...state,
        formStateDraft: {
          ...state.formStateDraft,
          materialsInputService: cloneDeep(state.formStateDraft?.initialMaterialsPreCalcItems),
        },
      }

    case REVERT_FORM_STATE_TO_RAW:
      return {
        ...state,
        formStateDraft: cloneDeep(state.formStateRaw),
      }

    case SET_SUPPLY_RAW:
      return { ...state, supplyRaw: payload }

    case SET_ORIGINAL_SKUS:
      return { ...state, originalSkus: payload }

    case SET_SERVICES_DOCUMENTS:
      return { ...state, servicesDocuments: payload }

    case SET_ALL_SERVICES_MATERIAL:
      return { ...state, allMaterialsServices: payload }

    default:
      return state
  }
}
