import { useMutation } from 'react-query'
import api from '@shared/api'

export interface IProps {
  id: string
  title: string
  type: string
}

const useUpdateZone = () => {
  const updateZone = async (props: IProps) => {
    const result = await api.post(`/warehouse/zone/${props.id}`, props)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(updateZone)
}

export default useUpdateZone
