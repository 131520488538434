import React, { useEffect, useState } from 'react'

import styles from './ShipmentModal.module.scss'
import { ComboboxWrapper, ComboboxWrapperWithApi, Modal } from 'src/components'
import { ModalProps, OrderStatusEnum } from 'src/interfaces'
import { Button } from '@consta/uikit/Button'
import { DefaultItem } from '@consta/uikit/Combobox'
import { useCreateShipOrders, useGetDeliveriesList, useGetMerchantList, useGetQueryOrderList } from 'src/hooks'
import { ComboboxMerchants } from '@entities/Merchant'

import { ValidateErrors } from '@pages/DirectoriesPage/types'

interface IProps extends ModalProps {
  refetch: () => void
}

const ShipmentModal = (props: IProps) => {
  const { isOpen, onClose, refetch = () => {} } = props

  const [deliveriesList, setDeliveriesList] = useState<DefaultItem[]>([])

  const [merchant, setMerchant] = useState<DefaultItem[] | null>([])
  const [deliveries, setDeliveries] = useState<DefaultItem[] | null>([])
  const [order, setOrder] = useState<DefaultItem[] | null>([])

  const [error, setError] = useState('')

  const { data: merchantData } = useGetMerchantList({})
  const { data: deliveriesListData } = useGetDeliveriesList()
  const { mutateAsync: createShipOrders } = useCreateShipOrders()
  const { data } = useGetQueryOrderList({})

  useEffect(() => {
    if (!deliveriesListData?.values) return

    setDeliveriesList(
      deliveriesListData.values.map((el) => ({
        label: el.title,
        id: el.value,
      })),
    )
  }, [deliveriesListData, deliveries, merchant])

  const handleSubmit = () => {
    if (!order?.length) {
      setError(ValidateErrors.isEmpty)
      return
    }

    const data = order?.map((el) => String(el.id))

    createShipOrders({ data })
      .then(async () => {
        refetch()
      })
      .catch(() => {})
    onClose()
  }

  console.log(merchant)

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.modal}
      size="m"
      headerTitle={'Отгрузить заказы'}
    >
      <div className={styles.formWrapper}>
        <ComboboxMerchants
          className={styles.merchantRow}
          value={merchant as any}
          isMultiple
          onChange={(value) => setMerchant(value as any)}
        />
        <ComboboxWrapper
          className={styles.merchantRow}
          items={deliveriesList}
          value={deliveries as any}
          label={'Служба доствки'}
          isMultiple
          placeholder="Выберите службу доставки"
          onChange={({ value }) => {
            setOrder([])
            setDeliveries(value as any)
          }}
        />

        <ComboboxWrapperWithApi
          className={styles.merchantRow}
          value={order as any}
          label={'Документ отгрузки'}
          placeholder={'Выберите документ отгрузки'}
          onChange={(value) => setOrder(value as any)}
          isMultiple
          auto
          useGetHookSearch={useGetQueryOrderList}
          autoConvertData={{
            key: 'orders',
            labelValue: 'id',
            idValue: 'id',
          }}
          convertHookParams={(params) => {
            return {
              filter: {
                search: params.search,
                status: OrderStatusEnum.packed,
                delivery_companies: deliveries
                  ? deliveries?.map((el) => el.id)
                  : [],
                merchant_ids: merchant ? merchant.map((el) => el.id) : [],
                size: 50,
              },
            }
          }}
        />
        <div className={styles.errorMessage}>{error}</div>
      </div>

      <div className={styles.footer}>
        <Button label="Отмена" view={'ghost'} onClick={onClose} />
        <Button label="Сохранить" view={'primary'} onClick={handleSubmit} />
      </div>
    </Modal>
  )
}

export default ShipmentModal
