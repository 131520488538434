import api from '@shared/api'
import { ApiReqResult, IEnumValue } from 'src/interfaces'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'


interface IResult extends ApiReqResult {
  values: IEnumValue[]
}

const useGetPickingsErrors = (
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IResult>(
    [`getPickings`],
    async () => {
      const result = await api.get(`/enum/picking-error`)
      if (!result.data?.success){
        return
      }
      return result.data
    },
    {
      keepPreviousData: true,
      ...options
    },
  )
}
export default useGetPickingsErrors