import { ResponseError } from '@shared/api/types'

export enum ErrorCodes {
  JOIN_ACCESS_DENIED = 'ACCESS_DENIED',
  INVALID_DIMENSIONS = 'INVALID_DIMENSIONS',
  INVALID_EXPIRATION_DATE = 'INVALID_EXPIRATION_DATE',
  INVALID_QUANTITY = 'INVALID_QUANTITY',
  INVALID_SERIAL_NUMBER = 'INVALID_SERIAL_NUMBER',
  INVALID_WEIGHT = 'INVALID_WEIGHT',
  FRONT_UNKNOWN_ERROR = 'FRONT_UNKNOWN_ERROR',
  SORTING_ZONE_REQUIRED = 'SORTING_ZONE_REQUIRED',
}


export interface IError extends ResponseError {
}