import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, IFBSOrderBatch } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { provideMock } from '../../mocks'
import { MOCK_ORDER_BATCH, MOCK_ORDER_BATCH_2 } from '../../mocks/batch/getOrderBatch'

interface Params {
  batchId: string
}

interface GetOrderBatchResult extends ApiReqResult {
  order_batch: IFBSOrderBatch
}

const getMethod = async (params: Params) => {
  const result = await provideMock<Params, { data: GetOrderBatchResult }>(
    async () => await api.post(`/packing/order-batch/${params.batchId}/start`),
    params,[
    {
      key: 'batchId',
      value: 'mock2',
      result: { data: MOCK_ORDER_BATCH },
      logData: 'Старт упаковки батча'
    },
    {
      key: 'batchId',
      value: 'mock',
      result: { data: MOCK_ORDER_BATCH_2 },
      logData: 'Старт упаковки батча'
    },
    ]
  )()

  const { success, ...order_batch } = result.data
  return { success, order_batch }
}


const useStartFBSOrderBatch = (params: Params, options?: UseMutationOptions<any, GetOrderBatchResult, any>) => {
  return useMutation(
    async () => getMethod(params),
    options,
  )
}

export default useStartFBSOrderBatch
