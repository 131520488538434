import React, { FC } from 'react'

import { ITableRow } from 'src/interfaces'
import { DownloadDocsTable } from '../../../../../components'
import { useReturnContext } from '../../../../../context/ReturnContext'

interface AdditionalTabProps {
  documentUUID?: string
}

const AdditionalTab: FC<AdditionalTabProps> = () => {
  const { state } = useReturnContext()

  let rows: ITableRow[] = []

  if (state.documentRaw?.return?.places) {
    rows = state.documentRaw?.return?.places?.reduce((acc: ITableRow[], place) => {
      if (place.files) {
        const files: ITableRow[] = place?.files?.map((file) => ({
          id: file.id,
          name: file.title,
        }))

        acc = [...acc, ...files]
      }

      return acc
    }, [])
  }

  const handleDownload = (filesIds: string[]) => {
    if (!filesIds.length) {
      return
    }

    let selectedDocsUrls: string[] = []

    filesIds.forEach((fileId) => {
      state.documentRaw?.return?.places?.forEach((place) => {
        place.files?.forEach((file) => {
          if (file.id === fileId) {
            selectedDocsUrls.push(file.url as string)
          }
        })
      })
    })

    selectedDocsUrls.forEach((url) => window.open(url, '_blank'))
  }

  return (
    <div>
      <DownloadDocsTable
        title={'Документы: сканы прикрепленные'}
        tableRows={rows}
        handleClickDownload={handleDownload}
      />
    </div>
  )
}

AdditionalTab.displayName = 'AdditionalTab'

export default AdditionalTab
