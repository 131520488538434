import { useMutation, useQuery } from 'react-query'

import api from '@shared/api'

import { convertObjectToURLParams } from '@shared/helpers'

import { ApiReqResult, ICellPlace, IPaginationFilter } from 'src/interfaces'

export interface IGetCellsParams extends IPaginationFilter {
  search?: string
  barcode?: string
}

export interface IGetCellsResult extends ApiReqResult {
  cells: ICellPlace[]
}

const getResult = async (params: IGetCellsParams) => {
  const urlParams = convertObjectToURLParams(params)
  const result = await api.get(`/warehouse/cell${urlParams}`)

  if (result.data) {
    return result.data as IGetCellsResult
  }
}

export const useGetCellsQ = (params: IGetCellsParams) => {
  const getCells = () => getResult(params)

  return useQuery('getCellsM', getCells)
}

export const useGetCellsM = () => {
  const getCells = (params: IGetCellsParams) => getResult(params)

  return useMutation('getCellsM', getCells)
}
