import { useMutation } from 'react-query'
import api from '@shared/api'

const useDeleteZone = () => {
  const deleteZone = async (zoneId: string) => {
    const result = await api.delete(`/warehouse/zone/${zoneId}`)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(deleteZone)
}

export default useDeleteZone
