import {
  REVERT_FORM_STATE_TO_RAW,
  SET_TEMPLATE_DOCUMENT_RAW,
  SET_TEMPLATE_FORM_STATE_DRAFT,
  SET_TEMPLATE_FORM_STATE_RAW,
  UPDATE_FORM_TEMPLATE_STATE_DRAFT,
  UPDATE_FORM_TEMPLATE_STATE_RAW,
} from '../actions'
import { IReducerAction } from 'src/interfaces'
import { cloneDeep } from '@shared/helpers'
import { ITemplatesFormState } from '../interfaces'

export const templateInitialState: ITemplatesFormState = {
  documentRaw: null,
  formStateRaw: null,
  formStateDraft: null,
}

export const templateReducer = (
  state: ITemplatesFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    /** Общие кейсы */
    case SET_TEMPLATE_FORM_STATE_DRAFT:
      return { ...state, formStateDraft: payload }

    case UPDATE_FORM_TEMPLATE_STATE_DRAFT:
      return {
        ...state,
        formStateDraft: {
          ...(state.formStateDraft || {}),
          [payload.key]: payload.value,
        },
      }

    case SET_TEMPLATE_FORM_STATE_RAW:
      return { ...state, formStateRaw: payload }

    case UPDATE_FORM_TEMPLATE_STATE_RAW:
      return {
        ...state,
        formStateRaw: {
          ...(state.formStateRaw || {}),
          [payload.key]: payload.value,
        },
      }

    case REVERT_FORM_STATE_TO_RAW:
      return {
        ...state,
        formStateDraft: cloneDeep(state.formStateRaw),
      }

    case SET_TEMPLATE_DOCUMENT_RAW:
      return { ...state, documentRaw: payload }

    default:
      return state
  }
}
