import { IServices, IServicesDocuments } from 'src/interfaces'
import { IServicesFormDataState } from '../interfaces/services'

export const SET_SERVICES_RAW = 'SET_SERVICES_RAW'
export const setServicesRawAction = (
  servicesRaw: IServicesDocuments | null,
) => {
  return {
    type: SET_SERVICES_RAW,
    payload: servicesRaw,
  }
}
export const SET_SERVICES_FORM_STATE_DRAFT = 'SET_SERVICES_FORM_STATE_DRAFT'
export const setServicesFormDraftAction = (
  formState: IServicesFormDataState,
) => {
  return {
    type: SET_SERVICES_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_SERVICES_FORM_STATE_RAW = 'SET_SERVICES_FORM_STATE_RAW'
export const setServicesFormRawAction = (formState: IServicesFormDataState) => {
  return {
    type: SET_SERVICES_FORM_STATE_RAW,
    payload: formState,
  }
}
export const SET_ORIGINAL_SERVICES = 'SET_ORIGINAL_SERVICES'
export const setOriginalServicesAction = (services: IServices[]) => {
  return {
    type: SET_ORIGINAL_SERVICES,
    payload: services,
  }
}
