import React, { useMemo } from 'react'
import { FormTabs, generateTabs } from '../../../../../helpers/documentTabs'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { Comment, HistoryChanges, RelativeDocs } from '../../../../../components/CommonTabsPages'
import { updateMovingFormStateDraftAction } from '@pages/OperationsAndDocumentsPage/store/actions'
import { TabsContentWrapper } from '../../../../../components'
import { useMovingFormContext } from '@pages/OperationsAndDocumentsPage/context/MovingFormContext'
import { DocumentsParamsFields } from '..'
import { DocumentTypeEnum } from '@/interfaces'

interface MovingFormContentProps {
  loading?: boolean
  refetch?: () => void
}
const MovingFormContent = (props: MovingFormContentProps) => {
  const {
    refetch
  } = props
  const {
    page,
    id: movingId,
    tabId,
  } = useParams<{ page: OperAndDocumPagesType; id: string; tabId: string }>()

  const movingTabsWithDescription = useMemo(
    () =>
      generateTabs([
        FormTabs.documentsParams,
        FormTabs.relativeDocs,
        FormTabs.comments,
        FormTabs.historyChanges,
      ]),
    [page],
  )

  return (
    <TabsContentWrapper tabs={movingTabsWithDescription}>
      <TabPage tabId={tabId} documentId={movingId} refetch={refetch} />
    </TabsContentWrapper>
  )
}
interface ITabContent {
  tabId: string
  documentId: string
  refetch?: () => void
}
const TabPage = React.memo(({ documentId, tabId, refetch }: ITabContent) => {
  const {
    state: { documentRaw, formStateDraft },
    dispatch,
  } = useMovingFormContext()

  switch (tabId) {
    case FormTabs.documentsParams:
      return <DocumentsParamsFields refetch={refetch} />
    case FormTabs.relativeDocs:
      return <RelativeDocs documentId={documentId} documentType={DocumentTypeEnum.MOVING}/>
    case FormTabs.comments:
      return (
        <Comment
          stateCommentValue={formStateDraft?.comment || ''}
          handleChangeComment={(value) =>
            dispatch(updateMovingFormStateDraftAction('comment', value))
          }
        />
      )
    case FormTabs.historyChanges:
      return <HistoryChanges documentUUID={documentRaw?.uuid} />
  }
  return null
})
export default MovingFormContent
