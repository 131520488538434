import { ITableColumn, ITableRow, Service } from 'src/interfaces'
import React from 'react'
import { CheckboxCell } from 'src/components'

export const generateColumns = (
  checkedIds: string[],
  setCheckedIds: React.Dispatch<React.SetStateAction<string[]>>,
  rows: ITableRow[],
): ITableColumn[] => [
  {
    key: 'actions',
    title: '',
    title_txt: '',
    isAction: true,
    withHeaderAction: true,
    gridCellWidth: '50px',
    renderCell: ({ row, isHeader, index }: any) => (
      <CheckboxCell
        isHeaderCheckbox={isHeader}
        fieldId={row.id}
        fieldsIds={rows.map(row => row.id)}
        checkedItemsIds={checkedIds}
        setCheckedItemsIds={setCheckedIds}
      />
    )
  },
  {
    key: 'name',
    title: 'Номенклатура',
    title_txt: 'Номенклатура',
  },
]

export const generateRows = (services: Service[]): ITableRow[] => {
  return services.map(service => ({
    id: service.id,
    name: service.title,
  }))
}
