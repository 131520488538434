import { ISku } from 'src/interfaces'


export const REVERT_FORM_STATE_TO_RAW = 'REVERT_FORM_STATE_TO_RAW'
export const revertDraftStateToRawAction = () => {
  return {
    type: REVERT_FORM_STATE_TO_RAW,
    payload: null,
  }
}

export const SET_ORIGINAL_SKUS = 'SET_ORIGINAL_SKUS'
export const setOriginalSkusAction = (skus: ISku[]) => {
  return {
    type: SET_ORIGINAL_SKUS,
    payload: skus,
  }
}
