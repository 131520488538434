import { IconDocFilled } from '@consta/icons/IconDocFilled'
import React, { useState } from 'react'
import { Tooltip } from '@consta/uikit/Tooltip'
import { Position } from '@consta/uikit/Popover'
import { Text } from '@shared/ui/Text'
import cx from 'classnames'
import styles from './FileTooltip.module.scss'

type Props = {
  count?: number
  className?: string
}
export const FileTooltip = (props: Props) => {
  const {
    count,
    className,
  } = props
  const [position, setPosition] = useState<Position>(undefined)

  const handleMouseMove = (event: React.MouseEvent) => {
    setPosition({ x: event.clientX, y: event.clientY })
  }
  return (
    <>
      <div
        onMouseMove={handleMouseMove}
        onMouseLeave={() => setPosition(undefined)}
        className={cx(styles.iconWrapper, className)}
      >
        <IconDocFilled className={cx(styles.icon)} />
        {count}
      </div>

      <Tooltip
        placeholder={''}
        position={position}
        direction='upCenter'
        spareDirection='downStartLeft'
        size={'l'}
        isInteractive={false}
      >
        <Text size='xs' view='primary' lineHeight='m'>
          Есть доп. документы заказа (кол-во файлов: {count})
        </Text>
      </Tooltip>
    </>
  )
}