import React, { useEffect, useMemo } from 'react'
import { useGetSupplies } from 'src/hooks'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { DocumentsListWrapper } from '../../../components'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { useFilter } from '../../../hooks/useFilter'

const SupplyList = () => {
  /** Стейт для поиска и отображения данных фильтров */
  const { filterState, setFilterState } = useFilter()

  const {
    isFetching: suppliesLoading,
    data: suppliesData,
    refetch: refetchSupplies,
  } = useGetSupplies(generateFilterParams(filterState))

  useEffect(() => {
    refetchSupplies()
  }, [filterState])

  const rows = useMemo(
    () => generateRows(suppliesData?.supplies || []),
    [suppliesData?.supplies],
  )
  const columns = useMemo(() => generateColumns(), [])
  const filtersConfig = generateFilters()

  return (
    <DocumentsListWrapper
      title={'Документы приемки'}
      // @ts-ignore
      rows={rows}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      filtersConfig={filtersConfig}
      loading={suppliesLoading}
      pagination={suppliesData?.pagination}
    />
  )
}

export default SupplyList
