import React, { useMemo } from 'react'

import styles from './MassAcceptTable.module.scss'

import { TableV2 } from '@/components'

import { ConditionEnum, IAcceptanceSkuObj, IDefectType, ISupplyItem } from '@/interfaces'
import { generateTableColumns, generateTableRows } from './utils'
import { tableV2Theme } from '@/components/Table/TableV2'

interface IMassAcceptTableProps {
  condition: ConditionEnum
  theme?: tableV2Theme
  title?: string
  originalSkusObj: IAcceptanceSkuObj
  supplyItems: ISupplyItem[]
  tableExtraHeader?: React.ReactNode
  maxHeightBody?: number
  defectTypes: IDefectType[]
  quantityItemsValues:Record<string, number | undefined>
  batchItemsValues: Record<string, string | undefined>
  defectsItemsValues: Record<string, string | undefined>
  handleSetQuantity: (itemId: string, value?: number) => void
  handleSetItemBatch: (skuId: string, batchId: string | undefined) => void,
  handleSetDefect: (skuId: string, defect: string | undefined) => void,
  handleCreateItemBatch: (skuId: string) => void
}

const MassAcceptTable = (props: IMassAcceptTableProps) => {
  const {
    condition,
    theme = 'white',
    title = 'К обработке',
    originalSkusObj,
    supplyItems,
    tableExtraHeader,
    handleSetQuantity,
    handleSetItemBatch,
    handleCreateItemBatch,
    handleSetDefect,
    maxHeightBody = 320,
    quantityItemsValues,
    batchItemsValues,
    defectsItemsValues,
    defectTypes
  } = props

  const rows = useMemo(() =>
      generateTableRows({
        originalSkusObj,
        supplyItems,
        handleSetQuantity,
        handleSetItemBatch,
        handleCreateItemBatch,
        quantityItemsValues,
        handleSetDefect,
        batchItemsValues,
        defectsItemsValues,
        condition,
        defectTypes
      }),
    [
      originalSkusObj,
      supplyItems,
      quantityItemsValues,
      batchItemsValues,
      defectsItemsValues,
      handleSetQuantity,
    ],
  )
  const columns = useMemo(() => generateTableColumns(condition),[])

  return (
    <div className={styles.unProcessed}>
      <TableV2
        theme={theme}
        rows={rows}
        columns={columns}
        title={title}
        className={styles.tableWrap}
        maxHeightBody={maxHeightBody}
        extraHeader={tableExtraHeader}
      />
    </div>
  )
}

export default React.memo(MassAcceptTable)
