import { useMutation, useQuery } from 'react-query'

import api from '@shared/api'

import { convertObjectToURLParams } from '@shared/helpers'

import { ApiReqResult, ICellPlace, IPaginationFilter } from 'src/interfaces'

interface WarehousePlacesParams extends IPaginationFilter {
  search?: string
  barcode?: string
  title?: string
}

interface WarehousePlacesResult extends ApiReqResult {
  cells: ICellPlace[]
}

const getWarehousePlaces = async (params: WarehousePlacesParams): Promise<WarehousePlacesResult> => {
  const urlParams = convertObjectToURLParams(params)
  const result = await api.get(`/warehouse/place${urlParams}`)

  if (!result?.data?.success) {
    throw result
  }
  return result.data
}

export const useGetWarehousePlacesQ = (params: WarehousePlacesParams) =>
  useQuery(['getCellsQ', params], () => getWarehousePlaces(params))
export const useGetWarehousePlacesM = () =>
  useMutation('getCellsM', getWarehousePlaces)
