import { useMutation } from 'react-query'
import api from '@shared/api'

// request types
export interface PlaceContainerProps {
  docId: string
  cellBarcode: string
}

/*
 * Размещение тары целиком
 */
export const usePlaceContainer = () => {
  const sendContainer = async (props: PlaceContainerProps) => {
    const result = await api.post(`/placing/${props.docId}/place-container`, {
      cell_barcode: props.cellBarcode,
    })

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(sendContainer)
}

export default usePlaceContainer
