import { useMutation } from 'react-query'
import api from '@shared/api'

export interface IProps {
  roleId: string | null
  roleData: IData
}
interface IData {
  title: string
  privileges: string[]
}
const useCreateRole = () => {
  const createRole = async (props: IProps) => {
    const result = await api.post(
      `/warehouse/role${props.roleId ? `/${props.roleId}` : ''}`,
      props.roleData,
    )

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(createRole)
}

export default useCreateRole
