import api from '@shared/api'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'
import { PartialInventoryRequestResult } from 'src/interfaces'


const resetPartInventory = async (inventoryId: string) => {
  const result = await api.post(`/inventory-cell/${inventoryId}/reset`)
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

const useResetPartInventory = (
  options?: UseMutationOptions<any, PartialInventoryRequestResult, any>,
) => {
  return useMutation(resetPartInventory, options)
}

export default useResetPartInventory
