import api from '@shared/api'

import { MOCK_GET_STICKER_BY_PALLET, provideMock } from 'src/mocks'

import { OperationTypes, StickerResult } from 'src/interfaces'

interface useGetStickerByPalletParams {
  pallet_id: string
  operationType?: OperationTypes | string
}

const getStickerByPallet = async (params: useGetStickerByPalletParams) => {
  const url = params.operationType
    ? `/packing/pallet/${params.pallet_id}/sticker?operationType=${params.operationType}`
    : `/packing/pallet/${params.pallet_id}/sticker`
  const result = await api.get(url)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

const useGetStickerByPallet = () => ({
  fetch: (params: useGetStickerByPalletParams) =>
    provideMock<useGetStickerByPalletParams, StickerResult>(
      getStickerByPallet,
      params,
      [
        {
          key: 'pallet_id',
          value: 'mock',
          result: MOCK_GET_STICKER_BY_PALLET,
        },
        {
          key: 'pallet_id',
          value: 'mock2',
          result: MOCK_GET_STICKER_BY_PALLET,
        },
      ],
    )(),
})

export default useGetStickerByPallet
