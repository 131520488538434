import api from '@shared/api'

import { MOCK_GET_REGISTER_BY_ID, provideMock } from 'src/mocks'

import { ApiReqResult, IRegister } from 'src/interfaces'

interface UsetRegisterByIdParamsType {
  registerId: string
}

const getRegisterById = async (params: UsetRegisterByIdParamsType) => {
  const path = `/getregistry/registry/${params.registerId}/org/1/delivery/1`
  // window.showNotification('warning', path)
  console.log('getRegisterById')
  console.log(path)

  const result = await api.get(path)
  if (!result.data?.success) throw result.data
  return result.data
}

interface GetRegisterByIdResult extends ApiReqResult {
  objects: IRegister
}

const useGetRegisterById = () => ({
  fetch: (params: UsetRegisterByIdParamsType) =>
    provideMock<UsetRegisterByIdParamsType, GetRegisterByIdResult>(
      getRegisterById,
      params,
      {
        key: 'registerId',
        value: 'mock',
        result: MOCK_GET_REGISTER_BY_ID,
      },
    )(),
})

export default useGetRegisterById
