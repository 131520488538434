import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'

import { pickingInitialState, pickingReducer } from '../store/reducer'

import { IPickingFormState } from '../store/interfaces'

export interface IUseReducer {
  state: IPickingFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {
}

export const PickingFormContext = createContext<IContext>({
  state: pickingInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const PickingFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof pickingInitialState, IReducerAction>>(pickingReducer, pickingInitialState)

  useEffect(() => {
    console.log('--- Picking State ---', state)
  }, [state])

  return (
    <PickingFormContext.Provider value={{ state, dispatch }}>
      {children}
    </PickingFormContext.Provider>
  )
}

export const usePickingFormContext = () => useContext(PickingFormContext)
