import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { appLocalStorage } from '@shared/lib/storage'

import AdvanceModeModal from './AdvanceModeModal'

import { useGetDatabases, useRemoveAdvancedMode } from '@/hooks'

import packageInfo from '../../../../../../package.json'

import { ICurrentUser, IDatabaseSelectOption, IInformer } from '@/interfaces'
import { Informer } from '@/components'
import { DocumentsType, generateMenuList, IMenuList } from '@shared/ui/NavigationMenu/routes'
import { useGetReportsTypeList } from '@/hooks/report/useGetReportsTypeList'
import { useGetDocumentTypeList } from '@/hooks/document/useGetDocumentTypes'
import { AppContext } from '../config/context'


export const AppContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const removeAdvanceModeMutation = useRemoveAdvancedMode()

  const [databases, setDatabases] = useState<IDatabaseSelectOption[] | null>(null)
  const [authorized, setAuthorized] = useState(false)
  const [currentUser, setCurrentUser] = useState<ICurrentUser>({})
  const [globalLoading, setGlobalLoading] = useState(true)

  const [informer, setInformer] = useState<IInformer | null>(null)

  const [advanceMode, setAdvanceMode] = useState<boolean>(false)
  const [showAdvanceModeModal, setShowAdvanceModeModal] =
    useState<boolean>(false)
  const [deferredAction, setDeferredAction] = useState<(() => void) | null>(
    null,
  )

  const [appVersion, setAppVersion] = useState(packageInfo.version)

  const [afkTimer, setAfkTimer] = useState(dayjs())


  const [mainMenuList, setMainMenuList] = useState<IMenuList[]>([])
  const [reportsTypeList, setReportsTypeList] = useState<DocumentsType[]>([])
  const [documentsTypeList, setDocumentsTypeList] = useState<DocumentsType[]>([])

  useEffect(() => {
    if (!authorized) return
    const menuValue = generateMenuList({ reportsTypeList, documentsTypeList })
    setMainMenuList(menuValue.filter(menu => menu.items.length))
  }, [mainMenuList?.length, reportsTypeList?.length, documentsTypeList?.length, authorized])

  useGetReportsTypeList({
    enabled: !reportsTypeList?.length && authorized,
    onSuccess: (data) => setReportsTypeList(data?.values)
  })
  useGetDocumentTypeList({
    enabled: !documentsTypeList?.length && authorized,
    onSuccess: (data) => setDocumentsTypeList(data?.values)
  })


  const logout = () => {
    console.log(logout)
    appLocalStorage.clear()
    setAuthorized(false)
  }

  const getDatabases = useGetDatabases()

  useEffect(() => {
    if (databases) {
      // базы уже добавлены
      return
    }
    getDatabases
      .fetch()
      .then((data) => {
        setDatabases(data)
      })
  }, [databases])

  const performWithAdvance = useCallback((action: () => () => void) => {
    if (advanceMode) {
      action()()
    } else {
      setDeferredAction(action)
      setShowAdvanceModeModal(true)
    }
  }, [advanceMode])

  const removeAdvanceMode = async () => {
    await removeAdvanceModeMutation.mutateAsync().then(({ data }) => {
      if (data?.success === true) {
        setAdvanceMode(false)
      }
    })
  }

  return (
    <AppContext.Provider
      value={{
        mainMenuList,
        documentsTypeList,

        databases: databases || [],
        setDatabases,
        globalLoading,
        setGlobalLoading,
        authorized,
        setAuthorized,
        currentUser,
        setCurrentUser: (user: ICurrentUser) => {
          appLocalStorage.currentUser = user
          setCurrentUser(user)
        },
        advanceMode,
        setAdvanceMode,
        removeAdvanceMode,
        removeAdvanceModeIsLoading: removeAdvanceModeMutation.isLoading,
        performWithAdvance,
        showAdvanceModeModal,
        requestAdvanceMode: () => setShowAdvanceModeModal(true),
        appVersion,
        setAppVersion,
        resetAfkInterval: () => setAfkTimer(dayjs()),

        logout,

        informer,
        setInformer
      }}
    >
      {children}

      {showAdvanceModeModal ? (
        <AdvanceModeModal
          isOpen={true}
          deferredAction={deferredAction}
          setAdvanceMode={setAdvanceMode}
          onClose={() => {
            setDeferredAction(null)
            setShowAdvanceModeModal(false)
          }}
        />
      ) : null}
      {
        informer ?
          <Informer {...informer} />
        : null
      }
    </AppContext.Provider>
  )
}
