import { FieldRow } from '@widgets/GeneratorFields'

export const generateFilters = (): FieldRow[] => [
  {
    hidden: true,
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: 'search',
          viewDescription: 'Поиск',
        },
      },
    ],
  },
]
