import api from '@shared/api'
import { convertObjectToURLParams } from '@shared/helpers'
import { MOCK_STICKER_6x6, provideMock } from '../../mocks'
import { DocumentTypeEnum, StickerResult } from '../../interfaces'

export interface IItemStickerParams {
  sku_id: string
  /** Годен до */
  exp_date?: string // в итоге мы от этого откажемся
  /** Штрихкод товара для печати */
  barcode?: string
  /** Дата изготовления */
  create_date?: string
  /** Идентификатор документа */
  document_id?: string
  document_type?: DocumentTypeEnum
  /** Не учитывать в билинге */
  disable_billing?: boolean
  format?: 'PDF' | 'PNG'
  // старое непонятное поле, но используется в 1с
  advanced?: boolean
  // уникальный номер товара маркетплейса
  marketplace_sku_id?: string

  // // todo такого тега нет, для получения стикер брака используется другой метод
  // defect_comment?: string
}

const getStickerPath = (data: IItemStickerParams) => {
  const params = convertObjectToURLParams(data)
  return `/items/sticker${params}`
}

/** Новый метод получения стикера товара */
const useItemGetSticker = () => {
  return {
    fetch: (params: IItemStickerParams) =>
      provideMock<IItemStickerParams, StickerResult>(
        async () => {
          const path = getStickerPath(params)
          const result = await api.get(path)
          if (!result?.data?.success) {
            throw new Error()
          }
          const resultHasStickerProp = result.data.sticker
          if (!resultHasStickerProp) {
            // структурв для ответов, у которых нет sticker объекта
            return {
              success: result.data.success,
              stickerExists: true,
              sticker: {
                content: result.data.content,
                printer: result.data.printer
              }
            }
          }
          return result.data
        },
        params,
        {
          key: 'sku_id',
          value: 'mock',
          result: {
            success: true,
            sticker: {
              content: MOCK_STICKER_6x6,
              printer: 'STICKER_6x6',
            },
            stickerExists: true
          },
          logData: 'Получение стикера товара'
        },
      )(),
  }
}

export default useItemGetSticker
