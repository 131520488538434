import api from '@shared/api'
import { StickerResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'

interface IParams {
  orderId?: string
  type?: 'sku' | 'place'
}
const useGetFragileSticker = () => ({
  fetch: async (params?: IParams): Promise<StickerResult> => {
    const paramsStr = convertObjectToURLParams(params)
    const result = await api.get(`/packing/sticker/fragile${paramsStr}`);
    if (!result.data?.success) {
      throw result.data;
    }
    return result.data;
  },
})

export default useGetFragileSticker;
