
import { ICurrentUser } from '@/interfaces'
import { LOCAL_STORAGE_NAME } from '@shared/const/common'
import { LocalStorage } from './localStorage'


/* LS который отчищается при выходе из системы */
class AppLocalStorage extends LocalStorage {
  get token() {
    return this.getData('token')
  }
  set token(token: string) {
    this.setData('token', token)
  }
  get token_warehouse() {
    return this.getData('token_warehouse')
  }
  set token_warehouse(token_warehouse: string) {
    this.setData('token_warehouse', token_warehouse)
  }
  get currentUser() {
    return this.getData('currentUser')
  }
  set currentUser(u: ICurrentUser) {
    this.setData('currentUser', u)
  }
  get currentBase() {
    return this.getData('currentBase')
  }
  set currentBase(b: string) {
    this.setData('currentBase', b)
  }
}


export const appLocalStorage = new AppLocalStorage(LOCAL_STORAGE_NAME)
