import api from '@shared/api'

import { MOCK_UPDATE_BATCH_PACKING_PLACE, provideMock } from 'src/mocks'

import { IOrderPackagingPlace, IUpdatePackingPlaceResult } from 'src/interfaces'

interface useUpdateBatchPackingPlaceParamsType {
  orderId: string
  place: IOrderPackagingPlace
}

const updateBatchPackingPlace = async (
  params: useUpdateBatchPackingPlaceParamsType,
) => {
  const result = await api.post(`/packing/order/${params.orderId}/place`, {
    place: params.place,
  })

  if (!result.data?.success) {
    throw result.data
  }
  return result.data
}

const useUpdateBatchPackingPlace = () => ({
  fetch: (params: useUpdateBatchPackingPlaceParamsType) =>
    provideMock<
      useUpdateBatchPackingPlaceParamsType,
      IUpdatePackingPlaceResult
      // @ts-ignore
    >(updateBatchPackingPlace, params, [
      {
        key: 'orderId',
        value: 'mock',
        result: MOCK_UPDATE_BATCH_PACKING_PLACE,
      },
      {
        key: 'orderId',
        value: 'mock2',
        result: MOCK_UPDATE_BATCH_PACKING_PLACE,
      },
      {
        key: 'orderId',
        value: 'mock3',
        result: MOCK_UPDATE_BATCH_PACKING_PLACE,
      },
    ])(),
})

export default useUpdateBatchPackingPlace
