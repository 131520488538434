import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useBillingContext } from '../../../../../context/BillingContext'
import { CommentCollapse, DocumentsParams } from '../../../../../components'
import { Button, ConfirmActionModal, InputWrapper } from 'src/components'
import Details from '../Details/Details'

import { generateBillingColumns, generateBillingRows } from './utils'
import { OperAndDocumPagesType } from '../../../../../pagesType'

import styles from './documents-params.module.scss'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { useBillingPas, useCreateBilling } from 'src/hooks'
import { useNotifications } from '@shared/providers/NotificationProvider'

interface BillingFormContentProps {
  isLoading?: boolean
  refetch: () => void
}

const DocumentsParamsFields = (props: BillingFormContentProps) => {
  const {
    isLoading,
    refetch: refetchBilling,
  } = props
  const { state } = useBillingContext()
  const notification = useNotifications()
  const { page, id, tabId, serviceId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
    serviceId: string
  }>()

  const [search, setSearch] = useState<TextFieldPropValue>('')
  const [openModal, setOpenModal] = useState(false)
  const [titleForModal, setTitleForModal] = useState('')
  const [typeForModal, setTypeForModal] = useState('')

  const { isLoading: billingPasLoading, mutateAsync: billingPas } = useBillingPas()
  const { isLoading: createBillingLoading, mutateAsync: createBilling } = useCreateBilling()


  const rows = useMemo(
    () =>
      generateBillingRows(
        state.documentRaw?.items || [],
        state.services || [],
        String(search),
      ),
    [state.documentRaw?.items, state.services, search],
  )
  const handleConfirm = (type: string, title: string) => {
    setOpenModal(true)
    setTitleForModal(title)
    setTypeForModal(type)
  }
  const handleSubmit = () => {
    if (!state.documentRaw) return
    if (typeForModal === 'PAS') {
      const data = {
        merchant_ids: [state?.documentRaw?.merchant.id],
        year_month: state?.documentRaw?.date.slice(0, 7),
      }

      billingPas(data)
        .then(async () => {
          refetchBilling()
          notification?.show('success', 'Биллинг успешно выгружен в PAS', {
            soundName: 'PACKING_COMPLETED',
          })
        })
        .catch(() => {
        })
    } else {
      const data = {
        merchant_ids: [state?.documentRaw?.merchant.id],
        recreate: true,
        year_month: state?.documentRaw?.date.slice(0, 7),
      }
      createBilling(data)
        .then(async () => {
          refetchBilling()
          notification?.show('success', 'Биллинг успешно переформирован', {
            soundName: 'PACKING_COMPLETED',
          })
        })
        .catch(() => {
        })
    }
  }

  const columns = useMemo(() => generateBillingColumns(page, id, tabId), [])

  return serviceId ? (
    <Details items={state.documentRaw?.items} services={state.services || []} />
  ) : (
    <>
      <DocumentsParams
        rows={rows}
        columns={columns}
        isLoading={isLoading}
        tableTitle={'Позиции документа'}
        tableExtraHeader={
          <div className={styles.extraHeaderWrapper}>
            <Button
              size={'s'}
              label={'Переформировать биллинг'}
              view='secondary'
              loading={createBillingLoading}
              onClick={() => handleConfirm('edit', 'переформировать биллинг')}
            />
            <Button
              size={'s'}
              label={'Выгрузка в PAS'}
              loading={billingPasLoading}
              onClick={() => handleConfirm('PAS', 'выгрузить биллинг в PAS')}
            />
            <InputWrapper
              size={'s'}
              className={styles.search}
              value={search}
              id={'search'}
              label={'Поиск'}
              handleChange={setSearch}
            />
          </div>
        }
        tableMaxHeightBody={400}
      >
        <div className={styles.contentWrapper}>
          <div className={styles.fieldsWrapper}>
            <InputWrapper
              className={styles.fieldType25}
              value={state.documentRaw?.merchant?.title || '-'}
              id={'org'}
              label={'Организация'}
              disabled
            />
            {/*<InputWrapper*/}
            {/*  className={styles.fieldType25}*/}
            {/*  value={state.documentRaw?.comment || '-'}*/}
            {/*  id={'comment'}*/}
            {/*  label={'Комментарий'}*/}
            {/*  type={'textarea'}*/}
            {/*  disabled*/}
            {/*/>*/}
          </div>
          <CommentCollapse
            comment={state.documentRaw?.comment}
          />
        </div>
      </DocumentsParams>
      {openModal ? (
        <ConfirmActionModal
          isOpen={true}
          type='warning'
          withPreview={false}
          title={`Вы уверены, что хотите ${titleForModal}?`}
          size={'min'}
          confirmWithScan={false}
          onClose={() => {
            setOpenModal(false)
          }}
          onSubmit={() => {
            setOpenModal(false)
            handleSubmit()
          }}
        />
      ) : null}
    </>
  )
}

export default DocumentsParamsFields
