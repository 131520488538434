import React from 'react'

import { IShipmentTransportPlace, ITableColumn, ITableRow } from 'src/interfaces'
import { OperAndDocumPages } from '@pages/OperationsAndDocumentsPage'

import { IShipmentData } from '../../../../../store/interfaces/shipment'
import { DocumentNumCell } from '../../../../../components'


export const generateShipmentColumns = (): ITableColumn[] => {
  return [
    {
      key: 'merchant',
      title: 'Организация',
      title_txt: 'Организация',
    },
    {
      key: 'orderId',
      title: 'Заказ на отгрузку',
      title_txt: 'Заказ на отгрузку',
      renderCell: ({ row, index }) => {
        const orderId = row.orderId as string
        return <DocumentNumCell page={OperAndDocumPages.ordersShippedForm} documentNum={orderId} />
      }
    },
    {
      key: 'placeBarcode',
      title: 'Грузовое место',
      title_txt: 'Грузовое место',
    },
    {
      key: 'TPlaceBarcode',
      title: 'Транспортное место',
      title_txt: 'Транспортное место',
      renderCell: ({ row, index }) => {
        return <DocumentNumCell page={OperAndDocumPages.transportPlaceForm} documentNum={row.id} />
      }
    },
  ]
}

export const generateShipmentRows = (shipmentItems: IShipmentTransportPlace[], data: IShipmentData): ITableRow[] => {
  return shipmentItems
    .map((item) => {
      return item.places
        .map((placeBarcode, i )=> {
        const orderId = data.places?.find((place) => place.id === placeBarcode)?.order_id || ''
        const merchant = data.orders?.find((order) => order.id === orderId)?.merchant?.title || ''
        const TPlaceBarcode = item.barcode || ''
        return ({
          id: TPlaceBarcode,
          TPlaceBarcode,
          orderId,
          placeBarcode,
          merchant,
        })
      })
    })
    .flat(1)
    .sort((tRowA, tRowB) => {
      return tRowA.orderId === tRowB.orderId ? -1 : 1
    })
}
