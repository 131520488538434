import React, { CSSProperties } from 'react'
import days from 'dayjs'
import styles from './DateCell.module.scss'
import cx from 'classnames'
import { DATE_FORMAT_VIEW, TIME_FORMAT } from '@shared/const/date'

interface DateCellProps {
	date: string,
  dateFormat?: string
  timeFormat?: string
  className?: string
  style?: CSSProperties
}

export const DateCell = (props: DateCellProps) => {
  const {
    date,
    dateFormat = DATE_FORMAT_VIEW,
    timeFormat = TIME_FORMAT,
    className,
    style
  } = props

  return (
    <div className={cx(styles.date, className)} style={style}>
      <p>{days(date).format(dateFormat)}</p>
      <span>{days(date).format(timeFormat)}</span>
    </div>
  )
};
