import React, { useMemo } from 'react'
import cx from 'classnames'
import styles from './documents-params.module.scss'
import { TableWithCopy } from 'src/components'
import { ITableColumn, ITableRow } from 'src/interfaces'

interface SupplyFormContentProps {
	children?: React.ReactNode
	rows: ITableRow[]
	columns: ITableColumn[]
	tableTitle?: React.ReactNode
	tableClassName?: string
	tableExtraHeader?: React.ReactNode
	extraContent?: React.ReactNode
	tableMaxHeightBody?: number
	withCopy?: boolean // включаем/отключаем режим копирования в таблице
	isLoading?: boolean
	withNumbering?: boolean
}

const DocumentsParams = React.memo((props: SupplyFormContentProps) => {
	const {
		isLoading,
		withNumbering = true,
		withCopy,
		children,
		rows,
		columns,
		tableTitle = 'Товары',
		tableClassName,
		tableExtraHeader,
		extraContent,
		tableMaxHeightBody = 445
	} = props

	const correctRows = useMemo(() =>
		rows.map((row, i) => ({ ...row, numbering: i+1 })),
			[rows]
	)

  const correctColumns = useMemo(() => {
		const numbering = {
			key: 'numbering',
			title: "№",
			title_txt: "№",
			gridCellWidth: '30px',
			thClassName: styles.numberingCell,
			tdClassName: styles.numberingCell,
		}
		let resColumns = columns
		if (withNumbering) {
			resColumns = [numbering, ...columns]
		}

		return resColumns
	}, [columns, withNumbering])

	return (
		<div className={cx(styles.main)}>
			<div className={cx(styles.inputs)}>
				{children}
			</div>
			{ extraContent }
			<TableWithCopy
				// fullHeight
				// theme={'white'}
				isLoading={isLoading}
				withCopy={withCopy}
        rows={correctRows}
        columns={correctColumns}
				extraHeader={tableExtraHeader}
        title={tableTitle}
        tableWrapperClassName={cx(styles.tableWrap, tableClassName)}
        maxHeightBody={tableMaxHeightBody}
			/>
		</div>
	);
});
export default DocumentsParams
