import { IDeliveryItem, IOrderBatch, ITableColumn, OrderStatusEnum } from 'src/interfaces'
import { TableColumn } from '@consta/uikit/Table'
import { BATCH_STATUS_BADGE_TYPE, BATCH_STATUSES_DESCRIPTION } from '@/interfaces/statuses'
import { Badge } from '@consta/uikit/Badge'
import React from 'react'
import { OperAndDocumPages } from '../../../../pagesType'
import { CheckboxCell, DateCell, FileTooltip } from 'src/components'
import { DocumentNumCell, StatusCell } from '../../../../components'
import { getFullPersonName } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import days from 'dayjs'


export const generateRows = (batches: IOrderBatch[], deliveryService: IDeliveryItem[]) => {
  return batches.map(batch => {
    const firstOrderDeliveryService = batch.orders[0]?.delivery?.delivery_service
    const deliveryServiceMix = batch.orders.some(order => order.delivery?.delivery_service !== firstOrderDeliveryService)
    const total = batch.orders.length
    const packed = batch.orders.reduce((acc, order) => {
      if ([
        OrderStatusEnum.packedAwaitingDocs,
        OrderStatusEnum.packedAwaitingRelabel,
        OrderStatusEnum.packed,
        OrderStatusEnum.shipped,
        OrderStatusEnum.return_partially,
        OrderStatusEnum.return,
        OrderStatusEnum.done,
      ].includes(order.status as OrderStatusEnum)) {
        acc++
      }

      return acc
    }, 0)

    const sideStatus = total === packed

    return ({
      id: batch.id,
      date: batch.create_ts,
      date_value: days(batch.create_ts).format(DATE_TIME_VIEW),
      pickingPrinted: batch.picking_printed_at,
      pickingPrintedValue: days(batch.picking_printed_at).format(DATE_TIME_VIEW),
      documentNum: batch.id,
      ordersFilesCount: batch.orders.reduce((acc, order) => acc+order.files_count, 0),
      deliveryService: deliveryServiceMix ? 'МИКС' : deliveryService.find(ds => ds.value === firstOrderDeliveryService)?.title || '-',
      executor: getFullPersonName({
        name: batch.executor?.name,
        surname: batch.executor?.surname,
        patronymic: batch.executor?.patronymic,
      }),
      status: batch.status,
      status_value: BATCH_STATUSES_DESCRIPTION[batch.status],
      sideStatus,
      total,
      packed,
    })
  })
}
export const generateColumns = (
  allItemsIds: string[],
  selectionsIds: string[],
  setSelectionsIds: (data: string[]) => void,
): (TableColumn<any> | ITableColumn)[] => {

  return [
    {
      accessor: 'actions',
      title: '',
      withHeaderAction: true,
      gridCellWidth: '50px',
      renderCell: ({row, isHeader}) => {
        return (
          <CheckboxCell
            isHeaderCheckbox={isHeader}
            fieldId={row.id}
            fieldsIds={allItemsIds}
            checkedItemsIds={selectionsIds}
            setCheckedItemsIds={setSelectionsIds}
          />
        )
      },
    },
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({ row }) => {
        return (
          <StatusCell
            themeStatus={row.sideStatus ? 'success' : 'warning'}
            statusDescription={{
              danger: '',
              success: '',
              warning: ''
            }}
          >
            <DateCell date={row.date}/>
          </StatusCell>
        )
      },
    },
    {
      title: 'Дата печати подбора',
      sortable: true,
      accessor: 'pickingPrintedValue',
      renderCell: ({ row }) => {
        return <DateCell date={row.pickingPrinted}/>
      },
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({ row }) => {
        return <DocumentNumCell withNewTab documentNum={row.documentNum} page={OperAndDocumPages.batchForm} />
      },
    },
    {
      title: 'Исполнитель',
      accessor: 'executor',
    },
    {
      title: 'Способ доставки',
      accessor: 'deliveryService',
    },
    {
      title: 'Статус',
      sortable: true,
      accessor: 'status_value',
      renderCell: ({ row }) => {
        const status = row.status
        return <div style={{display: 'flex', alignItems: 'center'}}>
          <Badge
            status={BATCH_STATUS_BADGE_TYPE[status]}
            label={BATCH_STATUSES_DESCRIPTION[status]}
          />
          {row.ordersFilesCount ? <FileTooltip count={row.ordersFilesCount} /> : null}
        </div>
      },
    },
    {
      title: 'Упаковано',
      accessor: 'packed',
    },
    {
      title: 'Всего',
      accessor: 'total',
    },
  ]
}


