import React from 'react'

import { BadgeTypeEnum, ConditionEnum, IAcceptanceSku, ISupply, ITableColumn, ITableRow } from 'src/interfaces'

import { generateDiscrepancies } from '@shared/helpers/acceptance'
import { getCurrencySymbol } from '@shared/helpers'
import { BatchCell, ImageCell, NomenclatureBadgesList } from 'src/components'
import { NomenclatureCell, TitleCell } from '@pages/OperationsAndDocumentsPage/components'
import { IBadgeItem } from 'src/components/Badges/NomenclatureBadge'
import { getBatchNameValue, getNomenclatureNameValue } from '../../../../../../helpers/common'

export const aggregateSupplyInfo = (rows: ITableRow[]) => {
  return rows.reduce((acc, row) => {
    return {
      ...acc,
      quantity: acc.quantity + (Number(row.quantity) || 0),
      plan: acc.plan + (Number(row.plan) || 0),
    }
  }, { quantity: 0, plan: 0 })
}

export const generateColumns = (): ITableColumn[] => {
  return [
    {
      key: 'img',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '90px',
      renderCell: ({ row }) => <ImageCell image={String(row.img)} />,
    },
    {
      key: 'name_value',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: '2fr',
      renderCell: ({ row }) => (<>{row.name}</>),
    },
    {
      key: 'batch_value',
      title: 'Срок годности',
      title_txt: 'Срок годности',
      gridCellWidth: '1fr',
      renderCell: ({ row }) => (
        <BatchCell
          num={String(row.batchNum || '')}
          date={String(row.batchDate || '')}
        />
      ),
    },
    {
      key: 'price',
      title: 'Цена',
      title_txt: 'Цена',
    },
    {
      key: 'plan',
      title: 'План',
      title_txt: 'План',
      align: 'right',
      gridCellWidth: '100px',
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
      gridCellWidth: '100px',
    },
    {
      key: 'barcode',
      title: 'Штрихкод',
      title_txt: 'Штрихкод',
      align: 'right',
    },
  ]
}

export const generateRows = (supply: ISupply | null, skus: IAcceptanceSku[]): ITableRow[] => {
  if (!supply) {
    return []
  }

  const itemsWithDiscrepancies = generateDiscrepancies(
    skus,
    supply.items,
    supply.receipts,
    supply.trust_acceptance_boxes,
  )

  const rows = itemsWithDiscrepancies.filter(item => item.skuId).map((item) => {
    const originalSku = item.originalSku
    const supplyItem = item.supplyItem
    const currency = getCurrencySymbol(supplyItem?.price_data?.currency || 'RUB')
    const barcode = originalSku?.barcodes?.[0]
    const price = supplyItem?.price_data?.price
      ? `${supplyItem?.price_data?.price} ${currency}`
      : '-'
    const hasSubItems = item.itemsWithBatch?.length

    return {
      id: item.skuId,
      img: originalSku?.image,
      name_value: getNomenclatureNameValue(originalSku),
      name: (
        <>
          <NomenclatureCell
            title={(
              <TitleCell
                title={originalSku?.title || ''}
                article={originalSku?.article || ''}
              />
            )}
            itemId={item.skuId || '-'}
          />
          <BadgesList
            defect={Boolean(item.defect)}
            surplus={Boolean(item.surplus)}
            shortages={Boolean(item.shortages)}
          />
        </>
      ),
      price: price,
      surplus: Boolean(item.surplus),
      shortages: Boolean(item.shortages),
      plan: item.plan,
      quantity: Number(item.fact) + Number(item.defect),
      barcode: barcode?.barcode,
      theme: 'white',
      defaultRowView: true,
      defaultIsExpanded: true,
      subItems: hasSubItems ?
        item.itemsWithBatch?.map((itemWithBatch) => {
          return ({
            id: item.skuId,
            img: originalSku?.image,
            name: (
              <>
                <BadgesList
                  defect={Boolean(itemWithBatch.condition === ConditionEnum.DEFECT)}
                />
                <NomenclatureCell
                  title={(
                    <TitleCell
                      title={originalSku?.title || ''}
                      article={originalSku?.article || ''}
                    />
                  )}
                  itemId={item.skuId || '-'}
                />
              </>
            ),
            quantity: itemWithBatch.multiplicity ? +itemWithBatch.multiplicity * itemWithBatch.quantity : itemWithBatch.quantity,
            batchNum: itemWithBatch.batch.num || '',
            batchDate: itemWithBatch.batch.exp_date || '',
            batch_value:  getBatchNameValue(itemWithBatch.batch),
          })
        }) : undefined,
    }
  })
  .slice()
  .sort(row => row.surplus || row.shortages ? -1 : 0)
  return rows as ITableRow[]
}

const BadgesList = (props: { defect?: boolean, surplus?: boolean, shortages?: boolean }) => {
  const badges: IBadgeItem[] = [
    { key: BadgeTypeEnum.defect, visible: Boolean(props.defect), view: 'stroked' },
    { key: BadgeTypeEnum.surplus, visible: Boolean(props.surplus), view: 'stroked' },
    { key: BadgeTypeEnum.shortages, visible: Boolean(props.shortages), view: 'stroked' },
  ]
  return (
    <NomenclatureBadgesList badgesList={badges} />
  )
}
