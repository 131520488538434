import { useMutation } from 'react-query'
import api from '@shared/api'
import { IDocumentsFields } from '@pages/OperationsAndDocumentsPage'
import { ApiReqResult } from 'src/interfaces'

interface Result extends ApiReqResult {
  id: string
}
interface CreateUpdateServicesParams {
  // documentId: string
  newDoc: IDocumentsFields
}
//`https:// https://api-wms-test.tempoline.ru/billing/service-doc
// http://wms-test.kak2c.ru/warehouse-api/billing/service-doc/{doc_id
const useCreateServicesRendered = () => {
  return useMutation(async (newDoc: IDocumentsFields) => {
    const path = `/billing/service-doc`

    const result = await api.post(path, newDoc)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}
export default useCreateServicesRendered
