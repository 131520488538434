import React from 'react'
import styles from './document-status-field.module.scss'
import { Select } from 'src/components'
import { DocumentTypeEnum, ISelectOption } from 'src/interfaces'
import { useGetDocumentStatusesEnum } from 'src/hooks'

import cx from 'classnames'

interface DocumentParamsFieldsProps {
  className?: string
  documentType: DocumentTypeEnum
  value?: string
  onChange: (value: ISelectOption | null) => void
}

const DocumentStatusField = (props: DocumentParamsFieldsProps) => {
  const { documentType, value, onChange, className } = props
  const { data } = useGetDocumentStatusesEnum({ params: { type: documentType } })

  const statusesOptions = data?.values?.map((value) => ({ label: value.title, value: value.value })) || []
  const statusValue = statusesOptions.find((statusOption) => statusOption.value === value)
  return (
    <Select
      label={'Статус документа'}
      options={statusesOptions}
      value={statusValue || null}
      onChange={({ value }) => onChange(value)}
      className={cx(styles.itemField, className)}
    />
  )
}
export default DocumentStatusField
