import React, { useEffect, useState } from 'react'

import styles from './ConfirmActionModal.module.scss'

import { Button } from '@consta/uikit/Button'

import { EnterHandler, InputWrapper, ItemPreview, Modal } from 'src/components'

import { ModalProps } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { Loader } from '@consta/uikit/Loader'
import warningImg from '@shared/assets/images/orangeTiangle.svg'
import dangerImg from '@shared/assets/images/redTiangle.png'
import infoImg from '@shared/assets/images/blueTiangle.svg'
import { ModalPropSize } from '../Modal/Modal'
import cx from 'classnames'


/**
 * Модальное окно c подтверждением действия
 * через сканирование и без сканирования
 * */
type WithPreview = (
  {
    withPreview: false
    previewImage?: never,
    previewTitle?: never,
    previewBarcode?: never,
  } | {
  withPreview: true
  previewImage: string,
  previewTitle: string,
  previewBarcode: string,
}
  )

type ConfirmType = 'warning' | 'danger' | 'info'
type ConfirmActionProps = {
  confirmWithInput?: boolean
  confirmWithScan?: boolean
  title?: string
  onSubmit: (value?: TextFieldPropValue) => void
  onMount?: () => void
  subtitle?: string
  inputLabel?: string
  inputPlaceholder?: string
  confirmLoading?: boolean
  isInteger?: boolean
  cancelBtnLabel?: string
  submitBtnLabel?: string
  size?: ModalPropSize
  type?: ConfirmType
  // callback для проверки введенного значения,
  // возвращаем текст ошибки или ничего
  validateCallback?: (value: TextFieldPropValue) => string | void
  className?: string
} & ModalProps & WithPreview

const imgByType: Record<ConfirmType, string> = {
  'warning': warningImg,
  'danger': dangerImg,
  'info': infoImg,
}

const ConfirmActionModal = (props: ConfirmActionProps) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    onMount = () => {},
    title,
    type,
    subtitle,
    inputLabel = 'Отсканируйте штрихкод',
    inputPlaceholder,
    previewImage,
    previewTitle,
    cancelBtnLabel = 'Отмена',
    submitBtnLabel = 'Подтвердить',
    previewBarcode,
    withPreview,
    confirmLoading,
    confirmWithScan = true,
    confirmWithInput,
    validateCallback,
    isInteger,
    size = 'm',
    className,
  } = props


  useEffect(() => {
    onMount()
  }, [onMount])


  const [value, setValue] = useState<TextFieldPropValue>('')
  const [error, setError] = useState<string>('')

  const handleClickSubmit = (val: TextFieldPropValue) => {
    const errorTxt = validateCallback?.(val)
    if (errorTxt) {
      setError(errorTxt)
      return
    }
    onSubmit(value)
  }
  const handleChange = (val: TextFieldPropValue) => {
    if (!confirmWithInput) {
      const errorTxt = validateCallback?.(val)
      if (errorTxt) {
        setError(errorTxt)
        return
      }
    }

    setValue(val)
    setError('')
    if (!confirmWithInput) {
      onSubmit(val)
    }
  }

  const img = type ? imgByType[type] : undefined
  return (
    <EnterHandler onEnter={() => handleClickSubmit(value)}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={cx(styles.modal, className)}
        title={title}
        img={img}
        imgClass={styles.modalImg}
        subtitle={subtitle}
        size={size}
      >
        {
          withPreview ? (
            <ItemPreview
              image={previewImage || '-'}
              title={previewTitle || '-'}
              barcode={previewBarcode || ''}
            />
          ) : null
        }
        {confirmLoading ? <Loader className={styles.loading} /> : null}
        {
          confirmWithScan || confirmWithInput ? (
            <InputWrapper
              id={'barcode'}
              handleChange={handleChange}
              autoFocus={!confirmLoading}
              value={value}
              label={inputLabel}
              placeholder={inputPlaceholder}
              withDelay={!confirmWithInput}
              error={error}
              isInteger={isInteger}
            />
          ) : null
        }
        <div className={styles.btns}>
          <Button
            label={cancelBtnLabel}
            view='ghost'
            className={styles.btn}
            onClick={onClose}
          />
          {
            !confirmWithScan || confirmWithInput ? (
              <Button
                label={submitBtnLabel}
                view='primary'
                className={styles.btn}
                loading={confirmLoading}
                onClick={() => handleClickSubmit(value)}
              />
            ) : null
          }
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default ConfirmActionModal
