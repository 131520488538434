import { useMutation } from 'react-query'
import api from '@shared/api'

export interface IProps {
  documentId: string
  data: IData
}
interface IData {
  status: string
}

// http://wms-test.kak2c.ru/warehouse-api/placing/{doc_id}
const useChangeStatusPlacing = () => {
  const changeStatusPlacing = async ({ documentId, data }: IProps) => {
    const result = await api.post(`/placing/${documentId}`, data)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(changeStatusPlacing)
}

export default useChangeStatusPlacing
