import React, { FC, useRef } from 'react'

import cx from 'classnames'
import styles from './status-cell.module.scss'
import { Popover } from '@consta/uikit/Popover'
import { useToggle } from '@shared/hooks'
import { Text } from '@shared/ui/Text'
import { Card } from '@consta/uikit/Card'

type themeStatusType = 'danger' | 'success' | 'warning'

interface StatusCellProps {
  children?: React.ReactNode
  themeStatus?: themeStatusType
  statusDescription?: {
    [key in themeStatusType]: string
  }
}


const StatusCell: FC<StatusCellProps> = (props) => {
  const {
    children,
    themeStatus = 'warning',
    statusDescription,
  } = props

  const {
    value: isPopoverVisible,
    turnOff,
    turnOn,
  } = useToggle();

  const statusClass = cx(styles.status, styles[themeStatus])

  const anchorRef = useRef<HTMLDivElement>(null)

  const popoverDescription = statusDescription?.[themeStatus]

  return (
    <div className={styles.container}>
      <div
        ref={anchorRef}
        className={statusClass}
        onMouseEnter={turnOff}
        onMouseLeave={turnOn}
      />
      {
        popoverDescription && isPopoverVisible ? (
          <Popover
            placeholder={''}
            offset={10}
            direction='rightCenter'
            anchorRef={anchorRef}
          >
            <Card className={styles.cardText}>
              <Text size="s">
               {popoverDescription}
              </Text>
            </Card>
          </Popover>
        ) : null
      }

      {children}
    </div>
  )
}

StatusCell.displayName = 'StatusCell'

export default StatusCell
