import api from '@shared/api'
import { useMutation } from 'react-query'


const useDeleteReturnPlace = () => {
  return useMutation(async (data: {returnId: string, placeId: string}) => {
    const result = await api.delete(`/return/${data.returnId}/place/${data.placeId}`)
    return result.data
  })
}

export default useDeleteReturnPlace
