import { ValueOf } from '@shared/helpers'
import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'

export const QubyStatusEnum = {
  new: 'NEW',
  exported: 'EXPORTED',
} as const
export type QubyStatusEnum = ValueOf<typeof QubyStatusEnum>

export const QUBY_STATUSES: Record<QubyStatusEnum | string, string> = {
  [QubyStatusEnum.new]: 'Новый',
  [QubyStatusEnum.exported]: 'Экспорт',
}

export const QUBY_STATUS_BADGE_TYPE: Record<
  QubyStatusEnum | string,
  BadgePropStatus
> = {
  [QubyStatusEnum.new]: 'system',
  [QubyStatusEnum.exported]: 'success',
}
