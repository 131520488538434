import React, { useMemo } from 'react'
import { FormTabs, generateTabs } from '../../../../../helpers/documentTabs'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { Comment, HistoryChanges, RelativeDocs } from '../../../../../components/CommonTabsPages'
import { updateFormStateDraftAction } from '../../../../../store/actions'
import { TabsContentWrapper } from '../../../../../components'
import { useReceiptFormContext } from '@pages/OperationsAndDocumentsPage/context/ReceiptFormContext'
import { DocumentsParamsFields } from '..'
import useGetDocumentRelated from '@/hooks/document/useGetDocumentRelated'
import { DocumentTypeEnum } from '@/interfaces'

interface SupplyFormContentProps {
  loading?: boolean
  refetch: () => void
}

const ReceiptFormContent = (props: SupplyFormContentProps) => {
  const {
    page,
    id: supplyId,
    tabId,
  } = useParams<{ page: OperAndDocumPagesType; id: string; tabId: string }>()

  const {refetch} = props

  const supplyTabsWithDescription = useMemo(
    () =>
      generateTabs([
        FormTabs.documentsParams,
        FormTabs.relativeDocs,
        FormTabs.comments,
        FormTabs.historyChanges,
      ]),
    [page],
  )

  return (
    <TabsContentWrapper tabs={supplyTabsWithDescription}>
      <TabPage tabId={tabId} documentId={supplyId} refetch={refetch} />
    </TabsContentWrapper>
  )
}

interface ITabContent {
  tabId: string
  documentId: string
  refetch: () => void
}

const TabPage = React.memo(({ documentId, tabId, refetch }: ITabContent) => {
  const {
    state: { documentRaw, formStateDraft },
    dispatch,
  } = useReceiptFormContext()
  const { data } = useGetDocumentRelated({ params: { doc_id: documentId } })
  const document = data?.document

  switch (tabId) {
    case FormTabs.documentsParams:
      return <DocumentsParamsFields document={document} refetch={refetch}/>
    case FormTabs.relativeDocs:
      return <RelativeDocs documentId={documentId} documentType={DocumentTypeEnum.RECEIPT}/>
    case FormTabs.comments:
      return (
        <Comment
          stateCommentValue={formStateDraft?.comment || ''}
          handleChangeComment={(value) =>
            dispatch(updateFormStateDraftAction('comment', value))
          }
        />
      )
    case FormTabs.historyChanges:
      return <HistoryChanges documentUUID={documentRaw?.uuid} />
  }
  return null
})

export default ReceiptFormContent
