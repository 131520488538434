import { isCalendar, isComboboxSearchApi, isInput, isSelect, isSkuBatch, isTable } from '../generator/fieldTypes'
import { TemplateFieldType, TemplateTableCell } from '@shared/types/tableGenerator/generatorTypes'


/** Получаем новый формат стейта для каждого типа поля */
export const getStateValueByType = (value, columnId, type: TemplateFieldType): TemplateTableCell => {
  switch(true) {
    case isSkuBatch(type):
    case isComboboxSearchApi(type):
      if (!value) {
        return {
          id: columnId,
          meta: { dto: null },
          value: null
        }
      }
      return {
        id: columnId,
        meta: { dto: value.dto },
        value: value.dto.id
      }
    case isInput(type):
      return {
        id: columnId,
        value: value || null
      }
    case isSelect(type):
      return {
        id: columnId,
        value: value?.id || null
      }
    case isCalendar(type):
      return {
        id: columnId,
        value: value || null
      }
    case isTable(type):
      return {
        id: columnId,
        additional_data: value?.additional_data,
        value: value?.value || null
      }
  }
}