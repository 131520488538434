import api from '@shared/api'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'
import { ApiReqResult, IError } from 'src/interfaces'

interface IData {
  cell_barcode: string
  items: IDataItems[]
}
interface IDataItems {
  quantity: number
  sku_batch_id?: string
  sku_id: string
}

interface IResult extends ApiReqResult {
  errors?: IError[]
  id: string
  success: boolean
}

interface IParams {
  factId?: string
  docId: string
  data: IData
}
// http://wms-test.kak2c.ru/warehouse-api/inventory/{inventory_id}/fact
const addResultInventory = async ({ factId, docId, data }: IParams) => {
  const result = await api.post(
    `/inventory/${docId}/${factId ? `fact/${factId}` : 'fact'}`,
    data,
  )
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

const useAddResultInventory = (
  options?: UseMutationOptions<any, IResult, any>,
) => {
  return useMutation(addResultInventory, options)
}

export default useAddResultInventory
