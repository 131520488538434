import React, { useEffect, useMemo, useState } from 'react'

import styles from './MovingModalNew.module.scss'

import { Modal } from 'src/components'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IDefectType, IMoving, IMovingItem, ISku, ModalProps } from 'src/interfaces'
import { Button } from '@consta/uikit/Button'
import { generateAddStateItems, generateStateItems } from './table'
import { fetchItemsStock } from 'src/hooks'
import { useCreateAddMoving } from 'src/hooks/warehouse/movingSimple'
import { CellDefaultType, CellsObjType, ISubmitPropsData, StockType } from './types'
import { useNotifications } from '@shared/providers/NotificationProvider'
import useGetDefectType from '@/hooks/defect/useGetDefectType'
import MovingModalForm from './MovingModalForm'
import { v4 } from 'uuid'

interface IProps extends ModalProps {
  docId: string
  refetch: () => void
  movingDocument?: IMoving
  skus: ISku[] | undefined
  stocksData?: StockType | null
}

enum CellStateKey {
  cell_barcode_from = 'from',
  cell_barcode_to = 'to'
}

const initialCellForm: CellsObjType = { [v4()]: { position: 0, from: null, to: null, stock: null } }

const MovingModalNew = (props: IProps) => {
  const {
    isOpen,
    onClose,
    refetch = () => {
    },
    docId,
    movingDocument,
    skus,
    stocksData,
  } = props
  const [comment, setComment] = useState<TextFieldPropValue>('')
  const [resultState, setResultState] = useState<ISubmitPropsData>({})

  const [cellsObj, setCellsObj] = useState<CellsObjType>(initialCellForm)
  const [errorObj, setErrorObj] = useState<Record<string, string>>({})
  const [defectTypes, setDefectTypes] = useState<IDefectType[]>([]) // todo


  const handleAddNewForm = () => {
    /** Добавляем новую форму на основе прошлой, для удобства склада */
    const formId = v4()
    const cellsKeys = Object.keys(cellsObj)
    const position = cellsKeys.length
    const cellKey = cellsKeys.find(key => cellsObj[key]?.position === position - 1)
    const prevValue = cellsObj[cellKey]
    const from = prevValue?.from || null, to = prevValue?.to || null, stock = prevValue?.stock || null
    const resultItems = getResultItems(formId, prevValue?.stock || null, {from: from?.dto?.barcode, to: to?.dto?.barcode})
    // Данные по ячейкам
    setCellsObj({ ...cellsObj, [formId]: { position, from, to, stock } })
    // Данные для формы ввода
    setResultState((prev) => ({
      ...prev,
      [formId]: {
        ...prev[formId],
        cell_barcode_from: from?.dto?.barcode,
        cell_barcode_to: to?.dto?.barcode,
        items: resultItems?.map(item => ({...item, quantity: '0'})) || [],
      },
    }))
  }
  const handleRemoveForm = (formId: string) => {
    setCellsObj(prev => {
      if (Object.keys(prev).length === 1) return prev
      delete prev[formId]
      return { ...prev }
    })
    setResultState(prev => {
      if (Object.keys(prev).length === 1) return prev
      delete prev[formId]
      return { ...prev }
    })
  }

  const handleSetError = (formId: string, text: string) => {
    setErrorObj(prev => ({ ...prev, [formId]: text }))
  }

  const notification = useNotifications()

  const { fetch: fetchDefectTypes } = useGetDefectType()

  useEffect(() => {
    if (defectTypes.length) return
    (async function() {
      try {
        const types = await fetchDefectTypes()
        setDefectTypes(types)
      } catch (e) {
      }
    }())
  }, [])

  const createAddMoving = useCreateAddMoving({})


  useEffect(() => {
    if (!movingDocument || !skus || !stocksData) {
      return
    }
    const document = movingDocument

    // сортируем новые данные по формам
    const sortRawDataToForms = document.items.reduce((acc, item) => {
      const addedArr = acc.find(addedItems =>
        (addedItems[0]?.from_cell_barcode === item.from_cell_barcode)
        && (addedItems[0]?.to_cell_barcode === item.to_cell_barcode),
      )
      if (addedArr) {
        addedArr.push(item)
        return acc
      }
      return [...acc, [item]]
    }, [] as IMovingItem[][])

    const movingDataToEdit: CellsObjType = sortRawDataToForms.reduce((acc, itemArr, i) => {
      const stock = stocksData.stock.filter(stock => (stock.place_id === itemArr[0]?.from_cell_barcode))
      const skus = stocksData.skus.filter(sku => stock.find(item => item.sku_id === sku.id))
      const places = stocksData.places.filter(place =>
        (place.barcode === itemArr[0]?.from_cell_barcode)
        || (place.barcode === itemArr[0]?.to_cell_barcode),
      )
      const fromCell = places.find(place => place.barcode === itemArr[0]?.from_cell_barcode)
      const toCell = places.find(place => place.barcode === itemArr[0]?.to_cell_barcode)
      return {
        ...acc,
        [v4()]: {
          position: i,
          from: { id: fromCell?.id, label: fromCell?.title, dto: fromCell },
          to: { id: toCell?.id, label: toCell?.title, dto: toCell },
          stock: { places, skus, stock },
        },
      }
    }, {})

    const resultStateValue: ISubmitPropsData = (
      Object
        .entries(movingDataToEdit)
        .reduce((acc, cellData) => {
          const formId = cellData[0]
          const formData = cellData[1]
          const formStock: StockType = formData?.stock

          const resultItems = getResultItems(
            formId,
            formStock || null,
            {from: formData?.from?.dto?.barcode, to: formData?.to?.dto?.barcode}
          )

          return {
            ...acc,
            [formId]: {
              cell_barcode_from: formData?.from?.dto?.barcode,
              cell_barcode_to: formData?.to?.dto?.barcode,
              items: resultItems,
            },
          }
        }, {}))

    setCellsObj(movingDataToEdit)
    setResultState(resultStateValue)
    setComment(document?.warehouse_comment || '')
  }, [movingDocument, skus, stocksData])

  const handleChangeTableItems = (formId: string, id: string, key: string, value: string | null) => {
    const newItems = resultState[formId]?.items.map((el) => {
      if (el.id === id) {
        return { ...el, [key]: value }
      } else {
        return el
      }
    })
    setErrorObj(prev => ({ ...prev, [formId]: '' }))

    setResultState((prev) => ({
      ...prev,
      [formId]: {
        ...prev[formId],
        items: newItems,
      },
    }))
  }

  const getResultItems = (formId: string, stockData: StockType | null, cellsData?: { from: string, to: string }) => {
    if (!stockData) return []
    return !docId
      ? generateStateItems(stockData)
      : generateAddStateItems(stockData, movingDocument?.items || [], cellsData)
  }
  const handleChangeCell = async (formId: string, fieldType: keyof ISubmitPropsData, value: CellDefaultType | null) => {
    const formCells = cellsObj[formId]
    const fromCellBarcode = String((fieldType === 'cell_barcode_from') ? value?.dto?.barcode : formCells?.from?.dto?.barcode)
    const toCellBarcode = String((fieldType === 'cell_barcode_to') ? value?.dto?.barcode : formCells?.to?.dto?.barcode)
    let stockData = null
    if (fromCellBarcode) {
      stockData = await fetchItemsStock({ place_ids: [fromCellBarcode] })
      const resultItems = getResultItems(formId, stockData, {from: fromCellBarcode, to: toCellBarcode })
      setResultState((prev) => ({
        ...prev,
        [formId]: {
          ...prev[formId],
          [fieldType]: String(value?.dto?.barcode),
          items: resultItems,
        },
      }))
    }
    setCellsObj((prev) => {
      return ({
        ...prev,
        [formId]: {
          ...prev[formId],
          [CellStateKey[fieldType]]: value,
          stock: stockData,
        },
      })
    })
  }

  const checkCellsProblem = (): string | void => {
    return Object.keys(resultState).find(formId =>
      (!resultState[formId]?.cell_barcode_from || !resultState[formId]?.cell_barcode_to))
  }
  const handleSubmit = () => {
    const cellProblemFormId = checkCellsProblem()
    if (cellProblemFormId) {
      handleSetError(cellProblemFormId, 'Отредактируйте количество товара для перемещения!')
      return
    }

    const subArray = Object.entries(resultState).map(formItem => {
      const formId = formItem[0]
      const form = formItem[1]
      const subArray = form
        .items
        .filter((el) => Number(el.quantity))
        .map((el) => {
          return {
            from_cell_barcode: form.cell_barcode_from,
            to_cell_barcode: form.cell_barcode_to,
            defect_type_id: el.defect_type_id,
            quantity: +el.quantity,
            sku_batch_id: el.sku.sku_batch_id,
            sku_id: el.sku.sku_id,
          }
        })
      if (!subArray.length) {
        setErrorObj(prev => ({
          [formId]: 'Отредактируйте количество товара для перемещения !',
        }))
      }
      return subArray
    })
    if (subArray.some(arr => !arr.length)) {
      console.error('Ошибка данных')
      return
    }

    const data = {
      items: subArray.flat(1),
      warehouse_comment: comment || '',
    }
    createAddMoving.mutateAsync({ docId, data })
      .then(async () => {
        refetch()
        notification?.show('success', 'Документ успешно сохранен', {
          soundName: 'PACKING_COMPLETED',
        })
      })
      .catch(() => {
      })

    onClose()
  }

  const saveBtnDisabled = useMemo(() => {
    return Object.values(resultState).reduce((acc, item) => {
      return (!item.cell_barcode_from || !item.cell_barcode_to) && acc
    }, true)
  }, [resultState])

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.modal}
      size='xl'
      headerTitle={'Создание документа перемещения'}
    >
      <MovingModalForm
        resultState={resultState}
        errorObj={errorObj}
        defectTypes={defectTypes}
        comment={comment}
        setComment={setComment}
        setResultState={setResultState}
        onChangeTableItems={handleChangeTableItems}
        onChangeCell={handleChangeCell}
        onAddNewForm={handleAddNewForm}
        onRemoveForm={handleRemoveForm}
        cellsObj={cellsObj}
        setCellsObj={setCellsObj}
        setErrorObj={setErrorObj}
      />

      <div className={styles.footer}>
        <Button label='Отмена' view={'ghost'} onClick={onClose} />
        <Button
          label='Сохранить'
          view={'primary'}
          onClick={handleSubmit}
          loading={createAddMoving.isLoading}
          disabled={saveBtnDisabled}
        />
      </div>

    </Modal>
  )
}
export default MovingModalNew
