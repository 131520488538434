import React, { useEffect, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { PATHS } from '@shared/routing'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'

import { formStatesAreEqual } from '../../../store/utils'
import { revertDraftStateToRawAction } from '../../../store/actions'

import { FormWrapper } from '../../../components'
import { PackingFormContent } from './components'

import { setPackingRawAction, setPlacesRawAction } from '@pages/OperationsAndDocumentsPage/store/actions/packing'

import { usePackingContext } from '../../../context/PackingContext'

import { IOrderFormDataState } from '../../../store/interfaces/order'
import useGetTransportPallet from '@/hooks/packing/useGetTransportPallet'
import { combineDataToFrontFormat } from '../utils'
import { IGetPackingListResponse } from '@/hooks/packing/useGetPackingList'
import { DATE_TIME_VIEW } from '@shared/const/date'

const TransportPlaceForm = () => {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const { state, dispatch } = usePackingContext()

  const { id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()

  const { fetch: getPack } = useGetTransportPallet()

  useEffect(() => {
    setIsLoading(true)
    getPack({ pallet_id: documentId })
      .then((data) => {
        const newData = {
          places: data.places.filter((el) =>
            data.pallet.places.includes(el.id),
          ),
          orders: data.orders,
          pallets: [data.pallet],
        } as IGetPackingListResponse
        const palletDataFrontFormat = combineDataToFrontFormat(newData)
        dispatch(setPackingRawAction(palletDataFrontFormat.pallets[0]))
        dispatch(setPlacesRawAction(newData.places))
      })
      .finally(() => setIsLoading(false))
  }, [])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.transportPlaceList,
      }),
    )
  }

  const statesAreEqual = formStatesAreEqual<IOrderFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )

  return (
    <FormWrapper
      loading={isLoading}
      title={documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw?.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <PackingFormContent />
    </FormWrapper>
  )
}

export default TransportPlaceForm
