import React, { useEffect, useMemo, useState } from 'react'

import { generateColumns, generateFilters, generateRows } from './helpers'
import { DocumentsListWrapper } from '../../../components'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'

import useGetShipmentsList from 'src/hooks/shipment/useGetShipmentsList'

import { IShipmentData } from '../../../store/interfaces/shipment'
import { Button } from 'src/components'
import ShipmentModal from '../ShipmentModal/ShipmentModal'
import { useFilter } from '../../../hooks/useFilter'

const ShipmentList = () => {
  /** Стейт для поиска и отображения данных фильтров */
  const { filterState, setFilterState } = useFilter()
  const [openModal, setOpenModal] = useState(false)

  const {
    refetch: getShipments,
    isFetching,
    data,
  } = useGetShipmentsList(generateFilterParams(filterState))

  useEffect(() => {
    getShipments()
  }, [filterState])

  const rows = useMemo(
    () => generateRows(data || ({} as IShipmentData)),
    [data],
  )
  const columns = useMemo(() => generateColumns(), [])
  const filtersConfig = generateFilters()

  return (
    <>
      <DocumentsListWrapper
        title={'Отгрузка со склада'}
        rows={rows}
        columns={columns}
        pagination={data?.pagination}
        filterState={filterState}
        setFilterState={setFilterState}
        filtersConfig={filtersConfig}
        loading={isFetching}
        tableExtraHeaderRight={
          <Button label={'Добавить'} onClick={() => setOpenModal(true)} />
        }
      />
      {openModal ? (
        <ShipmentModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          refetch={getShipments}
        />
      ) : null}
    </>
  )
}

export default ShipmentList
