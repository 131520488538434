import { useMutation } from 'react-query'
import api from '@shared/api'

// request types
export interface DeleteProductProps {
  docId: string
  itemId: string
}

export const useDeleteProduct = () => {
  const deleteProduct = async (props: DeleteProductProps) => {
    const result = await api.delete(
      `/placing/${props.docId}/item/${props.itemId}`,
    )

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(deleteProduct)
}

export default useDeleteProduct
