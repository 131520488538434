import React, { useEffect, useMemo, useState } from 'react'

import SelectBatchModal from './SelectBatchModal'

import {
  ItemCountModal,
  ItemScanSerialModal,
  SelectDefectModal,
  TSDSelectBatchModalV2,
  ValidateCodesEnum,
  WeightDimensionsModal,
} from '@/components'

import { useAcceptanceContext } from '../../context/AcceptanceContext'

import { useNotifications } from '@shared/providers/NotificationProvider'
import { useSound } from '@shared/providers/SoundProvider'

import {
  AcceptanceType,
  DocumentTypeEnum,
  IAcceptanceSku,
  IBatch,
  IDefectType,
  ISelectOption,
  ISupplyItem,
  ISupplyReceiptItem,
} from '@/interfaces'
import { setSavedItemsWeightAndDimensions } from '../../store/actions'
import SelectMultiplicityModal from './SelectMultiplicityModal'
import isMobile from 'ismobilejs'
import { RemainingClientBatches } from '../../types'

interface ItemAddingProps {
  remainingClientBatches: RemainingClientBatches // из этих данных надо понимать когда выбираем партию / кода автоматически подставляем / когда предлагаем выбрать все
  withPrintChz?: boolean
  isTSD?: boolean
  additionalStickerCount?: number
  openTareIsDefect?: boolean
  indicateItemCount: boolean
  addingItem: IAcceptanceSku | null
  setAddingItem: (item: IAcceptanceSku) => void
  items: ISupplyItem[]
  originalSkuItems: IAcceptanceSku[]
  updateOriginalSku: (newItems: IAcceptanceSku[]) => void
  docId: string
  currentTareId: string | null
  onClose: (isFinal?: boolean) => void
  onAddItemToTare: (newItem: ISupplyReceiptItem[]) => void
  blurOrFocusBarcodeInput?: (type: 'focus' | 'blur') => void

  alreadyUsedSerialNumbers: string[]
  setAlreadyUsedSerialNumbers: (serialNumbers: string[]) => void
  defectTypes: IDefectType[]
  acceptanceOption: ISelectOption | null
}

enum modalsEnum {
  defectQuestion = 'defectQuestion',
  weightAndDimensions = 'weightAndDimensions',
  selectBatch = 'selectBatch',
  scanSerial = 'scanSerial',
  itemCount = 'itemCount',
  selectMultiplicity = 'selectMultiplicity'
}

const getModalsArr = (
  addingItem: IAcceptanceSku | null,
  indicateItemCount?: boolean,
  savedWeightAndDimensions: string[] = [],
  isTareDefect?: boolean,
  acceptanceOption?: ISelectOption | null,
) => {
  /**
   * Модальные окна которые последовательно должны выводиться
   * в зависимотси от заданий, которые есть у добавляемого товара
   */

  const modalsArr: modalsEnum[] = []

  /** Задание на указание веса и габаритов */
  if (addingItem?.require_weight || addingItem?.require_dimensions) {
    const isSavedWeightAndDimensions = savedWeightAndDimensions.includes(
      addingItem.id,
    )
    if (!isSavedWeightAndDimensions) {
      modalsArr.push(modalsEnum.weightAndDimensions)
    }
  }

  /** Задание на указание причины дефекта */
  if (isTareDefect) {
    modalsArr.push(modalsEnum.defectQuestion)
  }

  /** Учет по партиям */
  if (addingItem?.batch_accounting) {
    modalsArr.push(modalsEnum.selectBatch)
  }

  /** Серийные номера */
  const itemHasSerialNumbersToScan =
    addingItem?.scan_serial_numbers?.length &&
    addingItem?.needScanSerialCodes?.length
  if (itemHasSerialNumbersToScan) {
    modalsArr.push(modalsEnum.scanSerial)
  }

  /** Открывать множественное добавление (выбор кол-ва товара),
   * если нет серийных номеров и учета по партиям */
  if (
    indicateItemCount &&
    !itemHasSerialNumbersToScan
  ) {
    modalsArr.push(modalsEnum.itemCount)
  }


  /** Выбор кратности */
  if (
    acceptanceOption?.value !== 'PIECE'
    && !itemHasSerialNumbersToScan
  ) {
    modalsArr.push(modalsEnum.selectMultiplicity)
  }

  return modalsArr
}

const ItemAdding = (props: ItemAddingProps) => {
  const {
    withPrintChz,
    isTSD = false,
    docId,
    openTareIsDefect,
    items: supplyItems,
    indicateItemCount,
    addingItem,
    setAddingItem,
    originalSkuItems,
    updateOriginalSku,
    currentTareId,
    onClose,
    onAddItemToTare,
    blurOrFocusBarcodeInput,
    alreadyUsedSerialNumbers,
    setAlreadyUsedSerialNumbers,
    defectTypes,
    acceptanceOption,
    remainingClientBatches,
    additionalStickerCount,
  } = props

  const {
    state: { savedItemsWeightAndDimensions },
    dispatch,
  } = useAcceptanceContext()

  const [multiplicity, setMultiplicity] = useState<string | null>('1')
  const [currentModal, setCurrentModal] = useState<modalsEnum | null>(null)
  const [count, setCount] = useState<string>('')

  const notification = useNotifications()
  const sound = useSound()

  useEffect(() => {
    blurOrFocusBarcodeInput?.('blur')
    return () => blurOrFocusBarcodeInput?.('focus')
  }, [])

  useEffect(() => {
    if (currentTareId === null) {
      notification?.show('alert', 'Нет открытой тары')
      onClose()
    } else {
      if (addingItem) {
        gotoNextStep({ result: [{ ...addingItem, quantity: 1 }] })
      }
    }
  }, [])

  const handleAdd = (newItems: IAcceptanceSku[]) => {

    onAddItemToTare(
      newItems.map((item: IAcceptanceSku) => {
        const supplyItem = supplyItems.find(
          (supItem) => supItem.sku_id === item.id,
        )
        return {
          id: supplyItem?.id || '',
          sku_id: item.id ?? null,
          sku_batch_id: item?.batch?.id ?? null,
          quantity: item.quantity,
          defect_type_id: item.defect_type_id,
          barcode_used: item.barcode_used,
          serial_numbers: [...(item?.scanned_serial_numbers ?? [])],
          acceptance_type: acceptanceOption?.value as AcceptanceType,
          multiplicity: Number(multiplicity || 1),
        }
      }),
    )
    onClose(true)
  }

  const gotoNextStep = ({ result }: { result: IAcceptanceSku[] }) => {
    const modalsArr = getModalsArr(
      addingItem,
      indicateItemCount,
      savedItemsWeightAndDimensions,
      openTareIsDefect,
      acceptanceOption,
    )

    const currentIndex = modalsArr.findIndex((m: string) => m === currentModal)
    const isNotEndModal = currentIndex < modalsArr.length - 1
    if (isNotEndModal) {
      setCurrentModal(modalsArr[currentIndex + 1])
    } else {
      handleAdd([...result])
    }
  }

  const handleSetWeightAndDimensions = () => {
    if (addingItem) {
      const isSavedWeightAndDimensions = savedItemsWeightAndDimensions.find(
        (s: string) => addingItem.id === s,
      )
      if (!isSavedWeightAndDimensions) {
        dispatch(setSavedItemsWeightAndDimensions(addingItem.id))
      }
      gotoNextStep({ result: [{ ...addingItem, quantity: 1 }] })
    }
  }

  const handleSelectDefectName = (defectId: string) => {
    if (addingItem) {
      const actualItem = { ...addingItem, defect_type_id: defectId }
      setAddingItem(actualItem)
      gotoNextStep({ result: [{ ...actualItem, quantity: 1 }] })
    }
  }

  const handleSelectBatch = (batch: IBatch, isNewBatch?: boolean) => {
    if (addingItem) {
      const actualItem = {
        ...addingItem,
        batch: batch,
        batches: isNewBatch
          ? [...(addingItem.batches || []), batch]
          : addingItem.batches,
      }
      setAddingItem(actualItem)

      if (isNewBatch) {
        updateOriginalSku(
          originalSkuItems?.map((item: IAcceptanceSku) => ({
            ...item,
            batches:
              item.id === addingItem.id
                ? [...(item.batches || []), batch]
                : item.batches,
          })) ?? [],
        )
      }

      gotoNextStep({ result: [{ ...actualItem, quantity: 1 }] })
    }
  }

  const handleChangeCount = (directCount?: number) => {
    if (addingItem) {
      const actualItem = {
        ...addingItem,
        quantity: directCount ? Number(directCount) : Number(count),
      }
      setAddingItem(actualItem)
      gotoNextStep({ result: [actualItem] })
    }
  }

  const handleScanSerial = (items: IAcceptanceSku[]) => {
    setAddingItem({ ...addingItem, scanned_serial_numbers: items[0]?.scanned_serial_numbers })
    gotoNextStep({ result: items })
  }
  const handleMultiplicity = (multiplicity: string | null, directCount?: number) => {
    if (addingItem) {
      const actualItem = {
        ...addingItem,
        multiplicity: Number(multiplicity || 1),
        quantity: addingItem.quantity ? Number(addingItem.quantity) : 1,
      }
      setAddingItem(actualItem)
      gotoNextStep({ result: [actualItem] })
    }
  }

  /** Партия, которую выбрал клиент в кактусе */
  const addingItemDefaultBatch = useMemo(() => {
    const supplyItem = supplyItems.find(
      (supItem) => supItem.sku_id === addingItem?.id,
    )
    return addingItem?.batches?.find(
      (batch) => batch.id === supplyItem?.sku_batch_id,
    )
  }, [addingItem, supplyItems])

  if (!addingItem) return null

  if (currentModal === modalsEnum.defectQuestion) {
    return (
      <SelectDefectModal
        isOpen={true}
        item={{
          title: addingItem.title,
          image: addingItem.image,
          barcode: addingItem.barcode_used,
        }}
        defectTypes={defectTypes}
        onSubmit={handleSelectDefectName}
        onClose={onClose}
      />
    )
  }

  if (currentModal === modalsEnum.weightAndDimensions) {
    return (
      <WeightDimensionsModal
        withClose
        isOpen
        item={addingItem}
        onClose={onClose}
        onSubmit={handleSetWeightAndDimensions}
        onMount={() => sound?.play('RECEIVING_WEIGHT_ITEM')}
      />
    )
  }

  if (currentModal === modalsEnum.selectBatch) {
    if (isMobile().any) {
      return (
        <TSDSelectBatchModalV2
          isOpen={true}
          sku={addingItem}
          onSubmit={(id, batch) => handleSelectBatch(batch)}
          onClose={onClose}
        />
      )
    }

    return (
      <SelectBatchModal
        isOpen={true}
        expdate={addingItem?.batch_expdate_mandatory}
        defaultSelectedBatch={addingItemDefaultBatch}
        remainingClientBatches={remainingClientBatches}
        item={addingItem}
        onSubmit={handleSelectBatch}
        onClose={onClose}
      />
    )
  }

  if (currentModal === modalsEnum.itemCount) {
    return (
      <ItemCountModal
        count={count}
        item={{
          ...addingItem,
          barcode: addingItem.barcode_used,
        }}
        isOpen={true}
        setCount={setCount}
        onItemAdding={handleChangeCount}
        onClose={onClose}
      />
    )
  }

  if (currentModal === modalsEnum.scanSerial) {
    return (
      <ItemScanSerialModal
        withPrintChz={withPrintChz}
        isTSD={isTSD}
        docId={docId}
        additionalStickerCount={additionalStickerCount}
        docType={DocumentTypeEnum.SUPPLY_PLAN}
        isOpen={true}
        onClose={onClose}
        itemId={addingItem.id}
        validateCallback={(code) => {
          if (code === ValidateCodesEnum.tareIsDefect) {
            return openTareIsDefect
          }
        }}
        itemImage={addingItem.image}
        itemTitle={addingItem.title}
        itemBarcodeUsed={addingItem.barcode_used}
        itemScanSerialNumbers={addingItem.scan_serial_numbers.filter(
          (serialNumber) =>
            addingItem?.needScanSerialCodes?.includes(serialNumber.code),
        )
        }
        onSubmit={(filledSerialNumbers) => {
          handleScanSerial([
            {
              ...addingItem,
              scanned_serial_numbers: filledSerialNumbers,
              quantity: 1,
            },
          ])
        }}
        alreadyUsedSerialNumbers={alreadyUsedSerialNumbers}
        setAlreadyUsedSerialNumbers={setAlreadyUsedSerialNumbers}
      />
    )
  }

  if (currentModal === modalsEnum.selectMultiplicity) {
    return (
      <SelectMultiplicityModal
        onClose={onClose}
        isOpen={true}
        multiplicity={multiplicity}
        setMultiplicity={setMultiplicity}
        onSubmit={handleMultiplicity}
      />
    )
  }

  return null
}

export default ItemAdding
