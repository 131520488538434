import api from '@shared/api'
import { ApiReqResult, IDeliveryContract, IPaginationFilter, PaginationResult } from 'src/interfaces'
import { useQuery } from 'react-query'
import { convertObjectToURLParams } from '@shared/helpers'

// http://wms-test.kak2c.ru/warehouse-api/delivery-contract
interface IParams extends IPaginationFilter {
  search?: string
  delivery_companies?: string[]
  merchant_ids?: string[]
}
const getDeliveryContractList = async (params?: IParams) => {
  const paramsStr = convertObjectToURLParams(params)
  const result = await api.get(`/delivery-contract${paramsStr}`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

interface IGetOrdersListResult extends ApiReqResult, PaginationResult {
  delivery_contracts: IDeliveryContract[]
}

const useGetDeliveryContractsList = (
  params?: IParams,
  callback?: (delivery_contract: IDeliveryContract[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IGetOrdersListResult>(
    [`getDeliveryContractList`, params],
    () => getDeliveryContractList(params),
    {
      onSuccess: ({ success, delivery_contracts }) => {
        if (success) {
          callback?.(delivery_contracts)
        } else {
          error?.()
        }
      },
      onError: error,
      onSettled: settled,
    },
  )
}
export default useGetDeliveryContractsList
