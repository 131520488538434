
// Тип ШК - общий
export const COMMON_BARCODE_TYPE = 'COMMON'


// ШК завершения грузового места
export const CLOSE_PACKAGING_BARCODE = '3PL_CLPL'

// ШК завершения траснпортного места
export const CLOSE_PALLET_BARCODE = '3PL_CLPLT'

// ШК завершения регистра
export const COMPLETE_REGISTER = 'CMP_RGS'

// ШК расширенного режима
export const ADVANCED_BARCODE = 'aEdu76Gfc76gvD'

// ШК годного товара
export const GOOD_CONDITION_BARCODE = '3PL_PLGOOD'

// ШК брака товара
export const DEFECT_CONDITION_BARCODE = '3PL_PLBAD'