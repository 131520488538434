import { useMutation } from 'react-query'
import api from '@shared/api'

import { IWHData } from '@pages/DirectoriesPage/pages/WarehouseSection/types'

const useUpdateWarehouse = () => {

  const updateWarehouse = async (params: IWHData) => {
    const result = await api.post('/warehouse', params);

    if (result.status === 200) {
      return result;
    }
  };

  return useMutation(updateWarehouse);
}

export default useUpdateWarehouse;
