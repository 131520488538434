import { IMoving } from 'src/interfaces'
import { Badge } from '@consta/uikit/Badge'
import { OperAndDocumPages } from '../../../../pagesType'
import { DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import { TableColumn } from '@consta/uikit/Table'
import { RETURN_STATUS_BADGE_TYPE, RETURN_STATUSES } from '@/interfaces/statuses'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'

export const generateRows = (document: IMoving[]) => {
  return document.map((doc) => ({
    id: doc.id,
    date: doc.create_ts,
    date_value: days(doc.create_ts).format(DATE_TIME_VIEW),
    documentNum: doc.id,
    merchant: doc.merchant,
    status: doc.status,
    status_value: RETURN_STATUSES[doc.status || ''],
    comment: doc?.warehouse_comment || '',
    items: doc.items,
    merchant_title: doc.merchant?.title,
  }))
}
export const generateColumns = ({
  handleEdit,
}: {
  handleEdit: (doc: IMoving) => void
}): TableColumn<any>[] => {
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({ row }) => {
        return <DateCell date={row.date} style={{ minWidth: '120px' }} />
      },
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({ row }) => {
        return (
          <DocumentNumCell
            page={OperAndDocumPages.movingForm}
            documentNum={row.documentNum}
          />
        )
      },
    },

    {
      title: 'Статус',
      sortable: true,
      accessor: 'status_value',
      renderCell: ({ row }) => {
        const status = row.status
        return (
          <>
            <Badge
              status={RETURN_STATUS_BADGE_TYPE[status]}
              label={RETURN_STATUSES[status]}
            />
          </>
        )
      },
    },
    {
      title: ' Комментарий',
      accessor: 'comment',
      sortable: true,
    },
  ]
}
