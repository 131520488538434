import React from 'react'
import { Service, ServiceGroup } from 'src/interfaces'
import { TableColumn } from '@consta/uikit/Table'
import styles from '../BillingServicesList.module.scss'
import { Button } from 'src/components'
import { IconEdit } from '@consta/icons/IconEdit'

export const generateRows = (services: Service[]) => {
  return services.map((service) => ({
    id: service.id,
    title: service.title,
    measure: service.measure || '-',
    idPas: service.code,
    group_Id: service.group_id,
    hrefRow: null
  }))
}

export const generateColumns = (
  {
    handleEdit,
  }: {
    handleEdit: (id: string) => void
  },
  groups: ServiceGroup[],
): TableColumn<any>[] => {
  return [
    {
      title: 'Наименование',
      sortable: true,
      accessor: 'title',
    },
    {
      title: 'IdPas',
      sortable: true,
      accessor: 'idPas',
    },
    {
      title: ' Ед. измерения ',
      accessor: 'measure',
    },
    {
      title: 'Редактирование',
      accessor: 'actions',
      align: 'right',
      renderCell: ({row}: any) => {
        const group = groups.find((el) => el.id === row.group_Id)

        return (
          <div className={styles.actionsBtns}>
            {group?.type === 'WITHOUT_CALCULATION' ? (
              <div className={styles.actionsBtns}>
                <Button
                  onlyIcon
                  view={'ghost'}
                  size={'s'}
                  iconLeft={IconEdit}
                  onClick={() => handleEdit(row.id)}
                />
              </div>
            ) : null}
          </div>
        )
      },
    },
  ]
}
