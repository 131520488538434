import React from 'react'
import cx from 'classnames'
import styles from './AdditionalParams.module.scss'
import DownloadDoc from '../DownloadDoc/DownloadDoc'

interface AdditionalParamsProps {}

const AdditionalParams = (props: AdditionalParamsProps) => {
  return (
    <div className={cx(styles.main)}>
      <DownloadDoc />
    </div>
  )
}
export default React.memo(AdditionalParams)
