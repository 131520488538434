import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, ISku, OrderPackingV2, StickerWithCopies } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { MOCK_GET_ORDER_PACKING_V2, MOCK_GET_ORDER_RELABELING_V2 } from '../../mocks/packing/getOrderPackingV2'
import { provideMock } from '../../mocks'
import { MOCK_GET_ORDER_PACKING_SKU_MARKETPLACE_V2 } from '../../mocks/packing/getOrderPackingV2_marketplace_sku_id'


interface Params {
  orderId: string,
  joinCode?: string
  print_invoice?: boolean
}

interface Result extends ApiReqResult {
  order: OrderPackingV2
  skus: ISku[]
  files_for_print: StickerWithCopies[]
}

const joinToPackingOrderV2 = async (params: Params) => {
  const result = await provideMock<Params, { data: Result }>(
    async () =>
      await api.post(`/packing-v2/order/${params.orderId}/join`, { join_code: params.joinCode, print_invoice: params.print_invoice }),
    params,
    [
      {
        key: 'orderId',
        value: 'mock2',
        result: { data: MOCK_GET_ORDER_PACKING_V2 },
        logData: 'Формирование паллет до перемаркировки'
      },
      {
        key: 'orderId',
        value: 'mock',
        result: { data: MOCK_GET_ORDER_PACKING_SKU_MARKETPLACE_V2 },
        logData: 'Маркетплейс id пробелма'
      },
      {
        key: 'orderId',
        value: 'mock_relabeling',
        result: { data: MOCK_GET_ORDER_RELABELING_V2 },
        logData: 'Перемаркировка заказа'
      },
      ],
  )()

  if (!result.data?.success) {
    throw result
  }
  return result.data
}


const useJoinToPackingOrderV2 = (
  options?: UseMutationOptions<Result, any, any>,
) => {
  return useMutation(
    joinToPackingOrderV2,

    options
  )
}

export default useJoinToPackingOrderV2
