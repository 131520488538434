import React, { useMemo, useState } from 'react'
import styles from './documents-params.module.scss'
import { Button, InputWrapper } from 'src/components'
import { useGetService, useGetServicesList } from 'src/hooks'

import { generateServicesColumns, generateServicesRows } from './utils'
import { DocumentsParams } from '../../../../../components'
import { useServicesFormContext } from '@pages/OperationsAndDocumentsPage/context/ServiceRenderedFormContext'
import days from 'dayjs'
import ServicesRenderedModal from '../../../ServicesRenderedModal/ServicesRenderedModal'
import { OperAndDocumPagesType } from '@pages/OperationsAndDocumentsPage'
import { useParams } from 'react-router-dom'
import { DATE_TIME_VIEW } from '@shared/const/date'

interface SupplyFormContentProps {}

const DocumentsParamsFields = (props: SupplyFormContentProps) => {
  const { page, id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()
  const { state } = useServicesFormContext()
  const [openModal, setOpenModal] = useState(false)
  const [servicesSearch, setServicesSearch] = useState<string>('')
  const {
    data: serviceData,
    isFetching: serviceLoading,
    refetch: refetchData,
  } = useGetService({
    serviceId: documentId,
  })
  const { data: services } = useGetServicesList({params: {search: servicesSearch}})

  const rows = useMemo(
    () =>
      generateServicesRows(
        state.documentRaw?.items || [],
        state.originalServices || [],
      ),
    [state.documentRaw?.items, state.originalServices],
  )

  const columns = useMemo(() => generateServicesColumns(), [])

  return (
    <>
      <DocumentsParams
        rows={rows}
        columns={columns}
        tableTitle={'Оказанные услуги'}
        tableExtraHeader={
          <Button label={'Изменить'} onClick={() => setOpenModal(true)} />
        }
      >
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.id || null}
          id={'id'}
          label={'Номер'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={days(state.documentRaw?.create_ts || null).format(DATE_TIME_VIEW)}
          id={'create_ts'}
          label={'Дата'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.merchant?.title || null}
          id={'org'}
          label={'Организация'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.executor?.name || null}
          id={'executor'}
          label={'Ответственный'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.comment || null}
          id={'comment'}
          label={'Комментарий'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.warehouse_comment || null}
          id={'warehouse_comment'}
          label={'Комментарий склада'}
          disabled
        />
      </DocumentsParams>
      {openModal ? (
        <ServicesRenderedModal
          services={services?.services}
          servicesTotalCount={services?.pagination?.total_elements}
          isOpen
          document={state.documentRaw}
          onClose={() => setOpenModal(false)}
          refetch={refetchData}
          setServicesSearch={setServicesSearch}
        />
      ) : null}
    </>
  )
}
export default DocumentsParamsFields
