import { useMutation } from 'react-query'

import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'
import { ICreateBilling } from './useCreateBilling'

/*
 http://wms-test.kak2c.ru/warehouse-api/billing/result/export-to-pas
 */

interface Result extends ApiReqResult {
  id: string
  n: number
}

const useBillingPas = () => {
  return useMutation(async (data: ICreateBilling) => {
    const path = `/billing/result/export-to-pas`
    const result = await api.post(path, data)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}

export default useBillingPas
