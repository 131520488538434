import { ITableColumn, ITableRow } from '@/interfaces'
import { NomenclatureCell } from '../../../../../components'
import React from 'react'
import { ImageCell, QuantityCell } from 'src/components/Table/cells'
import { ChangeFormArgType, PlacingFormData } from './types'

export const generateColumns = (): ITableColumn[] => {
  return [
    {
      key: 'condition',
      title: 'Годность',
      title_txt: 'Годность',
      gridCellWidth: '150px',
    },
    {
      key: 'img',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '150px',
      renderCell: ({ row }) => <ImageCell image={String(row.img)} />,
    },
    {
      key: 'name_value',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      renderCell: ({ row }) => (
        <NomenclatureCell title={row.name} itemId={`${row.id}` || '-'} />
      ),
    },
    {
      key: 'barcode',
      title: 'Штрихкод',
      title_txt: 'Штрихкод',
    },
    {
      key: 'planQuantity',
      title: 'Плановое кол-во',
      title_txt: 'Плановое кол-во',
      gridCellWidth: '150px',
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
      gridCellWidth: '150px',
    },
  ]
}
export const generateRows = (
  rows: ITableRow[],
  formData: PlacingFormData,
  handleChangeFormData: (data: ChangeFormArgType) => void
): ITableRow[] => {
  return rows.map((row) => {
    const formDataKey = `${row.id}${row.sku_batch_id||''}`
    const formDataValue = formData[formDataKey]
    return {
      ...row,
      planQuantity: row.quantity,
      quantity: (
        <QuantityCell
          id={formDataKey}
          errorTxt={
            formDataValue?.quantity > row.quantity ? `Максимум ${row.quantity}` : undefined
          }
          skuQuantity={row.quantity}
          quantityValue={formDataValue?.quantity}
          handleSetPrintQuantity={(itemId, value) => {
            handleChangeFormData({
              quantity: value,
              planQuantity: row.quantity,
              sku_id: row.id,
              sku_batch_id: row.sku_batch_id,
            })
          }}
        />
      ),
    }
  })
}
