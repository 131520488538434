import api from '@shared/api'
import { useQuery } from 'react-query'

import { ApiReqResult, IOrderBatch } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'


/*
 * Получение батча
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Batchi-zakazov/operation/getOrderBatch
 */
/**
 * Это метод точно такой же как getOrderBatch, но использует useQuery
 */

export interface IGetBatchParams {
  batchId: string
}

export interface IGetBatchResult extends ApiReqResult {
  batch: IOrderBatch
}

const useGetBatch = (
  params: IGetBatchParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IGetBatchResult>(
    [`getBatch`, params],
    async () => {
      const result = await api.get(`/order-batch/${params.batchId}`)
      return result.data
      // return MOCK_BATCH
    },
    options,
  )
}

export default useGetBatch
