import React, { useEffect } from 'react'

import styles from './sticker-error-modal.module.scss'

import img from './assets/error.svg'

import { IconRestart } from '@consta/icons/IconRestart'
import { Button } from '@consta/uikit/Button'

import { Modal } from 'src/components'

import { usePrinter } from '@shared/providers/PrinterProvider'
import { useNotifications } from '@shared/providers/NotificationProvider'

import { ModalProps } from 'src/interfaces'

interface IStickerErrorProps extends ModalProps {
  sticker: {
    content: string
    printer: string
  } | null
  showStickerPrint?: (value: boolean) => void
  isFinal?: boolean
}

const StickerErrorModal = (props: IStickerErrorProps) => {
  const { isOpen, onClose, sticker, showStickerPrint, isFinal, onMount } = props
  const printer = usePrinter()
  const notification = useNotifications()

  const handleRepeat = async () => {
    if (sticker && sticker.content && sticker.printer) {
      await printer?.setPrinter(sticker.printer)

      printer?.print(sticker.content, {
        onSuccess: () => {
          notification?.show('success', 'Стикер успешно отправлен на печать')
          if (isFinal && showStickerPrint) {
            showStickerPrint(true)
          }
          onClose()
        },
        onError: (err) => {
          notification?.show('alert', 'Ошибка печати стикера')
          console.error('STICKER PRINT ERROR:')
          console.error(err)
        },
      })
    }
  }

  useEffect(() => {
    if (onMount) {
      onMount()
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.sticker}
      title="Ошибка при печати"
      subtitle="Повторите печать или позовите старшего смены"
      img={img}
      imgClass={styles.img}
      size="s"
    >
      <div className={styles.btns}>
        <Button
          label="Повторить"
          view="primary"
          iconLeft={IconRestart}
          className={styles.primary}
          onClick={handleRepeat}
          size="l"
        />
        <Button
          label="Закрыть"
          view="ghost"
          onClick={() => onClose()}
          size="l"
        />
      </div>
    </Modal>
  )
}

export default StickerErrorModal
