import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

/*
  Добавление транспортные места в отгрузку
  wiki:
  https://wiki.kak2c.ru/link/785#bkmrk-%2Fshipment%2F%7Bid%7D%2Fitem
*/

interface IParams {
  shipmentId: string
}
interface IResult extends ApiReqResult {
  results: { n: 1; success: boolean }[]
}

const useAddTransportPlacesToShipment = (
  params: IParams,
  callback?: () => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useMutation(
    async (transportPlacesIds: string[]) =>
      api.post(`/shipment/${params.shipmentId}/item`, transportPlacesIds),
    {
      onSuccess: ({ data: { success, results } }: { data: IResult }) => {
        if (success) {
          callback?.()
        } else {
          error?.()
        }
      },
      onError: error,
      onSettled: settled,
    },
  )
}

export default useAddTransportPlacesToShipment
