import React, { useEffect, useState } from 'react'
import { getListPageByFormPage, OperAndDocumPagesType, sidebarPagesTabs } from '../../pagesType'
import { useParams } from 'react-router-dom'
import { ITab } from 'src/interfaces'
import { SidebarNav } from 'src/components'
import { useMainContext } from '../../context/MainContext'
import { useHistoryPush } from '../../hooks/useHistoryPush'


const Sidebar = () => {
  const { historyPush } = useHistoryPush()
  const { page } = useParams<{ page: OperAndDocumPagesType }>()
  const {state,dispatch} = useMainContext()

  const [selectedTab, selectTab] = useState<ITab | null>(null)

  useEffect(() => {
    const listPage = getListPageByFormPage(page)
    const correctTab = sidebarPagesTabs.find(tabItem =>
      tabItem.value === page ||
      tabItem.value === listPage,
    )
    selectTab(correctTab || null)
  }, [page])

  const pushTab = (tab: ITab) => {
    selectTab(tab)
    historyPush(tab.value as OperAndDocumPagesType)
  }

  return (
    <SidebarNav
      selectedTab={selectedTab}
      items={sidebarPagesTabs}
      onClickTab={(e) => {
        // todo придумать как исправить
        // @ts-ignore
        const selectedLabel = e.target.innerText
        const selectedTab = sidebarPagesTabs.find(tab => tab.label === selectedLabel)
        if (selectedTab) {
          pushTab(selectedTab)
        }
      }}
    />
  )
}

export default Sidebar
