import { IRefund, IRefundEditing, IRefundPlace, IRefundPlanItem, IRefundSku } from '@/interfaces/refund/refund'
import { ConditionEnum, IContainer } from '@/interfaces'
import { ProblemContainersType } from '../types'

export const findPlanItemByBarcode = (barcode: string, planItems: IRefundPlanItem[], skus:IRefundSku[]): {items: IRefundPlanItem[] | null, sku:IRefundSku | null} => {
  const sku = skus.find(s => s.barcodes.some(b =>  b.barcode === barcode))
  const items = sku && planItems.filter(i => i.sku_id === sku.id)
  return sku && items && items.length ? {sku, items} : {sku: null, items: null}
}


export const getAlreadyUsedSerialNumbers = (places: IRefundPlace[]) => {
  return places
    .map((p) =>
      p.items.map((i) =>
        i.serial_numbers?.map((serial) => serial.value) || [],
      ) || [],
    )
    .flat(3) || []
}


export interface ITareData {
  tare_barcode: string
  merchant_id: string | null
  is_substitution: boolean
}

export const getTareBarcodesFromRefund = (places: IRefundPlace[]): ITareData[] => {
  let tares:ITareData[] = []
  places.forEach(
    place => place.items.forEach(item => {
      if (item.tare_barcode && !tares.find(t => t.tare_barcode === item.tare_barcode)) {
        tares.push({
          tare_barcode: item.tare_barcode,
          merchant_id: place.merchant?.id || null,
          is_substitution: Boolean(item.is_substitution)
        })
      }
    })
  )
  return tares
}

export const convertRefundForPost = (refund: IRefund): IRefundEditing => ({
  comment: refund.warehouse_comment,
  delivery_code: refund.delivery_company,
  external_id: refund.external_id || '',
  status: refund.status
})


export const deleteUriData = (base64 : string): string => base64.replace(/^data:.*base64,/, '')

export const checkProblemOpenTares = (containers: IContainer[] = [], places: IRefundPlace[] = []): {error: boolean, problemTares: ProblemContainersType} => {
  const barcodes = getTareBarcodesFromRefund(places);
  const problemTares = containers.reduce((acc, container) => {
    if (!container.open) return acc
    const barcode = barcodes.find(bc => bc.tare_barcode === container.barcode)
    if (barcode?.is_substitution)
      acc.substitution.push(container)
    if (container.condition === ConditionEnum.GOOD)
      acc.good.push(container)
    if (container.condition === ConditionEnum.DEFECT && !barcode?.is_substitution)
      acc.defect.push(container)
    return acc
  }, {substitution: [], good: [], defect: []})

  const hasError = Object.values(problemTares).some(item => item.length >= 2)
  return {error: hasError, problemTares: problemTares}
}