import { useMutation } from 'react-query'
import api from '@shared/api'

// http://wms-test.kak2c.ru/warehouse-api/receiving/supply/{supply_id}/file/{file_id}

interface IParams {
  fileId: string
  supplyId: string
}
const useDeleteSupplyFile = () => {
  const deleteSupplyFile = async ({ fileId, supplyId }: IParams) => {
    const result = await api.delete(
      `/receiving/supply/${supplyId}/file/${fileId}`,
    )

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(deleteSupplyFile)
}

export default useDeleteSupplyFile
