import React from 'react'
import cx from 'classnames'
import styles from './download-acts.module.scss'
import { DownloadDocsTable } from '../../../../../components'
import { useGetActOfAcceptanceMX1, useGetActOfDivergenceTORG2 } from 'src/hooks'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { saveBase64AsPdf } from '@shared/helpers'

interface DownloadActsProps {

}

const DownloadActs = (props: DownloadActsProps) => {
  const { id: supplyId } = useParams<{ page: OperAndDocumPagesType, id: string, tabId: string }>()

  const { isFetching: isFetchingMX1, data: dataMX1, refetch: refetchMX1 } = useGetActOfAcceptanceMX1(
    { supplyId: supplyId },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: ({content}) => {
        saveBase64AsPdf({ content, fileName: 'Акт MX-1.pdf' })
      }
    }
    )
  const { isFetching: isFetchingTORG2, data: dataTORG2, refetch: refetchTORG2 } = useGetActOfDivergenceTORG2(
    { supplyId: supplyId },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: ({content}) => {
        saveBase64AsPdf({ content, fileName: 'Акт ТОРГ-2.pdf' })
      }
    }
    )

  const filesRow = [
    {
      id: 'mx-1',
      name: 'Акт MX-1',
      action: () => {
        refetchMX1();
        console.log('Акт MX-1')
      },
    },
    {
      id: 'torg-2',
      name: 'Акт ТОРГ-2',
      action: () => {
        refetchTORG2();
        console.log('Акт ТОРГ-2')
      },
    },
  ]

  const downloadFiles = (filesKeys: string[]) => {
    filesKeys.map(key => {
      const currFileRow = filesRow.find(row => row.id === key)
      currFileRow?.action()
    })
  }

  return (
    <div className={cx(styles.main)}>
      <DownloadDocsTable
        title={'Акты'}
        handleClickDownload={downloadFiles}
        tableRows={filesRow}
        loading={isFetchingMX1 || isFetchingTORG2}
      />
    </div>
  )
}

export default DownloadActs
