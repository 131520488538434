import {
  REVERT_FORM_STATE_TO_RAW,
  SET_CELLS,
  SET_ORIGINAL_SKUS,
  SET_PLACING_FORM_STATE_DRAFT,
  SET_PLACING_FORM_STATE_RAW,
  SET_PLACING_RAW,
  UPDATE_FORM_STATE_DRAFT,
  UPDATE_FORM_STATE_RAW,
} from '../actions'
import { IReducerAction } from 'src/interfaces'
import { cloneDeep } from '@shared/helpers'
import { IPlacingFormState } from '../interfaces'

export const placingInitialState: IPlacingFormState = {
  documentRaw: null,
  formStateRaw: null,
  formStateDraft: null,
  originalSkus: [],
  cells: [],
}

export const placingReducer = (
  state: IPlacingFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    /** Общие кейсы */

    case SET_PLACING_RAW:
      return { ...state, documentRaw: payload }

    case SET_CELLS:
      return { ...state, cells: payload }

    case SET_PLACING_FORM_STATE_DRAFT:
      return { ...state, formStateDraft: payload }

    case SET_PLACING_FORM_STATE_RAW:
      return { ...state, formStateRaw: payload }

    case UPDATE_FORM_STATE_DRAFT:
      return {
        ...state,
        formStateDraft: {
          ...(state.formStateDraft || {}),
          [payload.key]: payload.value,
        },
      }

    case UPDATE_FORM_STATE_RAW:
      return {
        ...state,
        formStateRaw: {
          ...(state.formStateRaw || {}),
          [payload.key]: payload.value,
        },
      }

    case REVERT_FORM_STATE_TO_RAW:
      return {
        ...state,
        formStateDraft: cloneDeep(state.formStateRaw),
      }

    case SET_ORIGINAL_SKUS:
      return { ...state, originalSkus: payload }

    default:
      return state
  }
}
