import { usePrint } from './print'
import { useAppContext } from '@shared/providers/AppContextProvider'
import { useGetDocsForPacking } from '@/hooks'
import { usePreviewBase64Pdf } from '@shared/hooks'


/**
 * Печатает / открывает просмотр инвойсы заказов
 */

interface printOrderInvoicesResult {
  printOrderInvoices: (orderIds: string[], isPreview?: boolean) => Promise<void>
}

export const usePrintOrderInvoices = (): printOrderInvoicesResult => {
  const { setGlobalLoading } = useAppContext()
  const { printSticker } = usePrint()
  const { fetch: getDocsForPacking } = useGetDocsForPacking()
  const { previewBase64Pdf } = usePreviewBase64Pdf()

  const printOrderInvoices = async (ordersIds: string[], isPreview?: boolean) => {
    setGlobalLoading(true)
    try {
      const { content, printer } = await getDocsForPacking({ ordersIds })
      if (content && printer) {
        if (isPreview) {
          previewBase64Pdf(content, 'Инвойсы заказов')
        } else {
          await printSticker(printer, content)
        }
      }
    } finally {
      setGlobalLoading(false)
    }
  }
  return {
    printOrderInvoices,
  }
}