import React from 'react'
import { ICellPlace, IMovingItem, ISku, ITableColumn, ITableRow } from 'src/interfaces'
import { ImageCell } from 'src/components'
import { NomenclatureCell } from '@pages/OperationsAndDocumentsPage/components'

export const generateMovingColumns = (hasDefect: boolean): ITableColumn[] => {
  return [
    {
      key: 'image',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '150px',
      renderCell: ({ row }: any) => {
        return <ImageCell image={row.img} />
      },
    },
    {
      key: 'title',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: '2fr',
      renderCell: ({ row }) => (
        <NomenclatureCell
          title={row.title}
          itemId={String(row.sku_id) || '-'}
        />
      ),
    },
    hasDefect ? {
      key: 'defectType',
      title: 'Тип брака',
      title_txt: 'Тип брака',
    } : null,
    {
      key: 'barcode_from',
      title: 'Из ячейки',
      title_txt: 'Из ячейки',
    },
    {
      key: 'barcode_to',
      title: 'В ячейку',
      title_txt: 'В ячейку',
    },
    {
      key: 'quantity',
      title: 'Количество',
      title_txt: 'Количество',
    },
  ].filter(Boolean)
}

export const generateMovingRows = (
  items: IMovingItem[],
  skus: ISku[],
  cells: ICellPlace[],
): ITableRow[] => {
  return items.map((item) => {
    const sku = skus.find((el) => el.id === item.sku_id)
    const cellFrom = cells.find((el) => el.barcode === item.from_cell_barcode)
    const cellTo = cells.find((el) => el.barcode === item.to_cell_barcode)
    return {
      id: item.sku_batch_id || '',
      title: sku?.title || '',
      img: sku?.image || sku?.image_preview,
      defectType: item.defect_type?.title,
      sku_id: item.sku_id,
      barcode_from: cellFrom?.title || '',
      barcode_to: cellTo?.title || '',
      quantity: item.quantity || '',
    }
  })
}
