import React from 'react'
import cx from 'classnames'
import styles from './history-changes.module.scss'
import HistoryChangesTable from '../../HistoryChangesTable/HistoryChangesTable'
import { useGetLogChange } from 'src/hooks'

interface HistoryChangesProps {
	documentUUID?: string
}

const HistoryChanges = (props: HistoryChangesProps) => {
	const {documentUUID} = props

	const {data: logChangesData, isFetching } = useGetLogChange(
		{ params: {entity_id: documentUUID || 'mock'} },
	)

	return (
		<div className={cx(styles.main)}>
			<HistoryChangesTable
				changes={logChangesData?.changes}
				isLoading={isFetching}
			/>
		</div>
	);
};
export default HistoryChanges
