import api from '@shared/api'
import { convertObjectToURLParams } from '@shared/helpers'

import { ApiReqResult, IOrder, IOrderPackagingOption } from 'src/interfaces'

interface GetOrderByPlaceBarcodeParams {
  placeBarcode: string,
  enabled?: boolean,
  notShowErrors?: boolean
}

const getOrder = async ({ placeBarcode, notShowErrors }: GetOrderByPlaceBarcodeParams) => {
  const params = convertObjectToURLParams({barcode: placeBarcode})
  const result = await api.get(`/packing/order/barcode${params}`, {notShowErrors})
  if (!result.data?.success) {
    if (notShowErrors) {
      return
    }
    throw result
  }
  return result.data
}

interface GetOrdersByPlaceBarcodeResult extends ApiReqResult {
  order: IOrder,
  packagings: IOrderPackagingOption[]
}

const useGetOrderByPlaceBarcode = (
  params?: GetOrderByPlaceBarcodeParams,
  callback?: (order: IOrder) => void,
  error?: () => void,
  settled?: () => void,
) => ({
  refetch: async (params: GetOrderByPlaceBarcodeParams) => {
    try {
      const data = await getOrder(params)
      return { order: data?.order, packagings: data?.packagings }
    } catch (err) {
      console.log('error', err)
    }
  }
});

export default useGetOrderByPlaceBarcode
