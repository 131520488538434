import React, { useState } from 'react'

import styles from './MultipleCopiesPrintingModal.module.scss'
import { InputWrapper, ItemPreview, Modal } from '@/components'

import { IAcceptanceSku, ISupplyReceiptItem, ModalProps } from '@/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { Button } from '@consta/uikit/Button'

interface IProps extends ModalProps {
  item: ISupplyReceiptItem
  itemOriginal?: IAcceptanceSku
  onSubmit: (item: ISupplyReceiptItem, repeatQuantity: number) => void
}

const MultipleCopiesPrintingModal = (props: IProps) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    item,
    itemOriginal
  } = props

  const [quantity, setQuantity] = useState<TextFieldPropValue>('')

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      title={'Печать стикеров товара'}
      titleAlign={'left'}
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size="m"
    >
      <div className={styles.body}>
        {
          itemOriginal ? (
            <ItemPreview
              image={itemOriginal.image}
              title={itemOriginal.title}
              barcode={''}
            />
          ) : null
        }
        <p className={styles.quantity}>Кол-во товаров: {item.quantity}</p>
        <div className={styles.inputs}>
          <InputWrapper
            isInteger
            id={'quantity'}
            autoFocus
            handleChange={setQuantity}
            value={quantity}
            label={'Кол-во стикеров'}
            className={styles.countInput}
          />
        </div>
        <div className={styles.btns}>
          <Button
            label={'Отменить'}
            view={'ghost'}
            onClick={onClose}
            size={'m'}
          />
          <Button
            label={'Напечатать'}
            view={'primary'}
            disabled={!Number(quantity)}
            onClick={() => onSubmit(item, Number(quantity))}
            size={'m'}
          />
        </div>
      </div>
    </Modal>
  )
}

export default MultipleCopiesPrintingModal
