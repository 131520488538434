import { REVERT_FORM_STATE_TO_RAW, UPDATE_FORM_STATE_DRAFT, UPDATE_FORM_STATE_RAW } from '../actions'
import { IReducerAction } from 'src/interfaces'
import { cloneDeep } from '@shared/helpers'
import { IShipmentPlanFormState } from '../interfaces/shipmentPlan'
import { SET_PLAN_FORM_STATE_DRAFT, SET_PLAN_FORM_STATE_RAW, SET_PLAN_RAW } from '../actions/shipmentPlan'

export const shipmentPlanInitialState: IShipmentPlanFormState = {
  documentRaw: null,
  formStateRaw: null,
  formStateDraft: null,
}

export const shipmentPlanReducer = (
  state: IShipmentPlanFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    /** Общие кейсы */

    case SET_PLAN_RAW:
      return { ...state, documentRaw: payload }

    case SET_PLAN_FORM_STATE_DRAFT:
      return { ...state, formStateDraft: payload }

    case SET_PLAN_FORM_STATE_RAW:
      return { ...state, formStateRaw: payload }

    case UPDATE_FORM_STATE_DRAFT:
      return {
        ...state,
        formStateDraft: {
          ...(state.formStateDraft || {}),
          [payload.key]: payload.value,
        },
      }

    case UPDATE_FORM_STATE_RAW:
      return {
        ...state,
        formStateRaw: {
          ...(state.formStateRaw || {}),
          [payload.key]: payload.value,
        },
      }

    case REVERT_FORM_STATE_TO_RAW:
      return {
        ...state,
        formStateDraft: cloneDeep(state.formStateRaw),
      }

    default:
      return state
  }
}
