import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IAcceptanceSku, ISupplyReceipt, TrustBox } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'


export interface IGetReceiptParams {
  receiptId: string
}

export interface IGetReceiptResult extends ApiReqResult {
  skus: IAcceptanceSku[]
  receipt: ISupplyReceipt

  trust_acceptance_boxes: TrustBox[]
}

const useGetReceipt = (
  params: IGetReceiptParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IGetReceiptResult>(
    [`getReceipt`, params],
    async () => {
      const result = await api.get(`/receiving/receipt/${params.receiptId}`)
      return result.data
    },
    options
  )
}

export default useGetReceipt
