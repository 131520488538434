import { useMutation } from 'react-query'

import api from '@shared/api'


const useDeleteOrderBatch = () => {
  return useMutation(async (batchId: string) => {
    const result = await api.delete(`/order-batch/${batchId}`)
    if (!result?.data?.success) {
      throw new Error(result.data.message)
    }
    return result.data
  })
}

export default useDeleteOrderBatch
