import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './documents-list-wrapper.module.scss'
import { Loader } from '@consta/uikit/Loader'
import { DocumentsListWrapperProps, Filters } from '..'
import cx from 'classnames'
import { TablePagination, TableWithCopy } from 'src/components'
import { useHistoryPush } from '../../hooks/useHistoryPush'
import { listPostfix, OperAndDocumPagesType } from '../../pagesType'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { PATHS } from '@shared/routing'

const DocumentsListWrapper: FC<DocumentsListWrapperProps> = (props) => {
  const { page: listPage } = useParams<{ page: OperAndDocumPagesType }>()
  const formPage = listPage.replace(listPostfix, '')

  const history = useHistory()

  const {
    withCopy = true,
    pagination,
    loading,
    rows,
    columns,
    title,
    filterState,
    setFilterState,
    filtersConfig,
    searchPlaceholder = 'Поиск документа',
    inputSearchFilterKey,
    tableClassName,
    customExtraHeader,
    tableExtraHeaderLeft,
    tableExtraHeaderRight,
  } = props

  const tableRef = useRef<HTMLTableElement>()

  const { handlePushBreadcrumb } = useHistoryPush()

  /** Стейт для данных до нажатия на поиск */
  const [filterStateDraft, setFilterStateDraft] = useState<Record<string, any>>({})

  useEffect(() => {
    setFilterStateDraft(filterState)
  }, [filterState])


  const scrollTableTop = () => {
    if (tableRef?.current) {
      tableRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    }
  }

  const handleChangeFilter = (fieldId: string, value: any) => {
    /**
     * Обновляем и черновик, и данных фильтров для обновления данных.
     * Используется при закрытии тега фильтра
     */
    setFilterState(prevState => ({ ...prevState, [fieldId]: value }))
  }
  const handleChangeFilterDraft = (fieldId: string, value: any) => {
    /** Обновляем черновик фильтра */
    setFilterStateDraft(prevState => ({ ...prevState, [fieldId]: value }))
  }
  const handleSubmitFilter = () => {
    /** Переводим черновой фильтр в основной, для обновления данных */
    setFilterStateDraft({ ...filterStateDraft, page: 0 })
    setFilterState({ ...filterStateDraft, page: 0 })
    scrollTableTop()
  }
  const handleClearFilter = () => setFilterState({})

  const correctRows = useMemo(() => {
    return rows.map((row, i) => {
      const lastIndex = (pagination?.size || 0) * (pagination?.page || 0)
      return {
        hrefRow: generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, { page: formPage, id: row.id }),
        ...row,
        numbering: lastIndex+i+1,
      }
    })
  }, [formPage, pagination?.page, pagination?.size, rows])

  const correctColumns = useMemo(() => {
    const formattedColumns = columns.reduce((acc, column) => [
      ...acc,
      {
        key: column.accessor,
        ...column
      },
    ], [] as any[])
    return [
      {
        key: 'numbering',
        title: "№",
        title_txt: "№",
        gridCellWidth: '30px',
        thClassName: styles.numberingCell,
        tdClassName: styles.numberingCell,
      },
      ...formattedColumns,
    ]
  }, [columns])

  const handleOpenLink = useCallback(
    (row) => handlePushBreadcrumb(listPage, row.docId || row.id),
    [listPage]
  )

  const handleGenerateRowCopyValue = useCallback(
    (columns, row) => {
      return columns.map(column => {
        // @ts-ignore
        const value = row[column.key]
        return value !== undefined ? String(value) : ''
      })
    },
    [],
  )

  const extraHeader = useMemo(() => {
    return (
      customExtraHeader
        ? customExtraHeader
        : (
          <div>
            <div className={styles.extraHeaderLeft}>
              {tableExtraHeaderLeft}
            </div>
            <div className={styles.extraHeaderRight}>
              {tableExtraHeaderRight}
            </div>
          </div>
        ))
  }, [customExtraHeader, tableExtraHeaderLeft, tableExtraHeaderRight])

  const handleChangePage = (page: number) => {
    setFilterState(prev => ({...prev, page }))
    scrollTableTop()
  }

  return (
    <div className={styles.main}>
      <h2 className={styles.title}>{title}</h2>
      <Filters
        inputSearchFilterKey={inputSearchFilterKey}
        searchPlaceholder={searchPlaceholder}
        filterState={filterState}
        filterStateDraft={filterStateDraft}
        handleChangeFilter={handleChangeFilter}
        handleChangeFilterDraft={handleChangeFilterDraft}
        handleSubmit={handleSubmitFilter}
        handleClear={handleClearFilter}
        filtersConfig={filtersConfig}
      />
      <div className={styles.tableWrapper}>
        {loading ? <Loader className={styles.loader} /> : null}
        <TableWithCopy
          tableRef={tableRef}
          withCopy={withCopy}
          rows={correctRows}
          columns={correctColumns}
          onOpenLink={handleOpenLink}
          extraHeader={extraHeader}
          tableWrapperClassName={cx(styles.table, tableClassName)}
          generateRowCopyValue={handleGenerateRowCopyValue}
        />
      </div>
      <div className={styles.footer}>
        {
          pagination ? (
             <TablePagination
              form={'round'}
              currentPage={pagination.page || 0}
              totalPages={pagination.total_pages || 0}
              onChangePage={handleChangePage}
            />
          ) : null
        }
      </div>
    </div>
  )
}

export default DocumentsListWrapper
