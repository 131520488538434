import { SETTINGS_LOCAL_STORAGE_NAME } from '@shared/const/common'
import { LocalStorage } from './localStorage'


/* LS который сохраняется и после выхода из системы */
class DefaultSettingsLS extends LocalStorage {
  get defaultBase() {
    return this.getData('defaultBase')
  }

  set defaultBase(b: string) {
    this.setData('defaultBase', b)
  }

  get defaultWorkplace() {
    return this.getData('defaultWorkPlace')
  }

  set defaultWorkplace(v: string) {
    this.setData('defaultWorkPlace', v)
  }

  get defaultArm() {
    return this.getData('defaultArm')
  }

  set defaultArm(v: string) {
    this.setData('defaultArm', v)
  }

  set newDesign(newDesign: boolean) {
    this.setData('newDesign', Number(newDesign))
  }
  get newDesign() {
    return this.getData('newDesign')
  }
}
export const defaultSettingsLS = new DefaultSettingsLS(SETTINGS_LOCAL_STORAGE_NAME)