import React, { useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'
import { formStatesAreEqual } from '../../../store/utils'
import { revertDraftStateToRawAction } from '../../../store/actions'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW, DATETIME_REQUEST_FORMAT } from '@shared/const/date'
import { FormWrapper } from '../../../components'
import { PATHS } from '@shared/routing'
import { OrderShippedFormContent } from './components'
import useGetPackingById from '@/hooks/packing/useGetPackingById'
import {
  setOrderFormDraftAction,
  setOrderFormRawAction,
  setOrderRawAction,
} from '@pages/OperationsAndDocumentsPage/store/actions/order'
import { OrderFormContextProvider, useOrderFormContext } from '../../../context/OrderFormContext'
import { IOrderFormDataState } from '../../../store/interfaces/order'
import { Badge } from '@consta/uikit/Badge'
import { useEditOrder } from 'src/hooks'
import { useNotifications } from '@shared/providers/NotificationProvider'
import useGetDeliveryContractsList from '@/hooks/warehouse/delivery/useGetDeliveryContractsList'
import { useAppContext } from '@shared/providers/AppContextProvider'

const OrderShippedForm = () => {
  const history = useHistory()
  const { state, dispatch } = useOrderFormContext()
  const notification = useNotifications()

  const {
    setGlobalLoading,
  } = useAppContext()

  const { id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()

  const {
    refetch: refetchData,
    data: documentData,
    isFetching: isDocumentLoading,
  } = useGetPackingById(
    { orderId: documentId, type: 'docs' },
    (items, places, shippingForm, order) => {
      if (!order) {
        return
      }
      dispatch(setOrderRawAction(order))
    },
  )

  const { data: deliveryContractsData } = useGetDeliveryContractsList()
  const { isLoading: addLoading, mutateAsync: editOrder } = useEditOrder()

  useEffect(() => {
    if ((documentData !== undefined) && deliveryContractsData) {
      const delivery = documentData.order?.delivery
      const address = delivery?.address
      const delivery_contract = deliveryContractsData?.delivery_contracts?.find(contract => {
        return contract.id === delivery?.delivery_contract_id
      })
      const formData: IOrderFormDataState = {
        status: documentData?.order?.status || '',
        comment: documentData?.order?.warehouse_comment || '',

        disable_billing: documentData?.order?.disable_billing,
        phone: documentData.order?.customer?.phone || '',
        name: documentData.order?.customer?.name || '',
        surname: documentData.order?.customer?.surname || '',

        area: address?.area || '',
        block: address?.block || '',
        building: address?.building || '',
        city: address?.city || '',
        country: address?.country || '',
        flat: address?.flat || '',
        floor: address?.floor || 0,
        house: address?.house || '',
        region: address?.region || '',
        settlement: address?.settlement || '',
        street: address?.street || '',
        zip: address?.zip || '',
        delivery_contract: { label: delivery_contract?.title, id: delivery_contract?.id },
        delivery_date_plan:
          delivery?.delivery_date_plan || '',
        delivery_time_plan_from:
          delivery?.delivery_time_plan_from || '',
        delivery_time_plan_to:
          delivery?.delivery_time_plan_to || '',
        shipping_form: documentData.order.shipping_form || [],
        shipping_date_plan: days(
          delivery?.shipping_date_plan,
        ).format(DATETIME_REQUEST_FORMAT),
        postamat_num: delivery?.postamat_num || '',
      }
      dispatch(setOrderFormDraftAction(cloneDeep(formData)))
      dispatch(setOrderFormRawAction(cloneDeep(formData)))
    }
  }, [documentData, deliveryContractsData])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.ordersShippedList,
      }),
    )
  }

  const draftData = state.formStateDraft

  const handleClickSave = async () => {
    let fullAddress = `${draftData?.city} 
                       ${draftData?.settlement} 
                       ${draftData?.street} 
                       ${draftData?.house}`.replace(/  +/g, ' ')

    const shipping = draftData?.shipping_form?.map((el) => ({
      id: el.id,
      value: el.value,
    }))
    const data = {
      delivery: {
        address: {
          area: draftData?.area,
          block: draftData?.block,
          building: draftData?.building,
          city: draftData?.city,
          country: draftData?.country,
          flat: draftData?.flat,
          floor: draftData?.floor,
          full_address: fullAddress,
          house: draftData?.house,
          region: draftData?.region,
          settlement: draftData?.settlement,
          street: draftData?.street,
          zip: draftData?.zip,
        },
        delivery_contract_id: draftData?.delivery_contract?.id,
        delivery_date_plan: draftData?.delivery_date_plan,
        delivery_time_plan_from: draftData?.delivery_time_plan_from,
        delivery_time_plan_to: draftData?.delivery_time_plan_to,
        postamat_num: draftData?.postamat_num,
        shipping_date_plan: draftData?.shipping_date_plan,
      },
      disable_billing: draftData?.disable_billing,
      shipping_form: shipping,
      receiver: {
        name: draftData?.name,
        phone: draftData?.phone,
        surname: draftData?.surname,
      },
    }

    await editOrder({ orderId: documentId, data })
      .then(() => {
        notification?.show('success', 'Документ успешно сохранен', {
          soundName: 'PACKING_COMPLETED',
        })
        refetchData()
      })
      .catch(() => {
      })
  }

  const statesAreEqual = formStatesAreEqual<IOrderFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )
  const dateFormat = DATE_TIME_VIEW
  return (
    <FormWrapper
      loading={isDocumentLoading || addLoading}
      title={state.documentRaw?.id || documentId}
      subtitle={
        state.documentRaw
          ? <>
            {`от ${days(state.documentRaw.create_ts).format(dateFormat)}`}
            {
              state.documentRaw?.cancel_items_confirmed
                ? <span style={{ color: 'red', display: 'block' }}>Отмена позиций подтверждена</span>
                : ''
            }
            {
              state.documentRaw?.paused_at
                ? <span style={{
                  color: 'red',
                  display: 'block',
                }}>{`На паузе с ${days(state.documentRaw?.paused_at).format(dateFormat)}`}</span>
                : ''
            }
          </>
          : ' '
      }
      extraHead={
        state.documentRaw?.cancel_requested ? (
          <Badge status={'error'} label={'Отменен'} view={'stroked'} />
        ) : null
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={handleClickSave}
    >
      <OrderShippedFormContent
        handleSave={handleClickSave}
        refetch={refetchData}
        deliveryContracts={deliveryContractsData?.delivery_contracts}
      />
    </FormWrapper>
  )
}

const OrderFormWithContext = () => (
  <OrderFormContextProvider>
    <OrderShippedForm />
  </OrderFormContextProvider>
)

export default OrderFormWithContext
