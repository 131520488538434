import React, { useEffect, useMemo } from 'react'
import { DocumentsListWrapper } from '@pages/OperationsAndDocumentsPage/components'
import { useGetPhysicalSetList } from 'src/hooks'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { useFilter } from '../../../hooks/useFilter'

const TaskOfCompletingList = () => {
  const { filterState, setFilterState } = useFilter()

  const {
    isFetching: taskLoading,
    data: orderData,
    refetch,
  } = useGetPhysicalSetList(generateFilterParams(filterState))

  useEffect(() => {
    refetch()
    console.log(filterState)
  }, [filterState])

  const rows = useMemo(
    () => generateRows(orderData?.orders || []),
    [orderData?.orders],
  )
  const columns = useMemo(() => generateColumns(), [])
  const filtersConfig = generateFilters()
  return (
    <DocumentsListWrapper
      title={'Задание на комплектацию'}
      rows={rows}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      filtersConfig={filtersConfig}
      loading={taskLoading}
      pagination={orderData?.pagination}
    />
  )
}
export default TaskOfCompletingList
