import { ISupplyFormState } from '../../../store/interfaces'
import { IUpdateServiceDocData } from 'src/hooks/billing/useUpdateServiceDoc'
import days from 'dayjs'
import { IUpdateSupplyData } from '@/hooks/receiving/useUpdateSupply'
import { SupplyStatusEnum } from 'src/interfaces'
import { DATETIME_REQUEST_FORMAT } from '@shared/const/date'

export const convertServicesData = (
  supplyId: string,
  state: ISupplyFormState,
): IUpdateServiceDocData => {
  const merchant = state.supplyRaw?.merchant

  const docId = state.servicesDocuments[0]?.id

  const operationsInputService =
    state.formStateDraft?.operationsInputService || []
  const materialsInputService =
    state.formStateDraft?.materialsInputService || []
  const calcItems = operationsInputService
    .concat(materialsInputService)
    .map((item) => ({ ...item, quantity: Number(item.quantity) }))
    .filter((item) => Boolean(item.quantity))

  return {
    doc_id: docId,
    items: calcItems,
    date: days().format(DATETIME_REQUEST_FORMAT),
    merchant_id: merchant?.id,
    parent_id: supplyId,
  }
}
export const convertSupplyData = (
  state: ISupplyFormState,
): IUpdateSupplyData | null => {
  const status = state.formStateDraft?.status
  const comment = state.formStateDraft?.comment

  if (!status) {
    console.error('Отсутствует статус')
    return null
  }

  return {
    status: status as SupplyStatusEnum,
    warehouse_comment: comment || '',
  }
}
