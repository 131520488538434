import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { IMovingFormState } from '../store/interfaces'
import { movingInitialState, movingReducer } from '../store/reducer'

export interface IUseReducer {
  state: IMovingFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {}

export const MovingFormContext = createContext<IContext>({
  state: movingInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const MovingFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof movingInitialState, IReducerAction>
  >(movingReducer, movingInitialState)

  useEffect(() => {
    console.log('--- State ---', state)
  }, [state])

  return (
    <MovingFormContext.Provider value={{ state, dispatch }}>
      {children}
    </MovingFormContext.Provider>
  )
}
export const useMovingFormContext = () => useContext(MovingFormContext)
