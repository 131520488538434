import React from 'react'

import { SHIPMENT_STATUS_BADGE_TYPE, SHIPMENT_STATUSES_DESCRIPTION } from 'src/interfaces'

import { IShipmentData } from '../../../../store/interfaces/shipment'
import { OperAndDocumPages } from '../../../../pagesType'

import { TableColumn } from '@consta/uikit/Table'
import { Badge } from '@consta/uikit/Badge'
import { DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'


export const generateRows = ({ shipments }: IShipmentData) => {
  if (!shipments) {
    return []
  }

  return shipments.map(shipment => ({
    id: shipment.id,
    date: shipment.create_ts,
    date_value: days(shipment.create_ts).format(DATE_TIME_VIEW),
    documentNum: shipment.id,
    deliveryService: shipment.delivery_service,
    status: shipment.status,
    status_value: SHIPMENT_STATUSES_DESCRIPTION[shipment.status],
  }))
}
export const generateColumns = (): TableColumn<any>[] => {

  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{minWidth: '120px'}}/>
      }
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        return <DocumentNumCell page={OperAndDocumPages.shipmentForm} documentNum={row.documentNum} />
      }
    },
    {
      title: 'Курьерская служба',
      accessor: 'deliveryService',
    },
    {
      title: 'Статус',
      accessor: 'status_value',
      sortable: true,
      renderCell: ({row}) => (
        <Badge
          status={SHIPMENT_STATUS_BADGE_TYPE[row.status]}
          label={SHIPMENT_STATUSES_DESCRIPTION[row.status]}
        />
      )
    },
  ]
}


