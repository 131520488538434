import {
  BadgeTypeEnum,
  CONDITION_BADGE_TYPE,
  CONDITION_DESCRIPTION,
  ConditionEnum,
  IAcceptanceSkuObj,
  ISupplyReceipt,
  ISupplyReceiptItem,
  ITableColumn,
  ITableRow,
  TareStatusEnum,
  TrustBox,
} from '@/interfaces'
import styles from '../accepted-table.module.scss'
import React from 'react'
import cx from 'classnames'
import { getFormattedTares } from '../../../../../utils/common'
import { Button, ImageCell, InputWrapper, NomenclatureBadgesList, PrintCell } from '@/components'
import { TableBatchCell } from '../../index'
import days from 'dayjs'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IconEdit } from '@consta/icons/IconEdit'
import { IconClose } from '@consta/icons/IconClose'
import { IconCheck } from '@consta/icons/IconCheck'
import { checkIsTare, hideSymbols } from '@shared/helpers'
import { DATE_FORMAT_VIEW } from '@shared/const/date'
import { Badge } from '@consta/uikit/Badge'


interface IType {
  [key: string]: string | undefined
}

const ACCEPTANCE_TYPE: IType = {
  PIECE: 'Поштучно',
  BOX: 'По коробам',
  PALLET: 'По паллетам',
}

export const generateColumns = (): ITableColumn[] => {
  /** Генерация столбцов (принятных тар с товарами) */
  return [
    {
      key: 'img',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '90px',
      renderCell: ({ row }) => <ImageCell image={String(row.img)} />,
    },
    {
      key: 'name',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: '4fr',
    },
    {
      key: 'acceptance_type',
      title: 'Тип приемки',
      title_txt: 'Тип приемки',
    },
    {
      key: 'quantity',
      title: 'Кол-во упаковок',
      title_txt: 'Кол-во упаковок',
      gridCellWidth: '150px',
    },
    {
      key: 'multiplicity',
      title: 'Кратность упаковки',
      title_txt: 'Кратность упаковки',
    },
    {
      key: 'total',
      title: 'Итого',
      title_txt: 'Итого',
    },
    {
      key: 'party',
      title: 'Партия',
      title_txt: 'Партия',
      gridCellWidth: '190px',
      thClassName: styles.thStyle,
      tdClassName: styles.tdStyle,
    },
    {
      key: 'barcodeUsed',
      title: 'ШК',
      title_txt: 'ШК',
      gridCellWidth: '190px',
      align: 'right',
      thClassName: styles.thStyle,
      tdClassName: styles.tdStyle,
    },
    {
      key: 'print__action',
      title: '',
      title_txt: '',
      isAction: true,
      align: 'right',
      renderCell: ({ row, index }) => {
        return (
          <>
            {row.isSubRow ? (
              <PrintCell
                onClick={(e) => row?.helperCallback?.()}
                align={'right'}
                wrapperClassName={styles.printBtn}
              />
            ) : null}
          </>
        )
      },
    },
  ]
}

export const generateRows = (args: {
  tares: ISupplyReceipt[]
  originalSkusObj: IAcceptanceSkuObj
  handleChangeSwitchDefective: (tare: ISupplyReceipt, checked: boolean) => void
  barcode: TextFieldPropValue | null
  printItemSticker: (item: ISupplyReceiptItem) => void
  printTareSticker: (tareBarcode: string) => void
  handleEditTareItemQuantity: (tareItemId: string, value: string | null) => void
  handleClickTareEdit: (tareId: string, editMode: 'accept' | 'reset' | 'start') => void
  editTareId: string | null
  draftTareData: Record<string, string | null> | null
  disableChangeCondition: boolean
  trustBoxes: TrustBox[]
}): ITableRow[] => {
  /** Генерация строк (принятных тар с товарами) */
  const {
    tares,
    originalSkusObj,
    barcode,
    printItemSticker,
    printTareSticker,
    handleChangeSwitchDefective,
    handleEditTareItemQuantity,
    handleClickTareEdit,
    draftTareData,
    editTareId,
    disableChangeCondition,
    trustBoxes
  } = args


  const formattedTares = getFormattedTares(tares, originalSkusObj, barcode)
  return formattedTares.map((tare, index) => {
    const isOpenBadge = tare.status === TareStatusEnum.open
    const tareNotEmpty = Boolean(tare.items.length)
    const defectiveSwitcherIsDisabled = !isOpenBadge || tareNotEmpty || disableChangeCondition
    const isDefectiveContainer = [ConditionEnum.DEFECT, ConditionEnum.HIDDEN_DEFECT].includes(tare.condition)

    const editMode = tare.id === editTareId

    const subItems = generateTareItems({
      tare,
      isDefectiveContainer,
      isOpenBadge,
      barcode,
      originalSkusObj,
      printItemSticker,
      editMode,
      handleEditTareItemQuantity,
      draftTareData,
      trustBoxes
    })
    const tareItemsQuantity = subItems.reduce((a, c) => a + c.quantityNumValue, 0)

    return {
      id: tare.id,
      defaultIsExpanded: isOpenBadge,
      hardExpanded: editTareId !== null || isOpenBadge ? editMode || isOpenBadge : false,
      withOpenCloseBadge: {
        isOpenValue: isOpenBadge,
      },
      collapsableRowSticky: true,
      withSwitch: !disableChangeCondition ? {
        title: 'Тара брака',
        disabled: defectiveSwitcherIsDisabled,
        switchValue: isDefectiveContainer,
        onChange: (checked) => {
          if (!isOpenBadge) {
            window.showNotification('Тара уже закрыта')
            return
          }
          handleChangeSwitchDefective(tare, checked)
        },
      } : null,
      theme: isDefectiveContainer ? 'red' : 'gray',
      renderExtraRow: ({ row }: any) => (
        <>
          {
            disableChangeCondition ? (
              <div className={styles.conditionBadge}>
                <Badge
                  status={CONDITION_BADGE_TYPE[tare.condition]}
                  label={CONDITION_DESCRIPTION[tare.condition]}
                  size='s'
                />
              </div>
            ) : null
          }
          <div
            className={cx(
              styles.collapseRowContent,
              isDefectiveContainer ? styles.isDefective : '',
            )}
          >
            <div className={styles.tareName}>
              <span>{tare.tare_barcode} </span> <span>{tare.id}</span>
            </div>
            <div className={styles.tareQuantity}>
              <span>{tareItemsQuantity}</span>
              <span>товара(-ов)</span>
            </div>
            <div className={styles.tareBtns}>
              {
                editMode ? (
                  <>
                    <Button
                      onlyIcon
                      theme={'success'}
                      className={styles.editBtn}
                      iconLeft={IconCheck}
                      size='s'
                      onClick={(e) => {
                        e.stopPropagation()
                        handleClickTareEdit(tare.id, 'accept')
                      }}
                    />
                    <Button
                      onlyIcon
                      theme={'danger'}
                      className={styles.editBtn}
                      iconLeft={IconClose}
                      size='s'
                      onClick={(e) => {
                        e.stopPropagation()
                        handleClickTareEdit(tare.id, 'reset')
                      }}
                    />
                  </>
                ) : !editMode && !editTareId ? ( // не отображаем, если открыли на редактирование другую тару
                  <Button
                    onlyIcon
                    view='clear'
                    className={styles.editBtn}
                    iconLeft={IconEdit}
                    size='s'
                    onClick={(e) => {
                      e.stopPropagation()
                      handleClickTareEdit(tare.id, 'start')
                    }}
                  />
                ) : null
              }

              <PrintCell
                onClick={(e) => {
                  e?.stopPropagation()
                  if (!tare.tare_barcode) {
                    console.error('Нет ШК тары')
                    return
                  }
                  printTareSticker(tare.tare_barcode)
                }}
              />
            </div>
          </div>
        </>
      ),
      subItems: subItems,
    }
  })
}

interface IGenerateTareItems {
  originalSkusObj: IAcceptanceSkuObj
  tare: ISupplyReceipt
  barcode: TextFieldPropValue | null
  isDefectiveContainer: boolean
  isOpenBadge: boolean
  printItemSticker: (item: ISupplyReceiptItem) => void
  editMode: boolean
  handleEditTareItemQuantity: (tareItemId: string, value: string | null) => void
  draftTareData: Record<string, string | null> | null
  trustBoxes: TrustBox[]
}

export const generateTareItems = (args: IGenerateTareItems) => {
  /** Генерация подстрок (товаров в таре) */
  const {
    tare,
    isDefectiveContainer,
    isOpenBadge,
    barcode,
    // originalSkus,
    originalSkusObj,
    printItemSticker,
    editMode,
    handleEditTareItemQuantity,
    draftTareData,
    trustBoxes
  } = args


  const updateArrayToBoxType = (array) => {
    return array.map(item => ({
      ...item,
      acceptance_type: 'BOX',
      trust_box: true
    }))
  } //Временное решение: Все текущие доверенные приемки всегда в КОРОБАХ


  const newTareItems = tare.items.map((tareItem, index) => {
    return trustBoxes.find(trustBox => trustBox.barcode === tareItem.trust_acceptance_box_barcode)
      ? updateArrayToBoxType(trustBoxes.find(trustBox => trustBox.barcode === tareItem.trust_acceptance_box_barcode).items)
      : tareItem
  }).flat(1) //Для того чтобы товары внутри коробки легли в общий ряд

  return newTareItems
    .filter((tareItem) => {
      if (barcode && !checkIsTare(barcode)) {
        const originalSku = originalSkusObj[tareItem?.sku_id]
        return originalSku?.barcodes?.find(
          (barcodeObj) => barcodeObj?.barcode === barcode,
        )
      }
      return true
    })
    .map((tareItem) => {
      const originalSku = originalSkusObj[tareItem?.sku_id]

      const draftItemQuantity = draftTareData?.[tareItem.id]

      const batch = originalSku?.batches?.find(
        (batch) => batch.id === tareItem.sku_batch_id,
      )
      const batchDate = batch?.exp_date
        ? days(batch.exp_date).format(DATE_FORMAT_VIEW)
        : ''

      // Временно не обрабатываются излишки, оставил на будущее
      const isSurplus = false

      const badges = [
        { key: BadgeTypeEnum.defect, visible: isDefectiveContainer },
        { key: BadgeTypeEnum.surplus, visible: isSurplus },
      ]
      const totalQuantity = tareItem.multiplicity ? tareItem.quantity * tareItem.multiplicity : tareItem.quantity

      return {
        id: tareItem.id,
        isSubRow: true,
        img: originalSku?.image,
        name: (
          <>
            <NomenclatureBadgesList badgesList={badges} />
            <p>{originalSku?.title}</p>
            <span className={styles.lightTxt}>{originalSku?.article}</span>
          </>
        ),
        acceptance_type: ACCEPTANCE_TYPE[tareItem.acceptance_type as keyof IType],
        multiplicity: tareItem.multiplicity,
        total: totalQuantity,
        quantity: (!tareItem.trust_box && editMode) ?
          <InputWrapper
            isInteger
            id={'barcode'}
            label={''}
            placeholder={''}
            handleChange={(value) => {
              handleEditTareItemQuantity(tareItem.id, value)
            }}
            value={String(draftItemQuantity || '')}
            className={styles.barcodeInput}
          />
          : tareItem.quantity,
        quantityNumValue: totalQuantity, // данные для подсчетов
        barcodeUsed: hideSymbols(6, tareItem.barcode_used),
        measure: 'шт',
        party: batch ? (
          <TableBatchCell date={batchDate} num={batch.num || ''} />
        ) : null,
        // @ts-ignore Не совпадает с новым типом уникальной коробки
        helperCallback: () => printItemSticker(tareItem),
        tareOpen: isOpenBadge,
      }
    }).reverse()
}
