import React, { useEffect, useMemo } from 'react'
import days from 'dayjs'

import styles from './discrepancies-modal.module.scss'

import { Modal, TableV2 } from '@/components'

import { IAcceptanceSku, ISupplyItem, ISupplyReceipt, ModalProps, TrustBox } from '@/interfaces'
import { generateTableColumns, generateTableRows } from './utils'
import { DATE_TIME_VIEW } from '@shared/const/date'

interface IDiscrepanciesModal extends ModalProps {
  originalSkus?: IAcceptanceSku[]
  date?: string
  planItems: ISupplyItem[]
  tares: ISupplyReceipt[]
  trustBoxes?: TrustBox[]
  supplyId?: string
  isLoading: boolean
  reloadStateData: () => void
}

const DiscrepanciesModal = (props: IDiscrepanciesModal) => {
  const {
    isOpen,
    onClose,
    date,
    planItems,
    trustBoxes = [],
    originalSkus = [],
    tares,
    supplyId,
    isLoading,
    reloadStateData,
  } = props

  /** Обновление даннхы для таблицы */
  useEffect(() => {
    reloadStateData()
  }, [])

  const columns = useMemo(() => generateTableColumns(),[])
  const rows = useMemo(
    () => generateTableRows(originalSkus, planItems, trustBoxes, tares),
    [originalSkus, planItems, trustBoxes, tares]
  )

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.discrepancies}
      withClose
      onClose={onClose}
    >
      <div className={styles.top}>
        Отчет о расхождении в приемке заказа {supplyId}
        <span className={styles.date}>
          от {days(date).format(DATE_TIME_VIEW)}
        </span>
      </div>

      <TableV2
        isLoading={isLoading}
        theme={'gray'}
        rows={rows}
        columns={columns}
        className={styles.tableWrapper}
      />
    </Modal>
  )
}

export default DiscrepanciesModal
