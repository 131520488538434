import React, { useCallback, useMemo } from 'react'

import styles from './unprocessed.module.scss'

import { TableV2 } from '@/components'

import { IAcceptanceSkuObj, ISupplyItem } from '@/interfaces'
import { useAppContext } from '@shared/providers/AppContextProvider'
import { generateTableColumns, generateTableRows } from './utils'
import { tableV2Theme } from '@/components/Table/TableV2'
import { BatchItemsValuesType, PrintItemBatchType, PrintItemsValuesType } from '../../../../types'

interface IUnProcessedTableProps {
  theme?: tableV2Theme
  title?: string
  openTareId: string | null
  batchItemsValues?: BatchItemsValuesType
  handleSetItemBatch?: (skuId: string, data: PrintItemBatchType) => void,
  handleCreateItemBatch?: (skuId: string, supplyItemId: string) => void,
  originalSkusObj: IAcceptanceSkuObj
  supplyItems: ISupplyItem[]
  handleChooseItem?: (item: ISupplyItem) => void
  tableExtraHeader?: React.ReactNode
  maxHeightBody?: number

  batchSelectMode?: boolean
  quantityMode?: boolean
  massAcceptanceMode?: boolean
  printItemsValues?: PrintItemsValuesType
  handleSetPrintQuantity?: (supplyItemId: string, skuId: string, value?: number) => void
}

interface ContextProps {
  advanceMode: boolean
}

const UnProcessed = React.memo((props: IUnProcessedTableProps & ContextProps) => {
  const {
    advanceMode,
    quantityMode = false,
    batchSelectMode = false,
    batchItemsValues,
    handleSetItemBatch,

    handleCreateItemBatch,
    theme = 'white',
    title = 'К обработке',
    openTareId,
    originalSkusObj,
    supplyItems,
    tableExtraHeader,
    handleChooseItem,
    handleSetPrintQuantity,
    maxHeightBody = 320,
    printItemsValues
  } = props

  const handleClickAddItem = useCallback(
    (supplyItemId: string) => {
      const correctSupplyItem = supplyItems.find(
        (supplyItem) => supplyItem.id === supplyItemId,
      )
      if (!correctSupplyItem) {
        console.log('supply item does not exist')
        return
      }
      handleChooseItem?.(correctSupplyItem)
    },
    [originalSkusObj, supplyItems, openTareId],
  )

  const rows = useMemo(() =>
      generateTableRows({
        batchSelectMode,
        batchItemsValues,
        originalSkusObj,
        supplyItems,
        advanceMode,
        handleAddItem: handleClickAddItem,
        quantityMode,
        handleSetPrintQuantity,
        printItemsValues,
        handleSetItemBatch,
        handleCreateItemBatch
      }),
    [
      batchSelectMode,
      batchItemsValues,
      originalSkusObj,
      supplyItems,
      advanceMode,
      handleClickAddItem,
      quantityMode,
      printItemsValues,
      handleSetPrintQuantity
    ],
  )
  const columns = useMemo(
    () => generateTableColumns(quantityMode),
    [quantityMode],
  )

  return (
    <div className={styles.unProcessed}>
      <TableV2
        theme={theme}
        rows={rows}
        columns={columns}
        title={title}
        className={styles.tableWrap}
        maxHeightBody={maxHeightBody}
        extraHeader={tableExtraHeader}
      />
    </div>
  )
} , (prevProps, nextProps) => {
  return (
    prevProps.theme === nextProps.theme
    && prevProps.title === nextProps.title
    && prevProps.openTareId === nextProps.openTareId
    && prevProps.batchItemsValues === nextProps.batchItemsValues
    && prevProps.originalSkusObj === nextProps.originalSkusObj
    && prevProps.supplyItems === nextProps.supplyItems
    && prevProps.tableExtraHeader === nextProps.tableExtraHeader
    && prevProps.maxHeightBody === nextProps.maxHeightBody
    && prevProps.batchSelectMode === nextProps.batchSelectMode
    && prevProps.quantityMode === nextProps.quantityMode
    && prevProps.massAcceptanceMode === nextProps.massAcceptanceMode
    && prevProps.printItemsValues === nextProps.printItemsValues
  )
})

const UnProcessedWithContext = (props: IUnProcessedTableProps) => {
  const { advanceMode } = useAppContext()
  return (
    <UnProcessed
      advanceMode={advanceMode}
      {...props}
    />
  )
}

export default UnProcessedWithContext
