import { IBatch, IDefectType } from '@/interfaces'
import styles from './desktopComponents/MassAcceptModal/MassAcceptTable/MassAcceptTable.module.scss'
import React from 'react'


export const getBatchesOptions = (batches: IBatch[] = []) => batches.map((batch) => ({
  label: (
    <div className={styles.batchItem}>
      <p>{batch.num}</p>
      <p className={styles.lightTxt}>{batch.exp_date}</p>
    </div>
  ),
  labelTxt: `${batch.num} ${batch.exp_date}`,
  id: String(batch.id)
}))

export const getDefectOptions = (defectTypes: IDefectType[]) => defectTypes.map((defect) => ({
  label: defect.title,
  id: String(defect.id)
}))