import React, { useEffect } from 'react'
import { useGetService } from 'src/hooks'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'
import { formStatesAreEqual } from '../../../store/utils'
import {
  revertDraftStateToRawAction,
  setOriginalServicesAction,
  setServicesFormDraftAction,
  setServicesFormRawAction,
  setServicesRawAction,
} from '../../../store/actions'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { FormWrapper } from '../../../components'
import {
  ServicestFormContextProvider,
  useServicesFormContext,
} from '@pages/OperationsAndDocumentsPage/context/ServiceRenderedFormContext'
import { ServiceRenderedFormContent } from './components'
import { IServicesFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces'
import { PATHS } from '@shared/routing'
import useEditServicesRendered from 'src/hooks/servicesRendered/useEditServiceRendered'
import { useNotifications } from '@shared/providers/NotificationProvider'

export const ServicesRenderedForm = () => {
  const history = useHistory()

  const { state, dispatch } = useServicesFormContext()
  const notification = useNotifications()
  const { id } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()
  const { data: serviceData, isFetching: serviceLoading } = useGetService(
    { serviceId: id },
    {
      onSuccess: ({ document, services }) => {
        /** Проверить!!!! */
        dispatch(setServicesRawAction(document))
        /** Проверить!!!! */
        dispatch(setOriginalServicesAction(services))
      },
    },
  )
  const { mutateAsync: editUpdateServices } = useEditServicesRendered({
    id,
  })
  useEffect(() => {
    const formData: IServicesFormDataState = {
      status: serviceData?.document.status || '',
      comment: serviceData?.document.warehouse_comment || '',
    }
    dispatch(setServicesFormDraftAction(cloneDeep(formData)))
    dispatch(setServicesFormRawAction(cloneDeep(formData)))
  }, [serviceData])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.servicesRenderedList,
      }),
    )
  }
  const handleClickSave = async () => {
    const data = {
      items: state.documentRaw?.items,
      warehouse_comment: state.formStateDraft?.comment || '',
      merchant_id: state.documentRaw?.merchant?.id,
    }
    await editUpdateServices(data)
      .then(() => {
        notification?.show('success', 'Документ успешно сохранен', {
          soundName: 'PACKING_COMPLETED',
        })
      })
      .catch(() => {})
  }
  const statesAreEqual = formStatesAreEqual<IServicesFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )

  return (
    <FormWrapper
      loading={serviceLoading}
      title={state.documentRaw?.id || id}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={handleClickSave}
    >
      <ServiceRenderedFormContent />
    </FormWrapper>
  )
}
const ServicesFormWithContext = () => (
  <ServicestFormContextProvider>
    <ServicesRenderedForm />
  </ServicestFormContextProvider>
)
export default ServicesFormWithContext
