import { useMutation } from 'react-query'

import api from '@shared/api'

export interface IUpdateRefundPlaceFile {
  data: string
  title: string
}

const useLoadReturnPlaceFiles = () => {
  return useMutation(
    async (data: { files: IUpdateRefundPlaceFile[]; returnId: string;  placeId: string}) => {
      const result = await api.post(`/return/${data.returnId}/place/${data.placeId}/file`, data.files)
      return result.data
    },
  )
}

export default useLoadReturnPlaceFiles
