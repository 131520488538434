import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IError, IPagination, IPaginationFilter, ISku } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'


/** https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Tovary/operation/getSkus */


interface IGetItemsResult extends ApiReqResult {
  errors?: IError[]
  skus: ISku[]
  pagination: IPagination
}

export interface ISkuListFilter {
  articles?: string[]
  barcodes?: string[]
  categories?: string[]
  external_ids?: string[]
  ids?: string[]
  merchant_ids?: string[]
  search?: string

  options?: UseQueryOptions<any,any,any,any>
}

interface Params extends IPaginationFilter {
  filter: ISkuListFilter
  options?: UseQueryOptions<any,any,any,any>
}

const getItems = async (params?: Params): Promise<IGetItemsResult> => {
  const result = await api.post(`/items/list`, params)
  if (!result?.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

export const useGetItemsNew = (
  params?: Params,
  options?: UseQueryOptions<any,any,any,any>
) => {
  return useQuery(
    [`getSkus2`, params],
    () => getItems(params),
    options
  )
}

export const useFetchItemsNew = () => {
  return { fetch: getItems }
}
