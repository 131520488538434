import { IPrinterState } from "./schema"

export const printerReducer = (state: IPrinterState, action: any) => {
  switch (action.type) {
    case 'connected':
      return { ...state, connected: true }
    case 'disconnected':
      return { ...state, connected: false }
    default:
      return state
  }
}