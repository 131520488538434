import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


const useShipmentPlanVerify = (
  options?: UseMutationOptions<any,ApiReqResult,any>
) => {
  return useMutation(
    async (shipmentPlans: string[]) => {
      return api.post(`/shipment-plan/verify`, shipmentPlans)
    },
    options,
  )
}

export default useShipmentPlanVerify

