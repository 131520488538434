import { ISelectOption } from 'src/interfaces'

export const ACCEPTANCE_TYPES: ISelectOption[] = [
  {
    value: 'PIECE',
    label: 'Поштучно',
  },
  {
    value: 'BOX',
    label: 'По коробам',
  },
  {
    value: 'PALLET',
    label: 'По паллетам',
  },
]
