import React, { useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'
import { formStatesAreEqual } from '../../../store/utils'
import {
  revertDraftStateToRawAction,
  setCellsAction,
  setOriginalSkusAction,
  setPlacingFormDraftAction,
  setPlacingFormRawAction,
  setPlacingRawAction,
} from '../../../store/actions'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { FormWrapper } from '../../../components'
import { IReceiptFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces/receipt'
import { PlacingFormContextProvider, usePlacingFormContext } from '../../../context/PlacingFormContext'
import { PATHS } from '@shared/routing'
import { PlacingFormContent } from './components'
import { useChangeStatusPlacing, useGetPlacing } from 'src/hooks/placing'
import { IPlacingFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces'
import { useNotifications } from '@shared/providers/NotificationProvider'

const PlacingForm = () => {
  const notification = useNotifications()
  const history = useHistory()
  const { state, dispatch } = usePlacingFormContext()
  const { id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()

  const { data: documentData, isFetching: isSupplyLoading } = useGetPlacing(
    { placingId: documentId },
    {
      onSuccess: ({ cells, placing, skus }) => {
        dispatch(setCellsAction(cells))
        dispatch(setPlacingRawAction(placing))
        dispatch(setOriginalSkusAction(skus))
      },
    },
  )
  const { isLoading: addLoading, mutateAsync: changeStatus } =
    useChangeStatusPlacing()
  // todo добавить сохранение изменений
  // const updateSupply = useUpdateSupply({ supplyId })

  useEffect(() => {
    const formData: IReceiptFormDataState = {
      status: documentData?.placing?.status || '',
      comment: documentData?.placing?.warehouse_comment || '',
    }
    dispatch(setPlacingFormDraftAction(cloneDeep(formData)))
    dispatch(setPlacingFormRawAction(cloneDeep(formData)))
  }, [documentData])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.placingList,
      }),
    )
  }

  const handleClickSave = async () => {
    if (!documentId || !state.formStateDraft?.status) {
      return
    }
    const data = {
      status: state.formStateDraft?.status,
    }
    await changeStatus({ documentId, data })
      .then(() => {
        notification?.show('success', 'Статус успешно сохранен', {
          soundName: 'PACKING_COMPLETED',
        })
      })
      .catch(() => {})
  }

  const statesAreEqual = formStatesAreEqual<IPlacingFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )
  return (
    <>
      <FormWrapper
        loading={isSupplyLoading}
        title={state.documentRaw?.id || documentId}
        subtitle={
          state.documentRaw
            ? `от ${days(state.documentRaw.create_ts).format(DATE_TIME_VIEW)}`
            : ' '
        }
        cancelBtnDisabled={statesAreEqual}
        handleClickCancel={handleClickCancel}
        handleClickSave={handleClickSave}
      >
        <PlacingFormContent />
      </FormWrapper>
    </>
  )
}

const PlacingFormWithContext = () => (
  <PlacingFormContextProvider>
    <PlacingForm />
  </PlacingFormContextProvider>
)

export default PlacingFormWithContext
