import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { usePackingContext } from '../../../../../context/PackingContext'
import { DocumentsParams } from '../../../../../components'
import { InputWrapper } from 'src/components'

import { generatePackingColumns, generatePackingRows } from './utils'

import styles from './documents-params.module.scss'
import { convertWeight, getDimensionStr } from '@shared/helpers'

interface SupplyFormContentProps {}

const DocumentsParamsFields = (props: SupplyFormContentProps) => {
  const { page, id: documentId } = useParams<{ page: OperAndDocumPagesType, id: string, tabId: string }>()
  const { state } = usePackingContext()

  const rows = useMemo(() => {
    return generatePackingRows(state.places)
  }, [state.documentRaw, state.places])

  const cols = useMemo(generatePackingColumns,[])

  const merchants = new Map()
  const deliveries = new Set()

  if (state.documentRaw) {
    state.documentRaw.orders.forEach((order) => {
      merchants.set(order?.merchant?.id, order?.merchant?.title)
      deliveries.add(order?.delivery.delivery_service)
    })
  }

  const weight = convertWeight('g', 'kg', (state.documentRaw?.weight || 0))

  const dimensions = state.documentRaw?.dimensions
  const dimsStr = getDimensionStr(dimensions, {from: 'mm', to: 'sm'}, ['depth', 'width', 'height'])

  return (
    <DocumentsParams
      rows={rows}
      columns={cols}
      tableTitle={'Места'}
    >
      <div className={styles.fieldsWrapper}>

        <InputWrapper
          className={styles.fieldType25}
          value={Array.from(merchants.values()).join(', ')}
          id={'orgs'}
          label={'Организация'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={Array.from(deliveries).join(', ')}
          id={'deliveries'}
          label={'Курьерская служба'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.barcode}
          id={'barcode'}
          label={'Код'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={`${weight}`}
          id={'weight'}
          label={'Вес (КГ)'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={dimsStr}
          id={'dimensions'}
          label={'Габариты ГхШхВ (CM)'}
          disabled
        />
      </div>
    </DocumentsParams>
  )
}

export default DocumentsParamsFields
