import api from '@shared/api'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'
import { PartialInventoryRequestResult } from 'src/interfaces'

export type AddPartInventoryItemType = {
  quantity: number
  sku_batch_id?: string
  sku_id: string
}

type RequestData = {
  inventoryId: string
}
const addPartInventoryItem = async ({ inventoryId, ...data }: RequestData) => {
  const result = await api.post(`/inventory-cell/${inventoryId}/item`, data)
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

const useAddPartInventoryItem = (
  options?: UseMutationOptions<any, PartialInventoryRequestResult, any>,
) => {
  return useMutation(addPartInventoryItem, options)
}

export default useAddPartInventoryItem
