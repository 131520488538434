import React, { useEffect, useMemo, useState } from 'react'

import { generateColumns, generateFilters, generateRows } from './helpers'
import { Button } from '@consta/uikit/Button'
import { DocumentsListWrapper } from '../../../components'
import ReplaceModal from '../ReplaceModal/ReplaceModal'

import { useGetReplaceList } from 'src/hooks'
import { generateFilterParams } from '../../../helpers/filters'
import { useFilter } from '../../../hooks/useFilter'

const ReplaceList = () => {
  /** Стейт для поиска и отображения данных фильтров */
  const { filterState, setFilterState } = useFilter()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { isFetching, data, refetch } = useGetReplaceList(
    generateFilterParams(filterState),
  )

  useEffect(() => {
    refetch()
  }, [filterState])

  const rows = useMemo(
    () => generateRows(data?.documents || []),
    [data?.documents],
  )
  const columns = useMemo(() => generateColumns(), [])
  const filtersConfig = generateFilters()

  return (
    <>
      <DocumentsListWrapper
        title={'Коррекция'}
        rows={rows}
        columns={columns}
        filterState={filterState}
        setFilterState={setFilterState}
        filtersConfig={filtersConfig}
        loading={isFetching}
        pagination={data?.pagination}
        tableExtraHeaderRight={
          <Button
            label={'Добавить'}
            onClick={() => {
              setIsModalOpen(true)
            }}
          />
        }
      />

      {isModalOpen && (
        <ReplaceModal
          refetch={refetch}
          type={'add'}
          isOpen
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

export default ReplaceList
