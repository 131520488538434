import { IFieldParams, TemplateTableColumn } from '@shared/types/tableGenerator/generatorTypes'
import { IReportCell } from '@/interfaces/report'


export enum TemplateAnalyticsType {
  ORDERS = 'ORDERS',
  STORAGE = 'STORAGE',
  SUPPLIES = 'SUPPLIES',
}

export interface BlockValueBase {
  title: string
  value: string
}

export interface DashboardBlockTemplateDto {
  block: TemplateAnalyticsType,
  params: DashboardSettingsParamDto[]
}

export interface DashboardSettingsParamDto extends Omit<IFieldParams, 'values'> {
  values: string[]
  available_values: DashboardSettingsParamValueDto[]
}

export interface DashboardBlockTableCellDto extends IReportCell {

}

export interface DashboardBlockTableRowDto {
  cells: DashboardBlockTableCellDto[]
}

export interface DashboardBlockTableDto {
  id: number,
  title: string,
  columns: Pick<TemplateTableColumn, 'id' | 'title' | 'type'>[]
  rows: DashboardBlockTableRowDto[]
}


export interface DashboardSettingsParamValueDto extends BlockValueBase {
}

export interface DashboardBlockDataItemDto extends BlockValueBase {
}

export interface DashboardBlockDto {
  id: string,
  data: DashboardBlockDataItemDto[]
  tables: DashboardBlockTableDto[]
  params: DashboardSettingsParamDto[]
  title: string
}


export interface PutDashboardSettingsBlockParamDto {
  id: number
  value?: string
  values?: string[]
}

export interface PutDashboardSettingsBlockDto {
  block: TemplateAnalyticsType,
  position: number,
  title: string,
  params?: PutDashboardSettingsBlockParamDto[],
}

export interface DashboardSettingsDto {
  block: TemplateAnalyticsType
  position?: number
  params: DashboardSettingsParamDto[]
  title?: string
}
