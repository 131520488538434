import React, { useCallback, useMemo } from 'react'

import styles from './unprocessed.module.scss'

import { TableV2 } from 'src/components'

import { IAcceptanceSku } from 'src/interfaces'
import { IReplaceItem } from 'src/interfaces/replace'
import { useAppContext } from '@shared/providers/AppContextProvider'
import { generateTableColumns, generateTableRows } from './utils'

interface IUnProcessTableProps {
  openTareId: string | null
  originalSkus: IAcceptanceSku[]
  documentReplace: IReplaceItem[]
  handleChooseItem: (item: IReplaceItem) => void
  tableExtraHeader?: React.ReactNode
  maxHeightBody?: number
  printItemsValues?: Record<string, number | undefined>
  printMode?: boolean
  handleSetPrintQuantity?: (itemId: string, value?: number) => void
}
const UnProcess = ({
  openTareId,
  originalSkus,
  tableExtraHeader,
  handleChooseItem,
  printMode = false,
  handleSetPrintQuantity,
  maxHeightBody = 320,
  printItemsValues,
  documentReplace,
}: IUnProcessTableProps) => {
  const { advanceMode } = useAppContext()
  const handleClickAddItem = useCallback(
    (documentItemId: string) => {
      const correctSupplyItem = documentReplace.find(
        (item) => item.sku_id === documentItemId,
      )
      if (!correctSupplyItem) {
        console.log('supply item does not exist')
        return
      }
      handleChooseItem(correctSupplyItem)
    },
    [originalSkus, documentReplace, openTareId],
  )
  const rows = useMemo(
    () =>
      generateTableRows({
        originalSkus,
        documentReplace,
        advanceMode,
        handleAddItem: handleClickAddItem,
        printMode,
        handleSetPrintQuantity,
        printItemsValues,
      }),
    [
      originalSkus,
      documentReplace,
      advanceMode,
      handleClickAddItem,
      printMode,
      printItemsValues,
      handleSetPrintQuantity,
    ],
  )
  const columns = useMemo(() => generateTableColumns(printMode), [printMode])

  return (
    <div className={styles.unProcessed}>
      <TableV2
        theme={'white'}
        rows={rows}
        columns={columns}
        title={'К обработке'}
        className={styles.tableWrap}
        maxHeightBody={maxHeightBody}
        extraHeader={tableExtraHeader}
      />
    </div>
  )
}

export default React.memo(UnProcess)
