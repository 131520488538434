import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './documents-params.module.scss'
import { Button, InputWrapper, TableWithCopy } from 'src/components'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { generateColumns, generateRows, generateStockRows } from './utils'
import { DocumentTypeEnum } from 'src/interfaces'
import { DocumentsParams, DocumentStatusField } from '../../../../../components'
import { updateFormStateDraftAction } from '../../../../../store/actions'
import { usePlacingFormContext } from '@pages/OperationsAndDocumentsPage/context/PlacingFormContext'
import cx from 'classnames'
import { IGetCellStockByCellIdResult, useGetItemsStock } from '@/hooks'
import useDeleteProduct, { DeleteProductProps } from '@/hooks/placement/useDeleteProduct'
import PlacingFormModal from '../PlacingFormModal/PlacingFormModal'
import PlacingFormMassModal from '../PlacingFormMassModal/PlacingFormMassModal'
import usePlaceProducts, { IRequestPlaceProduct } from '@/hooks/placement/usePlaceProducts'
import { Item } from '@/hooks/placement/useGetPlacementList'
import { useGetPlacing } from '@/hooks/placing'

interface SupplyFormContentProps {
}

const DocumentsParamsFields = (props: SupplyFormContentProps) => {
  const [isModalPlacingItemVisible, setIsModalPlacingItemVisible] = useState<boolean>(false)
  const [isModalPlacingMassVisible, setIsModalPlacingMassVisible] = useState<boolean>(false)
  const [itemData, setItemData] = useState<IGetCellStockByCellIdResult | null>(null)

  const { page, id: supplyId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()
  const { state, dispatch } = usePlacingFormContext()

  const deletePlacement = useDeleteProduct()

  const handleDeletePlacement = useCallback(
    (itemId: React.ReactNode | string, placementId: string) => {
      deletePlacement.mutate({ docId: placementId, itemId: itemId } as DeleteProductProps,
        { onSuccess: refetchData },
      )
    },
    [],
  )

  const { refetch: refetchPlacingItems } = useGetPlacing(
    { placingId: supplyId },
  )

  const { data: cellsDataRaw, refetch: refetchItems } = useGetItemsStock(
    { place_ids: [state.documentRaw?.tare_barcode || ''] },
    {
      onSuccess: (data) => {
        console.log('TARA stock', data)
        setItemData(data)
      },
    },
  )

  const handleClickPlaceItem = (item: Item) => {
    setStockItem(item)
    setIsModalPlacingItemVisible(true)
  }
  const refetchData = useCallback(() => {
    refetchItems()
    refetchPlacingItems()
  }, [refetchItems, refetchPlacingItems])

  const rows = useMemo(
    () => generateRows(state.documentRaw, state.cells, state.originalSkus),
    [state.documentRaw, state.originalSkus],
  )
  const planRows = useMemo(
    () =>
      generateStockRows(cellsDataRaw?.stock || [], cellsDataRaw?.skus || []),
    [cellsDataRaw],
  )
  const columns = useMemo(
    () =>
      generateColumns({
        withCell: true,
        deleteItem: handleDeletePlacement,
        editItem: handleClickPlaceItem,
        supplyId,
        refetchData
      }),
    [refetchData],
  )
  const planColumns = useMemo(
    () =>
      generateColumns({
        withCell: false,
        deleteItem: handleDeletePlacement,
        editItem: handleClickPlaceItem,
        supplyId,
        refetchData
      }),
    [],
  )
  const [rowsState, setRowsState] = useState(rows)
  const [stockItem, setStockItem] = useState<Item | null>(null)

  useEffect(() => {
    setRowsState(rows)
  }, [rows])

  const submitItem = usePlaceProducts()

  const handleSubmitPlacement = useCallback(
    async (docId: string, products: IRequestPlaceProduct[]) => {
      await submitItem.mutateAsync({ docId, products },
        {
          onSuccess: () => {
            setIsModalPlacingItemVisible(false)
            refetchData()
          },
        },
      )
    },
    [refetchData, submitItem],
  )
  const handleSubmitMassPlacement = useCallback(async (data) => {
    await submitItem.mutateAsync({ docId: supplyId, products: data }, {
      onSuccess: () => {
        setIsModalPlacingMassVisible(false)
        refetchData()
      },
    })
  }, [refetchData, submitItem, supplyId])

  return (
    <>
      <DocumentsParams
        tableTitle={`Размещенные товары`}
        withCopy={false}
        rows={rowsState}
        columns={columns}
        extraContent={
          <div className={styles.planTableWrapper}>
            {
              planRows.length ? (
                <div className={styles.massBtnWrapper}>
                  <Button
                    size='m'
                    label='Массовое размещение'
                    onClick={() => {
                      setIsModalPlacingMassVisible(true)
                    }}
                  />
                </div>
              ) : null
            }
            <TableWithCopy
              withCopy={false}
              withVisibleToggle={!!planRows.length}
              rows={planRows}
              columns={planColumns}
              title={`План на размещение ${!planRows.length ? '(Пусто)' : ''}`}
              tableWrapperClassName={cx(styles.tableWrap)}
              maxHeightBody={445}
            />
          </div>
        }
      >
        <DocumentStatusField
          value={state.formStateDraft?.status}
          onChange={(optionValue) =>
            dispatch(updateFormStateDraftAction('status', optionValue?.value))
          }
          documentType={page as DocumentTypeEnum}
        />
        <InputWrapper
          id={'merchant'}
          label={'Организация'}
          value={state.documentRaw?.merchant?.title || null}
          className={styles.itemField}
          disabled={true}
        />
        <InputWrapper
          value={state.documentRaw?.id || supplyId}
          id={'supplyId'}
          label={'Номер документа'}
          className={styles.itemField}
          disabled={true}
        />
        <InputWrapper
          value={state.documentRaw?.tare_barcode || '-'}
          id={'supplyId'}
          label={`Тара`}
          className={styles.itemField}
          disabled={true}
        />
      </DocumentsParams>
      {isModalPlacingItemVisible && stockItem !== null && (
        <PlacingFormModal
          onSubmit={handleSubmitPlacement}
          formData={itemData}
          isOpen={isModalPlacingItemVisible}
          onClose={() => setIsModalPlacingItemVisible(false)}
          stockItem={stockItem}
        />
      )}
      {
        isModalPlacingMassVisible ? (
          <PlacingFormMassModal
            onSubmit={handleSubmitMassPlacement}
            isOpen={true}
            planRows={planRows}
            onClose={() => setIsModalPlacingMassVisible(false)}
            loading={submitItem.isLoading}
          />
        ) : null
      }
    </>
  )
}
export default DocumentsParamsFields
