import React, { useEffect, useMemo, useState } from 'react'

import { generateColumns, generateFilters, generateRows } from './helpers'
import { DocumentsListWrapper } from '../../../components'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'

import { useGetDeliveriesList, useGetQueryTransportPlaces } from 'src/hooks'
import { IGetPackingListResponse } from 'src/hooks/packing/useGetPackingList'
import { usePackingContext } from '../../../context/PackingContext'
import { setPackingListRawAction } from '../../../store/actions/packing'
import { combineDataToFrontFormat } from '../utils'
import { IDeliveryItem } from '@/interfaces'
import { useFilter } from '../../../hooks/useFilter'


const TransportPlacesList = () => {
  const { filterState, setFilterState } = useFilter()

  const { state, dispatch } = usePackingContext()

  const [deliveriesList, setDeliveriesList] = useState<IDeliveryItem[]>([])
  const { data: deliveriesListData } = useGetDeliveriesList(setDeliveriesList)

  const {
    isFetching: transportPlacesLoading,
    data: transportPlacesData,
    refetch: refetchTransportPlaces,
  } = useGetQueryTransportPlaces(generateFilterParams(filterState), {
    onSuccess: (data) => {
      dispatch(setPackingListRawAction(combineDataToFrontFormat({
        pallets: data.pallets,
        orders: data.orders,
        places: data.places,
      })))
    }
  })

  useEffect(() => {
    refetchTransportPlaces()
  }, [filterState])

  const rows = useMemo(() => generateRows(state.list || {} as IGetPackingListResponse), [state.list])
  const columns = useMemo(() => generateColumns(), [])
  const filtersConfig = generateFilters(deliveriesList)

  return (
    <DocumentsListWrapper
      title={'Транспортные места'}
      rows={rows}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      filtersConfig={filtersConfig}
      loading={transportPlacesLoading}
      pagination={transportPlacesData?.pagination}
    />
  )
}

export default TransportPlacesList
