import React from 'react'
import { ComboboxWrapperWithApi } from '@/components'
import { IContractorDto } from '@/interfaces'
import { CommonFieldProps } from '@shared/types/fields'
import useGetContractorsList from '../../../../../hooks/contractor/useGetContractorsList'

interface IProps extends CommonFieldProps  {
  convertHookData?: (data: { contractors: IContractorDto[] }) => any
}

const ComboboxContractors = (props: IProps) => {
  const {
    label = 'Контрагент',
    placeholder = 'Выберите контрагента',
    convertHookData = (data) => {
      return data.contractors.map((el) => ({
        label: el.title,
        id: el.id,
        value: el.id,
        dto: el,
      }))
    },
    convertHookParams = (params) => ({ search: params.search }),
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      label={label}
      placeholder={placeholder}
      useGetHookSearch={useGetContractorsList}
      convertHookData={convertHookData}
      convertHookParams={convertHookParams}
      {...restProps}
    />
  )
}
export default ComboboxContractors
