import { useQuery } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

/*
 * Получение файлов для печати
 * wiki:
 * https://wiki.kak2c.ru/link/785#bkmrk-%2Fshipment%2F%7Bid%7D%2Fdocs-
 */

interface IParams {
  shipmentId: string
}

interface IResultData {
  content: string
  printer: string // base64
}
interface IResult extends ApiReqResult, IResultData {}

const getDocsForPrint = async ({ shipmentId }: IParams) => {
  const result = await api.get(`/shipment/${shipmentId}/docs-for-print`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

const useFetchShipmentDocsForPrint = () => ({
  fetch: getDocsForPrint
})

const useGetShipmentDocsForPrint = (
  params: IParams,
  callback?: (data: IResultData) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IResult>(
    [`getDocsForPrint`, params],
    () => getDocsForPrint(params),
    {
      enabled: false,
      keepPreviousData: false,
      onSuccess: ({ success, ...data }) => {
        if (success) {
          callback?.(data)
        } else {
          error?.()
        }
      },
      onError: () => error?.(),
      onSettled: () => settled?.(),
    },
  )
}
export {
  useFetchShipmentDocsForPrint,
  useGetShipmentDocsForPrint
}