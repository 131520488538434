import React, { useEffect, useMemo, useState } from 'react'
import { DocumentsListWrapper } from '@pages/OperationsAndDocumentsPage/components'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { useDeleteTransportationTemplate, useGetTransportationTemplatesList } from 'src/hooks/trasportationTemplates'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { Button } from 'src/components'
import TemplatesModal from '../TemplatesModal/TemplatesModal'
import ConfirmModal from '../../ServicesRendered/ConfirmModal/ConfirmModal'
import { useFilter } from '../../../hooks/useFilter'


const TransportationTemplatesList = () => {
  const { filterState, setFilterState } = useFilter()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [deleteDocId, setDeleteDocId] = useState<string | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const {
    isFetching: transportationLoading,
    data: transportationData,
    refetch: refetchTransportation,
  } = useGetTransportationTemplatesList(generateFilterParams(filterState))
  const { mutateAsync: deleteDoc } = useDeleteTransportationTemplate()

  useEffect(() => {
    refetchTransportation()
  }, [filterState])

  const rows = useMemo(
    () => generateRows(transportationData?.templates || []),
    [transportationData?.templates],
  )
  const handleEdit = (docId: string) => {
    setDeleteDocId(docId)
    setOpenModal(true)
  }
  const handleConfirmModal = (docId: string) => {
    setOpenConfirmModal(true)
    setDeleteDocId(docId)
  }
  const handleDelete = (docId: string | null) => {
    if (!docId) {
      return
    }
    deleteDoc({ docId })
      .then((data) => {
        refetchTransportation()
      })
      .catch(() => {})
  }
  const handleClick = () => {
    setDeleteDocId(null)
    setOpenModal(true)
  }
  const columns = useMemo(
    () => generateColumns({ handleEdit, handleConfirmModal }),
    [],
  )

  const filtersConfig = generateFilters()

  return (
    <>
      <DocumentsListWrapper
        title={'Перевозки шаблоны'}
        rows={rows}
        columns={columns}
        filterState={filterState}
        setFilterState={setFilterState}
        filtersConfig={filtersConfig}
        loading={transportationLoading}
        pagination={transportationData?.pagination}
        tableExtraHeaderRight={
          <Button label={'Добавить'} onClick={handleClick} />
        }
      />
      {openModal ? (
        <TemplatesModal
          onClose={() => setOpenModal(false)}
          isOpen={openModal}
          docId={deleteDocId}
          documents={transportationData?.templates || []}
          refetch={refetchTransportation}
        />
      ) : null}
      {openConfirmModal ? (
        <ConfirmModal
          isOpen={true}
          onClose={() => {
            setDeleteDocId(null)
            setOpenConfirmModal(false)
          }}
          onSubmit={() => {
            handleDelete(deleteDocId)
            setOpenConfirmModal(false)
            setDeleteDocId(null)
          }}
        />
      ) : null}
    </>
  )
}
export default TransportationTemplatesList
