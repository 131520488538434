import api from '@shared/api'

import { IContainer } from 'src/interfaces'


const useGetTare = () => ({
  fetch: async (barcode: string) => {
    const result = await api.get(`/warehouse/container/${barcode}`)
    if (!result?.data?.success) {
      throw result
    }
    return result.data.container as IContainer
  }
})

export default useGetTare
