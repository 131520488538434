import React from 'react'
import styles from './input-param.module.scss'
import { IReportParamProps } from '../intrefaces'
import { InputWrapper } from '../../../../../../components'

const InputParam = (props: IReportParamProps) => {
  const {
    template,
    paramValue,
    handleChange,
    type = 'text'
  } = props

  return (
    <InputWrapper
      value={paramValue.value || null}
      id={'report-param-' + template.id}
      handleChange={val => handleChange({id: template.id, value: val || undefined})}
      label={template.title}
      className={styles.input}
      type={type}
    />
  )
}

export default InputParam