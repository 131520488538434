import React, { useEffect } from 'react'
import { useGetMovingDoc } from 'src/hooks/warehouse/movingSimple'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'
import { formStatesAreEqual } from '../../../store/utils'
import {
  revertDraftStateToRawAction,
  setMovingFormDraftAction,
  setMovingFormRawAction,
  setMovingRawAction,
  setOriginalSkusAction,
} from '../../../store/actions'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { FormWrapper } from '../../../components'
import {
  MovingFormContextProvider,
  useMovingFormContext,
} from '@pages/OperationsAndDocumentsPage/context/MovingFormContext'
import { MovingFormContent } from './components'
import { IMovingFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces'
import { PATHS } from '@shared/routing'

const MovingForm = () => {
  const history = useHistory()

  const { state, dispatch } = useMovingFormContext()

  const { id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()

  const { data: movingData, refetch: refetchMovingData, isFetching: movingLoading } = useGetMovingDoc(
    { doc_id: documentId },
    {
      onSuccess: ({ document, skus }) => {
        dispatch(setMovingRawAction(document))
        dispatch(setOriginalSkusAction(skus))
      },
    },
  )

  useEffect(() => {
    const formData: IMovingFormDataState = {
      status: movingData?.document.status || '',
      comment: movingData?.document.warehouse_comment || '',
    }
    dispatch(setMovingFormDraftAction(cloneDeep(formData)))
    dispatch(setMovingFormRawAction(cloneDeep(formData)))
  }, [movingData, dispatch])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.movingList,
      }),
    )
  }


  const statesAreEqual = formStatesAreEqual<IMovingFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )
  return (
    <FormWrapper
      loading={movingLoading}
      title={state.documentRaw?.id || documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <MovingFormContent refetch={refetchMovingData} />
    </FormWrapper>
  )
}
const ServicesFormWithContext = () => (
  <MovingFormContextProvider>
    <MovingForm />
  </MovingFormContextProvider>
)
export default ServicesFormWithContext
