import React, { createContext, useContext, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { IAcceptanceFormState } from '../store/interfaces'
import { initialState, reducer } from '../store/reducer'

export interface IUseReducer {
  state: IAcceptanceFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {}

export const AcceptanceContext = createContext<IContext>({
  state: initialState,
  dispatch: (action: IReducerAction) => action,
})

export const AcceptanceContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<IAcceptanceFormState, IReducerAction>
  >(reducer, initialState)

  return (
    <AcceptanceContext.Provider value={{ state, dispatch }}>
      {children}
    </AcceptanceContext.Provider>
  )
}

export const useAcceptanceContext = () => useContext(AcceptanceContext)
