import { useQuery } from 'react-query'
import api from '@shared/api'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'
import { ApiReqResult, ICellPlace, IPaginationFilter, PaginationResult } from 'src/interfaces'

export interface IWarehouseCellsResult extends ApiReqResult, PaginationResult {
  cells: ICellPlace[]
}

export interface IWarehouseCellsParams extends IPaginationFilter {
  search?:string,
}

export const useGetWarehouseCells = (params?: IWarehouseCellsParams, options?: UseQueryOptions<any>) => {
  return useQuery<IWarehouseCellsResult>(
    [`getWarehouseCells`],
    async () => {
      const paramsStr = convertObjectToURLParams(params)
      const result = await api.get(`/warehouse/cell${paramsStr}`)
      if (!result.data?.success) {
        throw new Error(result.data.message)
      }
      return result.data
    },
    options
    /*{
      refetchOnWindowFocus: false, //TODO basic
      enabled: false,
    },*/
  ) as ({
    refetch: any
    isLoading: boolean
    isFetching: boolean
  })
}
