import { IPlacePacked, ITableColumn, ITableRow } from 'src/interfaces'

export const generatePackingColumns = (): ITableColumn[] => {
  return [
    {
      key: 'id',
      title: 'Код',
      title_txt: 'Код',
    },
    {
      key: 'order',
      title: 'Заказа клиента',
      title_txt: 'Заказа клиента',
    },
  ]
}

export const generatePackingRows = (places: IPlacePacked[]): ITableRow[] => {
  return places.map((place) => {
    return {
      id: place.id || '',
      order: place.order_id,
    }
  })
}
