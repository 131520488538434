import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { shipmentPlanInitialState, shipmentPlanReducer } from '../store/reducer/shipmentPlanReducer'
import { IShipmentPlanFormState } from '../store/interfaces/shipmentPlan'

export interface IUseReducer {
  state: IShipmentPlanFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {
}

export const ShipmentPlanFormContext = createContext<IContext>({
  state: shipmentPlanInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const ShipmentPlanFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof shipmentPlanInitialState, IReducerAction>>(shipmentPlanReducer, shipmentPlanInitialState)

  useEffect(() => {
    console.log('--- State ---', state)
  }, [state])

  return (
    <ShipmentPlanFormContext.Provider value={{ state, dispatch }}>
      {children}
    </ShipmentPlanFormContext.Provider>
  )
}

export const useShipmentPlanFormContext = () => useContext(ShipmentPlanFormContext)
