import React, { FC, useState } from 'react'
import { InputWrapper } from 'src/components'
import { useDebounce } from '@consta/uikit/useDebounce'
import { useChangeQuantityPlacing } from '@/hooks/placing'
import { Loader } from '@consta/uikit/Loader'
import styles from './QuantityCell.module.scss'

interface QuantityCellProps {
  quantity: string
  docId: string
  itemId: string
  refetchData: () => void
}

const QuantityCell: FC<QuantityCellProps> = ({ quantity, refetchData, docId, itemId }) => {
  const [quantityState, setQuantityState] = useState<string | null>(quantity)
  const { isLoading: addLoading, mutateAsync: changeQuantity } =
    useChangeQuantityPlacing()

  const sendData = async (value: string) => {
    if (value === '' || value === null) return
    if (itemId) {
      changeQuantity({
        doc_id: docId,
        item_id: itemId,
        quantity: parseInt(value),
      })
        .then(() => {
          refetchData()
        })
        .catch(() => {
          setQuantityState(quantity)
        })
    }
  }

  const debounceSetQuantityValue = useDebounce(sendData, 800)


  const handleChangeQuantity = async (value: string) => {
    setQuantityState(value)
    debounceSetQuantityValue(value)
  }
  return (
    <div className={styles.wrapper}>
      {addLoading ? <Loader className={styles.loading} /> : null}
      <InputWrapper
        isInteger
        id={'quantity'}
        handleChange={(value) => handleChangeQuantity(value)}
        value={quantityState?.toString()}
        disabled={addLoading}
      />
    </div>
  )
}

export default QuantityCell
