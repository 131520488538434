export enum FormTabs {
  documentsParams = 'documentsParams',
  additionalParams = 'additionalParams',
  relativeDocs = 'relativeDocs',
  comments = 'comments',
  historyChanges = 'historyChanges',
  discrepancy = 'discrepancy',
}
export const FormTabsDescription = {
  [FormTabs.documentsParams]: 'Параметры документа',
  [FormTabs.additionalParams]: 'Дополнительные параметры',
  [FormTabs.relativeDocs]: 'Связанные документы',
  [FormTabs.comments]: 'Комментарий',
  [FormTabs.historyChanges]: 'История изменений',
  [FormTabs.discrepancy]: 'Расхождение',
}
export interface TabsItem {
  value: FormTabs
  label: string
}

export const generateTabs = (tabsList: FormTabs[]) =>
  tabsList.map((tab) => ({ value: tab, label: FormTabsDescription[tab] }))
