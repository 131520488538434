import React, { useRef, useState } from 'react'
import { useMainContext } from '../../context/MainContext'
import { useHistory, useLocation } from 'react-router-dom'
import { ContextMenu } from '@consta/uikit/ContextMenu'
import { Button } from 'src/components'
import styles from './breadcrumbs-history.module.scss'
import { IconHamburger } from '@consta/icons/IconHamburger'
import { IconBackward } from '@consta/icons/IconBackward'
import cx from 'classnames'

interface IProps {
}

const BreadcrumbsHistory = (props: IProps) => {
  const { state, dispatch } = useMainContext()
  const history = useHistory()
  const { search } = useLocation()
  const params = new URLSearchParams(search);

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const menuRef = useRef(null)

  const prevDocumentPagePath = params.get('prev')

  return (
    <div className={styles.wrapper}>
      {
        prevDocumentPagePath ? (
          <Button
            iconLeft={IconBackward}
            label={'Вернуться'}
            onClick={() => history.push(prevDocumentPagePath)}
            className={styles.btn}
          />
        ) : null
      }
      <Button
        view={'clear'}
        width={'default'}
        label={'История навигации'}
        onClick={() => setMenuIsOpen(prev => !prev)}
        iconLeft={IconHamburger}
        className={cx(styles.btn, styles.menuBtn)}
        ref={menuRef}
        disabled={!state.breadcrumbsPaths.length}
      />
      <ContextMenu
        isOpen={menuIsOpen}
        offset={10}
        direction={'downCenter'}
        onClickOutside={() => setMenuIsOpen(false)}
        items={
          state.breadcrumbsPaths
            .map((item, i) => ({
              ...item,
              label: `${i + 1} - ${item.label}`,
              onClick: () => {
                history?.push(item.path)
              },
            }))
            .reverse()
        }
        getItemLabel={(item) => item.label}
        onClick={(e) => {
          // console.log(e.target)
        }}
        anchorRef={menuRef}
        className={styles.contextMenu}
      />
    </div>
  )
}
export default BreadcrumbsHistory
