import api from '@shared/api'
import { IDatabaseSelectOption } from '../../interfaces'

const getCorrectHost = () => {
  let host = window.location.host
  if (host.includes('localhost')) {
    host = 'front-wms-test.notasoft.ru'
  }
  return host
}
const getDatabases = async (): Promise<IDatabaseSelectOption[]> => {
  const host = getCorrectHost()
  const result = await api.get(`/?domain=${host}`, {
    baseURL: 'https://front-wms-endpoints.kak2c.ru',
  })
  if (!Array.isArray(result.data)) {
    return []
  }
  return result.data
}

const useGetDatabases = () => ({
  fetch: getDatabases
})

export default useGetDatabases
