import { Service, ServiceDocument } from 'src/interfaces'
import { IBillingFormDataState } from '../interfaces/billing'

export const SET_BILLING_RAW = 'SET_BILLING_RAW'
export const setBillingRawAction = (documentRaw: ServiceDocument | null) => {
  return {
    type: SET_BILLING_RAW,
    payload: documentRaw,
  }
}

export const SET_BILLING_FORM_STATE_DRAFT = 'SET_BILLING_FORM_STATE_DRAFT'
export const setBillingFormDraftAction = (formState: IBillingFormDataState) => {
  return {
    type: SET_BILLING_FORM_STATE_DRAFT,
    payload: formState,
  }
}

export const SET_BILLING_FORM_STATE_RAW = 'SET_BILLING_FORM_STATE_RAW'
export const setBillingFormRawAction = (formState: IBillingFormDataState) => {
  return {
    type: SET_BILLING_FORM_STATE_RAW,
    payload: formState,
  }
}

export const SET_BILLING_LIST = 'SET_BILLING_LIST'
export const setBillingListAction = (list: ServiceDocument[]) => {
  return {
    type: SET_BILLING_LIST,
    payload: list,
  }
}

export const SET_BILLING_SERVICES = 'SET_BILLING_SERVICES'
export const setBillingServicesAction = (services: Service[]) => {
  return {
    type: SET_BILLING_SERVICES,
    payload: services,
  }
}
