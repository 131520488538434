import React, { ReactNode } from 'react'
import styles from './tsd-table-wrapper.module.scss'

import cx from 'classnames'
import { ITSDTableItem, TSDTableRow } from '../TSDTableRow/TSDTableRow'
import { Loader } from '@consta/uikit/Loader'
import { TablePagination } from '../../../index'
import { IPagination } from '@/interfaces'
import { useVirtualScroll } from '@consta/uikit/useVirtualScrollCanary'
import { useForkRef } from '@consta/uikit/useForkRef'

interface ITSDTableWrapperProps {
  loading?: boolean
  header?: React.ReactNode
  title?: string
  wrapperClassName?: string
  tableClassName?: string
  maxTableHeight?: number
  rowsColumns?: ITSDTableItem[]
  pagination?: IPagination
  onChangePage?: (page: number) => void
  onRowClick?: (rowKey: string) => void
  children?: ReactNode
  tableRef?: React.RefObject<HTMLDivElement>
}

const TSDTableWrapper: React.FC<ITSDTableWrapperProps> = (props) => {
  const {
    loading,
    header,
    title,
    children,
    wrapperClassName,
    tableClassName,
    maxTableHeight,
    rowsColumns = [],
    pagination,
    onChangePage = () => {},
    onRowClick,
    tableRef
  } = props


  const { listRefs, scrollElementRef, slice, spaceTop } = useVirtualScroll({
    length: rowsColumns?.length,
    isActive: rowsColumns?.length > 100,
  });

  return (
    <div className={cx(styles.mainWrapper, wrapperClassName)}>
      {
        header
          ? header
          : title ? <h4 className={cx(styles.title)}>{title}</h4>
            : null
      }
      <div
        ref={useForkRef([scrollElementRef, tableRef])}
        className={cx(styles.tableWrapper, {[styles.withPagination]: Boolean(pagination)}, tableClassName)}
        style={{ maxHeight: maxTableHeight, marginTop: spaceTop  }}
      >
        {
          loading ? (
            <Loader className={styles.loader}/>
          ) : (
            <>
              {
                rowsColumns?.length ? (
                  rowsColumns?.slice(...slice).map((row, index) => (
                    <TSDTableRow
                      key={row.key}
                      className={cx(styles.tableItem, {[styles.pointer]: Boolean(onRowClick)})}
                      columns={row.columns}
                      onClickRow={() => onRowClick?.(row.key)}
                      itemRef={listRefs[slice[0] + index]}
                    />
                  ))
                ) : children
              }
            </>
          )
        }

        {
          pagination && pagination.total_pages > 1 ? (
            <TablePagination
              type={'input'}
              withoutHotkey={true}
              pageSize={pagination.size}
              currentPage={pagination.page}
              totalPages={pagination.total_pages}
              onChangePage={onChangePage}
              classNameWrapper={styles.pagination}
            />
          ) : null
        }
      </div>
    </div>
  )
}

export default TSDTableWrapper
