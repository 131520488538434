import React from 'react'

import styles from './item-sticker-print-modal.module.scss'

import img from './assets/sticker.svg'

import { Timer } from '@shared/ui/Timer'
import { Button } from '@consta/uikit/Button'

import { Modal } from 'src/components'

import { ModalProps } from 'src/interfaces'

interface IItemStickerPrintModal extends ModalProps {
  backTo?: string
  title?: string
  subtitle?: string
}

const ItemStickerPrintModal = (props: IItemStickerPrintModal) => {
  const {
    isOpen,
    onClose,
    title = 'Печать стикера',
    subtitle = `Приклейте стикер`,
    backTo = 'упаковке',
  } = props

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay={false}
      onOverlayClick={(): boolean => false}
      className={styles.sticker}
      title={title}
      img={img}
      subtitle={subtitle}
    >
      <Timer size="m" handleComplete={onClose} />

      <Button
        label={`Вернуться к ${backTo}`}
        view="clear"
        className={styles.btnClear}
        onClick={() => onClose()}
      />
    </Modal>
  )
}

export default ItemStickerPrintModal
