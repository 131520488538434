import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { shipmentInitialState, shipmentReducer } from '../store/reducer/shipmentReducer'
import { IShipmentFormState } from '../store/interfaces/shipment'

export interface IUseReducer {
  state: IShipmentFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {
}

export const ShipmentContext = createContext<IContext>({
  state: shipmentInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const ShipmentContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof shipmentInitialState, IReducerAction>>(shipmentReducer, shipmentInitialState)

  useEffect(() => {
    console.log('--- Shipment State ---', state)
  }, [state])

  return (
    <ShipmentContext.Provider value={{ state, dispatch }}>
      {children}
    </ShipmentContext.Provider>
  )
}

export const useShipmentContext = () => useContext(ShipmentContext)
