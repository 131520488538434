import api from '@shared/api'

import { MOCK_GET_TRANSPORT_PALLET, provideMock } from 'src/mocks'

import { IUseGetPalletResult } from 'src/interfaces'

/*
Ресурс:
- https://wiki.kak2c.ru/books/tempoline-vnutrenniy/page/api-upakovka-packing
- № 23
*/

interface useGetPalletParams {
  pallet_id: string // штрихкод паллеты
}

const getPallet = async ({ pallet_id }: useGetPalletParams) => {
  const result = await api.get(`/packing/pallet/${pallet_id}`)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

const useGetTransportPallet = () => ({
  fetch: (params: useGetPalletParams) =>
    provideMock<useGetPalletParams, IUseGetPalletResult>(getPallet, params, {
      key: 'pallet_id',
      value: 'mock',
      result: MOCK_GET_TRANSPORT_PALLET,
    })(),
})

export default useGetTransportPallet
