import { IPrintOptions, usePrint } from './print'
import { usePreviewBase64Pdf } from '@shared/hooks'
import { useFetchInventoryDocsForPrint } from '@/hooks/warehouse/inventory/useGetInventoryDocsForPrint'

/** Печать INF-3 */

interface printResult {
  printInventoryDocs: (
    inventoryId: string,
    isPreview?: boolean,
    options?: IPrintOptions,
  ) => Promise<void>
}

export const usePrintInventoryDocs = (): printResult => {
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const { printSticker } = usePrint()
  const { fetch: getInventoryDocsForPrint } = useFetchInventoryDocsForPrint()

  const printInventoryDocs = async (
    inventoryId: string,
    isPreview?: boolean,
    options: IPrintOptions = {},
  ) => {
    return await getInventoryDocsForPrint({ inventoryId })
      .then((result) => {
        if (!result) {
          return
        }
        const { content, printer } = result

        if (isPreview) {
          previewBase64Pdf(content, 'INF-3')
          return
        }

        printSticker(printer, content, {
          copies: 1,
          ...options,
        })
      })
      .catch(() => {})
  }

  return {
    printInventoryDocs,
  }
}
