export interface ISideTab {
  label: string
  value: string
}

export interface IHeaderTab {
  label: string
  value: string
  /** Разделы бокового меню */
  sideTabs: { [key: string]: ISideTab }
}

export interface ISection {
  /** Дополнительный класс стилей */
  className?: string
  /** Название раздела бокового меню */
  title?: string
}

export enum PopupZoneType {
  create = 'create',
  edit = 'edit',
}

export enum ValidateErrors {
  isEmpty = 'Поле не может быть пустым',
  notMatch = 'Значения должны совпадать по типу',
  isTooMuch = 'Начальное значение больше конечного',
}
