import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IPaginationFilter, PaginationResult } from 'src/interfaces'
import { IDocumentStatuses } from 'src/interfaces/documentStatuses'
import { convertObjectToURLParams } from '@shared/helpers'

/*
 * http://wms-test.kak2c.ru/warehouse-api/enum/document-status
 */

interface DocumentStatusesResult extends ApiReqResult, PaginationResult {
  values: IDocumentStatuses[]
}
interface getDocumentStatusesParams extends IPaginationFilter {
  type: string
}

const useGetDocumentStatuses = (paramsData: {
  params?: getDocumentStatusesParams
  handleSuccess?: (data: DocumentStatusesResult) => void
  handleSettled?: () => void
  handleError?: () => void
}) => {
  const { params, handleSuccess, handleSettled, handleError } = paramsData

  return useQuery(
    [`getDocumentStatuses`],
    async () => {
      const searchParams = convertObjectToURLParams({ ...params })
      const result = await api.get(`/enum/document-status${searchParams}`)
      return result.data
    },
    {
      onSuccess: (data: DocumentStatusesResult) => {
        if (data?.success) {
          return handleSuccess?.(data)
        }
        handleError?.()
      },
      onSettled: handleSettled,
      onError: handleError,
    },
  )
}

export default useGetDocumentStatuses
