import { IAcceptanceSku, IBarcode, ISupplyItem, ISupplyReceipt, ITableColumn, ITableRow, TrustBox } from '@/interfaces'
import React from 'react'
import styles from '../discrepancies-modal.module.scss'
import { ImageCell } from '@/components'
import { generateDiscrepancies } from '@shared/helpers/acceptance'
import { COMMON_BARCODE_TYPE } from '@shared/const/barcodes'

export const generateTableColumns = (): ITableColumn[] => {
  return [
    {
      key: 'image',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '80px',
      renderCell: ({ row }) => <ImageCell image={String(row.image)} />,
    },
    {
      key: 'title',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: 'minmax(200px, 1fr)',
      renderCell: ({ row }) => {
        const barcodes = (row.barcodes as IBarcode[]).filter((item) => item.used_in_last_supply || item.type === COMMON_BARCODE_TYPE)
        return (
          <div className={styles.cellColumn}>
            <p>{row.title}</p>
            <ul className={styles.barcodesList}>
              {barcodes.map((barcodeItem, i) => (
                <li key={barcodeItem.barcode+i}>{barcodeItem.barcode}</li>
              ))}
            </ul>
          </div>
        )
      },
    },
    {
      key: 'plan',
      title: 'План',
      title_txt: 'План',
      gridCellWidth: 'minmax(100px, 1fr)',
      thClassName: styles.secondary,
      tdClassName: styles.secondary,
      renderCell: ({ row }) => {
        if (!row.plan) {
          return null
        }
        return <>{row.plan} шт.</>
      },
    },
    {
      key: 'fact',
      title: 'Факт',
      title_txt: 'Факт',
      gridCellWidth: 'minmax(100px, 1fr)',
      thClassName: styles.primary,
      tdClassName: styles.primary,
      renderCell: ({ row }) => {
        if (!row.fact) {
          return null
        }
        return <>{row.fact} шт.</>
      },
    },
    {
      key: 'defect',
      title: 'Брак',
      title_txt: 'Брак',
      gridCellWidth: 'minmax(100px, 1fr)',
      thClassName: styles.red,
      tdClassName: styles.red,
      renderCell: ({ row }) => {
        if (!row.defect) {
          return null
        }
        return <>{row.defect} шт.</>
      },
    },
    {
      key: 'shortages',
      title: 'Недостачи',
      title_txt: 'Недостачи',
      gridCellWidth: 'minmax(100px, 1fr)',
      thClassName: styles.yellow,
      tdClassName: styles.yellow,
      renderCell: ({ row }) => {
        if (!row.shortages) {
          return null
        }
        return <>{row.shortages} шт.</>
      },
    },
    {
      key: 'surplus',
      title: 'Излишки',
      title_txt: 'Излишки',
      gridCellWidth: 'minmax(100px, 1fr)',
      thClassName: styles.blue,
      tdClassName: styles.blue,
      renderCell: ({ row }) => {
        if (!row.surplus) {
          return null
        }
        return <>{row.surplus} шт.</>
      },
    },
  ]
}

export const generateTableRows = (
  originalItems: IAcceptanceSku[],
  planItems: ISupplyItem[],
  trustBoxes: TrustBox[],
  tares: ISupplyReceipt[],
): ITableRow[] => {
  const result = generateDiscrepancies(
    originalItems,
    planItems,
    tares,
    trustBoxes
  )
  return result.flatMap(item => {
    const filterCondition = item.skuId && (item.fact !== item.plan || Number(item.plan) === Number('0'))
    return filterCondition ? [{ ...item }] : []
  })
}
