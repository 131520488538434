import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'

import { packingInitialState, packingReducer } from '../store/reducer/packingReducer'

import { IPackingFormState } from '../store/interfaces'

export interface IUseReducer {
  state: IPackingFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {
}

export const PackingContext = createContext<IContext>({
  state: packingInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const PackingContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof packingInitialState, IReducerAction>>(packingReducer, packingInitialState)

  useEffect(() => {
    console.log('--- Packing State ---', state)
  }, [state])

  return (
    <PackingContext.Provider value={{ state, dispatch }}>
      {children}
    </PackingContext.Provider>
  )
}

export const usePackingContext = () => useContext(PackingContext)
