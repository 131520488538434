import { useMutation } from 'react-query'

import api from '@shared/api'

import { provideMock } from 'src/mocks'

import { ApiReqResult, SupplyStatusEnum } from 'src/interfaces'

/*
 * Редактирование приёмки
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Postavki/operation/putSupply
 */

export interface IUpdateSupplyParams {
  supplyId: string
}

export interface IUpdateSupplyData {
  status: SupplyStatusEnum
  warehouse_comment?: string
}

const useUpdateSupply = ({ supplyId }: IUpdateSupplyParams) => {
  return useMutation(async (data: IUpdateSupplyData) => {
    const result = await provideMock<{}, { data: ApiReqResult }>(
      async () => await api.post(`/receiving/supply/${supplyId}`, data),
      {
        key: 'doc_id',
        value: 'mock',
        result: {
          data: {
            success: true,
          },
        },
      },
    )()

    if (!result?.data?.success) {
      throw result
    }
    return result?.data
  })
}

export default useUpdateSupply
