import api from '@shared/api'
import { convertObjectToURLParams } from '@shared/helpers'
import { DocumentTypeEnum, StickerResult } from '../../interfaces'
import { MOCK_STICKER_6x6, provideMock } from '../../mocks'

interface IParams {
  sku_id: string, /* идентификатор товара */
  serial_number_type: "chestniy_znak" | "imei" | "unique_number"
  document_id: string, /* номер поставки */
  document_type: DocumentTypeEnum,
  disable_billing?: boolean /* Не учитывать в билинге */
  reprint?: boolean
  value?: string
}

interface IResult extends StickerResult {

}

const useGetSerialNumbersSticker = () => ({
  fetch: async (params: IParams) =>
  provideMock(async () => {
    const paramsStr = convertObjectToURLParams(params)
    const result = await api.get(`/items/serial-number-sticker${paramsStr}`)
    if (!result?.data?.success) {
      throw result
    }
    return result.data as IResult
  },
    params,
    {
      key: 'document_id',
      value: 'mock',
      result: {
        success: true,
        sticker: {
          content: MOCK_STICKER_6x6,
          printer: 'STICKER_6x6',
        },
        stickerExists: true
      },
      logData: 'Печать ЧЗ'
    })(),

})

export default useGetSerialNumbersSticker
