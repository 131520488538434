import { generateStatusesOptions, ValueOf } from '@shared/helpers'
import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { ICombobox } from '../acceptance'

export const BillingStatusEnum: Record<string, string> = {
  new: 'NEW',
  exported: 'EXPORTED',
  calculation: 'CALCULATION',
  calculated: 'CALCULATED',
  billGeneration: 'BILL_GENERATION',
  billGenerated: 'BILL_GENERATED',
  error: 'ERROR',
} as const
export type BillingStatusEnum = ValueOf<typeof BillingStatusEnum>

export const BILLING_STATUSES: Record<BillingStatusEnum | string, string> = {
  [BillingStatusEnum.new]: 'Новый',
  [BillingStatusEnum.exported]: 'Выгружен в pas',
  [BillingStatusEnum.calculation]: 'Расчет',
  [BillingStatusEnum.calculated]: 'Рассчитан',
  [BillingStatusEnum.billGeneration]: 'Генерация счета',
  [BillingStatusEnum.billGenerated]: 'Счет сгенерирован',
  [BillingStatusEnum.error]: 'Ошибка',
}

export const BILLING_STATUS_BADGE_TYPE: Record<
  BillingStatusEnum | string,
  BadgePropStatus
> = {
  [BillingStatusEnum.new]: 'system',
  [BillingStatusEnum.exported]: 'success',
  [BillingStatusEnum.calculation]: 'system',
  [BillingStatusEnum.calculated]: 'warning',
  [BillingStatusEnum.billGeneration]: 'success',
  [BillingStatusEnum.billGenerated]: 'normal',
  [BillingStatusEnum.error]: 'error',
}

export const billingSelectOptions: ICombobox[] = generateStatusesOptions(BILLING_STATUSES)
