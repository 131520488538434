import React, { useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { PATHS } from '@shared/routing'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'
import { useNotifications } from '@shared/providers/NotificationProvider'
import { formStatesAreEqual } from '../../../store/utils'
import { revertDraftStateToRawAction, setShipmentData } from '../../../store/actions'
import { useUpdateShipment } from 'src/hooks'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'

import { FormWrapper } from '../../../components'
import { ShipmentFormContent } from './components'
import { IBatchFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces'

import {
  setShipmentFormDraftAction,
  setShipmentFormRawAction,
  setShipmentRawAction,
} from '@pages/OperationsAndDocumentsPage/store/actions/shipment'

import { useShipmentContext } from '../../../context/ShipmentContext'
import { IOrderFormDataState } from '../../../store/interfaces/order'
import useGetShipment from '@/hooks/shipment/useGetShipment'

const ShipmentForm = () => {
  const notification = useNotifications()
  const history = useHistory()
  const { state, dispatch } = useShipmentContext()

  const { id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()

  const { isLoading: isDocumentLoading, data: shipmentDataRaw } =
    useGetShipment({ shipmentId: documentId })

  const { isLoading: addLoading, mutateAsync: updateShipment } =
    useUpdateShipment()

  useEffect(() => {
    if (!shipmentDataRaw) {
      return
    }

    dispatch(setShipmentRawAction(shipmentDataRaw.shipment))
    dispatch(
      setShipmentData({
        shipments: state.allData?.shipments || [],
        orders: shipmentDataRaw.orders,
        places: shipmentDataRaw.places,
      }),
    )

    const formData: IBatchFormDataState = {
      status: shipmentDataRaw.shipment.status,
      comment: shipmentDataRaw.shipment.warehouse_comment || '',
    }
    dispatch(setShipmentFormDraftAction(cloneDeep(formData)))
    dispatch(setShipmentFormRawAction(cloneDeep(formData)))
  }, [shipmentDataRaw])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.shipmentList,
      }),
    )
  }

  const handleClickSave = async () => {
    const shipment_plans = state?.documentRaw?.shipment_plans.map((el) => el.id)
    await updateShipment({
      id: documentId,
      carrier_id: state.documentRaw?.carrier.id,
      vehicle_id: state.documentRaw?.vehicle.id,
      date: state.documentRaw?.date,
      shipment_plans: shipment_plans,
      delivery_service: state.documentRaw?.delivery_service,
      gate: { id: state.documentRaw?.gate.id || '' },
      status: state.formStateDraft?.status,
    })
      .then(() => {
        notification?.show('success', 'Статус успешно сохранен', {
          soundName: 'PACKING_COMPLETED',
        })
      })
      .catch(() => {})
  }

  const statesAreEqual = formStatesAreEqual<IOrderFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )
  return (
    <FormWrapper
      loading={isDocumentLoading}
      title={state.documentRaw?.id || documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={handleClickSave}
    >
      <ShipmentFormContent />
    </FormWrapper>
  )
}

export default ShipmentForm
