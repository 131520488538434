import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import styles from './MassPhysicalSetForm.module.scss'
import { Button, ComboboxWrapper, DimensionsInput, EnterHandler, InputWrapper } from 'src/components'
import { IDimensions, PackagingPurposes } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { useQueryGetPackagings } from 'src/hooks'
import { ComboboxPackagingsType, StateDataType } from './types'
import { convertWeight } from '@shared/helpers'


interface MassPhysicalSetFormProps {
  isDisassembling?: boolean,
  loading?: boolean
  merchantId?: string
  onClose: () => void
  onSubmit: (data: StateDataType) => void
}


const initialState: StateDataType = {
  dimensions: { width: null, height: null, depth: null },
  quantity: null,
  tare_barcode: null,
  packaging: null,
  weight: null,
}

export const MassPhysicalSetForm = (props: MassPhysicalSetFormProps) => {
  const {
    isDisassembling,
    loading,
    merchantId,
    onClose,
    onSubmit,
  } = props

  const [formState, setFormState] = useState<StateDataType>(initialState)
  const validationError = !formState.quantity || !formState.tare_barcode

  const getPackagings = useQueryGetPackagings({
    merchant_id: merchantId,
    purpose: PackagingPurposes.physicalSet,
  }, { enabled: false })
  const packagings = getPackagings?.data?.packagings

  useEffect(() => {
    if (!merchantId || getPackagings?.data) return
    getPackagings.refetch()
  }, [getPackagings, merchantId])

  const packagingsOptions: ComboboxPackagingsType[] = useMemo(() => {
    if (!packagings) return []
    return packagings.map((packaging) => ({
      id: packaging.id,
      label: packaging.title,
      requireDimensions: packaging.require_dimensions,
    }))
  }, [packagings])

  const handleClickSubmit = useCallback(() => {
    if (validationError || loading) return
    const weightG = formState.weight
      ? convertWeight('kg', 'g', Number(formState.weight))
      : undefined
    onSubmit({ ...formState, weight: `${weightG}` })
  }, [onSubmit, formState, validationError, loading])

  const handleChangeDimensions = useCallback((data: IDimensions, field: keyof IDimensions) => {
    setFormState(prev => ({ ...prev, dimensions: { ...prev.dimensions, [field]: data[field] } }))
  }, [setFormState])

  const handleChangePackagings = useCallback((data: { value: ComboboxPackagingsType | null, e: React.SyntheticEvent }) => {
    setFormState(prev => ({ ...prev, packaging: data.value }))
  }, [setFormState])

  const handleChangeInput = (value: TextFieldPropValue, key: keyof StateDataType) => {
    setFormState(prev => ({ ...prev, [key]: value }))
  }

  return (
    <EnterHandler onEnter={handleClickSubmit}>
      <div className={cx(styles.MassPhysicalSetForm)}>
        <div className={cx(styles.requiredFields)}>
          <InputWrapper
            autoFocus
            isNumber
            isRequired
            id={'quantity'}
            label={'Кол-во'}
            handleChange={(value) => handleChangeInput(value, 'quantity')}
            value={formState?.quantity}
            size='l'
          />
          <InputWrapper
            isRequired
            id={'tareBarcode'}
            label={'Тара'}
            handleChange={(value) => handleChangeInput(value, 'tare_barcode')}
            value={formState?.tare_barcode}
            size='l'
          />
        </div>

        {!isDisassembling ? (
          <>
            <ComboboxWrapper
              items={packagingsOptions}
              value={formState.packaging}
              label={'Упаковочный материал'}
              size={'l'}
              loading={getPackagings.isLoading}
              onChange={handleChangePackagings}
            />
            {
              formState?.packaging?.requireDimensions ? (
                <DimensionsInput
                  withLabelTopMargin={false}
                  value={formState?.dimensions}
                  onChange={handleChangeDimensions}
                  floatNumbersInputs
                />
              ) : null
            }
            <InputWrapper
              isNumber
              id={'weight'}
              label={'Вес'}
              handleChange={(value) => handleChangeInput(value, 'weight')}
              value={formState?.weight}
              size='l'
            />
          </>
        ) : null}

        <div className={styles.btns}>
          <Button
            view={'ghost'}
            label={'Отменить'}
            onClick={onClose}
          />
          <Button
            label={'Сохранить'}
            loading={loading}
            disabled={validationError}
            onClick={handleClickSubmit}
          />
        </div>
      </div>
    </EnterHandler>
  )
}
