import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, ICarrier, IPaginationFilter, PaginationResult } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { convertObjectToURLParams } from '@shared/helpers'

/*
 * Получить список перевозчиков
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Perevozchiki/operation/getCarriers
 */

interface GetCarriersListResult extends ApiReqResult, PaginationResult {
  carriers: ICarrier[]
}

interface Params extends IPaginationFilter {
  search?: string
  with_deleted?: boolean
}

const useGetCarriersList = (
  callback?: (data: ICarrier[]) => void,
  params: Params = {},
  options?: UseQueryOptions<any>,
) => {
  return useQuery<GetCarriersListResult>(
    [`getCarriersList`],
    async () => {
      const paramsStr = convertObjectToURLParams(params)
      const result = await api.get(`/carrier${paramsStr}`)
      return result.data as GetCarriersListResult
    },
    {
      onSuccess: ({ success, carriers }) => {
        if (success) {
          callback?.(carriers)
        }
      },
      ...options,
    },
  )
}

export default useGetCarriersList
