import React, { useMemo } from 'react'
import { FormTabs, generateTabs } from '../../../../../helpers/documentTabs'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { Comment, HistoryChanges, RelativeDocs } from '../../../../../components/CommonTabsPages'
import { updateFormInventoryStateDraftAction } from '@pages/OperationsAndDocumentsPage/store/actions/inventory'
import { TabsContentWrapper } from '../../../../../components'
import { useInventoryFormContext } from '@pages/OperationsAndDocumentsPage/context/InventoryContext'
import { DocumentsParamsFields } from '..'
import { DocumentTypeEnum } from '@/interfaces'

interface InventoryFormContentProps {
  loading?: boolean
}

const InventoryFormContent = (props: InventoryFormContentProps) => {
  const {
    page,
    id: inventoryId,
    tabId,
  } = useParams<{ page: OperAndDocumPagesType; id: string; tabId: string }>()

  const inventoryTabsWithDescription = useMemo(
    () =>
      generateTabs([
        FormTabs.documentsParams,
        FormTabs.discrepancy,
        FormTabs.relativeDocs,
        FormTabs.comments,
        FormTabs.historyChanges,
      ]),
    [page],
  )
  return (
    <TabsContentWrapper tabs={inventoryTabsWithDescription}>
      <TabPage tabId={tabId} documentId={inventoryId} />
    </TabsContentWrapper>
  )
}
interface ITabContent {
  tabId: string
  documentId: string
}

const TabPage = React.memo(({ documentId, tabId }: ITabContent) => {
  const {
    state: { documentRaw, formStateDraft },
    dispatch,
  } = useInventoryFormContext()

  switch (tabId) {
    case FormTabs.documentsParams:
      return <DocumentsParamsFields />
    case FormTabs.relativeDocs:
      return <RelativeDocs documentId={documentId} documentType={DocumentTypeEnum.INVENTORY}/>
    case FormTabs.comments:
      return (
        <Comment
          stateCommentValue={formStateDraft?.comment || ''}
          handleChangeComment={(value) =>
            dispatch(updateFormInventoryStateDraftAction('comment', value))
          }
        />
      )
    case FormTabs.historyChanges:
      return <HistoryChanges documentUUID={documentRaw?.uuid} />
    case FormTabs.discrepancy:
      return <DocumentsParamsFields view="discrepancy" />
  }
  return null
})
export default InventoryFormContent
