import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { receiptInitialState, receiptReducer } from '../store/reducer/receiptReducer'
import { IReceiptFormState } from '../store/interfaces/receipt'

export interface IUseReducer {
  state: IReceiptFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {}

export const ReceiptFormContext = createContext<IContext>({
  state: receiptInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const ReceiptFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof receiptInitialState, IReducerAction>>(receiptReducer, receiptInitialState)

  useEffect(() => {
    console.log('--- State ---', state)
  }, [state])

  return (
    <ReceiptFormContext.Provider value={{ state, dispatch }}>
      {children}
    </ReceiptFormContext.Provider>
  )
}

export const useReceiptFormContext = () => useContext(ReceiptFormContext)
