import React, { useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'
import { formStatesAreEqual } from '../../../store/utils'
import {
  revertDraftStateToRawAction,
  setBatchFormDraftAction,
  setBatchFormRawAction,
  setBatchRawAction,
} from '../../../store/actions'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { FormWrapper } from '../../../components'
import { useBatchFormContext } from '../../../context/BatchFormContext'
import { PATHS } from '@shared/routing'
import { BatchFormContent } from './components'
import { IBatchFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces'
import { BatchFormContextProvider } from '@pages/OperationsAndDocumentsPage/context/BatchFormContext'
import { useQueryGetOrderBatch } from 'src/hooks'


const BatchForm = () => {
  const history = useHistory()
  const { state, dispatch } = useBatchFormContext()

  const { id: documentId } = useParams<{ page: OperAndDocumPagesType, id: string }>()

  const {
    data: documentData,
    isFetching: isBatchLoading,
  } = useQueryGetOrderBatch(
    { batchId: documentId },
    {
      onSuccess: (data) => {
        dispatch(setBatchRawAction(data?.batch || null))
      },
    },
  )
  // todo добавить сохранение изменений
  // const updateSupply = useUpdateSupply({ supplyId })


  useEffect(() => {
    const formData: IBatchFormDataState = {
      status: documentData?.batch?.status || '',
      comment: documentData?.batch?.warehouse_comment || '',
    }
    dispatch(setBatchFormDraftAction(cloneDeep(formData)))
    dispatch(setBatchFormRawAction(cloneDeep(formData)))
  }, [documentData])


  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
      page: OperAndDocumPages.batchesList,
    }))
  }


  const statesAreEqual = formStatesAreEqual<IBatchFormDataState | null>(state.formStateDraft, state.formStateRaw)
  return (
    <FormWrapper
      loading={isBatchLoading}
      title={state.documentRaw?.id || documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <BatchFormContent />
    </FormWrapper>
  )
}

const BatchFormWithContext = () => (
  <BatchFormContextProvider>
    <BatchForm />
  </BatchFormContextProvider>
)

export default BatchFormWithContext
