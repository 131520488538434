import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IMerchant, IPaginationFilter, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'

/*
 * Получение списка продавцов:
 *  https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Prodavcy/operation/getMerchants
 */


interface merchantsListResult extends ApiReqResult, PaginationResult {
  merchants: IMerchant[]
}
interface getMerchantsListParams extends IPaginationFilter {
  /** Строка поиска (ищет по названию, ИНН и CAM ID) */
  search?: string
}

const useGetMerchantList = (paramsData: {
  params?: getMerchantsListParams,
  handleSuccess?: (data: merchantsListResult) => void,
  handleSettled?: () => void,
  handleError?: () => void,
}) => {
  const {
    params,
    handleSuccess,
    handleSettled,
    handleError,
  } = paramsData

  return  useQuery(
    [`getMerchantsList`, params],
    async () => {
      // todo тут size это hotfix
      const searchParams = convertObjectToURLParams({ size: 300, ...params })
      const result = await api.get(`/merchant${searchParams}`)
      return result.data
    },
    {
      onSuccess: (data: merchantsListResult) => {
        if (data?.success) {
          return handleSuccess?.(data)
        }
        handleError?.()
      },
      onSettled: handleSettled,
      onError: handleError,
    },
  )
}

export default useGetMerchantList
