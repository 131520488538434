import React, { useEffect, useReducer } from 'react'
import { v4 as uuidv4 } from 'uuid'

import Notifications from './Notification/Notifications'

import { useSound } from '@shared/providers/SoundProvider'
import { NotificationOptions } from '../config/schema'
import { NotificationContext } from '../config/context'
import { notificationReducer } from '../config/reducer'
import { ResponseError } from '@shared/api/types'


export const NotificationProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const sound = useSound()

  const [state, dispatch] = useReducer(notificationReducer, {})

  const show = (
    type: string,
    content: string | React.ReactNode,
    options?: NotificationOptions,
    allErrors?: ResponseError[],
  ) => {
    const id = options?.id || uuidv4()
    dispatch({
      type: 'addNotification',
      newNotification: {
        id,
        type,
        content,
        options,
        allErrors
      },
    })
  }

  useEffect(() => {
    window.showNotification = show //TODO better
    window.clearNotification = clear
  }, [])

  const clear = (id: string) => {
    const newNotifications = { ...state }
    delete newNotifications[id]

    dispatch({ type: 'removeNotification', id })
  }

  const clearAll = () => dispatch({ type: 'clearAll' })

  const playSound = (type?: string, options?: NotificationOptions) => {
    if (options?.soundName) {
      sound?.play(options?.soundName)
    } else {
      if (type === 'alert' && !options?.allWidth) {
        sound?.play('OTHER_ERROR')
      }
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        state,
        show,
        clear,
        clearAll,
        playSound,
      }}
    >
      {children}
      <Notifications />
    </NotificationContext.Provider>
  )
}
