import { useMutation } from 'react-query'
import api from '@shared/api'
import { GetTareStickersResponseTypes } from '../tare/useGetTareStickers'

interface IParams {
  id: string
}

// http://wms-test.kak2c.ru/warehouse-api/warehouse/user/sticker
const useGetQrCode = () => {
  const sendQrCode = async (params: IParams) => {
    const result = await api.post(
      `/warehouse/user/sticker`,
      [params.id],
    )

    if (result.status === 200) {
      return result.data as GetTareStickersResponseTypes
    }
  }

  return useMutation(sendQrCode)
}

export default useGetQrCode
