import { ConditionEnum, ISupplyConditionDescription } from '@/interfaces'

export const saveDocumentStubFunction = () => {
  window?.showNotification('alert', 'На данный момент сохранение еще не добавлено')
}

type SkuNameType = {
  title?: string
  article?: string

  [key: string]: any
}

export const getNomenclatureNameValue = (sku?: SkuNameType | null) => (
  `${sku?.title || ''} ${sku?.article||''}`
)

type BatchNameType = {
  num?: string
  exp_date?: string

  [key: string]: any
}
export const getBatchNameValue = (batch?: BatchNameType | null, withNum: boolean = true) => (
  `${withNum ? batch?.num || '' : ''} ${batch?.exp_date || ''}`
)

export const getConditionNameValue = (condition?: ConditionEnum) => (
  ISupplyConditionDescription[condition || '']
)