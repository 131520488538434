import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { inventoryInitialState, inventoryReducer } from '../store/reducer'
import { IInventoryFormState } from '../store/interfaces'

export interface IUseReducer {
  state: IInventoryFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {}

export const InventoryFormContext = createContext<IContext>({
  state: inventoryInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const InventoryFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof inventoryInitialState, IReducerAction>
  >(inventoryReducer, inventoryInitialState)

  useEffect(() => {
    console.log('--- State ---', state)
  }, [state])

  return (
    <InventoryFormContext.Provider value={{ state, dispatch }}>
      {children}
    </InventoryFormContext.Provider>
  )
}
export const useInventoryFormContext = () => useContext(InventoryFormContext)
