import React, { useEffect } from 'react'
import { useGetReceipt } from 'src/hooks'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'
import { formStatesAreEqual } from '../../../store/utils'
import {
  revertDraftStateToRawAction,
  setOriginalSkusAction,
  setReceiptFormDraftAction,
  setReceiptFormRawAction,
  setReceiptRawAction,
  setTrustAcceptanceBoxesAction,
} from '../../../store/actions'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { FormWrapper } from '../../../components'
import {
  ReceiptFormContextProvider,
  useReceiptFormContext,
} from '@pages/OperationsAndDocumentsPage/context/ReceiptFormContext'
import { ReceiptFormContent } from './components'
import { IReceiptFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces/receipt'
import { PATHS } from '@shared/routing'

const ReceiptForm = () => {
  const history = useHistory()
  const { state, dispatch } = useReceiptFormContext()



  const { id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()


  const { data: receiptData, isFetching: isReceiptLoading, refetch } = useGetReceipt(
    { receiptId: documentId },
    {
      onSuccess: ({ receipt, skus, trust_acceptance_boxes }) => {
        /** Задаем "чистый" документ */
        dispatch(setReceiptRawAction(receipt))
        /** Задаем оригинальные товары */
        dispatch(setOriginalSkusAction(skus))
        /** Доверенные коробки */
        dispatch(setTrustAcceptanceBoxesAction(trust_acceptance_boxes))
      },
    },
  )
  // todo добавить сохранение изменений
  // const updateSupply = useUpdateSupply({ supplyId })


  useEffect(() => {
    const formData: IReceiptFormDataState = {
      status: receiptData?.receipt?.status || '',
      comment: receiptData?.receipt?.warehouse_comment || '',
    }
    dispatch(setReceiptFormDraftAction(cloneDeep(formData)))
    dispatch(setReceiptFormRawAction(cloneDeep(formData)))
  }, [receiptData])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.receiptsList,
      }),
    )
  }

  const statesAreEqual = formStatesAreEqual<IReceiptFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )
  return (
    <FormWrapper
      loading={isReceiptLoading}
      title={state.documentRaw?.id || documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <ReceiptFormContent refetch={refetch}/>
    </FormWrapper>
  )
}

const ReceiptFormWithContext = () => (
  <ReceiptFormContextProvider>
    <ReceiptForm />
  </ReceiptFormContextProvider>
)

export default ReceiptFormWithContext
