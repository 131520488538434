import { useMutation } from 'react-query'

import api from '@shared/api'
import { ApiReqResult, ILogScanBarcodeData } from 'src/interfaces'

interface ILogScanBarcodeResult extends ApiReqResult {
  content: string
  printer: string
}

const useLogScanBarcode = () => {
  return useMutation(async (data: ILogScanBarcodeData) => {
    const result = await api.post(`/log/scan-barcode`, data)

    if (!result?.data?.success) {
      throw result
    }
    return result?.data
  })
}

export default useLogScanBarcode
