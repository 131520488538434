import React, { useEffect, useMemo, useState } from 'react'

import styles from './item-scan-serial-modal.module.scss'

import { TextFieldPropValue } from '@consta/uikit/TextField'

import { ItemPreview, Modal, ScanSerialInput, WarningModal } from 'src/components'

import { useNotifications } from '@shared/providers/NotificationProvider'

import { CommonSerialNumberFilled, DocumentTypeEnum, ISerialNumber, ModalProps } from 'src/interfaces'
import { Button } from '@consta/uikit/Button'
import { CHZ_CODE, ChzExcludeCodes } from '@shared/helpers/chz'
import { usePrintSerialNumber } from '@shared/hooks'

/**
 * Компонент: Модалка сканирования серийных номеров
 * Как работает:
 * - Поочередное сканирование серийных номеров, которые пришли на задание (itemScanSerialNumbers)
 *
 * Особенности:
 *  Переключение между полями реализовано  ̶ко̶р̶я̶в̶ы̶м̶ ̶ хитрым способом.
 *  У компонента ScanSerialInput есть 3 состояния полей ввода (Input-ов) done, undone, disabled;
 *  только одно поле может быть активным - undone, у него стоит автофокус, в остальных статусах полей
 *  ничего вводить нельзя (readonly).
 *  Таким образом при удачном вводе серийного номера и нажатии на кнопку "Подтвердить" (или автоматически), поле либо
 *  получает ошибку (значит потребуется ввести заново), либо перейдет в статус done, а следующее поле
 *  получит статутс undone и сработает автофокус на этом поле.
 *
 *  P.S. todo Хорошо бы это переписать на нормальную интуитивно понятную логики
 */

const SPECIAL_CODE_FOR_SKIP_SERIAL = '__skip__'

export type ItemScanSerialModalProps = ModalProps & {
  withPrintChz?: boolean
  withClose?: boolean
  isTSD?: boolean
  additionalStickerCount?: number
  withSkipSerialNumbers?: boolean /* Параметр, который позволяет пропустить серийные номера  todo (СТАРОЕ, убрать)  */
  /** Данные товара к которому добавляем серийные номера */
  docId?: string
  docType?: DocumentTypeEnum
  extraType?: string // иногда требуется расширение функциональности для определения действий
  itemId?: string
  itemImage?: string // изображение
  itemTitle?: string // название товара
  itemBarcodeUsed?: string // используемый ШК товара

  reprintSerialNumbers?: string[] // Коды серийных номеров для печати после сканирования
  requiredSerialNumbers?: string[] // Коды серийных обязательных номеров
  itemScanSerialNumbers?: ISerialNumber[] // Шаблоны серийных номеров для сканирования

  /** Передача заполенных серийных номеров наверх */
  onSubmit: (filledSerialNumbers: CommonSerialNumberFilled[]) => void

  /**
   * Кешированные данные сессии, для валидации сканированных серийных номеров
   * (для избавления от дублирования сериныйх номеров)
   */
  alreadyUsedSerialNumbers?: string[]
  setAlreadyUsedSerialNumbers?: (serials: string[]) => void

  // вспомогательный коллбек, чтобы делать какие-нибудь проверки
  validateCallback?: (code: string) => boolean | undefined
}


type WarningModalType = {
  title: string
  onClick: () => void
}

export enum ValidateCodesEnum {
  'tareIsDefect' = 'tareIsDefect'
}

const ItemScanSerialModal = (props: ItemScanSerialModalProps) => {
  const notification = useNotifications()

  const {
    loading,
    printSerialNumber,
  } = usePrintSerialNumber()

  const {
    withPrintChz = true,
    isTSD = false,
    withClose = true,
    isOpen,

    docId,
    docType,
    extraType,
    itemId = '',
    itemImage = '',
    itemTitle = '',
    itemBarcodeUsed = '',
    requiredSerialNumbers,
    additionalStickerCount,
    itemScanSerialNumbers = [],
    reprintSerialNumbers = [],

    alreadyUsedSerialNumbers = [],
    setAlreadyUsedSerialNumbers = () => undefined,
    validateCallback = () => undefined,

    onSubmit,
    onClose,
  } = props

  const [scannedSerialNumbers, setScannedSerialNumbers] = useState<CommonSerialNumberFilled[]>([])

  const [warningClosed, setWarningClosed] = useState<boolean>(false)
  const [warningModal, setWarningModal] = useState<WarningModalType | null>(null)
  // показываем, что напечатали ЧЗ из пула и нужно только перепечатывать, а не вытаскивать новый ЧЗ
  const [chzReprint, setChzReprint] = useState<boolean>(false)


  const warningModals = {
    'packingWithoutChz': {
      title: 'Отгрузите без ЧЗ',
      onClick: () => {
      },
    },
    'acceptWithoutChz': {
      title: 'Примите без ЧЗ',
      onClick: () => {
      },
    },
    'cancelOrder': {
      title: `Товар подлежит обязательной маркировке ЧЗ!
      Просьба обратиться к главному смены для отмены заказа под расширенными правами!`,
      onClick: onClose,
    },
    'cancelPhysicalSet': {
      title: `Необходимо подгрузить ЧЗ для продолжения комплектации`,
      onClick: onClose,
    },
    'itemAddOnlyDefectTare': {
      title: `Товар можно принять только в тару брака`,
      onClick: onClose,
    },
  }

  useEffect(() => {
    const isComplete = itemScanSerialNumbers.reduce(
      (accum, taskSerialNumber) => {
        const foundSerialNumber = scannedSerialNumbers.find(
          (scannedSerialNumber) =>
            scannedSerialNumber.code === taskSerialNumber.code,
        )
        return Boolean(accum && foundSerialNumber?.value)
      },
      true,
    )

    if (isComplete) {
      // фильтуем значения от тех значений, которые мы пропустили
      onSubmit(scannedSerialNumbers.filter(item => item.value !== SPECIAL_CODE_FOR_SKIP_SERIAL))
    }
  }, [scannedSerialNumbers])


  const requiredSerialNumbersCodes = useMemo(() => {
    /** Получаем коды обязательных серийных номеров, для совместимости с прошлой врсией серийников */
    if (requiredSerialNumbers) {
      return requiredSerialNumbers
    }
    return itemScanSerialNumbers.flatMap(serialNumber => serialNumber.required || serialNumber.required === undefined ? [serialNumber.code] : [])
  }, [requiredSerialNumbers])

  const getScannedSerialNumberByIndex = (index: number) =>
    scannedSerialNumbers[index]

  const handleReprintSerialNumber = async (value: string, code: string) => {
    /** Перепечать серийного номера (на момент написания для возвратов определенного клиента) */
    if (!reprintSerialNumbers.includes(code)) return true
    try {
      const copies: number = additionalStickerCount ? 1 + additionalStickerCount : 1
      await printSerialNumber(itemId, code, docId, docType, false, false, false, copies, value)
      return true
    } catch (e) {
      return false
    }
  }

  const validateSerialValue = (value: TextFieldPropValue) => {
    /**
     * Валидация
     * ВАЖНО (первичная валидация значения проходит в компоненте ScanSerialInput)
     */

    if (!value) return false

    const itsException = ChzExcludeCodes.includes(value)
    const isSpecialCode = SPECIAL_CODE_FOR_SKIP_SERIAL === value // не проверяем на дубликат значение "пропуск"
    if (alreadyUsedSerialNumbers.includes(value) && !itsException && !isSpecialCode) {
      notification?.show(
        'alert',
        `Ошибка сканирования - такой серийный номер уже был введён.`,
      )
      return false
    }
    if (itemBarcodeUsed === value) {
      notification?.show(
        'alert',
        `Ошибка сканирования - совпадает с шк товара.`,
      )
      return false
    }

    return true
  }

  const handleChangeValue = async (
    scanSerialNumberValue: TextFieldPropValue,
    code: string,
  ) => {
    const trimmedValue = scanSerialNumberValue?.trim()

    const isValid = validateSerialValue(trimmedValue)
    if (!isValid) return


    /** Производим перечатать, если передали список для перепечати */
    const isPrintSuccess = await handleReprintSerialNumber(trimmedValue, code)
    if (!isPrintSuccess) return
    /** Добавляем значение серийного номера в список использованных */
    setAlreadyUsedSerialNumbers(
      [...alreadyUsedSerialNumbers, trimmedValue])

    /** Добавляем в стейт серийный заполненный номер */
    const newScannedSerialNumber = {
      code,
      value: trimmedValue,
    }
    setScannedSerialNumbers((prevSerialNumbers) => [
      ...prevSerialNumbers,
      newScannedSerialNumber,
    ])
  }

  const getInputStatus = (
    index: number,
    inputSerialNumCode: string,
  ): 'done' | 'undone' | 'disabled' => {
    /**
     *  Проверяем статус инпута
     *  P.S. ничего не стал изменять, работает - не трогай
     */
    const isDone = scannedSerialNumbers.find(
      (ser) => ser?.code === inputSerialNumCode,
    )?.value
    if (isDone) {
      return 'done'
    }

    const findItemIndex =
      itemScanSerialNumbers.findIndex(
        (item) => item.code === inputSerialNumCode,
      ) ?? -1

    if (findItemIndex > -1) {
      const prevScanCode = itemScanSerialNumbers[findItemIndex - 1]?.code
      const prevScanVal = scannedSerialNumbers.find(
        (ser) => ser.code === prevScanCode,
      )?.value
      if (findItemIndex > 0 && !prevScanVal) {
        return 'disabled'
      }
    }
    return 'undone'
  }

  const getValue = (index: number) => {
    const serialNumber = getScannedSerialNumberByIndex(index)
    if (serialNumber?.value === SPECIAL_CODE_FOR_SKIP_SERIAL) {
      return ''
    }
    return serialNumber?.value || ''
  }

  const isPackingPage = docType === DocumentTypeEnum.ORDER
  const isAcceptancePage = docType === DocumentTypeEnum.SUPPLY_PLAN
  const isCrossdockingSortingPage = docType === DocumentTypeEnum.SUPPLY_SORTING
  const isPhysicalSetAssemblingPage = docType === `${DocumentTypeEnum.PHYSICAL_SET_ORDER}` && extraType === 'ASSEMBLING'
  const isPhysicalSetDisassemblingPage = docType === `${DocumentTypeEnum.PHYSICAL_SET_ORDER}` && extraType === 'DISASSEMBLING'
  const tareIsDefect = isAcceptancePage && validateCallback?.(ValidateCodesEnum.tareIsDefect)
  return (
    <>
      <Modal
        isOpen={isOpen}
        hasOverlay
        withClose={withClose}
        onClose={onClose}
        onOverlayClick={(): boolean => false}
        className={styles.itemCount}
        size='m'
      >
        <ItemPreview
          image={itemImage}
          title={itemTitle}
          barcode={itemBarcodeUsed}
          imgClassName={styles.img}
        />

        <div className={styles.wrap}>
          {itemScanSerialNumbers?.map((scanSerial: ISerialNumber, i: number) => {
            const serialIsRequired = requiredSerialNumbersCodes.includes(scanSerial.code)
            const isChz = scanSerial.code === CHZ_CODE
            const withPrintChzBtn =
              withPrintChz
              && isChz // Это ЧЗ
              && !warningClosed // Закрыли модалку продолжение без ЧЗ (в иных случайях прерывается процесс добавления товара)
              && (
                isPackingPage
                || isCrossdockingSortingPage // в сортировке кроссдоком нужна кнопка для ЧЗ
                || isPhysicalSetAssemblingPage // в сборке комплектов нужна кнопка для ЧЗ
                || (isAcceptancePage && !tareIsDefect) // в приемке только в тару брака НЕ нужна кнопка для ЧЗ
              )
            let required = (
              (tareIsDefect && isChz) // для примки в тару брака делаем ЧЗ необязательным полем
                ? false
                : warningClosed || !isChz // Если ЧЗ, то ждем момент когда закроются предупреждающие окна
                  ? serialIsRequired
                  : true
            )
            if (isPhysicalSetDisassemblingPage && isChz) {
              required = false
            }
            return (
              <ScanSerialInput
                key={`${i}-${scanSerial.code}`}
                required={required}
                loading={loading}
                chestniyZnakInput={!isTSD && withPrintChzBtn}
                chestniyZnakReprint={!isTSD && chzReprint}
                autoscan={true}
                value={getValue(i)}
                skipBtnLabel={isPhysicalSetDisassemblingPage ? "Нет ЧЗ" : 'Пропустить'}
                onChange={(val: TextFieldPropValue) => {
                  handleChangeValue(val, scanSerial.code)

                }}
                handleSkipSerial={() => {
                  handleChangeValue(SPECIAL_CODE_FOR_SKIP_SERIAL, scanSerial.code)
                }}
                handlePrintSerial={isChz ? async () => {
                  try {
                    const copies: number = additionalStickerCount ? 1 + additionalStickerCount : 1
                    await printSerialNumber(itemId, CHZ_CODE, docId, docType, chzReprint, chzReprint, false, copies)
                    setChzReprint(true)
                  } catch (e) {
                    // блок открытия модалок, если это требуется
                    if (isPackingPage) {
                      if (serialIsRequired) {
                        setWarningModal(warningModals.cancelOrder)
                        return
                      }
                      setWarningModal(warningModals.packingWithoutChz)
                    }
                    if (isPhysicalSetAssemblingPage) {
                      if (serialIsRequired) {
                        setWarningModal(warningModals.cancelPhysicalSet)
                        return
                      }
                      setWarningModal(warningModals.packingWithoutChz)
                    }
                    if (isAcceptancePage) {
                      if (!tareIsDefect) {
                        if (serialIsRequired) {
                          setWarningModal(warningModals.itemAddOnlyDefectTare)
                          return
                        }
                        setWarningModal(warningModals.acceptWithoutChz)
                      }
                    }
                    if (isCrossdockingSortingPage) {
                      // Будем принимать без чз в брак
                      setWarningModal(warningModals.acceptWithoutChz)
                    }
                  }
                } : undefined}
                status={getInputStatus(i, scanSerial.code)}
                code={scanSerial.code}
                label={`Введите код "${scanSerial.title}"`}
                template={scanSerial.template}
              />
            )
          })}
        </div>
      </Modal>

      {
        warningModal ? (
          <WarningModal
            isOpen={true}
            title={warningModal.title}
            subtitle={''}
            onClose={() => {
            }}
            size={'s'}
            customFooter={
              <>
                <br />
                <Button
                  className={styles.btn}
                  label={'ОК'}
                  onClick={() => {
                    setWarningClosed(true)
                    setWarningModal(null)
                    warningModal.onClick()
                  }}
                />
              </>
            }
          />
        ) : null
      }
    </>
  )
}

export default ItemScanSerialModal
