import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

/*
  Завершает отгрузку. Переводит её в статус DONE
  wiki:
  https://wiki.kak2c.ru/link/785#bkmrk-%2Fshipment%2F%7Bid%7D%2Fcompl-0
*/

interface IParams {
  shipmentId: string
}

const useCompleteShipment = (
  params: IParams,
  callback?: () => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useMutation(
    async () => api.post(`/shipment/${params.shipmentId}/complete`),
    {
      onSuccess: ({ data: { success } }: { data: ApiReqResult }) => {
        if (success) {
          callback?.()
        } else {
          error?.()
        }
      },
      onError: error,
      onSettled: settled,
    },
  )
}

export default useCompleteShipment
