import React, { FC } from 'react'

import ReplaceList from './ReplaceList/ReplaceList'
import ReplaceForm from './ReplaceForm/ReplaceForm'

import { ReplaceContextProvider } from '../../context/ReplaceContext'

interface ReplaceProps {
  type?: 'list' | 'form'
}

const Replace: FC<ReplaceProps> = ({
  type = 'list'
}) => {
  return (
    <ReplaceContextProvider>
      {
        type === 'list'
          ? (<ReplaceList />)
          : (<ReplaceForm />)
      }
    </ReplaceContextProvider>
  )
}

Replace.displayName = 'Replace'

export default Replace
