import React, { useEffect, useState } from 'react'
import styles from './CarryerQubyModal.module.scss'
import { CalendarInput, Modal } from 'src/components'
import { ModalProps } from 'src/interfaces'
import { Button } from '@consta/uikit/Button'
import days from 'dayjs'
import { IFields } from './types'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { ValidateErrors } from '@pages/DirectoriesPage/types'
import { DATETIME_REQUEST_FORMAT } from '@shared/const/date'
import { TextField } from '@consta/uikit/TextField'
import 'dayjs/locale/ru'
import { ITransportaions } from 'src/interfaces/transportationTemplates'

import { useUpdateCarryer } from 'src/hooks/carryerQuby'

interface IProps extends ModalProps {
  docId?: string | null
  refetch: () => void
  docList: ITransportaions[]
}

const CarryerQubyModal = (props: IProps) => {
  const { isOpen, onClose, refetch = () => {}, docId, docList = [] } = props

  const [fieldValues, setFieldValues] = useState<IFields>({
    comment: '',
    date: null,
  })
  const [fieldErrors, setFieldErrors] = useState({
    date: '',
  })
  const { mutateAsync: createQubyDoc, isLoading: isQubyDoc } =
    useUpdateCarryer()

  useEffect(() => {
    if (!docId) {
      return
    }
    const doc = docList.find((el) => el.id === docId)

    setFieldValues({
      comment: doc?.comment || '',
      date: new Date(doc?.date || ''),
    })
  }, [docId, docList])

  const handleSubmit = () => {
    if (!fieldValues.date) {
      setFieldErrors({ date: ValidateErrors.isEmpty })
      return
    }

    const data = {
      comment: fieldValues.comment || '',
      date: days(fieldValues.date).format(DATETIME_REQUEST_FORMAT) || '',
    }
    createQubyDoc({ docId, data })
      .then(async () => {
        refetch()
      })
      .catch(() => {})
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.modal}
      size="m"
      title={'Создание документа'}
    >
      <div className={styles.formWrapper}>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <CalendarInput
              label="Дата"
              id={'date'}
              handleChange={(value) => {
                setFieldErrors({ date: '' })
                setFieldValues({ ...fieldValues, date: value })
              }}
              type={'date-time'}
              value={fieldValues.date}
              size="m"
              className={styles.dateInput}
              rightSide={IconCalendar}
              backspaceReset
            />
            <div className={styles.errorMessage}>{fieldErrors.date}</div>
          </div>
        </div>
        <div className={styles.inputsRow}>
          <div className={styles.inputCell}>
            <TextField
              className={styles.textarea}
              width="full"
              label="Коментарий"
              value={fieldValues.comment}
              type="textarea"
              rows={4}
              onChange={({ value }) => {
                setFieldValues((prev) => {
                  return {
                    ...prev,
                    comment: value || '',
                  }
                })
              }}
            />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Button label="Отмена" view={'ghost'} onClick={onClose} />
        <Button label="Сохранить" view={'primary'} onClick={handleSubmit} />
      </div>
    </Modal>
  )
}
export default CarryerQubyModal
