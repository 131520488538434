import { useMutation } from 'react-query'

import api from '@shared/api'

/*
 * Удаление компании-перевозчика
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Kompanii-perevozchiki/operation/deleteCarrierCompany
 */

export interface IProps {
  gate_id: string
}

const useDeleteGate = () => {
  const deleteGate = async (props: string) => {
    const result = await api.delete(`/warehouse/gate/${props}`)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(deleteGate)
}

export default useDeleteGate
