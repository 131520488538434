import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import styles from './TableWithCopy.module.scss'
import { ITableColumn, ITableRow } from 'src/interfaces'
import { IconRemove } from '@consta/icons/IconRemove'
import { IconAdd } from '@consta/icons/IconAdd'
import { CopyTupleType, useTableCopy } from './hooks/useTableCopy'
import { useClickOutside } from '@consta/uikit/useClickOutside'
import useTableSelectHelper from './hooks/useTableSelectHelper'
import { START_INDEX } from './const'
import { SortBtn } from '../settings'
import { useSort } from '../hooks'
import { Button, ErrorBoundary, LinkContextMenu, TableHeader } from '../../index'
import { Loader } from '@consta/uikit/Loader'
import { useForkRef } from '@consta/uikit/useForkRef'
import { SortType } from '../settings/SortBtn/types/sortTypes'
import { useCustomVirtualScroll } from '@/components/Table/hooks/useCustomVirtualScroll'

type withCreateType = {
  withCreate: true
  onCreateItem: () => void
} | {
  withCreate?: false
  onCreateItem?: () => void
}

export type TableCopyProps = {
  tableRef?: React.RefObject<HTMLTableElement>
  withHeader?: boolean
  withCopy?: boolean
  withVirtualScroll?: boolean

  theme?: 'white' | 'blue' | 'gray' | 'red'

  mainWrapperClassName?: string
  tableWrapperClassName?: string
  tableClassName?: string

  title?: React.ReactNode
  subtitle?: React.ReactNode
  titleClassName?: string
  extraHeader?: React.ReactNode
  rows: ITableRow[]
  columns: ITableColumn[]
  withColumnSettings?: boolean
  isLoading?: boolean
  withVisibleToggle?: boolean
  defaultTableVisible?: boolean
  hiddenColumnsKeys?: string[]
  maxHeightBody?: number
  generateColumnsCopyValue?: (columns: ITableColumn[]) => string[]
  generateRowCopyValue?: (columns: ITableColumn[], row: ITableRow) => string[]

  onOpenLink?: (row: ITableRow) => void // срабатывает открытие ссылки на строку в текущей вкладке
} & withCreateType & SortType


type CopyRowProps = {
  startCopyRegime: boolean
  startForCopy: CopyTupleType
  endForCopy: CopyTupleType
  handleMouseOverRow: (columnIndex: string, rowIndex: string, e) => void
  handleMouseDownRow: (columnIndex: string, rowIndex: string, e) => void
}

const TableWithCopy = React.memo((props: TableCopyProps) => {
  const {
    tableRef,
    withCreate,
    onCreateItem,
    onOpenLink,

    sortByColumns,
    handleClickSortButton,

    withVirtualScroll = true,
    withHeader = true,
    withCopy = true,
    theme = 'white',
    title,
    subtitle,
    titleClassName,
    extraHeader,
    isLoading,
    mainWrapperClassName,
    tableWrapperClassName,
    tableClassName,
    columns,
    rows,
    maxHeightBody,
    withVisibleToggle,
    defaultTableVisible = true,
    hiddenColumnsKeys = [],
    withColumnSettings,
    generateColumnsCopyValue = (columns): string[] =>
      columns.map((column) => column.title_txt || `${column.title}`),
    generateRowCopyValue = (columns, row): string[] => {
      return columns.map((column) => {
        const value = row[column.copy_key || column.key]
        return value !== undefined ? String(value) : ''
      })
    },
  } = props

  const tableWrapperRef = useRef(null)

  const {
    resetCopyState,
    startCopyRegime,
    startForCopy,
    endForCopy,
    handleMouseOverRow,
    handleMouseDownRow,
  } = useTableCopy({
    tableRef: tableWrapperRef,
    withCopy,
    columns,
    rows,
    generateColumnsCopyValue,
    generateRowCopyValue,
  })


  const {
    listData,
    virtualScrollIsActive,
    handleTableScroll
  } = useCustomVirtualScroll({
    withVirtualScroll,
    rows,
    tableRef: tableWrapperRef
  })


  const { checkColumnsSelected, columnStartIndex, columnEndIndex } =
    useTableSelectHelper({ startForCopy, endForCopy })

  useClickOutside({
    isActive: !startCopyRegime,
    ignoreClicksInsideRefs: [tableWrapperRef],
    handler: resetCopyState,
  })

  const { sort, handleSortRows } = useSort({ rows })

  const [tableVisible, setTableVisible] = useState(defaultTableVisible)
  const [showColumnsSettings, setShowColumnsSettings] = useState(false)

  const [hiddenColumns, setHiddenColumns] =
    useState<string[]>(hiddenColumnsKeys)

  const handleChangeCheckbox = (columnKey: string) => {
    setHiddenColumns((prevKeys) => {
      if (prevKeys.includes(columnKey)) {
        return prevKeys.filter((prevColumnKey) => prevColumnKey !== columnKey)
      }
      return [...prevKeys, columnKey]
    })
  }

  const rowsHasSubRows = rows.some((row) => row.subItems)
  const tableHasHeader =
    withHeader && (title || extraHeader || withColumnSettings)
  return (
    <div className={cx(styles.tableMainWrapper, mainWrapperClassName)}>
      {tableHasHeader ? (
        <TableHeader
          title={title}
          subtitle={subtitle}
          extraHeader={extraHeader}
          withColumnSettings={withColumnSettings}
          tableVisible={tableVisible}
          withVisibleToggle={withVisibleToggle}
          onVisibleToggle={() => setTableVisible((prev) => !prev)}
          titleClassName={titleClassName}
          columnSettingsItems={columns
            .filter((column) => !column.isAction)
            .map((column) => ({
              key: column.key,
              label: column.title_txt,
              column,
              onClick: () => handleChangeCheckbox(column.key),
            }))}
          hiddenColumnsKeys={hiddenColumns}
          columnsMenuVisible={showColumnsSettings}
          handleChangeCheckbox={handleChangeCheckbox}
          handleCloseColumnsSetting={() => setShowColumnsSettings(false)}
          btnMenuColumnsOnClick={() => setShowColumnsSettings((prev) => !prev)}
        />
      ) : null}
      <div
        ref={useForkRef([tableWrapperRef])}
        className={cx(styles.tableWrapper, tableWrapperClassName)}
        style={{
          height: virtualScrollIsActive ? `${maxHeightBody}px` : undefined,
          maxHeight: `${maxHeightBody}px`,
          overflow: isLoading ? 'hidden' : 'auto',
        }}
        onScroll={handleTableScroll}
      >
        {isLoading ? <Loader className={styles.loading} /> : null}
        <ErrorBoundary>
          <table
            /*@ts-ignore*/
            border={1}
            ref={tableRef}
            cellPadding={0}
            cellSpacing={0}
            className={cx(styles.table, tableClassName, styles[`theme-${theme}`])}
          >
            <thead>
            <tr>
              {/* столбец для скрытия / раскрытия */}
              {rowsHasSubRows ? <th></th> : null}

              {columns.map((column, columnIndex) => {
                const Component: any = column.renderCell
                const columnSelected = checkColumnsSelected(
                  `${columnIndex}`,
                  columnStartIndex,
                  columnEndIndex,
                )
                const sortColumn = sortByColumns?.find(sortItem => sortItem.columnKey === column.key)
                return (
                  <th
                    key={columnIndex}
                    className={cx({ [styles.cellSelected]: columnSelected }, column.thClassName)}
                  >
                    <div className={cx(styles.cellWrapp, styles.headerCell)}>
                      <div
                        className={styles.cell}
                        onMouseDown={(e) => {
                          if (column.onClickCell) return
                          handleMouseDownRow(`${columnIndex}`, '0', e)
                        }}
                        onMouseOver={(e) => {
                          if (!startCopyRegime || column.onClickCell) return
                          handleMouseOverRow(`${columnIndex}`, '0', e)
                        }}
                        onClick={() => {
                          column.onClickCell?.({
                            index: 0,
                            isHeader: true,
                          })
                        }}
                      >
                        {column.withHeaderAction ? (
                          <Component
                            row={{ id: 'header' }}
                            isSubItem={false}
                            isHeader={true}
                          />
                        ) : (
                          <div>{column.title}</div>
                        )}
                      </div>

                      <SortBtn
                        active={column.sortable}
                        sort={sortByColumns ? sortColumn?.direction : sort}
                        onClick={() => handleClickSortButton
                          ? handleClickSortButton(column.key)
                          : handleSortRows(column.key)
                        }
                        className={styles.sortBtn}
                      />
                    </div>
                  </th>
                )
              })}
            </tr>
            </thead>
            <tbody>
            {
              withCreate ? (
                <tr>
                  <td colSpan={columns.length} className={styles.createRowCell}>
                    <div>
                      <Button
                        view={'ghost'}
                        label='Добавить'
                        iconLeft={IconAdd}
                        onClick={onCreateItem}
                      />
                    </div>
                  </td>
                </tr>
              ) : null
            }
            {listData.map((row, rowIndexNum) => {
              if (!row) return null
              const rowIndexStr = `${rowIndexNum + START_INDEX}`
              return (
                <TableRow
                  key={`${rowIndexStr}`}
                  row={row}
                  columns={columns}
                  isSubItem={false}
                  rowsHasSubRows={rowsHasSubRows}
                  rowIndex={rowIndexStr}
                  rowIndexForMargin={0}
                  copyProps={{
                    startCopyRegime,
                    startForCopy,
                    endForCopy,
                    handleMouseOverRow,
                    handleMouseDownRow,
                  }}
                  onOpenLink={onOpenLink}
                />
              )
            })}
            {!rows.length && !withCreate ? (
              <tr>
                <td align={'center'} colSpan={columns.length}>
                  <div className={cx(styles.emptyCell)}>Ничего нет</div>
                </td>
              </tr>
            ) : null}
            </tbody>
          </table>
        </ErrorBoundary>
      </div>
    </div>
  )
})

type TableRowProps = {
  row: ITableRow
  columns: ITableColumn[]
  isSubItem: boolean
  rowsHasSubRows: boolean
  rowIndexForMargin: number // индекс для отступов вложенности
  rowIndex: string
  spaceTop?: number
  itemRef?: React.RefObject<HTMLTableRowElement>
  copyProps: CopyRowProps
  onOpenLink?: (row: ITableRow) => void
}


/* открытие контекстного меню на правую кнопку мыши */
const TableRowWrapper = React.memo((props: TableRowProps & { children: React.ReactNode }) => {
  const { spaceTop, itemRef, row, rowIndex, children, onOpenLink } = props
  const classNameValue = cx(styles[`theme-${row.theme}`], row.className, {[styles.totalRow]: row.isTotal})
  if (row.hrefRow) {
    return (
      <LinkContextMenu
        as={'tr'}
        href={row.hrefRow}
        children={children}
        className={classNameValue}
        onOpen={() => onOpenLink?.(row)}
        itemRef={itemRef}
        style={{ marginTop: spaceTop }}
      />
    )
  }
  return (
    <tr
      style={{ marginTop: spaceTop }}
      ref={itemRef}
      data-group-index={rowIndex[0]}
      className={classNameValue}
      children={children}
      onClick={(e) => row.onClickRow?.(row, e)}
    />
  )
})

const TableRow = React.memo((props: TableRowProps) => {
  const {
    row,
    columns,
    isSubItem,
    rowsHasSubRows,
    copyProps,
    rowIndex,
    rowIndexForMargin,
    itemRef,
    copyProps: {
      startCopyRegime,
      startForCopy,
      endForCopy,
      handleMouseOverRow,
      handleMouseDownRow,
    },
  } = props

  const [isExpanded, setExpanded] = useState<boolean>(!!row.defaultIsExpanded)

  useEffect(() => {
    if (row.hardExpanded === undefined) {
      return
    }
    setExpanded(row.hardExpanded)
  }, [row.hardExpanded])

  const toggleExpand = () => {
    if (copyProps.startCopyRegime) {
      return
    }
    setExpanded((prev) => !prev)
  }

  const {
    getRowIndex,
    checkRowSelected,
    checkOccurrenceIndex,
    columnStartIndex,
    columnEndIndex,
    rowStartIndex,
    rowEndIndex,
  } = useTableSelectHelper({ startForCopy, endForCopy })

  const ExtraComponent = row.renderExtraRow
  const rowHasExpanded = Boolean(row.subItems?.length)
  return (
    <>
      <TableRowWrapper {...props} itemRef={itemRef}>
        {rowsHasSubRows ? (
          <td
            className={styles.action}
            style={{ opacity: row.opacity }}
            onClick={toggleExpand}
          >
            <div style={{ marginLeft: `${(rowIndexForMargin * 15) / 16}em` }}>
              {rowHasExpanded ? (
                isExpanded ? (
                  <IconRemove size={'s'} />
                ) : (
                  <IconAdd size={'s'} />
                )
              ) : null}
            </div>
          </td>
        ) : null}
        {
          ExtraComponent
            ? (
              <td
                colSpan={columns.length}
                key={row.id+'-1'}
              >
                <ExtraComponent row={row} />
              </td>
            )
            : columns.map((column, columnIndexNum) => {
                const Component: any = column.renderCell
                const columnIndex = String(columnIndexNum)
                const columnSelected = checkOccurrenceIndex(
                  columnIndex,
                  columnStartIndex,
                  columnEndIndex,
                )
                const rowSelected = checkRowSelected(
                  rowIndex,
                  rowStartIndex,
                  rowEndIndex,
                )

                return (
                  <td
                    key={column.key}
                    className={cx(
                      { [styles.cellSelected]: columnSelected && rowSelected },
                      column.tdClassName,
                    )}
                    style={{ opacity: column.isAction ? 1 : row.opacity }}
                    onMouseDown={(e) => {
                      if (column.onClickCell) return
                      handleMouseDownRow(columnIndex, rowIndex, e)
                    }}
                    onMouseOver={(e) => {
                      if (!startCopyRegime || column.onClickCell) return
                      handleMouseOverRow(columnIndex, rowIndex, e)
                    }}
                    onClick={() => {
                      column.onClickCell?.({
                        row,
                        index: columnIndexNum,
                        isHeader: false,
                        isExpanded,
                        toggleExpand,
                        isSubItem,
                      })
                    }}
                  >
                    <ErrorBoundary>
                      <div className={styles.cellWrapp}>
                        <div className={styles.cell}>
                          {Component ? (
                            <Component
                              row={row}
                              isSubItem={isSubItem}
                              index={columnIndexNum}
                              isExpanded={isExpanded}
                              isHeader={false}
                              toggleExpand={setExpanded}
                            />
                          ) : (
                            <div>{row[column.key]}</div>
                          )}
                        </div>
                      </div>
                    </ErrorBoundary>
                  </td>
                )
              }
            )
        }
      </TableRowWrapper>
      {rowHasExpanded && isExpanded
        ? row.subItems?.map((subrow, index) => {
          const subIndex = getRowIndex(rowIndex, index)
          return (
            <TableRow
              key={subIndex}
              row={subrow}
              columns={columns}
              itemRef={itemRef}
              isSubItem={true}
              rowIndex={`${subIndex}`}
              rowIndexForMargin={rowIndexForMargin + 1}
              rowsHasSubRows={rowsHasSubRows}
              copyProps={copyProps}
            />
          )
        })
        : null}
    </>
  )
})
export default TableWithCopy
