import { IServicesDocuments } from 'src/interfaces'
import { Badge } from '@consta/uikit/Badge'
import { OperAndDocumPages } from '../../../../pagesType'
import { Button, DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import { TableColumn } from '@consta/uikit/Table'
import styles from '../ServiceRenderedList.module.scss'
import { SERVICES_STATUS_BADGE_TYPE, SERVICES_STATUSES } from '@/interfaces/statuses'
import { IconRemove } from '@consta/icons/IconRemove'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'

export const generateRows = (document: IServicesDocuments[]) => {
  return document.map((doc) => ({
    id: doc.id,
    date: doc.create_ts,
    date_value: days(doc.create_ts).format(DATE_TIME_VIEW),
    activated: doc.activated,
    documentNum: doc.id,
    merchant: doc.merchant,
    status: doc.status,
    status_value: SERVICES_STATUSES[doc.status || ''],
    comment: doc.comment || '',
    items: doc.items,
    merchant_title: doc.merchant?.title,
    warehouse_comment: doc.warehouse_comment || '',
  }))
}
export const generateColumns = ({
  handleEdit,
  handleConfirmModal,
}: {
  handleEdit: (doc: IServicesDocuments) => void
  handleConfirmModal: (id: string) => void
}): TableColumn<any>[] => {
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{ minWidth: '120px' }} />
      },
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        if (!row.activated) {
          return <div className={styles.noactive}>{row.documentNum}</div>
        } else {
          return (
            <DocumentNumCell
              page={OperAndDocumPages.servicesRenderedForm}
              documentNum={row.documentNum}
            />
          )
        }
      },
    },
    {
      title: ' Организация',
      accessor: 'merchant_title',
    },
    {
      title: 'Статус',
      sortable: true,
      accessor: 'status_value',
      renderCell: ({row}) => {
        const status = row.status
        return (
          <>
            <Badge
              status={SERVICES_STATUS_BADGE_TYPE[status]}
              label={SERVICES_STATUSES[status]}
            />
          </>
        )
      },
    },
    {
      title: ' Комментарий',
      accessor: 'comment',
    },
    {
      title: ' Комментарий склада',
      accessor: 'warehouse_comment',
    },

    {
      title: 'Удаление',
      accessor: 'actions',
      align: 'right',
      renderCell: ({row}: any) => {
        return (
          <div className={styles.actionsBtns}>
            {/* <Button
              onlyIcon
              view={'ghost'}
              size={'s'}
              iconLeft={IconEdit}
              onClick={() => handleEdit(row)}
            /> */}
            <Button
              onlyIcon
              view={'ghost'}
              size={'s'}
              iconLeft={IconRemove}
              onClick={() => handleConfirmModal(row.id)}
            />
          </div>
        )
      },
    },
  ]
}
