import React, { JSX } from 'react'

import styles from './GeneratorFields.module.scss'
import cx from 'classnames'
import {
  FieldRow,
  FieldType,
  GenerateFieldType,
  isBool,
  isCalendar,
  isCell,
  isCombobox,
  isContractors,
  isDocument,
  isGroup,
  isInput,
  isJson,
  isMerchants,
  isSku,
  isSkuBatch,
  isTable,
  isUrl,
  isWarehouseContainer,
  isWarehousePlace,
} from './types'
import { CalendarInput, ComboboxWrapper, InputWrapper, SwitchWrapper } from '@/components' // todo переделать на shared
import { GeneratorValuesPreview } from '@widgets/GeneratorValuesPreview'
import { convertValuesToView } from './utils'
import { ComboboxMerchants } from '@entities/Merchant'
import { ComboboxCells } from '@entities/Cell'
import { ComboboxSkuBatch, ComboboxSkus } from '@entities/Sku'
import { GroupField } from '@shared/ui/fields/GroupField/GroupFields'
import { DefaultItem } from '@consta/uikit/Combobox'
import { TableField } from '@shared/ui/fields/TableField/TableField'
import { JsonField } from '@shared/ui/fields/JsonField/JsonField'
import { ComboboxDocumentsSearchApi } from '@entities/Document'
import { ComboboxContractors } from '@entities/Contractor'
import { ComboboxWarehousePlace } from '@entities/WarehousePlace'
import { ComboboxWarehouseContainer } from '@entities/WarehouseContainer'


type ComboboxFieldType = {
  Component: (props: any) => JSX.Element
  extraProps?: Record<string, any>
  conditionFn: (data: GenerateFieldType) => boolean
}

type valueType = any
export type GeneratorChangeType = {
  fieldId: string
  value?: valueType
  filedType?: FieldType
}
export type onChangeType = { onChange: (data: GeneratorChangeType) => void }
export type GeneratorStateType = Record<string, valueType>

type GeneratorFieldDataType = GenerateFieldType & {
  valuesState: GeneratorStateType
  disabled?: boolean
} & onChangeType


interface GeneratorFieldsProps {
  visible: boolean
  disabled?: boolean
  /** Поля фильтров */
  fieldsConfig: FieldRow[]
  /** Стейт фильтров */
  valuesState: GeneratorStateType
  /**
   * Стейт черновика фильтров
   * Если требуется отображать превью фильтров (теги) не сразу,
   * а по какому-то действию (например после нажатия кнопки Поиск), то
   * filterStateDraft является стейтом для начальных данных, а filterState
   * для конечных данных
   */
  valuesStateDraft?: GeneratorStateType
  /** Обновить основной стейт */
  onChangeField: (fieldId: string, value?: valueType) => void
  /** Обновить стейт черновика */
  onChangeFieldDraft?: (fieldId: string, value: any, type: FieldType) => void
  wrapperClassName?: string
  excludeFields?: string[]
  visibleValuesPreview?: boolean
}

type GeneratorRowFieldsType = { valuesState: GeneratorStateType; disabled: boolean } & onChangeType & FieldRow

const GeneratorFields = (props: GeneratorFieldsProps) => {
  const {
    visible,
    disabled = false,
    fieldsConfig,
    wrapperClassName,
    valuesState,
    valuesStateDraft,
    excludeFields,
    onChangeField,
    onChangeFieldDraft,
    visibleValuesPreview = true,
  } = props

  const handleChange = (data: GeneratorChangeType) => {
    if (onChangeFieldDraft) {
      onChangeFieldDraft(data.fieldId, data.value, data.filedType)
      return
    }
    onChangeField(data.fieldId, data.value)
  }

  const valuesView = convertValuesToView(
    valuesState,
    fieldsConfig,
    excludeFields,
  )

  return (
    <div className={cx(styles.filtersBlockWrapper, wrapperClassName)}>
      {visible
        ? fieldsConfig
          .filter((field) => !field.hidden)
          .map((filterRow, i) => (
            <GeneratorRowFields
              key={i}
              {...filterRow}
              valuesState={
                valuesStateDraft !== undefined
                  ? valuesStateDraft
                  : valuesState
              }
              onChange={handleChange}
              disabled={disabled}
            />
          ))
        : null}

      {visibleValuesPreview ? (
        <GeneratorValuesPreview
          withResetAll={false}
          onResetValue={(fieldId) => onChangeField(fieldId, undefined)}
          valuesPreview={valuesView}
        />
      ) : null}
    </div>
  )
}
const GeneratorRowFields = (data: GeneratorRowFieldsType) => (
  /** Строка полей */
  <div
    className={cx(
      styles.filterRow,
      data.flexSpaceBetween ? styles.spaceBetween : '',
      data.extraClassName,
    )}
  >
    {data.fields.map(
      ({ fieldWrapperClassName, jointFields, ...fieldProps }, i) => (
        <React.Fragment key={i}>
          {jointFields ? (
            /** Слитые поля вместе, без раздедений */
            <GeneratorRowFields
              valuesState={data.valuesState}
              fields={jointFields}
              extraClassName={styles.jointFilterRow}
              onChange={data.onChange}
              disabled={data.disabled}
            />
          ) : (
            /** Выбранное поле */
            <div className={cx(styles.fieldWrapper, data.extraClassName)}>
              <GeneratorField
                {...fieldProps}
                valuesState={data.valuesState}
                onChange={data.onChange}
                disabled={data.disabled}
              />
            </div>
          )}
        </React.Fragment>
      ),
    )}
  </div>
)

const GeneratorField = (data: GeneratorFieldDataType) => {
  /** Выбор нужного компонента по типу */

  if (!data.fieldProps || !data.type) {
    return null
  }
  const value = data.valuesState[data.fieldProps.id]
  const commonProps = {
    value,
    disabled: data.disabled || data.fieldProps.readOnly,
    readOnly: data.disabled || data.fieldProps.readOnly,
    isRequired: data.fieldProps.isRequired,
    className: cx(styles.fieldClassName, data.fieldProps.className),
  }

  const onChangeCombobox = (valueObj: DefaultItem | null) => {
    if (!data.fieldProps) {
      console.error('Ошибка в поле изменения, нет пропсов поля')
      return
    }
    data.fieldProps.onChange?.(valueObj)
    data.onChange({
      value: valueObj,
      filedType: data.type,
      fieldId: data.fieldProps.id,
    })
  }

  /* Комбобоксы с api search */
  const comboboxFields: ComboboxFieldType[] = [
    { Component: ComboboxWrapper, extraProps: {onChange: ({value}) => onChangeCombobox(value)}, conditionFn: isCombobox },
    { Component: ComboboxSkuBatch, conditionFn: isSkuBatch },
    // Api search
    { Component: ComboboxContractors, conditionFn: isContractors },
    { Component: ComboboxMerchants, conditionFn: isMerchants },
    { Component: ComboboxSkus, conditionFn: isSku },
    { Component: ComboboxCells, conditionFn: isCell },
    { Component: ComboboxWarehousePlace, conditionFn: isWarehousePlace },
    { Component: ComboboxWarehouseContainer, conditionFn: isWarehouseContainer },
    { Component: ComboboxDocumentsSearchApi, conditionFn: isDocument },
  ]
  const comboboxField = comboboxFields.find(comboboxEl => comboboxEl.conditionFn(data))
  if (comboboxField) {
    return (
      <comboboxField.Component
        {...data.fieldProps}
        {...commonProps}
        className={''} // костыль, чтобы обойти дефолтный пропс
        wrapperClassName={cx(styles.fieldClassName, data.fieldProps.className)}
        onChange={onChangeCombobox}
        {...comboboxField.extraProps}
      />
    )
  }
  if (isInput(data)) {
    return (
      <InputWrapper
        {...data.fieldProps}
        {...commonProps}
        handleChange={(value, type) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(value, type)
          data.onChange({
            value,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isUrl(data)) {
    return (
      <InputWrapper
        {...data.fieldProps}
        {...commonProps}
        link={commonProps.value}
        handleChange={(value, type) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(value, type)
          data.onChange({
            value,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isCalendar(data)) {
    return (
      <CalendarInput
        {...data.fieldProps}
        {...commonProps}
        value={value ? new Date(value) : null}
        handleChange={(value) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(value)
          data.onChange({
            value,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isBool(data)) {
    return (
      <SwitchWrapper
        {...data.fieldProps}
        {...commonProps}
        onChange={(boolValue) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(boolValue)
          data.onChange({
            value: boolValue,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isGroup(data)) {
    return (
      <GroupField
        {...data.fieldProps}
        {...commonProps}
        onSubmit={(valeArr) => {
          data.fieldProps.onChange?.(valeArr)
          data.onChange({
            value: valeArr,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isTable(data)) {
    return (
      <TableField
        {...data.fieldProps}
        {...commonProps}
        onSubmit={(value) => {
          data.fieldProps.onChange?.(value)
          data.onChange({
            value: value,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isJson(data)) {
    return (
      <JsonField
        {...data.fieldProps}
        {...commonProps}
        onSubmit={(vale) => {
          data.fieldProps.onChange?.(vale)
          data.onChange({
            value: vale,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  return null
}

export { GeneratorFields, GeneratorField }
