import React, { useMemo } from 'react'
import styles from './documents-params.module.scss'
import { InputWrapper } from 'src/components'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../../pagesType'
import { useMainFormContext } from '../../../../../../context/SupplyFormContext'
import { aggregateSupplyInfo, generateColumns, generateRows } from './utils'
import { DocumentTypeEnum } from 'src/interfaces'
import { CommentCollapse, DocumentsParams, DocumentStatusField } from '../../../../../../components'
import { updateFormStateDraftAction } from '../../../../../../store/actions'
import cx from 'classnames'

interface SupplyFormContentProps {
}

const DocumentsParamsWrapper = (props: SupplyFormContentProps) => {
  const { page, id: supplyId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()

  const { state, dispatch } = useMainFormContext()

  const rows = useMemo(
    () => generateRows(state.supplyRaw, state.originalSkus),
    [state.supplyRaw, state.originalSkus],
  )
  const columns = useMemo(() => generateColumns(), [])

  const { plan, quantity } = useMemo(() => aggregateSupplyInfo(rows), [rows])

  const hasDiscrepancies = rows.some(row => row.surplus || row.shortages)
  return (
    <DocumentsParams
      rows={rows}
      columns={columns}
      tableExtraHeader={
        <div className={cx(styles.count, { [styles.red]: hasDiscrepancies })}>{`Принято ${quantity} из ${plan}`} штук</div>
      }
    >
      <div className={styles.fieldsWrapper}>
        <div className={styles.fields}>
          <div>
            <DocumentStatusField
              value={state.formStateDraft?.status}
              onChange={(optionValue) =>
                dispatch(updateFormStateDraftAction('status', optionValue?.value))
              }
              documentType={page as DocumentTypeEnum}
            />

            <InputWrapper
              value={state.supplyRaw?.id || supplyId}
              id={'supplyId'}
              label={'Номер документа'}
              className={styles.itemField}
              disabled={true}
            />
            <InputWrapper
              value={state.supplyRaw?.accompanying_doc_num}
              id={'accompanying_doc_num'}
              label={'Номер сопроводительного документа'}
              className={styles.itemField}
              disabled={true}
            />
          </div>
          <div>
            <InputWrapper
              id={'merchant'}
              label={'Организация'}
              value={state.supplyRaw?.merchant?.title || null}
              className={styles.itemField}
              disabled={true}
            />
            <InputWrapper
              id={'supplier'}
              label={'Поставщик'}
              value={state.supplyRaw?.supplier?.title || null}
              className={styles.itemField}
              disabled={true}
            />
          </div>
        </div>
        <CommentCollapse
          comment={state.supplyRaw?.comment}
        />
      </div>
    </DocumentsParams>
  )
}
export default DocumentsParamsWrapper
