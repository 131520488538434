import {
  REVERT_FORM_STATE_TO_RAW,
  SET_BILLING_FORM_STATE_DRAFT,
  SET_BILLING_FORM_STATE_RAW,
  SET_BILLING_LIST,
  SET_BILLING_RAW,
  SET_BILLING_SERVICES,
  UPDATE_FORM_STATE_DRAFT,
  UPDATE_FORM_STATE_RAW,
} from '../actions'

import { IReducerAction } from 'src/interfaces'
import { cloneDeep } from '@shared/helpers'
import { IBillingFormState } from '../interfaces'

export const billingInitialState: IBillingFormState = {
  documentRaw: null,
  formStateRaw: null,
  formStateDraft: null,
  list: null,
  services: null,
}

export const billingReducer = (
  state: IBillingFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    case SET_BILLING_RAW:
      return { ...state, documentRaw: payload }

    case SET_BILLING_FORM_STATE_DRAFT:
      return { ...state, formStateDraft: payload }

    case SET_BILLING_FORM_STATE_RAW:
      return { ...state, formStateRaw: payload }

    case SET_BILLING_LIST:
      return { ...state, list: payload }

    case SET_BILLING_SERVICES:
      return { ...state, services: payload }

    case UPDATE_FORM_STATE_DRAFT:
      return {
        ...state,
        formStateDraft: {
          ...(state.formStateDraft || {}),
          [payload.key]: payload.value,
        },
      }

    case UPDATE_FORM_STATE_RAW:
      return {
        ...state,
        formStateRaw: {
          ...(state.formStateRaw || {}),
          [payload.key]: payload.value,
        },
      }

    case REVERT_FORM_STATE_TO_RAW:
      return {
        ...state,
        formStateDraft: cloneDeep(state.formStateRaw),
      }

    default:
      return state
  }
}
