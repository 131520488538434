import { HistoryChangesDescrType, ITableColumn, ITableRow } from 'src/interfaces'
import React from 'react'
import { DateCell, UserCell } from 'src/components'
import { getFullPersonName, HistoryChangesListResult } from '@shared/helpers'

export const generateColumns = (): ITableColumn[] => [
  {
    key: 'date',
    title: 'Дата',
    title_txt: 'Дата',
    gridCellWidth: '140px',
    renderCell: ({ row }: any) => {
      return <DateCell date={row.date} />
    },
  },
  {
    key: 'user',
    title: 'Пользователь',
    title_txt: 'Пользователь',
    copy_key: 'user_copy_txt',
    renderCell: ({ row }: any) => {
      return <UserCell user={row.user} />
    },
  },
  {
    key: 'workTable',
    title: 'Рабочее место',
    title_txt: 'Рабочее место',
    gridCellWidth: '160px',
  },
  {
    key: 'valueType',
    title: 'Значение',
    title_txt: 'Значение',
    renderCell: ({ row }: any) => {
      return <b>{row.valueType}</b>
    },
  },
  {
    key: 'was',
    title: 'Было',
    title_txt: 'Было',
  },
  {
    key: 'became',
    title: 'Стало',
    title_txt: 'Стало',
  }
]

export const generateRows = (changes: HistoryChangesListResult[]): ITableRow[] => {
  return changes.map((change, i) => {
    const actionDescr = HistoryChangesDescrType[change.action as keyof typeof HistoryChangesDescrType]
    return {
      id: `${i}`,
      date: change.date,
      user: change.user,
      user_copy_txt: `${change.user?.login || ''} ${getFullPersonName(change.user || {})}`,
      workTable: change.workspace,
      valueType: `${actionDescr} - ${change.field}`,
      was: change.before,
      became: change.after,
    }
  })
}
