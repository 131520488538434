import api from '@shared/api'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'
import { PartialInventoryRequestResult } from 'src/interfaces'


const deletePartInventoryItem = async (params: { itemId: string, quantity: number }) => {
  const result = await api.delete(`/inventory-cell/item/${params.itemId}`, { data: { quantity: params.quantity } })
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

const useDeletePartInventoryItem = (
  options?: UseMutationOptions<any, PartialInventoryRequestResult, any>,
) => {
  return useMutation(deletePartInventoryItem, options)
}

export default useDeletePartInventoryItem
