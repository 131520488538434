import React, { useEffect, useMemo, useState } from 'react'
import { useAppContext } from '@shared/providers/AppContextProvider'
import { useParams } from 'react-router-dom'
import { useGetMerchantList, useGetOrderList } from 'src/hooks'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { DocumentsListWrapper } from '../../../components'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { IOrder, OrderStatusEnum } from 'src/interfaces'
import { useFilter } from '../../../hooks/useFilter'


const OrderPlaceList = () => {
  const { page } = useParams<any>()
  const { setGlobalLoading, advanceMode } = useAppContext()

  const { filterState, setFilterState } = useFilter()
  const [ordersList, setOrdersList] = useState<IOrder[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { data: merchantData } = useGetMerchantList({})


  const { fetch: refetchOrders } = useGetOrderList()

  useEffect(() => {
    if (ordersList.length || loading) {
      return
    }
    setLoading(true)
    refetchOrders(
      generateFilterParams({ ...filterState, status: { value: OrderStatusEnum.shipped } })
    ).then((data) => {
      setOrdersList(data)
    }).finally(() => {
      setLoading(false)
    })
  }, [filterState])

  const rows = useMemo(() => generateRows(ordersList || []), [ordersList])
  const columns = useMemo(() => generateColumns(), [])
  const filtersConfig = generateFilters(merchantData?.merchants || [])
  return (
    <DocumentsListWrapper
      title={'Грузовое место'}
      rows={rows}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      filtersConfig={filtersConfig}
      loading={loading}
    />
  )
}

export default OrderPlaceList
