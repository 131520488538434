import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IPaginationFilter, PaginationResult } from 'src/interfaces'
import { IPaymentMethods } from 'src/interfaces/paymentMethods'
import { convertObjectToURLParams } from '@shared/helpers'

/*
 * http://wms-test.kak2c.ru/warehouse-api/enum/payment-type
 */

interface PaymentMethodsResult extends ApiReqResult, PaginationResult {
  values: IPaymentMethods[]
}
interface getPaymentMethodsParams extends IPaginationFilter {
  search?: string
}

const useGetPaymentMethods = (paramsData: {
  params?: getPaymentMethodsParams
  handleSuccess?: (data: PaymentMethodsResult) => void
  handleSettled?: () => void
  handleError?: () => void
}) => {
  const { params, handleSuccess, handleSettled, handleError } = paramsData

  return useQuery(
    [`getPaymentMethods`],
    async () => {
      const searchParams = convertObjectToURLParams({ size: 300, ...params })
      const result = await api.get(`/enum/payment-type${searchParams}`)
      return result.data
    },
    {
      onSuccess: (data: PaymentMethodsResult) => {
        if (data?.success) {
          return handleSuccess?.(data)
        }
        handleError?.()
      },
      onSettled: handleSettled,
      onError: handleError,
    },
  )
}

export default useGetPaymentMethods
