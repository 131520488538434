import api from '@shared/api'
import { IPacking } from 'src/interfaces/packing'
import {
  ApiReqResult,
  IOrder,
  IPaginationFilter,
  IPlacePacked,
  PaginationResult,
  palletStatuses,
} from 'src/interfaces'
import { useQuery } from 'react-query'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'

export interface IGetPackingListResponse {
  pallets: IPacking[]
  orders:  IOrder[]
  places:  IPlacePacked[]
}

export const useGetPackingList = () => ({
  fetch: async () => {
    const result = await api.get(`/packing/pallet`)
    if (!result.data?.success) {
      throw result.data
    }
    return {
      pallets: result.data?.pallets || [],
      orders: result.data?.orders || [],
      places: result.data?.places || [],
    } as IGetPackingListResponse
  },
})



interface IParams extends IPaginationFilter {
  status?: palletStatuses
  processing_by?: string
  from?: string
  to?: string
}
interface IResult extends ApiReqResult, PaginationResult, IGetPackingListResponse {

}

export const useGetQueryTransportPlaces = (
  params: IParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IResult>(
    [`getQueryTransportPlaces`],
    async () => {
      const searchParams = convertObjectToURLParams(params)
      const result = await api.get(`/packing/pallet${searchParams}`)
      return result.data
    },
    {
      keepPreviousData: true,
      ...options
    },
  )
}

