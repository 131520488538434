import { IShipmentPlan } from 'src/interfaces'
import { IShipmentPlanFormDataState } from '../interfaces/shipmentPlan'

export const SET_PLAN_RAW = 'SET_PLAN_RAW'
export const setShipmentPlanRawAction = (documentRaw: IShipmentPlan | null) => {
  return {
    type: SET_PLAN_RAW,
    payload: documentRaw,
  }
}

export const SET_PLAN_FORM_STATE_DRAFT = 'SET_PLAN_FORM_STATE_DRAFT'
export const setShipmentPlanFormDraftAction = (formState: IShipmentPlanFormDataState) => {
  return {
    type: SET_PLAN_FORM_STATE_DRAFT,
    payload: formState,
  }
}

export const SET_PLAN_FORM_STATE_RAW = 'SET_PLAN_FORM_STATE_RAW'
export const setShipmentPlanFormRawAction = (formState: IShipmentPlanFormDataState) => {
  return {
    type: SET_PLAN_FORM_STATE_RAW,
    payload: formState,
  }
}

