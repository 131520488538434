import React, { ReactNode, useRef, useState } from 'react'
import styles from './Popover.module.scss'
import { Button, ButtonProps } from '../index'
import { Text } from '@shared/ui/Text'
import { Card } from '@consta/uikit/Card'
import { Popover as ConstaPopover, Props as PopoverProps } from '@consta/uikit/Popover'

interface TooltipProps extends Omit<PopoverProps, 'children'| 'anchorRef'> {
  tooltipText: ReactNode
  btnProps: ButtonProps
}

const Popover: React.FC<TooltipProps> = (props) => {
  const {
    btnProps,
    tooltipText,
    ref,
    ...restProps
  } = props

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleClickOnAnchor = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };
  return (
    <>
      <Button
        {...btnProps}
        onClick={(e) => {
          btnProps.onClick?.(e)
          handleClickOnAnchor()
        }}
        ref={anchorRef}
      />
      {isPopoverVisible && (
        <ConstaPopover
          direction="upCenter"
          offset="2xs"
          arrowOffset={0}
          placeholder='Поповер'
          {...restProps}
          anchorRef={anchorRef}
          className={styles.tooltip}
          onClickOutside={() => setIsPopoverVisible(false)}
        >
          <Card className={styles.cardText}>
            <Text size="s">
              {tooltipText}
            </Text>
          </Card>
        </ConstaPopover>
      )}
    </>
  )
}

export default Popover
