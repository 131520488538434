import React, { useCallback, useMemo, useState } from 'react'

import styles from './mass-printing-modal.module.scss'
import { Modal } from '@/components'

import { IAcceptanceSku, IAcceptanceSkuObj, ISupplyItem, ModalProps } from '@/interfaces'
import { Button } from '@consta/uikit/Button'
import { UnProcessed } from '../tables'
import SelectBatchModal from '../../ItemAdding/SelectBatchModal/SelectBatchModal'
import { BatchItemsValuesType, CreateModalType, PrintItemBatchType, PrintItemsValuesType } from '../../../types'

interface ItemRemovingProps extends ModalProps {
  originalSkus: IAcceptanceSku[]
  originalSkusObj: IAcceptanceSkuObj
  supplyItems: ISupplyItem[]
  onSubmit: (tableData: PrintItemsValuesType, batchItemsValues: BatchItemsValuesType) => void
  updateOriginalSku: (newItems: IAcceptanceSku[]) => void
}

const MassPrintingModal = (props: ItemRemovingProps) => {
  const {
    isOpen,
    originalSkus,
    originalSkusObj,
    supplyItems,
    onClose,
    onSubmit,
    updateOriginalSku
  } = props

  const [printItemsValues, setPrintItemsValues] = useState<PrintItemsValuesType>({})

  const [createBatchItem, setCreateBatchItem] = useState<CreateModalType | null>(null)
  const [batchItemsValues, setBatchItemsValues] = useState<BatchItemsValuesType>({})

  const originalSkuForCreateBatch = useMemo(() => {
    if (!createBatchItem) {
      return null
    }
    //
    return originalSkusObj[createBatchItem.skuId]
  }, [createBatchItem, originalSkusObj])


  const resultPrintItems = useMemo(() => {
    return Object.keys(printItemsValues).reduce((acc, supplyItemId) => {
      const value = printItemsValues[supplyItemId as keyof object]
      if (value) {
        acc[supplyItemId as keyof object] = value
      }
      return acc
    }, {} as PrintItemsValuesType)
  }, [printItemsValues])

  const handleSetPrintQuantity = useCallback((supplyItemId: string, skuId: string, value?: number) =>
    setPrintItemsValues(prev => ({
      ...prev,
      [supplyItemId]: { skuId, copies: value },
    })),
    []
  )

  const handleClickApplyAll = () => {
    setPrintItemsValues(supplyItems.reduce((acc, sup) => ({ ...acc, [sup.id]: { skuId: sup.sku_id, copies: sup.quantity } }), {}))
  }

  // задаем данные по выбранным партиям
  const setSelectedBach = (skuId: string, batchData: PrintItemBatchType) => {
    setBatchItemsValues(prev => {
      if (!prev) {
        return { [skuId]: [batchData] }
      }
      const prevSkuData = (prev[skuId] || []).filter(el => el.supplyItemId !== batchData.supplyItemId)
      return ({
        ...prev,
        [skuId]: [...prevSkuData, batchData],
      })
    })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        hasOverlay
        title={'Печать стикеров товара'}
        titleAlign={'left'}
        onOverlayClick={(): boolean => false}
        className={styles.modal}
        size='m-plus'
      >
        <div className={styles.body}>
          <UnProcessed
            batchSelectMode
            quantityMode
            batchItemsValues={batchItemsValues}
            tableExtraHeader={
              <Button
                label={'Вставить все значения'}
                view={'primary'}
                onClick={handleClickApplyAll}
                size={'m'}
              />
            }
            openTareId={null}
            originalSkusObj={originalSkusObj}
            maxHeightBody={500}
            supplyItems={supplyItems}
            printItemsValues={printItemsValues}
            handleSetPrintQuantity={handleSetPrintQuantity}
            handleChooseItem={() => {
            }}
            handleSetItemBatch={setSelectedBach}
            handleCreateItemBatch={(skuId, supplyItemId) => {
              setCreateBatchItem({ skuId, supplyItemId })
            }}
          />
          <div className={styles.btns}>
            <Button
              label={'Отменить'}
              view={'ghost'}
              onClick={onClose}
              size={'m'}
            />
            <Button
              label={'Напечатать'}
              view={'primary'}
              disabled={!Object.keys(resultPrintItems).length}
              onClick={() => onSubmit(resultPrintItems, batchItemsValues)}
              size={'m'}
            />
          </div>
        </div>
      </Modal>

      {
        originalSkuForCreateBatch ? (
          <SelectBatchModal
            isOpen={true}
            onlyCreate={true}
            expdate={originalSkuForCreateBatch.batch_expdate_mandatory}
            item={originalSkuForCreateBatch}
            onSubmit={(batch, isNewBatch) => {
              // Задаем данные
              setSelectedBach(createBatchItem.skuId, {batchId: batch.id, supplyItemId: createBatchItem.supplyItemId})
              // Обновляем все оригинальные товары
              updateOriginalSku(
                originalSkus.map((item: IAcceptanceSku) => ({
                  ...item,
                  batches:
                    item.id === originalSkuForCreateBatch.id
                      ? [...(item.batches || []), batch]
                      : item.batches,
                })) ?? [],
              )
              setCreateBatchItem(null)
            }}
            onClose={() => setCreateBatchItem(null)}
          />
        ) : null
      }
    </>
  )
}

export default MassPrintingModal
