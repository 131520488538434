import { IBarcode } from '@/interfaces'
import { useState } from 'react'
import { Button, Modal, TableWithCopy } from '@/components'
import { Text } from '@shared/ui/Text'
import { Flex } from '@shared/ui/Flex'
import { IconOpenInNew } from '@consta/icons/IconOpenInNew'

export const BarcodesCell = ({ barcodes = [], shortMode }: { barcodes: IBarcode[], shortMode?: boolean }) => {
  const [modalVisible, srtModalVisible] = useState<boolean>(false)

  const handleCloseModal = () => srtModalVisible(false)
  if (shortMode && barcodes.length > 1) {
    return (
      <>
        <Flex
          gap={'2xs'}
          direction={'column'}
        >
          <Text
            as={'p'}
            size={'s'}
            children={barcodes[0]?.barcode || ''}
          />
          <Button
            size={'xs'}
            view={'clear'}
            label={'открыть все'}
            iconRight={IconOpenInNew}
            onClick={(e) => {
              e.stopPropagation();
              srtModalVisible(true)
            }}
          />
        </Flex>
        <Modal
          isOpen={modalVisible}
          withClose
          onClose={handleCloseModal}
          onOverlayClick={handleCloseModal}
          size="m"
        >
          <TableWithCopy
            columns={[{
              key: 'barcode',
              title: 'Штрихкод',
              title_txt: 'Штрихкод',
            },{
              key: 'type',
              title: 'Тип',
              title_txt: 'Тип',
            },]}
            rows={barcodes.map((barcode) => ({
              id: barcode.barcode+barcode.type,
              barcode: barcode.barcode,
              type: barcode.type,
            }))}
          />
        </Modal>
      </>
    )
  }
  return (
    <Flex
      gap={'2xs'}
      direction={'column'}
    >
      {
        barcodes.map(barcodeObj => (
          <Text
            key={barcodeObj.barcode}
            as={'p'}
            size={'s'}
            children={barcodeObj.barcode}
          />
        ))
      }
    </Flex>
  )
}
