import React from 'react'
import { ComboboxWrapperWithApi } from '@/components'
import { useGetDocument } from '@/hooks'
import { CommonFieldProps } from '@shared/types/fields'
import { DocumentTypeEnum, IDocumentLight } from '@/interfaces'
import { generatePath } from 'react-router-dom'
import { PATHS } from '@shared/routing'

interface IProps extends CommonFieldProps {
  documentType: DocumentTypeEnum
  merchantId?: string
  convertHookData?: (data: { documents: IDocumentLight[] }) => any
}

const ComboboxDocumentsSearchApi = (props: IProps) => {
  const {
    documentType,
    merchantId,
    convertHookData = (data) => {
      return data.documents.map((el) => ({ label: el.title, id: el.id, value: el.id, dto: el }))
    },
    convertHookParams = (params) => {
      return {
        document_type: documentType,
        merchant_ids: merchantId ? [merchantId] : undefined,
        search: params.search
      }
    },
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      link={
        restProps.value?.value
          ? generatePath(PATHS.DOCUMENT_FORM, { docType: documentType, docId: restProps.value?.value })
          : undefined
      }
      useGetHookSearch={useGetDocument}
      convertHookParams={convertHookParams}
      convertHookData={convertHookData}
      {...restProps}
    />
  )
}
export default ComboboxDocumentsSearchApi
