import React, { FC, useEffect, useState } from 'react'
import { ModalProps, Service, ServiceGroup } from 'src/interfaces'
import { ComboboxWrapper, InputWrapper, Modal } from 'src/components'
import { IFormField } from './types'
import { DefaultItem } from '@consta/uikit/Combobox'
import { Button } from '@consta/uikit/Button'
import { useCreateUpdateBillingService } from 'src/hooks/billingServices.ts'
import styles from './BillingServicesModal.module.scss'
import { ValidateErrors } from '@pages/DirectoriesPage/types'

interface BillingServicesModalProps extends ModalProps {
  refetch?: () => void
  groups: ServiceGroup[] | undefined
  services: Service[]
  docId?: string
}

const BillingServicesModal: FC<BillingServicesModalProps> = ({
  isOpen,
  onClose,
  groups = [],
  services = [],
  docId,
  refetch = () => {},
}) => {
  const [groupsList, setGropusList] = useState<DefaultItem[] | null>(null)
  const [fieldValues, setFieldValues] = useState<IFormField>({
    title: '',
    code: '',
    measure: '',
    group: null,
  })
  const [fieldErrors, setFieldErrors] = useState({
    title: '',
    code: '',
    measure: '',
    group: '',
  })
  const { mutateAsync: createUpdateBillingServic } =
    useCreateUpdateBillingService()

  useEffect(() => {
    if (!groups || !services) {
      return
    }
    if (docId) {
      const service = services.find((el) => el.id === docId)
      const group = groups.find((el) => el.id === service?.group_id)
      setFieldValues({
        title: service?.title || '',
        code: `${service?.code}`,
        measure: service?.measure || '',
        group: { label: group?.title || '', id: group?.id || '' },
      })
    }

    setGropusList(
      groups
        .filter((el) => el.type === 'WITHOUT_CALCULATION')
        .map((el) => ({
          label: el.title,
          id: el.id,
        })),
    )
  }, [groups])

  const canSubmit = (): boolean => {
    const emptyFieldKey = Object.keys(fieldValues).find((key) => {
      const value = fieldValues[key as keyof typeof fieldValues]

      return !value
    })

    if (emptyFieldKey) {
      setFieldErrors((prevState) => ({
        ...prevState,
        [emptyFieldKey]: ValidateErrors.isEmpty,
      }))
    }

    return !emptyFieldKey
  }

  const handleSubmit = () => {
    const isValid = canSubmit()

    if (!isValid) {
      return
    }

    const data = {
      active: true,
      code: Number(fieldValues.code),
      group_id: String(fieldValues.group?.id) || '',
      measure: fieldValues.measure,
      title: fieldValues.title,
    }

    createUpdateBillingServic({ docId, data })
      .then(async () => {
        refetch()
      })
      .catch(() => {})
    onClose()
  }
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      className={styles.modal}
      onOverlayClick={onClose}
      title={docId ? 'Редактирование услуги' : 'Создание услуги'}
    >
      <div className={styles.formWrapper}>
        <div className={styles.input}>
          <InputWrapper
            label={'Наименование'}
            value={fieldValues.title}
            handleChange={(value) =>
              setFieldValues((prev) => ({ ...prev, title: value || '' }))
            }
          />
          <div className={styles.errorMessage}>{fieldErrors.title}</div>
        </div>
        <div className={styles.input}>
          <ComboboxWrapper
            className={styles.input}
            items={groupsList || []}
            value={fieldValues.group}
            label={'Наменование группы услуг'}
            onChange={({ value }) =>
              setFieldValues((prev) => ({ ...prev, group: value }))
            }
          />
          <div className={styles.errorMessage}>{fieldErrors.group}</div>
        </div>
        <div className={styles.input}>
          <InputWrapper
            label={'IdPas'}
            value={fieldValues.code}
            isNumber
            handleChange={(value) => {
              if (value?.length === 6) {
                return
              }
              setFieldValues((prev) => ({ ...prev, code: value || '' }))
            }}
          />
          <div className={styles.errorMessage}>{fieldErrors.code}</div>
        </div>
        <div className={styles.input}>
          <InputWrapper
            label={'Ед. измерения'}
            value={fieldValues.measure}
            handleChange={(value) =>
              setFieldValues((prev) => ({ ...prev, measure: value || '' }))
            }
          />
          <div className={styles.errorMessage}>{fieldErrors.code}</div>
        </div>
        <div className={styles.footer}>
          <Button label="Отмена" view={'ghost'} onClick={onClose} />
          <Button label="Сохранить" view={'primary'} onClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  )
}

BillingServicesModal.displayName = 'BillingServicesModal'

export default BillingServicesModal
