import useGetZoneSettings from '@/hooks/warehouse/useGetZoneSettings'
import { ICellPlace, ITreeLvlItem } from '@/interfaces'
import { TreeOptionType, TreeSelect } from '@shared/ui/fields/TreeSelect'
import React, { useMemo } from 'react'


// const exampleData: TreeOptionType[] = [
//   {
//     label: 'test 1',
//     value: 'hello',
//     children: [
//       {
//         label: 'Hi',
//         value: 'Hi',
//       },
//     ],
//   },
//   {
//     label: 'test 2',
//     value: 'Hey',
//     children: [],
//   },
// ]

interface Props {
  selectedCells?: ICellPlace[],
  label: string
  onChange: (resultValues: string[]) => void
}

const TreeSelectCell = (props: Props) => {
  const {
    selectedCells = [],
    label,
    onChange
  } = props

  const { data, refetch } = useGetZoneSettings({})

  const zones = data?.zones

  const selectedCellsIds = selectedCells.map(cell => cell.id)
  const convertToSelect = (
    treeLvlItem: ITreeLvlItem & { topology?: { tier: string }, type?: string, id?: string },
    prevValue: string,
    zoneId: string
  ): TreeOptionType => {
    const {
      items,
      title,
      ...restProps
    } = treeLvlItem

    const isCell = treeLvlItem.type === 'CELL'
    const checked = selectedCellsIds.includes(treeLvlItem.id) && isCell

    const currValue = `${prevValue}|${treeLvlItem.index ?? treeLvlItem.topology.tier ?? ''}`

    const children = items?.map(item => convertToSelect(item, currValue, zoneId))

    return {
      label: title,
      value: currValue,
      zone_id: zoneId,
      checked,
      children,
      ...restProps
    }
  }

  const dataItems: TreeOptionType[] = useMemo(() =>
    zones
      ?.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
      ?.map((zone) => {
        const children = zone.items.map((item) => convertToSelect(item, zone.id, zone.id))

        return {
          label: zone.title,
          value: zone.id,
          zone_id: zone.id,
          children,
          ...zone
        }
  }), [zones, selectedCells])

  const fillValues = (val: string[]) => {
    if (val.length < 4) {
      val.push('')
      return fillValues(val)
    }
    return val
  }
  return (
    <TreeSelect
      label={label}
      options={dataItems}
      onChange={(currentNode, selectedNodes) =>{
        const resultValues = selectedNodes.map(node => {
          const splitVal = node.value?.split('|')
          return fillValues(splitVal).join('|')
        })
        onChange(resultValues)
      }}
      searchPredicate={(currentNode, searchTerm) => {
        const search = searchTerm.toLowerCase()
        const currentZone = dataItems.find(item => {
          return item.id === currentNode.zone_id
        })
        const searchByLabelAndBarcode = (
          currentNode.label?.toLowerCase().includes(search)
          || currentNode.barcode?.toLowerCase().includes(search)
        )
        // Если это заона OUT_OF_STOCK то отображаем только ячейки, без узлов топологии
        if (currentZone?.type === "OUT_OF_STOCK") {
          return searchByLabelAndBarcode && (currentNode.type === 'CELL')
        }
        return searchByLabelAndBarcode
      }}
    />
  )
}
export default TreeSelectCell