import React, { FC, useMemo } from 'react'
import { FormTabs, generateTabs } from '../../../../../helpers/documentTabs'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { Comment, HistoryChanges, RelativeDocs } from '../../../../../components/CommonTabsPages'
import { updateFormStateDraftAction } from '../../../../../store/actions'
import { TabsContentWrapper } from '../../../../../components'
import { DocumentsParamsFields } from '..'
import { useOrderFormContext } from '../../../../../context/OrderFormContext'
import { DocumentTypeEnum, IDeliveryContract } from '@/interfaces'

interface BatchFormContentProps {
  loading?: boolean
  handleSave: () => void
  refetch?: () => void
  deliveryContracts?: IDeliveryContract[]
}

const ShipmentFormContent: FC<BatchFormContentProps> = ({
  handleSave,
  refetch,
  deliveryContracts,
}) => {
  const {
    page,
    id: supplyId,
    tabId,
  } = useParams<{ page: OperAndDocumPagesType; id: string; tabId: string }>()

  const supplyTabsWithDescription = useMemo(
    () =>
      generateTabs([
        FormTabs.documentsParams,
        FormTabs.relativeDocs,
        FormTabs.comments,
        FormTabs.historyChanges,
      ]),
    [page],
  )

  return (
    <TabsContentWrapper tabs={supplyTabsWithDescription}>
      <TabPage
        tabId={tabId}
        documentId={supplyId}
        handleSave={handleSave}
        refetch={refetch}
        deliveryContracts={deliveryContracts}
      />
    </TabsContentWrapper>
  )
}

interface ITabContent {
  tabId: string
  documentId: string
  handleSave: () => void
  refetch?: () => void
  deliveryContracts?: IDeliveryContract[]
}

const TabPage = React.memo(
  ({ documentId, tabId, handleSave, refetch, deliveryContracts }: ITabContent) => {
    const {
      state: { documentRaw, formStateDraft },
      dispatch,
    } = useOrderFormContext()

    switch (tabId) {
      case FormTabs.documentsParams:
        return (
          <DocumentsParamsFields handleSave={handleSave} refetch={refetch} deliveryContracts={deliveryContracts} />
        )
      case FormTabs.relativeDocs:
        return <RelativeDocs documentId={documentId} documentType={DocumentTypeEnum.ORDER}/>
      case FormTabs.comments:
        return (
          <Comment
            stateCommentValue={formStateDraft?.comment || ''}
            handleChangeComment={(value) =>
              dispatch(updateFormStateDraftAction('comment', value))
            }
          />
        )
      case FormTabs.historyChanges:
        return <HistoryChanges documentUUID={documentRaw?.uuid} />
    }
    return null
  },
)

export default ShipmentFormContent
