import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, ISku } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { ISortingDoc } from '../../interfaces/sorting'

interface IParams {
  sorting_id: string,
  sku_id: string
  barcode_used: string
}

const addSortingItem = async (params: IParams) => {
  const {
    sorting_id,
    sku_id,
    barcode_used,
  } = params
  const result = await api.post(`/sorting/${sorting_id}/item`, {sku_id, barcode_used})
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

interface IApiResult extends ApiReqResult {
  sorting: ISortingDoc
  skus: ISku[]
  current_sorting_number: number
}

const useAddSortingItem = (
  options?: UseMutationOptions<IApiResult, any, IParams>,
) => {
  return useMutation<IApiResult, any, IParams>(addSortingItem, options)
}

export default useAddSortingItem
