import React, { FC } from 'react'

import PackingList from './TransportPlacesList'
import PackingForm from './TransportPlaceForm'

import { PackingContextProvider, usePackingContext } from '../../context/PackingContext'

interface PackingProps {
  type?: 'list' | 'form'
}

const TransportPlaces: FC<PackingProps> = ({
  type = 'list'
}) => {
  const { state, dispatch } = usePackingContext()

  return (
    <PackingContextProvider>
      {
        type === 'list'
          ? (<PackingList />)
          : (<PackingForm />)
      }
    </PackingContextProvider>
  )
}

TransportPlaces.displayName = 'Packing'

export default TransportPlaces
