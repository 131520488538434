import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IPaginationFilter, IVehicle, PaginationResult } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { convertObjectToURLParams } from '@shared/helpers'


interface GetVehiclesListResult extends ApiReqResult, PaginationResult {
  vehicles: IVehicle[]
}

interface Params extends IPaginationFilter {
  search?: string
  with_deleted?: boolean
}

const useGetVehiclesList = (
  callback?: (data: IVehicle[]) => void,
  params: Params = {},
  options?: UseQueryOptions<any>,
) => {
  return useQuery<GetVehiclesListResult>(
    [`getVehiclesList`],
    async () => {
      const paramsStr = convertObjectToURLParams(params)
      const result = await api.get(`/vehicle${paramsStr}`)
      return result.data
    },
     {
      onSuccess: ({ success, vehicles }) => {
        if (success) {
          callback?.(vehicles)
        }
      },
      ...options,
    },
  )
}

export default useGetVehiclesList
