import { useQuery, UseQueryOptions } from 'react-query'

import api from '@shared/api'
import { ApiReqResult, ICommonDocument, ISku, IWarehouseCell, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'

export interface IParams {
  search?: string | null
}

interface GetInventoryListResult extends ApiReqResult, PaginationResult {
  documents: ICommonDocument[]
  cells: IWarehouseCell[]
  skus: ISku[]
}
// http://wms-test.kak2c.ru/warehouse-api/inventory
const useGetInventoryList = (
  params: IParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<GetInventoryListResult>(
    [`getInventoryList`, params],
    async () => {
      const paramsStr = convertObjectToURLParams(params)

      const result = await api.get(`/inventory${paramsStr}`)
      return result.data as GetInventoryListResult
    },
    options,
  )
}
export default useGetInventoryList
