import React from 'react'
import cx from 'classnames'

import styles from './input-with-dste-mask.module.scss'

import { TextField, TextFieldPropValue } from '@consta/uikit/TextField'

import { useAppContext } from '@shared/providers/AppContextProvider'


import { IconComponent } from '@consta/icons/Icon'
import { TextFieldPropForm, TextFieldPropSize } from '@consta/uikit/__internal__/src/components/TextField/TextField'
import { getFormattedDate } from './utils'

interface InputWithDateMaskProps {
  id: string
  label?: string
  placeholder?: string
  size?: TextFieldPropSize
  value: string | null
  handleChange?: (value: TextFieldPropValue) => void
  className?: any
  rightSide?: string | IconComponent
  autofocus?: boolean
  form?: TextFieldPropForm
  error?: string
}

const InputWithDateMask = ({
  id,
  value,
  handleChange = () => false,
  placeholder,
  rightSide,
  size,
  className,
  autofocus,
  label,
  form,
  error,
}: InputWithDateMaskProps) => {
  const { resetAfkInterval } = useAppContext()

  const handleChangeInput = (val: TextFieldPropValue) => {
    resetAfkInterval()

    if (!val) {
      handleChange('')
      return
    }
    const newVal = getFormattedDate(val)
    if (newVal) {
      handleChange(newVal)
    }
  }

  return (
    <div className={cx(styles.input, className)}>
      {label && <label htmlFor={id}>{label}</label>}
      <TextField
        type="text"
        id={id}
        value={value}
        placeholder={placeholder}
        rightSide={rightSide}
        size={size}
        onChange={({ value }) => handleChangeInput(value)}
        className={className}
        autoFocus={autofocus}
        form={form}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  )
}

export default InputWithDateMask
