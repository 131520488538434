import { useQuery, UseQueryOptions } from 'react-query'

import api from '@shared/api'
import { ApiReqResult, ICommonDocument, ISku, IWarehouseCell, PaginationResult } from 'src/interfaces'

export interface IParams {
  invenoryId: string
}

interface GetInventoryDocumentResult extends ApiReqResult, PaginationResult {
  document: ICommonDocument
  cells: IWarehouseCell[]
  skus: ISku[]
}
// http://wms-test.kak2c.ru/warehouse-api/inventory/{inventory_id}
const useGetInventoryDocument = (
  params: IParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<GetInventoryDocumentResult>(
    [`getInventoryDocument`, params],
    async () => {
      const result = await api.get(`/inventory/${params.invenoryId}`)
      return result.data as GetInventoryDocumentResult
    },
    options,
  )
}
export default useGetInventoryDocument
