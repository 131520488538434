import { generateStatusesOptions } from '@shared/helpers'
import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { BatchStatusesEnum, ICombobox } from '../../interfaces'

export const BATCH_STATUSES_DESCRIPTION: Record<
  BatchStatusesEnum | string,
  string
> = {
  [BatchStatusesEnum.new]: 'Новый',
  [BatchStatusesEnum.picking]: 'Подбор',
  [BatchStatusesEnum.picking_error]: 'Ошибка подбора',
  [BatchStatusesEnum.picked]: 'Подобран',
  [BatchStatusesEnum.packing]: 'На упаковке',
  [BatchStatusesEnum.packed]: 'Упакован',
  [BatchStatusesEnum.readyToShip]: 'Готов к доставке',
  [BatchStatusesEnum.shipped]: 'Доставлен',
  [BatchStatusesEnum.canceled]: 'Отменен',
}

export const BATCH_STATUS_BADGE_TYPE: Record<
  BatchStatusesEnum | string,
  BadgePropStatus
> = {
  [BatchStatusesEnum.new]: 'system',
  [BatchStatusesEnum.picking]: 'normal',
  [BatchStatusesEnum.picking_error]: 'error',
  [BatchStatusesEnum.picked]: 'normal',
  [BatchStatusesEnum.packing]: 'normal',
  [BatchStatusesEnum.packed]: 'success',
  [BatchStatusesEnum.readyToShip]: 'warning',
  [BatchStatusesEnum.shipped]: 'warning',
  [BatchStatusesEnum.canceled]: 'system',
}

export const BATCH_STATUSES: {
  value: string
  label: string
  style: BadgePropStatus
}[] = Object.keys(BATCH_STATUSES_DESCRIPTION).map((key) => ({
  value: key as string,
  label: BATCH_STATUSES_DESCRIPTION[key as keyof object],
  style: BATCH_STATUS_BADGE_TYPE[key as keyof object],
}))

export const batchSelectOptions: ICombobox[] = generateStatusesOptions(
  BATCH_STATUSES_DESCRIPTION,
)
