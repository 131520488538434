import { ICellPlace, IPlacing } from 'src/interfaces'
import { IPlacingFormDataState } from '../interfaces'


export const SET_CELLS = 'SET_CELLS'
export const setCellsAction = (cells: ICellPlace[] = []) => {
  return {
    type: SET_CELLS,
    payload: cells,
  }
}

export const SET_PLACING_RAW = 'SET_PLACING_RAW'
export const setPlacingRawAction = (receiptRaw: IPlacing | null) => {
  return {
    type: SET_PLACING_RAW,
    payload: receiptRaw,
  }
}

export const SET_PLACING_FORM_STATE_DRAFT = 'SET_PLACING_FORM_STATE_DRAFT'
export const setPlacingFormDraftAction = (formState: IPlacingFormDataState) => {
  return {
    type: SET_PLACING_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_PLACING_FORM_STATE_RAW = 'SET_PLACING_FORM_STATE_RAW'
export const setPlacingFormRawAction = (formState: IPlacingFormDataState) => {
  return {
    type: SET_PLACING_FORM_STATE_RAW,
    payload: formState,
  }
}

