import { ISupplyReceipt } from 'src/interfaces'
import { TableColumn } from '@consta/uikit/Table'
import { RECEIPT_STATUS_BADGE_TYPE, RECEIPT_STATUSES } from '@/interfaces/statuses'
import { Badge } from '@consta/uikit/Badge'
import React from 'react'
import { OperAndDocumPages } from '../../../../pagesType'
import { DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'


export const generateRows = (receipts: ISupplyReceipt[]) => {
  return receipts.map(receipt => ({
    id: receipt.id,
    date: receipt.create_ts,
    date_val: receipt.create_ts,
    documentNum: receipt.id,
    merchant: receipt.merchant?.title,
    condition: receipt.condition,
    status: receipt.status,
    status_value: RECEIPT_STATUSES[receipt.status],
  }))
}
export const generateColumns = (): TableColumn<any>[] => {
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_val',
      // width: 130,
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{minWidth: '120px'}}/>
      }
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        return <DocumentNumCell page={OperAndDocumPages.receiptForm} documentNum={row.documentNum} />
      }
    },
    {
      title: 'Организация',
      accessor: 'merchant',
    },
    // {
    //   title: 'Качество',
    //   accessor: 'condition',
    //   renderCell: ({row}) => {
    //     return <b>{ISupplyConditionDescription[row.condition]}</b>
    //   }
    // },
    {
      title: 'Статус',
      sortable: true,
      accessor: 'status_value',
      renderCell: ({row}) => {
        const status = row.status
        return <>
          <Badge
            status={RECEIPT_STATUS_BADGE_TYPE[status]}
            label={RECEIPT_STATUSES[status]}
          />
        </>
      }
    },
  ]
}


