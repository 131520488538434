import React, { FC } from 'react'

import ShipmentList from './ShipmentList/ShipmentList'
import ShipmentForm from './ShipmentForm/ShipmentForm'

import { ShipmentContextProvider } from '../../context/ShipmentContext'

interface ShipmentProps {
  type?: 'list' | 'form'
}


const Shipment: FC<ShipmentProps> = ({type = 'list'}) => {
  return (
    <ShipmentContextProvider>
      {
        type === 'list'
          ? (<ShipmentList />)
          : (<ShipmentForm />)
      }
    </ShipmentContextProvider>
  )
}

Shipment.displayName = 'Shipment'

export default Shipment
