import { IOrderTask } from 'src/interfaces/orderTask'
import { Badge } from '@consta/uikit/Badge'
import { OperAndDocumPages } from '../../../../pagesType'
import { DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import { TableColumn } from '@consta/uikit/Table'
import { ORDER_STATUS_BADGE_TYPE, ORDER_STATUSES_DESCRIPTION, TASK_TYPE, TASK_TYPE_BADGE_TYPE } from '@/interfaces/statuses'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'

export const generateRows = (orders: IOrderTask[]) => {
  return orders.map((order) => ({
    id: order.id,
    merchant: order.merchant?.title,
    status: order.status,
    status_value: ORDER_STATUSES_DESCRIPTION[order.status || ''],
    comment: order.comment || '-',
    type: order.sku.type,
    quantity: order.quantity,
    set: order.id,
    operation: order.operation,
    operation_value: TASK_TYPE[order.operation],
    external_id: order.sku.external_id,
    date: order.create_ts,
    date_value: days(order.create_ts).format(DATE_TIME_VIEW),
  }))
}
export const generateColumns = (): TableColumn<any>[] => {
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({ row }) => {
        return <DateCell date={row.date} style={{ minWidth: '120px' }} />
      },
    },
    {
      title: ' Организация',
      accessor: 'merchant',
    },
    {
      title: 'Комплект',
      sortable: true,
      accessor: 'set',
      renderCell: ({ row }) => {
        return (
          <DocumentNumCell
            page={OperAndDocumPages.taskOfCompletingForm}
            documentNum={row.set}
          />
        )
      },
    },
    {
      title: 'Статус',
      sortable: true,
      accessor: 'status_value',
      renderCell: ({ row }) => {
        const status = row.status
        return (
          <>
            <Badge
              status={ORDER_STATUS_BADGE_TYPE[status]}
              label={ORDER_STATUSES_DESCRIPTION[status]}
            />
          </>
        )
      },
    },
    {
      title: 'Тип',
      sortable: true,
      accessor: 'operation_value',
      renderCell: ({ row }) => {
        const operation = row.operation
        return (
          <>
            <Badge
              status={TASK_TYPE_BADGE_TYPE[operation]}
              label={TASK_TYPE[operation]}
            />
          </>
        )
      },
    },
    {
      title: ' Внешний ключ',
      accessor: 'external_id',
    },
    {
      title: ' Комментарий',
      accessor: 'comment',
    },
    {
      title: ' Количество',
      accessor: 'quantity',
    },
  ]
}
