import React from 'react'
import { ITransportaion } from 'src/interfaces/transportationTemplates'
import { TableColumn } from '@consta/uikit/Table'
import { OperAndDocumPages } from '@pages/OperationsAndDocumentsPage'
import { DocumentNumCell } from '@pages/OperationsAndDocumentsPage/components'
import { TEMPLATES_STATUS, TEMPLATES_STATUS_BADGE_TYPE } from '@/interfaces/statuses'
import { Badge } from '@consta/uikit/Badge'
import { Button } from 'src/components'
import styles from '../TransportationTemplatesList.module.scss'
import { IconEdit } from '@consta/icons/IconEdit'
import { IconRemove } from '@consta/icons/IconRemove'

export const generateRows = (document: ITransportaion[]) => {
  return document.map((doc) => ({
    id: doc.id,
    order_prefix: doc.order_prefix,
    title: doc.title,
    transportation_type: doc.transportation_type,
    transportation_type_value: TEMPLATES_STATUS[doc.transportation_type],
    contractor: doc.contractor?.title,
  }))
}

export const generateColumns = ({
  handleEdit,
  handleConfirmModal,
}: {
  handleEdit: (id: string) => void
  handleConfirmModal: (id: string) => void
}): TableColumn<any>[] => {
  return [
    {
      title: 'Контрагент',
      sortable: true,
      accessor: 'contractor',
    },
    {
      title: 'Наименование ',
      sortable: true,
      accessor: 'title',
      renderCell: ({row}) => {
        return (
          <DocumentNumCell
            page={OperAndDocumPages.transportationTemplatesForm}
            title={row.title}
            documentNum={row.id}
          />
        )
      },
    },

    {
      title: ' Префикс заказа',
      sortable: true,
      accessor: 'order_prefix',
    },
    {
      title: 'Тип перевозки',
      sortable: true,
      accessor: 'transportation_type_value',
      renderCell: ({row}) => {
        const type = row.transportation_type
        return (
          <>
            <Badge
              status={TEMPLATES_STATUS_BADGE_TYPE[type]}
              label={TEMPLATES_STATUS[type]}
            />
          </>
        )
      },
    },
    {
      title: 'Удаление/Редактирование',
      accessor: 'actions',
      align: 'right',
      renderCell: ({row}: any) => {
        return (
          <div className={styles.actionsBtns}>
            <Button
              onlyIcon
              view={'ghost'}
              size={'s'}
              iconLeft={IconEdit}
              onClick={() => handleEdit(row.id)}
            />
            <Button
              onlyIcon
              view={'ghost'}
              size={'s'}
              iconLeft={IconRemove}
              onClick={() => handleConfirmModal(row.id)}
            />
          </div>
        )
      },
    },
  ]
}
