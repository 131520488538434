import React, { useEffect, useMemo } from 'react'
import { useGetMerchantList, useGetReceipts } from 'src/hooks'
import { DocumentsListWrapper } from '../../../components'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { useFilter } from '../../../hooks/useFilter'


const ReceiptsList = () => {
  const { filterState, setFilterState } = useFilter()

  const { data: merchantData } = useGetMerchantList({})

  const {
    isFetching: receiptsLoading,
    data: receiptsData,
    refetch: refetchReceipts,
  } = useGetReceipts(generateFilterParams(filterState))

  useEffect(() => {
    refetchReceipts()
  }, [filterState])

  const rows = useMemo(() => generateRows(receiptsData?.receipts || []), [receiptsData?.receipts])
  const columns = useMemo(() => generateColumns(), [])
  const filtersConfig = generateFilters(merchantData?.merchants || [])

  return (
    <DocumentsListWrapper
      title={'Документы приходных ордеров'}
      rows={rows}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      filtersConfig={filtersConfig}
      loading={receiptsLoading}
      pagination={receiptsData?.pagination}
    />
  )
}

export default ReceiptsList
