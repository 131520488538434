import React, { useEffect, useMemo, useState } from 'react'
import styles from './ChangingOrderModal.module.scss'

import { Button, ComboboxWrapper, InputWrapper, Modal } from 'src/components'
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup'
import { ComboboxSkus } from '@entities/Sku'

import { ModalProps } from 'src/interfaces'
import { useGetItemsStock, useGetQueryPickings, useUpdateAdjustingDocument } from 'src/hooks'
import { WorkModeEnum } from './enums'
import { DefaultItem } from '@consta/uikit/Combobox'

import { useOrderFormContext } from '@pages/OperationsAndDocumentsPage/context/OrderFormContext'
import { ValidateErrors } from '@pages/DirectoriesPage/types'
import { useNotifications } from '@shared/providers/NotificationProvider'

import { generateSelectItemsAdd, generateSelectItemsDelete } from './helpers/selectItems'

const workModeSwitcherTypes: { label: string; value: WorkModeEnum }[] = [
  { label: 'Удаление позиции', value: WorkModeEnum.delete },
  { label: 'Добавление позиции', value: WorkModeEnum.add },
]

export interface IChangingOrderModalProps extends ModalProps {
  refetch?: () => void
}

const ChangingOrderModal = (props: IChangingOrderModalProps) => {
  const { isOpen, onClose, refetch } = props
  const { state, dispatch } = useOrderFormContext()
  const notification = useNotifications()

  const [workMode, setWorkMode] = useState<WorkModeEnum>(WorkModeEnum.delete)
  const [barcodePlaceList, setBarcodePlaceList] = useState<DefaultItem[]>([])

  const [requiredValues, setRequiredValues] = useState({
    skus: null,
    quantity: '',
  })
  const [fieldValues, setFieldValues] = useState({
    barcodePlace: null,
    cells: null,
    batch: null,
  })

  const [fieldErrors, setFieldErrors] = useState({
    skus: '',
    quantity: '',
    batch: '',
  })

  const { data: pickings } = useGetQueryPickings({
    parent_id: state?.documentRaw?.batch_uuid,
  })
  const { mutateAsync: createUpdateItem } = useUpdateAdjustingDocument()
  const { isLoading: stockLoading, data: cellsDataRaw } = useGetItemsStock({
    skus: requiredValues.skus
      ? [{ sku_id: String(requiredValues?.skus.id) }]
      : [],
  })
  const { data: stockForCells } = useGetItemsStock({
    skus:
      requiredValues.skus && fieldValues.batch
        ? [
          {
            sku_id: String(requiredValues?.skus.id),
            sku_batch_id: String(fieldValues?.batch?.id),
          },
        ]
        : [],
  })

  useEffect(() => {
    setBarcodePlaceList(
      state?.documentRaw?.places.map((el) => ({ label: el.id, id: el.id })),
    )
  }, [state?.documentRaw])

  const onSwitchWorkMode = (value: WorkModeEnum) => {
    setWorkMode(value)
    setRequiredValues({
      skus: null,
      quantity: '',
    })
    setFieldValues({
      barcodePlace: null,
      cells: null,
      batch: null,
    })
  }
  const selectItemsDelete = useMemo(
    () =>
      generateSelectItemsDelete(
        workMode,
        pickings,
        state?.documentRaw?.places || [],
        requiredValues.skus,
        fieldValues.batch,
      ),
    [
      workMode,
      pickings,
      state?.documentRaw?.places,
      requiredValues.skus,
      fieldValues.batch,
    ],
  )
  const selectItemsAdd = useMemo(
    () =>
      generateSelectItemsAdd(
        workMode,
        requiredValues.skus,
        cellsDataRaw,
        fieldValues.batch,
        stockForCells,
      ),
    [workMode, requiredValues, cellsDataRaw, fieldValues.batch, stockForCells],
  )

  const canSubmit = (): boolean => {
    const emptyFieldKey = Object.keys(requiredValues).find((key) => {
      const value = requiredValues[key as keyof typeof requiredValues]

      return !value
    })

    if (emptyFieldKey) {
      setFieldErrors((prevState) => ({
        ...prevState,
        [emptyFieldKey]: ValidateErrors.isEmpty,
      }))
    }

    return !emptyFieldKey
  }

  const handleSubmit = () => {
    const isValid = canSubmit()
    if (!isValid) {
      return
    }
    if (
      (selectItemsAdd.batch.length ||
        selectItemsDelete.batch.length) && !fieldValues.batch
    ) {
      setFieldErrors((prevState) => ({
        ...prevState,
        batch: ValidateErrors.isEmpty,
      }))
      return
    }
    const orderId = state?.documentRaw?.id
    const data = {
      items: [
        {
          change:
            workMode === WorkModeEnum.delete
              ? -Number(requiredValues.quantity)
              : Number(requiredValues.quantity),
          id: String(requiredValues.skus?.id),
          place_barcode: fieldValues?.barcodePlace
            ? String(fieldValues.barcodePlace?.id)
            : '',
          cell_barcode: fieldValues?.cells ? String(fieldValues.cells?.id) : '',
          sku_batch_id: fieldValues?.batch ? String(fieldValues.batch?.id) : undefined,
        },
      ],
    }

    createUpdateItem({ orderId, data })
      .then(async () => {
        notification?.show('success', 'Документ успешно сохранен', {
          soundName: 'PACKING_COMPLETED',
        })
        onClose()
        refetch()
      })
      .catch(() => {
      })
  }

  const handleChangeSku = (value: DefaultItem | null) => {
    setRequiredValues((prev) => ({
      ...prev,
      skus: value,
    }))
    setFieldValues({
      ...fieldValues,
      cells: null,
      barcodePlace: null,
      batch: null,
    })
    setFieldErrors({
      skus: '',
      quantity: '',
      batch: '',
    })
  }


  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={onClose}
      className={styles.modal}
      size='m'
      headerTitle={'Редактирование цепочки'}
    >
      <div className={styles.container}>
        <ChoiceGroup
          className={styles.choiceGroup}
          value={
            workModeSwitcherTypes.find((t) => t.value === workMode) ?? null
          }
          items={workModeSwitcherTypes}
          getItemLabel={(item) => item.label}
          onChange={({ value }) => onSwitchWorkMode(value.value)}
          name={'workMode'}
          multiple={false}
          width={'full'}
        />
        <div className={styles.formWrapper}>
          <div className={styles.inputsRow}>
            {workMode === WorkModeEnum.delete ? (
              <div className={styles.input}>
                <ComboboxWrapper
                  label={'Номенклатура'}
                  items={state?.documentRaw?.items.map((el) => ({
                    label: el.title,
                    id: el.id,
                  }))}
                  value={requiredValues.skus}
                  placeholder='Выберите номенклатуру '
                  onChange={({value}) => handleChangeSku(value)}
                />
                <div className={styles.errorMessage}>{fieldErrors.skus}</div>
              </div>
            ) : (
              <div className={styles.input}>
                <ComboboxSkus
                  label={'Номенклатура'}
                  value={requiredValues.skus}
                  placeholder='Выберите номенклатуру '
                  onChange={handleChangeSku}
                />
                <div className={styles.errorMessage}>{fieldErrors.skus}</div>
              </div>
            )}

            {selectItemsDelete?.batch.length || selectItemsAdd?.batch.length ? (
              <div className={styles.input}>
                <ComboboxWrapper
                  label={'Партия товара'}
                  items={
                    workMode === WorkModeEnum.delete
                      ? selectItemsDelete?.batch
                      : selectItemsAdd.batch
                  }
                  value={fieldValues.batch}
                  placeholder='Выберите партию товара '
                  onChange={({ value }) => {
                    setFieldValues({ ...fieldValues, batch: value })
                    setFieldErrors({
                      skus: '',
                      quantity: '',
                      batch: '',
                    })
                  }}
                />
                <div className={styles.errorMessage}>{fieldErrors.batch}</div>
              </div>
            ) : null}

            <div className={styles.quantity}>
              <InputWrapper
                label={'Кол-во'}
                value={requiredValues.quantity}
                isNumber
                handleChange={(value) => {
                  setRequiredValues((prev) => ({
                    ...prev,
                    quantity: value,
                  }))
                  setFieldErrors({
                    skus: '',
                    quantity: '',
                    batch: '',
                  })
                }}
              />
              <div className={styles.errorMessage}>{fieldErrors.quantity}</div>
            </div>
          </div>
          <div className={styles.inputsRow}>
            <div className={styles.input}>
              <ComboboxWrapper
                items={
                  workMode === WorkModeEnum.delete
                    ? selectItemsDelete?.places
                    : barcodePlaceList
                }
                value={fieldValues.barcodePlace}
                label={'ШК грузового места'}
                placeholder='Выберите ШК грузового места '
                onChange={({ value }) => {
                  setFieldValues({ ...fieldValues, barcodePlace: value })
                }}
              />
            </div>
            <div className={styles.input}>
              <ComboboxWrapper
                items={
                  workMode === WorkModeEnum.delete
                    ? selectItemsDelete?.cells
                    : selectItemsAdd?.cells
                }
                value={fieldValues.cells}
                label={'Ячейка'}
                placeholder='Выберите ячейку '
                onChange={({ value }) => {
                  setFieldValues({ ...fieldValues, cells: value })
                }}
              />
              {
                workMode === WorkModeEnum.add
                && requiredValues.skus
                && !selectItemsAdd.cells.length ? (
                  <div className={styles.infoMessage}>
                    Нет остатков по ячейкам!
                  </div>
                ) : null
              }
            </div>
          </div>
          {/*  */}
        </div>
      </div>
      <div className={styles.footer}>
        <Button label='Отмена' view={'ghost'} onClick={onClose} />
        <Button label='Сохранить' view={'primary'} onClick={handleSubmit} />
      </div>
    </Modal>
  )
}
export default ChangingOrderModal
