import { ICommonDocument } from './documents'
import { PartialField } from '@shared/helpers'

export enum PickingStatusEnum {
  readyForProcessing = 'READY_FOR_PROCESSING',
  requireMoving = 'REQUIRE_MOVING',
  error = 'ERROR',
  processing = 'PROCESSING',
  done = 'DONE'
}

export interface PutPickingRequestItemDto extends PartialField<IPickItem, 'errors' | 'position' | 'barcode_used' | 'sku_batch_id'> {
}

export interface IPickItemError {
  error: PickingSkuErrorEnum,
  quantity: number
}

export interface IPickItem {
  barcode_used: boolean,
  cell_barcode: string
  position: number,
  quantity: number,
  quantity_plan: number,
  sku_batch_id: string,
  sku_id: string
  errors: IPickItemError[]
}

export interface IPicking extends ICommonDocument {
  status: PickingStatusEnum
  items: IPickItem[]
  warehouse_comment: string
  warehouse_place_barcode: string
}

export enum PickingSkuErrorEnum {
  damaged = 'DAMAGED',
  not_found = 'NOT_FOUND',
}