import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, IDimensionsObj } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { provideMock } from '../../mocks'
import { MOCK_START_PHYSICAL_SET } from '../../mocks/physicalSet'

interface IResult extends ApiReqResult {
}

export interface IMassProcessPhysicalSetData {
  quantity: number
  tare_barcode: string
  dimensions?: IDimensionsObj
  packaging_id?: string
  weight?: number
}

interface IParams {
  orderId: string

  data: IMassProcessPhysicalSetData
}

const useMassProcessPhysicalSet = (
  options?: UseMutationOptions<any, IResult, any>,
) => {
  return useMutation(async (params: IParams) => {
      return provideMock<IParams, IResult>(
        async () => {
          const result = await api.post(`/physical-set-order/${params.orderId}/mass-processing`, params.data)
          if (!result) {
            throw result
          }
          return result.data
        },
        params,
        [
          {
            key: 'orderId',
            value: 'mock',
            result: MOCK_START_PHYSICAL_SET,
            logData: 'Старт упаковки комплекта',
          },
        ],
      )()
    },
    options,
  )
}

export default useMassProcessPhysicalSet
