import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { IServicesFormState } from '../store/interfaces'
import { servicesInitialState, servicesReducer } from '../store/reducer/servicesReducer'

export interface IUseReducer {
  state: IServicesFormState
  dispatch: React.Dispatch<IReducerAction>
}
interface IContext extends IUseReducer {}

export const ServicesFormContext = createContext<IContext>({
  state: servicesInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const ServicestFormContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof servicesInitialState, IReducerAction>
  >(servicesReducer, servicesInitialState)

  useEffect(() => {
    console.log('--- State ---', state)
  }, [state])

  return (
    <ServicesFormContext.Provider value={{ state, dispatch }}>
      {children}
    </ServicesFormContext.Provider>
  )
}
export const useServicesFormContext = () => useContext(ServicesFormContext)
