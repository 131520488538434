import { cloneDeep } from '@shared/helpers'

import { IReducerAction } from 'src/interfaces'
import { IPickingFormState } from '../interfaces'

import {
  REVERT_FORM_STATE_TO_RAW,
  SET_PICKING_FORM_STATE_DRAFT,
  SET_PICKING_FORM_STATE_RAW,
  SET_PICKING_RAW,
  UPDATE_FORM_STATE_DRAFT,
  UPDATE_FORM_STATE_RAW,
} from '../actions'

export const pickingInitialState: IPickingFormState = {
  documentRaw: null,
  formStateRaw: null,
  formStateDraft: null,
}

export const pickingReducer = (
  state: IPickingFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    /** Общие кейсы */

    case SET_PICKING_RAW:
      return { ...state, documentRaw: payload }

    case SET_PICKING_FORM_STATE_DRAFT:
      return { ...state, formStateDraft: payload }

    case SET_PICKING_FORM_STATE_RAW:
      return { ...state, formStateRaw: payload }

    case UPDATE_FORM_STATE_DRAFT:
      return {
        ...state,
        formStateDraft: {
          ...(state.formStateDraft || {}),
          [payload.key]: payload.value,
        },
      }

    case UPDATE_FORM_STATE_RAW:
      return {
        ...state,
        formStateRaw: {
          ...(state.formStateRaw || {}),
          [payload.key]: payload.value,
        },
      }

    case REVERT_FORM_STATE_TO_RAW:
      return {
        ...state,
        formStateDraft: cloneDeep(state.formStateRaw),
      }

    default:
      return state
  }
}
