import { IPackingFormDataState } from '../interfaces'
import { IGetPackingListResponse } from 'src/hooks/packing/useGetPackingList'
import { IPacking } from 'src/interfaces/packing'
import { IPlacePacked } from '@/interfaces'

export const SET_PACKING_RAW = 'SET_PACKING_RAW'
export const setPackingRawAction = (documentRaw: IPacking | null) => {
  return {
    type: SET_PACKING_RAW,
    payload: documentRaw,
  }
}
export const SET_PLACES_RAW = 'SET_PLACES_RAW'
export const setPlacesRawAction = (places: IPlacePacked[]) => {
  return {
    type: SET_PLACES_RAW,
    payload: places,
  }
}

export const SET_PACKING_FORM_STATE_DRAFT = 'SET_PACKING_FORM_STATE_DRAFT'
export const setPackingFormDraftAction = (formState: IPackingFormDataState) => {
  return {
    type: SET_PACKING_FORM_STATE_DRAFT,
    payload: formState,
  }
}

export const SET_PACKING_FORM_STATE_RAW = 'SET_PACKING_FORM_STATE_RAW'
export const setPackingFormRawAction = (formState: IPackingFormDataState) => {
  return {
    type: SET_PACKING_FORM_STATE_RAW,
    payload: formState,
  }
}

export const SET_PACKING_LIST_STATE_RAW = 'SET_PACKING_LIST_STATE_RAW'
export const setPackingListRawAction = (list: IGetPackingListResponse) => {
  return {
    type: SET_PACKING_LIST_STATE_RAW,
    payload: list,
  }
}
