import { useQuery } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, StickerWithCopies } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'

interface IParams {
  orderId: string
}
const getAdditionalDocsV2 = async (params: IParams): Promise<IResult> => {
  const result = await api.get(`/packing-v2/order/${params.orderId}/documents`)
  if (!result?.data?.success) {
    throw result
  }
  return result.data
}

interface IResult extends ApiReqResult {
  files_for_print: StickerWithCopies[]
}


const useFetchAdditionalDocsV2 = () => ({
  fetch: getAdditionalDocsV2
})

const useGetAdditionalDocsV2 = (
  params: IParams,
  options?: UseQueryOptions<IResult>,
) => {
  return useQuery<IResult>(
    [`getPackagings`, params],
    () => getAdditionalDocsV2(params),
      options
  )
}

export { useGetAdditionalDocsV2, useFetchAdditionalDocsV2 }
