import { ValuePreview } from '@widgets/GeneratorValuesPreview'
import { GeneratorStateType } from './GeneratorFields'
import {
  FieldRow,
  FieldType,
  GenerateFieldType,
  isBool,
  isCalendar,
  isCombobox,
  isInput,
  isMerchants,
  isSku,
} from './types'
import days from 'dayjs'
import { DATE_FORMAT_VIEW, MONTH_FORMAT_VIEW, TIME_FORMAT } from '@shared/const/date'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { boolSelectItems } from '@widgets/GeneratorFields/commonData'
import { IFieldParams, IFieldParamsValues, TemplateFieldType } from '@shared/types/tableGenerator/generatorTypes'

type ResultType = Record<string, GenerateFieldType>
const getInfoFields = (fieldsList: GenerateFieldType[]): ResultType => {
  /** Рекурсивно вытаскиваем все поля в объект, с доступом по ключу */
  return fieldsList.reduce((acc, field): ResultType => {
    if (field.jointFields) {
      const fields = getInfoFields(field.jointFields)
      return { ...acc, ...fields }
    }
    if (field.fieldProps) {
      return { ...acc, [field.fieldProps.id]: field }
    }
    return acc
  }, {} as ResultType)
}

const getHashTableFields = (rowsFields: FieldRow[]): ResultType => {
  /** Приводим все поля к объекту с доступом по ключу */
  return rowsFields.reduce((acc, row) => {
    const infoObj = getInfoFields(row.fields)
    return { ...acc, ...infoObj }
  }, {} as ResultType)
}
const getDateValue = (args: {
  value: string
  hasTime?: boolean
  isMonth?: boolean
  customFormat?: string
}) => {
  const { value, hasTime, customFormat, isMonth } = args
  let format = `${DATE_FORMAT_VIEW} ${hasTime ? TIME_FORMAT : ''}`
  if (isMonth) {
    format = MONTH_FORMAT_VIEW
  }
  if (customFormat) {
    format = customFormat
  }
  return days(value).format(format)
}

const convertValueToCorrectFormat = (
  value: any,
  field: GenerateFieldType,
): string => {
  /**  Конвертируем значения фильтров в строку в нужный формат */
  if (isBool(field)) {
    return value ? 'Включен' : ''
  }
  if (isInput(field)) {
    return value
  }
  if (isCalendar(field)) {
    const fieldType = field.fieldProps?.type || ''
    const hasTime = fieldType.includes('time')
    const isRange = fieldType.includes('range')
    const isMonth = fieldType.includes('month')
    if (isMonth) {
      return getDateValue({ value, isMonth })
    }
    if (isRange) {
      return value
        .map((v: string) => getDateValue({ value: v, hasTime }))
        .join(' - ')
    }
    return getDateValue({ value, hasTime })
  }
  const isComboboxFnsArr = [isCombobox, isMerchants, isSku]
  const isComboboxField = isComboboxFnsArr.some(fn => fn(field))
  if (isComboboxField) {
    if (Array.isArray(value)) {
      return value.map((v) => v.label).join(', ')
    }
    return value.label
  }

  return '-'
}

export const convertValuesToView = (
  filterState: GeneratorStateType,
  filterRowsFields: FieldRow[],
  excludeFilterFields: string[] = [],
): ValuePreview[] => {
  const fieldInfo = getHashTableFields(filterRowsFields)
  return Object.keys(filterState)
    .filter(
      (filterKey) =>
        filterState[filterKey as keyof typeof filterState] &&
        !excludeFilterFields.includes(filterKey),
    )
    .map((filterKey) => {
      const key = filterKey as keyof typeof filterState
      const field = fieldInfo[key]
      const label =
        field?.fieldProps?.viewDescription || field?.fieldProps?.label || '-'
      const value = convertValueToCorrectFormat(filterState[key], field)

      return {
        key: filterKey,
        label: label,
        value: value,
      }
    })
}


export const generateFieldsValue = (fields: IFieldParamsValues[] = []) =>
  fields.map((field) => ({
    label: field.title,
    id: field.value,
    value: field.value,
  }))

/* Преобразовываем тип с бека под тип фронтового генератора */
export const templateChecker = (fields: IFieldParams[]): any[] => {
  return fields.map((field) => {
    switch (field.type) {
      // Дата
      case TemplateFieldType.DATETIME:
        return {
          type: FieldType.calendar,
          fieldProps: {
            id: field.id,
            type: 'date-time',
            label: field.title,
            form: 'defaultBrick',
            rightSide: IconCalendar,
            size: 's',
          },
        }
      case TemplateFieldType.DATE:
        return {
          type: FieldType.calendar,
          fieldProps: {
            id: field.id,
            label: field.title,
            form: 'defaultBrick',
            rightSide: IconCalendar,
            size: 's',
          },
        }
      case TemplateFieldType.YEARMONTH:
        return {
          type: FieldType.calendar,
          fieldProps: {
            id: field.id,
            label: field.title,
            rightSide: IconCalendar,
            type: 'month',
            size: 's',
          },
        }


      case TemplateFieldType.CONTRACTORS:
      case TemplateFieldType.SKUS:
      case TemplateFieldType.MERCHANTS:
      case TemplateFieldType.CELLS:
        return {
          type: field.type,
          fieldProps: {
            id: field.id,
            isMultiple: true,
            label: field.title,
            size: 's'
          },
        }

      case TemplateFieldType.SKU:
      case TemplateFieldType.MERCHANT:
      case TemplateFieldType.CELL:
      case TemplateFieldType.CONTRACTOR:
        return {
          type: field.type,
          fieldProps: {
            id: field.id,
            label: field.title,
            size: 's',
          },
        }

      case TemplateFieldType.MULTISELECT:
        return {
          type: FieldType.combobox,
          fieldProps: {
            id: field.id,
            label: field.title,
            isMultiple: true,
            items: generateFieldsValue(field.values),
            type: field.type,
            size: 's',
          },
        }
      case TemplateFieldType.SELECT:
        return {
          type: FieldType.combobox,
          fieldProps: {
            id: field.id,
            label: field.title,
            items: generateFieldsValue(field.values),
            type: field.type,
            size: 's',
          },
        }


      case TemplateFieldType.TEXT:
        return {
          type: FieldType.input,
          fieldProps: {
            id: field.id,
            label: field.title,
            size: 's',
          },
        }
      case TemplateFieldType.NUMBER:
        return {
          type: FieldType.input,
          fieldProps: {
            id: field.id,
            label: field.title,
            size: 's',
            isNumber: true
          },
        }


      case TemplateFieldType.BOOLEAN:
        return {
          type: FieldType.combobox,
          fieldProps: {
            id: field.id,
            label: field.title,
            items: boolSelectItems,
            size: 's'
          },
        }
      default:
        return null
    }
  }).filter((item) => item !== null)
}


// export const generateFieldsGrid = <T>(arr: T[], cols: number = 5): FieldRow[] => {
export const generateFieldsGrid = <T>(arr: T[], cols: number = 5) => {
  let result = []
  let i = 0
  while (i < arr.length) {
    result.push({ fields: arr.slice(i, i + cols) })
    i += cols
  }
  return result
}