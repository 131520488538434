import { PUSH_MAIN_BREADCRUMB_ITEM, UPDATE_MAIN_BREADCRUMB_ITEM } from '../actions'
import { IReducerAction } from 'src/interfaces'
import { IMainState } from '../interfaces'

export const mainInitialState: IMainState = {
  breadcrumbsPaths: []
}

export const mainReducer = (
  state: IMainState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {

    case PUSH_MAIN_BREADCRUMB_ITEM:
      if (state.breadcrumbsPaths[state.breadcrumbsPaths?.length-1]?.label === payload.label) return state
      return {
        ...state,
        breadcrumbsPaths: [...state.breadcrumbsPaths, payload]
      }

    case UPDATE_MAIN_BREADCRUMB_ITEM:
      if (state.breadcrumbsPaths[state.breadcrumbsPaths?.length-1]?.label === payload.label) {
        state.breadcrumbsPaths[state.breadcrumbsPaths?.length-1].path = payload.path
        return {
          ...state,
          breadcrumbsPaths: [...state.breadcrumbsPaths]
        }
      }
      return {
        ...state,
        breadcrumbsPaths: [...state.breadcrumbsPaths, payload]
      }


    default:
      return state
  }
}
