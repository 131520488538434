import React, { useEffect } from 'react'
import { useGetCarryerQuby } from 'src/hooks/carryerQuby'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'
import { formStatesAreEqual } from '../../../store/utils'
import {
  revertDraftStateToRawAction,
  setQubyFormDraftAction,
  setQubyFormRawAction,
  setQubyRawAction,
} from '@pages/OperationsAndDocumentsPage/store/actions'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { FormWrapper } from '../../../components'
import {
  QubyFormContextProvider,
  useQubyFormContext,
} from '@pages/OperationsAndDocumentsPage/context/CarryerQubyContext'
import { CarryerQubyFormContent } from './components'
import { IQubyFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces'
import { PATHS } from '@shared/routing'

const CarryerQubyForm = () => {
  const history = useHistory()

  const { state, dispatch } = useQubyFormContext()

  const { id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()
  const { data: qubyData, isFetching: qubyLoading } = useGetCarryerQuby({
    docId: documentId,
  })

  useEffect(() => {
    if (!qubyData) {
      return
    }
    const formData: IQubyFormDataState = {
      status: qubyData?.transportation.status || '',
      comment: qubyData?.transportation.comment || '',
    }

    dispatch(setQubyRawAction(qubyData?.transportation))
    dispatch(setQubyFormDraftAction(cloneDeep(formData)))
    dispatch(setQubyFormRawAction(cloneDeep(formData)))
  }, [qubyData])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.carryerQubyList,
      }),
    )
  }

  const statesAreEqual = formStatesAreEqual<IQubyFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )

  return (
    <FormWrapper
      loading={qubyLoading}
      title={state.documentRaw?.id || documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <CarryerQubyFormContent />
    </FormWrapper>
  )
}
const CarryerQubyFormWithContext = () => (
  <QubyFormContextProvider>
    <CarryerQubyForm />
  </QubyFormContextProvider>
)
export default CarryerQubyFormWithContext
