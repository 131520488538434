import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { FormTabs, generateTabs } from '../../../../../helpers/documentTabs'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { Comment, HistoryChanges, RelativeDocs } from '../../../../../components/CommonTabsPages'
import { updateFormStateDraftAction } from '../../../../../store/actions'
import { TabsContentWrapper } from '../../../../../components'
import { DocumentsParamsFields } from '..'
import { usePickingFormContext } from '../../../../../context/PickingFormContext'
import { DocumentTypeEnum } from '@/interfaces'

interface BatchFormContentProps {
  loading?: boolean
  refetch: () => void
}

const PickingFormContent = (props: BatchFormContentProps) => {
  const { page, id: supplyId, tabId } = useParams<{ page: OperAndDocumPagesType, id: string, tabId: string }>()

  const supplyTabsWithDescription = useMemo(() => generateTabs([
    FormTabs.documentsParams,
    FormTabs.relativeDocs,
    FormTabs.comments,
    FormTabs.historyChanges
  ]), [page])

  return (
    <TabsContentWrapper
      tabs={supplyTabsWithDescription}
    >
      <TabPage
        tabId={tabId}
        documentId={supplyId}
        refetch={props.refetch}
      />
    </TabsContentWrapper>
  )
}

interface ITabContent {
  tabId: string
  documentId: string
  refetch: () => void
}

const TabPage = React.memo(({ documentId, tabId, refetch }: ITabContent) => {
  const { state: { documentRaw, formStateDraft }, dispatch } = usePickingFormContext()

  switch (tabId) {
    case FormTabs.documentsParams:
      return <DocumentsParamsFields refetch={refetch} />
    case FormTabs.relativeDocs:
      return <RelativeDocs documentId={documentId} documentType={DocumentTypeEnum.PICKING}/>
    case FormTabs.comments:
      return (
        <Comment
          stateCommentValue={formStateDraft?.comment || ''}
          handleChangeComment={(value) =>
            dispatch(updateFormStateDraftAction('comment', value))}
        />
      )
    case FormTabs.historyChanges:
      return <HistoryChanges documentUUID={documentRaw?.picking.uuid} />
  }
  return null
})

export default PickingFormContent
