import { useMutation } from 'react-query'
import api from '@shared/api'
import { IDeliveryFromTo, IPlaceTransportation, IWarehouseAdress } from 'src/interfaces/transportationTemplates'

export interface IProps {
  docId?: string | null
  data: IData
}
interface IData {
  contractor: {
    phone: string
    title: string
  }
  external_warehouse_address: IWarehouseAdress | null
  order_prefix: string
  title: string
  transportation_type: string
  delivery_from?: IDeliveryFromTo | string
  delivery_to?: IDeliveryFromTo | string
  pickup_to?: IDeliveryFromTo | string
  pickup_from?: IDeliveryFromTo | string
  place?: IPlaceTransportation
}
// http://wms-test.kak2c.ru/warehouse-api/transportation-item-template/{template_id}
// ${props.docId ? `/${props.docId}` : ''}
const useUpdateTransportation = () => {
  const updateTransportation = async (props: IProps) => {
    const result = await api.post(
      `/transportation-item-template${props.docId ? `/${props.docId}` : ''}`,
      props.data,
    )
    if (!result) {
      throw new Error('Ошибка создания')
    }

    return result
  }

  return useMutation(updateTransportation)
}

export default useUpdateTransportation
