import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'
import { useMainContext } from '../context/MainContext'
import { PATHS } from '@shared/routing'
import { formPostfix, listPostfix, OperAndDocumPagesType, sidebarPagesTabs } from '../pagesType'
import { FormTabs } from '../helpers/documentTabs'
import { updateBreadcrumb } from '../store/actions'


const OPER_DOCS_PATH = PATHS.OPERATIONS_AND_DOCUMENTS
const DOCUMENT_PATH = PATHS.DOCUMENT_FORM


export const useHistoryPush = () => {
  const { state, dispatch } = useMainContext()
  const history = useHistory()
  const location = useLocation()
  const { page: currentPage } = useParams<{ page: OperAndDocumPagesType }>()

  const getSidebarPageByPageId = (pageId: string) => sidebarPagesTabs.find((pageItem) => {
    return (
      pageItem.value === pageId ||
      pageItem.value === `${pageId}${listPostfix}` ||
      pageItem.value === `${pageId}${formPostfix}`
    )
  })

  const getLinkPath = (
    page: OperAndDocumPagesType | string,
    id?: string,
    tabId: string = FormTabs.documentsParams,
  ) => {
    return generatePath(OPER_DOCS_PATH, { page, id, tabId })
  }
  const getNewLinkPath = (
    docType: OperAndDocumPagesType | string,
    docId?: string,
    tabId: string = FormTabs.documentsParams,
  ) => {
    return generatePath(DOCUMENT_PATH, { docType, docId, tabId })
  }


  const handleUpdateBreadcrumb = (
    page: OperAndDocumPagesType | string,
    id?: string,
    tabId: string = FormTabs.documentsParams,
  ) => {
    const sidebarPage = getSidebarPageByPageId(page)
    const search = history?.location?.search || ''
    const generatedPath = `${generatePath(OPER_DOCS_PATH, { page, id, tabId })}${search || ''}`
    dispatch(updateBreadcrumb(
      `${sidebarPage?.label || '-'}${id ? `: ${id}` : ''}`,
        generatedPath,
    ))
  }

  const handlePushBreadcrumb = (
    page: OperAndDocumPagesType | string,
    id?: string,
    tabId: string = FormTabs.documentsParams,
  ) => {
    handleUpdateBreadcrumb(page, id, tabId)
  }

  const historyPush = (
    page: OperAndDocumPagesType | string,
    id?: string,
    tabId: string = FormTabs.documentsParams,
  ) => {
    const generatedPath = generatePath(OPER_DOCS_PATH, { page, id, tabId })
    const search = history?.location?.search || ''
    const listFormPageItem = (
      page?.replace(listPostfix, '')?.replace(formPostfix, '') === currentPage?.replace(listPostfix, '')?.replace(formPostfix, '')
    )
    history?.push({ pathname: generatedPath, search: listFormPageItem ? search : '' })
    handlePushBreadcrumb(page, id, tabId)
  }

  return {
    getLinkPath,
    historyPush,
    getNewLinkPath,
    handlePushBreadcrumb,
    handleUpdateBreadcrumb
  }
}
