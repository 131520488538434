import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useShipmentContext } from '../../../../../context/ShipmentContext'

import { DocumentsParams, DocumentStatusField } from '../../../../../components'
import { DocumentTypeEnum } from 'src/interfaces'
import { InputWrapper } from 'src/components'
import { updateFormStateDraftAction } from '@pages/OperationsAndDocumentsPage/store/actions'

import { OperAndDocumPagesType } from '../../../../../pagesType'
import { IShipmentData } from '../../../../../store/interfaces/shipment'

import { generateShipmentColumns, generateShipmentRows } from './utils'

import styles from './documents-params.module.scss'

const DocumentsParamsFields = () => {
  const { page, id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()
  const { state, dispatch } = useShipmentContext()

  const driver = `${state.documentRaw?.carrier?.surname || ''} ${
    state.documentRaw?.carrier?.name || ''
  } ${state.documentRaw?.carrier?.patronymic || ''}`.replace('  ', '')

  const rows = useMemo(
    () =>
      generateShipmentRows(
        state.documentRaw?.items || [],
        state.allData || ({} as IShipmentData),
      ),
    [state.documentRaw?.items],
  )

  const cols = useMemo(() => generateShipmentColumns(), [])

  return (
    <DocumentsParams rows={rows} columns={cols} tableTitle={''}>
      <div className={styles.fieldsWrapper}>
        <DocumentStatusField
          className={styles.status}
          value={state.formStateDraft?.status}
          onChange={(optionValue) =>
            dispatch(updateFormStateDraftAction('status', optionValue?.value))
          }
          documentType={page as DocumentTypeEnum}
        />
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.delivery_service || ''}
          id={'deliveryService'}
          label={'Курьерская служба'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.delivery_service || ''}
          id={'transportService'}
          label={'Транспортная служба'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={driver}
          id={'driver'}
          label={'Водитель'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.vehicle?.model || ''}
          id={'car'}
          label={'Автомобиль (Номер, марка)'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.carrier?.phone || ''}
          id={'telephone'}
          label={'Телефон'}
          disabled
        />
      </div>
    </DocumentsParams>
  )
}

export default DocumentsParamsFields
