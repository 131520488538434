import React, { useEffect, useMemo, useState } from 'react'

import { useReplaceContext } from '../../../../../context/ReplaceContext'
import { DocumentsParams } from '../../../../../components'
import { Button, InputWrapper } from 'src/components'
import { IAcceptanceSku, REPLACE_STATUSES, StickerResult } from 'src/interfaces'
import PrintWidget, { IPrintWidgetItem, StickerTypeEnum } from 'src/components/widgets/PrintWidget/PrintWidget'
import days from 'dayjs'
import { useParams } from 'react-router-dom'
import { OperAndDocumPagesType } from '@pages/OperationsAndDocumentsPage'
import MassPrintModal from '../MassPrintModal/MassPrintModal'
import { generateReplaceColumns, generateReplaceRows } from './utils'
import { useGetReplaceById, useItemGetSticker } from 'src/hooks'
import { usePrintPickingLists } from '@shared/hooks'
import styles from './documents-params.module.scss'
import { IReplaceItem } from 'src/interfaces/replace'
import useGetDocumentRelated from '@/hooks/document/useGetDocumentRelated'
import { DATE_TIME_VIEW } from '@shared/const/date'

interface ReplaceFormContentProps {}

const DocumentsParamsFields = (props: ReplaceFormContentProps) => {
  const { state } = useReplaceContext()
  const { id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()
  const { isLoading, data: replaceData } = useGetReplaceById(documentId)

  const [showPrinting, setShowPrinting] = useState<boolean>(false)
  const [printList, setPrintList] = useState<IPrintWidgetItem[]>([])
  const [originalSkus, setOriginalSkus] = useState<IAcceptanceSku[]>([])
  const [document, setDocument] = useState<IReplaceItem[]>([])

  const rows = useMemo(
    () =>
      generateReplaceRows(
        state.documentRaw?.document?.items || [],
        replaceData?.skus,
        state?.documentRaw?.cells || [],
      ),
    [state.documentRaw],
  )
  const getSkuItemSticker = useItemGetSticker()

  const { data } = useGetDocumentRelated({ params: { doc_id: documentId } })

  const cols = useMemo(() => generateReplaceColumns(), [])

  useEffect(() => {
    if (!replaceData?.skus || !replaceData?.document.items) {
      return
    }
    setDocument(replaceData.document.items)
    setOriginalSkus(replaceData.skus)
  }, [replaceData?.skus, replaceData?.document.items])

  const { printPickingLists } = usePrintPickingLists()

  const handlePrint = async () => {
    const dataDocumentId = state.documentRaw?.document.uuid
    if (dataDocumentId) {
      await printPickingLists([dataDocumentId], false, true)
    } else {
      console.log('error while printing')
    }
  }
  const merchants = [
    ...new Set(state?.documentRaw?.skus.map((el) => el.merchant.title)),
  ]

  return (
    <>
      <DocumentsParams
        rows={rows}
        columns={cols}
        tableTitle={'Товары'}
        tableExtraHeader={
          <>
            <Button
              label={'Печать листа подбора'}
              onClick={handlePrint}
              size={'m'}
            />
            {/* Старая реализация печати стикеров. Пока не удаляю,может потом понадобиться. Но модалку нужно поправить,некорректно работает */}
            {/* <Button
              label={'Печать стикеров новой номенклатуры'}
              view={'secondary'}
              iconRight={IconPrinter}
              onClick={() => setShowPrinting(true)}
              size={'m'}
            /> */}
          </>
        }
      >
        <div className={styles.fieldsWrapper}>
          <InputWrapper
            className={styles.fieldType25}
            value={days(state.documentRaw?.document?.date).format(DATE_TIME_VIEW)}
            id={'date'}
            label={'Дата'}
            disabled
          />

          <InputWrapper
            className={styles.fieldType25}
            value={merchants.length === 1 ? merchants[0] : '-'}
            id={'organization'}
            label={'Организация'}
            disabled
          />
          {/* REPLACE_STATUSES[
                state.documentRaw?.document?.merchant?.title || '-'
               ] */}
          <InputWrapper
            className={styles.fieldType25}
            value={REPLACE_STATUSES[state.documentRaw?.document?.status || '']}
            id={'status'}
            label={'Статус'}
            disabled
          />

          <InputWrapper
            className={styles.fieldType25}
            value={state.documentRaw?.document.comment || ''}
            id={'comment'}
            label={'Комментарий'}
            disabled
          />
        </div>
      </DocumentsParams>
      {showPrinting ? (
        <MassPrintModal
          isOpen={true}
          originalSkus={originalSkus.flat()}
          documentReplace={document}
          onSubmit={(data) => {
            const printList = Object.keys(data).map((skuId) => {
              const origSku = originalSkus.find((sku) => sku.id === skuId)

              return {
                id: skuId,
                title: `Стикер номенклатуры: ${origSku?.title || ''}`,
                stickerType: StickerTypeEnum.nomenclature,
                action: async () =>
                  await getSkuItemSticker.fetch({
                    sku_id: skuId,
                    disable_billing: true,
                  }),
                copies: data[skuId as keyof object],
                resultCallback: (result: StickerResult) => ({
                  stickerExists: true,
                  sticker: result.sticker,
                }),
              }
            })
            setPrintList(printList)
            setShowPrinting(false)
          }}
          onClose={() => setShowPrinting(false)}
        />
      ) : null}
      {printList.length ? (
        <PrintWidget
          onStartPrinting={() => {}}
          onPausePrinting={() => {}}
          onStopPrinting={() => {}}
          onClose={() => {
            setPrintList([])
          }}
          printList={printList}
        />
      ) : null}
    </>
  )
}

export default DocumentsParamsFields
