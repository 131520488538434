import { REVERT_FORM_STATE_TO_RAW, UPDATE_FORM_STATE_DRAFT, UPDATE_FORM_STATE_RAW } from '../actions'

import { IReducerAction } from 'src/interfaces'
import { cloneDeep } from '@shared/helpers'
import { IPackingFormState } from '../interfaces'

import {
  SET_PACKING_FORM_STATE_DRAFT,
  SET_PACKING_FORM_STATE_RAW,
  SET_PACKING_LIST_STATE_RAW,
  SET_PACKING_RAW,
  SET_PLACES_RAW,
} from '../actions/packing'

export const packingInitialState: IPackingFormState = {
  documentRaw: null,
  formStateRaw: null,
  formStateDraft: null,
  places: [],
  list: null,
}

export const packingReducer = (
  state: IPackingFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    case SET_PACKING_RAW:
      return { ...state, documentRaw: payload }

    case SET_PLACES_RAW:
      return { ...state, places: payload }

    case SET_PACKING_FORM_STATE_DRAFT:
      return { ...state, formStateDraft: payload }

    case SET_PACKING_FORM_STATE_RAW:
      return { ...state, formStateRaw: payload }

    case SET_PACKING_LIST_STATE_RAW:
      return { ...state, list: payload }

    case UPDATE_FORM_STATE_DRAFT:
      return {
        ...state,
        formStateDraft: {
          ...(state.formStateDraft || {}),
          [payload.key]: payload.value,
        },
      }

    case UPDATE_FORM_STATE_RAW:
      return {
        ...state,
        formStateRaw: {
          ...(state.formStateRaw || {}),
          [payload.key]: payload.value,
        },
      }

    case REVERT_FORM_STATE_TO_RAW:
      return {
        ...state,
        formStateDraft: cloneDeep(state.formStateRaw),
      }

    default:
      return state
  }
}
