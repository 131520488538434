import { useMutation } from 'react-query'

import api from '@shared/api'

/*
 * Удаление документа
 * http://wms-test.kak2c.ru/warehouse-api/transportation-item-template/{template_id}
 */

interface DeleteTransportationTemplateParams {
  docId: string
}

const useDeleteTransportationTemplate = () => {
  return useMutation(async ({ docId }: DeleteTransportationTemplateParams) => {
    const result = await api.delete(`/transportation-item-template//${docId}`)

    if (!result?.data?.success) {
      throw result
    }
    return result.data
  })
}

export default useDeleteTransportationTemplate
