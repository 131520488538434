import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

/*
  Завершает погрузку. Переводит отгрузку в статус LOADED.
  wiki:
  https://wiki.kak2c.ru/link/785#bkmrk-%D0%97%D0%B0%D0%B2%D0%B5%D1%80%D1%88%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D1%82%D0%B3%D1%80%D1%83%D0%B7%D0%BA%D0%B8
*/

interface IParams {
  shipmentId: string
}

const useCompleteLoadingShipment = (
  params: IParams,
  callback?: () => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useMutation(
    async () => api.post(`/shipment/${params.shipmentId}/complete-loading`),
    {
      onSuccess: ({ data: { success } }: { data: ApiReqResult }) => {
        if (success) {
          callback?.()
        } else {
          error?.()
        }
      },
      onError: error,
      onSettled: settled,
    },
  )
}

export default useCompleteLoadingShipment
