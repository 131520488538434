import { ISupply, SupplyTypEnum } from 'src/interfaces'
import { TableColumn } from '@consta/uikit/Table'
import { SUPPLY_STATUSES, SUPPLY_STATUSES_STYLES } from '@/interfaces/statuses'
import { Badge } from '@consta/uikit/Badge'
import React from 'react'
import styles from '../supply-list.module.scss'
import { OperAndDocumPages } from '../../../../pagesType'
import { DateCell } from 'src/components'
import { DocumentNumCell } from '../../../../components'
import days from 'dayjs'
import { DATE_TIME_VIEW } from '@shared/const/date'


export const generateRows = (supplies: ISupply[]) => {
  return supplies.map(supply => ({
    id: supply.id,
    docId: supply.id,
    date: supply.create_ts,
    date_value: days(supply.create_ts).format(DATE_TIME_VIEW),
    supplyNum: supply.id,
    merchant: supply.merchant?.title,
    status: supply.status,
    supply_type: supply.supply_type,
    accompanyingDocNum: supply.accompanying_doc_num,
    supplier: supply.supplier?.title,
    status_value: SUPPLY_STATUSES[supply.status],
    hasDiffs: supply.has_diffs,
  }))
}
export const generateColumns = (): TableColumn<any>[] => {
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      // width: 130,
      renderCell: ({row}) => {
        return <DateCell date={row.date} style={{ minWidth: '120px' }} />
      },
    },

    {
      title: 'Номер поставки',
      sortable: true,
      accessor: 'supplyNum',
      renderCell: ({row}) => {
        return <div className={styles.supplyNum}>
          <DocumentNumCell
            withNewTab={true}
            documentNum={row.supplyNum}
            page={OperAndDocumPages.supplyForm}
          />
          <div className={styles.badges}>
          {row.hasDiffs ? (
            <Badge
              status={'warning'}
              view={'stroked'}
              label={'Расхождения'}
              size={'s'}
              className={styles.badge}
            />
          ) : null}
          {row.supply_type === SupplyTypEnum.FROM_SUPPLIER_WITH_DRIVER ? (
            <Badge
              status={'error'}
              view={'filled'}
              label={'Срочная приёмка'}
              size={'s'}
              className={styles.badge}
            />
          ) : null}
          </div>
        </div>
      },
    },
    {
      title: 'Поставщик',
      accessor: 'supplier',
    },
    {
      title: 'Организация',
      accessor: 'merchant',
    },
    {
      title: 'Номер сопровод-го документа',
      accessor: 'accompanyingDocNum',
    },
    {
      title: 'Статус',
      sortable: true,
      accessor: 'status_value',
      renderCell: ({row}) => {
        const status = row.status
        return <>
          <Badge
            status={SUPPLY_STATUSES_STYLES[status]}
            label={SUPPLY_STATUSES[status]}
          />
        </>
      },
    },
  ]
}
