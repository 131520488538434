import { useMutation } from 'react-query'

import api from '@shared/api'

/*
 
 *http://wms-test.kak2c.ru/warehouse-api/inventory/{inventory_id}
 */

export interface IProps {
  id: string
}

export const useDeleteInventoryDoc = () => {
  const deleteInventoryDoc = async ({ id }: IProps) => {
    const result = await api.delete(`/inventory/${id}`)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(deleteInventoryDoc)
}

export default useDeleteInventoryDoc
