import { useMutation } from 'react-query'

import api from '@shared/api'

import { MOCK_UPDATE_PACKING_PLACE, provideMock } from 'src/mocks'

import { IOrderPackagingPlace, IUpdatePackingPlaceResult } from 'src/interfaces'

interface UsePackingPlaceParamsType {
  orderId: string
  placeId?: string | number | null
}

const useUpdatePackingPlace = (params: UsePackingPlaceParamsType) => {
  return useMutation(async (data: { place: IOrderPackagingPlace }) => {
    let url = `/packing/order/${params.orderId}/place`
    if (params.placeId) {
      url += `/${params.placeId}`
    }
    try {
      const result = await provideMock<
        UsePackingPlaceParamsType,
        { data: IUpdatePackingPlaceResult }
      >(async () => await api.post(url, data), params, [
        {
          key: 'orderId',
          value: 'mock',
          result: MOCK_UPDATE_PACKING_PLACE,
          logData: { name: 'Отправка данных', data }
        },
        {
          key: 'orderId',
          value: 'mockb2b',
          result: MOCK_UPDATE_PACKING_PLACE,
          logData: { name: 'Отправка данных', data }
        },
      ])()

      if (!result?.data?.success) {
        throw result
      }
      return result.data
    } catch (e) {
      throw e
    }
  })
}

export default useUpdatePackingPlace
