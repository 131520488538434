import { useQuery } from 'react-query'
import api from '@shared/api'
import { ApiReqResult, PaginationResult } from 'src/interfaces'
import { ITransportaions } from 'src/interfaces/transportationTemplates'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'

// http://wms-test.kak2c.ru/warehouse-api/transportation

export interface IGetICarryerQubyResultParams {}

export interface IGetCarryerQubyResult extends ApiReqResult, PaginationResult {
  transportations: ITransportaions[]
}

const useGetCarryerQubyList = (
  paramsObj: IGetICarryerQubyResultParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IGetCarryerQubyResult>(
    [`getCarryerQubyList`, paramsObj],
    async () => {
      const searchParams = convertObjectToURLParams(paramsObj)
      const result = await api.get(`/transportation${searchParams}`)

      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}
export default useGetCarryerQubyList
