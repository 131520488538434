import { generateStatusesOptions, ValueOf } from '@shared/helpers'
import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { ICombobox } from '../acceptance'

export const ReturnStatusEnum: Record<string, string> = {
  new: 'NEW',
  processing: 'PROCESSING',
  awaitPlacing: 'AWAITING_PLACING',
  placing: 'PLACING',
  done: 'DONE',
} as const
export type ReturnStatusEnum = ValueOf<typeof ReturnStatusEnum>

export const RETURN_STATUSES: Record<ReturnStatusEnum | string, string> = {
  [ReturnStatusEnum.new]: 'Новый',
  [ReturnStatusEnum.processing]: 'В работе',
  [ReturnStatusEnum.awaitPlacing]: 'Ожидает',
  [ReturnStatusEnum.placing]: 'Размещение',
  [ReturnStatusEnum.done]: 'Выполнен',
}
export const returnStatusSelectOptions: ICombobox[] =
  generateStatusesOptions(RETURN_STATUSES)
export const RETURN_STATUS_BADGE_TYPE: Record<
  ReturnStatusEnum | string,
  BadgePropStatus
> = {
  [ReturnStatusEnum.new]: 'system',
  [ReturnStatusEnum.processing]: 'success',
  [ReturnStatusEnum.awaitPlacing]: 'system',
  [ReturnStatusEnum.placing]: 'system',
  [ReturnStatusEnum.done]: 'error',
}
export const RETURN_STATUS: {
  value: ReturnStatusEnum
  label: string
  style: BadgePropStatus
}[] = Object.keys(RETURN_STATUSES).map((key) => ({
  value: key as ReturnStatusEnum,
  label: RETURN_STATUSES[key as keyof object],
  style: RETURN_STATUS_BADGE_TYPE[key as keyof object],
}))
