import { IAcceptanceSkuObj, ISupplyItem, ITableColumn, ITableRow } from '@/interfaces'
import React from 'react'
import styles from '../unprocessed.module.scss'
import { IconAdd } from '@consta/icons/IconAdd'
import { Button } from '@consta/uikit/Button'
import { TableBatchCell } from '../../index'
import days from 'dayjs'
import { ComboboxWrapper, ImageCell, InputWrapper } from '@/components'
import { getBatchesOptions } from '../../../../utils'
import { BatchItemsValuesType, PrintItemBatchType, PrintItemsValuesType } from '../../../../../types'
import { DATE_FORMAT_VIEW } from '@shared/const/date'


interface IQuantityCell {
  skuQuantity: number, quantityValue?: number, handleSetPrintQuantity?: (value?: number) => void
}
const QuantityCell = (props: IQuantityCell) => {
  const { skuQuantity, quantityValue,handleSetPrintQuantity } = props

  const updateValue = (newValue: string | null) => {
    handleSetPrintQuantity?.(newValue ? Number(newValue) : undefined)
  }
  return (
    <div className={styles.printInputWrapper}>
      <InputWrapper
        isInteger
        id={'quantity'}
        handleChange={updateValue}
        value={quantityValue?.toString()}
        className={styles.countInput}
      />
      <Button
        label={'Вставить кол-во'}
        view={'ghost'}
        onClick={() => updateValue(String(skuQuantity || 0))}
        size={'m'}
      />
    </div>
  )
}

export const generateTableColumns = (
  quantityMode: boolean,
): ITableColumn[] => {
  const res = [
    {
      key: 'img',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '90px',
      renderCell: ({ row }) => <ImageCell image={String(row.img)} />,
    },
    {
      key: 'name',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: '1fr',
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
      gridCellWidth: '100px',
      align: 'center',
    },
    {
      key: 'measure',
      title: 'Едн.',
      title_txt: 'Едн.',
      gridCellWidth: '100px',
    },
    {
      key: 'batch',
      title: 'Партия',
      title_txt: 'Партия',
      gridCellWidth: quantityMode ? '100px' : '200px',
    },
    {
      key: 'action',
      title: '',
      title_txt: '',
      gridCellWidth: quantityMode
        ? '400px'
        : '1fr',
      isAction: true,
      align: 'right',
    },
  ] as ITableColumn[]
  return res.filter((column) => quantityMode ? !['measure','party'].includes(column.key) : true)
}

export const generateTableRows = (args: {
  originalSkusObj: IAcceptanceSkuObj,
  supplyItems: ISupplyItem[],
  batchSelectMode?: boolean,
  batchItemsValues?: BatchItemsValuesType
  advanceMode: boolean,
  handleAddItem: (itemId: string) => void,
  quantityMode: boolean,
  handleSetPrintQuantity?: (supplyItemId: string, skuId: string, value?: number) => void,
  printItemsValues?: PrintItemsValuesType,
  handleSetItemBatch: (skuId: string, data: PrintItemBatchType | {}) => void,
  handleCreateItemBatch: (skuId: string, supplyItemId: string) => void,
}): ITableRow[] => {
  const {
    batchItemsValues,
    batchSelectMode,
    originalSkusObj,
    supplyItems,
    advanceMode,
    handleAddItem,
    quantityMode,
    handleSetPrintQuantity,
    printItemsValues,
    handleCreateItemBatch,
    handleSetItemBatch
  } = args
  return supplyItems.map((supplyItem) => {
    const originalSku = originalSkusObj[supplyItem?.sku_id]
    const batch = originalSku?.batches?.find(
      (batch) =>
        batch.id === supplyItem.sku_batch_id,
    )
    const batchDate = batch?.exp_date
      ? days(batch.exp_date).format(DATE_FORMAT_VIEW)
      : ''

    const supplyItemId = supplyItem.id
    const skuId = supplyItem.sku_id
    const quantity = supplyItem.quantity
    const batchesOptions = getBatchesOptions(originalSku?.batches || [])
    const batchValue = batchesOptions?.find(option => batchItemsValues?.[skuId]?.find(el => el.batchId === option.id && el.supplyItemId === supplyItemId))
    const defaultBatchValue = batchesOptions?.find(option => option.id === supplyItem.sku_batch_id)


    return {
      id: supplyItemId,
      skuId: skuId,
      img: originalSku?.image,
      article: originalSku?.article,
      name: (
        <>
          <p>{originalSku?.title}</p>
          <span className={styles.lightTxt}>{originalSku?.article}</span>
        </>
      ),
      barcodes: originalSku?.barcodes,
      quantity: supplyItem.quantity,
      batches: originalSku?.batches,
      measure: 'шт',
      batch:
        batchSelectMode && originalSku.batch_accounting ?
           <ComboboxWrapper
              withCreateItem
              id='batch'
              label=''
              /* @ts-ignore */
              items={batchesOptions}
              /* @ts-ignore */
              value={batchValue || defaultBatchValue || null}
              placeholder='Выберите партию'
              searchFunction={(item, searchValue) => {
                const value = searchValue?.toUpperCase()
                const label = item?.labelTxt?.toUpperCase()
                return label?.includes(value)
              }}
              onCreate={() => handleCreateItemBatch(skuId, supplyItemId)}
              onChange={({value}) => {
                // if (!value) {
                //   handleSetItemBatch(skuId, {})
                //   return
                // }
                handleSetItemBatch(skuId, { batchId: value?.id ? String(value.id) : undefined, supplyItemId: supplyItemId })
              }}
              className={styles.batchSelect}
            />
          : batch
            ? <TableBatchCell date={batchDate} num={batch.num || ''} />
            : null,
      action: (
        <div className={styles.actionCell}>
          {quantityMode && QuantityCell ? (
            <QuantityCell
              skuQuantity={Number(quantity)}
              quantityValue={printItemsValues?.[supplyItemId]?.copies}
              handleSetPrintQuantity={(quantity) => handleSetPrintQuantity(supplyItemId, skuId, quantity)}
            />
          ) : advanceMode ? (
            <Button
              onlyIcon
              iconLeft={IconAdd}
              view={'clear'}
              size={'s'}
              onClick={(e) => {
                handleAddItem(supplyItemId)
              }}
              className={styles.addIcon}
            />
          ) : null
          }
        </div>
      )
    }
  })
}
