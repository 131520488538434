import React from 'react'

import { IGetPackingListResponse } from 'src/hooks/packing/useGetPackingList'

import { OperAndDocumPages } from '../../../../pagesType'

import { TableColumn } from '@consta/uikit/Table'
// import { Badge } from '@consta/uikit/Badge'
import { DateCell } from 'src/components'
import { DocumentNumCell, StatusCell } from '../../../..//components'
import { DATE_TIME_VIEW } from '@shared/const/date'
import days from 'dayjs'
import { OrderStatusEnum } from '@/interfaces'


export const generateRows = (data: IGetPackingListResponse) => {
  if (!data.pallets) {
    return []
  }

  return data.pallets.map((pallet) => {
    const merchants = new Map()
    const deliveries = new Set()

    pallet.orders.forEach((order) => {
      merchants.set(order?.merchant?.id, order?.merchant?.title)
      deliveries.add(order?.delivery.title)
    })

    let borderStatus: 'danger' | 'success' | 'warning' = 'warning'
    if (!pallet.places?.length) {
      borderStatus = 'warning'
    } else {
      const allOrdersShipped = pallet.orders.every((order) => order?.status === OrderStatusEnum.shipped)
      if (allOrdersShipped) {
        borderStatus = 'success'
      } else {
        borderStatus = 'danger'
      }
    }
    return {
      id: pallet.id || '',
      date: pallet.create_ts,
      date_value: days(pallet.create_ts).format(DATE_TIME_VIEW),
      documentNum: pallet.id,
      status: pallet?.status,
      borderStatus: borderStatus,
      deliveries: Array.from(deliveries).join(', '),
      orgs: Array.from(merchants.values()).join(', '),
    }
  })
}

export const generateColumns = (): TableColumn<any>[] => {
  return [
    {
      title: 'Дата',
      sortable: true,
      accessor: 'date_value',
      renderCell: ({row}) => {
        return (
          <StatusCell
            themeStatus={row.borderStatus}
          //   statusDescription={{
          //     success: 'Отгружен',
          //     danger: 'В работе',
          //     warning: 'В плане'
          //   }}
          >
            <DateCell date={row.date} style={{minWidth: '120px'}}/>
          </StatusCell>
        )
      }
    },
    {
      title: 'Номер документа',
      sortable: true,
      accessor: 'documentNum',
      renderCell: ({row}) => {
        return <DocumentNumCell page={OperAndDocumPages.transportPlaceForm} documentNum={row.documentNum} />
      }
    },
    {
      title: 'Организация',
      accessor: 'orgs',
    },
    {
      title: 'Курьерская служба',
      accessor: 'deliveries',
    },
  ]
}
