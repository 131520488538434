export const checkValidDate = (val: string) => {
  let valid = true
  const strWithoutDots = val.replace(/\./g, '')

  strWithoutDots.split('').forEach((c: string, i: number) => {
    if (i === 0 && !/[0-3]/.test(c)) {
      valid = false
    }
    if (i === 1 && !/[0-9]/.test(c)) {
      valid = false
    }
    if (i === 2 && !/[0-1]/.test(c)) {
      valid = false
    }
    if (i === 3 && !/[0-9]/.test(c)) {
      valid = false
    }
  })

  if (strWithoutDots[2] === '1' && /[3-9]/.test(strWithoutDots[3])) {
    valid = false
  }
  if (
    strWithoutDots[2] === '0' &&
    strWithoutDots.length > 3 &&
    !/[1-9]/.test(strWithoutDots[3])
  ) {
    valid = false
  }

  if (strWithoutDots[0] === '3' && /[2-9]/.test(strWithoutDots[1])) {
    valid = false
  }

  return valid
}

export const getFormattedDate = (val: string) => {
  if (!checkValidDate(val)) return

  return val
    .split('')
    .reduce((a: string[], c: string, i: number) => {
      if (i === 5 && c !== '.') a.push('.20')
      if (i === 2 && c !== '.') a.push('.')
      a.push(c)
      return a
    }, [])
    .join('')
    .slice(0, 10)
}