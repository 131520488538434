import React, { useEffect } from 'react'
import { useGetTransportationTemplate } from 'src/hooks/trasportationTemplates'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import { formStatesAreEqual } from '../../../store/utils'
import { cloneDeep } from '@shared/helpers'
import { FormWrapper } from '../../../components'
import {
  revertDraftStateToRawAction,
  setTemplateDocumentRawAction,
  setTemplateFormDraftAction,
  setTemplateFormRawAction,
} from '../../../store/actions'
import {
  TemplateFormContextProvider,
  useTemplateFormContext,
} from '@pages/OperationsAndDocumentsPage/context/TemplateContext'
import { TransportationTemplatesFormContent } from './components'
import { ITemplatesFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces'
import { PATHS } from '@shared/routing'

const TransportationTemplatesForm = () => {
  const history = useHistory()
  const { state, dispatch } = useTemplateFormContext()
  const { id: documentId, tabId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()

  const {
    data: templateData,
    isFetching: isTemplateLoading,
    refetch: refetchTemplate,
  } = useGetTransportationTemplate({ template_id: documentId })

  useEffect(() => {
    if (!templateData) {
      return
    }
    const formData: ITemplatesFormDataState = {
      status: templateData?.template.transportation_type || '',
      comment: templateData?.template.order_prefix || '',
    }
    dispatch(setTemplateDocumentRawAction(templateData?.template))
    dispatch(setTemplateFormDraftAction(cloneDeep(formData)))
    dispatch(setTemplateFormRawAction(cloneDeep(formData)))
  }, [templateData])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.inventoryList,
      }),
    )
  }


  const statesAreEqual = formStatesAreEqual<ITemplatesFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )
  return (
    <FormWrapper
      loading={isTemplateLoading}
      title={state.documentRaw?.title || documentId}
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <TransportationTemplatesFormContent />
    </FormWrapper>
  )
}
const TransportationTemplatesFormWithContext = () => (
  <TemplateFormContextProvider>
    <TransportationTemplatesForm />
  </TemplateFormContextProvider>
)
export default TransportationTemplatesFormWithContext
