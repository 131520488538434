import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { IReducerAction } from '@/interfaces'
import { IQubyFormState } from '../store/interfaces'
import { qubyInitialState, qubyReducer } from '../store/reducer'

export interface IUseReducer {
  state: IQubyFormState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {}

export const QubyFormContext = createContext<IContext>({
  state: qubyInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const QubyFormContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof qubyInitialState, IReducerAction>
  >(qubyReducer, qubyInitialState)

  useEffect(() => {
    console.log('--- State ---', state)
  }, [state])

  return (
    <QubyFormContext.Provider value={{ state, dispatch }}>
      {children}
    </QubyFormContext.Provider>
  )
}
export const useQubyFormContext = () => useContext(QubyFormContext)
