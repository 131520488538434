import { FieldRow, FieldType } from '@widgets/GeneratorFields'

export const generateFilters = (): FieldRow[] => [
  {
    hidden: true,
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: 'search',
          viewDescription: 'Поиск',
        },
      },
    ],
  },
  {
    fields: [
      {
        type: 'input',
        fieldProps: {
          id: 'ids',
          label: 'Поиск по номеру документа',
          viewDescription: 'Номер документа',
        },
      },

      {
        type: FieldType.MERCHANTS,
        fieldProps: {
          id: 'merchant',
          isMultiple: true,
          label: 'Организация',
          viewDescription: 'Организация',
        },
      },
    ],
  },
]
