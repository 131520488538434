import React, { FC, useMemo } from 'react'
import styles from './services-table.module.scss'
import { TableV2 } from 'src/components'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { generateColumns, generateRows } from './utils'
import { FormInputCalcItem, PreCalcItem, Service } from 'src/interfaces'

/**
 * Таблица с инпутами для каждой строки
 */

interface ServicesTableProps {
  title: string
  services: Service[]
  preCalcItems: FormInputCalcItem[]
  initialPreCalcItems: PreCalcItem[]
  extraHeader?: React.ReactNode
  withCheckbox?: boolean
  maxHeightBody?: number
  checkedIds?: string[]
  setCheckedIds?: React.Dispatch<React.SetStateAction<string[]>>
  handleChangeInput: (id: string, value: TextFieldPropValue) => void
  readonly?: boolean
}

const ServicesTable: FC<ServicesTableProps> = (props) => {
  const {
    title,
    services,
    preCalcItems,
    initialPreCalcItems,
    extraHeader,
    withCheckbox,
    maxHeightBody = 550,
    checkedIds,
    setCheckedIds,
    handleChangeInput,
    readonly,
  } = props

  /**
   * Зависимости
   * !!! Набор зависимостей лучше не изменять
   * @checkedIds - Зависимость нужна для работы с чекбоксами,
   * если убрать то не будет последнего значения инпута
   * и при клике на чекбокс, будет подставляться прошлое значение
   * @services - Добавлеиные услуги
   * @preCalcItems - Добавлеиные значения услуг
   */
  const rows = useMemo(
    () =>
      generateRows(
        services || [],
        preCalcItems || [],
        initialPreCalcItems || [],
      ),
    [services, preCalcItems, checkedIds, readonly],
  )

  const columns = useMemo(
    () =>
      generateColumns(
        rows,
        checkedIds || [],
        withCheckbox,
        setCheckedIds,
        handleChangeInput,
        readonly,
      ),
    [rows, checkedIds],
  )

  return (
    <div className={styles.main}>
      <TableV2
        fullHeight
        theme={'gray'}
        extraHeader={extraHeader}
        rows={rows}
        columns={columns}
        title={title}
        titleClassName={styles.tableTitle}
        className={styles.tableWrap}
        maxHeightBody={maxHeightBody}
      />
    </div>
  )
}

export default React.memo(ServicesTable)
