import { useMutation } from 'react-query'

import api from '@shared/api'

import { provideMock } from 'src/mocks'

import { IBatch, useCreateBatchResult } from 'src/interfaces'

interface useCreateBatchParams {
  itemId: string
}

const useCreateBatch = (params: useCreateBatchParams) => {
  return useMutation(async (data: { batch: Partial<IBatch> }) => {
    try {
      const result = await provideMock<
        useCreateBatchParams,
        { data: useCreateBatchResult }
      >(
        async () => await api.post(`/items/batches`, { ...data, sku_id: params.itemId }),
        params,
        {
          key: 'itemId',
          value: 'mockItem',
          result: { data: { success: true, id: 'someId' } },
        },
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result?.data
    } catch (e) {
      throw e
    }
  })
}

export default useCreateBatch
