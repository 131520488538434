import React, { FC, useEffect } from 'react'
import styles from './input-with-button-control.module.scss'
import { Button, InputWrapper } from 'src/components'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { useDebounce } from '@shared/hooks'

interface InputWithButtonControlProps {
  id: string
  value: string | number
  initialServerValue: string
  handleChangeInput: (id: string, value: TextFieldPropValue) => void
  readonly?: boolean
}

const InputWithButtonControl: FC<InputWithButtonControlProps> = (props) => {
  const {
    id,
    value,
    initialServerValue,
    handleChangeInput,
    readonly = false,
  } = props

  const [valueDebounced, localStateValue, setLocalStateValue] =
    useDebounce<TextFieldPropValue | null>(value ? `${value}` : null)

  useEffect(() => {
    /** Обновление значения в инпуте с debounce */
    if (valueDebounced === null || value?.toString() === valueDebounced) {
      return
    }
    handleChangeInput(id, valueDebounced)
  }, [valueDebounced])

  useEffect(() => {
    /**
     * Обновление значения в инпуте через стейт
     * Для вставки значения через кнопку рядом с инпутом
     * или через вставку всех значений с сервера
     */
    if (value || value === 0) {
      setLocalStateValue(`${value}`)
    }
  }, [value])

  return (
    <div className={styles.main}>
      <div className={styles.fieldItemBtnWrap}>
        <Button
          label={'Вставить'}
          view={'ghost'}
          onClick={() => {
            const strValue = `${initialServerValue || 0}`
            handleChangeInput(id, initialServerValue)
            setLocalStateValue(strValue)
          }}
          disabled={readonly}
          className={styles.btn}
        />
        <div>{initialServerValue ?? ''}</div>
      </div>
      <InputWrapper
        isNumber={true}
        id={id}
        value={localStateValue}
        handleChange={(value) => setLocalStateValue(value)}
        className={styles.input}
        placeholder={'0'}
        disabled={readonly}
      />
    </div>
  )
}

export default React.memo(InputWithButtonControl)
