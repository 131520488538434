import api from '@shared/api'

import { ApiReqResult, IContainer, IPhysicalSetOrderPlaceDto } from 'src/interfaces'


interface IParams {
  placeId: string
}

interface IResult extends ApiReqResult {
  place: IPhysicalSetOrderPlaceDto
  containers: IContainer[]
}

const getPhysicalSetOrderPlace = async (params: IParams) => {
  const result = await api.get(`/physical-set-order-place/${params.placeId}`)
  if (!result?.data?.success) {
    throw new Error('Ошибка комплекта')
  }
  return result.data
}


export const useFetchPhysicalSetOrderPlace = () => ({
  fetch: async (params: IParams): Promise<IResult> => await getPhysicalSetOrderPlace(params)
})
