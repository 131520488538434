import { IOrder } from 'src/interfaces'
import { IOrderFormDataState } from '../interfaces/order'

export const SET_ORDER_RAW = 'SET_ORDER_RAW'
export const setOrderRawAction = (documentRaw: IOrder | null) => {
  return {
    type: SET_ORDER_RAW,
    payload: documentRaw,
  }
}

export const SET_ORDER_FORM_STATE_DRAFT = 'SET_ORDER_FORM_STATE_DRAFT'
export const setOrderFormDraftAction = (formState: IOrderFormDataState) => {
  return {
    type: SET_ORDER_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_ORDER_FORM_STATE_RAW = 'SET_ORDER_FORM_STATE_RAW'
export const setOrderFormRawAction = (formState: IOrderFormDataState) => {
  return {
    type: SET_ORDER_FORM_STATE_RAW,
    payload: formState,
  }
}

