import { useQuery } from 'react-query'
import api from '@shared/api'
import { ApiReqResult, IZoneTreeNode, ZoneTypesEnum } from '../../interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'


interface Params {
  zone_type?: ZoneTypesEnum
  zone_id?: string
  row_index?: number
  bay_index?: number
  hide_cells?: boolean
}

interface Result extends ApiReqResult {
  zones: IZoneTreeNode[]
}

const useGetZoneSettings = (
  params: Params,
  options?: UseQueryOptions<any>
) => {
  const fetchZoneSettings = async () => {
    const result = await api.get('/warehouse/cell/tree', {})

    return result.data
  }

  return useQuery(['getZoneSettings'], fetchZoneSettings, options)
}

export default useGetZoneSettings
