import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, ISku } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { ISortingDoc } from '@/interfaces/sorting'

interface IParams {
  picking_id: string
}

const startSorting = async (params: IParams) => {
  const result = await api.post(`/sorting/start`, {picking_id: params.picking_id})
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

interface IApiResult extends ApiReqResult {
  sorting: ISortingDoc
  skus: ISku[]
}

const useStartSorting = (
  options?: UseMutationOptions<IApiResult, any, IParams>,
) => {
  return useMutation<IApiResult, any, IParams>(startSorting, options)
}

export default useStartSorting
