import React from 'react'
import { InputWrapper, Modal } from '@/components'
import styles from './confirm-deletion-modal.module.scss'
import { Button } from '@consta/uikit/Button'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { rusToLatin } from '@shared/helpers'

interface IProps {
  loading?: boolean
  onSubmit: (barcode: TextFieldPropValue) => void
  onClose: () => void
  isOpen: boolean
}

const ConfirmDeletionModal = ({
  loading,
  isOpen,
  onSubmit,
  onClose,
}: IProps) => {
  const onClickCancel = () => {
    onClose()
  }
  const onChangeInput = (value: TextFieldPropValue) => {
    if (!value) {
      return
    }
    let barcodeValue = rusToLatin(value.trim())
    onSubmit(barcodeValue)
  }

  if (!isOpen) {
    return null
  }

  return (
    <Modal
      title="Удаление товара или тары"
      titleAlign={'left'}
      subtitle=""
      size="s"
      isOpen={isOpen}
      className={styles.modal}
      onClose={() => {}}
    >
      <div className={styles.body}>
        <InputWrapper
          id={'closeOrderForPlaceBarcode'}
          handleChange={onChangeInput}
          autoFocus
          label={'Отсканируйте ШК товара или тары чтобы удалить товар'}
          className={styles.cancelOrderForPlaceInput}
          placeholder={''}
          withDelay
        />

        <span>
          <Button
            label={'Закрыть'}
            view={'ghost'}
            onClick={onClickCancel}
            size={'m'}
            width={'default'}
            loading={loading}
          />
        </span>
      </div>
    </Modal>
  )
}

export default ConfirmDeletionModal
