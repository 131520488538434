import { IRefund } from 'src/interfaces/refund/refund'
import { IReturnFormDataState } from '../interfaces/return'
import { IGetReturnByIdResult } from '@/hooks/refundAcceptance/useGetReturnById'

export const SET_RETURN_RAW = 'SET_RETURN_RAW'
export const setReturnRawAction = (documentRaw: IGetReturnByIdResult | null) => {
  return {
    type: SET_RETURN_RAW,
    payload: documentRaw,
  }
}

export const SET_RETURN_FORM_STATE_DRAFT = 'SET_RETURN_FORM_STATE_DRAFT'
export const setReturnFormDraftAction = (formState: IReturnFormDataState) => {
  return {
    type: SET_RETURN_FORM_STATE_DRAFT,
    payload: formState,
  }
}

export const SET_RETURN_FORM_STATE_RAW = 'SET_RETURN_FORM_STATE_RAW'
export const setReturnFormRawAction = (formState: IReturnFormDataState) => {
  return {
    type: SET_RETURN_FORM_STATE_RAW,
    payload: formState,
  }
}

export const SET_RETURN_LIST = 'SET_RETURN_LIST'
export const setReturnListAction = (list: IRefund[]) => {
  return {
    type: SET_RETURN_LIST,
    payload: list,
  }
}

