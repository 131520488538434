import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

interface Result extends ApiReqResult {
  id: string
}
interface IData {
  comment: string
  date: string
}
interface useUpdateCarrierParams {
  docId?: string | null
  data: IData
}
//http://wms-test.kak2c.ru/warehouse-api/transportation/{doc_id}
const useUpdateCarryer = () => {
  return useMutation(async (params: useUpdateCarrierParams) => {
    const path = `/transportation${params.docId ? `/${params.docId}` : ''}`

    const result = await api.post(path, params.data)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}
export default useUpdateCarryer
