import styles from './ConfirmModal.module.scss'
import { Button } from '@consta/uikit/Button'
import React from 'react'

import { EnterHandler, Modal } from 'src/components'
import { ModalProps } from 'src/interfaces'
import warningImg from '@shared/assets/images/orangeTiangle.svg'

interface IProps extends ModalProps {
  onSubmit: () => void
}

const ConfirmModal = ({ isOpen, onClose, onSubmit }: IProps) => {
  return (
    <EnterHandler onEnter={onClose}>
      <Modal
        isOpen={isOpen}
        onOverlayClick={(): boolean => false}
        className={styles.modal}
        img={warningImg}
        imgClass={styles.img}
        title={'Удаление документа'}
        subtitle={'Вы действительно хотите удалить документ?'}
        size="min"
      >
        <div className={styles.btns}>
          <Button label={'Отмена'} view={'secondary'} onClick={onClose} />
          <Button label={'Удалить'} view={'ghost'} onClick={onSubmit} />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default ConfirmModal
