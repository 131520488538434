import React, { useEffect, useState } from 'react'
import { useGetServiceDocByParent, useGetServicesList, useGetSupplyById, useUpdateServiceDoc } from 'src/hooks'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { IAcceptanceSku, ISupply, SupplyStatusEnum, SupplyTypEnum } from 'src/interfaces'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'
import SupplyFormContent from './components/SupplyContent'
import { formStatesAreEqual } from '../../../store/utils'
import { SupplyFormContextProvider, useMainFormContext } from '../../../context/SupplyFormContext'
import {
  revertDraftStateToRawAction,
  setAllServicesMaterialAction,
  setFormStateDraftAction,
  setFormStateRawAction,
  setOriginalSkusAction,
  setServicesDocumentsAction,
  setSupplyRawAction,
} from '../../../store/actions'
import { getOperationsAndMaterials } from './components/ServicesForm/utils'
import { ISupplyFormDataState } from '../../../store/interfaces'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { convertServicesData, convertSupplyData } from './utils'
import useUpdateSupply, { IUpdateSupplyData } from '@/hooks/receiving/useUpdateSupply'
import { useNotifications } from '@shared/providers/NotificationProvider'
import ServicesModal from './components/ServicesModal'
import { FormWrapper } from '../../../components'
import { PATHS } from '@shared/routing'
import { Badge } from '@consta/uikit/Badge'

const SupplyForm = () => {
  const { state, dispatch } = useMainFormContext()

  const notification = useNotifications()
  const history = useHistory()

  const [paramModalVisible, setParamModalVisible] = useState<boolean>(false)

  const { id: supplyId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()
  const {} = useGetServicesList({
    params: { is_material: true },
    options: {
      onSuccess: (data) => {
        if (data?.success) {
          dispatch(setAllServicesMaterialAction(data.services))
        }
      },
    },
  })

  const { data: SupplyData, isFetching: isSupplyLoading } = useGetSupplyById(
    { supplyId },
    (supply: ISupply, skus: IAcceptanceSku[]) => {
      /** Задаем "чистую" поставку */
      dispatch(setSupplyRawAction(supply))
      /** Задаем оригинальные товары */
      dispatch(setOriginalSkusAction(skus))
    },
  )

  const { mutateAsync: updateServiceDoc, isLoading: updateServiceDocLoading } =
    useUpdateServiceDoc()
  const { mutateAsync: updateSupply, isLoading: updateSupplyLoading } =
    useUpdateSupply({ supplyId })

  const { data: ServiceDocData } = useGetServiceDocByParent({
    params: { parent_id: supplyId },
  })

  useEffect(() => {
    /** Добавление начального стейта */
    const {
      operations,
      materials,
      operationsInputService,
      materialsInputService,
      operationsPreCalcItems,
      materialsPreCalcItems,
    } = getOperationsAndMaterials(
      ServiceDocData?.services || [],
      ServiceDocData?.pre_calc_items || [],
      ServiceDocData?.documents || [],
    )
    const formData: ISupplyFormDataState = {
      status: SupplyData?.supply?.status || '',
      comment: SupplyData?.supply?.warehouse_comment || '',
      materialsServices: materials,
      operationsServices: operations,
      initialMaterialsPreCalcItems: materialsPreCalcItems,
      initialOperationsPreCalcItems: operationsPreCalcItems,
      materialsInputService,
      operationsInputService,
    }

    dispatch(setServicesDocumentsAction(ServiceDocData?.documents || []))
    dispatch(setFormStateRawAction(cloneDeep(formData)))
    dispatch(setFormStateDraftAction(cloneDeep(formData)))
  }, [SupplyData, ServiceDocData])

  const handleSaveSupplyData = async (supplyData: IUpdateSupplyData | null) => {
    if (!supplyData) {
      console.error('Нечего обновлять в поставке, нет supplyData')
      notification?.show('alert', 'Ошибка сохранения поставки')
      return
    }
    await updateSupply(supplyData)
      .then(() => {
        notification?.show('success', 'Поставка успешно сохранена', {
          soundName: 'PACKING_COMPLETED',
        })

        setParamModalVisible(false)
      })
      .catch(() => {})
  }

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.suppliesList,
      }),
    )
  }
  const handleClickSave = async (saveFromParamsModal?: boolean) => {
    const draftStatus = state.formStateDraft?.status
    const rawStatus = state.formStateRaw?.status

    const isStatusChanged = draftStatus !== rawStatus
    const isNewStatusPlaced = draftStatus === SupplyStatusEnum.placed
    if (isStatusChanged && isNewStatusPlaced && !saveFromParamsModal) {
      setParamModalVisible(true)
      return
    }

    const supplyData = convertSupplyData(state)
    const servicesData = convertServicesData(supplyId, state)

    const itemsIsEmpty = !servicesData.items.length

    if (itemsIsEmpty) {
      await handleSaveSupplyData(supplyData)
      return
    }

    await updateServiceDoc(servicesData)
      .then(async ({ success, id }) => {
        if (!success) {
          return
        }
        notification?.show('success', 'Доп параметры успешно сохранены', {
          soundName: 'PACKING_ITEM_ADDED_TO_PLACE',
        })
        if (id && !servicesData.doc_id) {
          dispatch(setServicesDocumentsAction([{ id: id }]))
        }
        await handleSaveSupplyData(supplyData)
      })
      .catch(() => {})
  }

  const statesAreEqual = formStatesAreEqual<ISupplyFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )


  return (
    <>
      <FormWrapper
        loading={isSupplyLoading}
        title={state.supplyRaw?.id || supplyId}
        subtitle={
          state.supplyRaw
            ? `от ${days(state.supplyRaw.create_ts).format(DATE_TIME_VIEW)}`
            : ' '
        }
        extraHead={
          state.supplyRaw?.supply_type === SupplyTypEnum.FROM_SUPPLIER_WITH_DRIVER
            ?
            <Badge
              size={'s'}
              status={'error'}
              view={'filled'}
              form={'round'}
              label={'Срочная приемка'}
            />
            : null
        }
        cancelBtnDisabled={statesAreEqual}
        handleClickCancel={handleClickCancel}
        handleClickSave={() => handleClickSave(false)}
        saveBtnLoading={updateServiceDocLoading || updateSupplyLoading}
      >
        <SupplyFormContent />
      </FormWrapper>
      {paramModalVisible ? (
        <ServicesModal
          isOpen={true}
          onClose={() => {
            setParamModalVisible(false)
          }}
          handleClickSave={() => handleClickSave(true)}
          saveBtnLoading={updateServiceDocLoading || updateSupplyLoading}
        />
      ) : null}
    </>
  )
}

const SupplyFormWithContext = () => (
  <SupplyFormContextProvider>
    <SupplyForm />
  </SupplyFormContextProvider>
)

export default SupplyFormWithContext
