import { useRefsSwitcher } from '@shared/hooks'
import { useCallback, useEffect } from 'react'
import isMobile from 'ismobilejs'

type Props = {
  isActive?: boolean
  withEnterClickBtn: boolean, // Обработка нажатия на энтер как на клик кнопки
  refsKeys: readonly string[]
}

export const useArrowSwitch = (props: Props) => {
  const {
    isActive = true,
    withEnterClickBtn,
    refsKeys,
  } = props

  const {
    currentRef,
    refsObj,
    next,
    prev,
  } = useRefsSwitcher({ refsKeys, withLoopIndex: true })

  const keyHandler = useCallback((event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      next()
    }
    if (event.key === 'ArrowRight') {
      prev()
    }
    if (event.key === 'Enter' && withEnterClickBtn) {
      currentRef?.current?.click()
    }
  }, [currentRef, next, prev, withEnterClickBtn])

  useEffect(() => {
    if (isMobile()?.any || !isActive) return

    window.removeEventListener('keyup', keyHandler, false)
    window.addEventListener('keyup', keyHandler, false)

    return () => {
      window.removeEventListener('keyup', keyHandler, false)
    }
  }, [isActive])

  return {
    refsObj,
  }
}