import React, { useEffect } from 'react'
import cx from 'classnames'
import styles from './comment.module.scss'
import { InputWrapper } from 'src/components'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { useDebounce } from '@shared/hooks'

interface CommentProps {
  stateCommentValue: TextFieldPropValue
  handleChangeComment: (value: TextFieldPropValue) => void
}

const Comment = (props: CommentProps) => {
  const { handleChangeComment, stateCommentValue } = props

  const [commentDebounced, comment, setComment] = useDebounce<
    TextFieldPropValue | ''
  >('')

  useEffect(() => {
    /** Сбрасываем комментарий */
    if (!stateCommentValue) {
      return
    }
    setComment(stateCommentValue)
  }, [stateCommentValue])

  useEffect(() => {
    /** Обновляем стейт формы */
    handleChangeComment(commentDebounced)
  }, [commentDebounced])

  return (
    <div className={cx(styles.main)}>
      <InputWrapper
        autoFocus
        type={'textarea'}
        id={'comment'}
        value={comment}
        minRows={10}
        maxRows={26}
        className={styles.input}
        placeholder={''}
        handleChange={setComment}
      />
    </div>
  )
}
export default Comment
