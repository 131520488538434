import { IShipmentPlanOrder, ITableColumn, ITableRow } from 'src/interfaces'
import { DateCell } from 'src/components'
import React from 'react'

export const generateOrderColumns = (): ITableColumn[] => {
  return [
    {
      key: 'date',
      title: 'Дата',
      title_txt: 'Дата',
      gridCellWidth: '150px',
      renderCell: ({ row }: any) => {
        return <DateCell date={row.date} />
      },
    },
    {
      key: 'contractNum',
      title: 'Номер заказа',
      title_txt: 'Номер заказа',
    },
    {
      key: 'merchant',
      title: 'Организация',
      title_txt: 'Организация',
    },
  ]
}

export const generateOrderRows = (planItems: IShipmentPlanOrder[]): ITableRow[] => {
  return planItems.map((item) => {

    return {
      id: item.id,
      contractNum: item.merchant?.contract_num,
      merchant: item.merchant?.title,
      date: item.merchant?.contract_date,
    }
  })
}
