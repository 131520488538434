import React, { useEffect } from 'react'
import { useGetTask } from 'src/hooks'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import { formStatesAreEqual } from '../../../store/utils'
import {
  revertDraftStateToRawAction,
  setCompletingFormDraftAction,
  setCompletingFormRawAction,
  setCompletingRawAction,
} from '../../../store/actions'
import days from 'dayjs'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'
import { FormWrapper } from '../../../components'
import {
  CompletingFormContextProvider,
  useCompletingFormContext,
} from '@pages/OperationsAndDocumentsPage/context/TaskOfCompletingFormContext'
import { PATHS } from '@shared/routing'
import { ICompletingFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces'
import { TaskOfCompletingFormContent } from './components'

export const TaskOfCompletingForm = () => {
  const history = useHistory()

  const { state, dispatch } = useCompletingFormContext()
  const { id: completingId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()

  const { data: completingData, isFetching: completingLoading, refetch } = useGetTask(
    { completingId: completingId },
    {
      onSuccess: ({ order }) => {
        dispatch(setCompletingRawAction(order))
      },
    },
  )

  useEffect(() => {
    const formData: ICompletingFormDataState = {
      status: completingData?.order.status || '',
      comment: completingData?.order.warehouse_comment || '',
    }
    dispatch(setCompletingFormDraftAction(cloneDeep(formData)))
    dispatch(setCompletingFormRawAction(cloneDeep(formData)))
  }, [completingData])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.taskOfCompletingList,
      }),
    )
  }

  const statesAreEqual = formStatesAreEqual<ICompletingFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )

  return (
    <FormWrapper
      loading={completingLoading}
      title={state.documentRaw?.id || completingId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <TaskOfCompletingFormContent refetch={refetch}/>
    </FormWrapper>
  )
}
const CompletingFormWithContext = () => (
  <CompletingFormContextProvider>
    <TaskOfCompletingForm />
  </CompletingFormContextProvider>
)
export default CompletingFormWithContext
