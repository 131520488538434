import React from 'react'

import styles from './SelectModal.module.scss'

import { Button } from '@consta/uikit/Button'

import { Modal, SelectList } from 'src/components'

import { ISelectListOption, ModalProps } from 'src/interfaces'
import isMobile from 'ismobilejs'

interface ISelectModal extends ModalProps {
  title?: string
  subtitle?: string
  selectLabel?: string
  options: ISelectListOption[]
  selectedItem: ISelectListOption | null
  setSelectedItem: (val: ISelectListOption | null) => void
  formTopSlot?: React.ReactNode
  formBottomSlot?: React.ReactNode
  onSubmit: () => void
}

const SelectModal = (props: ISelectModal) => {
  const {
    title,
    subtitle,
    selectLabel = 'Выберите из списка',
    isOpen,
    onSubmit,
    selectedItem,
    setSelectedItem,
    options,
    formTopSlot,
    formBottomSlot,
    onClose
  } = props

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      withClose
      onClose={onClose}
      title={title}
      subtitle={subtitle}
    >
      {formTopSlot}
      <div className={styles.body}>
        <h3>{selectLabel}</h3>
        <SelectList
          height={isMobile().any ? 200 : undefined}
          options={options}
          value={selectedItem}
          onChange={(value) => setSelectedItem(value)}
          className={styles.select}
        />
      </div>
      {formBottomSlot}
      <div className={styles.btns}>
        <Button
          label="Выбрать"
          className={styles.btn}
          disabled={!selectedItem}
          onClick={onSubmit}
        />
      </div>
    </Modal>
  )
}

export default SelectModal
