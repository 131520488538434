import React, { FC } from 'react'
import { NomenclatureNameCell } from 'src/components'

interface TitleCellProps {
  className?: string
  title: string
  article: string
}

const TitleCell: FC<TitleCellProps> = (props) => {
  return (
    <NomenclatureNameCell {...props}/>
  )
}

TitleCell.displayName = 'TitleCell'

export default TitleCell
