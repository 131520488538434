import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { FormTabs, generateTabs } from '../../../../../helpers/documentTabs'
import { OperAndDocumPagesType } from '../../../../../pagesType'
import { Comment, HistoryChanges, RelativeDocs } from '../../../../../components/CommonTabsPages'
import { updateFormStateDraftAction } from '../../../../../store/actions'
import { TabsContentWrapper } from '../../../../../components'
import { DocumentsParamsFields } from '..'
import { useBatchFormContext } from '../../../../../context/BatchFormContext'
import { DocumentTypeEnum } from '@/interfaces'

interface BatchFormContentProps {
  loading?: boolean
}

const ReturnPlaceFormContent = (props: BatchFormContentProps) => {
  const { page, id, tabId } = useParams<{ page: OperAndDocumPagesType, id: string, tabId: string }>()
  const [docId] = id.split('*')

  const supplyTabsWithDescription = useMemo(() => generateTabs([
    FormTabs.documentsParams,
    FormTabs.relativeDocs,
    FormTabs.comments,
    FormTabs.historyChanges
  ]), [page])

  return (
    <TabsContentWrapper
      tabs={supplyTabsWithDescription}
    >
      <TabPage
        tabId={tabId}
        documentId={docId}
      />
    </TabsContentWrapper>
  )
}

interface ITabContent {
  tabId: string
  documentId: string
}

const TabPage = React.memo(({ documentId, tabId }: ITabContent) => {
  const { state: { documentRaw, formStateDraft }, dispatch } = useBatchFormContext()

  switch (tabId) {
    case FormTabs.documentsParams:
      return <DocumentsParamsFields />
    case FormTabs.relativeDocs:
      return <RelativeDocs documentId={documentId} documentType={DocumentTypeEnum.RETURN_PLACE}/>
    case FormTabs.comments:
      return (
        <Comment
          stateCommentValue={formStateDraft?.comment || ''}
          handleChangeComment={(value) =>
            dispatch(updateFormStateDraftAction('comment', value))}
        />
      )
    case FormTabs.historyChanges:
      return <HistoryChanges documentUUID={documentRaw?.uuid} />
  }
  return null
})

export default ReturnPlaceFormContent
