import React, { FC, useMemo } from 'react'
import styles from './history-changes-table.module.scss'
import { TableWithCopy } from 'src/components'
import { generateColumns, generateRows } from './utils'
import { IHistoryChanges } from '@/interfaces'
import { convertHistoryChangesToChangesList } from '@shared/helpers'


interface HistoryChangesTableProps {
  isLoading?: boolean
  changes?: IHistoryChanges[]
}

const HistoryChangesTable: FC<HistoryChangesTableProps> = (props) => {
  const {
    isLoading,
    changes=[]
  } = props

  const convertedHistoryChanges = useMemo(
    () => convertHistoryChangesToChangesList(changes),
    [changes]
  )

  const rows = generateRows(convertedHistoryChanges)
  const columns = generateColumns()
  return (
    <div className={styles.main}>
      <TableWithCopy
        rows={rows}
        columns={columns}
        titleClassName={styles.tableTitle}
        tableWrapperClassName={styles.tableWrap}
        maxHeightBody={620}
        isLoading={isLoading}
      />
    </div>
  )
}

export default HistoryChangesTable
