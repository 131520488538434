import React, { useEffect, useMemo } from 'react'

import { useGetMerchantList, useGetQueryPickings } from 'src/hooks'
import { generateColumns, generateFilters, generateRows } from './helpers'
import { DocumentsListWrapper } from '../../../components'
import { generateFilterParams } from '@pages/OperationsAndDocumentsPage/helpers/filters'
import { useFilter } from '../../../hooks/useFilter'


const PickingList = () => {
  const { filterState, setFilterState } = useFilter()

  const { data: merchantData } = useGetMerchantList({})
  const {
    isFetching: pickingsLoading,
    data: pickingsData,
    refetch: refetchPickings,
  } = useGetQueryPickings(generateFilterParams(filterState))

  useEffect(() => {
    refetchPickings()
  }, [filterState])

  const rows = useMemo(() => generateRows(pickingsData?.pickings || []), [pickingsData?.pickings])
  const columns = useMemo(() => generateColumns(), [])
  const filtersConfig = generateFilters(merchantData?.merchants || [])

  return (
    <DocumentsListWrapper
      title={'Отбор'}
      rows={rows}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      filtersConfig={filtersConfig}
      loading={pickingsLoading}
      pagination={pickingsData?.pagination}
    />
  )
}

export default PickingList
