import React from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { PATHS } from '@shared/routing'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'

import { formStatesAreEqual } from '../../../store/utils'
import { revertDraftStateToRawAction } from '../../../store/actions'
import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'

import { FormWrapper } from '../../../components'
import { ShipmentPlanFormContent } from './components'
import { IBatchFormDataState } from '@pages/OperationsAndDocumentsPage/store/interfaces'

import {
  setShipmentPlanFormDraftAction,
  setShipmentPlanFormRawAction,
  setShipmentPlanRawAction,
} from '@pages/OperationsAndDocumentsPage/store/actions/shipmentPlan'

import { ShipmentPlanFormContextProvider, useShipmentPlanFormContext } from '../../../context/ShipmentPlanFormContext'
import { IOrderFormDataState } from '../../../store/interfaces/order'
import { useGetShipmentPlan } from 'src/hooks'


const ShipmentPlanForm = () => {
  const history = useHistory()
  const { state, dispatch } = useShipmentPlanFormContext()

  const { id: documentId } = useParams<{ page: OperAndDocumPagesType, id: string }>()

  const { isLoading: isDocumentLoading } = useGetShipmentPlan(
    {docId: documentId},
    {
      onSuccess: (data) => {
        if (!data.success) { return }
        dispatch(setShipmentPlanRawAction(data.shipment_plan))

        const formData: IBatchFormDataState = { status: '', comment: '' }
        dispatch(setShipmentPlanFormDraftAction(cloneDeep(formData)))
        dispatch(setShipmentPlanFormRawAction(cloneDeep(formData)))
      }
  })

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
      page: OperAndDocumPages.shipmentPlanList,
    }))
  }


  const statesAreEqual = formStatesAreEqual<IOrderFormDataState | null>(state.formStateDraft, state.formStateRaw)
  return (
    <FormWrapper
      loading={isDocumentLoading}
      title={state.documentRaw?.id || documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <ShipmentPlanFormContent />
    </FormWrapper>
  )
}

const OrderFormWithContext = () => (
  <ShipmentPlanFormContextProvider>
    <ShipmentPlanForm />
  </ShipmentPlanFormContextProvider>
)

export default OrderFormWithContext
