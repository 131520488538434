import React from 'react'

import styles from './open-tare-error-modal.module.scss'

import warningImg from '@shared/assets/images/orangeTiangle.svg'

import { Button } from '@consta/uikit/Button'

import { EnterHandler, Modal } from '@/components'

import { ModalProps } from '@/interfaces'

interface IDeleteTareConfirmModalProps extends ModalProps {
  handleConfirm: () => void
}

const DeleteTareConfirmModal = (props: IDeleteTareConfirmModalProps) => {
  const { isOpen, onClose, handleConfirm } = props

  return (
    <EnterHandler onEnter={handleConfirm}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.error}
        title="Вы уверены, что хотите удалить тару?"
        subtitle="При подтверждении операции все товары в таре будут удалены."
        img={warningImg}
        imgClass={styles.img}
      >
        <div className={styles.btns}>
          <Button
            label="Подтвердить"
            view="primary"
            className={styles.primary}
            onClick={handleConfirm}
            size="l"
          />
          <Button
            label="Отменить"
            view="ghost"
            onClick={() => onClose()}
            size="l"
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default DeleteTareConfirmModal
