import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import styles from './exp-date-modal.module.scss'

import { Text } from '@shared/ui/Text'
import { Button } from '@consta/uikit/Button'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup'

import { EnterHandler, InputWithDateMask, ItemPreview, Modal } from 'src/components'

import { useNotifications } from '@shared/providers/NotificationProvider'

import { ModalProps, TypesEnum } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { DATE_FORMAT, DATE_FORMAT_VIEW, DATE_TIME_VIEW } from '@shared/const/date'


const dateTypes: { label: string; value: TypesEnum }[] = [
  {
    label: 'дата изготовления',
    value: TypesEnum.dateOfManufacture,
  },
  {
    label: 'дата - годен до',
    value: TypesEnum.expirationDate,
  },
]


interface ExpDateItem {
  id: string | number
  image: string
  title: string
  barcode?: string
  expiryMonths?: number
  expiryDaysLimitShipments?: number
  expirationDate?: string,
  dateOfManufacture?: string
}

interface ItemCountProps extends ModalProps {
  item: ExpDateItem | null
  onDateExpAdding: (newItems: ExpDateItem[]) => void
  newItems: ExpDateItem[]
  externalError?: string
}

const ExpDateModal = (props: ItemCountProps) => {
  const { item, externalError, onDateExpAdding,onClose, newItems } = props

  const [date, setDate] = useState<TextFieldPropValue>('')
  const [type, setType] = useState<TypesEnum>(TypesEnum.dateOfManufacture)
  const [error, setError] = useState<string>('')
  const [curItems, setCurItems] = useState<ExpDateItem[]>([])
  const [curItemIndex, setCurItemIndex] = useState<number>(0)

  const notification = useNotifications()

  const isExpirationDate = type === TypesEnum.expirationDate
  const isDateOfManufacture = type === TypesEnum.dateOfManufacture


  useEffect(() => {
    setError(externalError || '')
  }, [externalError])


  const checkDate = (date: string): boolean => {
    if (!dayjs(date, DATE_FORMAT_VIEW).isValid()) {
      setError('Некорректная дата')
      return false
    }

    const now = dayjs(dayjs(), DATE_FORMAT_VIEW)

    if (
      isExpirationDate &&
      (dayjs(date, DATE_FORMAT_VIEW).isBefore(now) ||
        dayjs(date, DATE_FORMAT_VIEW).isSame(now, 'day'))
    ) {
      setError('Дата срока годности должна быть позже текущей даты')

      notification?.show(
        'alert',
        'дата - годен до должна быть позже текущей даты',
      )

      return false
    }

    if (
      isExpirationDate &&
      (dayjs(date, DATE_FORMAT_VIEW)
        .subtract(item?.expiryMonths || 999, 'month')
        .isAfter(now) ||
        dayjs(date, DATE_FORMAT_VIEW).isSame(now, 'day'))
    ) {
      const errorText = `Некорректно введена ${date} или сроки годности ${
        item?.expiryMonths || 999
      } мес. в номенклатуре товара`
      setError(errorText)
      notification?.show('alert', errorText)
      return false
    }

    if (
      isDateOfManufacture &&
      dayjs(date, DATE_FORMAT_VIEW).isAfter(now)
    ) {
      setError('Дата изготовления должна быть раньше текущей даты')

      notification?.show(
        'alert',
        'Дата изготовления должна быть раньше текущей даты',
      )

      return false
    }

    const expirationDate =
      isExpirationDate
        ? dayjs(date, DATE_FORMAT_VIEW)
        : dayjs(date, DATE_FORMAT_VIEW)
            .add(item?.expiryMonths || 999, 'month')
            .format(DATE_FORMAT_VIEW)
    const nowWithLimitDays = now.add(item?.expiryDaysLimitShipments ?? 0, 'day')
    const isAfterLimits = dayjs(nowWithLimitDays, DATE_FORMAT_VIEW).isAfter(
      dayjs(expirationDate, DATE_FORMAT_VIEW),
      'day',
    )
    if (isAfterLimits) {
      setError('Превышены лимиты макс. допустимых сроков годности')
      notification?.show(
        'alert',
        'Превышены лимиты макс. допустимых сроков годности',
      )
      return false
    }
    return true
  }

  const handleAddExpDate = () => {
    if (!item || !date) return
    if (!checkDate(date)) return

    const expirationDate =
      isExpirationDate
        ? dayjs(date, DATE_FORMAT_VIEW).format(DATE_FORMAT)
        : dayjs(date, DATE_FORMAT_VIEW)
            .add(item.expiryMonths || 999, 'month')
            .format(DATE_FORMAT)
    const dateOfManufacture =
      isDateOfManufacture
        ? dayjs(date, DATE_FORMAT_VIEW).format(DATE_FORMAT)
        : dayjs(date, DATE_FORMAT_VIEW)
            .subtract(item.expiryMonths || 999, 'month')
            .format(DATE_FORMAT)

    const newCurItems = [
      ...curItems,
      { ...item, expirationDate, dateOfManufacture },
    ]

    if (curItemIndex === newItems.length - 1) {
      onDateExpAdding(newCurItems)
    }

    setCurItems(newCurItems)
    setCurItemIndex((prev) => prev + 1)

    setError('')
    setDate('')
  }

  const handleChange = (value: TextFieldPropValue) => {
    setError('')
    setDate(value)
  }

  return (
    <>
      {newItems.map((newItem, i) => (
        <React.Fragment key={newItem.id}>
          {curItemIndex === i ? (
            <EnterHandler onEnter={handleAddExpDate}>
              <Modal
                isOpen={true}
                hasOverlay
                withClose
                onClose={onClose}
                className={styles.expDate}
                size="s"
              >
                {item ? (
                  <ItemPreview
                    image={item.image}
                    title={item.title}
                    barcode={item.barcode || ''}
                  />
                ) : null}

                <ChoiceGroup
                  className={styles.choiceGroup}
                  value={dateTypes.find((t) => t.value === type) ?? null}
                  items={dateTypes}
                  getItemLabel={(item) => item.label}
                  onChange={({ value }) => setType(value.value)}
                  name={'dateTypes'}
                  multiple={false}
                  width={'full'}
                />

                {newItems.length > 1 ? (
                  <div className={styles.itemsCount}>
                    {i + 1} из {newItems.length}
                  </div>
                ) : null}
                <div>
                  <div className={styles.input} key={`newItem-${i}`}>
                    <InputWithDateMask
                      label={
                        isExpirationDate
                          ? 'Годен до'
                          : 'Введите дату изготовления'
                      }
                      value={date}
                      id={'date'}
                      handleChange={(value: TextFieldPropValue) =>
                        handleChange(value)
                      }
                      className={styles.itemInput}
                      size="l"
                      placeholder={DATE_TIME_VIEW}
                      rightSide={IconCalendar}
                      autofocus
                      form="defaultClear"
                    />
                    <Button
                      form="brickDefault"
                      size="l"
                      label="Подтвердить"
                      className={styles.btn}
                      disabled={!date || !!error}
                      onClick={handleAddExpDate}
                    />
                  </div>
                  <Text view={'alert'} size={'xs'}>{error}</Text>
                </div>
              </Modal>
            </EnterHandler>
          ) : null}
        </React.Fragment>
      ))}
    </>
  )
}

export default ExpDateModal
