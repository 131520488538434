import {
  ConditionEnum,
  IAcceptanceSku,
  IAcceptanceSkuObj,
  IDefectType,
  ISelectOption,
  ISticker,
  ISupply,
  ISupplyItem,
  ISupplyReceipt,
  ISupplyReceiptItem,
  PrintTypeEnum,
} from '@/interfaces'
import { IPrintWidgetItem } from '../../../../../components/widgets/PrintWidget'

export const SET_SUPPLY_RAW = 'SET_SUPPLY_RAW'
export const setSupplyRawAction = (supplyRaw: ISupply | null) => {
  return {
    type: SET_SUPPLY_RAW,
    payload: supplyRaw,
  }
}

export const SET_SUPPLY_CONDITION_TYPE = 'SET_SUPPLY_CONDITION_TYPE'
export const setSupplyConditionTypeAction = (condition: ConditionEnum | null) => {
  return {
    type: SET_SUPPLY_CONDITION_TYPE,
    payload: condition,
  }
}
export const SET_SHOW_CONFIRM_COMPLETE_MODAL = 'SET_SHOW_CONFIRM_COMPLETE_MODAL'
export const setSupplyConfirmCompleteAction = (status: boolean) => {
  return {
    type: SET_SHOW_CONFIRM_COMPLETE_MODAL,
    payload: status,
  }
}

export const SET_SHOW_SELECT_CONDITION_MODAL = 'SET_SHOW_SELECT_CONDITION_MODAL'
export const setShowSelectConditionModalAction = (data: { status: boolean, callback?: (condition: ConditionEnum) => void }) => {
  return {
    type: SET_SHOW_SELECT_CONDITION_MODAL,
    payload: data,
  }
}

export const SET_USERNAME = 'SET_USERNAME'
export const setUsernameAction = (username: string) => {
  return {
    type: SET_USERNAME,
    payload: username,
  }
}

export const SET_INITIAL_SUPPLY_ITEMS = 'SET_INITIAL_SUPPLY_ITEMS'
export const setInitialSupplyItemsAction = (supplyItems: ISupplyItem[]) => {
  return {
    type: SET_INITIAL_SUPPLY_ITEMS,
    payload: supplyItems,
  }
}

export const SET_SUPPLY_ITEMS = 'SET_SUPPLY_ITEMS'
export const setSupplyItemsAction = (supplyItems: ISupplyItem[]) => {
  return {
    type: SET_SUPPLY_ITEMS,
    payload: supplyItems,
  }
}

export const SET_ORIGINAL_SKUS = 'SET_ORIGINAL_SKUS'
export const setOriginalSkusAction = (skus: IAcceptanceSku[]) => {
  return {
    type: SET_ORIGINAL_SKUS,
    payload: skus,
  }
}
export const SET_ORIGINAL_SKUS_OBJ = 'SET_ORIGINAL_SKUS_OBJ'
export const setOriginalSkusObjAction = (skus: IAcceptanceSkuObj) => {
  return {
    type: SET_ORIGINAL_SKUS_OBJ,
    payload: skus,
  }
}
export const SET_TARES = 'SET_TARES'
export const setTaresAction = (tares: ISupplyReceipt[]) => {
  return {
    type: SET_TARES,
    payload: tares,
  }
}
export const ADD_ITEM_TO_TARE = 'ADD_ITEM_TO_TARE'
export const addItemToTareAction = (
  tareId: string,
  item: ISupplyReceiptItem,
) => {
  return {
    type: ADD_ITEM_TO_TARE,
    payload: { tareId, item },
  }
}
export const DELETE_ITEM_FROM_TARE = 'DELETE_ITEM_FROM_TARE'
export const deleteItemFromTareAction = (
  tareId: string,
  itemId: string,
  quantity: number,
) => {
  return {
    type: DELETE_ITEM_FROM_TARE,
    payload: { tareId, itemId, quantity },
  }
}
export const CLOSE_TARE = 'CLOSE_TARE'
export const closeTareAction = (tareId: string) => {
  return {
    type: CLOSE_TARE,
    payload: tareId,
  }
}
export const DELETE_TARE = 'DELETE_TARE'
export const deleteTareAction = (deleteTareId: string) => {
  return {
    type: DELETE_TARE,
    payload: deleteTareId,
  }
}
export const UPDATE_TARE = 'UPDATE_TARE'
export const updateTareAction = (tare: ISupplyReceipt) => {
  return {
    type: UPDATE_TARE,
    payload: tare,
  }
}
export const SET_REMOVING_ITEM_BARCODE = 'SET_REMOVING_ITEM_BARCODE'
export const setRemovingItemBarcodeAction = (supplyItem: string | null) => {
  return {
    type: SET_REMOVING_ITEM_BARCODE,
    payload: supplyItem,
  }
}
export const SET_ADDING_SUPPLY_ITEM = 'SET_ADDING_SUPPLY_ITEM'
export const setAddingSkuItemAction = (supplyItem: IAcceptanceSku | null) => {
  return {
    type: SET_ADDING_SUPPLY_ITEM,
    payload: supplyItem,
  }
}
export const SET_OPEN_TARE_ID = 'SET_OPEN_TARE_ID'
export const setOpenTareIdAction = (tareId: string | null) => {
  return {
    type: SET_OPEN_TARE_ID,
    payload: tareId,
  }
}
export const OPEN_TARE = 'OPEN_TARE'
export const setOpenTareAction = (tareId: string) => {
  return {
    type: OPEN_TARE,
    payload: tareId,
  }
}
export const SET_WEIGHT_AND_DIMENSION = 'SET_WEIGHT_AND_DIMENSION'
export const setSavedItemsWeightAndDimensions = (itemId: string) => {
  return {
    type: SET_WEIGHT_AND_DIMENSION,
    payload: itemId,
  }
}
export const SET_INDICATE_ITEM_COUNT = 'SET_INDICATE_ITEM_COUNT'
export const setIndicateItemCount = (indicateCount: boolean) => {
  return {
    type: SET_INDICATE_ITEM_COUNT,
    payload: indicateCount,
  }
}
export const SET_USED_SERIAL_NUMBERS = 'SET_USED_SERIAL_NUMBERS'
export const setUsedSerialNumbers = (serialNumbers: string[]) => {
  return {
    type: SET_USED_SERIAL_NUMBERS,
    payload: serialNumbers,
  }
}
export const SET_STICKER = 'SET_STICKER'
export const setStickerAction = (sticker: ISticker | null) => {
  return {
    type: SET_STICKER,
    payload: sticker,
  }
}

export const SHOW_DISCREPANCIES_MODAL = 'SHOW_DISCREPANCIES_MODAL'
export const showDiscrepanciesModalAction = (show: boolean) => {
  return {
    type: SHOW_DISCREPANCIES_MODAL,
    payload: show,
  }
}

export const SHOW_SUPPLY_FORM_MODAL = 'SHOW_SUPPLY_FORM_MODAL'
export const showSupplyFormModalAction = (show: boolean) => {
  return {
    type: SHOW_SUPPLY_FORM_MODAL,
    payload: show,
  }
}

export const SHOW_TSD_UNPROCESS_MODAL = 'SHOW_TSD_UNPROCESS_MODAL'
export const showTSDUnProcessModalAction = (show: boolean) => {
  return {
    type: SHOW_TSD_UNPROCESS_MODAL,
    payload: show,
  }
}
export const SHOW_SETTINGS_DRAWER_MODAL = 'SHOW_SETTINGS_DRAWER_MODAL'
export const showSettingsDrawerAction = (show: boolean) => {
  return {
    type: SHOW_SETTINGS_DRAWER_MODAL,
    payload: show,
  }
}
export const SHOW_INFO_DRAWER_MODAL = 'SHOW_INFO_DRAWER_MODAL'
export const showInfoDrawerAction = (show: boolean) => {
  return {
    type: SHOW_INFO_DRAWER_MODAL,
    payload: show,
  }
}
export const SHOW_SELECT_BATCH_MODAL = 'SHOW_SELECT_BATCH_MODAL'
export const showSelectBatchModalAction = (show: boolean) => {
  return {
    type: SHOW_SELECT_BATCH_MODAL,
    payload: show,
  }
}
export const SHOW_DELETE_CONFIRM_MODAL = 'SHOW_DELETE_TARE_CONFIRM_MODAL'
export const showDeleteConfirmModalAction = (data: {
  visible: boolean
  tareId?: string | null
}) => {
  return {
    type: SHOW_DELETE_CONFIRM_MODAL,
    payload: data,
  }
}
export const SHOW_SELECT_DELETION_ITEM_MODAL = 'SHOW_SELECT_DELETION_ITEM_MODAL'
export const showSelectDeletionModalAction = (show: boolean) => {
  return {
    type: SHOW_SELECT_DELETION_ITEM_MODAL,
    payload: show,
  }
}
export const SHOW_SEARCH_ITEMS_MODAL = 'SHOW_SEARCH_ITEMS_MODAL'
export const showSearchItemsModalAction = (show: boolean) => {
  return {
    type: SHOW_SEARCH_ITEMS_MODAL,
    payload: show,
  }
}
export const SHOW_PRINT_ERROR_MODAL = 'SHOW_PRINT_ERROR_MODAL'
export const showPrintErrorModalAction = (show: boolean) => {
  return {
    type: SHOW_PRINT_ERROR_MODAL,
    payload: show,
  }
}
export const SET_SHOW_ONLY_GOOD_MODAL = 'SET_SHOW_ONLY_GOOD_MODAL'
export const setShowOnlyGoodWarningAction = (show: boolean) => {
  return {
    type: SET_SHOW_ONLY_GOOD_MODAL,
    payload: show,
  }
}
export const SET_SHOW_ONLY_DEFECTIVE_MODAL = 'SET_SHOW_ONLY_DEFECTIVE_MODAL'
export const setShowOnlyDefectiveWarningAction = (show: boolean) => {
  return {
    type: SET_SHOW_ONLY_DEFECTIVE_MODAL,
    payload: show,
  }
}
export const SHOW_MASS_ITEM_PRINTING_MODAL = 'SHOW_MASS_ITEM_PRINTING_MODAL'
export const setShowMassItemPrintingAction = (show: boolean) => {
  return {
    type: SHOW_MASS_ITEM_PRINTING_MODAL,
    payload: show,
  }
}
export const SHOW_MASS_ACCEPTANCE_MODAL = 'SHOW_MASS_ACCEPTANCE_MODAL'
export const setShowMassAcceptanceAction = (show: boolean) => {
  return {
    type: SHOW_MASS_ACCEPTANCE_MODAL,
    payload: show,
  }
}

export const SHOW_MULTIPLE_COPIES_PRINTING_MODAL = 'SHOW_MULTIPLE_COPIES_PRINTING_MODAL'
export const setShowMultipleCopiesPrintingModalAction = (item: ISupplyReceiptItem | null) => {
  return {
    type: SHOW_MULTIPLE_COPIES_PRINTING_MODAL,
    payload: { item },
  }
}

export const SET_PREVIEW_PRINT = 'SET_PREVIEW_PRINT'
export const setPreviewPrintAction = (printType: PrintTypeEnum) => {
  return {
    type: SET_PREVIEW_PRINT,
    payload: printType,
  }
}



export const SET_SHOW_ALL_TARES = 'SET_SHOW_ALL_TARES'
export const setShowAllTaresAction = (show: boolean) => {
  return {
    type: SET_SHOW_ALL_TARES,
    payload: show,
  }
}

export const SET_ADDITIONAL_STICKER_COUNT = 'SET_ADDITIONAL_STICKER_COUNT'
export const setAdditionalStickerCountAction = (quantity: number | null) => {
  return {
    type: SET_ADDITIONAL_STICKER_COUNT,
    payload: quantity,
  }
}


export const SET_PRINT_LIST = 'SET_PRINT_LIST'
export const setPrintListAction = (printList: IPrintWidgetItem[]) => {
  return {
    type: SET_PRINT_LIST,
    payload: printList,
  }
}


export const SET_DEFECT_TYPES = 'SET_DEFECT_TYPES'
export const setDefectTypesAction = (defectTypes: IDefectType[]) => {
  return {
    type: SET_DEFECT_TYPES,
    payload: defectTypes,
  }
}

export const SET_ACCEPTANCE_OPTION = 'SET_ACCEPTANCE_OPTION'
export const setAcceptanceOptionAction = (value: ISelectOption | null) => {
  return {
    type: SET_ACCEPTANCE_OPTION,
    payload: value,
  }
}
