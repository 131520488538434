import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, BatchStatusesEnum, IError, IOrderBatch, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'

export interface IGetBatchListParams {
  __front__light?: boolean
  search?: string
  picking_printed_from?: string
  picking_printed_to?: string
  picking_printed?: boolean
  status?: BatchStatusesEnum[]
  from?: string
  to?: string
  executor_ids?: string[]
  child_doc_id?: string
}

interface IGetBatchListResult extends ApiReqResult, PaginationResult {
  errors?: IError[]
  batches: IOrderBatch[]
}

const getBatchList = async ({ __front__light, ...params }: IGetBatchListParams) => {
  const urlParams = convertObjectToURLParams(params)
  const result = await api.get(`/order-batch${__front__light ? '/list-light' : ''}${urlParams}`)
  return result?.data
}

const useGetBatchList = (
  params: {},
  handleSuccess?: (result: IOrderBatch[]) => void,
  handleSettled?: () => void,
  handleError?: () => void,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IGetBatchListResult>(
    [`getBatchList`],
    async () => await getBatchList(params),
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        if (data?.success) {
          return handleSuccess?.(data.batches)
        }
        handleError?.()
      },
      onSettled: handleSettled,
      onError: handleError,
      ...options
    },
  )
}

export default useGetBatchList
