import { ITableColumn, ITableRow } from 'src/interfaces'

import { NomenclatureCell, TitleCell } from '../../../../../components'
import { IPickingDoc } from '../../../../../store/interfaces'
import { IReportParam } from 'src/interfaces/report'
import React from 'react'
import { BatchCell } from 'src/components'
import { getBatchNameValue } from '../../../../../helpers/common'

export const generatePickingColumns = (): ITableColumn[] => {
  return [
    {
      key: 'cell',
      title: 'Ячейка',
      title_txt: 'Ячейка',
    },
    {
      key: 'id',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      renderCell: ({ row }) => (
        <NomenclatureCell
          title={
            <TitleCell title={`${row.title}`} article={`${row.article}`} />
          }
          itemId={`${row.skuId}`}
        />
      ),
    },
    {
      key: 'merchant',
      title: 'Организация',
      title_txt: 'Организация',
    },
    {
      key: 'article',
      title: 'Артикул',
      title_txt: 'Артикул',
    },
    {
      key: 'outKey',
      title: 'Внешний ключ',
      title_txt: 'Внешний ключ',
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
    },
    {
      key: 'batch_value',
      title: 'Партия',
      title_txt: 'Партия',
      renderCell: ({ row, isSubItem }) => (
        <BatchCell
          num={String(row.batchNum || '')}
          date={String(row.batchDate || '')}
        />
      ),
    },
  ]
}

export const generatePickingRows = (
  doc: IPickingDoc | null,
  paramValues: IReportParam[],
): ITableRow[] => {
  if (!doc) {
    return [{ id: '' }]
  }
  let filteredDocItems = [...doc.picking.items]

  const filterBySkuId = paramValues.find((param) => param.id === 0)
  if (filterBySkuId) {
    const skuIdsToFilter = filterBySkuId.values
    filteredDocItems = filteredDocItems.filter((item) =>
      skuIdsToFilter?.includes(item.sku_id),
    )
  }

  const filterByCellBarcode = paramValues.find((param) => param.id === 1)
  if (filterByCellBarcode) {
    const cellBarcodesToFilter = filterByCellBarcode.values
    filteredDocItems = filteredDocItems.filter((item) =>
      cellBarcodesToFilter?.includes(item.cell_barcode),
    )
  }

  return (
    filteredDocItems.map((item) => {
      const sku = doc.skus.find((sku) => sku.id === item.sku_id)
      const merchant = sku?.merchant?.title
      const batch = sku?.batches?.find(
        (skuBatch) => skuBatch.id === item.sku_batch_id,
      )

      const cell = doc?.cells?.find((el) => el.barcode === item.cell_barcode)
      return {
        id: item?.sku_id + item.cell_barcode || '',
        skuId: item.sku_id,
        quantity: item.quantity,
        merchant: merchant || '-',
        cell: cell?.title || '-',
        batchNum: batch?.num || '-',
        batchDate: batch?.exp_date || '-',
        batch_value: getBatchNameValue(batch),
        article: sku?.article || '',
        title: sku?.title || '',
        outKey: sku?.external_id || '',
      }
    }) || [{ id: '' }]
  )
}
