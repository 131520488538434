import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import styles from './tableV2.module.scss'
import { LinkContextMenu, OpenCloseBadge, SignalBadge, SwitchWrapper, TableHeader, TablePagination } from '../../index'
import { IconAdd } from '@consta/uikit/IconAdd'
import { IconRemove } from '@consta/uikit/IconRemove'
import { IPagination, ITableColumn, ITableRow } from 'src/interfaces'
import { IconArrowUp } from '@consta/uikit/IconArrowUp'
import { IconArrowDown } from '@consta/uikit/IconArrowDown'
import { Loader } from '@consta/uikit/Loader'
import { SortBtn } from '../settings'
import { useSort } from '../hooks'
import { useVirtualScroll } from '@consta/uikit/useVirtualScrollCanary'
import { SortType } from '../settings/SortBtn/types/sortTypes'
import { useCustomVirtualScroll } from '@/components/Table/hooks/useCustomVirtualScroll'

export type tableV2Theme = 'white' | 'blue' | 'gray' | 'red'

export type ITableV2Props = {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  titleClassName?: string
  extraHeader?: React.ReactNode
  rows: ITableRow[]
  columns: ITableColumn[]
  rightOpenCloseButton?: boolean
  /** Ограничивает высоту таблицы */
  maxHeightBody?: number
  /** Включает кнопку с настройкой столбцов */
  withColumnSettings?: boolean
  /** С переключателем видимости */
  withVisibleToggle?: boolean
  /** Начальная видимость */
  defaultTableVisible?: boolean
  /** Включает пагинацию */
  //withPagination?: boolean // todo

  isLoading?: boolean

  /** На всю высоту */
  fullHeight?: boolean

  className?: string
  theme?: tableV2Theme
  hiddenColumnsKeys?: string[]
  /* Настройки пагинации */
  pagination?: IPagination | null
  onChangePage?: (page: number) => void
  /* Настройки пустой таблицы */
  withEmptyRow?: boolean
  /* Отображение хедера */
  withHeader?: boolean
  emptyRowSlot?: React.ReactNode
  altStyle?: boolean
} & SortType

const TableV2 = React.memo((props: ITableV2Props) => {
  const {
    withHeader = true,
    withEmptyRow = true,
    emptyRowSlot,
    pagination,
    onChangePage = (page) => alert('Не добавлена функция изменения стр.'),
    withVisibleToggle,
    defaultTableVisible = true,
    isLoading,
    title,
    subtitle,
    fullHeight = false,
    titleClassName,
    className,
    extraHeader,
    columns,
    rows,
    theme = 'blue',
    hiddenColumnsKeys = [],
    maxHeightBody,
    withColumnSettings,
    rightOpenCloseButton,
    altStyle = false,
    sortByColumns,
    handleClickSortButton
  } = props

  const tableWrapperRef = useRef(null)
  const {
    listData,
    virtualScrollIsActive,
    handleTableScroll
  } = useCustomVirtualScroll({
    maxRowsLenToStartVirtualScroll: 200,
    displayedItemsLen: 40,
    withVirtualScroll: true,
    rows,
    tableRef: tableWrapperRef
  })

  const { sort, handleSortRows } = useSort({rows})

  const [tableVisible, setTableVisible] = useState(defaultTableVisible)
  const [showColumnsSettings, setShowColumnsSettings] = useState(false)

  const [hiddenColumns, setHiddenColumns] = useState<string[]>(hiddenColumnsKeys)

  const handleChangeCheckbox = (columnKey: string) => {
    setHiddenColumns((prevKeys) => {
      if (prevKeys.includes(columnKey)) {
        return prevKeys.filter((prevColumnKey) => prevColumnKey !== columnKey)
      }
      return [...prevKeys, columnKey]
    })
  }

  const correctColumns = columns.filter(
    (column) => !hiddenColumns.includes(column.key || '') && !column.hidden,
  )
  const gridTemplateColumns = correctColumns
    .map((column) => column.gridCellWidth || '1fr')
    .join(' ')

  return (
    <div
      className={cx(
        altStyle ? styles.altTableWrapper : styles.tableWrapper,
        { [styles.fullHeight]: fullHeight },
        className,
      )}
    >
      {
        withHeader ? (
          <TableHeader
            tableVisible={tableVisible}
            withVisibleToggle={withVisibleToggle}
            onVisibleToggle={() => setTableVisible((prev) => !prev)}
            title={title}
            subtitle={subtitle}
            titleClassName={titleClassName}
            extraHeader={extraHeader}
            withColumnSettings={withColumnSettings}
            columnSettingsItems={columns
              .filter((column) => !column.isAction)
              .map((column) => ({
                key: column.key,
                label: column.title_txt,
                column,
                onClick: () => handleChangeCheckbox(column.key),
              }))}
            hiddenColumnsKeys={hiddenColumns}
            columnsMenuVisible={showColumnsSettings}
            handleChangeCheckbox={handleChangeCheckbox}
            handleCloseColumnsSetting={() => setShowColumnsSettings(false)}
            btnMenuColumnsOnClick={() => setShowColumnsSettings((prev) => !prev)}
          />
        ):null
      }

      <div
        className={cx(
          styles.table,
          { [styles.fullHeight]: fullHeight },
          styles[`theme-${theme}`],
        )}
      >
        {/* HEADER */}
        {
          withHeader ? (
          <div className={styles.header}>
            <div className={styles.row} style={{ gridTemplateColumns }}>
              {correctColumns.map((column, index) => {
                const Component: any = column.renderCell
                const sortColumn = sortByColumns?.find(sortItem => sortItem.columnKey === column.key)
                return (
                  <div
                    key={column.key}
                    className={cx(styles.cell, column.thClassName)}
                    style={{ justifyContent: column.align || 'left' }}
                  >
                    {column.withHeaderAction ? (
                      <Component
                        row={{ id: 'header' }}
                        isSubItem={false}
                        index={index}
                        isHeader={true}
                      />
                    ) : (
                      column.title
                    )}

                    <SortBtn
                      active={column.sortable}
                      sort={sortByColumns ? sortColumn?.direction : sort}
                      onClick={() => handleClickSortButton
                        ? handleClickSortButton(column.key)
                        : handleSortRows(column.key)
                      }
                      className={styles.sortBtn}
                    />
                  </div>
                )
              })}
            </div>
          </div>
          ) : null
        }

        {/* BODY */}
        <div
          // ref={scrollElementRef}
          ref={tableWrapperRef}
          className={styles.body}
          style={{
            maxHeight: maxHeightBody,
            // height: virtualScrollIsActive ? `${maxHeightBody}px` : undefined,
            overflow: isLoading ? 'hidden' : 'auto',
            height: !tableVisible && withVisibleToggle ? '0' : '100%',
          }}
          onScroll={handleTableScroll}
        >
          {isLoading ? (
            <Loader size="m" className={styles.tableLoader} />
          ) : null}

          <div className={styles.rowsWrapper}>
          {/*<div className={styles.rowsWrapper} style={{ marginTop: spaceTop }}>*/}
            {/*{rows.slice(...slice).map((row, index) => {*/}
            {listData.map((row, index) => {
              if (!row) return null
              return (
                <RowContainer
                  // key={row.id}
                  // key={`${index}${spaceTop}`}
                  key={`${index}`}
                  row={row}
                  // itemRef={listRefs[slice[0] + index]}
                  columns={correctColumns}
                  gridTemplateColumns={gridTemplateColumns}
                  rightOpenCloseButton={rightOpenCloseButton}
                />
              )
            })}
          </div>

          {!rows.length && withEmptyRow && !isLoading ? (
            emptyRowSlot ? (
              emptyRowSlot
            ) : (
              <div className={styles.emptyRow}>Ничего нет</div>
            )
          ) : null}
        </div>
        {pagination && pagination.total_pages > 1 ? (
          <TablePagination
            withoutHotkey={true}
            pageSize={pagination.size}
            currentPage={pagination.page}
            totalPages={pagination.total_pages}
            onChangePage={onChangePage}
            classNameWrapper={styles.pagination}
          />
        ) : null}
      </div>
    </div>
  )
})

interface IRowComponentProps {
  row: ITableRow
  columns: ITableColumn[]
  gridTemplateColumns: string
  isSubItem?: boolean
  rightOpenCloseButton?: boolean
  defaultRowView?: boolean
  isExpanded?: boolean
  setExpanded?: () => void
  className?: string
  itemRef?: React.RefObject<HTMLDivElement>
}

const RowContainer = React.memo(({
  row,
  columns,
  itemRef,
  gridTemplateColumns,
  rightOpenCloseButton,
}: IRowComponentProps) => {
  return (
    <>
      {row.subItems ? (
        <CollapseRow
          defaultRowView={row.defaultRowView}
          row={row}
          itemRef={itemRef}
          columns={columns}
          gridTemplateColumns={gridTemplateColumns}
          rightOpenCloseButton={rightOpenCloseButton}
        />
      ) : (
        <Row
          row={row}
          itemRef={itemRef}
          isSubItem={false}
          columns={columns}
          gridTemplateColumns={gridTemplateColumns}
        />
      )}
    </>
  )
})
const RowWrapper = (props: IRowComponentProps & {children: React.ReactNode}) => {
  const { row, children, itemRef, gridTemplateColumns, className } = props
  const classNameValue = cx(styles.row, className, styles[`theme-${row.theme}`], {
    [styles.cursorPointer]: Boolean(row.onClickRow),
    [styles.linkStyle]: Boolean(row.hrefRow),
  })
  const style = { gridTemplateColumns }
  if (row.hrefRow) {
    return (
      <LinkContextMenu
        as={'div'}
        href={row.hrefRow}
        children={children}
        className={classNameValue}
        style={style}
        itemRef={itemRef}
      />
    )
  }
  return (
    <div
      className={classNameValue}
      style={style}
      ref={itemRef}
      children={children}
    />
  )
}
const Row = React.memo((props: IRowComponentProps) => {
  const {
    row,
    columns,
    className,
    gridTemplateColumns,
    isExpanded,
    setExpanded,
    isSubItem,
    itemRef
  } = props
  return (
    <RowWrapper {...props}>
      {columns.map((column: any, index: number) => {
        const Component: any = column.renderCell
        const value = row[column.key]
        return (
          <div
            key={column.key}
            className={cx(styles.cell, column.tdClassName)}
            style={{ justifyContent: column.align || 'flex-left' }}
            onClick={(e) => {
              column.onClickCell?.({
                row,
                index,
                isHeader: false,
                isExpanded,
                toggleExpand: setExpanded,
                isSubItem,
              })
              row.onClickRow?.(row, e)
            }}
          >
            {column.renderCell ? (
              <Component
                row={row}
                isSubItem={isSubItem}
                index={index}
                isExpanded={isExpanded}
                toggleExpand={setExpanded}
              />
            ) : (
              <div>{value}</div>
            )}
          </div>
        )
      })}
    </RowWrapper>
  )
})

const CollapseRow = React.memo(({
  row,
  columns,
  defaultRowView,
  gridTemplateColumns,
  rightOpenCloseButton,
  itemRef
}: IRowComponentProps) => {
  const [isExpanded, setExpanded] = useState<boolean>(!!row.defaultIsExpanded)

  useEffect(() => {
    if (row.hardExpanded === undefined) {
      return
    }
    setExpanded(row.hardExpanded)
  }, [row.hardExpanded])

  const ExtraComponent = row.renderExtraRow
  /** Прилипоние раскрывающейся строки */
  const rowCollapseSticky =
    row.collapsableRowSticky || row.collapsableRowSticky === undefined
  const rowBodyClasses = cx(
    styles.collapseRowBody,
    rowCollapseSticky ? styles.collapseRowSticky : '',
    styles[`theme-${row.theme}`],
  )
  const toggleExpand = () => setExpanded((prev) => !prev)
  return (
    <>
      <div ref={itemRef} className={styles.collapseRow}>
        {defaultRowView ? (
          /** Обыный вид основной строки (как у обычно таблицы) */
          <Row
            key={row.id}
            row={row}
            columns={columns}
            gridTemplateColumns={gridTemplateColumns}
            isExpanded={isExpanded}
            setExpanded={toggleExpand}
            // className={styles[`theme-${row.theme}`]} // todo подумать на счет темы строки
          />
        ) : (
          /** Кастомный видом основной строки  */
          <div className={rowBodyClasses} onClick={toggleExpand}>
            {!rightOpenCloseButton ? (
              <ExpandedBtn isExpanded={isExpanded} />
            ) : null}

            {row.withOpenCloseBadge ? (
              <div className={styles.openCloseBadge}>
                <OpenCloseBadge isOpen={row.withOpenCloseBadge.isOpenValue} view={row.withOpenCloseBadge.view} />
              </div>
            ) : null}

            {row.withFragileBadge?.isFragileValue ? (
              <div className={styles.fragileBadge}>
                <SignalBadge size={'s'} />
              </div>
            ) : null}

            {row.withSwitch ? (
              <div className={styles.switcherWrap}>
                <SwitchWrapper
                  onClick={(e) => e.stopPropagation()}
                  label={row.withSwitch.title || ''}
                  value={row.withSwitch.switchValue}
                  onChange={row.withSwitch.onChange}
                  disabled={row.withSwitch.disabled}
                  className={styles.switcher}
                />
              </div>
            ) : null}

            {ExtraComponent ? <ExtraComponent row={row} /> : null}

            {rightOpenCloseButton ? (
              <div className={styles.openCloseWrapperRight}>
                <div className={styles.openCloseIcon}>
                  {isExpanded ? (
                    <IconArrowUp size={'s'} placeholder='' />
                  ) : (
                    <IconArrowDown size={'s'} placeholder='' />
                  )}
                </div>
              </div>
            ) : null}
          </div>
        )}
        {isExpanded ? (
          /** Вложенная строка */
          <div ref={itemRef} className={styles.subRowsWrapper}>
            {
              row.subItems?.map((subRow: any, i) => (
                <Row
                  key={(subRow.id || i) + row.id}
                  row={subRow}
                  columns={columns}
                  // itemRef={itemRef}
                  gridTemplateColumns={gridTemplateColumns}
                  isSubItem={true}
                />
              ))
            }
          </div>
        ) : null}
      </div>
    </>
  )
})

const ExpandedBtn = React.memo((props: {
  className?: string
  isExpanded: boolean
  onClick?: () => void
}) => (
  <div
    className={cx(styles.openCloseWrapper, props.className)}
    onClick={props.onClick}
  >
    <div className={styles.openCloseIcon}>
      {props.isExpanded ? <IconRemove size={'s'} placeholder='' /> : <IconAdd size={'s'} placeholder='' />}
    </div>
  </div>
))

export default TableV2
