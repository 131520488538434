import { useQuery } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, IOrder, IPaginationFilter } from 'src/interfaces'
import { IPallet, IPalletPlace, palletStatuses } from 'src/interfaces/pallet/pallet'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'
import { provideMock } from '../../mocks'
import { MOCK_GET_PALLETS_BY_STATUS } from '../../mocks/packing/getPalletsListByStatus'

interface GetPalletsListParams extends IPaginationFilter {
  search?: string;
  status?: palletStatuses
  userBarcode?: string
  from?: string
  to?: string
}

export const getTransportPlaceList = async ({ userBarcode, ...rest }: GetPalletsListParams) => {
  const params = convertObjectToURLParams({
    processing_by: userBarcode,
    ...rest
  })
  const result = await api.get(`/packing/pallet${params}`)
  if (!result.data?.success) throw result
  return result.data
}

export interface IPalletsResult {
  pallets: IPallet[]
  places: IPalletPlace[]
  orders: IOrder[]
}
type PalletsListResultType = ApiReqResult & IPalletsResult

const useGetTransportPlaceList = (
  params: GetPalletsListParams,
  callback?: ({ pallets, places }: IPalletsResult) => void,
  options?: UseQueryOptions<any, any, any>
) => {
  return useQuery<PalletsListResultType>(
    [`getPallets`, params],
    // () => getPallets(params),
    provideMock<GetPalletsListParams, PalletsListResultType>(() => getTransportPlaceList(params), params, [
      {
        key: 'userBarcode',
        value: 'mock',
        result: MOCK_GET_PALLETS_BY_STATUS,
      },
    ]),
    {
      enabled: !!params,
      onSuccess: ({ success, orders, pallets, places }) => {
        if (success) {
          if (callback) {
            callback({ orders, pallets, places })
          }
        }
      },
      ...options,
    },
  )
}

export default useGetTransportPlaceList
