import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { initialState, reducer } from '../store/reducer'
import { IReducerAction, IReducerState } from '../store/storeInterfaces'
import { useGetDeliveriesList, useGetPackagings } from '@/hooks'
import { useAppContext } from '@shared/providers/AppContextProvider'
import {
  addRefundPlace,
  addSkus,
  setCurrentPlanItems,
  setCurrentRefundPlace,
  setDefectTara,
  setDefectTaraMerchantId,
  setDefectTypes,
  setDeliveryList,
  setErrorOpenTares,
  setGoodTara,
  setGoodTaraMerchantId,
  setPackagings,
  setRefund,
  setSkus,
  setSubstitutionTara,
  setSubstitutionTaraMerchantId,
  setTares,
  setUsedSerialNumbers,
  updateRefundPlace,
} from '../store/actions'
import useGetDefectType from '@/hooks/defect/useGetDefectType'
import { ConditionEnum, IContainer, IRefund, IRefundPlace, IRefundSku } from '@/interfaces'
import useGetReturnPlace from '@/hooks/refundAcceptance/useGetReturnPlace'
import { checkProblemOpenTares, getAlreadyUsedSerialNumbers, getTareBarcodesFromRefund } from '../utils/utils'
import useGetTare from '@/hooks/tare/useGetTare'
import useGetReturns from '@/hooks/refundAcceptance/useGetReturns'

export interface IUseReducer {
  state: IReducerState
  dispatch: React.Dispatch<IReducerAction>
}


interface IContext extends IUseReducer {
  fetchCurrentRefundPlace: (placeID: string) => Promise<IRefundPlace | null>
  loadRefund: (externalId: string) => Promise<IRefund | null>
}


export const RefundAcceptanceContext = createContext<IContext>({
  state: initialState,
  dispatch: (action: IReducerAction) => action,
  fetchCurrentRefundPlace: (placeID: string) => Promise.resolve(null),
  loadRefund: (externalId: string) => Promise.resolve(null),
})


export const RefundAcceptanceProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { setGlobalLoading } = useAppContext()
  const { isLoading: isDeliveriesListLoading } = useGetDeliveriesList(
    (deliveries) => dispatch(setDeliveryList(deliveries)),
  )
  const {fetch: fetchPackagings} = useGetPackagings()
  const {fetch: fetchDefectTypes} = useGetDefectType()
  const [isLoading, setLoading] = useState<boolean>(false)
  const {fetch: returnPlaceFetch} = useGetReturnPlace()
  const {fetch: returnFetch} = useGetReturns()
  const {fetch: fetchContainer} = useGetTare()


  useEffect(() => {
    ;(async () => {
      setGlobalLoading(true)
      try {
        dispatch(setPackagings(await fetchPackagings()))
        dispatch(setDefectTypes(await fetchDefectTypes()))
      } finally {
        setGlobalLoading(false)
      }
    })()
  }, [])

  useEffect(() => setGlobalLoading(isLoading || isDeliveriesListLoading),
    [isLoading, isDeliveriesListLoading],
  )

  useEffect(() => {
    console.log(
      '====== DEBUG STATE ======\n\n',
      state,
      '\n\n==================',
    )
  }, [state])


  const fetchCurrentRefundPlace = async (placeID: string) => {
    const data = await returnPlaceFetch(state.refund?.id || '', placeID)
    const place = data?.place || null
    if (place) {
      if (state.currentRefundPlace) {
        updateRefundPlace(place, {state, dispatch})
      } else {
        addRefundPlace(place, { state, dispatch })
      }
    } else {
      dispatch(setCurrentRefundPlace(null))
    }
    dispatch(setCurrentPlanItems(data?.plan_items || []))
    addSkus(data?.skus || [], {state, dispatch})
    return place
  }

  const loadTares = async (containers?: IContainer[], places?: IRefundPlace[])=> {
    const tares: IContainer[] = containers || []
    let goodTara: IContainer | null = null
    let defectTara: IContainer | null = null
    let substitutionTara: IContainer | null = null
    let goodTaraMerchantId: string | null = null
    let defectTaraMerchantId: string | null = null
    let substitutionTaraMerchantId: string | null = null
    containers?.forEach(tara => {
      const barcodes = getTareBarcodesFromRefund(places);
      const barcode = barcodes.find(bc => bc.tare_barcode === tara.barcode)
      if (tara.open) {
        if (barcode?.is_substitution && !substitutionTara) {
          substitutionTara = tara
          substitutionTaraMerchantId = barcode?.merchant_id
        } else if (tara.condition === ConditionEnum.GOOD && !goodTara) {
          goodTara = tara
          goodTaraMerchantId = barcode?.merchant_id
        } else if (tara.condition === ConditionEnum.DEFECT && !defectTara) {
          defectTara = tara
          defectTaraMerchantId = barcode?.merchant_id
        }
      }
    })
    dispatch(setGoodTara(goodTara))
    dispatch(setDefectTara(defectTara))
    dispatch(setSubstitutionTara(substitutionTara))
    dispatch(setTares(tares))
    dispatch(setGoodTaraMerchantId(goodTaraMerchantId))
    dispatch(setDefectTaraMerchantId(defectTaraMerchantId))
    dispatch(setSubstitutionTaraMerchantId(substitutionTaraMerchantId))
  }

  useEffect(() => {
    /* Проверяем на дублирование открытых тар */
    if (!state.refund || !state.tares) return
    const {error, problemTares} = checkProblemOpenTares(state.tares, state.refund.places)
    dispatch(setErrorOpenTares(error ? problemTares : null))
  }, [state.tares, state.tares])

  const loadCurrentPlace = async (refund: IRefund): Promise<IRefundSku[]> => {
    const place = refund.places?.find(p => p.status !== 'DONE') || null
    dispatch(setCurrentRefundPlace(place))

    if (place) {
      const data = await returnPlaceFetch(refund.id || '', place.id)
      dispatch(setCurrentPlanItems(data?.plan_items || []))
      return data?.skus || []
    } else {
      dispatch(setCurrentPlanItems([]))
      return []
    }
  }


  const loadRefund = async (externalId: string):Promise<IRefund | null> => {
    if (!externalId) {
      return null
    }
    setLoading(true)
    const data = await returnFetch({external_id: externalId});

    let refund: IRefund
    if (data?.returns?.length) {
      refund = data.returns[0]
      const skus = data.skus || []
      dispatch(setRefund(refund))
      dispatch(setUsedSerialNumbers(getAlreadyUsedSerialNumbers(refund.places || [])))
      await loadTares(data.containers, refund.places)
      const planSkus = await loadCurrentPlace(refund)
      dispatch(setSkus([
        ...skus,
        ...planSkus.filter(ps => !skus.find(s => s.id === ps.id)),
      ]))

    } else {
      refund = {
        external_id: externalId,
        status: 'PROCESSING',
      }
      dispatch(setRefund(refund))
      dispatch(setSkus([]))
      dispatch(setUsedSerialNumbers([]))
      dispatch(setTares([]))
      dispatch(setGoodTara(null))
      dispatch(setDefectTara(null))
      dispatch(setSubstitutionTara(null))
      dispatch(setGoodTaraMerchantId(null))
      dispatch(setDefectTaraMerchantId(null))
      dispatch(setSubstitutionTaraMerchantId(null))
      dispatch(setCurrentRefundPlace(null))
      dispatch(setCurrentPlanItems([]))
    }
    setLoading(false)
    return refund
  }


  return (
    <RefundAcceptanceContext.Provider
      value={{ state, dispatch, fetchCurrentRefundPlace, loadRefund }}
    >
      {children}
    </RefundAcceptanceContext.Provider>
  )
}

export const useRefundAcceptanceContext = () => {
  return useContext(RefundAcceptanceContext)
}
