import {
  REVERT_FORM_STATE_TO_RAW,
  SET_SHIPMENT_DATA,
  SET_SHIPMENT_FORM_STATE_DRAFT,
  SET_SHIPMENT_FORM_STATE_RAW,
  SET_SHIPMENT_LIST,
  SET_SHIPMENT_RAW,
  UPDATE_FORM_STATE_DRAFT,
  UPDATE_FORM_STATE_RAW,
} from '../actions'

import { IReducerAction } from 'src/interfaces'
import { cloneDeep } from '@shared/helpers'
import { IShipmentFormState } from '../interfaces/shipment'

export const shipmentInitialState: IShipmentFormState = {
  documentRaw: null,
  formStateRaw: null,
  formStateDraft: null,
  list: [],
  allData: null,
}

export const shipmentReducer = (
  state: IShipmentFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    /** Общие кейсы */

    case SET_SHIPMENT_RAW:
      return { ...state, documentRaw: payload }

    case SET_SHIPMENT_FORM_STATE_DRAFT:
      return { ...state, formStateDraft: payload }

    case SET_SHIPMENT_FORM_STATE_RAW:
      return { ...state, formStateRaw: payload }

    case SET_SHIPMENT_LIST:
      return { ...state, list: payload }

    case SET_SHIPMENT_DATA:
      return {
        ...state,
        allData: payload,
        list: payload.shipments,
      }

    case UPDATE_FORM_STATE_DRAFT:
      return {
        ...state,
        formStateDraft: {
          ...(state.formStateDraft || {}),
          [payload.key]: payload.value,
        },
      }

    case UPDATE_FORM_STATE_RAW:
      return {
        ...state,
        formStateRaw: {
          ...(state.formStateRaw || {}),
          [payload.key]: payload.value,
        },
      }

    case REVERT_FORM_STATE_TO_RAW:
      return {
        ...state,
        formStateDraft: cloneDeep(state.formStateRaw),
      }

    default:
      return state
  }
}
