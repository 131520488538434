import { useMutation, useQuery } from 'react-query'
import api from '@shared/api'
import { UseQueryOptions } from 'react-query/types/react/types'
import { provideMock } from '../../../mocks'
import { ConditionEnum, IWarehouseCell } from '../../../interfaces'

export const useGetCellById = () => {
  const fetchCellById = async (barcode: string) => {
    const result = await api.get(`/warehouse/cell/${barcode}`)

    if (!result?.data?.success) {
      throw 'error'
    }
    return result.data
  }

  return useMutation('getCellById', fetchCellById)
}

interface IQueryGetCellByIdParams { barcode: string }
interface IQueryGetCellByIdResult extends IWarehouseCell {}

export const useQueryGetCellById = (paramsData: {
  params: IQueryGetCellByIdParams,
  options?: UseQueryOptions<any>
}) => {
  const { params, options } = paramsData

  return useQuery<IQueryGetCellByIdResult>(
    [`getCellById`, params],
    provideMock<IQueryGetCellByIdParams, IQueryGetCellByIdResult>(
      async () => {
        const result = await api.get(`/warehouse/cell/${params.barcode}`)
        return result.data
      },
      params,
      [
        {
        key: 'barcode',
        value: 'mock',
        result: {
          barcode: "string",
          condition: ConditionEnum.GOOD,
          id: "497f6eca-6276-4993-bfeb-53cbbbba6f08",
          title: "string",
          zone_id: "4495020a-236f-46e0-9453-e3f9cc6476f4"
        },
      },
    ]),
    options
  )
}
