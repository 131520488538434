import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import styles from './SelectBatchModal.module.scss'

import { Button } from '@consta/uikit/Button'

import { InputWithDateMask, InputWrapper, ItemPreview, Modal, SelectList } from '@/components'

import { useCreateBatch } from '@/hooks'

import { IAcceptanceSku, IBatch, ISelectListOption, ModalProps, useCreateBatchResult } from '@/interfaces'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { DATE_FORMAT, DATE_FORMAT_VIEW } from '@shared/const/date'
import { RemainingClientBatches } from '@pages/Acceptance/AcceptanceFormPage/types'

interface ISelectBatchModal extends ModalProps {
  remainingClientBatches?: RemainingClientBatches
  onlyCreate?: boolean
  expdate?: boolean
  item: IAcceptanceSku | null
  onSubmit: (batch: IBatch, isNewBatch?: boolean) => void
  defaultSelectedBatch?: IBatch
}

const SelectBatchModal = (props: ISelectBatchModal) => {
  const {
    remainingClientBatches = [],
    onlyCreate,
    isOpen,
    onSubmit,
    expdate,
    item,
    onClose,
    defaultSelectedBatch,
  } = props

  const createBatchMutation = useCreateBatch({ itemId: item?.id ?? '' })

  const [selectedBatch, setSelectedBatch] = useState<ISelectListOption | null>(
    null,
  )
  const [batchNum, setBatchNum] = useState<TextFieldPropValue>(null)
  const [batchDate, setBatchDate] = useState<TextFieldPropValue>(null)

  const [batchesOptions, setBatchesOptions] = useState<ISelectListOption[]>([])

  useEffect(() => {
    if (batchDate) {
      setBatchNum(`${item?.external_id} ${batchDate}`)
    }
  }, [batchDate])

  useEffect(() => {
    if (item) {
      const skuRemainingBatchesIds = remainingClientBatches[item.id]
      const options =
        item?.batches
          ?.filter((batch) => {
            if (skuRemainingBatchesIds) {
              return skuRemainingBatchesIds.includes(batch.id)
            }
            return true
          })
          .map((b) => {
            return {
              label: (
                <span className={styles.batchSelect}>
                  {dayjs(b.exp_date).format(DATE_FORMAT_VIEW)} <span>{b.num}</span>
                </span>
              ),
              id: b.id,
              value: b.id,
            }
          }) ?? []
      setBatchesOptions(options)

      /**
       * Если у товара есть плановая партия от клиента и она осталась одна,
       * то выбираем ее
       */
      const optionIncludesPlanBatch = skuRemainingBatchesIds?.includes(
        options[0]?.id,
      )
      if (options.length === 1 && optionIncludesPlanBatch) {
        handleChoiceBatch(options[0])
        return
      }

      if (!options.length) {
        return
      }
      /** Дефолтно выбранный батч */
      const defaultBatchOption = options.find(
        (option) => option.id === defaultSelectedBatch?.id,
      )
      if (defaultBatchOption) {
        setSelectedBatch(defaultBatchOption)
        return
      }
      setSelectedBatch(options[0])
    }
  }, [])

  const handleChoiceBatch = (selectedBatch: ISelectListOption | null) => {
    const findBatch = item?.batches?.find(
      (b: IBatch) => b.id === selectedBatch?.value,
    )

    if (findBatch) {
      onSubmit(findBatch)
    } else {
      console.error('Какая ошибка, которую не предусмотрели')
    }
  }

  const handleAddNewBatch = async () => {
    const newBatch = {
      num: batchNum ? String(batchNum) : undefined,
      exp_date: batchDate
        ? dayjs(batchDate, DATE_FORMAT_VIEW).format(DATE_FORMAT)
        : undefined,
    }

    createBatchMutation
      .mutateAsync({
        batch: newBatch,
      })
      .then((result: useCreateBatchResult) => {
        onSubmit({ id: result.id, ...newBatch }, true)
      })
      .catch((error: any) => {})
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.selectBatch}
      withClose
      onClose={onClose}
    >
      <div className={styles.top}>
        {item && (
          <ItemPreview
            image={item.image}
            title={item.title}
            barcode={item.barcode_used || ''}
          />
        )}
      </div>

      <div className={styles.body}>
        {!onlyCreate ? (
          <div className={styles.left}>
            <h3>Выберите партию из списка</h3>
            <SelectList
              options={batchesOptions}
              value={selectedBatch}
              onChange={(value) => setSelectedBatch(value)}
              className={styles.select}
            />
            <Button
              label="Выбрать"
              className={styles.btn}
              disabled={!selectedBatch}
              onClick={() => handleChoiceBatch(selectedBatch)}
            />
          </div>
        ) : null}
        <div className={styles.right}>
          <h3>Новая партия</h3>
          <div className={styles.newBatch}>
            {expdate ? (
              <InputWithDateMask
                value={batchDate}
                id={'dateTo'}
                handleChange={(value: TextFieldPropValue) =>
                  setBatchDate(value)
                }
                className={styles.itemInput}
                size="l"
                placeholder={DATE_FORMAT_VIEW}
                rightSide={IconCalendar}
              />
            ) : null}

            <InputWrapper
              value={batchNum}
              id={'batchNum'}
              handleChange={(value: TextFieldPropValue) =>
                setBatchNum(String(value))
              }
              className={styles.itemInput}
              placeholder={'№ партии'}
              size="l"
            />
          </div>
          <Button
            label="Добавить партию"
            className={styles.btn}
            disabled={expdate ? !batchNum || !batchDate : !batchNum}
            onClick={handleAddNewBatch}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SelectBatchModal
