import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { ICombobox } from '../acceptance'
import { generateStatusesOptions } from '@shared/helpers'


export enum ShipmentStatuses {
  new = "NEW",
  ready_for_loading = "READY_FOR_LOADING",
  loading = "LOADING",
  loaded = "LOADED",
  done = "DONE",
}

export const SHIPMENT_STATUSES_DESCRIPTION: Record<ShipmentStatuses | string, string> = {
  [ShipmentStatuses.new]: 'Новый',
  [ShipmentStatuses.ready_for_loading]: 'Готов для отгрузки',
  [ShipmentStatuses.loading]: 'Отгружается',
  [ShipmentStatuses.loaded]: 'Погрузка завершена',
  [ShipmentStatuses.done]: 'Отгружено',
}

export const SHIPMENT_STATUS_BADGE_TYPE: Record<ShipmentStatuses | string, BadgePropStatus> = {
  [ShipmentStatuses.new]: "warning",
  [ShipmentStatuses.ready_for_loading]: "error",
  [ShipmentStatuses.loading]: "success",
  [ShipmentStatuses.loaded]: "normal",
  [ShipmentStatuses.done]: "system",
}

export const shipmentSelectOptions: ICombobox[] = generateStatusesOptions(SHIPMENT_STATUSES_DESCRIPTION)