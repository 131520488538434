import api from '@shared/api'

import { provideMock } from 'src/mocks'

import { ApiReqResult } from 'src/interfaces'

interface ICheckOrderParams {
  orderId: string
}

const checkOrderMethod = async ({ orderId }: ICheckOrderParams) => {
  let result = await api.get(`/packing/order/${orderId}/check-status`)
  return result.data
}

interface IResult extends ApiReqResult {
}
const useCheckOrderStatus = () => ({
  fetch: (params: ICheckOrderParams) =>
    provideMock<ICheckOrderParams, IResult>(checkOrderMethod, params, {
      key: 'orderId',
      value: 'mock',
      result: { success: true },
    })(),
})

export default useCheckOrderStatus
