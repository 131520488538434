import React, { useMemo, useState } from 'react'

import styles from './accepted-table.module.scss'

import { InputWrapper, TableV2 } from '@/components'

import { IAcceptanceSkuObj, ISupplyReceipt, ISupplyReceiptItem, TrustBox } from '@/interfaces'
import { generateColumns, generateRows } from './utils/generators'
import { Button } from '@consta/uikit/Button'
import { IconTrash } from '@consta/icons/IconTrash'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { useDebounce } from '@shared/hooks'
import { useAppContext } from '@shared/providers/AppContextProvider'

interface IAcceptedTableProps {
  disableChangeCondition?: boolean
  documentLoading: boolean
  tares: ISupplyReceipt[]
  originalSkusObj: IAcceptanceSkuObj
  trustBoxes?: TrustBox[]
  handleClickDeleteBtn: () => void
  handleChangeSwitchDefective: (tare: ISupplyReceipt, checked: boolean) => void
  printItemSticker: (item: ISupplyReceiptItem) => void
  printTareSticker: (tareBarcode: string) => void
  handleEditTare: (receiptId: string, tareItemsQuantityData: Record<string, string | null>) => Promise<void>
}

interface ContextProps {
  advanceMode: boolean
  performWithAdvance: (action: () => () => void) => void
}

type editModeType = 'accept' | 'reset' | 'start'

const AcceptedTable = React.memo((props: IAcceptedTableProps & ContextProps) => {
    const {
      disableChangeCondition,
      tares,
      originalSkusObj,
      handleClickDeleteBtn,
      handleChangeSwitchDefective,
      printItemSticker,
      printTareSticker,
      handleEditTare,
      documentLoading,
      trustBoxes = [],
      advanceMode,
      performWithAdvance,
    } = props

    const [searchBarcodeDebounced, searchBarcode, setSearchBarcode] =
      useDebounce<TextFieldPropValue | null>(null)


    // Данные для редактирования
    const [editTareId, setEditTareId] = useState<string | null>(null)
    const [draftTareData, setDraftTareData] = useState<Record<string, string | null> | null>(null)

    const handleEditTareItemQuantity = (tareItemId: string, value: string | null) => {
      if (!draftTareData) {
        console.error('Тара должна быть открыта для редактирования')
        return
      }
      setDraftTareData(prev => ({
        ...prev, [tareItemId]: value,
      }))
    }

    const tareEditAction = async (tareId: string, editMode: editModeType) => {
      switch (editMode) {
        case 'start':
          const tare = tares.find(tare => tare.id === tareId)
          if (!tare) {
            console.error('Тара не найдена по id!')
            return
          }
          setDraftTareData(tare.items.reduce(
            (acc, item) => ({ ...acc, [item.id]: String(item.quantity) }), {}),
          )
          setEditTareId(tareId)
          break
        case 'accept':
          if (!draftTareData) {
            console.error('Данных о редактировании нет!')
            return
          }
          await handleEditTare(tareId, draftTareData)
          setEditTareId(null)
          break
        case 'reset':
          setEditTareId(null)
          break
      }
    }
    const handleClickTareEdit = (tareId: string, editMode: editModeType) => {
      if (editMode === 'start' && !advanceMode) {
        performWithAdvance(() => () => tareEditAction(tareId, editMode))
        return
      }
      tareEditAction(tareId, editMode)
    }

    const columns = useMemo(generateColumns, [])
    const rows = useMemo(
      () =>
        generateRows({
          tares,
          originalSkusObj,
          handleChangeSwitchDefective,
          barcode: searchBarcodeDebounced,
          printItemSticker,
          printTareSticker,
          handleEditTareItemQuantity,
          handleClickTareEdit,
          draftTareData,
          editTareId,
          disableChangeCondition,
          trustBoxes,
        }),
      [
        tares,
        originalSkusObj,
        handleChangeSwitchDefective,
        searchBarcodeDebounced,
        draftTareData,
        editTareId,
        trustBoxes,
      ],
    )

    return (
      <div className={styles.acceptedTable}>
        <TableV2
          isLoading={documentLoading}
          withColumnSettings
          extraHeader={
            <div className={styles.extraHeader}>
              <InputWrapper
                id={'searchBarcode'}
                withDelayAndManual
                clearable={Boolean(searchBarcode)}
                handleChange={(value: TextFieldPropValue) => setSearchBarcode(value)}
                onClear={() => setSearchBarcode(null)}
                value={searchBarcode}
                label={''}
                className={styles.searchBarcode}
                placeholder={'Поиск по ШК товара или тары'}
              />
              <Button
                view={'secondary'}
                label='Удалить товар или тару'
                iconRight={IconTrash}
                onClick={handleClickDeleteBtn}
              />
            </div>
          }
          rows={rows}
          columns={columns}
          title={'Принято'}
          className={styles.tableWrap}
          maxHeightBody={500}
        />
      </div>
    )
  }
  , (prevProps, nextProps) => {
    return (
      prevProps.disableChangeCondition === nextProps.disableChangeCondition
      && prevProps.tares === nextProps.tares
      && prevProps.originalSkusObj === nextProps.originalSkusObj
      && prevProps.documentLoading === nextProps.documentLoading
      && prevProps.trustBoxes === nextProps.trustBoxes
      && prevProps.advanceMode === nextProps.advanceMode
    )
  },
)

const AcceptedTableWithContext = (props: IAcceptedTableProps) => {
  const { advanceMode, performWithAdvance } = useAppContext()
  return (
    <AcceptedTable
      advanceMode={advanceMode}
      performWithAdvance={performWithAdvance}
      {...props}
    />
  )
}

export default AcceptedTableWithContext
