import { generatePath, Link } from 'react-router-dom'

import { DocumentItem, ITableColumn, ITableRow, Service } from 'src/interfaces'

import { PATHS } from '@shared/routing'

export const generateBillingColumns = (
  page: string,
  id: string,
  tabId: string,
): ITableColumn[] => {
  return [
    {
      key: 'code',
      title: 'ID PAS',
      title_txt: 'ID PAS',
      gridCellWidth: '100px',
    },
    {
      key: 'transcript',
      title: 'Расшифровка',
      title_txt: 'Расшифровка',
      gridCellWidth: '150px',
      renderCell: ({ row }) => (
        <Link
          title={'нажмите для расшифровки'}
          to={generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
            page,
            id,
            tabId,
            serviceId: `${row.serviceId || '-'}`,
          })}
        >
          расшифровка
        </Link>
      ),
    },
    {
      key: 'operation',
      title: 'Операция',
      title_txt: 'Операция',
    },
    {
      key: 'measure',
      title: 'Ед. измерения',
      title_txt: 'Ед. измерения',
    },
    {
      key: 'quantity',
      title: 'Количество',
      title_txt: 'Количество',
      gridCellWidth: '150px',
    },
  ]
}

export const generateBillingRows = (
  docs: DocumentItem[],
  services: Service[],
  search: string,
): ITableRow[] => {
  return docs
    .map((doc) => {
      const service = services.find((serv) => serv.id === doc.service_id)
      return {
        id: doc.id,
        code: `${service?.code || ''}`,
        operation: service?.title || '',
        measure: service?.measure,
        quantity: doc.quantity,
        serviceId: doc.service_id,
      }
    })
    .filter((item) => {
      if (!search) {
        return true
      }
      return (
        item.code.includes(search) ||
        item.operation.toLowerCase().includes(search.toLowerCase())
      )
    })
}
