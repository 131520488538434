import { TemplateFieldType } from '../types/tableGenerator/generatorTypes'
import { IField, IFieldValue } from '@shared/types/fields'
import { getCalendarConstaFieldType } from './calendar'
import { FieldType } from '@widgets/GeneratorFields'
import { generateFieldsValue } from '@widgets/GeneratorFields/utils'

/**
 * Получение пропсов полей по типу поля
 */
export const getFieldsGeneratorByFieldType = (
  fields: IField[],
  fieldValues: IFieldValue[],
  editMode: boolean,
): any[] /* todo тип поля */ => {
  return fields.map((field) => {
    const required = field.required && editMode

    const id = field.id
    const title = field.title
    const read_only = field.read_only

    const fieldValue = fieldValues.find(fieldVal => fieldVal.id === id)

    const commonProps = {
      id: `${id}`,
      label: title,
      size: 's',
      readOnly: read_only,
      isRequired: required,
      documentType: field.document_type,
    }

    switch (field.type) {
      case TemplateFieldType.BOOLEAN:
        return { type: FieldType.BOOLEAN, fieldProps: { ...commonProps, size: 'm' } }

      case TemplateFieldType.CELL:
        return { type: FieldType.CELL, fieldProps: commonProps }
      case TemplateFieldType.CONTAINER:
        return { type: FieldType.CONTAINER, fieldProps: commonProps }
      case TemplateFieldType.WAREHOUSE_PLACE:
        return { type: FieldType.PLACE, fieldProps: commonProps }

      case TemplateFieldType.DATE:
      case TemplateFieldType.DATETIME:
      case TemplateFieldType.TIME:
      case TemplateFieldType.YEARMONTH:
        return { type: FieldType.calendar, fieldProps: { ...commonProps, type: getCalendarConstaFieldType(field.type) },
        }

      case TemplateFieldType.DOCUMENT:
        return { type: FieldType.DOCUMENT, fieldProps: commonProps }

      case TemplateFieldType.MERCHANT:
        return { type: FieldType.MERCHANTS, fieldProps: commonProps }
      case TemplateFieldType.CONTRACTOR:
        return { type: FieldType.CONTRACTOR, fieldProps: commonProps }
      case TemplateFieldType.SKU:
        return { type: FieldType.SKU, fieldProps: commonProps }
      case TemplateFieldType.SKU_BATCH:
        return { type: FieldType.SKU_BATCH, fieldProps: commonProps }


      case TemplateFieldType.SELECT:
        return {
          type: FieldType.combobox,
          fieldProps: {
            ...commonProps,
            items: generateFieldsValue(field.values),
            type: field.type,
          },
        }


      case TemplateFieldType.NUMBER:
        return {
          type: FieldType.input,
          fieldProps: {
            ...commonProps,
            isNumber: true
          },
        }
      case TemplateFieldType.URL:
        return { type: FieldType.input, fieldProps: { ...commonProps } }
      case TemplateFieldType.TEXT:
        return { type: FieldType.input, fieldProps: { ...commonProps } }
      case TemplateFieldType.TEXT_AREA:
        return { type: FieldType.input, fieldProps: { ...commonProps, type: 'textarea' } }

      case TemplateFieldType.GROUP:
        return {
          type: FieldType.GROUP,
          fieldProps: {
            ...commonProps,
            title: field.title,
            fields: field.fields,
            fieldValues: [],
            editMode
          },
        }

      case TemplateFieldType.JSON:
        return {
          type: FieldType.JSON,
          fieldProps: {
            ...commonProps,
            title: field.title,
            fields: field.fields,
            fieldValues: [],
            editMode
          },
        }

      case TemplateFieldType.TABLE:
        return {
          type: FieldType.TABLE,
          fieldProps: {
            ...commonProps,
            creationAvailable: !commonProps.readOnly,
            deletionAvailable: !commonProps.readOnly,
            dataId: id,
            rows: fieldValue?.additional_data?.rows || [],
            columns: field.additional_data?.columns,
            editMode,
          }
        }

      default:
        return null
    }
  })
}
