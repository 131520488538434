import { useMutation } from 'react-query'
import api from '@shared/api'

export interface IProps {
  merchant_id: string
  scheduled_from?: string
  scheduled_to?: string
  sku_condition: string
}

// http://wms-test.kak2c.ru/warehouse-api/inventory
const useCreateInventory = () => {
  const createInventory = async (props: IProps) => {
    const result = await api.post(`/inventory`, props)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(createInventory)
}

export default useCreateInventory
