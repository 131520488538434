import React from 'react'
import styles from './multiselect-param.module.scss'
import { IReportParamProps } from '../intrefaces'
import { ComboboxWrapper } from '../../../../../../components'
import { DefaultItem } from '@consta/uikit/Combobox'
import { Button } from '@consta/uikit/Button'
import { IconLayers } from '@consta/icons/IconLayers'

const MultiselectParam = (props: IReportParamProps) => {
  const {
    template,
    paramValue,
    handleChange,
    options,
    handleApiSearch,
    searchFunction,
    withApiSearch,
    loading,
    modalOptions,
    setIsModalOpen,
  } = props

  const items =
    options?.map((o) => ({ id: o.value, label: o.title, ...o })) || []
  const comboValues =
    paramValue.values
      ?.map((val) => items.find((i) => i.id === val))
      .filter((i) => i) || []
  const comboChange = (val: DefaultItem[] | null) =>
    handleChange({
      id: template.id,
      values: val?.map((v) => String(v.id)) || undefined,
    })

  return (
    <div className={styles.inputWrapper}>
      <ComboboxWrapper
        id={'report-param-' + template.id}
        isMultiple
        label={template.title}
        items={items}
        value={comboValues as any}
        onChange={({ value }) => comboChange(value as any)}
        wrapperClassName={styles.select}
        loading={loading}
        searchFunction={searchFunction}
        withApiSearch={withApiSearch}
        handleApiSearch={handleApiSearch}
        className={styles.combobox}
      />
      {modalOptions && (
        <>
          <Button
            iconLeft={IconLayers}
            onClick={() => setIsModalOpen?.(modalOptions)}
            className={styles.button}
            title='Массовая замена данных в комбобоксе'
          />
        </>
      )}
    </div>
  )
}

export default MultiselectParam
