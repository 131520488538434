import React, { useCallback, useEffect, useMemo, useState } from 'react'

import styles from './MassAcceptModal.module.scss'
import { ComboboxWrapper, Modal } from '@/components'

import {
  ConditionEnum,
  IAcceptanceSku,
  IAcceptanceSkuObj,
  IDefectType,
  ISupplyItem,
  ISupplyReceiptItem,
  ModalProps,
} from '@/interfaces'
import { Button } from '@consta/uikit/Button'
import MassAcceptTable from './MassAcceptTable'
import { DefaultItem } from '@consta/uikit/Combobox'
import SelectBatchModal from '../../ItemAdding/SelectBatchModal/SelectBatchModal'
import { Loader } from '@consta/uikit/Loader'

interface ItemRemovingProps extends ModalProps {
  loading: boolean
  defectTypes: IDefectType[]
  originalSkus: IAcceptanceSku[]
  originalSkusObj: IAcceptanceSkuObj
  supplyItems: ISupplyItem[]
  onSubmit: (acceptedItems: ISupplyReceiptItem[], condition: ConditionEnum) => void
  updateOriginalSku: (newItems: IAcceptanceSku[]) => void
}

const MassAcceptModal = (props: ItemRemovingProps) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    originalSkus,
    originalSkusObj,
    supplyItems,
    defectTypes,
    updateOriginalSku,
    loading
  } = props

  const [createBatchSkuId, setCreateBatchSkuId] = useState<string | null>(null)

  const originalSkuForCreateBatch = useMemo(() => {
    if (!createBatchSkuId) {
      return null
    }
    return originalSkusObj[createBatchSkuId]
  }, [createBatchSkuId, originalSkusObj])

  const conditionsItems = [
    { label: 'Годный', id: ConditionEnum.GOOD },
    { label: 'Брак', id: ConditionEnum.DEFECT },
  ]

  const [conditionItem, setConditionItem] = useState<DefaultItem | null>(conditionsItems[0])
  const isBadCondition = conditionItem?.id === ConditionEnum.DEFECT

  const [quantityItemsValues, setQuantityItemsValues] = useState<Record<string, number | undefined>>({})
  const [batchItemsValues, setBatchItemsValues] = useState<Record<string, string | undefined>>({})
  const [defectsItemsValues, setDefectsItemsValues] = useState<Record<string, string | undefined>>({})

  useEffect(() => {
    /** Сбрасываем стейт при переключении */
    setQuantityItemsValues({})
    setBatchItemsValues({})
    setDefectsItemsValues({})
  }, [conditionItem])


  const reduceSupplyItems = () => supplyItems.reduce((acc, sup) => ({ ...acc, [sup.sku_id]: sup.quantity }), {})

  const handleSetGoodQuantity = useCallback((itemId: string, value?: number) => {
    setQuantityItemsValues(prev => ({ ...prev, [itemId]: value }))
  }, [])

  const handleClickGoodApplyAll = useCallback(
    () => setQuantityItemsValues(reduceSupplyItems()), [])

  const resultData: ISupplyReceiptItem[] = useMemo(() =>
    /** Генерируем финальные данные для отправки на сервер */
    supplyItems.reduce((acc, supplyItem) => {
      const key = supplyItem.sku_id
      const quantity = Number(quantityItemsValues[key])
      const batchId = batchItemsValues[key]
      const defectId = defectsItemsValues[key]

      if (quantity || batchId) {
        const item: ISupplyReceiptItem = {
          id: supplyItem.id,
          quantity: Number(quantity),
          serial_numbers: [],
          defect_type_id: defectId,
          sku_batch_id: batchId || null,
          sku_id: supplyItem.sku_id,
        }
        return [...acc, item]
      }
      return acc
    }, [] as ISupplyReceiptItem[]),
    [
      supplyItems,
      quantityItemsValues,
      batchItemsValues,
      defectsItemsValues
    ]
  )

  const handleClickSubmit = () => {
    if (!conditionItem) {
      console.error('Не выбрали состояние товара')
      return
    }
    onSubmit(resultData, conditionItem.id as ConditionEnum)
  }

  const submitBtnDisable = useMemo(() => {
    const itemProblem = resultData.some(item => {
      const origItem = originalSkusObj[item.sku_id]
      const batchProblem = (!item.sku_batch_id && origItem?.batch_accounting) || false
      const quantityProblem = !item.quantity
      return batchProblem || quantityProblem
    })
    const emptyData = !resultData.length
    return emptyData || itemProblem
  }, [originalSkusObj, resultData])

  return (
    <>
      <Modal
        isOpen={isOpen}
        hasOverlay
        title={'Массовая приемка'}
        titleAlign={'left'}
        onOverlayClick={(): boolean => false}
        className={styles.modal}
        size='xl'
      >
        <div className={styles.body}>
          <div className={styles.bodyContent}>
            {
              loading ? (
                <Loader className={styles.loader}/>
              ) : null
            }
            <ComboboxWrapper
              id='condition'
              label='Тест добавления'
              items={conditionsItems}
              value={conditionItem}
              placeholder='Выберите состояние товара'
              onChange={({ value }) => setConditionItem(value)}
            />

            <MassAcceptTable
              defectTypes={defectTypes}
              condition={(conditionItem?.id || ConditionEnum.GOOD) as ConditionEnum}
              theme={isBadCondition ? 'red' : 'blue'}
              key={isBadCondition ? 'bad' : 'good'}
              title={isBadCondition ? 'Брак' : 'Годный товар'}
              tableExtraHeader={
                <Button
                  label={'Вставить все'}
                  view={'primary'}
                  onClick={handleClickGoodApplyAll}
                  size={'m'}
                />
              }
              originalSkusObj={originalSkusObj}
              maxHeightBody={400}
              supplyItems={supplyItems}
              quantityItemsValues={quantityItemsValues}
              batchItemsValues={batchItemsValues}
              defectsItemsValues={defectsItemsValues}
              handleSetQuantity={handleSetGoodQuantity}
              handleSetItemBatch={(skuId, batchId) => {
                setBatchItemsValues(prev => ({
                  ...prev,
                  [skuId]: batchId
                }))
              }}
              handleSetDefect={(skuId, defect) => {
                setDefectsItemsValues(prev => ({
                  ...prev,
                  [skuId]: defect
                }))
              }}
              handleCreateItemBatch={(skuId) => {
                setCreateBatchSkuId(skuId)
              }}
            />
          </div>
          <div className={styles.btns}>
            <Button
              label={'Отменить'}
              view={'ghost'}
              onClick={onClose}
              loading={loading}
              size={'m'}
            />
            <Button
              label={'Принять'}
              view={'primary'}
              disabled={submitBtnDisable}
              onClick={handleClickSubmit}
              loading={loading}
              size={'m'}
            />
          </div>
        </div>
      </Modal>
      {
        originalSkuForCreateBatch ? (
          <SelectBatchModal
            isOpen={true}
            onlyCreate={true}
            expdate={originalSkuForCreateBatch.batch_expdate_mandatory}
            item={originalSkuForCreateBatch}
            onSubmit={(batch, isNewBatch) => {
              // Задаем данные
              setBatchItemsValues(prev => ({
                ...prev,
                [originalSkuForCreateBatch.id]: batch.id
              }))
              // Обновляем все оригинальные товары
              updateOriginalSku(
                originalSkus.map((item: IAcceptanceSku) => ({
                  ...item,
                  batches:
                    item.id === originalSkuForCreateBatch.id
                      ? [...(item.batches || []), batch]
                      : item.batches,
                })) ?? [],
              )
              setCreateBatchSkuId(null)
            }}
            onClose={() => setCreateBatchSkuId(null)}
          />
        ) : null
      }
    </>
  )
}

export default MassAcceptModal
