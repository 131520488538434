import api from '@shared/api'
import { ISticker } from 'src/interfaces'

export interface IQubyExcelParams {
  documentId: string
}

// http://wms-test.kak2c.ru/warehouse-api/transportation/{doc_id}/excel
const getQubyExcel = async (params: IQubyExcelParams) => {
  const result = await api.get(`/transportation/${params.documentId}/excel`)

  if (!result.data?.success) {
    throw result.data
  }

  return {
    content: result.data?.content,
    printer: result.data?.printer || '',
  } as ISticker
}

const useGetExcelQuby = () => ({
  fetch: (params: IQubyExcelParams) => getQubyExcel(params),
})

export default useGetExcelQuby
