import { Modal } from 'src/components'
import React, { useEffect, useState } from 'react'
import styles from './item-sticker-print-2-modal.module.scss'
import StickStickerSvg from './assets/sticker.svg'
import { Timer } from '@shared/ui/Timer'

interface IItemStickerPrint2Props {
  isOpen: boolean
  handleAcceptAndNext: () => void
  timerValue?: number
  title?: string
  subtitle?: string
  btnText?: string
}
const ItemStickerPrint2Modal = (props: IItemStickerPrint2Props) => {
  const {
    isOpen,
    handleAcceptAndNext,
    timerValue = 5,
    title = 'Стикер отправлен на печать',
    subtitle = 'Приклейте стикер на товар',
    btnText = 'Продолжить упаковку'
  } = props

  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size="min"
    >
      <div className={styles.contentWrapper}>
        <img src={StickStickerSvg} />
        <div className={styles.titleWrapper}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.grayText}>{subtitle}</p>
        </div>

        <div>
          <Timer seconds={timerValue} size="m" handleComplete={handleAcceptAndNext} />

          <div className={styles.btns}>
            <p className={styles.continueBtn} onClick={handleAcceptAndNext}>
              {btnText}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ItemStickerPrint2Modal
