import { useMutation } from 'react-query'
import api from '@shared/api'

export interface IProps {
  doc_id: string
  item_id: string
  quantity: number
}
interface IData {
  status: string
}

// http://wms-test.kak2c.ru/warehouse-api/placing/{doc_id}/item/{item_id}
const useChangeQuantityPlacing = () => {
  const changeQuantityPlacing = async ({ doc_id, item_id, quantity }: IProps) => {
    let path = `/placing/${doc_id}/item/${item_id}`

    const result = await api.post(path, {
     quantity: quantity
    })

    if (!result?.data?.success) {
      throw result
    }

    return result.data
  }

  return useMutation(changeQuantityPlacing)
}

export default useChangeQuantityPlacing
