import { useQuery } from 'react-query'

import api from '@shared/api'

import { ApiReqResult, IPlacing, ISupplyReceipt, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'


export interface IGetReceiptsParams {
  search?: string
}

export interface IGetReceiptsResult extends ApiReqResult, PaginationResult {
  placings: IPlacing[]
  receipts: ISupplyReceipt[]
}

const useGetPlacings = (
  paramsObj: IGetReceiptsParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IGetReceiptsResult>(
    [`getPlacings`, paramsObj],
    async () => {
      const searchParams = convertObjectToURLParams(paramsObj)
      const result = await api.get(`/placing${searchParams}`)
      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}

export default useGetPlacings
