import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


interface ResultData extends ApiReqResult {
  content: string
  printer: string
  files: {
    content: string
    printer: string
  }[]
}
type ReqResult = { data: ResultData }
const useShipmentPlanDocsSticker = (
  options?: UseMutationOptions<ReqResult,any, any>
) => {
  return useMutation<ReqResult, any, any>(
    async (shipmentPlans: string[]) => {
      return api.post(`/shipment-plan/docs-for-print`, shipmentPlans)
    },
    options,
  )
}

export default useShipmentPlanDocsSticker

