import api from '@shared/api'
import { IOrderBatch } from '../../interfaces'

interface IProps {
  id: string
}

const editOrderBatch = async (params: IProps) => {
  const {id} = params;

  const result = await api.post(`/order/${id}/edit-with-related-docs`, {
    remove_from_batch: true
  })

  if (!result.data?.success) {
    throw result.data
  }
  return result.data.batch
}

const useEditOrderBatch = () => ({
  fetch: async (params: IProps): Promise<IOrderBatch> => await editOrderBatch(params),
})

export default useEditOrderBatch
