import React, { FC, useEffect, useMemo, useState } from 'react'
import { IShippingFormItem } from 'src/interfaces'
import styles from './modal-address.module.scss'
import { CalendarInput, InputWrapper, TableWithCopy } from 'src/components'
import { Button } from '@consta/uikit/Button'
import { updateFormStateDraftAction } from '../../../../../store/actions'
import { useOrderFormContext } from '../../../../../context/OrderFormContext'
import { IconCalendar } from '@consta/icons/IconCalendar'
import { DATE_FORMAT, DATETIME_REQUEST_FORMAT, TIME_FORMAT } from '@shared/const/date'
import dayjs from 'dayjs'
import { generateColumns, generateRows } from './utils'
import { ComboboxDeliveryContracts } from '@entities/DeliveryContract'

interface IProps {
  onClose: () => void
  onSubmit: () => void
}
export interface IShippingForm extends IShippingFormItem {
  editMode: boolean
}

const ModalAddress: FC<IProps> = (props) => {
  const { state, dispatch } = useOrderFormContext()
  const { onClose, onSubmit } = props
  const [shippingForm, setShippingForm] = useState<IShippingForm[]>([])

  useEffect(() => {
    if (!state?.formStateDraft?.shipping_form) return
    setShippingForm(
      state?.formStateDraft?.shipping_form.map((el) => ({
        ...el,
        editMode: false,
      })),
    )
  }, [state?.formStateDraft?.shipping_form])

  const handleSaveData = () => {
    onSubmit()
    onClose()
  }
  const handleChange = (id: string, value: string) => {
    const result = shippingForm.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          value: value,
        }
      } else {
        return el
      }
    })

    dispatch(updateFormStateDraftAction('shipping_form', result))
  }

  const columns = useMemo(() => generateColumns(), [])

  const rows = useMemo(
    () => generateRows(shippingForm, handleChange),
    [shippingForm],
  )

  return (
    <>
      <h2>Редактировать</h2>
      <div className={styles.fieldsWrapper}>
        <InputWrapper
          className={styles.fieldType50}
          id={'region'}
          label={'Область'}
          value={state.formStateDraft?.region}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('region', value))
          }
        />
        <InputWrapper
          className={styles.fieldType50}
          id={'area'}
          label={'Район в области'}
          value={state.formStateDraft?.area}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('area', value))
          }
        />
        <InputWrapper
          className={styles.fieldType25}
          id={'settlement'}
          label={'Населенный пункт'}
          value={state.formStateDraft?.settlement}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('settlement', value))
          }
        />
        <InputWrapper
          className={styles.fieldType25}
          id={'city'}
          label={'Город'}
          value={state.formStateDraft?.city}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('city', value))
          }
        />
        <InputWrapper
          className={styles.fieldType25}
          id={'street'}
          label={'Улица'}
          value={state.formStateDraft?.street}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('street', value))
          }
        />
        <InputWrapper
          className={styles.fieldType25}
          id={'block'}
          label={'Корпус'}
          value={state.formStateDraft?.block}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('block', value))
          }
        />
        <InputWrapper
          className={styles.fieldType15}
          id={'building'}
          label={'Строение'}
          value={state.formStateDraft?.building}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('building', value))
          }
        />
        <InputWrapper
          className={styles.fieldType15}
          id={'house'}
          label={'Дом'}
          value={state.formStateDraft?.house}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('house', value))
          }
        />
        <InputWrapper
          className={styles.fieldType15}
          id={'flat'}
          label={'Квартира'}
          value={state.formStateDraft?.flat}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('flat', value))
          }
        />
        <InputWrapper
          className={styles.fieldType15}
          id={'floor'}
          label={'Этаж'}
          isNumber
          // @ts-ignore
          value={state.formStateDraft?.floor || 1}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('floor', value))
          }
        />
        <InputWrapper
          className={styles.fieldType25}
          id={'zip'}
          label={'Индекс'}
          value={state.formStateDraft?.zip || ''}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('zip', value))
          }
        />
        <ComboboxDeliveryContracts
          wrapperClassName={styles.fieldType25}
          value={state.formStateDraft?.delivery_contract}
          merchantId={state.documentRaw?.merchant?.id}
          onChange={(value) => {
            dispatch(updateFormStateDraftAction('delivery_contract', value))
          }}
        />
        <CalendarInput
          label="Плановая дата отгрузки"
          id={'shipping_date_plan'}
          handleChange={(value) => {
            if (!value) return
            dispatch(
              updateFormStateDraftAction(
                'shipping_date_plan',
                dayjs(value).format(DATETIME_REQUEST_FORMAT) || '',
              ),
            )
          }}
          type={'date-time'}
          value={new Date(state.formStateDraft?.shipping_date_plan || '')}
          className={styles.dateInput}
          rightSide={IconCalendar}
          backspaceReset
        />
        <CalendarInput
          label="Плановая дата доставки"
          id={'delivery_date_plan'}
          handleChange={(value) => {
            if (!value) return
            dispatch(
              updateFormStateDraftAction(
                'delivery_date_plan',
                dayjs(value).format(DATE_FORMAT) || '',
              ),
            )
          }}
          type={'date'}
          value={
            state.formStateDraft?.delivery_date_plan
              ? new Date(state.formStateDraft?.delivery_date_plan || '')
              : null
          }
          className={styles.dateInput}
          rightSide={IconCalendar}
          backspaceReset
        />
        <CalendarInput
          label="Плановое время доставки, c"
          id={'delivery_time_plan_from'}
          handleChange={(value) => {
            if (!value) return

            dispatch(
              updateFormStateDraftAction(
                'delivery_time_plan_from',
                dayjs(value).format(TIME_FORMAT) || '',
              ),
            )
          }}
          type={'time'}
          value={
            !state.formStateDraft?.delivery_time_plan_from
              ? null
              : new Date(
                  `2023-11-02T${
                    state.formStateDraft?.delivery_time_plan_from || ''
                  }`,
                )
          }
          className={styles.dateInput}
          rightSide={IconCalendar}
          backspaceReset
        />
        <CalendarInput
          label="Плановое время доставки, по"
          id={'delivery_time_plan_to'}
          handleChange={(value) => {
            if (!value) return
            dispatch(
              updateFormStateDraftAction(
                'delivery_time_plan_to',
                dayjs(value).format(TIME_FORMAT) || '',
              ),
            )
          }}
          type={'time'}
          value={
            !state.formStateDraft?.delivery_time_plan_to
              ? null
              : new Date(
                  `2023-11-02T${
                    state.formStateDraft?.delivery_time_plan_to || ''
                  }`,
                )
          }
          className={styles.dateInput}
          rightSide={IconCalendar}
          backspaceReset
        />
        <InputWrapper
          className={styles.fieldType25}
          id={'postamat_num'}
          label={'Номер ПВЗ'}
          value={state.formStateDraft?.postamat_num || ''}
          handleChange={(value) =>
            dispatch(updateFormStateDraftAction('postamat_num', String(value)))
          }
        />
      </div>
      <TableWithCopy
        rows={rows}
        columns={columns}
        title={'Анкета к отгрузке'}
      />
      <div className={styles.buttonWrapper}>
        <Button
          label={'Отмена'}
          view={'ghost'}
          type="button"
          onClick={onClose}
        />
        <Button
          label="Сохранить"
          view="primary"
          type="button"
          onClick={handleSaveData}
        />
      </div>
    </>
  )
}

export default ModalAddress
