import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, IUploadFile } from 'src/interfaces'

/*
http://wms-test.kak2c.ru/warehouse-api/receiving/supply/{supply_id}/file
*/

interface IParams {
  supplyId: string
}
export enum FileType {
  document = 'DOCUMENT',
  photo = 'PHOTO',
}

interface IFileResult {
  n: number
  id: string
  success: boolean
}
interface IResult extends ApiReqResult {
  results: IFileResult[]
}

const useAddFilesToSupplyPlan = (
  params: IParams,
  callback?: (results: IFileResult[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useMutation(
    async (data: IUploadFile[]) =>
      api.post(`/receiving/supply/${params.supplyId}/file`, data),
    {
      onSuccess: ({ data }: { data: IResult }) => {
        if (data?.success) {
          callback?.(data?.results)
        } else {
          error?.()
        }
      },
      onError: error,
      onSettled: settled,
    },
  )
}

export default useAddFilesToSupplyPlan
