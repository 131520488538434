import { useQuery } from 'react-query'
import api from '@shared/api'
import { ApiReqResult, PaginationResult, Service, ServiceGroup } from 'src/interfaces'
import { convertObjectToURLParams } from '@shared/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'

export interface IGetServicesParams {
  search?: string
  group_id?: string
  is_material?: boolean
}
export interface IGetServicesResult extends ApiReqResult, PaginationResult {
  groups: ServiceGroup[]
  services: Service[]
}
const useGetBillingServices = (
  paramsObj: IGetServicesParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IGetServicesResult>(
    [`getBillingServices`, paramsObj],
    async () => {
      const searchParams = convertObjectToURLParams(paramsObj)
      const result = await api.get(`/billing/service${searchParams}`)

      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}
export default useGetBillingServices
