import React, { useState } from 'react'

import styles from './select-defect-modal.module.scss'

import { Select } from '@/components'

import { ISelectOption, ModalProps } from '@/interfaces'
import { useRefundAcceptanceContext } from '../../../context/RefundAcceptanceContext'
import PhotosModal from '../PhotosModal/PhotosModal'

interface ISelectDefectModal extends ModalProps {
  onSubmit: (defectTypeId: string, photos: string[]) => void
}

const SelectDefectModal = (props: ISelectDefectModal) => {
  const { isOpen, onSubmit, onClose } = props
  const { state, dispatch } = useRefundAcceptanceContext()
  const [selectedDefect, setSelectedDefect] = useState<ISelectOption | null>(
    null,
  )

  const handleSubmit = (photos: string[]) => {
    if (!selectedDefect) {
      return
    }
    onSubmit(String(selectedDefect.value), photos)
  }

  const defectTypes =
    state.defectTypes.map((d) => ({ label: d.title, value: d.id })) || []

  return (
    <PhotosModal
      isOpen={isOpen}
      onSubmit={(photos) => handleSubmit(photos)}
      onClose={onClose}
      title="Выберите и загрузите причину брака"
      header="Выберите причину брака, сфотографируйте товар и загрузите 3 фото"
      submitDisabled={!selectedDefect}
    >
      <div className={styles.bodyWrp}>
        <Select
          label="Вид брака"
          options={defectTypes}
          value={selectedDefect}
          onChange={({ value }) => setSelectedDefect(value)}
          size="m"
          isRequired
          status="alert"
          className={styles.select}
        />
      </div>
    </PhotosModal>
  )
}

export default SelectDefectModal
