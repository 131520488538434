import { useMutation } from 'react-query'
import api from '@shared/api'
import { UseMutationOptions } from 'react-query/types/react/types'
import { ApiReqResult } from '../../interfaces'
import { provideMock } from '../../mocks'

interface IfbsOrderBatchParams {
  batchId: string
}

const getMethod = async (params: IfbsOrderBatchParams) => {
  const result = await provideMock<IfbsOrderBatchParams, { data: ApiReqResult }>(
    async () => await api.post(`/packing/order-batch/${params.batchId}/packed`),
    params,
    {
      key: 'batchId',
      value: 'mock',
      result: { data: { success: true } },
      logData: 'Завершить упаковку батча'
    },
  )()
  return result.data
}

const useUpdateToPackedFBSOrderBatch = (
  params: IfbsOrderBatchParams,
  options?: UseMutationOptions<any, ApiReqResult, any>,
) => {
  return useMutation(
    async () => getMethod(params),
    options,
  )
}

export default useUpdateToPackedFBSOrderBatch
