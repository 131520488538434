import { useMutation } from 'react-query'

import api from '@shared/api'

/*
 
 *http://wms-test.kak2c.ru/warehouse-api/inventory/{inventory_id}/fact/{fact_id}
 */

export interface IProps {
  inventory_id: string
  fact_id: string
}

const useDeleteInventoryFact = () => {
  const deleteInventoryFact = async (props: IProps) => {
    const result = await api.delete(
      `/inventory/${props.inventory_id}/fact/${props.fact_id}`,
    )

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(deleteInventoryFact)
}

export default useDeleteInventoryFact
