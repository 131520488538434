import { useMutation } from 'react-query'
import api from '@shared/api'
import { ApiReqResult } from 'src/interfaces'

interface Result extends ApiReqResult {
  id: string
}

export interface IProps {
  inventory_id: string
  hook: boolean
}

//http://wms-test.kak2c.ru/warehouse-api/inventory/{inventory_id}/start
//http://wms-test.kak2c.ru/warehouse-api/inventory/{inventory_id}/complete

const useStartInventory = () => {
  return useMutation(async ({ inventory_id, hook }: IProps) => {
    const path = `/inventory/${inventory_id}/${hook ? 'start' : 'complete'}`
    const result = await api.post(path)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}

export default useStartInventory

// const useStartInventory = () => {
//   const startDoneInventory = async (props: IProps) => {
//     const result = await api.post(
//       `/inventory/${props.inventory_id}/${props.hook ? 'start' : 'complete'}`,
//     )

//     if (result.status === 200) {
//       return result
//     }
//   }

//   return useMutation(startDoneInventory)
// }

// export default useStartInventory
