import React, { createContext, useContext, useReducer } from 'react'
import { IReducerAction } from 'src/interfaces'
import { mainInitialState, mainReducer } from '../store/reducer'
import { IMainState } from '../store/interfaces'

export interface IUseReducer {
  state: IMainState
  dispatch: React.Dispatch<IReducerAction>
}

interface IContext extends IUseReducer {

}

export const MainContext = createContext<IContext>({
  state: mainInitialState,
  dispatch: (action: IReducerAction) => action,
})

export const MainContextProvider = (
  props: React.PropsWithChildren<{}>,
) => {
  const { children } = props

  const [state, dispatch] = useReducer<
    React.Reducer<typeof mainInitialState, IReducerAction>>(mainReducer, mainInitialState)

  return (
    <MainContext.Provider value={{ state, dispatch }}>
      {children}
    </MainContext.Provider>
  )
}

export const useMainContext = () => useContext(MainContext)
