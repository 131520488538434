import React, { useMemo, useState } from 'react'
import styles from './DocumentsParamsField.module.scss'
import { Button, InputWrapper } from 'src/components'
import { generateColumns, generateRows } from './utils'
import { ConfirmModal, DocumentsParams } from '../../../../../components'
import { useQubyFormContext } from '@pages/OperationsAndDocumentsPage/context/CarryerQubyContext'
import days from 'dayjs'
import { OperAndDocumPagesType } from '@pages/OperationsAndDocumentsPage'
import { useParams } from 'react-router-dom'
import { useGetTransportationTemplatesList } from 'src/hooks/trasportationTemplates'
import QubyModal from '../QubyModal/QubyModal'
import { useDeleteQuby, useGetCarryerQuby, useGetExcelQuby } from 'src/hooks/carryerQuby'
import { useGetQueryOrderList, useGetQueryTransportPlaces } from 'src/hooks'
import { saveBase64File } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'

interface QubyFormContentProps {}

const DocumentsParamsFields = (props: QubyFormContentProps) => {
  const { page, id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
    tabId: string
  }>()
  const { state } = useQubyFormContext()
  const [openModal, setOpenModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [itemId, setItemId] = useState<string | null>(null)
  const {
    isFetching: transportationLoading,
    data: transportationData,
    refetch: refetchTransportation,
  } = useGetTransportationTemplatesList({})
  const { data: transportData } = useGetQueryTransportPlaces({})
  const { data: orderData } = useGetQueryOrderList({})

  const { fetch: getQubyExcel } = useGetExcelQuby()
  const {
    data: qubyData,
    isFetching: qubyLoading,
    refetch: refetchQuby,
  } = useGetCarryerQuby({
    docId: documentId,
  })
  const { mutateAsync: deleteDoc } = useDeleteQuby()

  const handleDelete = (itemId: string | null) => {
    if (!itemId) {
      return
    }
    deleteDoc({ documentId, itemId })
      .then((data) => {
        refetchQuby()
      })
      .catch(() => {})
  }
  const handleEdit = (itemId: string) => {
    setItemId(itemId)
    setOpenModal(true)
  }
  const handleConfirmModal = (itemId: string) => {
    setOpenConfirmModal(true)
    setItemId(itemId)
  }

  const getQubyExcelDoc = async () => {
    const res = await getQubyExcel({
      documentId: documentId,
    })
    if (res) {
      saveBase64File(res.content)
    }
  }

  const rows = useMemo(
    () =>
      generateRows(
        state.documentRaw?.items || [],
        transportationData?.templates || [],
      ),
    [state.documentRaw?.items, transportationData?.templates],
  )

  const columns = useMemo(
    () => generateColumns({ handleEdit, handleConfirmModal }),
    [],
  )

  return (
    <>
      <DocumentsParams
        rows={rows}
        columns={columns}
        tableExtraHeader={
          <div className={styles.buttonWrapper}>
            <Button
              label={'Выгрузка перевозки в Excel'}
              view="secondary"
              onClick={getQubyExcelDoc}
            />
            <Button
              label={'Добавить позицию перевозки'}
              onClick={() => {
                setItemId(null)
                setOpenModal(true)
              }}
            />
          </div>
        }
      >
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.id || null}
          id={'id'}
          label={'Номер'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={days(state.documentRaw?.create_ts || null).format(DATE_TIME_VIEW)}
          id={'create_ts'}
          label={'Дата'}
          disabled
        />

        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.merchant?.title || null}
          id={'org'}
          label={'Организация'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.executor?.name || null}
          id={'executor'}
          label={'Ответственный'}
          disabled
        />
        <InputWrapper
          className={styles.fieldType25}
          value={state.documentRaw?.comment || null}
          id={'comment'}
          label={'Комментарий'}
          disabled
        />
      </DocumentsParams>
      {openModal ? (
        <QubyModal
          onClose={() => setOpenModal(false)}
          isOpen={openModal}
          itemId={itemId}
          items={state.documentRaw?.items || []}
          docId={documentId}
          refetch={refetchQuby}
          templates={transportationData?.templates || []}
          transportPlaces={transportData?.pallets || []}
          orders={orderData?.orders || []}
        />
      ) : null}
      {openConfirmModal ? (
        <ConfirmModal
          isOpen={openConfirmModal}
          onClose={() => {
            setItemId(null)
            setOpenConfirmModal(false)
          }}
          onSubmit={() => {
            handleDelete(itemId)
            setOpenConfirmModal(false)
            setItemId(null)
          }}
        />
      ) : null}
    </>
  )
}
export default DocumentsParamsFields
