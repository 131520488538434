import { useMutation } from 'react-query'

import api from '@shared/api'

import { ApiReqResult } from 'src/interfaces'

/*
 * Создание/редактирование услуги
 * http://wms-test.kak2c.ru/warehouse-api/billing/service/{service_id}
 */

interface Result extends ApiReqResult {
  id: string
}

interface CreateUpdateBillingServiceParams {
  docId?: string | undefined
  data: IData
}

interface IData {
  active: boolean
  code: number
  group_id: string
  is_material?: boolean
  measure?: string
  title: string
}
// http://wms-test.kak2c.ru/warehouse-api/billing/service/{service_id}

const useCreateUpdateBillingService = () => {
  return useMutation(
    async ({ docId, data }: CreateUpdateBillingServiceParams) => {
      const path = `/billing/service${docId ? `/${docId}` : ''}`
      const result = await api.post(path, data)

      if (!result?.data?.success) {
        throw result
      }
      return result.data as Result
    },
  )
}

export default useCreateUpdateBillingService
