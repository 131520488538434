import { IOrderBatch } from 'src/interfaces'
import { IBatchFormDataState } from '../interfaces'


export const SET_BATCH_RAW = 'SET_BATCH_RAW'
export const setBatchRawAction = (documentRaw: IOrderBatch | null) => {
  return {
    type: SET_BATCH_RAW,
    payload: documentRaw,
  }
}

export const SET_BATCH_FORM_STATE_DRAFT = 'SET_BATCH_FORM_STATE_DRAFT'
export const setBatchFormDraftAction = (formState: IBatchFormDataState) => {
  return {
    type: SET_BATCH_FORM_STATE_DRAFT,
    payload: formState,
  }
}
export const SET_BATCH_FORM_STATE_RAW = 'SET_BATCH_FORM_STATE_RAW'
export const setBatchFormRawAction = (formState: IBatchFormDataState) => {
  return {
    type: SET_BATCH_FORM_STATE_RAW,
    payload: formState,
  }
}

