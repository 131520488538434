import { createContext } from 'react'
import { DocumentsType, IMenuList } from '@shared/ui/NavigationMenu/routes'
import { ICurrentUser, IDatabaseSelectOption, IInformer } from '@/interfaces'
import packageInfo from '../../../../../../package.json'

export const AppContext = createContext({
  documentsTypeList: [] as DocumentsType[],
  mainMenuList: [] as IMenuList[],
  globalLoading: true,
  removeAdvanceModeIsLoading: false,
  setGlobalLoading: (globalLoading: boolean) => void globalLoading as void,
  authorized: false,
  setAuthorized: (authorized: boolean) => void authorized as void,
  currentUser: {} as ICurrentUser,
  setCurrentUser: (currentUser: ICurrentUser) => void currentUser as void,
  advanceMode: false,
  setAdvanceMode: (value: boolean) => void value as void,
  removeAdvanceMode: () => void false as void,
  requestAdvanceMode: () => void false as void,
  showAdvanceModeModal: false as boolean,
  performWithAdvance: (action: () => () => void) => void action as void,
  appVersion: packageInfo.version,
  setAppVersion: (value: string) => void value as void,
  resetAfkInterval: () => void false as void,

  databases: [] as IDatabaseSelectOption[],
  setDatabases: (databases: IDatabaseSelectOption[]) => void databases as void,

  logout: () => void false as void,

  informer: null as IInformer | null,
  setInformer: (informer: IInformer | null) => void informer as void
})