import { useQuery } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, IRegistryListItem } from 'src/interfaces'

const getRegistriesHistory = async () => {
  const result = await api.get(`/getregistry/registry/history`)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data

  // return {
  //   success: true,
  //   objects: [
  //     {
  //       number: 'mock',
  //       status: 'CheckOut', //OnСheck -  на проверке, CheckOut - проверен
  //       datereg: '2021-07-15T14:23:11',
  //     },
  //     {
  //       number: 'TS0000160',
  //       status: 'OnСheck',
  //       datereg: '2021-07-15T14:23:11',
  //     },
  //     {
  //       number: 'KA0000249',
  //       status: 'OnСheck',
  //       datereg: '2021-07-15T14:23:11',
  //     },
  //     {
  //       number: 'NI0000236',
  //       status: 'OnСheck',
  //       datereg: '2021-07-15T14:23:11',
  //     },
  //   ],
  // }
}

interface GetRegistriesHistoryResult extends ApiReqResult {
  objects: IRegistryListItem[]
}

const useGetRegistriesHistory = (
  callback?: (objects: IRegistryListItem[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<GetRegistriesHistoryResult>(
    [`getRegistriesHistory`],
    () => getRegistriesHistory(),
    {
      keepPreviousData: true,
      onSuccess: ({ success, objects }) => {
        if (success) {
          if (callback) {
            callback(objects)
          }
        } else {
          if (error) {
            error()
          }
        }
      },
      onError: () => {
        if (error) {
          error()
        }
      },
      onSettled: () => {
        if (settled) {
          settled()
        }
      },
    },
  ) as { data: GetRegistriesHistoryResult }
}

export default useGetRegistriesHistory
