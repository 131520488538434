import React from 'react'
import { Modal } from '@/components'
import styles from './SupplyFormModal.module.scss'
import { ModalProps, SupplyFormItem } from '@/interfaces'

interface IProps extends ModalProps {
  form: SupplyFormItem[]
}

const SupplyFormModal = (props: IProps) => {
  const {
    form,
    onClose,
  } = props

  return (
    <Modal
      withClose
      hasOverlay
      title="Анкета поставки"
      subtitle=" "
      size="s"
      onOverlayClick={onClose}
      onClose={onClose}
      isOpen={true}
      className={styles.modal}
    >
      <div className={styles.itemsWrapper}>
        {
          form.map((formItem) => (
            <div className={styles.item} key={formItem.id}>
              <p>{formItem.title}</p>
              <p>{formItem.value}</p>
              {formItem.comment ? <span>Комментарий: {formItem.comment}</span> : null}
            </div>
          ))
        }
      </div>
    </Modal>
  )
}

export default SupplyFormModal
