import styles from './NomenclatureNameCell.module.scss'
import React, { FC } from 'react'
import cx from 'classnames'
import { generatePath, Link } from 'react-router-dom'
import { PATHS } from '@shared/routing'
import { SECTIONS, tabs } from '@pages/DirectoriesPage/constants'


export const NomenclatureNameCell = ({ className, title, article }: { title: string, article: string, className?: string }) => {
  return (
    <div className={cx(styles.nomenclature, className)}>
      <p>{title}</p>
      <span className={styles.lightTxt}>{article}</span>
    </div>
  )
}

interface NomenclatureCellProps {
  title: React.ReactNode
  itemId: string
  withNewTab?: boolean
}

export const NomenclatureLinkCell: FC<NomenclatureCellProps> = ({
  title,
  itemId,
  withNewTab
}) => {
  const linkPath = generatePath(PATHS.DIRECTORIES, {
    page: tabs.guides.value,
    section: SECTIONS.nomenclature.value,
    type: 'item',
    id: itemId || '-',
  })
  return (
    <Link
      to={linkPath}
      onClick={(e) => {
        if (withNewTab) {
          e.preventDefault();
          window.open(linkPath, "_blank")?.focus();
        }
      }}
    >
      {title}
    </Link>
  )
}
