import React, { useEffect, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { PATHS } from '@shared/routing'
import days from 'dayjs'

import { formStatesAreEqual } from '../../../store/utils'
import { revertDraftStateToRawAction } from '../../../store/actions'
import { IOrderFormDataState } from '../../../store/interfaces/order'

import { PickingFormContextProvider, usePickingFormContext } from '../../../context/PickingFormContext'

import { IBatchFormDataState, IPickingDoc } from '@pages/OperationsAndDocumentsPage/store/interfaces'

import {
  setPickingFormDraftAction,
  setPickingRawAction,
} from '@pages/OperationsAndDocumentsPage/store/actions/picking'

import { cloneDeep } from '@shared/helpers'
import { DATE_TIME_VIEW } from '@shared/const/date'

import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import { FormWrapper } from '../../../components'
import { PickingFormContent } from './components'

import { useGetPickingById } from 'src/hooks'


const PickingForm = () => {
  const history = useHistory()

  const { state, dispatch } = usePickingFormContext()

  const { id: documentId } = useParams<{ page: OperAndDocumPagesType, id: string }>()

  const [loading, setLoading] = useState<boolean>(false)
  const [pickingDoc, setPickingDoc] = useState<IPickingDoc | null>(null)

  const { fetch: fetchPickingById} = useGetPickingById()

  const fetchDocument = () => {
    setLoading(true)

    fetchPickingById(documentId)
      .then((data) => {
        dispatch(setPickingRawAction(data))
        setPickingDoc(data)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchDocument()
  }, [])

  useEffect(() => {
    if (!pickingDoc) {
      return
    }

    const formData: IBatchFormDataState = {
      status: pickingDoc?.picking.status || '',
      comment: pickingDoc?.picking.warehouse_comment || '',
    }

    dispatch(setPickingFormDraftAction(cloneDeep(formData)))
    dispatch(setPickingRawAction(cloneDeep(pickingDoc)))
  }, [pickingDoc])


  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
      page: OperAndDocumPages.pickingList,
    }))
  }



  const statesAreEqual = formStatesAreEqual<IOrderFormDataState | null>(state.formStateDraft, state.formStateRaw)
  return (
    <FormWrapper
      loading={loading}
      title={state.documentRaw?.picking.id || documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw.picking.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <PickingFormContent refetch={fetchDocument} />
    </FormWrapper>
  )
}

const SelectionFormWithContext = () => (
  <PickingFormContextProvider>
    <PickingForm />
  </PickingFormContextProvider>
)

export default SelectionFormWithContext
