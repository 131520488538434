import React, { useEffect } from 'react'
import cx from 'classnames'

import styles from './sidebar.module.scss'

import { useAppContext } from '@shared/providers/AppContextProvider'
import { Badge } from '@consta/uikit/Badge'

import { AdvanceModeLock, AppVersion, UserBlock } from 'src/components'

interface ISideBar {
  children: React.ReactNode
  customHeader?: React.ReactNode
  bottomBtns?: React.ReactNode
  className?: string
  btnsClass?: string
  withAppVersion?: boolean
  withAdvance?: boolean
  withResetAdvancedMode?: boolean
  badge?: React.ReactNode
}

const SideBar = (props: ISideBar) => {
  const {
    withAppVersion = false,
    children,
    className,
    customHeader,
    bottomBtns,
    btnsClass,
    withAdvance = true,
    withResetAdvancedMode = true,
    badge,
  } = props

  const { advanceMode, removeAdvanceMode } = useAppContext()

  useEffect(() => {
    // От склада поступил запрос на отключение расширенного режима при входе в форму арма
    // так как отсутствие явного включение расширенного режима ведет к ошибочным действиям пользователей
    // **Todo вынести сброс на верхних уровень, чтобы он не был привязан к сайдбару**
    if (withResetAdvancedMode) {
      removeAdvanceMode()
    }
  }, [])

  return (
    <div className={cx(styles.leftPanel, className)}>
      <div>
        {customHeader ? (
          customHeader
        ) : (
          <div className={styles.top}>
            <UserBlock view="ghost" size="l" />
            <div className={styles.topRight}>
              {withAdvance ? (
                <>
                  {advanceMode ? (
                    <Badge
                      form="round"
                      status="normal"
                      label="Расширенный"
                      className={styles.badge}
                    />
                  ) : null}

                  <AdvanceModeLock className={styles.lock} />
                </>
              ) : null}
              {badge}
            </div>
          </div>
        )}

        {children}
      </div>

      <div className={cx(styles.leftPanelBtn, btnsClass)}>
        {bottomBtns}
        {withAppVersion ? <AppVersion className={styles.appVersion} /> : null}
      </div>
    </div>
  )
}

export default SideBar
