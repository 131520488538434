import { IAcceptanceFormState } from './interfaces'
import {
  ADD_ITEM_TO_TARE,
  CLOSE_TARE,
  DELETE_ITEM_FROM_TARE,
  DELETE_TARE,
  OPEN_TARE,
  SET_ACCEPTANCE_OPTION,
  SET_ADDING_SUPPLY_ITEM,
  SET_ADDITIONAL_STICKER_COUNT,
  SET_DEFECT_TYPES,
  SET_INDICATE_ITEM_COUNT,
  SET_INITIAL_SUPPLY_ITEMS,
  SET_OPEN_TARE_ID,
  SET_ORIGINAL_SKUS,
  SET_ORIGINAL_SKUS_OBJ,
  SET_PREVIEW_PRINT,
  SET_PRINT_LIST,
  SET_REMOVING_ITEM_BARCODE,
  SET_SHOW_ALL_TARES,
  SET_SHOW_CONFIRM_COMPLETE_MODAL,
  SET_SHOW_ONLY_DEFECTIVE_MODAL,
  SET_SHOW_ONLY_GOOD_MODAL,
  SET_SHOW_SELECT_CONDITION_MODAL,
  SET_STICKER,
  SET_SUPPLY_CONDITION_TYPE,
  SET_SUPPLY_ITEMS,
  SET_SUPPLY_RAW,
  SET_TARES,
  SET_USED_SERIAL_NUMBERS,
  SET_USERNAME,
  SET_WEIGHT_AND_DIMENSION,
  SHOW_DELETE_CONFIRM_MODAL,
  SHOW_DISCREPANCIES_MODAL,
  SHOW_INFO_DRAWER_MODAL,
  SHOW_MASS_ACCEPTANCE_MODAL,
  SHOW_MASS_ITEM_PRINTING_MODAL,
  SHOW_MULTIPLE_COPIES_PRINTING_MODAL,
  SHOW_SELECT_DELETION_ITEM_MODAL,
  SHOW_SETTINGS_DRAWER_MODAL,
  SHOW_SUPPLY_FORM_MODAL,
  SHOW_TSD_UNPROCESS_MODAL,
  UPDATE_TARE,
} from './actions'
import { IReducerAction, ISerialNumber, PrintTypeEnum, TareStatusEnum, TrustBoxObj } from '@/interfaces'
import {
  checkTareItemBySerial,
  getSupplyItemsWithoutTareItems,
  getTareById,
  getTrustBoxesObj,
  getUpdatedSupplyItemsFromDeletedTareItems,
} from './utils'
import { cloneDeep } from '@shared/helpers'
import { ACCEPTANCE_TYPES } from '../utils/const'

export const initialState: IAcceptanceFormState = {
  username: '',
  supplyItems: [],
  initialSupplyItems: [],
  originalSkus: [],
  originalSkusObj: {},
  tares: [],
  supplyRaw: null,
  openTareId: null,

  acceptanceOption: ACCEPTANCE_TYPES[0],

  savedItemsWeightAndDimensions: [],

  addingSkuItem: null,
  removingItemBarcode: null,

  indicateItemCount: false,
  showAllTares: false,

  sticker: null,

  alreadyUsedSerialNumbers: [],

  showSelectDeletionItemModal: false,

  showDeleteConfirm: {
    visible: false,
    tareId: null,
  },

  showCompleteConfirm: false,
  acceptanceCondition: null,

  showSelectConditionModal: null,
  showDiscrepancies: false,
  showSearchItems: false,
  showPrintError: false,
  showOnlyGoodWarning: false,
  showOnlyDefectiveWarning: false,
  showMassItemPrintingModal: false,
  showMassAcceptanceModal: false,
  showTSDUnProcessModal: false,
  showSupplyFormModal: false,
  showSettingsDrawer: false,
  showInfoDrawer: false,
  showMultipleCopiesPrintingModal: null,
  additionalStickerCount: null,

  printType: PrintTypeEnum.print,
  printList: [],
  defectTypes: []
}

export const reducer = (
  state: IAcceptanceFormState,
  action: IReducerAction,
) => {
  const { type, payload } = action

  switch (type) {
    case SET_USERNAME:
      return { ...state, username: payload }

    case SET_SUPPLY_CONDITION_TYPE:
      return { ...state, acceptanceCondition: payload }
    case SET_SHOW_SELECT_CONDITION_MODAL:
      return { ...state, showSelectConditionModal: payload }
    case SET_SHOW_CONFIRM_COMPLETE_MODAL:
      return { ...state, showCompleteConfirm: payload }

    case SET_SUPPLY_RAW:
      return { ...state, supplyRaw: payload }

    case SET_INITIAL_SUPPLY_ITEMS:
      return { ...state, initialSupplyItems: payload }

    case SET_SUPPLY_ITEMS:
      return { ...state, supplyItems: payload }

    case SET_REMOVING_ITEM_BARCODE:
      return { ...state, removingItemBarcode: payload }

    case SET_ADDING_SUPPLY_ITEM:
      const addingItem = payload
        ? {
            ...payload,
            scan_serial_numbers: (payload?.scan_serial_numbers ?? []).sort(
              (a: ISerialNumber) => (a.code === 'chestniy_znak' ? -1 : 1),
            ),
          }
        : null
      return { ...state, addingSkuItem: addingItem }

    case SET_OPEN_TARE_ID:
      return { ...state, openTareId: payload }

    case SET_ORIGINAL_SKUS:
      return { ...state, originalSkus: payload }
    case SET_ORIGINAL_SKUS_OBJ:
      return { ...state, originalSkusObj: payload }

    case SET_TARES:
      return { ...state, tares: payload }

    case DELETE_ITEM_FROM_TARE: {
      const tares = cloneDeep(state.tares)
      const tare = getTareById(payload.tareId, tares)

      if (!tare?.items) {
        console.error('Проблема с товарами в таре tareItems пустой')
        return { ...state }
      }

      /** ищем индекс товара по его uuid в таре  */
      const deletedTareItem = tare.items.find(
        (item) => item.id === payload.itemId,
      )

      if (!deletedTareItem) {
        console.error('Проблема с поиском элемента при удалении товара reducer')
        return { ...state }
      }

      if (deletedTareItem.quantity < payload.quantity) {
        /* такого быть не может, так как есть валидация в форме на удаление */
        console.error('Товара в таре меньше, чем пытаемся удалить')
        return { ...state }
      }

      /** Фильтруем кешированные серийные номера */
      const alreadyUsedSerialNumbers = state.alreadyUsedSerialNumbers.filter(
        (usedSerialNumber) =>
          !checkTareItemBySerial(deletedTareItem, usedSerialNumber),
      )

      /** Обновляем данные в таблице ПРИНЯТНО */
      const deleteFullQuantityOfItem =
        deletedTareItem.quantity === payload.quantity
      if (deleteFullQuantityOfItem) {
        tare.items = tare.items.filter(
          (tareItem) => tareItem.id !== deletedTareItem.id,
        )
      } else {
        /* вычитаем из удаляемого товара из тары нужное количество товара */
        deletedTareItem.quantity -= payload.quantity
      }

      /** Обновляем данные в таблице К ОБРАБОТКЕ */
      const supplyItems = getUpdatedSupplyItemsFromDeletedTareItems(
        state.initialSupplyItems || [],
        state.supplyItems || [],
        [{ ...deletedTareItem, quantity: payload.quantity }], // удаляем конкретное количество
      )

      return {
        ...state,
        tares,
        supplyItems,
        alreadyUsedSerialNumbers,
      }
    }

    /** TARE */
    case OPEN_TARE: {
      const tares = [...state.tares]
      const currentTare = tares.find(tare => tare.id === payload)
      if (currentTare) {
        currentTare.status = TareStatusEnum.open
      }
      return {
        ...state,
        tares,
        openTareId: payload
      }
    }
    case CLOSE_TARE: {
      const tares = [...state.tares]
      const currentTare = getTareById(payload, tares)
      if (currentTare) {
        currentTare.status = TareStatusEnum.closed
      }
      return {
        ...state,
        tares,
      }
    }
    case DELETE_TARE: {
      const deletingTare = state.tares.find((tare) => tare.id === payload)
      const tares = state.tares.filter((tare) => tare.id !== payload)
      const tareItems = deletingTare?.items || []
      const supplyItems = getUpdatedSupplyItemsFromDeletedTareItems(
        state.initialSupplyItems || [],
        state.supplyItems,
        tareItems,
      )

      /** Фильтруем кешированные серийные номера */
      const alreadyUsedSerialNumbers = state.alreadyUsedSerialNumbers.filter(
        (serialNumberValue) =>
          tareItems.find(
            (tareItem) => !checkTareItemBySerial(tareItem, serialNumberValue),
          ),
      )
      return {
        ...state,
        tares,
        supplyItems,
        alreadyUsedSerialNumbers,
      }
    }
    case ADD_ITEM_TO_TARE: {
      const addedItem = payload.item
      /** Обновляем данные в таблице ПРИНЯТНО */
      const tares = cloneDeep(state.tares)
      const tare = getTareById(payload.tareId, tares)
      /* Ищем товар по id товара и по id батча */
      const tareItem = tare?.items?.find(
        (tareItem) =>
          tareItem.id === addedItem.id &&
          (addedItem.sku_batch_id ? tareItem.sku_batch_id === addedItem.sku_batch_id : true)
      )
      if (tareItem) {
        tareItem.quantity += addedItem.quantity
      } else {
        tare?.items.push(addedItem)
      }
      const allTares = [...tares, ...(state.supplyRaw?.receipts || [])].reduce((acc, tare) => {
        const foundTare = acc.find(tareRaw => tareRaw.id === tare.id)
        if (foundTare) return acc
        return [...acc, tare]
      }, [])
      const trustBoxesObj: TrustBoxObj = getTrustBoxesObj(state.supplyRaw.trust_acceptance_boxes)

      /** Обновляем данные в таблице К ОБРАБОТКЕ */
      const supplyItems = getSupplyItemsWithoutTareItems(
        state.supplyRaw?.items || [],
        allTares,
        trustBoxesObj
      )

      return {
        ...state,
        tares,
        supplyItems,
      }
    }
    case UPDATE_TARE: {
      const tares = [...state.tares]
      const tareIndex = tares.findIndex((tare) => tare.id === payload.id)
      tares[tareIndex] = payload
      return { ...state, tares }
    }

    case SET_WEIGHT_AND_DIMENSION: {
      return {
        ...state,
        savedItemsWeightAndDimensions: [
          ...state.savedItemsWeightAndDimensions,
          payload,
        ],
      }
    }

    case SET_INDICATE_ITEM_COUNT: {
      return { ...state, indicateItemCount: payload }
    }

    case SET_USED_SERIAL_NUMBERS:
      return { ...state, alreadyUsedSerialNumbers: payload }

    case SET_STICKER:
      return { ...state, sticker: payload }

    case SHOW_DISCREPANCIES_MODAL:
      return { ...state, showDiscrepancies: payload }
    case SHOW_SUPPLY_FORM_MODAL:
      return { ...state, showSupplyFormModal: payload }

    case SET_SHOW_ONLY_GOOD_MODAL:
      return { ...state, showOnlyGoodWarning: payload }

    case SET_SHOW_ONLY_DEFECTIVE_MODAL:
      return { ...state, showOnlyDefectiveWarning: payload }

    case SHOW_DELETE_CONFIRM_MODAL:
      return { ...state, showDeleteConfirm: payload }

    case SHOW_SELECT_DELETION_ITEM_MODAL:
      return { ...state, showSelectDeletionItemModal: payload }

    case SHOW_MASS_ITEM_PRINTING_MODAL:
      return { ...state, showMassItemPrintingModal: payload }

    case SHOW_MASS_ACCEPTANCE_MODAL:
      return { ...state, showMassAcceptanceModal: payload }

    case SHOW_MULTIPLE_COPIES_PRINTING_MODAL:
      return { ...state, showMultipleCopiesPrintingModal: payload }

    case SHOW_TSD_UNPROCESS_MODAL:
      return { ...state, showTSDUnProcessModal: payload }

    case SET_PREVIEW_PRINT:
      return { ...state, printType: payload }

    case SET_PRINT_LIST:
      return { ...state, printList: payload }

    case SET_DEFECT_TYPES:
      return { ...state, defectTypes: payload }

    case SHOW_SETTINGS_DRAWER_MODAL:
      return { ...state, showSettingsDrawer: payload }
    case SHOW_INFO_DRAWER_MODAL:
      return { ...state, showInfoDrawer: payload }

    case SET_ACCEPTANCE_OPTION:
      return { ...state, acceptanceOption: payload }

    case SET_SHOW_ALL_TARES:
      return { ...state, showAllTares: payload }

    case SET_ADDITIONAL_STICKER_COUNT:
      return { ...state, additionalStickerCount: payload }

    default:
      return state
  }
}
