import React from 'react'
import { BatchCell } from 'src/components'
import { IItemTask, ITableColumn, ITableRow } from 'src/interfaces'
import { getBatchNameValue } from '@pages/OperationsAndDocumentsPage/helpers/common'
import { NomenclatureCell } from '../../../../../components'

export const generateCompletingColumns = (): ITableColumn[] => {
  return [
    {
      key: 'name',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      renderCell: ({ row }) => (
        <NomenclatureCell
          title={row.name}
          itemId={String(row.sku_id)}
        />
      )
    },
    {
      key: 'batch_value',
      title: 'Партия',
      title_txt: 'Партия',
      renderCell: ({ row, index }) => (
        <BatchCell
          num={String(row.batchNum || '')}
          date={String(row.batchDate || '')}
        />
      ),
    },
    {
      key: 'quantity',
      title: 'Количество',
      title_txt: 'Количество',
    },
  ]
}
export const generateCompletingRows = (docs: IItemTask[]): ITableRow[] => {
  return docs.map((doc) => {
    const itemBatch = doc.sku.batches.find(batch => batch.id === doc.sku_batch_id)
    return {
      id: doc.sku.external_id,
      sku_id: doc.sku.id,
      batch_value: getBatchNameValue(itemBatch),
      batchNum: itemBatch?.num || '',
      batchDate: itemBatch?.exp_date || '',
      name: doc.sku.title || '',
      quantity: doc.quantity,
    }
  })
}

