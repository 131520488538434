import React, { useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { PATHS } from '@shared/routing'
import { OperAndDocumPages, OperAndDocumPagesType } from '../../../pagesType'
import days from 'dayjs'

import { FormWrapper } from '../../../components'
import { BillingFormContent } from './components'

import { setBillingRawAction, setBillingServicesAction } from '@pages/OperationsAndDocumentsPage/store/actions/billing'

import { formStatesAreEqual } from '../../../store/utils'
import { revertDraftStateToRawAction } from '../../../store/actions'
import { useBillingContext } from '../../../context/BillingContext'
import { IOrderFormDataState } from '../../../store/interfaces/order'
import { useGetQueryBilling } from '@/hooks/billing/useGetBilling'
import { DATE_TIME_VIEW } from '@shared/const/date'

const BillingForm = () => {
  const history = useHistory()
  const { state, dispatch } = useBillingContext()

  const { id: documentId } = useParams<{
    page: OperAndDocumPagesType
    id: string
  }>()


  const { isFetching: isLoading, data: billingData, refetch: refetchBilling } = useGetQueryBilling({
    docId: documentId,
  })

  useEffect(() => {
    if (!billingData) return
    dispatch(setBillingRawAction(billingData.document))
    dispatch(setBillingServicesAction(billingData.services))
  }, [billingData, dispatch])

  const handleClickCancel = () => {
    dispatch(revertDraftStateToRawAction())
    history.push(
      generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
        page: OperAndDocumPages.billingList,
      }),
    )
  }

  const statesAreEqual = formStatesAreEqual<IOrderFormDataState | null>(
    state.formStateDraft,
    state.formStateRaw,
  )

  return (
    <FormWrapper
      loading={isLoading}
      title={state.documentRaw?.id || documentId}
      subtitle={
        state.documentRaw
          ? `от ${days(state.documentRaw?.create_ts).format(DATE_TIME_VIEW)}`
          : ' '
      }
      cancelBtnDisabled={statesAreEqual}
      handleClickCancel={handleClickCancel}
      handleClickSave={undefined}
    >
      <BillingFormContent
        isLoading={isLoading}
        refetch={refetchBilling}
      />
    </FormWrapper>
  )
}

export default BillingForm
