import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import '@app/styles/index.scss'

import { AppContextProvider } from '@shared/providers/AppContextProvider'

import App from '@app/App'
import { createRoot } from 'react-dom/client'

dayjs.locale('ru')
dayjs.extend(customParseFormat)

declare global {
  //TODO Better
  interface Window {
    showNotification: any
    clearNotification: any
    printSticker: any
    soundPlay: any
    timeoutId?: number
    qzObj: {
      qz: any
      cfg: any
      setPrinter: (printer: string) => void
      printPdf: (base64Data: string) => void
    }
  }
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
})

const app = document.getElementById('root')
const root = createRoot(app)


root.render(
  //<React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  //</React.StrictMode>,
)
