import { FormInputCalcItem, ITableColumn, ITableRow, PreCalcItem, Service } from 'src/interfaces'
import React from 'react'
import InputWithButtonControl from '../InputWithButtonControl/InputWithButtonControl'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { CheckboxCell } from 'src/components'

export const generateColumns = (
  rows: ITableRow[],
  checkedIds: string[],
  withCheckbox?: boolean,
  setCheckedIds?: React.Dispatch<React.SetStateAction<string[]>>,
  handleChangeInput?: (id: string, value: TextFieldPropValue) => void,
  readonly?: boolean,
): ITableColumn[] => {
  const resColumns: ITableColumn[] = [
    {
      key: 'name',
      title: 'Наименование',
      title_txt: 'Наименование',
      gridCellWidth: '200px',
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
      align: 'right',
      renderCell: ({ row }: any) => {
        if (!handleChangeInput) {
          console.error('В ячейку не передали handleChangeInput')
          return null
        }
        return (
          <InputWithButtonControl
            id={row.id}
            value={row.quantity}
            initialServerValue={row.initialQuantity}
            handleChangeInput={handleChangeInput}
            readonly={readonly}
          />
        )
      },
    },
  ]

  const checkboxFiled = {
    key: 'actions',
    title: '',
    title_txt: '',
    isAction: true,
    withHeaderAction: true,
    gridCellWidth: '50px',

    renderCell: ({ row, isHeader, index }: any) => {
      if (!setCheckedIds) {
        console.error('В ячейку не передали setCheckedIds')
        return null
      }
      return (
        <CheckboxCell
          isHeaderCheckbox={isHeader}
          fieldId={row.id}
          fieldsIds={rows.map((row) => row.id)}
          checkedItemsIds={checkedIds}
          setCheckedItemsIds={setCheckedIds}
          readonly={readonly}
        />
      )
    },
  }

  if (withCheckbox) {
    return [checkboxFiled, ...resColumns]
  }
  return resColumns
}

export const generateRows = (
  services: Service[],
  preCalcItems: FormInputCalcItem[],
  initialPreCalcItems: PreCalcItem[],
): ITableRow[] => {
  return services.map((service) => {
    const preCalcItem = preCalcItems.find(
      (calc_item) => calc_item.service_id === service.id,
    )
    const initialPreCalcItem = initialPreCalcItems.find(
      (calc_item) => calc_item.service_id === service.id,
    )
    return {
      id: service.id,
      name: service.title,
      quantity: preCalcItem?.quantity,
      initialQuantity: initialPreCalcItem?.quantity,
    }
  })
}
