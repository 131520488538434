import { IReducerAction, IReducerState } from './storeInterfaces'
import {
  SET_ADD_MASS_ACCEPTANCE,
  SET_CURRENT_PLAN_ITEMS,
  SET_CURRENT_REFUND_PLACE,
  SET_DEFECT_TARA,
  SET_DEFECT_TARA_MERCHANT_ID,
  SET_DEFECT_TYPES,
  SET_DELIVERY_LIST,
  SET_ERROR_OPEN_TARES,
  SET_GOOD_TARA,
  SET_GOOD_TARA_MERCHANT_ID,
  SET_MASS_ACCEPTANCE,
  SET_PACKAGINGS,
  SET_REFUND,
  SET_SKUS,
  SET_SUBSTITUTION_TARA,
  SET_SUBSTITUTION_TARA_MERCHANT_ID,
  SET_TARES,
  SET_USED_SERIAL_NUMBERS,
} from './actions'

export const initialState: IReducerState = {
  problemTares: null,
  refund: null,
  skus: [],
  deliveryList: [],
  currentRefundPlace: null,
  currentPlanItems: [],
  packagings: [],
  alreadyUsedSerialNumbers: [],
  tares: [],
  goodTara: null,
  defectTara: null,
  substitutionTara: null,
  goodTaraMerchantId: null,
  defectTaraMerchantId: null,
  substitutionTaraMerchantId: null,
  defectTypes: [],
  massAcceptance: false,
  addMassAcceptance: null,
}

export const reducer = (
  state: IReducerState,
  action: IReducerAction,
): IReducerState => {
  const { type, payload } = action

  console.log(type, payload)
  switch (type) {
    case SET_REFUND:
      return {
        ...state,
        refund: payload,
      }

    case SET_SKUS:
      return {
        ...state,
        skus: payload,
      }

    case SET_DELIVERY_LIST:
      return {
        ...state,
        deliveryList: payload,
      }

    case SET_CURRENT_REFUND_PLACE:
      return {
        ...state,
        currentRefundPlace: payload,
      }

    case SET_CURRENT_PLAN_ITEMS:
      return {
        ...state,
        currentPlanItems: payload,
      }

    case SET_PACKAGINGS:
      return {
        ...state,
        packagings: payload,
      }

    case SET_USED_SERIAL_NUMBERS:
      return {
        ...state,
        alreadyUsedSerialNumbers: payload,
      }

    case SET_TARES:
      return {
        ...state,
        tares: payload,
      }

    case SET_GOOD_TARA:
      return {
        ...state,
        goodTara: payload,
      }

    case SET_DEFECT_TARA:
      return {
        ...state,
        defectTara: payload,
      }

    case SET_SUBSTITUTION_TARA:
      return {
        ...state,
        substitutionTara: payload,
      }
    case SET_ERROR_OPEN_TARES:
      return {
        ...state,
        problemTares: payload,
      }

    case SET_DEFECT_TYPES:
      return {
        ...state,
        defectTypes: payload,
      }

    case SET_GOOD_TARA_MERCHANT_ID:
      return {
        ...state,
        goodTaraMerchantId: payload,
      }

    case SET_DEFECT_TARA_MERCHANT_ID:
      return {
        ...state,
        defectTaraMerchantId: payload,
      }

    case SET_SUBSTITUTION_TARA_MERCHANT_ID:
      return {
        ...state,
        substitutionTaraMerchantId: payload,
      }

    case SET_MASS_ACCEPTANCE:
      return {
        ...state,
        massAcceptance: payload,
      }
    case SET_ADD_MASS_ACCEPTANCE:
      return {
        ...state,
        addMassAcceptance: payload,
      }

    default:
      return state
  }
}
