import { useMutation } from 'react-query'
import api from '@shared/api'

import { ApiReqResult, ISku, OrderPackingV2, StickerWithCopies } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


interface Params {
  orderId: string
  data: {
    barcode: string
    new_barcode?: string
  }
}
const itemRelabel = async (params: Params) => {
  const result = await api.post(`/packing-v2/order/${params.orderId}/relabel`, params.data)
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

interface Result extends ApiReqResult {
  order: OrderPackingV2
  skus: ISku[]
  files_for_print: StickerWithCopies[]
}

const useItemRelabel = (
  options?: UseMutationOptions<Result, any, any>,
) => {
  return useMutation((params: Params) => itemRelabel(params), options)
}

export default useItemRelabel
