import { usePrint } from './print'
import { useAppContext } from '@shared/providers/AppContextProvider'
import { useFullCompleteBatchMass, useGetBatchDocsForPrint } from '@/hooks'
import { usePreviewBase64Pdf } from '../usePreviewBase64Pdf'
import { useNotifications } from '@shared/providers/NotificationProvider'

/**
 * Печатает / открывает просмотр листов подбора
 */

interface Result {
  printBatchDocs: (
    batchIds: string[],
    withPickingList: boolean,
    withFullComplete: boolean,
    withInvoices: boolean,
    withPreview?: boolean,
    checkedOrders?: string[],
  ) => Promise<void>
}
/* Печать документов батча */
export const usePrintBatchDocs = (): Result => {
  const { setGlobalLoading } = useAppContext()
  const { printSticker } = usePrint({})
  const getBatchDocsForPrint = useGetBatchDocsForPrint()
  const fullCompleteBatchMass = useFullCompleteBatchMass()
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const notification = useNotifications()
  const printBatchDocs = async (
    batchIds: string[],
    withPickingList?: boolean,
    withFullComplete?: boolean,
    withInvoices?: boolean,
    withPreview?: boolean,
    checkedOrders?: string[],
  ) => {
    setGlobalLoading(true)
    try {
      getBatchDocsForPrint
        .mutateAsync(batchIds.map(batchId => ({
          batch_id: batchId,
          invoice: !!withInvoices,
          order_documents: !!withInvoices,
          picking_list: !!withPickingList,
          order_ids: checkedOrders
        })))
        .then((data) => {
          if (withPreview) {
            previewBase64Pdf(data.content)
          } else {
            try {
              printSticker(data.printer, data.content)
            } catch (e) {
              notification?.show('alert', 'Ошибка печати')
            }
          }
        })
        .catch(() => {

        }).finally(() => setGlobalLoading(false))
    } catch (e) {
      notification?.show('alert', 'Ошибка запроса стикеров')
    }
    try {
      if (withFullComplete) {
        fullCompleteBatchMass.mutateAsync(batchIds.map(batchId => ({ batch_id: batchId })))
      }
    } catch (e) {
      notification?.show('alert', 'Ошибка сбора батча')
    }
  }
  return {
    printBatchDocs,
  }
}
