import { useMutation } from 'react-query'
import api from '@shared/api'
import { ApiReqResult } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


type Params = {
  batch_id: string
  warehouse_place_barcode?: string
  with_errors?: boolean
}

interface IApiResult extends ApiReqResult {
  id: string
}

const useFullCompleteBatchMass = (
  options?: UseMutationOptions<any, IApiResult, any>,
) => {
  return useMutation(
    async (data: Params[]) => {
      const res = await api.post(`/order-batch/complete-picking`, data)
      if (!res?.data?.success) {
        throw res
      }
      return res.data
    },
    options
  )
}

export default useFullCompleteBatchMass
