import React from 'react'
import { ITableColumn, ITableRow } from 'src/interfaces'
import { InputWrapper } from 'src/components'
import { IShippingForm } from './ModalAddress'

export const generateColumns = (): ITableColumn[] => {
  return [
    {
      key: 'title',
      title: 'Наименовение',
      title_txt: 'Наименовение',
    },
    {
      key: 'value',
      title: 'Значение',
      title_txt: 'Значение',
    },
  ]
}

export const generateRows = (
  orderItems: IShippingForm[],
  handleChange: (
    id: string,
    value: string,
    type: string,
    mode?: boolean,
  ) => void,
): ITableRow[] => {
  return orderItems
    .filter((el) => el.id !== 'instruction')
    .map((item) => {
      return {
        id: item.id,
        title: item.title,
        value: (
          <InputWrapper
            value={String(item.value)}
            id={item.id}
            size="m"
            handleChange={(value) => {
              handleChange(item.id, value || '', 'state')
            }}
          />
        ),
        editMode: item.editMode,
      }
    })
}
