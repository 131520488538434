import { HistoryChangesActionType, IHistoryChanges, IUserData } from '@/interfaces'

export interface HistoryChangesListResult {
  date: string
  user: IUserData
  workspace: string
  action: HistoryChangesActionType
  entity: string
  after: string
  before: string
  field: string
}
export const convertHistoryChangesToChangesList = (changes: IHistoryChanges[]): HistoryChangesListResult[] => {
  return changes.reduce((accum, { items, ...restChange }) => {
    let resultItems: HistoryChangesListResult[] = []
    items.forEach(({ changes, ...restItem }) => {
      changes.forEach(itemChange => {
        resultItems.push(({ ...restChange, ...restItem, ...itemChange }))
      })
    })
    return [ ...accum, ...resultItems ]
  }, [] as HistoryChangesListResult[])
}