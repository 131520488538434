import { useMutation, useQuery } from 'react-query'
import api from '@shared/api'
import { convertObjectToURLParams, copyNoUndefined } from '@shared/helpers'

import { ApiReqResult, IPaginationFilter, IShipmentPlan, PaginationResult } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'

/*
 * Получение списка планов отгрузок
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Plan-otgruzki/operation/getShipmentPlanList
 */

interface IParams extends IPaginationFilter {
  delivery_companies?: string | string[]
  statuses?: string
  used?: string
  from?: string
  to?: string
  is_shipped?: boolean
}

const getShipmentPlan = async (params: IParams) => {
  const clearParams = copyNoUndefined(params)
  const urlParams = convertObjectToURLParams(clearParams)
  const result = await api.get(`/shipment-plan${urlParams}`)

  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

interface IShipmentPlanResultData extends ApiReqResult, PaginationResult {
  shipment_plans: IShipmentPlan[]
}

export const useGetShipmentPlans = (
  params: IParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IShipmentPlanResultData>(
    [`getShipmentPlans`, params],
    () => getShipmentPlan(params),
    {
      keepPreviousData: true,
      ...options
    },
  )
}


export const useGetShipmentPlansM = () => useMutation(
  'getShipmentPlans',
  getShipmentPlan
  )
